<template>
    <div
            v-if="isCustomIcon"
            class="icon"
            :style="iconStyle"
    >
        <div v-if="name === 'legal_consulting'">
            <LegalConsultingIcon />
        </div>
        <div v-else-if="name === 'study_consulting'">
            <StudyConsultingIcon />
        </div>
        <div v-else-if="name === 'psychological_consulting'">
            <PsychologicalConsultingIcon />
        </div>
        <div v-else-if="name === 'specific_needs'">
            <SpecificNeedsIcon />
        </div>
        <div v-else-if="name === 'career_consulting'">
            <CareerConsultingIcon />
        </div>
        <div v-else>
            <ICCorona/>
        </div>

    </div>
    <v-icon
            :color="color"
            v-else
    >
        {{ name }}
    </v-icon>
</template>

<script>
    import icons from './icons';
    import ICCorona from './assets/ic_corona.svg';
    import LegalConsultingIcon from './assets/legal_consulting.svg';
    import StudyConsultingIcon from './assets/study_consulting.svg';
    import PsychologicalConsultingIcon from './assets/psychological_consulting.svg';
    import SpecificNeedsIcon from './assets/specific_needs.svg';
    import CareerConsultingIcon from './assets/career_consulting.svg';


    export default {
        name: 'Icon',

        components: {
            ICCorona,
            LegalConsultingIcon,
            StudyConsultingIcon,
            PsychologicalConsultingIcon,
            SpecificNeedsIcon,
            CareerConsultingIcon
        },

        props: {
            name: {
                type: String,
                required: true
            },
            color: String,
        },

        computed: {
            isCustomIcon() {
                return icons.includes(this.name);
            },
            iconStyle() {
                const color = this.color || '#757575';
                return {
                    fill: color
                }
            }
        }
    }
</script>

<style scoped>
    .icon svg {
        width: 1.5rem;
        height: 1.5rem;
    }
</style>