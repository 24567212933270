import {del, get, post, put} from "@/services/api"
import Vue from "vue";

const state = {
    answers: [],
    didLoadedAnswers: false,
    textForYesNoAnswers: [
        {
            // temporary id
            id: -1,
            cz: 'Ano',
            en: "Yes"
        },
        {
            // temporary id
            id: -2,
            cz: 'Ne',
            en: "No"
        },
    ],
};

const getters = {
    getAnswers: state => state.answers,
    didLoadedAnswers: state => state.didLoadedAnswers,
    getYesNoText: state => state.textForYesNoAnswers,


    prepareNewAnswer: () => () => ({
        localizedAttributes: {
            cz: {
                text: null
            },
            en: {
                text: null
            }
        },
        correct: false,
        questionId: null,
    })
};

const actions = {

    async fetchAllAnswers({commit}) {
        commit('setDidLoadedAnswers', false);
        await get('gamification/test_answers/')
            .then((response) => {
                commit('setAnswers', response.data.items);
                commit('setDidLoadedAnswers', true);
            });
    },

    async fetchAnswerById({commit}, id) {
        commit('setDidLoadedAnswers', false);
        const response = await get('gamification/test_answers/' + id);
        commit('updateAnswer', response.data.content);
        commit('setDidLoadedAnswers', true);
    },

    async createAnswer({commit}, {item}) {
        const response = await post('gamification/test_answers/', item);
        const newAnswer = response.data.content;
        commit('addAnswer', newAnswer);
        return newAnswer.id;
    },

    async deleteAnswerById({commit}, id) {
        await del(`gamification/test_answers/${id}`)
            .then(() => {
                commit('filterAnswers', id);
            })
    },

    async updateAnswer({commit}, {item}) {
        await put(`gamification/test_answers/${item.id}`, item)
            .then((response) => {
                commit('updateAnswer', response);
            });
    }
};

const mutations = {
    setDidLoadedAnswers: (state, value) => (state.didLoadedAnswers = value),
    addAnswer: (state, item) => (state.answers.push(item)),
    setAnswers: (state, answers) => (state.answers = answers),
    filterAnswers: (state, id) => (state.answers = state.answers.filter(answer => answer.id !== id)),
    updateAnswer: (state, item) => {
        const existing = state.answers.findIndex(a => a.id === item.id);
        if (existing > -1) {
            Vue.set(state.answers, existing, item);
        } else {
            state.answers.push(item);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}