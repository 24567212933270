<template>
  <div>
    <v-dialog v-model="dialog" width="315">
      <template v-slot:activator="{ on }">
        <v-text-field
          :rules="pickerRules" 
          color="dark" 
          v-on="on"
          v-on:input="selectText" 
          v-model="selected" 
          :append-icon="icons.includes(selected) ? selected : ''" 
          :label="pickerTitle">
        </v-text-field>
      </template>
      <v-card style="overflow-y: visible">
        <div class="flex-row" style="overflow-y: visible; height: auto">
            <div class="input-field line">
              <v-text-field
                :autofocus="autofocus"
                placeholder="Vyhledávání"
                v-model="query"
                prepend-icon="search"
                clearable
              ></v-text-field>
            </div>
            <v-divider></v-divider>
        </div>
        <div class="flex-row" style="overflow-y: scroll; height: 160px;">
          <div 
            v-for="item in filteredIcons" 
            :id="`icon-${item}`"
            v-bind:key="item" 
            class="flex-cell" 
            v-bind:class="{ selected: item == selection }" 
            @click="preselect(item)">
              <div class="iconbox">
                  <i class="medium material-icons">{{item}}</i>
              </div>
              <div class="caption">{{item}}</div>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false;">
            Zrušit
          </v-btn>
          <v-btn id="icon-picker-confirm" color="primary" text @click="select" :disabled="selection === ''">
            Vybrat
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import icons from "./icons.json";
    export default {
      /**
       * Announcement types select component.
       */
      name: "IconPicker",

      props: {
        /**
         * Input validation rules.
         */
        rules: Array,
        /**
         * Input initial value.
         */
        initialValue: String,
        /**
         * Input title.
         */
        title: String,
        /**
         * Input autofocus.
         */
        autofocus: Boolean
      },

      data(){
        return {
          icons,
          dialog: false,
          pickerRules: [...this.rules, value => icons.includes(value) || 'Ikona s tímto názvem neexistuje'],
          query: '',
          selection: this.initialValue ? this.initialValue : '',
          selected: this.initialValue ? this.initialValue : '',
          pickerTitle: this.title ? this.title : 'Ikona'
        }
      },
      methods: {
        preselect(selection) {
          this.selection = selection;
        },
        select() {
          this.selected = this.selection;
          this.$emit('iconChanged', this.selected);
          this.dialog = false;
        },
        selectText(){
          if (this.icons.includes(this.selected)) {
            this.$emit('iconChanged', this.selected);
          }
        },
        clearData() {
          this.selection = '';
          this.selected = '';
        }
      },
      computed: {
        filteredIcons: function () {
          let query = this.query ? this.query : '';
          return this.icons.filter((item) => (
              item.toLowerCase().indexOf(query.toLowerCase()) !== -1))
        }
      }
    }
</script>

<style scoped>

.line {
  width:100%;
  margin: 10px 20px 0px 20px;
}

.flex-cell{
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 0 1 100px;
  height: 80px;
  text-align: center;
  transition: 0.5s;
  max-width: 100px;
}

.flex-cell:hover {
  background-color:#F0F0F0;
  cursor: pointer;
  border-radius: 3px;
  transition: 0.2s;
}

.selected {
  background-color:#F0F0F0;
  border-radius: 3px;
  transition: 0.2s;
}

.flex-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.caption {
    padding:2px;
    color: #757575;
    font-size: 11px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 10px;
}

.iconbox{
  word-wrap: break-word;
}
</style>