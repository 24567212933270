<template>
    <Loading v-if="!didLoadedData"></Loading>
    <div v-else>
        <ConfirmDialog v-if="questionToDelete"
            :model="showDeleteDialog"
            :onConfirm="attemptToDeleteQuestion"
            :onCancel="() => this.questionToDelete = undefined"
            yesColor="red"
            yes="Smazat"
        >
            Přejete si smazat otázku "{{ questionToDelete.localizedAttributes.cz.question }}" ?
            Tato operace je nevratná.
        </ConfirmDialog>
        <Alert
            :model="errorOnDelete" 
            :onConfirm="() => this.errorOnDelete = false" 
        >
            {{errorMessage}}
        </Alert>
        <ModuleHeader @onRefreshButtonClick="refreshFaqQuestions" >
            <RefreshButton @click="refreshFaqQuestions">
                <h2>Často kladené otázky</h2>
            </RefreshButton>
            <PrimaryButton :to="{name: 'faqQuestionDetail', params: {id: 'new'}}">+ Nová</PrimaryButton>
        </ModuleHeader>

        <v-row class="align-items-center justify-start mb-3">
            <v-col cols="12" md=6 lg="4" class="py-0">
                <FAQCategorySelect
                        label="Kategorie"
                        :categories="sortedCategories(currentLanguage)"
                        :clearable="true"
                        :language="currentLanguage"
                        v-model="$query.categoryFilter"
                />
            </v-col>
            <v-col cols="12" md=6 lg="4" class="py-0">
                <LightButton @click="$router.push({name:'faqCategories'})">Spravovat kategorie</LightButton>
            </v-col>
        </v-row>

        <v-data-table
                :headers="headers"
                :items="filteredQuestions" 
                :disable-pagination="true"
                :hide-default-footer="true"
                :hide-default-header="true"
                :sort-by="['id']"
                class="d-none d-sm-block"
        >
            <template v-slot:item.question="{ item }">
                <TwoLineCellWithIcon>
                    <template v-slot:icon>
                        <FAQCategoryIcon :category="categoryById(item.categoryID)"/>
                    </template>
                    <template v-slot:first>
                        <router-link :to="{name: 'faqQuestionDetail', params: {id: item.id}}">
                            <b class="pointer " :class="item.hidden ? ' hidden-question secondary--text ' : ' light-green--text '">
                                {{ item | localized('question', currentLanguage) | noname }}
                            </b>
                        </router-link>
                    </template>
                    <template v-slot:second>
                        <span class="answer">{{ item | localized('answer', currentLanguage) | ellipsis(100) }}</span>
                    </template>
                </TwoLineCellWithIcon>
            </template>
            <template v-slot:item.action="{ item }">
                <DeleteIcon @click="setQuestionToDelete(item)"/>
            </template>
        </v-data-table>

        <!-- ONLY FOR SMALLER SCREENS -->
        <div class="mx-0 d-flex d-block d-sm-none">
          <v-checkbox hide-details dense class=" mb-2 justify-content-center align-self-center" @change="handleSelection"/>
          <span style="font-size:10px" class="justify-content-center align-self-center"> Počet vybraných položek: {{ selected.length }}</span>
          <v-spacer/>
          <v-chip
              v-if="this.selected.length !== 0"
              label
              small
              outlined
              color="red"
              class="px-1 justify-content-center align-self-center"
              @click="attemptToDeleteQuestions()"
          ><b>ODSTRANIT</b></v-chip>
        </div>
        <v-data-iterator
            :items="filteredQuestions"
            :disable-pagination="true"
            :hide-default-footer="true"
            :hide-default-header="true"
            v-model="selected"
            :toggle-select-all="true"
            class="d-block d-sm-none pt-1"
        >
          <template v-slot:no-data>
            Žádná dostupná data
          </template>
          <template v-slot:default="{ items, isSelected, select}">
            <div v-for="item in items" :key="item.name">
              <div class="d-flex my-2">
                <v-checkbox
                    :input-value="isSelected(item)"
                    hide-details
                    class="justify-content-center align-self-center mb-2"
                    dense
                    @change="(v) => select(item, v)"
                />

                <FAQMobileDataTableRow
                    :to="{name: 'faqQuestionDetail', params: {id: item.id}}"
                >

                  <template v-slot:icon>
                    <FAQCategoryIcon color="white" :category="categoryById(item.categoryID)"/>
                  </template>
                  <template v-slot:title>
                    {{ item | localized('question', currentLanguage) | noname  }}
                  </template>
                  <template v-slot:subtitle>
                    {{ item | localized('answer', currentLanguage) }}
                  </template>
                </FAQMobileDataTableRow>

              </div>
              <v-divider/>
            </div>
          </template>

        </v-data-iterator>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import LightButton from "../../components/buttons/LightButton";
    import PrimaryButton from "../../components/buttons/PrimaryButton";
    import RefreshButton from "../../components/buttons/RefreshButton";
    import ModuleHeader from "../../components/layout/ModuleHeader";
    import Loading from "../../components/Loading";
    import TwoLineCellWithIcon from "../../components/layout/TwoLineCellWithIcon";
    import FAQCategoryIcon from "../../components/faq/FAQCategoryIcon";
    import ConfirmDialog from '../../components/dialogs/ConfirmDialog';
    import DeleteIcon from "../../components/buttons/DeleteIcon";
    import FAQCategorySelect from "../../components/faq/FAQCategorySelect";
    import FAQMobileDataTableRow from "@/components/layout/FAQMobileDataTableRow";
    import Alert from "../../components/dialogs/Alert.vue"

    export default {
        name: "FAQ",

        components: {
            FAQCategorySelect,
            DeleteIcon,
            FAQCategoryIcon,
            TwoLineCellWithIcon,
            Loading,
            ModuleHeader,
            PrimaryButton,
            RefreshButton,
            LightButton,
            FAQMobileDataTableRow,
            ConfirmDialog,
            Alert
        },

      created() {
            this.fetchQuestions();
            this.fetchCategories();
        },

        data() {
            return {
                deselectAll: false,
                selected: [],
                isAddDialogOpened: false,
                headers: [
                    {
                        text: 'Otázka',
                        sortable: true,
                        value: 'question',
                        width: "80%"
                    },
                    {
                        text: '',
                        align: 'right',
                        sortable: false,
                        value: 'action',
                        width: "20%"
                    }
                ],
                questionToDelete: undefined,
                errorOnDelete: false,
                moreQuestions: false,
                errorMessage: '',

            }
        },
        computed: {
            ...mapGetters('FAQQuestions', ['questions', 'didLoadQuestions']),
            ...mapGetters('FAQCategories', ['categoryById', 'sortedCategories', 'didLoadCategories']),
            ...mapGetters('localization', ['currentLanguage']),

            didLoadedData() { return this.didLoadQuestions && this.didLoadCategories },

            filteredQuestions() {
                if (this.categoryFilter === undefined) {
                    return this.questions;
                } else {
                    return this.questions.filter(q => q.categoryID === this.categoryFilter);
                }
            },

          categoryFilter(){
              // use this method for reading value of this.$query.categoryFilter
             return Number.isNaN(this.$query.categoryFilter) ? undefined : this.$query.categoryFilter
          },

            showDeleteDialog() {
                return this.questionToDelete !== undefined
            },
        },

        methods: {
            ...mapActions('FAQQuestions', ['fetchQuestions', 'deleteQuestion']),
            ...mapActions('FAQCategories', ['fetchCategories']),

            setQuestionToDelete(item) {
                this.questionToDelete = item
            },

            async attemptToDeleteQuestion() {
                try {
                    await this.deleteQuestion(this.questionToDelete.id)
                } catch(e) {
                    this.errorOnDelete = true;
                    this.errorMessage = e.message;
                }
                this.questionToDelete = undefined;
            },



          async attemptToDeleteQuestions() {
              const count = this.selected.length
              this.moreQuestions = true
              const subject = count === 1 ? 'otázku' : 'otázek'
              if (confirm('Opravdu chcete smazat ' + count + ' ' + subject + '?')) {
                  try {
                      for (const question of this.selected) {
                          await this.deleteQuestion(question.id);
                      }
                  } catch(e) {
                      this.errorOnDelete = true;
                      this.errorMessage = e.message;
                  }
                  this.selected = []
              }
          },

          handleSelection(){
              if (this.deselectAll){
                this.selected = []
              }
              else {
                this.filteredQuestions.map(q => {
                  if (!this.selected.includes(q)) this.selected.push(q)
                })
              }
              this.deselectAll = !this.deselectAll
          },

          refreshFaqQuestions() {
            this.fetchQuestions();
            this.fetchCategories();
          },
        }
    }
</script>

<style scoped>
    .answer {
        color: gray;
        font-size: 0.85rem;
    }

    .hidden-question {
        text-decoration: line-through;
    }

    .header-refresh {
        margin-left: 12px;
    }

    .refresh-margin {
        margin-left: 10px;
    }

</style>
