<template>
    <ItemNotFoundState v-if="objectNotFound" :objectName="'Problém/Nápad'">
        <template #backButton>
            <BackButton :target="{name: 'reports'}">Problémy a nápady</BackButton>
        </template>
    </ItemNotFoundState>
    <Loading v-else-if="!didLoadData"/>
    <div v-else class="reportDetail">
        <BackButton :target="{name: 'reports'}">Problémy a nápady</BackButton>
        <ModuleHeader>
            <h2>
                Detail reportu {{ singleDetail.id }}
            </h2>
            <v-spacer/>
        </ModuleHeader>
        <v-form ref="form">
            <v-layout row>
                <div class="col-lg-6">
                    <h4 class="mb-2">Report</h4>

                    <div class="mb-4">
                        <div class="mb-1" v-if="singleDetail.author">
                            <v-icon left>person</v-icon>
                            <i>{{ singleDetail.author }}</i>
                        </div>
                        <div>
                            <v-icon left>today</v-icon>
                            <i>{{ reportedDateTime }}</i>
                        </div>
                    </div>
                    <label>Popis</label>
                    <div>
                        {{ singleDetail.description }}
                    </div>
                    <label>E-mail</label>
                    <div>
                        {{ checkEmail }}
                    </div>
                    <label>Typ</label>
                    <div>
                        {{ reportType(singleDetail.type) }}
                    </div>
                    <div>
                        <label>Naléhavost</label>
                        <p v-if="singleDetail.urgent" style="font-weight: bold">Řešení spěchá</p>
                        <p v-else>Řešení nespěchá</p>
                    </div>
                </div>

                <WebReportImages :images="singleDetail.imageUrls"  class="col-lg-6"/>

            </v-layout>
        </v-form>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import BackButton from "../../components/layout/BackButton";

import ModuleHeader from "../../components/layout/ModuleHeader";
import Loading from "@/components/Loading";
import ItemNotFoundState from "@/components/states/ItemNotFoundState";
import moment from "moment";
import WebReportImages from "@/views/Reports/WebReportImages";

export default {
    name: "WebReportDetail",
    components: {
        WebReportImages,
        Loading, BackButton, ModuleHeader,
        ItemNotFoundState
    },

    mounted() {
        this.loadData();
    },

    watch: {
        reportId() {
            this.loadData()
        }
    },

    methods: {
        ...mapActions('Helpdesk', ['fetchWebReportById']),

        reportType(x) {
            switch (x) {
                case 'idea':
                    return "Nápad";
                case 'problem':
                    return "Problém";
                case 'question':
                    return "Otázka";
                default:
                    return "";
            }
        },

        loadData() {
            this.fetchWebReportById(this.reportId)
        },

    },
    computed: {
        ...mapGetters('Helpdesk', ['getWebReports', 'getSingleWebReport', 'didLoadWebReports']),
        ...mapGetters('errors', ['objectNotFound']),

        reportId() {
            return this.$route.params.id;
        },

        singleDetail() {
            return this.getSingleWebReport(this.reportId);
        },
        didLoadData() {
            return this.didLoadWebReports;
        },
        reportedDateTime() {
            const timestamp = this.singleDetail.created;
            return moment(timestamp).format('LLL');
        },
        checkEmail(){
            return this.singleDetail.email ? this.singleDetail.email : 'E-mail nezadán'  ;
        }
    }
}

</script>

<style scoped>

</style>