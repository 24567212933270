<template>
    <v-container>
        <v-dialog @click:outside="closeLinkDialog()" width="600" transition="dialog-bottom-transition" v-model="showLinkDialog">
            <v-card class="pa-4">
                <v-row justify="space-around">
                    <v-col cols="12" md="5">
                        <v-row>
                            <v-col>
                                <h3>{{$t('markdown.link_description')}}</h3>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    v-model="linkDescription"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-row>
                            <v-col>
                                <h3>{{$t('markdown.link_address_heading')}}</h3>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    v-model="linkAddress"
                                    :placeholder="$t('markdown.example_link_address')"
                                    :rules="[requiredLinkRule]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-alert v-model="showLinkAlert" dismissible text type="warning">{{linkAlertText}}</v-alert>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-row justify="end">
                        <v-col cols="auto">
                            <v-btn plain elevation="0" @click="closeLinkDialog()">{{$t('entities.close')}}</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn style="color: #78BE13 !important;" rounded outlined @click="createLink()">{{$t('markdown.save_link')}}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row justify="space-around" class="styled-btns">
            <v-col cols="12" md="auto">
                <v-btn class="px-n3" plain small @click="highlightText('**')"><v-icon>format_bold</v-icon> {{$t('markdown.bold')}}</v-btn>
            </v-col>
            <v-col cols="12" md="auto">
                <v-btn class="px-n3" plain small @click="highlightText('*')"><v-icon>format_italic</v-icon> {{$t('markdown.italic')}}</v-btn>
            </v-col>
            <v-col cols="12" md="auto">
                <v-btn class="px-n3" plain small @click="highlightText('~~')"><v-icon>format_strikethrough</v-icon> {{$t('markdown.strike')}}</v-btn>
            </v-col>
            <v-col cols="12" md="auto">
                <v-btn class="px-n3" plain small @click="highlightText('[]()')"><v-icon>add_link</v-icon> {{$t('markdown.link')}}</v-btn>
            </v-col>
            <v-col cols="12" md="auto">
                <v-btn class="px-n3" plain small @click="highlightText('##')"><v-icon>title</v-icon> {{$t('markdown.subheading')}}</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-textarea
                    @input="() => $emit('update:cz', textCzValue)"
                    data-cy="description-cz"
                    :label="$t('entities.description_cz')"
                    :rules="[required_rule]"
                    v-model="textCzValue"
                    auto-grow
                    :rows="5"
                    ref="textareaCz"
                    @focus="changeAreaFocus('cz')"
                    @keydown.ctrl.66="highlightText('**')"
                    @keydown.meta.66="highlightText('**')"
                    @keydown.ctrl.73="highlightText('*')"
                    @keydown.meta.73="highlightText('*')"
                > </v-textarea>
            </v-col>
            <v-col cols="12" md="6">
                <v-textarea
                    @input="() => $emit('update:en', textEnValue)"
                    data-cy="description-en"
                    :label="$t('entities.description_en')"
                    :rules="[required_rule]"
                    v-model="textEnValue"
                    :rows="5"
                    auto-grow
                    ref="textareaEn"
                    @focus="changeAreaFocus('en')"
                    @keydown.ctrl.66="highlightText('**')"
                    @keydown.meta.66="highlightText('**')"
                    @keydown.ctrl.73="highlightText('*')"
                    @keydown.meta.73="highlightText('*')"
                > </v-textarea>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="py-0" cols="12">
                <v-alert v-model="showAlert" dismissible text type="warning">{{alertText}}</v-alert>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <h3>{{$t('markdown.final_markdown')}}</h3>
            </v-col>
        </v-row>
        <v-card class="pa-4 my-4" style="background-color: #FaFaFa !important; border-radius: 0px !important;">
            <v-row>
                <v-col cols="12" md="6">
                    <div v-html="formattedTextCz"></div>
                </v-col>
                <v-col style="border-left: 1px solid lightgrey;" cols="12" md="6">
                    <div v-html="formattedTextEn"></div>
                </v-col>
            </v-row>
        </v-card>

        <v-row no-gutters class="mt-4">
            <p class="small-desc">{{$t('markdown.tutorial_description1')}}</p>
        </v-row>
        <v-row no-gutters>
            <p class="small-desc">{{$t('markdown.tutorial_description2')}}</p>
        </v-row>
        <v-row no-gutters>
            <p class="small-desc">{{$t('markdown.tutorial_description3')}}</p>
        </v-row>

    </v-container>
</template>
<script>
import MarkdownIt from 'markdown-it';

export default {
    name: "MarkDownEditor",

    props: {
        textCz: {
            type: String,
            required: false,
        },
        textEn: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            textCzValue: "",
            textEnValue: "",
            required_rule: value => (value && value.trim() !== "") || 'Povinné',
            showAlert: false,
            alertText: "",
            showLinkAlert: false,
            linkAlertText: "",
            showLinkDialog: false,
            linkDescription: "",
            linkAddress: "",
            selectedArea: "",
            textToBeModified : "",
            selectionStart : 0,
            selectionEnd : 0,
            selectionStartCz: 0,
            selectionEndCz: 0,
            selectionStartEn: 0,
            selectionEndEn: 0,
            requiredLinkRule: (v) =>
                this.isValidLinkAddress(v) ||
                'Adresa odkazu musí být v kompletním tvaru. To znamená, že musí obsahovat "https://" a mít alespoň jeden znak za tečkou.',
        }
    },

    created() {
        this.markDownIt = new MarkdownIt();
        this.textCzValue = this.textCz
        this.textEnValue = this.textEn
    },

    computed: {
        formattedTextCz() {
            return this.markDownIt.render(this.textCzValue)
        },

        formattedTextEn() {
            return this.markDownIt.render(this.textEnValue)
        },
    },

    methods: {
        changeAreaFocus(langArea) {
            this.selectedArea = langArea
        },

        /**
         * Finds the cursor position in the right text area and then either remove or add the markdown syntax
         */
        highlightText(action) {
            const actionLength = action.length

            this.findCursorPositions()

            if (this.selectedArea === "cz") {
                this.textToBeModified = this.textCzValue
                this.selectionStart = this.selectionStartCz
                this.selectionEnd = this.selectionEndCz
            } else {
                this.textToBeModified = this.textEnValue
                this.selectionStart = this.selectionStartEn
                this.selectionEnd = this.selectionEndEn

            }

            if (this.selectionStart === this.selectionEnd) {
                this.alertText = this.$t('markdown.no_text_selected')
                this.showAlert = true
            } else {
                switch (action) {
                    case "**":
                    case "~~":
                        // for 2 characters markdown syntax it is necessary to check if the text is already highlighted by checking 2 charactecters before and after the selection. if so those characters are removed
                        if (this.textToBeModified.substring(this.selectionStart - actionLength >= 0 ? this.selectionStart - actionLength : 0, this.selectionEnd + actionLength).includes(action)) {
                            this.textToBeModified = this.textToBeModified.slice(0, this.selectionStart - actionLength >= 0 ? this.selectionStart - actionLength : 0) + this.textToBeModified.substring(this.selectionStart - actionLength >= 0 ? this.selectionStart - actionLength : 0, this.selectionEnd + actionLength).replace(/\*\*/g, "") + this.textToBeModified.slice(this.selectionEnd + actionLength)
                        } else {
                            // if text is not highlighted the markdown syntax is added
                            this.textToBeModified = this.textToBeModified.slice(0, this.selectionStart) + this.modifyTextWithMarkdown(this.textToBeModified.substring(this.selectionStart, this.selectionEnd), action) + this.textToBeModified.slice(this.selectionEnd)
                        }
                        break
                    case "*":
                        if (this.textToBeModified.substring(this.selectionStart - actionLength >= 0 ? this.selectionStart - actionLength : 0, this.selectionEnd + actionLength).includes(action)) {
                            this.textToBeModified = this.textToBeModified.slice(0, this.selectionStart - actionLength >= 0 ? this.selectionStart - actionLength : 0) + this.textToBeModified.substring(this.selectionStart - actionLength >= 0 ? this.selectionStart - actionLength : 0, this.selectionEnd + actionLength).replace(/\*/g, "") + this.textToBeModified.slice(this.selectionEnd + actionLength)
                        } else {
                            this.textToBeModified = this.textToBeModified.slice(0, this.selectionStart) + this.modifyTextWithMarkdown(this.textToBeModified.substring(this.selectionStart, this.selectionEnd), action) + this.textToBeModified.slice(this.selectionEnd)
                        }
                        break
                    case "##":
                        // checks for '##' in the text and removes it if it is already there. if not it adds it
                        if (this.textToBeModified.substring(this.selectionStart - actionLength >= 0 ? this.selectionStart - actionLength : 0, this.selectionEnd).includes(action)) {
                            this.textToBeModified = this.textToBeModified.slice(0, this.selectionStart - actionLength >= 0 ? this.selectionStart - actionLength : 0) + this.textToBeModified.substring(this.selectionStart - actionLength >= 0 ? this.selectionStart - actionLength : 0, this.selectionEnd).replace(/## /g, "") + this.textToBeModified.slice(this.selectionEnd)
                        } else {
                            this.textToBeModified = this.textToBeModified.slice(0, this.selectionStart) + action + " " + this.textToBeModified.substring(this.selectionStart, this.selectionEnd) + this.textToBeModified.substring(this.selectionEnd)
                        }
                        break

                    case "[]()":
                        if (this.doesContainMarkdownLink()) {
                            this.textToBeModified = this.textToBeModified.slice(0, this.selectionStart - 1 >= 0 ? this.selectionStart - 1 : 0) + this.textToBeModified.substring(this.selectionStart - 1 >= 0 ? this.selectionStart - 1 : 0, this.selectionEnd + 1).replace(/\[|\]|\([^)]*\)/g, "") + this.textToBeModified.slice(this.selectionEnd + 1)
                        } else {
                            this.linkDescription = this.textToBeModified.substring(this.selectionStart, this.selectionEnd)
                            this.showLinkDialog = true
                        }
                        break
                    default:
                        break
                }

                this.assignTextToArea()
            }
        },

        createLink() {
            if (this.isValidLinkAddress(this.linkAddress)) {
                this.textToBeModified = this.textToBeModified.slice(0, this.selectionStart) + "[" + this.linkDescription + "](" + this.linkAddress + ")" + this.textToBeModified.slice(this.selectionEnd)

                this.assignTextToArea()

                this.linkDescription = ""
                this.linkAddress = ""

                this.showLinkDialog = false
            } else {
                this.linkAlertText = this.$t('markdown.incorrect_address')
                this.showLinkAlert = true
            }
        },

        isValidLinkAddress(text) {
            return /https:\/\/.+\..+/.test(text)
        },

        doesContainMarkdownLink() {
            return (this.textToBeModified.substring(this.selectionStart - 1 >= 0 ? this.selectionStart - 1 : 0, this.selectionStart + 1).includes("[") && this.textToBeModified.substring(this.selectionEnd - 1, this.selectionEnd + 1).includes(")"))
        },

        assignTextToArea() {
            if (this.selectedArea === "cz") {
                this.textCzValue = this.textToBeModified
                this.$emit('update:cz', this.textCzValue)
            } else {
                this.textEnValue = this.textToBeModified
                this.$emit('update:en', this.textEnValue)
            }
        },

        closeLinkDialog() {
            this.linkDescription = ""
            this.linkAddress = ""
            this.showLinkDialog = false
        },

        /**
         * Assigns the current cursor positions to the variables
         */
        findCursorPositions() {
            this.selectionStartCz = this.$refs.textareaCz.$refs.input.selectionStart
            this.selectionEndCz = this.$refs.textareaCz.$refs.input.selectionEnd
            this.selectionStartEn = this.$refs.textareaEn.$refs.input.selectionStart
            this.selectionEndEn = this.$refs.textareaEn.$refs.input.selectionEnd
        },

        /**
         * Checks for whitespaces at the start and end of the string and adds the markdown syntax
         * @param {String} textToBeModified
         * @param {String} action
         * @returns {String} modified text
         */
        modifyTextWithMarkdown(textToBeModified, action) {
            const regexStart = /^\s+/
            const regexEnd = /\s+$/

            // Check if there are spaces at the start of the string
            const matchStart = textToBeModified.match(regexStart)
            if (matchStart) {
                // If there are spaces at the start, remove them
                textToBeModified = textToBeModified.replace(regexStart, '')
            }

            // Check if there are spaces at the end of the string
            const matchEnd = textToBeModified.match(regexEnd)
            if (matchEnd) {
                // There are spaces at the end, remove them
                textToBeModified = textToBeModified.replace(regexEnd, '')
            }

            // Add the action at the start and end of the modified text
            if (matchStart) {
                textToBeModified = matchStart[0] + action + textToBeModified + action
            }

            if (matchEnd) {
                textToBeModified = action + textToBeModified + action + matchEnd[0]
            }

            if (!matchStart && !matchEnd) {
                textToBeModified = action + textToBeModified + action
            }

            return textToBeModified
        }
    },
}
</script>
<style scoped>
.v-alert {
    border-radius: 15px !important;
}

.v-card {
    border-radius: 15px !important;
}

.small-desc {
    font-size: 0.85em;
    color: #6D7278;
}

.styled-btns {
    border: 1px solid lightgrey !important;
    margin-top: 15px;
    border-radius: 5px !important;
}
</style>
