import store from '@/store/Store';
import axios from 'axios';
import { config } from '@/config';

export default class ConnectionMonitor {

    static init() {
        window.addEventListener('online', this.setOnline);
        window.addEventListener('offline', this.setOffline);
        this.startMonitoring();
    }

    static setOnline() {
        store.dispatch('ConnectionState/setOnline');
    }

    static setOffline() {
        store.dispatch('ConnectionState/setOffline');
    }

    static setServerAvailable() {
        store.dispatch('ConnectionState/setServerAvailabile');
    }

    static setServerUnavailable() {
        store.dispatch('ConnectionState/setServerUnavailabile');
    }

    static startMonitoring() {
        this.interval = setInterval(() => this.checkServerAvailability(), 15000);
    }

    static stopMonitoring() {
        if (this.interval) { 
            clearInterval(this.interval);
        }
    }

    static async checkServerAvailability() {
        try {
            const response = await axios.get(config.serverUrl + 'echo');
            if (response.status === 200) {
                this.setServerAvailable();
            } else {
                this.setServerUnavailable();
            }
        } catch {
            this.setServerUnavailable();
        }
    }

}
