<template>
  <v-card outlined class="mr-5 mb-5 dashboard-card">
    <v-card-title class="display-title pb-4">
      <v-chip dark class="ma-0 display-chip" :title="display.name" v-on:click="$router.push({name: 'displayDetail', params: {displayId: display.id}});">
        <div class="display-name">{{display.name}}</div>
      </v-chip>
      <v-btn class="display-link" title="Otevřít panel v novém okně" icon v-bind:href="getDisplayUrl(display.id)" target="blank">
        <v-icon>open_in_new</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="display.currentContent.length === 0" class="pb-3">
      Není zobrazován žádný obsah...
    </v-card-text>
    <v-card-text v-else class="d-flex flex-wrap pb-4">
      <div v-for="(content, idx) in display.currentContent" :key="`content-${display.id}-${idx}`" class="content-item" :title="content.announcementTypeName">
        <v-icon :style="{'--base-color': content.color}" class="type-icon">{{content.iconName}}</v-icon>
        <div class="ml-2 mr-1 type-text">{{content.title}}</div>
        <sup v-if="content.isPermanent" class="content-thumbtack">
          <i class="fas fa-thumbtack thumbtack-icon" />
        </sup>
      </div>
    </v-card-text>
    <div class="display-footer d-flex">
      <div class="display-chips">
        <v-chip small class="ml-4 mb-4">Spojení:&nbsp;<b>{{display.connectionsCount}}&nbsp;×</b></v-chip>
        <v-chip small class="ml-4 mb-4">Perioda:&nbsp;<b>{{display.refreshPeriod}}&nbsp;s</b></v-chip>
      </div>
      <ServiceStatus class="mb-4 mr-6" :color="display.error ? 'red' : 'green'" :text="display.error ? 'chyba' : 'ok'" :description="display.error"/>
    </div>
  </v-card>
</template>

<script>
  import { config } from '@/config';
  import ServiceStatus from "../ServiceStatus";
  /**
   * Active display dashboard card showing basic info & content types.
   */
  export default {
    name: 'DashboardItem',

    components: {
      ServiceStatus
    },

    props: {
      display: {
        /**
         * Display ID used for generating the link.
         */
        id: Number,
        /**
         * Display name.
         */
        name: String,
        /**
         * Current content displayed on the display.
         */
        currentContent: Array,
        /**
         * Number of active connections.
         */
        connectionsCount: Number,
        /**
         * Display content's refresh period.
         */
        refreshPeriod: Number,
        /**
         * Display error state flag.
         */
        error: Boolean
      }
    },

    methods: {
      getDisplayUrl(id) {
        return `${config.displaysUrl}?id=${id}`
      },
    }
  }
</script>

<style scoped>
  .dashboard-card {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .display-footer {
    flex-direction: row;
    justify-content: space-between;
  }

  .display-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .display-chip {
    background-color: #65a61a !important;
    max-width: 75%;
  }

  .display-name {
    display: block;
    overflow: hidden; 
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .display-link {
    color: rgba(0,0,0,.54) !important;
  }

  .content-item {
    display: block;
    max-width: 90%;
    justify-self: flex-start;
    padding-right: 1rem;
  }

  .type-icon {
    float: left;
    background-color: var(--base-color);
    font-size: 1.2em !important;
    padding: 0.3em;
    border-radius: 50%;
    color: white !important;
  }

  .type-text {
    width: -webkit-fill-available;
    margin:  0.2em 1em 0.2em;
    padding-left: 0.7em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .content-thumbtack {
    float: right;
    position: relative;
    top: -30px;
    right: -5px;
  }

  .thumbtack-icon {
    font-size: 0.6rem;
  }
</style>