<template>
  <v-text-field
    :id="id"
    :rules="[url_rule, ...(rules || [])]"
    :readonly="readonly"
    :append-outer-icon="shortenerIcon"
    v-model="value"
    :label="`${getLength(value)}/${maxLength} znaků`"
    @change="onChange"
    @click:append-outer="shortenURL()"
  />
</template>

<script>
  import { config } from '@/config';
  import { BitlyClient } from 'bitly';
  /**
   * Text with title displayed on mouse hover.
   */
  export default {
    name: 'URLInput',
    
    props: {
      /**
       * Element ID passed to the input.
       */
      id: String,
      /**
       * URL validation rules.
       */
      rules: Array,
      /**
       * Read only flag.
       */
      readonly: Boolean,
      /**
       * URL string.
       */
      url: String,
      /**
       * Input label.
       */
      label: String,
      /**
       * Max URL length.
       */
      maxLength: Number,
    },

    data() {
      return {
        url_rule: (value) => this.getLength(value) <= this.maxLength ||
          (!this.isURLValid || this.isBitLy(value) ? 'Příliš dlouhý odkaz, formát neumožňuje zkrácení' : 'Příliš dlouhý odkaz, zkrátit jej můžete kliknutím na ikonu nůžek'),

        isURLShortened: false,
        oldURL: undefined,
        value: this.url,
      }
    },

    watch: {
      url(value) {
        this.value = value;
      }
    },

    computed: {
      isURLValid() {
        return require('valid-url').isUri(this.value);
      },

      shortenerIcon() {
        if (this.isURLValid && !(!this.isURLShortened && this.isBitLy(this.value))) {
          return this.isURLShortened ? 'history' : 'content_cut';
        }
        return undefined;
      },
    },

    methods: {
      async shortenURL() {
        if (this.isURLShortened) {
          this.isURLShortened = false;
          this.$emit('change', this.oldURL);
        } else {
          this.isURLShortened = true;
          if (this.isURLValid && !this.isBitLy(this.value)) {
            const bitly = new BitlyClient(config.bitlyToken, {});
            let result;
            try {
              result = await bitly.shorten(this.value);
            } catch {
              this.$emit('change', this.value);
            }
            this.oldURL = this.value;
            this.$emit('change', result.link);
          }
        }
      },

      onChange(value) {
        this.$emit('change', value);
      },

      getLength(value) {
        return value && value.replace(/^https?:\/\//gi, "").replace(/\/$/gi, "").length || 0;
      },

      isBitLy(value) {
        return value.includes('bit.ly');
      }
    }
  }
</script>

<style scoped>
</style>