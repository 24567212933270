import axios from 'axios';

const state = {
    rents: [],
    rentDetail: null,
    technicianRents: []
};
const getters = {
    getRents: state => state.rents,
    getRent: state => state.rentDetail,
    getReturnedRents: state => state.rents.filter(rent => rent.returned === true),
    getCurrentRents: state => state.rents.filter(rent => rent.returned === false),
    getTechnicianRents: state => state.technicianRents,
    getTechnicianReturnedRents: state => state.technicianRents.filter(rent => rent.returned === true),
    getTechnicianCurrentRents: state => state.technicianRents.filter(rent => rent.returned === false),
};
const actions = {
    //Fetch ALL
    async fetchRents({commit}) {
        const response = await axios
            .get('https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/rent/my-rents');
        commit('setRents', response.data.rents);
    },

    //Fetch ONE
    async fetchRent({commit}, id) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/rent/${id}`);
        commit('setRent', response.data);
    },

    //Fetch technician rents
    async fetchTechnicianRents({commit}) {
        const response = await axios
            .get('https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/rent/technician-rent');
        commit('setTechnicianRents', response.data.rents);
    },

    //Add ONE
    async createRent({commit}, rent) {
        return new Promise((resolve) => {
            axios
                .post(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/rent`, rent).then((newRent) => {
                commit('addRent', newRent.data);
                if (newRent.status == 201) {
                    resolve();
                }
            })
        })
    },

    async returnRent({commit}, rent) {
        return new Promise((resolve) => {
            axios
                .post(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/rent/return`, rent).then((returnedRent) => {
                commit('addReturned', returnedRent.data);
                if (returnedRent.status == 201) {
                    resolve();
                }
            })
        })
    }
};

const mutations = {
    setRents: (state, rent) => (state.rents = rent),
    setRent: (state, rent) => (state.rentDetail = rent),
    addRent: (state, rent) => (state.rents.push(rent)),
    setTechnicianRents: (state, rent) => (state.technicianRents = rent),
    addReturned: (state, rent) => {state.rents.push(rent)},
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
