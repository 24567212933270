<template>
    <v-icon @click="this.onClick" :dense="dense" class="mx-1">
        delete
    </v-icon>
</template>

<script>
    export default {
        name: 'DeleteIcon',

        props: {
            dense: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            onClick() {
                this.$emit('click');
            }
        }
    }
</script>

<style scoped>

</style>