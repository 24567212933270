<template>
    <div>
        <v-app-bar-nav-icon class="mobile-menu" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-navigation-drawer
          id="app-drawer"
          class="sticky-menu"
          app
          floating
          width="300px"
          v-model="drawer"
          :isable-resize-watcher="true"
          :mini-variant="isMiniVariant()"
          mobile-breakpoint="820"
        >
            <v-layout class="hide-scroll" tag="v-list" column>
                <div class="btn-height">
                    <v-list-item
                        v-for="(link, i) in filteredLinks"
                        :key="i"
                        :to="link.to"
                        class="v-list-item"
                        @click="menuItemOnClick(link)"
                    >
                        <div v-if="link.parent !== undefined" class="nested"></div>
                        <v-list-item-action>
                            <div :class="link.parent !== undefined ? 'right-space' : ''">
                                <v-icon>{{ link.icon }}</v-icon>
                            </div>
                        </v-list-item-action>
                        <v-list-item-title v-text="link.text"/>
                        <v-list-item-action-text>
                            <v-chip small color="red" dark v-if="link.notification">
                                {{ link.notification }}
                            </v-chip>
                        </v-list-item-action-text>
                    </v-list-item>
                </div>
            </v-layout>
            <v-icon class="mobile-menu cross sticky" @click.stop="drawer = !drawer">close</v-icon>
        </v-navigation-drawer>
        <button
          class="menu-arrow v-list-item"
          @click="menuExpandCollapseOnClick()"
          v-bind:class="[isMiniVariant() ? 'menu-arrow-mini' : '']"
        >
            <span v-if="expandedMenu">
                <!-- double-arrow-left is rotated 180deg to make it right (missing icon)-->
                <v-icon class="double-arrow-left">double_arrow_right</v-icon>
                Skrýt postranní panel
            </span>
            <span v-else>
                <div>
                    <v-icon>double_arrow_right</v-icon>
                </div>
            </span>
        </button>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {

    name: 'LeftMenu',

    data: () => {
        return {
            expandedItemId: null,
            drawer: true,
            expandedMenu: true,

            //Forbid can be loaded from database in the future
            links: [
                {
                    name: 'reports',
                    to: '/auth/reports',
                    icon: 'message',
                    text: 'Problémy a nápady',
                    notification: 0
                },
                {
                    name: 'support',
                    to: '/auth/support',
                    icon: 'help_outline',
                    text:  'Helpdesk',
                },
                {
                    to: '/auth/warnings',
                    icon: 'warning',
                    text: 'Důležité zprávy',
                },
                {
                    to: '/auth/news',
                    icon: 'info',
                    text: 'Novinky',
                },
                {
                    to: '/auth/map',
                    icon: 'map',
                    text: 'Mapa',
                },
                {
                    path: '/mapwidget',
                    icon: 'my_location',
                    text: 'Mapový widget',
                },
                {
                    to: '/auth/study-department/reservation/calendar/detail',
                    icon: 'school',
                    text: 'Studijní oddělení',
                },
                {
                    to: '/auth/study-department/administration',
                    icon: 'web',
                    text: 'Správa studijního oddělení',
                },
                {
                    to: '/auth/displays',
                    icon: 'tv',
                    text: 'Informační panely',
                },
                {
                    to: '/auth/faq',
                    icon: 'question_answer',
                    text: 'Často kladené otázky',
                },
                {
                    to: '/auth/onboarding',
                    icon: 'explore',
                    text: 'Průvodce',
                },
                {
                    to: '/auth/gamification/dashboard',
                    icon: 'sports_esports',
                    text: 'Gamifikace studia',
                },
                {
                    to: '/auth/iot/places',
                    icon: 'view_stream',
                    text: 'Smart University',
                },
                {
                    to: '/auth/iot/beacons',
                    icon: 'bluetooth',
                    text: 'Majáky',
                },
                {
                    to: '/auth/users',
                    icon: 'account_box',
                    text: 'Uživatelé',
                },
                {
                    to: '/auth/schedules',
                    icon: 'event_note',
                    text: 'Datumy semestru',
                },
                // ZAPŮJČKY / RENTS
                // {
                //     id: 'zapujcky',
                //     text: "Zápůjčky",
                //     icon: "content_paste",
                //     isGroup: true,
                // },
                // {
                //     to: '/auth/rent/my-rents',
                //     icon: 'content_paste',
                //     text: 'Moje zápůjčky',
                //     parent: 'zapujcky'
                // },
                // {
                //     to: '/auth/request/my-request',
                //     icon: 'note_alt',
                //     text: 'Moje žádosti',
                //     parent: 'zapujcky'
                // },
                // {
                //     to: '/auth/request/to-approve',
                //     icon: 'note_alt',
                //     text: 'Schvalování žádostí',
                //     parent: 'zapujcky'
                // },
                // {
                //     to: '/auth/rent/technician-rent',
                //     icon: 'note_alt',
                //     text: 'Zápůjčky techniky',
                //     parent: 'zapujcky'
                // },
                // {
                //     to: '/auth/groups',
                //     icon: 'account_box',
                //     text: 'Správa skupin užívatelů',
                //     parent: 'zapujcky'
                // },
                // {
                //     to: '/auth/technics/model-type',
                //     icon: 'devices',
                //     text: 'Správa skupin zařízení',
                //     parent: 'zapujcky'
                // },
                // {
                //     to: '/auth/technics/model',
                //     icon: 'devices',
                //     text: 'Správa modelů zařízení',
                //     parent: 'zapujcky'
                // },
                // {
                //     to: '/auth/technics/device',
                //     icon: 'devices',
                //     text: 'Správa zařízení',
                //     parent: 'zapujcky'
                // },
                // {
                //     to: '/auth/technics/model-type?technics=true',
                //     icon: 'devices',
                //     text: 'Přehled techniky',
                //     parent: 'zapujcky'
                // }
            ]
        }
    },

    created() {
        this.onResize();
    },

    mounted() {
        if (this.hasAccessToReports) {
          this.fetchPendingReportCount();
        }
    },

    watch: {
        getCountOfPendingReports(newValue) {
            this.reportsLink.notification = newValue;
        }
    },

    methods: {
        ...mapActions('reports', ['fetchReportCount']),

        isActive(link) {
            return this.currentRoutePath.startsWith(link.to);
        },

        onResize() {
            this.drawer = window.innerWidth > 820;
        },

        menuItemOnClick(link) {
            if (link.path) window.open(this.$router.resolve({path: link.path}).href, '_blank');
            if (link.isGroup) {
                if (this.expandedItemId === link.id) {
                    this.expandedItemId = null;
                } else {
                    this.expandedItemId = link.id;
                }
            }
        },

        menuExpandCollapseOnClick() {
            if (this.drawer) {
                this.expandedMenu = !this.expandedMenu;
            }
        },

        isMiniVariant() {
            return window.innerWidth > 820 && !this.expandedMenu;
        },

        fetchPendingReportCount() {
            // if currently not in Reports module, download the reports periodically
            if (this.$auth.isLoggedIn() && !this.isActive(this.reportsLink)) {
                this.fetchReportCount();
            }
            setTimeout(() => this.fetchPendingReportCount(), 60000);
        },
    },

    computed: {
        ...mapGetters('reports', ['getCountOfPendingReports']),

        filteredLinks() {
            return this.links.filter(link => {
                const {route} = this.$router.resolve({path: link.to});
                return (
                  (!link.parent || link.parent === this.expandedItemId) &&
                  this.$auth.canAccessRoute(route)
                );
            });
        },

        currentRoutePath() {
            return this.$route.path;
        },

        reportsLink() {
            return this.links.find(l => l.name === 'reports');
        },

        hasAccessToReports() {
            return this.filteredLinks.find(l => l.name === 'reports') !== undefined;
        },
    }
}
</script>

<style scoped>

@media only screen and (max-width: 820px) {
    nav {
        z-index: 999;
        position: absolute;
        overflow: visible;
    }

    .menu-arrow {
        display: none;
    }

    .v-navigation-drawer > div.v-navigation-drawer__content {
        overflow: visible !important;
    }
}

@media only screen and (min-width: 820px) {
    .mobile-menu {
        display: none;
    }

    .sticky-menu {
        position: sticky;
        position: -webkit-sticky;
    }

    nav {
        background-color: transparent;
        margin-top: 0.5rem;
        position: initial;
    }
}

.hide-scroll {
    background-color: #eee;
    height: calc(100% - 50px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
    display: none;
}

.btn-height {
    height: 48px;
}

.mobile-menu {
    position: absolute !important;
    top: 15px;
    background-color: white;
    border-radius: 0px 12px 12px 0px;
}

.cross {
    z-index: 999;
    height: 36px;
    width: 36px;
}

.sticky {
    left: 300px;
}

.v-list-item {
    margin: 6px 0px 6px 0px;
    text-decoration: none;
}

.v-list-item--active {
    background-color: var(--primary-color) !important;
    color: #FFF;
    border-radius: 0px 20px 20px 0px !important;
    text-decoration: none;
}

a.v-list-item--active {
    color: white !important;
    font-weight: bold;
}

.v-list-item:before {
    border-radius: 0px 20px 20px 0px !important;
}

.v-list-item .v-icon {
    margin-right: 15px;
}

.toolbar-items {
    float: right;
}

.nested {
    margin: 6px 0px 6px 20px;
}

.right-space {
    margin-right: 50px;
}

.menu-arrow {
    position: fixed;
    z-index: 9;
    background: white;
    bottom: 0;
    margin-bottom: 0px;
    width: 300px;
}

.menu-arrow-mini {
    width: 56px;
}

.double-arrow-left {
    margin-left: -150px;
    transform: rotate(180deg);
}

</style>
