import {get,} from '@/services/api'

const state = {
    didLoadedStudyProblemsItems: false,
    studyProblemsItems: []
};
const getters = {
    didLoadedStudyProblemsItems: state => state.didLoadedStudyProblemsItems,

    getStudyProblemsItems: state => state.studyProblemsItems,

    getStudyProblemsItem: state => id => {
        return state.studyProblemsItems.find(studyProblemsItem => studyProblemsItem.id === id);
    },

    getStudyProblemsItemByCategory: state => id => {
        return state.studyProblemsItems.filter(studyProblemsItem => studyProblemsItem.studyProblemsCategoryId === id);
    },

    areStudyProblemsItemsFilled: state => {
        return state.studyProblemsItems.length !== 0;
    }

};
const actions = {

    //Fetch ALL
    async fetchStudyProblemsItems({commit}) {
        commit('setDidLoadedStudyProblemsItems', false);
        await get('study_department/study_problems_item/')
            .then((response) => {
                commit('setStudyProblemsItems', response.data.items);
                commit('setDidLoadedStudyProblemsItems', true);
            });
    },

    setStudyProblemsItemsStorage({commit}, value) {
        commit('setStudyProblemsItems', value);
    },
};

const mutations = {
    setDidLoadedStudyProblemsItems: (state, value) => (state.didLoadedStudyProblemsItems = value),
    setStudyProblemsItems: (state, studyProblemsItems) => (state.studyProblemsItems = studyProblemsItems),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
