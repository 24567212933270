<template>
  <v-layout wrap>
    <v-menu
      v-model="showPicker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="200px"
      @input="value => value && $refs.picker && ($refs.picker.selectingHour = true)"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :disabled="disabled"
          :class="condensed ? 'pt-0 pb-0 m-0' : ''"
          :append-icon="undefined"
          :id="id"
          :hide-details="true"
          :rules="rules"
          :label="pickerTitle"
          :prepend-icon="prependIcon ? 'access_time' : undefined"
          readonly
          :value="timeValue"
          v-on="on"
          :clearable="clearable"
          @click:clear="reset()"
          @blur="(target) => { 
            trySubmit(target); 
          }"
          dense
        ></v-text-field>
      </template>
      <v-time-picker
        ref="picker"
        v-model="timeValue"
        color="light-green darken-1"
        format="24hr"
        @click:minute="sendValue"
        :min="minTime"
        :max="maxTime">
      </v-time-picker>
    </v-menu>
  </v-layout>
</template>
<script>
  export default {
    name: "TimePicker",
    props: {
      id: String,
      neighborId: String,
      rules: Array,
      initialValue: String,
      title: String,
      minTime: String,
      maxTime: String,
      clearable: Boolean,
      prependIcon: Boolean,
      condensed: Boolean,
      disabled: Boolean
    },

    data() {
      return {
        showPicker: false,
        timeValue: this.initialValue ? this.initialValue : undefined,
        pickerTitle: this.title ? this.title : 'Čas'
      };
    },

    watch: {
      minTime() {
        if (this.minTime > this.timeValue) {
          this.timeValue = undefined;
        }
      },
      maxTime() {
        if (this.maxTime < this.timeValue) {
          this.timeValue = undefined;
        }
      }
    },
    
    methods: {
      sendValue() {
        this.showPicker = false;
        this.$emit('timeChanged', this.timeValue);
      },
      trySubmit(target) {
        const targetId = target && target.relatedTarget ? target.relatedTarget.id : null;
        if (targetId !== this.neighborId) {
          this.showPicker = false;
          this.$emit('trySubmit');
        }
      },
      reset() {
        this.timeValue = undefined
        this.$emit('timeChanged', this.timeValue)
      }
    }
  };
</script>