export default class MapVisual {

    static getDefaultPolygonOptions() {
        return {
            color: '#ada8a5',
            fillColor: '',
            weight: 1,
            opacity: 1,
            fillOpacity: 1
        }
    }

    static getTintColorOfMarker(type) {
        const tintColor = this.markerColors.find(el => el.type === type);
        return tintColor ? tintColor.tintColor : this.markerColors.find(el => el.type === 'default').tintColor;
    }

    static getFillColorOfPolygon(type) {
        let fillColor = this.indoorPolygonColours.find(el => el.type === type);
        return fillColor ? fillColor.fillColor : this.indoorPolygonColours.find(el => el.type === 'default').fillColor;
    }

    static getStrokeColorOfPolygon(type) {
        let strokeColor = this.indoorPolygonColours.find(el => el.type === type);
        return strokeColor ? strokeColor.strokeColor : this.indoorPolygonColours.find(el => el.type === 'default').strokeColor;
    }

    static getPolygonOptionsAccordingToType(placeType, weight = 0) {
        let options = this.getDefaultPolygonOptions();
        options.fillColor = this.getFillColorOfPolygon(placeType);
        options.fillOpacity = weight < 0 ? 0.5 : options.fillOpacity;
        options.color = this.getStrokeColorOfPolygon(placeType);
        options.opacity = weight < 0 ? 0.3 : options.opacity;
        options.weight = weight > 0 ? 5 : options.weight;
        return options;
    }

    /**
     * Return z-index based on the place's type.
     * @returns Double number representing the vertical position on the map, a higher value means up = closer to the user. May be negative.
     */
    static placeZIndex(placeType) {
        switch (placeType) {
            case null:
                return 100;
            case 'floor':
                return 1;
            case 'base':
                return -200;
            case 'emergency_floor':
                return 11;
            case 'emergency_stairs':
                return 12;
            case 'outline':
                return -100;
            case 'stairs':
                return 2;
            default:
                return 10;
        }
    }

}

/**
 * Colors used for coloring polygons of different place types.
 * Must be equal to the mobile applications.
 * A script for converting colors from mobile swift code to js is:
 ```
 colors.split('case .').slice(1).reduce((a, i) => {
        const type = i.match(/^[^:]+/)[0].replace(/([A-Z])/g, '_$1').toLowerCase();
        const rgb = [...i.matchAll(new RegExp("UIColor\\(red: ([0-9]+) / 255, green: ([0-9]+) / 255, blue: ([0-9]+) / 255", "g"))];
        const fill = rgb[0];
        const stroke = rgb[1];

        var RR = parseInt(fill[1]).toString(16).padStart(2, '0');
        var GG = parseInt(fill[2]).toString(16).padStart(2, '0');
        var BB = parseInt(fill[3]).toString(16).padStart(2, '0');
        var RR2 = parseInt(stroke[1]).toString(16).padStart(2, '0');
        var GG2 = parseInt(stroke[2]).toString(16).padStart(2, '0');
        var BB2 = parseInt(stroke[3]).toString(16).padStart(2, '0');

        return a + '{type: \''+type+'\', fillColor: \'#'+RR+GG+BB+'\', strokeColor: \'#'+RR2+GG2+BB2+'\'}, ' + `\n`;
    }, '')
 ```
 *
 * An example input of the script is:
 ```
 case .highlightedPolygon:
 fillColor = UIColor(red: 255 / 255, green: 153 / 255, blue: 0 / 255, alpha: 1)
 strokeColor = UIColor(red: 230 / 255, green: 128 / 255, blue: 0 / 255, alpha: 0.7)
 case .default:
 fillColor = UIColor(red: 226 / 255, green: 221 / 255, blue: 219 / 255, alpha: 1)
 strokeColor = UIColor(red: 200 / 255, green: 195 / 255, blue: 193 / 255, alpha: 1)
 ```
 */
MapVisual.indoorPolygonColours = [
    {type: 'archive', fillColor: '#e2dddb', strokeColor: '#c8c3c1'},
    {type: 'coatroom', fillColor: '#e2dddb', strokeColor: '#c8c3c1'},
    {type: 'bistro', fillColor: '#ff819d', strokeColor: '#f36783'},
    {type: 'emergency_floor', fillColor: '#ee6b5c', strokeColor: '#d55243'},
    {type: 'emergency_stairs', fillColor: '#75b764', strokeColor: '#5c9e4b'},
    {type: 'empty', fillColor: '#ffffff', strokeColor: '#ffffff'},
    {type: 'engine_room', fillColor: '#e2dddb', strokeColor: '#c8c3c1'},
    {type: 'floor', fillColor: '#ffffff', strokeColor: '#e6e6e6'},
    {type: 'garage', fillColor: '#e2dddb', strokeColor: '#c8c3c1'},
    {type: 'gatehouse', fillColor: '#ffa78f', strokeColor: '#e68e76'},
    {type: 'ground', fillColor: '#f5eee0', strokeColor: '#e6e6e6'},
    {type: 'classroom_and_laboratory', fillColor: '#cfdfc9', strokeColor: '#b6c6b0'},
    {type: 'kitchen', fillColor: '#ff819d', strokeColor: '#f36783'},
    {type: 'library', fillColor: '#e2dddb', strokeColor: '#c8c3c1'},
    {type: 'office', fillColor: '#f2d7b0', strokeColor: '#d9be97'},
    {type: 'room', fillColor: '#e6eaed', strokeColor: '#cdd1d4'},
    {type: 'utility_room', fillColor: '#e2dddb', strokeColor: '#c8c3c1'},
    {type: 'boardroom', fillColor: '#f7ebb7', strokeColor: '#ded2b7'},
    {type: 'terrace', fillColor: '#f2f2f2', strokeColor: '#d9d9d9'},
    {type: 'toilet_male', fillColor: '#c4dff9', strokeColor: '#abc6e0'},
    {type: 'toilet_female', fillColor: '#ffdfe2', strokeColor: '#e6c6c9'},
    {type: 'toilet_handicapped', fillColor: '#c5cdfa', strokeColor: '#b1b9e1'},
    {type: 'elevator', fillColor: '#e2c7ff', strokeColor: '#c9aee6'},
    {type: 'shaft', fillColor: '#e2dddb', strokeColor: '#c8c3c1'},
    {type: 'stairs', fillColor: '#e2c7ff', strokeColor: '#c9aee6'},
    {type: 'storage', fillColor: '#e2dddb', strokeColor: '#c8c3c1'},
    {type: 'study_room', fillColor: '#a3d7d1', strokeColor: '#8abeb8'},
    {type: 'walk', fillColor: '#ffffff', strokeColor: '#e6e6e6'},
    {type: 'wall', fillColor: '#ffffff', strokeColor: '#e6e6e6'},
    {type: 'highlighted_polygon', fillColor: '#ff9900', strokeColor: '#e68000'},
    {type: 'default', fillColor: '#e2dddb', strokeColor: '#c8c3c1'},
];

/**
 * Colors used for coloring markers of different place types.
 * Must be equal to the mobile applications.
 * A script for converting colors from mobile swift code to js is:
 ```
 colors.split('case .').slice(1).reduce((a, i) => {
        const type = i.match(/^[^:]+/)[0].replace(/([A-Z])/g, '_$1').toLowerCase();

        const rgb = [...i.matchAll(new RegExp("UIColor\\(red: ([0-9]+) / 255, green: ([0-9]+) / 255, blue: ([0-9]+) / 255", "g"))];
        // console.log(rgb);
        const tint = rgb[0];

        var RR = parseInt(tint[1]).toString(16).padStart(2, '0');
        var GG = parseInt(tint[2]).toString(16).padStart(2, '0');
        var BB = parseInt(tint[3]).toString(16).padStart(2, '0');

        return a + '{type: \''+type+'\', tintColor: \'#'+RR+GG+BB+'\'}, ' + '\n';
    }, '')
 ```
 *
 * An example input of the script is:
 ```
 case .terrace:
 return UIColor(red: 166 / 255, green: 166 / 255, blue: 166 / 255, alpha: 1)
 case .default:
 return UIColor(red: 150 / 255, green: 145 / 255, blue: 143 / 255, alpha: 1)
 ```
 */
MapVisual.markerColors = [
    {type: 'archive', tintColor: '#96918f'},
    {type: 'bistro', tintColor: '#cc4e6a'},
    {type: 'boardroom', tintColor: '#e6c11e'},
    {type: 'building', tintColor: '#17997e'},
    {type: 'cafe_machine', tintColor: '#cc4e6a'},
    {type: 'cafeteria', tintColor: '#cc4e6a'},
    {type: 'classroom_and_laboratory', tintColor: '#56b201'},
    {type: 'coatroom', tintColor: '#96918f'},
    {type: 'computer_kiosk', tintColor: '#998afc'},
    {type: 'copier', tintColor: '#51dafc'},
    {type: 'department', tintColor: '#eb9952'},
    {type: 'dormitory', tintColor: '#9c7df7'},
    {type: 'elevator', tintColor: '#967bb3'},
    {type: 'engine_room', tintColor: '#96918f'},
    {type: 'emergency_stairs', tintColor: '#428431'},
    {type: 'food_machine', tintColor: '#cc4e6a'},
    {type: 'garage', tintColor: '#96918f'},
    {type: 'garbage', tintColor: '#96918f'},
    {type: 'garden', tintColor: '#81be4f'},
    {type: 'gate', tintColor: '#f26e4a'},
    {type: 'gatehouse', tintColor: '#f26e4a'},
    {type: 'indoor_building', tintColor: '#17997e'},
    {type: 'kitchen', tintColor: '#cc4e6a'},
    {type: 'library', tintColor: '#96918f'},
    {type: 'locker', tintColor: '#96918f'},
    {type: 'office', tintColor: '#eb9952'},
    {type: 'parking', tintColor: '#0054ac'},
    {type: 'room', tintColor: '#a3b2bd'},
    {type: 'ticket_machine', tintColor: '#9c7df7'},
    {type: 'toilet_female', tintColor: '#eb9295'},
    {type: 'toilet_handicapped', tintColor: '#7981ae'},
    {type: 'toilet_male', tintColor: '#7893ad'},
    {type: 'transportation_stop', tintColor: '#49a0f8'},
    {type: 'trashcan', tintColor: '#998afc'},
    {type: 'utility_room', tintColor: '#96918f'},
    {type: 'water_machine', tintColor: '#cc4e6a'},
    {type: 'sink', tintColor: '#3a97fc'},
    {type: 'study_room', tintColor: '#578b85'},
    {type: 'stairs', tintColor: '#967bb3'},
    {type: 'storage', tintColor: '#96918f'},
    {type: 'terrace', tintColor: '#a6a6a6'},
    {type: 'default', tintColor: '#96918f'},
];