<template>
	<div class="onb-master-view">
		<ModuleHeader id="onb-header-1">
			<h2 id="onboarding-module-heading">Průvodce</h2>

			<div class="onb-header-lr">
				<div class="toggle-menu">
          <v-tabs
              color="light-green"
              icons-and-text
              :value="$query.tab === 'scenarios' ? 1 : 0"
          >
            <v-tab @click="changeToLocations()" >
              Lokace
              <v-icon>room</v-icon>
            </v-tab>
            <v-tab @click="changeToScenarios()"  >
              Prohlídky
              <v-icon>far fa-map</v-icon>
            </v-tab>
          </v-tabs>

        </div>

        <PrimaryButton id="add-location" v-if="$route.query.tab !== 'scenarios'" @click="goToDetail('location', 'new')">Nová lokace</PrimaryButton>
        <PrimaryButton id="add-scenario" v-else @click="goToDetail('scenario', 'new')">Nová prohlídka</PrimaryButton>

			</div>
		</ModuleHeader>

    <v-tabs-items :value="$query.tab" :touchless="true">
      <v-tab-item value="locations" >
        <Locations></Locations>
      </v-tab-item>
      <v-tab-item value="scenarios" >
        <Scenarios></Scenarios>
      </v-tab-item>
    </v-tabs-items>


  </div>
</template>

<script>
	import PrimaryButton from "../../components/buttons/PrimaryButton";
	import Locations from "@/views/Onboarding/Locations/Locations";
	import Scenarios from "@/views/Onboarding/Scenarios/Scenarios";
	import ModuleHeader from "@/components/layout/ModuleHeader";

	export default {
		name: "OnboardingMaster",

		components: {
			PrimaryButton,
			Locations,
			Scenarios,
			ModuleHeader
		},


		methods: {
			goToDetail(category, id) {
				if (category === 'location') {
					this.$router.push({name: 'locationDetail', params:{id: id}})
				}
				else {
					this.$router.push({name: 'scenarioDetail', params:{id: id}})
				}
			},

      changeToLocations(){
        this.$query.tab = 'locations'
      },

      changeToScenarios(){
        this.$query.tab = 'scenarios'
      }
		}
	}
</script>

<style scoped>
	.onb-master-view {
		width: 100%;
	}
	#onb-header-1 {
		display: flex;
		flex-direction: column;
		align-items: baseline!important;
	}
	.onb-header {
		margin-bottom: 1em;
		padding-bottom: 0.3em;
		border-bottom: 2px solid #C4C4C4;
		width: 100%;
	}
	.onb-header-lr {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
	.toggle-menu {
		width: 400px;
	}
</style>