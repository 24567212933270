export default
{
    errors: {
        insufficient_permissions: 'Insufficient permissions, contact your chief, please.',
    },

    queueManagement: {
        not_an_assistant: 'You have permissions to access this module. However, you have not been assigned as an assistant by the head of the study department yet.',
    },

    'entities': {
        'send': 'Send',
        'close': 'Close',
        'czech': 'Czech',
        'english': 'English',
        'successfull_request': 'Sent successfully',
        'result_not_found': 'Result not found',
        'detail': 'Detail',
        'loading': 'Loading...',
        'download': 'Download',
        'export': 'Export',
        'section_in_progress': 'This section is in progress.',
        'no_data': 'We could not retrieve any data, please try again later.',
        'description_cz': 'Description',
        'description_en': 'Description',
        'alert': 'Notify',
        'not_alert': 'Do not notify',
    },

    'map': {
        'generator': {
            'map': 'Map',
            'alert': 'Choose a place you want to show from the menu or click on it on the map.',
            'building_select': 'Area',
            'floor_select': 'Floor',
            'place_select': 'Place',
            'iframe_code': 'Code for your webpage',
            'copy': 'Copy',
            'save_as_image': 'Save as image',
            'link_to_map': 'Link to the map',
            'open_map_tab': 'Open the map in a new window',
            'hide_HTML_code': 'Hide HTML code',
            'show_HTML_code': 'Show HTML code',
            'map_is_generating': 'Map is generating',
            'bad_url': 'The entered URL parameters contain a mistake and the map cannot be displayed.',
            'contact': `<a class="white--text" href="https://my.mendelu.cz/contact">Contact us please.</a>`,
            'not_negative': 'Negative value',
            'not_found': (ctx) => `A place <code>${ctx.named('placeName')}</code> was not found.`,
            'bigger_map': 'ZOOM IN MAP',

        },
        'defaults': {
            'exterior': 'Exterior',
            'no_name': 'No name'
        },
        'legend': {
            'building_name': (ctx) => `Building ${ctx.named('building')}`,
            'floor_name': (ctx) => `, floor ${ctx.named('floor')}`,
        }
    },

    'support': {
        'headline': 'Problems and ideas',
        'message_type_input': 'Type of message',
        'email_input': 'E-mail (optional)',
        'description_input': 'Description',
        'image_drop_text': 'Drag photos to upload',
        'urgent_checkbox': 'The solution is urgent (mark only if there is an urgent request)'
    },

    'news': {
        "uploading" : "Uploading a post",
        "uploaded" : "Uploaded",
        "news_title": "News title",
        "uploading_title" : "Uploading title",
        "uploading_additional" : "Uploading additional images",
        "images_title": "Images",
        "placeEN": "Location in English",
        "time": "Time of the event (optional)",

        "detail_hint": "If you enter a custom name for the location of the news, you must also enter the name in English. To enter the time of the news, you must first enter the date of the news item.",
        "additional_info": "Additional information",
        "confirm_cancel_upload": "Clicking 'OK' will delete all the images you are uploading.",
        "info_images": "The recommended size of uploaded images is up to 5 MB, the maximum limit is 15 MB. If your connection is slow, it may take longer to save images.",
        "info_images_upload": "If it takes too long to upload, check your internet connection speed and image size.",
        "notification_dialog_description": "Everyone who uses the My MENDELU mobile app will receive a notification. If you are sure you want to notify users, click on the \"Notify\" button. If you want to edit the news without notification, click \"Do not notify\".",
        "notification_dialog_title": "Save and notify users again?",
    },

    'gamification': {
        'have_question': 'I have a question',
        'gamification_items': 'Administration of gamification items',
        'gamification_items_subheading': 'Manage gamification items for students',
        'dashboard_welcome_title': 'Welcome to Testing and Gamification module!',
        'dashboard_welcome_subtitle': 'Create quizzes for your students.',
        'launch': 'Launch',
        'users_heading': 'Users',
        'search_for_users': 'Search for users',
        'login': 'Login',
        'scored_points': 'Scored points',
        'number_of_points': 'Number of points',
        'type_of_question': 'Type of question',
        'one_right_answer': 'One right answer',
        'multiple_right_answers': 'Multiple right answers',
        'true_false': 'True/False',
        'date_sent_test': 'Date of sent test',
        'cannot_edit_when_active': 'Editing or deletion is not possible when the test is activated.',
        'to_subjects_btn': 'Go to subjects',
        'test_name': 'Test name',
        'course': 'Course',
        'category': 'Category',
        'created_by': 'Created by',
        'created': 'Created',
        'hide_test': 'Hide test',
        'show_test': 'Show test',
        'list_of_tests': 'List of tests',
        'add_test': 'Add test',
        'delete_test': 'Detele test',
        'test_detail': 'Test detail',
        'edit_test': 'Edit test',
        'test_activated': 'Test activated',
        'test_deactivated': 'Test deactivated',
        'test_questions': 'Test questions',
        'warning_edit': 'To edit the test, you must first deactivate it',
        'questions1': 'question',
        'questions2': 'questions',
        'questions3': 'questions',
        'points1': 'points',
        'points2': 'points',
        'points3': 'points',
        'assign_question': ' + ASSIGN A QUESTION',
        'no_questions': 'The test does not contain any questions.',
        'deactivate_to_edit': 'To edit or delete, deactivate the test',
        'show_correct_answers': 'Show students the correct results',
        'activate_test': 'Test activation',
        'test_name_filled': 'Completed test title in both languages',
        'test_category_selected': 'Selected test category',
        'test_questions_filled': 'Completed test questions',
        'tutorial_heading': 'Instructions for use',
        'badges_headline': 'Badges',
        'activities_headline': 'Activities',
        'levels_headline': 'Levels',
        'image': 'Picture',
        'badge_name': 'Badge name',
        'activity_name': 'Activity name',
        'cz_category_name': 'Category name in Czech',
        'en_category_name': 'Category name in English',
        'cz_badge_name': 'Badge name in Czech',
        'en_badge_name': 'Badge name in English',
        'cz_activity_name': 'Activity name in Czech',
        'en_activity_name': 'Activity name in English',
        'cz_activity_name_description': 'Activity description name in Czech',
        'en_activity_name_description': 'Activity description name in English',
        'new_category': 'New category',
        'edit_category': 'Edit category',
        'edit_badge': 'Edit badge',
        'edit_activity': 'Edit activity',
        'input_warning': 'Enter a name for both languages',
        'test_question': 'Test question',
        'name_of_question': 'Name of question',
        'add_new_answer': 'ADD another option',
        'new_question': 'NEW QUESTION',
        'what_you_choose': 'Select a cell to mark just 1 correct answer to the test.',
        'correct': 'Correct',
        'wrong': 'Wrong',
        'not_filled_answers_fields': 'The question must have at least two completed answers and at least one answer must be correct!',
        'not_filled_question_fields': 'The question must have a title in both languages and a number of points!',
        'question_help_text': 'In order to be saved, the question needs to have a title in both languages and the number of points. Individual questions must have at least two completed answers and at least one answer must be correct. You can use Tab (next item), Shift+Tab, Spacebar and Enter (save) to move around while creating the question.',
        'question_saved_successfully': 'Question was successfully saved',
        'answers_info': 'If it is not a true/false question, you can add another answer. You can only remove answers if the question contains more than 2 answers.',
        'QR_code_dialog_info': 'The QR code is used to load the mobile app.',
        'test_info': 'To save the test, you must fill in the necessary information about the test. The information is filled in correctly if the warning colour changes from red to green and if no exclamation mark appears.',
        'language_test_info': 'If an exclamation mark appears next to the language, it means that some of the data is not filled in.',
        'test_activation_info': 'Activate the test if it is to be available to students. If the test is activated, it cannot be edited. It must first be deactivated.',
        'QR_code_info': 'The QR code is used to load the mobile app. To view the QR code in a larger size, click on the QR code image.',
        'questions_info': 'This section shows the questions that are included in the test. You can add a new question, existing questions or edit questions in the test.',
        'users_info': 'In order to assign a user to a test, we need to enter the user\'s exact login name from UIS in the form xlogin. For example "xgona".',
        'question_dialog_info': 'Questions that are not in the test are shown here.',
        'insert_login': 'Enter the login of the user you want to allow to edit the content of the object.',
        'category_info': 'First you need to create a category in the subject section and then you can create a test.',
        'no_questions_to_show': 'No questions to show',
        'correctEN': 'Correct (EN)',
        'wrongEN': 'Wrong (EN)',
      },

    'iotSensors': {
        'dashboardTitle': 'Smart University',
        'detail_back_button':'List of rooms',
        'search': 'What room are you searching for?',
        'degree_celsius': '°C',
        'free_pcs': 'Free PCs',
        'projector': 'Projector',
        'whiteboard': 'Whiteboard',
        'floor': 'floor',
        'open': 'Open',
        'close': 'Close',
        'measurement_tech_list': 'List of technologies',
        'pc_list': 'List of computers',
        'software_list': 'Software list',
        'pc_occupied': 'Occupied',
        'pc_not_occupied': 'Free',
        'search_software': 'Which software are you looking for?',
        'search_pc': 'What computer are you looking for?',
        'search_category': 'What category are you looking for?',
        'no_match_for_software': 'Sorry, no software found here. Try searching for another one.',
        'no_match_for_computer': 'Sorry, no computer found here. Try searching for another one.',

        'roomTypes': {
            'all_rooms': 'All',
            'classroom_and_laboratory': 'Classrooms and laboratories',
            'study_room': 'Study rooms',
            'pc_rooms': 'Computer rooms',
        },
        'measurements': {
            'HUMIDITY': 'Humidity',
            'NOISE': 'Noise',
            'LIGHT_INTENSITY': 'Light Intensity',
            'OCCUPANCY': 'Occupancy',
            'WINDOW_STATE': 'Window state',
            'TEMPERATURE': 'Temperature',
        },
        'measurement_icons': {
            'HUMIDITY': 'opacity',
            'NOISE': 'record_voice_over',
            'LIGHT_INTENSITY': 'emoji_objects',
            'OCCUPANCY': 'groups',
            'OCCUPANCY_PCS': 'meeting_room',
            'WINDOW_STATE': 'window',
            'TEMPERATURE': 'thermostat',
        },
        'alert': {
            'success': 'The values are normal, you will feel good here',
            'warning': 'Some values are not great',
            'error': 'The values in this room are NOT appropriate',
          }
    },

    'markdown': {
        "link_address_heading": "Link address",
        "link_description": "Description of the link",
        "save_link": "Save the link",
        "final_markdown": "Resulting Markdown formatting",
        "tutorial_description1": "- for line indentation it is necessary to enter 2x spaces / enters",
        "tutorial_description2": "- to cancel styling it is necessary to mark the whole formatted section with special characters and press again the button whose styling you want to cancel",
        "tutorial_description3": "- for faster formatting you can use keyboard shortcuts like Ctrl + B for bold, Ctrl + I for italic",
        "no_text_selected": "No text selected",
        "incorrect_address": "Invalid address",
        "example_link_address": "https://link.cz",
        "bold": "Bold",
        "italic": "Italic",
        "strike": "Strike",
        "link": "Link",
        "subheading": "Subheading",
    }
}
