import {get, post, put, del} from "@/services/api"
import Vue from "vue";
const _ = require('lodash');

const state = {
    categories: [],
    didLoadCategories: false,
    didLoadCategory: false,
    defaultCategory: {
        localizedAttributes: {
            "cz": {
                name: "",
            },
            "en": {
                name: "",
            }
        },
        subjectId: null,
    },
    newCategory: null
};

const getters = {
    getCategoriesBySubjectId: state => subjectId => state.categories.filter(t => t.subjectId === subjectId),
    getEmptyCategory: state => state.defaultCategory,
    getCategoryByName: state => (categoryName, lang) => state.categories.find(t => t.localizedAttributes[lang].name === categoryName),
    didLoadCategories: state => state.didLoadCategories,
};

const actions = {
    async fetchCategoriesBySubjectId({commit}, id) {
        commit('startedDataDownload');
        const response = await get(`gamification/subjects/${id}/categories`);
        commit('setCategories', response.data.items);
        commit('finishedDataDownload');
    },

    async createCategory({commit}, {item}) {
        const response = await post('gamification/subjects/categories', item);
        const newCategory = response.data.content;
        commit('addCategory', newCategory);
        return newCategory.id;
    },

    prepareNewCategory({ commit }) {
        commit('prepareNewCategory');
    },

    async updateCategory({commit}, {item}) {
        const response = await put(`gamification/subjects/categories/${item.id}`, item);
        commit('updateCategory', response.data.content);
    },

    async deleteCategoryById({commit}, id) {
        await del(`gamification/subjects/categories/${id}`)
            .then(() => {
                commit('filterCategories', id);
            })
    },

};

const mutations = {
    startedDataDownload: state => state.didLoadCategories = false,
    finishedDataDownload: state => state.didLoadCategories = true,
    setDidLoadCategory: (state, value) => (state.didLoadCategory = value),
    setCategories: (state, categories) => (state.categories = categories), 
    addCategory: (state, item) => (state.categories.push(item)),
    prepareNewCategory: ( state ) => {
        state.newCategory = _.cloneDeep(state.defaultCategory);
    },
    filterCategories: (state, id) => (state.categories = state.categories.filter(categories => categories.id !== id)),
    updateCategory: (state, item) => {
        const existing = state.categories.findIndex(q => q.id === item.id);
        if (existing > -1) {
            Vue.set(state.categories, existing, item);
        } else {
            state.categories.push(item);
        }
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}