export const displaysHeaders = [
  {
    text: 'Název',
    align: 'left',
    sortable: true,
    value: 'name',
    width: "20%"
  },
  {
    text: 'URL',
    align: 'left',
    sortable: true,
    value: 'id',
    width: "30%"
  },
  {
    text: 'Paralelních zobrazení',
    align: 'center',
    sortable: false,
    value: 'mode',
    width: "15%"
  },
  {
    text: 'Perioda obnovy obsahu [s]',
    align: 'center',
    sortable: false,
    value: 'period',
    width: "15%"
  },
  {
    text: 'Akce',
    align: 'center',
    sortable: false,
    value: 'actions',
    width: "20%"
  }
];
