const state = {
    isLoading: true,
    isLoggedIn: false,
    username: null,
};

const getters = {
    isLoading(state) { return state.isLoading },
    isLoggedIn(state) {
        return state.isLoggedIn;
    },
    username(state) {
        return state.username;
    },
};

const actions = {

};

const mutations = {
    startLoading(state) {
        state.isLoading = true;
    },

    stopLoading(state) {
        state.isLoading = false;
    },

    logIn(state) {
        state.isLoggedIn = true;
        state.isLoading = false;
    },

    logOut(state) {
        state.isLoggedIn = false;
    },

    setUsername(state, username) {
        state.username = username;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}