<template>
    <div>
        <slot name="backButton"/>
        <h5 class="red--text"><v-icon>error</v-icon> {{ objectName }} neexistuje.</h5>
    </div>
</template>

<script>
    export default {
        name: "ItemNotFoundState",
        props: {
            objectName: {
                type: String,
                required: false,
                default: "Objekt"
            }
        }
    }
</script>

<style scoped>
</style>
