<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-container v-else class="max_width_none" align-content-start>
        <v-layout row class="no_margin no_padding width_max">
            <v-flex>
                <div>
                  <BackButton :target="{name: 'StudyProblemsItemPortal'}">{{ $t('entities.studyItem.portal_name') }}</BackButton>
                    <div v-if="isNewStudyProblemsItem">
                        <h2 class="h2_header">{{ $t('entities.studyItem.new_studyItem') }}</h2>
                        <save-button class="new_button" :disabled="!valid" :is-saving="isSaving" @click="addNewStudyProblemsItem()"
                                     :label="$t('entities.save')"></save-button>
                    </div>
                    <div v-else>
                        <h2 v-if="!validateUserRoles" class="h2_header">{{ $t('entities.studyItem.edit_study_req') }}</h2>
                        <h2 v-else class="h2_header">{{ $t('entities.studyItem.study_req_detail') }}</h2>

                        <v-menu top :offset-x="true" :offset-y="true" v-if="!validateUserRoles && !studyProblemsItemModel.archived">
                            <template v-slot:activator="{ on }">
                                <v-btn class="new_button" icon v-on="on"><v-icon>more_vert</v-icon></v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="deleteStudyProblemsItem">
                                    <v-list-item-title class="primary--text">{{ $t('entities.studyItem.del_req') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <save-button v-if="!validateUserRoles" class="new_button" :disabled="buttonDisabledValidate" :is-saving="isSaving" @click="updateStudyProblemsItem()"
                                     :label="$t('entities.save')"></save-button>
                    </div>
                </div>
            </v-flex>
        </v-layout>

        <v-form ref="form" v-model="valid">
            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">1. {{ $t('entities.studyItem.req_name') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  v-model="studyProblemsItemModel.name"
                                  :rules="nameRules"
                                  required
                                  :label="$t('entities.studyItem.type_req_name')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">2. {{ $t('entities.studyItem.req_name_en') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  v-model="studyProblemsItemModel.name_en"
                                  :rules="nameRules"
                                  required
                                  :label="$t('entities.studyItem.type_req_name_en')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">3. {{ $t('entities.studyItem.req_category') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-select
                            v-model="studyProblemsItemModel.studyProblemsCategoryId"
                            :items="studyProblemsCategoryInSelect"
                            :hide-details="true"
                            :rules="[v => !!v || $t('entities.required')]"
                            required
                            solo
                            :label="$t('entities.studyItem.type_req_category')"
                            :disabled="validateUserRoles">
                    </v-select>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin padding">
                <v-flex xs4 class="float_left">
                    <span class="text">4. {{ $t('entities.studyItem.req_code') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  v-model="studyProblemsItemModel.short_code"
                                  :rules="nameRules"
                                  required
                                  :label="$t('entities.studyItem.type_req_code')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin padding_bottom">
                <v-flex xs4 class="float_left">
                    <span class="text">5. {{ $t('entities.studyItem.length') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <number-input :disabled="validateUserRoles" class="vue_box_shadows" v-model="studyProblemsItemModel.default_time" :min="1"
                                  :max="100" :inputtable="false"
                                  :rules="[v => !!v || $t('entities.required')]" controls></number-input>
                </v-flex>
            </v-layout>
        </v-form>

        <v-layout row class="no_margin">
            <v-flex xs4 class="float_left">
                <span class="text">6. {{ $t('entities.studyItem.description') }}</span>
            </v-flex>

            <v-flex xs4 class="float_left">
                <v-text-field solo
                              v-model="studyProblemsItemModel.description"
                              :rules="nameRules"
                              :label="$t('entities.studyItem.type_description')"
                              :disabled="validateUserRoles">
                </v-text-field>
            </v-flex>
        </v-layout>

        <v-layout row class="no_margin">
            <v-flex xs4 class="float_left">
                <span class="text">7. {{ $t('entities.studyItem.description_en') }}</span>
            </v-flex>

            <v-flex xs4 class="float_left">
                <v-text-field solo
                              v-model="studyProblemsItemModel.description_en"
                              :rules="nameRules"
                              :label="$t('entities.studyItem.type_description_en')"
                              :disabled="validateUserRoles">
                </v-text-field>
            </v-flex>
        </v-layout>
        <v-layout row class="no_margin">
            <v-flex xs4 class="float_left">
                <span class="text">8. {{ $t('entities.assistants.ref_assign') }}</span>
            </v-flex>

            <v-flex xs4 class="float_left">
                <v-select
                    v-model="studyProblemsItemModel.assistantId"
                    :items="studyAssistantsInSelect"
                    :hide-details="true"
                    :rules="[v => !!v || $t('entities.required')]"
                    required
                    solo
                    :label="$t('entities.assistants.ref_assign')"
                    :disabled="validateUserRoles">
                </v-select>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import SaveButton from "../../../../../components/buttons/SaveButton";
import Loading from "../../../../../components/Loading";

import {del, post, put} from '@/services/api'

import {mapActions, mapGetters} from 'vuex';
import BackButton from "@/components/layout/BackButton";

export default {
        name: "StudyProblemsItemDetail",

        components: {
            BackButton,
            SaveButton,
            Loading,
        },

        props: {
            id: null,
        },

        data() {
            let Rid = this.$route.params.Rid;

            return {
                proRid: Rid,

                valid: false,
                isSaving: false,

                resp: null,

                codeRules: [
                    v => !!v || this.$t('entities.reasons.code_required'),
                    v => (v && v.length >= 5) || this.$t('entities.reasons.code_too_short'),
                ],

                nameRules: [
                    v => !!v || this.$t('entities.reasons.name_required'),
                    v => (v && v.length >= 5) || this.$t('entities.reasons.name_too_short'),
                ],

                studyProblemsItemModel: {
                    name: null,
                    name_en: null,
                    description: null,
                    description_en: null,
                    default_time: null,
                    short_code: null,
                    studyProblemsCategoryId: null,
                    id: null,
                    isShown: null,
                    assistantId: null,
                },

                studyProblemsItemModelCopy: null,
            }
        },

        created() {
            this.fetchStudyProblemsCategories();
            this.fetchStudyProblemsItems();
            this.fetchAssistants();

            if (!this.isNewStudyProblemsItem) {
                this.loadModel();
                this.createCopyOfOriginal();
            }
        },


        computed: {
            ...mapGetters('studyProblemsCategories', ['didLoadedStudyProblemsCategories', 'getStudyProblemsCategories']),
            ...mapGetters('studyProblemsItems', ['getStudyProblemsItems', 'getStudyProblemsItem', 'didLoadedStudyProblemsItems']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),
            ...mapGetters('assistant', ['getAssistants']),

            didLoadedAll() {
                return this.didLoadedStudyProblemsCategories && this.didLoadedStudyProblemsItems;
            },

            isNewStudyProblemsItem() {
                return this.proRid === 'new';
            },

            buttonDisabledValidate() {
                return !this.valid || this.compareOriginalAndStateNow();
            },

            validateUserRoles() {
                return this.$auth.getStudyAssistantAndRole()?.role === 'STUDY';
            },

            studyAssistantsInSelect(){
                let selectArr = this.getAssistants.map( item => {
                    return {
                        text: item.name,
                        value: item.id
                    }
                })
                selectArr.push({
                    text: this.$t('entities.assistants.no_study_ref_assigned'),
                    value: null
                })
                return selectArr;
            },

            studyProblemsCategoryInSelect() {
                return this.getStudyProblemsCategories.filter(c => c.type !== "other_type").map(item => {
                    return {
                        text: item.name,
                        value: item.id
                    }
                });
            },
        },

        methods: {
            ...mapActions('studyProblemsCategories', ['fetchStudyProblemsCategories']),
            ...mapActions('studyProblemsItems', ['fetchStudyProblemsItems', 'setStudyProblemsItemsStorage']),
            ...mapActions('assistant', ['fetchAssistants']),


            loadModel() {
                let studyItem = this.getStudyProblemsItem(this.proRid);

                this.studyProblemsItemModel.id = studyItem.id;
                this.studyProblemsItemModel.name = studyItem.name;
                this.studyProblemsItemModel.name_en = studyItem.name_en;
                this.studyProblemsItemModel.description = studyItem.description;
                this.studyProblemsItemModel.description_en = studyItem.description_en;
                this.studyProblemsItemModel.default_time = studyItem.defaultTime;
                this.studyProblemsItemModel.short_code = studyItem.shortCode;
                this.studyProblemsItemModel.studyProblemsCategoryId = studyItem.studyProblemsCategoryId;
                this.studyProblemsItemModel.isShown = studyItem.isShown;
                this.studyProblemsItemModel.archived = studyItem.archived;
                this.studyProblemsItemModel.assistantId = studyItem.assistantId;
            },

            createCopyOfOriginal: function () {
                this.studyProblemsItemModelCopy = {...this.studyProblemsItemModel}
            },

            compareOriginalAndStateNow: function () {
                return JSON.stringify(this.studyProblemsItemModel) === JSON.stringify(this.studyProblemsItemModelCopy);
            },

            goBack() {
              this.$router.push({name: "StudyProblemsItemPortal"});
            },

            addNewStudyProblemsItem: function () {
                if (this.$refs.form.validate()) {
                    this.isSaving = true;

                    post('study_department/study_problems_item/', {
                        "name": this.studyProblemsItemModel.name,
                        "name_en": this.studyProblemsItemModel.name_en,
                        "description": this.studyProblemsItemModel.description,
                        "description_en": this.studyProblemsItemModel.description_en,
                        "defaultTime": this.studyProblemsItemModel.default_time,
                        "shortCode": this.studyProblemsItemModel.short_code,
                        "studyProblemsCategoryId": this.studyProblemsItemModel.studyProblemsCategoryId,
                        "isShown": true,
                        assistantId: this.studyProblemsItemModel.assistantId,
                    }).then(response => {
                        this.setStudyProblemsItemsStorage(response.data.items);
                        this.isSaving = false;
                        this.goBack();
                    }).catch(e => {
                        if (e.statusCode > 200 && e.statusCode < 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save'));
                        }
                        if (e.statusCode === 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                    });
                }
            },

            updateStudyProblemsItem: function () {
                if (this.$refs.form.validate()) {
                    this.isSaving = true;

                    put(`study_department/study_problems_item/${this.proRid}`, {
                        "name": this.studyProblemsItemModel.name,
                        "name_en": this.studyProblemsItemModel.name_en,
                        "description": this.studyProblemsItemModel.description,
                        "description_en": this.studyProblemsItemModel.description_en,
                        "defaultTime": this.studyProblemsItemModel.default_time,
                        "shortCode": this.studyProblemsItemModel.short_code,
                        "studyProblemsCategoryId": this.studyProblemsItemModel.studyProblemsCategoryId,
                        "isShown": this.studyProblemsItemModel.isShown,
                        archived: this.studyProblemsItemModel.archived,
                        assistantId: this.studyProblemsItemModel.assistantId,
                    }).then(response => {
                        this.setStudyProblemsItemsStorage(response.data.items);
                        this.isSaving = false;
                        this.goBack();
                    }).catch(e => {
                        if (e.statusCode > 200 && e.statusCode < 500) {
                            this.could_save = false;
                            alert(this.$t('entities.could_save'));
                        }
                        if (e.statusCode === 500) {
                            this.could_save = false;
                            alert(this.$t('entities.could_save_server'));
                        }
                    });
                }
            },

            deleteFromVuex(id) {
                let items = this.getStudyProblemsItems;
                return items.filter(studyProblemsItem => studyProblemsItem.id !== id);
            },

            deleteStudyProblemsItem: function () {
                if (confirm('Opravdu chcete smazat tento záznam?')) {
                    this.setStudyProblemsItemsStorage(this.deleteFromVuex(this.proRid));
                    del('study_department/study_problems_item/' + this.proRid)
                        .then(() => {
                            this.goBack();
                        })
                        .catch(e => alert(e.message));
                }
            }
        },
    }
</script>

<style scoped>
    .new_button {
        float: right;
    }

    .max_width_none {
        max-width: none !important
    }

    .no_padding {
        padding: 0;
    }

    .no_margin {
        margin: 0;
    }

    .width_max {
        width: 100%;
    }

    .h2_header {
        margin-bottom: 1.5rem;
        width: 40%;
        float: left;
    }

    .text {
        font-size: large;
    }

    .padding {
        padding-top: 2rem;
    }

    .padding_bottom {
        padding-bottom: 2rem;
    }

    .vue_box_shadows {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.10) !important;
    }

    .float_left {
        float: left;
        margin: 0;
        padding: 0;
    }

    .delete_this {
        font-size: large;
        font-weight: bold;
        color: red;
        cursor: pointer;
    }

    b {
        font-size: 0.9rem;
        color: #65a61a;
    }

    .fas {
        font-size: 1.4rem;
        color: #65a61a;
    }
</style>
