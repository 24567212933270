<template>
  <v-layout wrap>
    <v-menu
      v-model="showPicker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="200px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :rules="rules"
          :label="pickerTitle"
          :prepend-icon="prependIcon"
          readonly
          :value="displayValue"
          v-on="on"
          :clearable="clearable"
          @click:clear="clearValue"
          color="light-green darken-1"
          :hint="hint"
          :persistent-hint="persistentHint"
        ></v-text-field>
      </template>
      <v-date-picker
        locale="cs-CZ"
        :min="minDate"
        :max="maxDate"
        :first-day-of-week="1"
        v-model="dateValue"
        :show-current="false"
        no-title
        @input="sendValue"
      ></v-date-picker>
    </v-menu>
  </v-layout>
</template>
<script>
  import moment from 'moment';
  export default {
    name: "DatePicker",
    props: {
      rules: Array,
      initialValue: String,
      title: String,
      minDate: String,
      maxDate: String,
      clearable: Boolean,
      customPrependIcon: String,
      hint: String,
      persistentHint: Boolean
    },

    data() {
      return {
        showPicker: false,
        dateValue: this.initialValue ? this.initialValue : undefined,
        pickerTitle: this.title ? this.title : 'Datum',
        prependIcon: this.customPrependIcon ? this.customPrependIcon : 'event'
      };
    },
    
    watch: {
      minDate() {
        if (moment(this.minDate) > moment(this.dateValue)) {
          this.dateValue = undefined;
        }
      }
    },

    computed: {
      displayValue() {
        return this.dateValue ? moment(this.dateValue).format('L') : undefined;
      }
    },

    methods: {
      sendValue() {
        this.showPicker = false;
        this.$emit('dateChanged', moment(this.dateValue).startOf('day'));
      },
      clearValue() {
        this.dateValue = undefined;
        this.$emit('dateChanged', undefined);
      }
    }
  };
</script>