<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-container v-else class="max_width_none" align-content-start>
        <v-layout row class="no_margin no_padding width_max">
            <v-flex>
                <div>
                    <BackButton :target="{name: 'ReasonsPortal'}">{{ $t('entities.reasons.portal_name') }}</BackButton>
                    <div v-if="isNewReason">
                        <h2 class="h2_header">{{ $t('entities.reasons.new_reason') }}</h2>
                        <save-button class="new_button" :disabled="!valid" :is-saving="isSaving" @click="addNewStudyField"
                                     :label="$t('entities.save')"></save-button>
                    </div>
                    <div v-else>
                        <h2 v-if="!validateUserRoles" class="h2_header">{{ $t('entities.reasons.reasons_edit') }}</h2>
                        <h2 v-else class="h2_header">{{ $t('entities.reasons.reasons_detail') }}</h2>

                        <v-menu top :offset-x="true" :offset-y="true" v-if="!validateUserRoles && !reasonsModel.archived">
                            <template v-slot:activator="{ on }">
                                <v-btn v-if="!validateUserRoles" class="new_button" icon v-on="on"><v-icon>more_vert</v-icon></v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="deleteReason">
                                    <v-list-item-title class="primary--text">{{ $t('entities.reasons.reasons_delete') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <save-button v-if="!validateUserRoles" class="new_button" :disabled="buttonDisabledValidate" :is-saving="isSaving" @click="putStudyField"
                                     :label="$t('entities.save')"></save-button>
                    </div>

                </div>
            </v-flex>
        </v-layout>

        <v-form ref="form" v-model="valid">

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">1. {{ $t('entities.reasons.reason_type') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-select
                            v-model="reasonsModel.type"
                            :items=reasonType
                            :hide-details="true"
                            :rules="[v => !!v || $t('entities.required')]"
                            required
                            return-object
                            solo
                            :label="$t('entities.reasons.type_reason_type')"
                            :disabled="validateUserRoles">
                    </v-select>
                </v-flex>
            </v-layout>

            <v-layout row class="margin_top">
                <v-flex xs4 class="float_left">
                    <span class="text">2. {{ $t('entities.reasons.reason_name') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  :rules="nameRules"
                                  required
                                  v-model="reasonsModel.reason"
                                  :label="$t('entities.reasons.type_reason_name')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">3. {{ $t('entities.reasons.reason_name_en') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  :rules="nameRules"
                                  required
                                  v-model="reasonsModel.reason_en"
                                  :label="$t('entities.reasons.type_reason_name_en')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
    import BackButton from "../../../../../components/layout/BackButton";
    import SaveButton from "../../../../../components/buttons/SaveButton";
    import Loading from "../../../../../components/Loading";

    import { put, post, del } from '@/services/api'

    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: "ReasonsDetail",

        components: {
            BackButton,
            SaveButton,
            Loading
        },

        data() {
            let Rid = this.$route.params.Rid;

            return {
                proRid: Rid,

                valid: false,
                isSaving: false,

                resp: null,

                codeRules: [
                    v => !!v || this.$t('entities.reasons.code_required'),
                    v => (v && v.length >= 5) || this.$t('entities.reasons.code_too_short'),
                ],

                nameRules: [
                    v => !!v || this.$t('entities.reasons.name_required'),
                    v => (v && v.length >= 5) || this.$t('entities.reasons.name_too_short'),
                ],

                reasonType: [
                    {text: this.$t('entities.reasons.person'), value: "person"},
                    {text: this.$t('entities.reasons.department'), value: "department"}
                ],

                reasonsModel: {
                    reason: null,
                    reason_en: null,
                    type: null,
                },

                reasonsModelCopy: null,
            }
        },

        created() {
            this.fetchClosedHoursReasons();

            if (!this.isNewReason) {
                this.loadModel();
                this.createCopyOfOriginal();
            }
        },

        computed: {
            ...mapGetters('closedHoursReason', ['didLoadedClosedHoursReasons', 'getClosedHoursReason', 'getClosedHoursReasons']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),
            
            didLoadedAll() {
                return this.didLoadedClosedHoursReasons;
            },

            isNewReason() {
                return this.proRid === 'new';
            },

            buttonDisabledValidate() {
                return !this.valid || this.compareOriginalAndStateNow();
            },

            validateUserRoles() {
                return this.getAssistantStorage.role === 'STUDY';
            }
        },

        methods: {
            ...mapActions('closedHoursReason', ['setClosedHoursReasonsStorage', 'fetchClosedHoursReasons']),

            loadModel() {
                let reason = this.getClosedHoursReason(this.proRid);

                this.reasonsModel.reason = reason.reason;
                this.reasonsModel.reason_en = reason.reason_en;
                this.reasonsModel.type = this.returnCorectTypeClass(reason.type);
                this.reasonsModel.archived = reason.archived;
            },

            returnCorectTypeClass(type) {
                switch (type) {
                    case 'person':
                        return {text: this.$t('entities.reasons.person'), value: "person"};
                    case 'department':
                        return {text: this.$t('entities.reasons.department'), value: "department"}
                }
            },

            deleteFromVuex(id) {
                let items = this.getClosedHoursReasons;
                return items.filter(studyProblemsItem => studyProblemsItem.id !== id);
            },

            deleteReason() {
                if (confirm(this.$t('entities.reasons.confirm_deletion'))) {
                    this.setClosedHoursReasonsStorage(this.deleteFromVuex(this.proRid));
                    del('study_department/assistant/closed_hours_reasons/' + this.proRid)
                        .then(() => {
                            this.$router.push({name: "ReasonsPortal"});
                        })
                        .catch(e => alert(e.message));
                }
            },

            createCopyOfOriginal: function () {
                this.reasonsModelCopy = JSON.parse(JSON.stringify(this.reasonsModel));
            },

            compareOriginalAndStateNow: function () {
                return JSON.stringify(this.reasonsModel) === JSON.stringify(this.reasonsModelCopy);
            },

            putStudyField: function () {
                if (this.$refs.form.validate()) {
                    this.isSaving = true;

                    put('study_department/assistant/closed_hours_reasons/' + this.proRid, {
                        "reason": this.reasonsModel.reason,
                        "reason_en": this.reasonsModel.reason_en,
                        "type": this.reasonsModel.type.value,
                        archived: this.reasonsModel.archived,
                    }).then(response => {
                        this.setClosedHoursReasonsStorage(response.data.items);
                        this.isSaving = false;
                        this.$router.push({name: "ReasonsPortal"})
                    }).catch(e => {
                        if (e.statusCode > 200 && e.statusCode < 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                        if (e.statusCode === 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                    });
                }
            },

            addNewStudyField: function () {
                if (this.$refs.form.validate()) {
                    this.isSaving = true;

                    post('study_department/assistant/closed_hours_reasons/', {
                        "reason": this.reasonsModel.reason,
                        "reason_en": this.reasonsModel.reason_en,
                        "type": this.reasonsModel.type.value,
                    }).then(response => {
                        this.setClosedHoursReasonsStorage(response.data.items);
                        this.isSaving = false;
                        this.$router.push({name: "ReasonsPortal"})
                    }).catch(e => {
                        if (e.statusCode > 200 && e.statusCode < 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                        if (e.statusCode === 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                    });
                }
            },
        },
    }
</script>

<style scoped>
    .max_width_none {
        max-width: none !important
    }

    .no_padding {
        padding: 0;
    }

    .no_margin {
        margin: 0;
    }

    .width_max {
        width: 100%;
    }

    .h2_header {
        margin-bottom: 1.5rem;
        width: 40%;
        float: left;
    }

    .text {
        font-size: large;
    }

    .no_margin {
        margin: 0;
    }

    .float_left {
        float: left;
        margin: 0;
        padding: 0;
    }

    .top {
        margin-top: 1.5rem;
        padding: 0 !important;
    }

    .new_button {
        float: right;
    }

    .delete_this {
        font-size: large;
        font-weight: bold;
        color: red;
        cursor: pointer;
    }

    .divider_margin {
        margin: 2rem 0 0 0;
    }

    .margin_top {
        margin: 2rem 0 0 0;
    }
</style>
