<template>
  <v-container class="mt-3" fluid>
    <v-row class="scores py-0">
      <div class="label py-0">
        <strong>{{ $t('statistics.detail_overview') }}</strong>
      </div>
    </v-row>
    <v-row v-if="hasQueueTotal" class="scores">
      <div class="circle absolute-score">
        {{ queueTotal }}
      </div>
      <div class="label">
        <strong>{{ queueTotalDescription }}</strong>
      </div>
    </v-row>
    <v-row v-for="(value, index) in unitValues" :key="index" class="scores">
      <div class="circle absolute-score">
        {{ value['count'] }}
      </div>
      <div class="circle relative-score">
        {{ `${(relativeTotal(value['count']) * 100).toFixed(0)} %` }}
      </div>
      <div class="label">
        {{ (typeof (value['tableValue']) === 'undefined' ? value['graphValue'] : value['tableValue']) }}
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "StatisticsTable",
  props: {
    data: Object,
    params: Object
  },

  computed: {
    queueTotal() {
      return this.data.unitValues.reduce((a, b) => a + b.count, 0)
    },
    queueTotalDescription() {
      return this.params?.groupByDescription
    },

    hasQueueTotal() {
      return this.params.groupByDescription !== null
    },

    isTableReversed() {
      return this.params.reversedTableOrder
    },

    unitValues() {
      let unitValues = this.data['unitValues'].slice()

      if (this.isTableReversed)
        unitValues.reverse()

      return unitValues
    }

  },

  methods: {
    relativeTotal(value) {
      return isNaN(value / this.queueTotal) === false
          ? value / this.queueTotal
          : 0
    }
  }
}
</script>

<style scoped>
.scores {
  padding: 8px 0;
  border-bottom: 1px solid #CCC;
}

.circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin: 4px;
}

.label {
  height: 50px;
  line-height: 50px;
  margin: 4px 4px 4px 16px;
}

.absolute-score {
  background-color: #7BB916;
  color: #fff;
}

.relative-score {
  background-color: #E9F2DC;
  color: #7BB916;
}

</style>