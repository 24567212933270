<template>
  <v-select
    id="announcement-stops"
    :rules="[value => !!value && value.length > 0 || 'Povinné']"
    v-model="stops"
    :items="allStops"
    item-text="stopName"
    item-value="stopName"
    chips
    color="default"
    label="Zobrazované zastávky"
    multiple
    :clearable="true"
    :menu-props="{ offsetY: true }"
  >
    <template v-if="allStops.length > 0" v-slot:prepend-item>
      <v-list-item
        ripple
        @click="toggleSelectedStops"
      >
        <v-list-item-action>
          <v-icon :color="someStopsAdded || allStopsAdded ? 'blue darken-2' : ''">{{ selectAllIcon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{allStopsAdded  ? 'Zrušit výběr' : 'Vybrat vše'}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
  export default {
    name: "StopsPicker",
    props: {
      initialValues: Object,
      allStops: Array,
      rules: Array
    },

    mounted() {
      this.$emit('stopsChanged', { pristine: this.lodash.isEqual(this.initialValues?.stops, this.stops), value: { stops: this.stops }, valid: this.stops.length > 0 });
    },

    data() {
      return {
        stops: this.initialValues?.stops ?? [],
      };
    },

    computed: {
      selectAllIcon () {
        if (this.allStopsAdded) return 'check_box'
        if (this.someStopsAdded) return 'indeterminate_check_box'
        return 'check_box_outline_blank'
      },

      allStopsAdded() {
        return (this.stops || []).length === this.allStops.length
      },

      someStopsAdded() {
        return (this.stops || []).length > 0 && !this.allStopsAdded
      },
    },
    
    watch: {
      stops(value) {
        this.$emit('stopsChanged', { pristine: this.lodash.isEqual(this.initialValues?.stops, value), value: { stops: value }, valid: value.length > 0 });
      }
    },

    methods: {
      toggleSelectedStops () {
        this.$nextTick(() => {
          if (this.allStopsAdded) {
            this.stops = []
          } else {
            this.stops = this.allStops.map((item) => item.stopName)
          }
        })
      },
    }
  };
</script>

<style>
</style>