const state = {
    previousPath: null,
};

const getters = {
    getPreviousPath: state => state.previousPath,
};

const actions = {
    save({ commit }, previousPath) {
        commit('savePath', previousPath)
    },
};

const mutations = {
    savePath: (state, previousPath) => {
        state.previousPath = previousPath
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}