import axios from 'axios';

const state = {
    devices: [],
    deviceDetail: null
};
const getters = {
    getDevices: state => state.devices,
    getDevice: state => state.deviceDetail
};
const actions = {
    //Fetch ALL
    async fetchDevices({commit}, modelId, groupId) {
        if (modelId === null && groupId === null) {
            const response = await axios
                .get('https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/device');
            commit('setDevices', response.data.devices);
        } else if (modelId !== null) {
            const response = await axios
                .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/device?modelId=${modelId}`);
            commit('setDevices', response.data.devices);
        } else if (modelId !== null || groupId !== null) {
            const response = await axios
                .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/device?modelId=${modelId}&groupId=${groupId}`);
            commit('setDevices', response.data.devices);
        }
    },

    async fetchDevicesByModelId({commit}, modelId) {
            const response = await axios
                .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/device?modelId=${modelId}`);
            commit('setDevices', response.data.devices);
    },

    //Fetch ONE
    async fetchDevice({commit}, id) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/device/${id}`);
        commit('setDevice', response.data);
    },

    //Add ONE
    async createDevice({ commit },  device) {
        return new Promise((resolve) => {
            axios
                .post(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/device`, device).then((newDevice) => {
                commit('addDevice', newDevice.data);
                if(newDevice.status == 201) {
                    resolve();
                }
            });
        })
    },

    //Update ONE
    async updateDevice({commit}, {id, device, onSuccess = () => {} }) {
        commit('updateDevice', id, device);
        await axios
            .put(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/device/${id}`, device)
            .then(onSuccess);
    },

    //Delete ONE
    async deleteDevice({commit}, id) {
        commit('filterDevice', id);
        await axios
            .delete(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/device/${id}`);
    }
};

const mutations = {
    setDevices: (state, device) => (state.devices = device),
    setDevice: (state, device) => (state.deviceDetail = device),
    addDevice: (state, device) => (state.devices.push(device)),
    updateDevice: (state, id, device) => {
        let index = state.devices.filter(device => device.id === id);
        state.devices[index] = device
    },
    filterDevice: (state, id) => (state.devices = state.devices.filter(device => device.id !== id))
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
