<template>
    <div>
        <v-row>
            <v-col>
                <SoftwarePlaceholder />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pt-0">
                <p class="text-center text-md-left">{{$t('entities.no_data')}}</p>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import SoftwarePlaceholder from "@/assets/sync_problem_light.svg";

export default {
    name: 'NoData',

    components: {
        SoftwarePlaceholder
    },
}
</script>
<style>
    p {
        font-size: 1.1em;
    }

    svg {
        display: block;
        margin: 0 auto;
    }
</style>