<template>
  <v-app style="background: #FFFFFF;">
  <div id="app">
    
    <Top v-if="showTop" />
    <Loading v-if="isLoading" />
    <div v-else class="wrapper">
      <LeftMenu v-if="isLoggedIn"/>
      <div class="content" :class="contentClass">
        <router-view/>
      </div>
    </div>

    <ConfirmDialog 
      :model="!!redirect"
      :onConfirm="didConfirmLeave"
      :onCancel="didCancelLeave"
      title="Opravdu chcete odejít?"
      :yesPrimary="false"
      :noPrimary="true">
      Chystáte se opustit stránku bez uložení změn.
      Údaje, které jste vložili, mohou být ztraceny.
    </ConfirmDialog>
    <snackbar/>

  </div>
  </v-app>
</template>

<script>
import Top from './components/Top.vue'
import LeftMenu from "./components/LeftMenu";
import ConfirmDialog from "./components/dialogs/ConfirmDialog";
import { mapActions, mapGetters } from "vuex";
import Loading from "./components/Loading";
import Snackbar from "./components/Snackbar.vue";

export default {
  name: 'app',

  components: {
    Top,
    LeftMenu,
    Loading,
    Snackbar,
    ConfirmDialog
  },

  methods: {
    ...mapActions('leavePrevent', ["didCancelLeave", "didConfirmLeave"]),
  },

  computed: {
    ...mapGetters('user', ['isLoggedIn', 'isLoading']),
    
    ...mapGetters('leavePrevent', ['redirect']),

    currentRouteName() {
      return this.$route.name;
    },

    showTop() {
        return !(["embeddedMap"].includes(this.$route.name));
    },

    contentClass() {
        if (this.$route.name == "embeddedMap") {
          return " no-padding ";
        }

        return "";
    }
  }
}

</script>

<style>

/*GLOBAL STYLES*/

:root {
  --primary-color: #78BE13;
}

.pointer {
  cursor: pointer;
}

 #auth-section {
   width: 100% !important;
 }

button.v-btn.primary {
  background: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  border-radius: 25px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

button.v-btn.primary.btn-small {
  background: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  border-radius: 25px;
  padding: 0 !important;
}

 .v-application button.primary--text,
 .v-application b.light-green--text,
 .v-application div.primary--text,
 .v-application span.primary--text,
 .v-application i.primary--text
 {
   color: #65a61a !important;
   caret-color: #65a61a !important;
 }

  button {
    outline: none !important;
  }

  label {
    margin-bottom: 0px !important;
  }

  thead {
    border-top: 2px solid rgba(0,0,0,.12);
  }

  a {
    color: #65a61a !important;
  }

  .btn-link {
    color: #65a61a !important;
  }

  .btn-primary, .dropdown-item.active, .dropdown-item:active {
    background: #42b983 !important;
    border-color: #42b983 !important;
  }

  .v-tab {
      color: #757575 !important;
      text-decoration: none !important;
  }

  .v-tab--active {
      color: #7BB916 !important;
  }

  .ml-auto{
    margin-left:auto;
  }

  .theme--light.v-pagination .v-pagination__item--active {
    color: #8cb1a0 !important;
  }

  .pointer {
    cursor: pointer;
  }

  .title {
    font-size: 1.25rem !important;
  }

  thead {
    border-top: none !important;
  }

  label {
    font-size: 12px;
    color: rgb(97, 97, 97);
  }

  /* BASIC LAYOUT */

  #app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  .content {
      display: flex;
      width: 100%;
  }

  @media only screen and (min-width: 821px) {
      .content:not(.no-padding) {
          padding: 1.5rem 2.5rem 2.5rem 4rem;
      }
  }

  @media only screen and (max-width: 820px) {
      .content:not(.no-padding) {
          padding: 0.5rem 2rem;
      }
  }

</style>
