<template>
    <Loading v-if="didLoadCategories === false"></Loading>
    <div v-else>
        <BackButton :target="{name: 'faq'}">Často kladené otázky</BackButton>
        <ModuleHeader>
            <h2>Kategorie</h2>
            <PrimaryButton @click="showAddDialog()">+ Nová</PrimaryButton>
        </ModuleHeader>

        <v-row>
            <v-col cols="12" lg="8" xl="6">
                <v-data-table
                        :headers="headers"
                        :items="categories"
                        :sort-by="['localizedAttributes[' + currentLanguage + '].name']"
                        :disable-pagination="true"
                        :hide-default-header="true"
                        :hide-default-footer="true"
                        mobile-breakpoint="0"
                >
                    <template v-slot:item.name="{ item }">
                        <div @click="openDialogIfSmallScreen(item)">
                        <span class="icon">
                            <FAQCategoryIcon :category="item"/>
                        </span>
                        {{ item.localizedAttributes[currentLanguage].name }}
                        </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <EditIcon v-if="$vuetify.breakpoint.smAndUp" @click="showEditDialog(item)"/>
                        <DeleteIcon @click="attemptToDeleteCategory(item)"/>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <FAQCategoryDialog
                :model="isDialogVisible"
                :category="editedCategory"
                @close="closeDialog()" />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import Loading from "../../components/Loading";
    import ModuleHeader from "../../components/layout/ModuleHeader";
    import PrimaryButton from "../../components/buttons/PrimaryButton";
    import FAQCategoryDialog from "./FAQCategoryDialog";
    import BackButton from "../../components/layout/BackButton";
    import {icons} from "../../components/faq/FAQCategoriesIcons";
    import FAQCategoryIcon from "../../components/faq/FAQCategoryIcon";
    import DeleteIcon from "../../components/buttons/DeleteIcon";
    import EditIcon from "../../components/buttons/EditIcon";

    export default {
        name: "categories",

        components: {
            EditIcon,
            DeleteIcon,
            FAQCategoryIcon,
            BackButton,
            FAQCategoryDialog,
            PrimaryButton,
            ModuleHeader,
            Loading
        },

        mounted() {
            this.fetchCategories();
        },

        data() {
            return {
                isDialogVisible: false,
                editedCategory: null,
                headers: [
                    {
                        text: 'Název',
                        value: 'name',
                        width: "70%",
                    },
                    {
                        text: 'Akce',
                        align: 'right',
                        value: 'actions',
                        width: "30%",
                    }
                ]
            }
        },

        computed: {
            ...mapGetters('FAQCategories', ['categories', 'didLoadCategories', 'prepareNewCategory']),
            ...mapGetters( 'localization', ['currentLanguage']),
        },

        methods: {
            ...mapActions('FAQCategories', ['fetchCategories', 'deleteCategory']),

            showAddDialog() {
                this.editedCategory = this.prepareNewCategory();
                this.isDialogVisible = true;
            },

            showEditDialog(category) {
                this.editedCategory = this._.cloneDeep(category);
                this.isDialogVisible = true;
            },

            closeDialog() {
                this.isDialogVisible = false;
            },

            attemptToDeleteCategory(category) {
                if (confirm("Opravdu smazat kategorii " + category.localizedAttributes[this.currentLanguage].name + "?")) {
                    this.deleteCategory(category.id);
                }
            },

            iconFor(item) {
                const icon = icons.find(i => i.mobileIcon === item.iconName);
                return icon ? icon.materialDesignIcon : "";
            },

            openDialogIfSmallScreen(item){
                // row clicking support for small displays
                if (this.$vuetify.breakpoint.xs) {
                    this.showEditDialog(item)
                }
            }
        }
    }
</script>

<style scoped>
    .icon {
        display: inline-block;
        width: 2rem;
    }
</style>
