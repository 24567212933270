<template>
    <v-slide-group
        show-arrows
        :value="modelValue"
        @change="changeModel($event)"
        class="mb-3"
        mandatory
    >
        <v-slide-item
            v-for="type in listOfItems"
            :key="type.value"
            :value="type.value"
            v-slot="{ active, toggle }"
        >
            <v-card
                class="py-xs-1 px-xs-2 py-sm-3 px-sm-4"
                :class="active ? 'item active' : 'item not_active'"
                @click="toggle"
            >
                {{$t(type.value)}}
            </v-card>
        </v-slide-item>
    </v-slide-group>
</template>
<script>
    export default {
        name: "SlideGroup",

        props: {
            listOfItems: {
                type: []
            },

            modelValue: {},

            search: {
                type: String
            },

            localizedPath: {
                type: String
            },
        },

        emits: ['update:modelValue', 'update:search'],

        methods: {
            changeModel(event) {
                this.$emit('update:modelValue', event)
                this.$emit('update:search', "")
            }
        }

    }
</script>
<style scoped>
.item {
    text-transform: uppercase;
    border-radius: 0px !important;
    box-shadow: none !important;
    border-style: solid none solid solid !important;
}

.item:first-child {
    border-top-left-radius: 14px !important;
    border-bottom-left-radius: 14px !important;
}

.item:last-child {
    border-top-right-radius: 14px !important;
    border-bottom-right-radius: 14px !important;
}

.not_active {
    border: 2px solid rgb(220, 220, 220) !important;
    color: gray !important;
}

.active {
    border: 2px solid rgb(175, 175, 175) !important;
    color: rgb(60, 60, 60) !important;
    font-weight: bold;
    background-color: rgb(175, 175, 175) !important;
}
</style>