import Vue from "vue";
import store from "@/store/Store";
import ApiError from "@/services/api/ApiError";

export function initApiErrorHandling() {
    // must listen to both window and Vue error events, because the error can be thrown in a million different ways depending on where it was thrown
    window.addEventListener('unhandledrejection', async event =>
        await handleApiError(event.reason)
    )
    Vue.config.errorHandler = async (err) => {
        await handleApiError(err);
        console.error(err); // must print to console manually, do not rethrow as it will cause Cypress to fail in case of not important errors from Leaflet etc.
    }
}

export async function handleApiError(e) {
    if (e.message === "Network Error") {
        // show generic error message
        await store.dispatch('Snackbar/showErrorSnackbar', "Chyba připojení k serveru.");
    }

    // an api error
    else if (e instanceof ApiError) {
        // auto logout in case of invalid token
        if (e.statusCode === 401) {
            Vue.$auth.logout();
        }

        // show error message from operation.js:checkResponse()
        await store.dispatch('Snackbar/showErrorSnackbar', e.message);
    }
}