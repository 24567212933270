<template>
    <Loading v-if="!didLoadAdministrators || !didLoadFaculties" />
    <v-container v-else>
        <v-row class="mr-1">   
            <v-col class="pl-0 mb-3">
                <h2 class="mb-2">Uživatelé a oprávnění</h2>
                <div v-if="!areAdministratorsEmpty" class="subtitle">Vyberte uživatele, kterého chcete spravovat</div>
            </v-col>
            <v-col v-if="!areAdministratorsEmpty" cols="12" md="4" align-self="center" class="d-flex justify-md-end justify-start pa-0">
                <PrimaryButton @click="createNew('new')" data-cy="add-new">
                    <v-icon class="mr-1">add</v-icon>
                    Přidat uživatele
                </PrimaryButton>
            </v-col>
        </v-row>
        <v-row v-if="!areAdministratorsEmpty" class="mr-1" justify="start">
            <v-col cols="12" md="5" lg="4" class="pl-0 pb-0">
                <v-text-field
                    color="light-green darken-1"
                    v-model="search"
                    prepend-icon="search"
                    label="Koho hledáte?"
                    clearable
                ></v-text-field>
            </v-col>
            <v-col>
                <v-container class="pa-0">
                    <v-row justify="end">
                        <v-col cols="12" md="4">
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select 
                                color="light-green darken-1"
                                :items="faculties"
                                v-model="selectedFaculties"
                                item-text="text"
                                item-value="id"
                                type="string"
                                label="Přiřazená práva"
                                multiple
                                clearable
                                :menu-props="{offsetY: true , maxHeight: '60vh'}"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span class="mr-1" v-if="index < maxDisplayOnSelect">{{ item.text }}</span>
                                    <span class="grey--text caption ml-1" v-if="index === maxDisplayOnSelect"
                                    >(+{{ numberMoreFaculties }})</span>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row v-if="!areAdministratorsEmpty">
            <v-col cols="12" lg="4" v-for="(entity, i) in filteredAdministrators" :key="i" class="pa-0">
                <v-card class="mr-5 mb-5" @click="goToDetailEdit(entity.id)" outlined
                        style="border: 2px solid #eeeeef;" data-cy="card-administrator">
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="mb-1 titleCard ">
                                    {{ entity.fullName }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="mb-1 subTitleCard">
                                    {{ entity.xlogin }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="mt-3">
                                    <v-divider></v-divider>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="mb-2 titleChips">
                                    Fakulty a součásti
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-card-text class="row font-weight-medium ma-0 pa-0">
                                        <v-chip v-for="(faculty, i) in entity.faculties" :key="i"
                                                color="#eeeeef"
                                                text-color="#6c7278"
                                                small
                                                class="mr-1 mb-1">
                                            <span>{{ faculty.localizedAttributes.cz.name }}</span>
                                        </v-chip>
                                    </v-card-text>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-card-actions class="mx-3 mb-2">
                            <span class="button font-weight-medium ">Upravit</span>
                        </v-card-actions>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else class="text-center">
            <v-col>
                <PlaceHolder :path="'/icons/no-users.png'"
                    title="Žádní uživatelé"
                    text="Zatím nebyli přidání žádní uživatelé">
                </PlaceHolder>
                <PrimaryButton class="mt-3" @click="createNew('new')" data-cy="add-new">
                    <v-icon class="mr-1">add</v-icon>
                    Přidat uživatele
                </PrimaryButton>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PrimaryButton from "@/components/buttons/PrimaryButton";
import Loading from "@/components/Loading";
import {mapActions, mapGetters} from "vuex";
import PlaceHolder from "@/components/news/PlaceHolder";

export default {
    name: "Administrators",

    components: {
        PrimaryButton,
        PlaceHolder,
        Loading
    },

    async created() {
        await this.fetchAdministrators();
        await this.fetchAllFaculties();
    },

    data() {
        return {
            selectedFaculties: [],
            search: '',
            maxDisplayOnSelect: 2,
        }
    },

    computed: {
        ...mapGetters('displaysAdmins', ['didLoadAdministrators', 'getAdministrators']),
        ...mapGetters('displaysFaculties', ['getFaculties', 'didLoadFaculties']),
        ...mapGetters('errors', ['objectNotFound']),

        numberMoreFaculties() {
            return this.selectedFaculties.length - this.maxDisplayOnSelect
        },
        
        faculties() {
            return this.getFaculties?.map(faculty => ({text: faculty.text, id: faculty.value.id})) || [];
        },

        filteredAdministrators() {
            let administrators = this.selectedFaculties.length === 0 ? this.getAdministrators : this.getAdministrators.filter((obj) =>
                obj.faculties.find((x) => this.selectedFaculties.includes(x.id))
            );

            administrators = administrators.filter((obj) =>
                !this.search ||
                obj.fullName.toUpperCase().includes((this.search.toUpperCase())) ||
                obj.xlogin.toUpperCase().includes((this.search.toUpperCase()))
            );

            return administrators;
        },

        areAdministratorsEmpty() {
            return this.getAdministrators.length === 0
        }
    },

    methods: {
        ...mapActions('displaysAdmins', ['fetchAdministrators']),
        ...mapActions('displaysFaculties', ['fetchAllFaculties']),

        goToDetailEdit(id) {
            this.$router.push({name: 'administratorDetail', params: {administratorId: id}});
        },

        createNew() {
            this.$router.push({name: 'administratorDetail', params: {administratorId: 'new'}});
        },
    }
}
</script>

<style scoped>

.button {
    color: #78BE13;
    font-weight: bold;
    font-size: 19px;
}

.titleCard {
    padding-left: 0;
    padding-top: 0;
    font-weight: 500;
    line-height: 1.2;
    word-break: normal;
    font-size: 24px;
}

.subTitleCard {
    padding: 0;
    line-height: 1.2;
    font-size: 20px;
}

.titleChips {
    padding: 0;
    line-height: 1.2;
    font-size: 14px;
    font-weight: 500;
    color: #6c7278;
}

.subTitle {
    font-size: 19px;
    color: #6D7278;
}

</style>
