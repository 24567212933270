<template>
    <Loading v-if="didLoadedAll === false"></Loading>
    <v-container v-else fluid class="pa-0 ma-0 flex-grow-1 wrapper">
        <study-overlay v-if="!isEverythingFilled"></study-overlay>
        <v-row v-else>
            <v-col cols="12" md="8" lg="9">
                <check-in-table :assistantID="assistId"></check-in-table>
            </v-col>
            <v-col cols="12" md="4" lg="3">
                <check-in-infobar :assistantID="assistId"></check-in-infobar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Loading from "../../../components/Loading";
    import CheckInInfobar from "./CheckIn/CheckInInfobar";
    import CheckInTable from "./CheckIn/CheckInTable";
    import StudyOverlay from "../../../components/StudyOverlay";
    import {mapGetters, mapActions} from 'vuex';
    import moment from "moment";

    export default {
        name: "CheckIn",

        components: {
            CheckInTable,
            CheckInInfobar,
            Loading,
            StudyOverlay,
        },

        data() {
            let AssistantId = this.$route.params.assistantID;

            return {
                assistId: AssistantId,
                todayFormatted: moment().format('YYYY-MM-DD')
            }
        },

        async created() {
            await Promise.all([
                this.fetchStudyOpenHours(),
                this.fetchStudyProblemsItems(),
                this.fetchQueueItemsByWantedDayAndAssistant({wantedDay: this.todayFormatted, assistantId: this.assistId}),
                this.fetchQueueItemStatusesByWantedDayAndAssistant({wantedDay: this.todayFormatted, assistantId: this.assistId}),
                this.fetchActivePause(this.assistId),
            ])
        },

        computed: {
            ...mapGetters('queueItems', ['didLoadedQueueItems']),
            ...mapGetters('assistant', ['areAssistantsFilled']),
            ...mapGetters('queueItemStatuses', ['didLoadedQueueItemStatuses']),
            ...mapGetters('studyProblemsCategories', ['areStudyProblemsCategoryFilled']),
            ...mapGetters('closedHoursReason', ['areClosedHoursReasonsFilled']),
            ...mapGetters('StudyOpenHours', ['didLoadedStudyOpenHours']),
            ...mapGetters('AssistantPause', ['didLoadedAssistantActivePause']),
            ...mapGetters('studyProblemsItems', ['areStudyProblemsItemsFilled', 'didLoadedStudyProblemsItems']),
            ...mapGetters('studyFields', ['areStudyFieldsFilled']),

            didLoadedAll() {
                return this.didLoadedAssistantActivePause && this.didLoadedStudyOpenHours && this.didLoadedStudyProblemsItems && this.didLoadedQueueItemStatuses && this.didLoadedQueueItems;
            },

            isEverythingFilled() {
                return this.areClosedHoursReasonsFilled && this.areStudyProblemsItemsFilled && this.areStudyProblemsCategoryFilled && this.areAssistantsFilled && this.areStudyFieldsFilled;
            },
        },

        methods: {
            ...mapActions('queueItems', ['fetchQueueItemsByWantedDayAndAssistant']),
            ...mapActions('queueItemStatuses', ['fetchQueueItemStatusesByWantedDayAndAssistant']),
            ...mapActions('StudyOpenHours', ['fetchStudyOpenHours']),
            ...mapActions('AssistantPause', ['fetchActivePause']),
            ...mapActions('studyProblemsItems', ['fetchStudyProblemsItems']),
        }
    }
</script>

<style scoped>
</style>