<template>
    <div >
        <component :is="componentInstance" :id="String(this.id)"/>
    </div>
</template>

<script>
    export default {
        name: "MapDetail",

        data() {
            const id = this.$route.params.id;
            const type = this.$route.params.type;
            return {
              type: type,
              id: id,
            }
        },

        mounted(){

        },
        computed: {
          componentInstance() {
              if( this.type == "place") return () => import(`@/views/Map/PlaceDetail.vue`);
              if( this.type == "building") return () => import(`@/views/Map/BuildingDetail.vue`);
              return () => import(`@/components/NotFound.vue`);
            },
        },

    }
</script>

<style scoped>

</style>
