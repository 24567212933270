var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"70vw","persistent":"","scrollable":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{attrs:{"height":"70vh"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Označte Beacon který chcete připojit k lokací")])]),_c('v-card-text',[_c('v-data-table',{staticClass:"d-sm-block",attrs:{"id":"beaconDialog","single-select":"","show-select":"","headers":_vm.dataTableHeaders,"items":_vm.getBeacons,"no-results-text":"Žádné údaje","footer-props":{
					itemsPerPageOptions: [5],
				},"search":_vm.search},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Žádná dostupná data ")]},proxy:true},{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"color":"light-green darken-1","prepend-icon":"search","label":"Hledat","single-line":"","hide-details":"","clear-icon":"clear","clearable":true},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.purposeName",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPurposeName(item))+" ")]}},{key:"item.spot.placeName",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPlaceName(item))+" ")]}},{key:"item.spot.buildingName",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBuildingName(item))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancelSelect}},[_vm._v("Zrušit")]),_c('v-spacer'),_c('v-btn',{attrs:{"id":"confirmBeaconSelection","color":"primary","rounded":"","disabled":_vm.selected.length !== 1},on:{"click":_vm.confirmSelect}},[_vm._v("Potvrdit")]),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }