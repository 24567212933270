<template>
    <div class="d-flex">
        <v-text-field
                type="number"
                class="mr-2"
                color="light-green darken-1"
                v-model.trim.number="editedCoordinate.lat"
                label="Zem. šířka (lat)"
                dense
                :rules="rules"
                :disabled="disabled"
                v-on:blur="change"
                v-on:keyup.enter="change"
        ></v-text-field>
        <v-text-field
                type="number"
                class="mr-2"
                color="light-green darken-1"
                v-model.trim.number="editedCoordinate.lon"
                label="Zem. délka (lon)"
                dense
                :rules="rules"
                :disabled="disabled"
                v-on:blur="change"
                v-on:keyup.enter="change"
        ></v-text-field>
        <v-icon v-if="showDelete" color="red" @click="remove" :disabled="disabled" dense>close</v-icon>
    </div>
</template>

<script>
export default {
  name: "CoordinateField",

  props: {
      coordinate: {
          type: Object,
          required: true,
      },
      showDelete: {
          type: Boolean,
          default: false,
      },
      disabled: {
          type: Boolean,
          default: false,
      },
  },

  data() {
      return {
        rules: [
            value => !!value || 'Povinné',
        ],
        editedCoordinate: {...this.coordinate},
      };
  },

  watch: {
      coordinate(newValue) {
          this.editedCoordinate = {...newValue};
      }
  },

  methods: {
      change() {
          this.$emit('change', this.editedCoordinate);
      },
      remove() {
          this.$emit('remove');
      }
  }
};
</script>

<style scoped>
</style>
