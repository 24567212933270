<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-container v-else class="max_width_none" align-content-start>
        <v-layout row class="no_margin no_padding width_max">
            <v-flex>
                <div>
                    <BackButton :target="{name: 'StudyProblemsCategoryPortal'}">{{ $t('entities.studyProblems.portal_name') }}</BackButton>
                    <div v-if="!isNewStudyProblemsCategory">
                        <h2 v-if="!validateUserRoles" class="h2_header">{{ $t('entities.studyProblems.detail_edit') }}</h2>
                        <h2 v-else class="h2_header">{{ $t('entities.studyProblems.new_study') }}</h2>

                        <v-menu top :offset-x="true" :offset-y="true" v-if="!validateUserRoles && !studyProblemsCategoryModel.archived">
                            <template v-slot:activator="{ on }">
                                <v-btn class="new_button" icon v-on="on"><v-icon>more_vert</v-icon></v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="deleteStudyProblemsCategory">
                                    <v-list-item-title class="primary--text">{{ $t('entities.studyProblems.del_req') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <save-button v-if="!validateUserRoles" class="new_button" :disabled="buttonDisabledValidate" :is-saving="isSaving"
                                     @click="saveCategory"
                                     :label="$t('entities.save')"></save-button>
                    </div>
                    <div v-else>
                        <h2 class="h2_header">{{ $t('entities.studyProblems.new_study') }}</h2>
                        <save-button class="new_button"
                                    :disabled="!valid"
                                    :is-saving="isSaving"
                                    @click=" addNewStudyProblemsCategory"
                                    :label="$t('entities.save')">
                        </save-button>
                    </div>
                </div>
            </v-flex>
        </v-layout>

        <v-form ref="form" v-model="valid">

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">1. {{ $t('entities.studyProblems.category_name') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  :rules="nameRules"
                                  required
                                  v-model="studyProblemsCategoryModel.name"
                                  :label="$t('entities.studyProblems.type_category_name')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">2. {{ $t('entities.studyProblems.category_name_en') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  :rules="nameRules"
                                  required
                                  v-model="studyProblemsCategoryModel.name_en"
                                  :label="$t('entities.studyProblems.type_category_name_en')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">3. {{ $t('entities.studyProblems.category_code') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  :rules="codeRules"
                                  required
                                  v-model="studyProblemsCategoryModel.code"
                                  :label="$t('entities.studyProblems.type_category_code')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">4. {{ $t('entities.studyProblems.study_type') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-select
                            v-model="studyProblemsCategoryModel.type"
                            :items=typeSelect
                            :hide-details="true"
                            :rules="[v => !!v || $t('entities.required')]"
                            required
                            return-object
                            solo
                            :label="$t('entities.studyProblems.type_study_type')"
                            :disabled="validateUserRoles">
                    </v-select>
                </v-flex>
            </v-layout>
        </v-form>
        
    </v-container>
</template>

<script>
    import BackButton from "../../../../../components/layout/BackButton";
    import SaveButton from "../../../../../components/buttons/SaveButton";

    import { put, post, del } from '@/services/api';
    import Loading from "../../../../../components/Loading";

    import {mapGetters, mapActions} from 'vuex';

    export default {
        name: "StudyProblemsCategoryDetail",

        components: {
            BackButton,
            SaveButton,
            Loading,
        },

        data() {
            let Rid = this.$route.params.Rid;

            return {
                proRid: Rid,

                valid: false,
                isSaving: false,

                resp: null,

                codeRules: [
                    v => !!v || this.$t('entities.reasons.code_required'),
                    v => (v && v.length >= 5) || this.$t('entities.reasons.code_too_short'),
                ],

                nameRules: [
                    v => !!v || this.$t('entities.reasons.name_required'),
                    v => (v && v.length >= 5) || this.$t('entities.reasons.name_too_short'),
                ],

                studyProblemsCategoryModel: {
                    code: null,
                    name: null,
                    name_en: null,
                    type: null,
                },

                studyProblemsCategoryModelCopy: null,

                typeSelect: [
                    {"text": this.$t('entities.study_types.weekly_type'), "value": "weekly_type"},
                    {"text": this.$t('entities.study_types.daily_type'), "value": "daily_type"},
                ],

                headers: [
                    {
                        text: this.$t('headers.title'),
                        align: 'left',
                        value: 'name',
                        width: '17%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.title_en'),
                        align: 'left',
                        value: 'name_en',
                        width: '17%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.code'),
                        align: 'left',
                        value: 'code',
                        width: '15%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.approx_time'),
                        align: 'left',
                        value: 'default_time',
                        width: '15%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.action'),
                        align: 'left',
                        value: 'action',
                        width: '7%',
                        sortable: false,
                    },
                ],

                dataToDataTable: []
            }
        },

        async created() {
            await this.fetchStudyProblemsCategories();

            if (!this.isNewStudyProblemsCategory) {
                this.loadModel();
                this.createCopyOfOriginal();
            }
        },

        computed: {
            ...mapGetters('studyProblemsCategories', ['getStudyProblemsCategories', 'getStudyProblemsCategory', 'didLoadedStudyProblemsCategories']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),

            didLoadedAll() {
                return this.didLoadedStudyProblemsCategories;
            },

            isNewStudyProblemsCategory() {
                return this.proRid === 'new';
            },

            buttonDisabledValidate() {
                return !this.valid || this.compareOriginalAndStateNow();
            },

            validateUserRoles() {
                return this.getAssistantStorage.role === 'STUDY';
            },
        },

        methods: {
            ...mapActions('studyProblemsCategories', ['setStudyProblemsCategoriesStorage', 'fetchStudyProblemsCategories']),
            
            createCopyOfOriginal: function () {
                this.studyProblemsCategoryModelCopy = {...this.studyProblemsCategoryModel}
            },

            compareOriginalAndStateNow: function () {
                return JSON.stringify(this.studyProblemsCategoryModel) === JSON.stringify(this.studyProblemsCategoryModelCopy);
            },

            loadModel() {
                let studyItemCategory = this.getStudyProblemsCategory(this.proRid);

                this.studyProblemsCategoryModel.code = studyItemCategory.code;
                this.studyProblemsCategoryModel.name = studyItemCategory.name;
                this.studyProblemsCategoryModel.name_en = studyItemCategory.name_en;
                this.studyProblemsCategoryModel.type = this.setRightType(studyItemCategory.type);
                this.studyProblemsCategoryModel.archived = studyItemCategory.archived;
            },

            setRightType(type) {
                if (type === 'daily_type') {
                    return {"text": "Prezenční studium", "value": "daily_type"}
                } else {
                    return {"text": "Dálkové studium", "value": "weekly_type"}
                }
            },

            saveCategory: function () {
                if (this.$refs.form.validate()) {
                    this.isSaving = true;

                    put(`study_department/study_problems_category/${this.proRid}`, {
                        "name": this.studyProblemsCategoryModel.name,
                        "name_en": this.studyProblemsCategoryModel.name_en,
                        "code": this.studyProblemsCategoryModel.code,
                        "type": this.studyProblemsCategoryModel.type.value,
                        archived: this.studyProblemsCategoryModel.archived,
                    }).then(response => {
                        this.setStudyProblemsCategoriesStorage(response.data.items);
                        this.isSaving = false;
                        this.$router.push({name: "StudyProblemsCategoryPortal"})
                    }).catch(e => {
                        if (e.statusCode > 200 && e.statusCode < 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                        if (e.statusCode === 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                    });
                }
            },

            addNewStudyProblemsCategory: function () {
                if (this.$refs.form.validate()) {
                    this.isSaving = true;

                    post('study_department/study_problems_category/', {
                        "name": this.studyProblemsCategoryModel.name,
                        "name_en": this.studyProblemsCategoryModel.name_en,
                        "code": this.studyProblemsCategoryModel.code,
                        "type": this.studyProblemsCategoryModel.type.value,
                    }).then( response => {
                        this.setStudyProblemsCategoriesStorage(response.data.items);
                        this.isSaving = false;
                        this.$router.push({name: "StudyProblemsCategoryPortal"})
                    }).catch(e => {
                        if (e.statusCode > 200 && e.statusCode < 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                        if (e.statusCode === 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                    });
                }
            },

            deleteFromVuex(id) {
                let items = this.getStudyProblemsCategories;
                return items.filter(studyProblemsItem => studyProblemsItem.id !== id);
            },

            deleteStudyProblemsCategory: function () {
                if (confirm(this.$t('entities.confirm_deletion'))) {
                    this.setStudyProblemsCategoriesStorage(this.deleteFromVuex(this.proRid));
                    del('study_department/study_problems_category/' + this.proRid)
                        .then(() => {
                            this.$router.push({name: "StudyProblemsCategoryPortal"});
                        })
                        .catch(e => alert(e.message));
                }
            },
        }
    }
</script>

<style scoped>
    .new_button {
        float: right;
    }

    .max_width_none {
        max-width: none !important
    }

    .no_padding {
        padding: 0;
    }

    .no_margin {
        margin: 0;
    }

    .width_max {
        width: 100%;
    }

    .h2_header {
        margin-bottom: 1.5rem;
        width: 40%;
        float: left;
    }

    .text {
        font-size: large;
    }

    .no_margin {
        margin: 0;
    }

    .float_left {
        float: left;
        margin: 0;
        padding: 0;
    }

    .requirement_padding {
        padding-top: 1rem;
    }

</style>
