var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"locations"},[_c('v-data-table',{attrs:{"id":"locations","headers":_vm.getLocationHeaders,"items":_vm.locationList,"custom-sort":_vm.customSort,"show-select":"","no-data-text":"Žádné údaje","loading":_vm.loading && !_vm.didLoadLocations,"loading-text":"Načítání...","no-results-text":"Žádné údaje","sort-by":_vm.$query.sort,"sort-desc":_vm.$query.desc,"items-per-page":_vm.$query.rowsPerPage,"page":_vm.$query.page},on:{"update:sortBy":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.$query, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.$query, "desc", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.$query, "rowsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.$query, "rowsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.$query, "page", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteSingleDialog),callback:function ($$v) {_vm.deleteSingleDialog=$$v},expression:"deleteSingleDialog"}},[_c('v-card',[_c('v-card-title',[_c('h5',{staticClass:"text-h5 ma-auto"},[_vm._v("Opravdu chcete smazat vybrané lokace?")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteCancel}},[_vm._v("Zrušit")]),_c('v-btn',{attrs:{"dark":"","color":"red darken-1","text":""},on:{"click":_vm.deleteConfirm}},[_vm._v("Potvrdit")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.localizedAttributes.cz)?_c('div',[_c('b',{staticClass:"pointer light-green--text",on:{"click":function($event){return _vm.goToDetail(item.id)}}},[_vm._v(_vm._s(item.localizedAttributes.cz.name))])]):_vm._e()]}},{key:"item.points",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.testQuestion ? item.testQuestion.points : 0)+" ")]}},{key:"item.locationType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.locationType.name)+" ")]}},{key:"item.augmentedRealityModelAvailability",fn:function(ref){
var item = ref.item;
return _vm._l((Object.keys(item.augmentedRealityModelAvailability)),function(platform){return _c('span',{key:platform},[_c('v-icon',[_vm._v(_vm._s(_vm.platformIcon(platform)))])],1)})}},{key:"item.scanningType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.scanningType.name)+" ")]}},{key:"item.promptDescription",fn:function(ref){
var item = ref.item;
return [(item.localizedAttributes.cz)?_c('div',[_c('span',[_vm._v(_vm._s(item.localizedAttributes.cz.promptDescription))])]):_vm._e()]}},(_vm.selectedItems.length > 0)?{key:"footer",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"dark":"","color":"red darken-1","text":""},on:{"click":_vm.deleteItems}},[_vm._v("Smazat vybrané lokace")])],1)]},proxy:true}:null],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }