import translator from "./translator";
import {translations} from "./translations";

export const languages = Object.keys(translations);

export const getLocalizedLanguages = () => {
    let map = {};

    languages.forEach(l => {
        map[l] = translator.translate(l);
    });

    return map;
};

export const localizedAttributesFactory = (innerObject) => {
    return languages.reduce(
        (obj, lang) => (
            {
                ...obj,
                [lang]: {...innerObject}
            }
        ),
        {}
    )
};

export const languageColors = {
    'cz': '#b0e1ff',
    'en': '#ffc0b0',
};