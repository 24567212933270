<template>
    <v-card>
        <v-card-title>
            <h3 class="font-weight-medium mt-2">{{ title }}</h3>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="9" class="pl-0">
                        <v-text-field
                            data-cy="item-name"
                            color="light-green darken-1"
                            :label="label"
                            v-model.trim="text"
                            :hint="hint"
                            persistent-hint
                            :prefix="prefix"
                            :placeholder="placeholder"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red font-weight-medium" text @click="closeDialog()" data-cy="cancel-item">
                {{ $t('news.cancel') }}
            </v-btn>
            <v-btn color="primary font-weight-medium" text data-cy="save-item"
                   @click="saveItem(text, textDetail.index || 0 ,status)"
                   :disabled="!isFormValid">
                {{ $t('news.save') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "NewsfeedDialog",

    props: {
        titleNew: {
            type: String,
            required: true
        },
        titleEdit: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        onClose: {
            type: Function,
            required: true
        },
        onSave: {
            type: Function,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        textDetail: {
            type: Object,
            required: true
        },
        status: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            text: this.textDetail.text || '',
        }
    },

    methods: {
        saveItem(text, index, status) {
            if (this.isLink && !text.includes('http')) text = `https://${text}`
            this.onSave(text, index, status)
            this.closeDialog();
        },

        closeDialog() {
            this.text = '';
            this.onClose();
        }
    },

    computed: {
        isLink() {
            return this.type === 'link';
        },

        hint() {
            return this.isLink ? this.$t('news.hint_dialog') : ''
        },

        prefix() {
            return this.isLink ? 'https://' : null
        },

        placeholder() {
            return this.isLink ? this.$t('news.placeholder_dialog') : null
        },

        isDomain() {
            const regex = /^[^.]+(\.[^.]+)+$/;
            return regex.test(this.text);
        },

        isFormValid() {
            return this.isLink ? this.text && this.isDomain : this.text
        },

        title() {
            return this.status ? this.titleNew : this.titleEdit;
        }

    },

    watch: {
        textDetail() {
            this.text = this.textDetail.text || '';
        },
    }
}
</script>

<style scoped>

</style>
