import {get} from '@/services/api'

const state = {
    didLoadedPlaces: false,
    didLoadedPlace: false,
    iotPlaces: [],
    placeSoftware: [],
    placeComputers: [],
    softwareCategories: [],
    iotPlace: null,
};

const getters = {
    didLoadedPlaces: state => state.didLoadedPlaces,
    iotPlace: state => state.iotPlace,
    placeSoftware: state => state.placeSoftware,
    placeComputers: state => state.placeComputers,
    iotPlacesSorted: state => state.iotPlaces.sort((a, b) => (a.localizedAttributes.cz.name.localeCompare(b.localizedAttributes.cz.name))),
    didLoadedPlace: state => state.didLoadedPlace,
    categoryNameById: state => id => state.softwareCategories.find(category => category.id === id)
};

const actions = {
    async fetchIotPlaces({commit}) {
        commit('setDidLoadedPlaces', false);
        const response = await get(`iot/places` )
        const places = response.data.items
        commit('setIotPlaces', places)
        commit('setDidLoadedPlaces', true);
    },

    async fetchIotPlace({commit}, id) {
        commit('setDidLoadedPlace', false);
        const response = await get(`iot/places/${id}` )
        const place = response.data.content
        commit('setIotPlace', place)
        commit('setDidLoadedPlace', true);
    },

};

const mutations = {
    setDidLoadedPlaces: (state, value) => {
        state.didLoadedPlaces = value;
    },

    setDidLoadedPlace: (state, value) => {
        state.didLoadedPlace = value;
    },

    setIotPlaces: (state, iotPlaces) => (state.iotPlaces = iotPlaces),
    setIotPlace: (state, iotPlace) => (state.iotPlace = iotPlace),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}