<template>
  <div>
    <!-- Module header -->
    <BackButton :target="{name: 'unitDetail'}">Informační panely</BackButton>
    <ModuleHeader>
      <h2>Jak na správu chytrých informačních panelů?</h2>
    </ModuleHeader>
    <v-row class="text-left">
      <v-col cols="12" md="4">
        <v-card class="pa-4" elevation="0" outlined>
          <v-row>
            <v-col cols="auto">
              <AddDisplay id="add-display-image"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>1. Přidání obrazovky</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>Vytvořte obrazovku, na které budou zobrazována oznámení. Zvolte její název, nakonfigurujte zobrazování oznámení, případně přidružený maják.</p>
            </v-col>
          </v-row>
          <v-row class="justify-center" no-gutters>
            <v-col cols="auto">
              <LightButton :to="{name: 'displayDetail', params: {displayId: 'new'}}" id="add-display-link">Přidat obrazovku</LightButton>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="pa-4" elevation="0" outlined>
          <v-row>
            <v-col cols="2">
              <AddType id="define-type-image"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>2. Definice typu oznámení</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>Založte kategorii pro oznámení, která budete později definovat. Zvolte její vzhled, zobrazující displeje, viditelnost v&nbsp;mobilní aplikaci Moje&nbsp;MENDELU nebo službu pro generování obsahu. </p>
            </v-col>
          </v-row>
          <v-row class="justify-center" no-gutters>
            <v-col cols="auto">
              <LightButton :to="{name: 'announcementTypeDetail', params: {announcementTypeId: 'new'}}" id="define-type-link">Definovat typ oznámení</LightButton>
            </v-col>
           </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="pa-4" elevation="0" outlined>
          <v-row>
            <v-col cols="auto">
              <AddAnnouncement id="create-announcement-image"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>2. Tvorba oznámení</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>Definujte obsah oznámení odpovídající zvolenému typu oznámení. Ověřte vzhled možných variant rozložení obsahu a&nbsp;sestavte harmonogram zobrazování.</p>
            </v-col>
          </v-row>
          <v-row class="justify-center" no-gutters>
            <v-col cols="auto">
              <LightButton :to="{name: 'announcementDetail', params: {announcementId: 'new'}}" id="create-announcement-link">Vytvořit oznámení</LightButton>
            </v-col>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-8 mb-4 justify-center">
      <v-col cols="auto">
        <PrimaryButton :to="{name: 'displays'}" id="back-to-dashboard">Zpět na přehled</PrimaryButton>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import BackButton from "../../components/layout/BackButton";
  import PrimaryButton from "../../components/buttons/PrimaryButton";
  import LightButton from "../../components/buttons/LightButton";
  import ModuleHeader from "../../components/layout/ModuleHeader";
  import AddDisplay from '../../assets/displays/add_display.svg';
  import AddType from '../../assets/displays/define_type.svg';
  import AddAnnouncement from '../../assets/displays/create_announcement.svg';

  export default {
    name: "displaysGuide",
    components: {
      AddDisplay,
      AddType,
      AddAnnouncement,
      BackButton,
      ModuleHeader,
      LightButton,
      PrimaryButton
    },

    async created() {
    },

    data(){
      return {
      }
    },

    methods: {
    },

    computed: {
    },
  }
</script>

<style scoped>
</style>
