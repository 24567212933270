const state = {
  users: [
    {
      id: 0,
      name: "Jan",
      pass: "kocicka",
      type: "admin",
      permissions: [
        { name: "faq", rw: false },
        { name: "reports", rw: false },
        { name: "map", rw: false },
      ],
    }
  ]
};
const getters = {
  getUsers: state => state.users,
  getSingleUser: state => id => {
    return state.users.find(user => user.id == id)
  }
};
const actions = {

  //Fetch ALL
  /*async fetchUsers({ commit }) {

  },*/

  //Add ONE
  async addUser({ commit },  user) {
    return new Promise((resolve) => {
      commit('addNewUser', user);
      resolve();
    })
  },

  //Delete ONE
  async deleteUser({ commit },  id) {
    commit('filterUser', id);
  },

  //Update ONE
  async updateUser({ commit }, { id, item }) {
    commit('updateUser', id, item);
  },

};

const mutations = {
  filterUser: ( state, id ) => (state.users = state.users.filter(users => users.id !== id)),
  addNewUser: ( state, user ) => (state.users.push(user)),
  updateUser: ( state, id, item ) => {
    let index = state.users.filter(users => users.id == id)
    state.users[index] = item
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
