<template>

    <v-layout v-if="didLoadedAll === false" justify-center wrap>
        <Loading/>
    </v-layout>

    <v-container v-else class="main_layout">
        <v-layout row class="no_margin no_padding">
            <v-flex>
                <div>
                    <h2 v-if="dataToDataTable.length !== 0" data-cy="check-in-waiting-heading">{{ $t('checkIn.que_waiting') }} {{ dataToDataTable.length }} </h2>
                    <h2 v-else data-cy="check-in-noone-waiting-heading"> {{ $t('checkIn.no_waiting') }} </h2>
                </div>
            </v-flex>
        </v-layout>

        <v-layout v-if="dataToDataTable.length !== 0" row class="no_margin no_padding">
            <v-flex>
                <v-data-table
                    :headers="headers"
                    class="elevation-1"
                    :calculate-widths="true"
                    :items-per-page=10
                    :sort-by="['time']"
                    :sort-desc="[false]"
                    :items="dataToDataTable"
                    :loading="isLoading"
                >
                    <template v-slot:item.ticketNumber="{ item }">
                        <v-chip
                            class="ma-2"
                            :color="item.priority === true ? 'red lighten-1' : 'gray lighten-2'"
                            :text-color="item.priority === true ? 'white' : 'black'"
                        >
                            <v-icon left v-if="isCalledIn(item)">directions_run</v-icon>
                            {{ item.ticketNumber }}
                        </v-chip>
                    </template>

                    <template v-slot:item.priorityAction="{ item }">
                        <b v-if="isCalledIn(item)" class="orange--text">{{ $t('reservations.next_in_line') }}</b>
                        <v-btn v-else rounded
                               :id="`select-ticket-${item.ticketNumber}`"
                               class="button_call_in"
                               :disabled="setDisabledOnCallInButton"
                               @click="callInButton(item.queueItemId)">
                            Zvolit
                        </v-btn>
                    </template>

                </v-data-table>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import moment from 'moment';
import Loading from "../../../../components/Loading";

import {config} from '@/config';

export default {
    name: "CheckInTable",

    components: {
        Loading,
    },

    props: {
        assistantID: [String, Number],
    },

    data() {

        return {
            isLoading: false,
            connected: false,

            today: moment(),
            dataToDataTable: [],
            URL: config.serverUrl,
            currentComponent: 'CallInStart',
            interval: null,
            intervalStart: null,

            headers: [
                {text: this.$t('headers.ticket_id'), value: 'ticketNumber', sortable: false, width: "8%"},
                {text: this.$t('headers.request'), value: 'studyProblems', sortable: false, width: "40%"},
                {text: this.$t('headers.assistant'), value: 'assistant', sortable: false, width: "10%"},
                {text: this.$t('headers.time'), value: 'time', sortable: false, align: "center", width: "6%"},
                {text: this.$t('headers.priority_action'), value: 'priorityAction', align: "center", sortable: false, width: "10%"},
            ],
        }
    },

    created() {
        this.setItemsToTable(this.parseAffectedQueueItem());
        this.getOutFromFallback();

        setTimeout( () => {
            this.setItemsToTable(this.parseAffectedQueueItem());
            this.getOutFromFallback();
            this.$root.$emit('checkInInfobarStopLoading')
        }, 500)

        this.interval = setInterval(() => {
            this.setItemsToTable(this.parseAffectedQueueItem());
            this.getOutFromFallback();
        }, 3500)
    },

    mounted() {
        //from CheckInInfobar
        this.$root.$on('callInCurrentComponent', () => {
            this.currentComponent = this.getCallInCurrentComponent;
        });

        //from CallInStart
        this.$root.$on('callInStartItsOn', () => {
            if (this.dataToDataTable.length !== 0) {
                this.whoToCallFirst();

                // goes to CheckInInfobar
                this.$root.$emit('callInNextInLine');

            } else {
                // goes to CheckInInfobar, nobody is there, but we want to start
                this.$root.$emit('callInFallback');
            }
        });

        //from CallInNextInLine
        this.$root.$on('CallInNextInLineRemoveChosen', () => {
            this.findByQueueItemIdAndRemoveFromArray(this.getCallInItemIdToShow)
        });
    },

    destroyed() {
        clearInterval(this.interval)
        this.interval = null;
    },

    computed: {
        ...mapGetters('queueItems', ['didLoadedQueueItems', 'getQueueItem', 'getQueueItemByAssistantId']),
        ...mapGetters('assistant', ['getAssistant']),
        ...mapGetters('queueItemStatuses', ['didLoadedQueueItemStatuses', 'getQueueItemStatus']),
        ...mapGetters('StudyOpenHours', ['didLoadedStudyOpenHours', 'getStudyOpenHours']),
        ...mapGetters('studyProblemsItems', ['didLoadedStudyProblemsItems', 'getStudyProblemsItem']),
        ...mapGetters('pageStorage', ['getCallInItemIdToShow', 'getCallInCurrentComponent', 'getAssistantStorage', 'getAssistantIdStorage']),
        ...mapGetters('studyFields',['getStudyField' ]),

        didLoadedAll() {
            return this.didLoadedStudyOpenHours && this.didLoadedStudyProblemsItems && this.didLoadedQueueItemStatuses && this.didLoadedQueueItems;
        },

        getOpenTime() {
            let closedHours = this.getStudyOpenHours;
            let today = new Date(this.today);

            return closedHours.find(closedHours => closedHours.dayNumber === (today.getDay()).toString() && closedHours.purpose === "mobile").openTime;
        },

        getCloseTime() {
            let closedHours = this.getStudyOpenHours;
            let today = new Date(this.today);

            return closedHours.find(closedHours => closedHours.dayNumber === (today.getDay()).toString() && closedHours.purpose === "mobile").closeTime;
        },

        setDisabledOnCallInButton() {
            return this.currentComponent === 'CallInPause' || this.currentComponent === 'CallInStart' || this.currentComponent === 'CallInTurn' || this.currentComponent === 'CallInDidCome' || this.currentComponent === 'CallInDidNotCome';
        },

        validateUserRoles() {
            return this.$auth.getStudyAssistantAndRole()?.role === 'STUDY_SPY';
        },

    },

    methods: {
        ...mapActions('pageStorage', ['setCallInItemIdToShowStorage']),

        parseAffectedQueueItem() {
            let affected = []
            let items = this.getQueueItemByAssistantId(this.assistantID);

            for (let item of items) {
                let status = this.getQueueItemStatus(item.queueItemStatusId)

                if (status.wantedDay === this.today.format('YYYY-MM-DD')) {
                    affected.push(item);
                }
            }
            return affected;
        },

        setItemsToTable(items) {
            this.dataToDataTable = [];

            let queueItems = [];

            for (let item of items) {
                let itemQS = this.getQueueItemStatus(item.queueItemStatusId);

                if (itemQS.status.toLowerCase() === 'created' || itemQS.status.toLowerCase() === 'active' || itemQS.status.toLowerCase() === 'doing') {
                    queueItems.push(item);
                }
            }

            for (let queueItem of queueItems) {
                if (queueItem.assistantId === this.getAssistantIdStorage) {
                    let dataPrototype = {
                        queueItemId: null,
                        ticketNumber: null,
                        time: null,
                        studyProblems: null,
                        assistant: null,
                        id: Math.floor((Math.random() * 100000)),
                        studentXlogin: null,
                        priority: null,
                        status: null,
                    };

                    let queueItemStatus = this.getQueueItemStatus(queueItem.queueItemStatusId);
                    let studyProblems = "";

                    if (queueItem.studyProblemsItemsIds.length !== 0) {
                        for (let problem of queueItem.studyProblemsItemsIds) {
                            studyProblems += this.getStudyProblemsItem(problem).name + ' ';
                        }
                    } else {
                        studyProblems = this.getStudyField(queueItem.studyFieldId).name;
                    }

                    if (studyProblems.length > 90) {
                        studyProblems = studyProblems.slice(0, 87) + "..."
                    }

                    dataPrototype.queueItemId = queueItem.id;
                    dataPrototype.ticketNumber = queueItemStatus.ticketNumber;
                    dataPrototype.assistant = this.getAssistant(queueItem.assistantId).name
                    dataPrototype.time = moment(queueItemStatus.actualMeetTime, "HH:mm:ss").format("HH:mm");
                    dataPrototype.studyProblems = studyProblems;
                    dataPrototype.studentXlogin = queueItem.xname;
                    dataPrototype.priority = queueItemStatus.priority;
                    dataPrototype.imagesId = queueItem.imagesId;
                    dataPrototype.status = queueItemStatus.status;

                    this.dataToDataTable.push(dataPrototype);
                }
            }
        },

        findByQueueItemIdAndRemoveFromArray(index) {
            if (this.dataToDataTable.length > 0) {
                let indexToRemove = null;

                for (let item of this.dataToDataTable) {
                    if (item.queueItemId === index) {
                        indexToRemove = this.dataToDataTable.indexOf(item);
                    }
                }

                this.dataToDataTable.splice(indexToRemove, 1);
            }
        },

        whoToCallFirst() {
            let sorted = this._.sortBy(this.dataToDataTable, ['time'])

            this.setCallInItemIdToShowStorage(sorted[0].queueItemId);

        },

        stuckedItem() {
            let stuckItem = this.dataToDataTable.find(function (item) {
                return item.status === 'active' || item.status === 'doing';
            })

            if (stuckItem !== undefined) {
                return {
                    id: stuckItem.queueItemId,
                    status: stuckItem.status
                };
            } else {
                return null;
            }
        },

        getOutFromFallback() {
            let dummyItem = this.stuckedItem();

            if (dummyItem !== null) {
                if (dummyItem.status.toLowerCase() === 'active') {
                    this.setCallInItemIdToShowStorage(dummyItem.id);
                    this.findByQueueItemIdAndRemoveFromArray(dummyItem.id)
                    this.$root.$emit('callInNext');
                }

                if (dummyItem.status.toLowerCase() === 'doing') {
                    this.setCallInItemIdToShowStorage(dummyItem.id);
                    this.findByQueueItemIdAndRemoveFromArray(dummyItem.id)
                    this.$root.$emit('callInDidCome');
                }
            }

            if (this.currentComponent === 'CallInFallback' && this.dataToDataTable.length > 0) {
                this.whoToCallFirst();

                // goes to CheckInInfobar
                this.$root.$emit('callInNextInLine');
            }
        },

        callInButton(id) {
            this.setCallInItemIdToShowStorage(id);

            // goes to callInNextInLine
            this.$root.$emit('callInNextInLine');

        },

        isCalledIn(item) {
            return item.queueItemId === this.getCallInItemIdToShow;
        },
    },

    watch: {
        'dataToDataTable': {
            handler: function () {
                if (this.getCallInItemIdToShow !== null) {
                    let queueItem = this.getQueueItem(this.getCallInItemIdToShow);
                    if (queueItem !== undefined) {
                        let queueItemStatus = this.getQueueItemStatus(queueItem.queueItemStatusId);

                        if (this.getCallInItemIdToShow !== null && ((queueItemStatus.status === 'cancel') || queueItemStatus.status === 'abort')) {
                            if (this.dataToDataTable.length > 0) {
                                this.setCallInItemIdToShowStorage(this.dataToDataTable[0].queueItemId);
                                this.$root.$emit('callInNextInLineReload');
                            } else {
                                this.setCallInItemIdToShowStorage(null);
                            }
                        }
                    }
                }
            },
        }
    },
}
</script>

<style scoped>

.main_layout {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.no_padding {
    padding: 0;
}

.button_call_in {
    text-transform: capitalize;
    font-size: 120% !important;
    font-weight: 400;
    letter-spacing: normal;
    background: #EF6C00 !important;
    color: white !important;
    width: 75% !important;
}

.no_margin {
    margin: 0;
}

</style>
