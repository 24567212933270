import { get } from '@/services/api';

const state = {
  didLoadStops: false,
  stops: [],
};

const getters = {
  didLoadStops: state => state.didLoadStops,
  getStops: state => state.stops,
};

const actions = {
  async fetchStops({ commit }) {
    commit('startedDataDownload');
    await get('kordis/stops/parent')
    .then((response) => {
      commit('setStops', response.data.items);
      commit('finishedDataDownload');
    })
  }
};

const mutations = {
  startedDataDownload: state => state.didLoadStops = false,
  finishedDataDownload: state => state.didLoadStops = true,
  setStops: ( state, stops ) => (state.stops = stops),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
