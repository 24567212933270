import {get} from '@/services/api'

const state = {
    didLoadedChangedQueues: false,
    changedQueues: []
};
const getters = {
    didLoadedChangedQueues: state => state.didLoadedChangedQueues,

    getChangedQueues: state => state.changedQueues,

    getTypeChangedQueues: state => closedHoursId => {
        return state.changedQueues.filter(changedQueues => changedQueues.closedHoursId === closedHoursId);
    },
};
const actions = {

    async fetchChangedQueuesByWantedDay({commit}, wantedDay) {
        commit('setDidLoadedChangedQueues', false);
        const response = await get('study_department/assistant/changed_queue_items/?wantedDay=' + wantedDay);
        commit('setChangedQueueItems', response.data.items);
        commit('setDidLoadedChangedQueues', true);
    },

};

const mutations = {
    setDidLoadedChangedQueues: (state, value) => (state.didLoadedChangedQueues = value),
    setChangedQueueItems: (state, changedQueues) => (state.changedQueues = changedQueues),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
