import {del, get, post, put} from '@/services/api'
import Vue from "vue";

const state = {
    currentLocation: null,
    locations: [],
    newLocation: {
        id: null,
        augmentedRealityModel: null,
        localizedAttributes: {
            cz: {
                name: "",
                promptDescription: "",
                mainDescription: "",
                interests: ""
            },
            en: {
                name: "",
                promptDescription: "",
                mainDescription: "",
                interests: ""
            }
        },
        position: null,
        locationType: {
            id: 'ic_id_0'
        },
        scanningType: {
            id: 'ic_id_qr'
        },
        testQuestion: null,
        beacon: null,
        url: null,
    },
    newQuestion: {
        id: null,
        points: 10,
        hidden: false,
        type: 1,
        localizedAttributes: {
            cz: {
                text: "",
            },
            en: {
                text: "",
            }
        },
    },
    newAnswer: {
        id: null,
        questionId: null,
        correct: false,
        localizedAttributes: {
            cz: {
                text: "",
            },
            en: {
                text: "",
            }
        },
    },
    locationHeaders: [
        {
            text: 'Název lokace',
            align: 'start',
            sortable: true,
            value: 'name',
            width: "22%",
            filterable: true
        },
        {
            text: 'Typ lokace',
            align: 'start',
            sortable: true,
            value: 'locationType',
            width: "17%",
            filterable: true
        },
        {
            text: 'Počet bodů',
            align: 'start',
            sortable: true,
            value: 'points',
            width: "10%",
            filterable: true
        },
        {
            text: 'AR model',
            align: 'start',
            sortable: true,
            value: 'augmentedRealityModelAvailability',
            width: "10%",
            filterable: true
        },
        {
            text: 'Typ skenování',
            align: 'start',
            sortable: true,
            value: 'scanningType',
            width: "17%",
            filterable: true
        },
        {
            text: 'Krátký popis',
            align: 'start',
            sortable: true,
            value: 'promptDescription',
            width: "24%",
            filterable: true
        },
    ],
    locationImages: [],
    didLoadLocation: false,
    didLoadLocations: false,
    locationTypes: [],
    scanningTypes: [],
    didLoadOnboardingUtils: false,
};

const getters = {
    getLocation: state => state.currentLocation,
    newLocation: state => state.newLocation,
    locations: state => state.locations,
    locationById: state => id => {
        return state.locations.find(l => l.id === id)
    },
    didLoadLocation: state => state.didLoadLocation,
    didLoadLocations: state => state.didLoadLocations,
    getLocationHeaders: state => state.locationHeaders,
    getLocationImages: state => state.locationImages,
    getNewQuestion: state => state.newQuestion,
    getNewAnswer: state => state.newAnswer,
    getLocationTypes: state => state.locationTypes,
    getScanningTypes: state => state.scanningTypes,
};

const actions = {
    prepareNewLocation({ commit }) {
        commit('clearLocation');
        commit('setLocation', JSON.parse(JSON.stringify(state.newLocation)));
    },

    async fetchLocations({commit}) {
        commit('setDidLoadLocations', false);
        await get('onboarding/locations/').then((response) => {
            commit('setLocations', response.data.items);
            commit('setDidLoadLocations', true);
        });
    },

    async fetchLocationById({commit}, id) {
        commit('errors/clear', null, { root: true });
        commit('setDidLoadLocation', false);
        await Promise.all([
            get(`onboarding/locations/${id}`),
            get(`onboarding/locations/images/${id}`),
        ]).then((response) => {
            commit('setLocation', response[0].data.content);
            commit('setImages', response[1].data.items);
            commit('setDidLoadLocation', true);
        }).catch(e => {
            if (e.statusCode === 404) {
                commit('errors/objectNotFound', null, {root: true});
            } else {
                throw e;
            }
        })
    },

    async fetchNeededOnboardingUtils({commit}) {
        commit('setDidLoadOnboardingUtils', false);
        await Promise.all([
            get('onboarding/utils/loc_types'),
            get('onboarding/utils/scn_types'),
        ]).then((response) => {
            commit('setLocationTypes', response[0].data.items)
            commit('setScanningTypes', response[1].data.items)
            commit('setDidLoadOnboardingUtils', true)
        }).catch(e => {
            if (e.statusCode === 404) {
                commit('errors/objectNotFound', null, {root: true});
            } else {
                throw e;
            }
        })
    },

    async createLocation({commit}, {location}) {
        const response = await post(`onboarding/locations/`, location);
        const newLocation = response.data.content;
        commit('addLocation', newLocation);
        return newLocation.id;
    },
    async updateLocation({commit}, {location}) {
        const response = await put(`onboarding/locations/${location.id}`, location);
        commit('setLocation', response.data.content);
    },

    async deleteLocation({ commit },  id) {
        await del( `onboarding/locations/${id}`)
            .then(() => {
                commit('updateLocations', {id});
            });

    },

    async addImage({state, commit}, image) {
        if ( image.postfix !== '.scn' && image.postfix !== '.zip' ) {
            commit('addImage', image);
        } else {
            get(`onboarding/locations/${state.currentLocation.id}`).then((response) => {
                commit('setAugmentedReality', response.data.content);
            })
        }
    },
    async deleteImage({commit}, {locationId, id}) {
        await del(`onboarding/locations/images/${locationId}/${id}`)
        commit('removeImage', id);
    },

    async deleteFile({commit}, {locationId, platform}) {
        await del(`onboarding/locations/images/${locationId}?platform=${platform}`)
            .then(() => {
                get(`onboarding/locations/${locationId}`).then((response) => {
                    commit('setAugmentedReality', response.data.content);
                })
            })
    },
};

const mutations = {
    addLocation: (state, item) => (state.locations.push(item)),
    setLocation: (state, location) => (state.currentLocation = location),
    setLocations: (state, locations) => (state.locations = locations),
    setAugmentedReality: (state, location) => {
        state.currentLocation.augmentedRealityModel = location.augmentedRealityModel;
        state.currentLocation.augmentedRealityModelAvailability = location.augmentedRealityModelAvailability;
    },
    clearLocation: (state) => {
        state.currentLocation = null;
    },
    updateLocation: (state, item) => {
        const existing = state.locations.findIndex(l => l.id === item.id);
        if (existing > -1) {
            Vue.set(state.locations, existing, item);
        } else {
            state.locations.push(item);
        }
    },
    updateLocations: (state, {id}) => {
        let locations = [];
        state.locations.forEach( (item) => {
            if ( item.id !== id ){
                locations.push(item);
            }
        });
        state.locations = locations;
    },
    addImage: (state, image) => (state.locationImages.push(image)),
    setImages: (state, images) => (state.locationImages = images),
    removeImage: (state, id) => state.locationImages = state.locationImages.filter(i => i.id !== id),
    setDidLoadLocation: (state, value) => (state.didLoadLocation = value),
    setDidLoadLocations: (state, value) => state.didLoadLocations = value,
    setLocationTypes: (state, types) => state.locationTypes = types,
    setScanningTypes: (state, types) => state.scanningTypes = types,
    setDidLoadOnboardingUtils: (state, value) => state.didLoadOnboardingUtils = value,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
