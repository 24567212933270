import {get, post, del, put} from '@/services/api'
import Vue from "vue";
import {localizedAttributesFactory} from "../../../localization/languages";

const state = {
    didLoadTasks: false,
    tasks: [],
};

const getters = {
    didLoadTasks: state => state.didLoadTasks,
    tasks: state => state.tasks,
    taskById: state => id => state.tasks.find(t => t.id === id),
    tasksForQuestion: state => question =>
        state.tasks
            .concat()
            .sort((a, b) => a.id - b.id)
            .filter(t => t.questionID === question.id),
    prepareNewTask: () => () => ({
        questionID: null,
        localizedAttributes: localizedAttributesFactory({
            name: null
        })
    })
};

const actions = {
    async fetchTasksForQuestion({commit}, question) {
        commit('startedDataDownload');
        const requests = question.taskIDs.map(async taskId => {
            const response = await get(`faq/tasks/${taskId}`);
            commit('updateTask', response.data.content);
        });
        await Promise.all(requests);
        commit('finishedDataDownload');
    },

    async createTask({commit}, item) {
        const response = await post(`faq/tasks/`, item);
        commit('addTask', response.data.content);
    },

    async updateTask({commit}, item) {
        const response = await put(`faq/tasks/${item.id}`, item);
        commit('updateTask', response.data.content);
    },

    async deleteTask({commit}, id) {
        await del(`faq/tasks/${id}`);
        commit('filterTasks', id)
    },
};

const mutations = {
    startedDataDownload: state => state.didLoadTasks = false,
    finishedDataDownload: state => state.didLoadTasks = true,
    filterTasks: (state, id) => (state.tasks = state.tasks.filter(t => t.id !== id)),
    addTask: (state, item) => state.tasks.push(item),
    updateTask: (state, item) => {
        const existing = state.tasks.findIndex(q => q.id === item.id);
        if (existing > -1) {
            Vue.set(state.tasks, existing, item);
        } else {
            state.tasks.push(item);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
