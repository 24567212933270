import {get, put} from '@/services/api'
import Vue from 'vue';

const state = {
    didLoadedQueueItems: false,
    queueItems: []
};
const getters = {
    didLoadedQueueItems: state => state.didLoadedQueueItems,
    getQueueItems: state => state.queueItems,
    getQueueItem: state => id => {
        return state.queueItems.find(queueItem => queueItem.id === id);
    },

    getQueueItemByQueueItemStatusId: state => statusId => {
        return state.queueItems.find(queueItem => queueItem.queueItemStatusId === statusId);
    },

    getQueueItemByAssistantId: state => assistId => {
        return state.queueItems.filter(queueItem => queueItem.assistantId === assistId);
    }

};
const actions = {

    async fetchQueueItemById({commit}, id) {
        commit('setDidLoadedQueueItems', false);
        const response = await get('queue/demand/' + id);
        commit('updateQueueItem', response.data.content);
        commit('setDidLoadedQueueItems', true);
    },

    async fetchQueueItemsByWantedDay({commit}, wantedDay) {
        commit('setDidLoadedQueueItems', false);
        const response = await get('queue/demand/?wantedDay=' + wantedDay);
        commit('setQueueItems', response.data.items);
        commit('setDidLoadedQueueItems', true);
    },

    async fetchQueueItemsByWantedDayAndAssistant({commit}, {wantedDay, assistantId}) {
        commit('setDidLoadedQueueItems', false);
        const response = await get('queue/demand/?wantedDay=' + wantedDay + '&assistant=' + assistantId);
        commit('setQueueItems', response.data.items);
        commit('setDidLoadedQueueItems', true);
    },

    setQueueItemsStorage({commit}, items) {
        commit('setDidLoadedQueueItems', false);
        commit('setQueueItems', items);
        commit('setDidLoadedQueueItems', true);
    },

    pushToQueueItemsStorage({commit}, item) {
        commit('setDidLoadedQueueItems', false);
        commit('pushQueueItem', item);
        commit('setDidLoadedQueueItems', true);
    },

    deleteFromQueueItemsStorage({commit}, item) {
        commit('setDidLoadedQueueItems', false);
        commit('removeQueueItem', item);
        commit('setDidLoadedQueueItems', true);
    },

    updateQueueItemsStorage({commit}, item) {
        commit('setDidLoadedQueueItems', false);
        commit('updateQueueItem', item);
        commit('setDidLoadedQueueItems', true);
    },

    setDidLoadedQueueItemsStorage({commit}, val) {
        commit('setDidLoadedQueueItems', val);
    },

    async delegateQueueItem({commit, getters, rootGetters}, {queueItemId, assistantId}) {
        const item = getters.getQueueItem(queueItemId);
        const itemCopy = {...item};
        const status = rootGetters["queueItemStatuses/getQueueItemStatus"](item.queueItemStatusId);

        itemCopy.assistantId = assistantId;

        await put(`queue/demand/${itemCopy.id}`, {
            "bypass": "false",
            "assistantId": itemCopy.assistantId,
            "priority": true,
            "note": status.note,
            "wantedDay": status.wantedDay,
            "studyFieldId": status.studyFieldId,
            "timeFrom": status.actualMeetTime,
            "timeTo": status.actualMeetEnd,
            "studyProblemsItemsIds": itemCopy.studyProblemsItemsIds,
            "status": "created",
        });

        commit('updateQueueItem', itemCopy);
    },

};

const mutations = {
    setDidLoadedQueueItems: (state, value) => (state.didLoadedQueueItems = value),
    setQueueItems: (state, queueItems) => (state.queueItems = queueItems),
    updateQueueItem: (state, item) => {
        const index = state.queueItems.findIndex(i => i.id === item.id);
        if (index !== -1) {
            Vue.set(state.queueItems, index, item);
        } else {
            state.queueItems.push(item)
        }
    },
    pushQueueItem: (state, queueItem) => (state.queueItems.push(queueItem)),
    removeQueueItem: (state, queueItem) => {
        const index = state.queueItems.findIndex(i => i.id === queueItem.id);
        Vue.delete(state.queueItems, index)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
