<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-container fluid v-else>
        <BackButton :target="{name: 'Administration'}">{{ $t('entities.study_faculties') }}</BackButton>
        <module-header>
            <h2>{{ $t('entities.reasons.portal_name') }}</h2>
            <v-spacer/>
            <primary-button v-if="isStudyAdmin" @click="goToNew()" class="ml-5">+ {{ $t('entities.add') }}</primary-button>
        </module-header>

        <v-data-table
                :headers="customHeaders"
                :items="notArchivedReasons"
                :disable-pagination="true"
                :hide-default-footer="true"
                :no-data-text="$t('entities.reasons.no_reasons_set')"
                class="elevation-1"
                :sort-by.sync="$query.sort"
                :sort-desc.sync="$query.desc"

        >
            <template v-slot:item.action="{ item }">
                <v-icon class="mr-2" @click="goToDetail(item.id)">
                    edit
                </v-icon>
            </template>
        </v-data-table>

        <h5 class="mt-10 mb-3">Archivované důvody</h5>

        <v-data-table
                :headers="customHeaders"
                :items="archivedReasons"
                :sort-by="['id']"
                :sort-desc="[true]"
                :hide-default-header="true"
                :disable-pagination="true"
                :hide-default-footer="true"
                :no-data-text="$t('entities.reasons.no_reasons_set')"
                class="elevation-1 archived-table"
        >
            <template v-slot:item.action="{ item }">
                <v-icon class="mr-2" @click="goToDetail(item.id)">
                    edit
                </v-icon>
            </template>
        </v-data-table>
   
    </v-container>
</template>

<script>
    import BackButton from "../../../../../components/layout/BackButton";
    import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
    import Loading from "../../../../../components/Loading";
    import {mapGetters, mapActions} from 'vuex';
    import ModuleHeader from '../../../../../components/layout/ModuleHeader.vue';

    export default {
        name: "ReasonsPortal",
        components: {
            BackButton,
            PrimaryButton,
            Loading,
            ModuleHeader,
        },

        data() {
            return {

                headers: [
                    {
                        text: this.$t('headers.restrict_reason'),
                        align: 'left',
                        value: 'type',
                        width: '',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.restrict_name'),
                        align: 'left',
                        value: 'reason',
                        width: '',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.restrict_name_en'),
                        align: 'left',
                        value: 'reason_en',
                        width: '',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.action'),
                        align: 'left',
                        value: 'action',
                        width: '',
                        sortable: true,
                    },
                ],

                dataToDataTable: []
            }
        },

        created() {
            this.fetchClosedHoursReasons();
        },

        computed: {
            ...mapGetters('closedHoursReason', ['didLoadedClosedHoursReasons', 'getClosedHoursReasons']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),

            didLoadedAll() {
                return this.didLoadedClosedHoursReasons;
            },

            isStudyAdmin() {
                return this.$auth.getStudyAssistantAndRole()?.role === 'STUDY_ADMIN';
            },

            customHeaders() {
                return this.isStudyAdmin ? this.headers : this.headers.slice(0, -1);
            },

            reasons() {
                return this.getClosedHoursReasons.map(item => ({
                    reason: item.reason,
                    reason_en: item.reason_en,
                    type: this.returnCorectTraslationForType(item.type),
                    id: item.id,
                    archived: item.archived,
                }));
            },

            notArchivedReasons() {
                return this.reasons.filter(f => !f.archived);
            },

            archivedReasons() {
                return this.reasons.filter(f => f.archived);
            },
        },

        methods: {
            ...mapActions('closedHoursReason', ['fetchClosedHoursReasons']),

            returnCorectTraslationForType(type) {
                switch (type) {
                    case 'person':
                        return this.$t('entities.reasons.person');
                    case 'department':
                        return this.$t('entities.reasons.department');
                }
            },

            goToNew() {
                this.$router.push({name: 'ReasonsDetail', params: {Rid: "new"}});
            },

            goToDetail(Rid) {
                this.$router.push({name: 'ReasonsDetail', params: {Rid: Rid}});

            }
        }
    }
</script>

<style scoped>

.archived-table >>> td:not(:last-child) {
    color: rgb(167, 167, 167);
}

</style>