<template>
    <Loading v-if="didLoadedAllForForm === false"></Loading>

    <v-container v-else fluid style="margin: 0">
        <v-layout row class="no_margin div_under_div">
            <v-flex lg12>
                <v-col class="float_left" :lg="9">
                    <h2 class="header_div">
                        {{ $t('reservations.restrict_calendar') }}
                    </h2>
                </v-col>
                <v-col class="float_left" :lg="3">
                    <div class="button_group">
                        <v-btn @click="goBack(assistantId)" class="close_button mb-3 button margin_left">{{ $t('reservations.close') }}</v-btn>
                        <v-btn
                               id="save-btn"
                               @click="sendNew()"
                               :disabled="!valid"
                               color="primary"
                               class="mb-3 button margin_left">{{ $t('reservations.save') }}
                        </v-btn>
                    </div>
                </v-col>
            </v-flex>
        </v-layout>

        <v-form ref="form" v-model="valid">
            <!--Agenda-->
            <v-layout row class="no_margin">
                <v-flex xs4 class="v_flex_span_date float_left">
                    <span class="text">1. {{ $t('reservations.restrict_agend') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-radio-group :disabled="returnAssistantRole === 'STUDY'" class="no_margin_no_padding " v-model="radioGroupWho" row>
                        <v-radio color="#000" :label="$t('reservations.person')" value="person"></v-radio>
                        <v-radio color="#000" :label="$t('reservations.stud_dep_pef')" value="department"></v-radio>
                    </v-radio-group>
                    <v-select
                            id="restriction-person"
                            v-if="radioGroupWho === 'person'"
                            v-model="personSelect"
                            :items="getAssistantsForDropdown"
                            :hide-details="true"
                            :rules="[v => !!v || 'Item is required']"
                            required
                            solo
                    ></v-select>
                </v-flex>
            </v-layout>

            <!--Duvod Restrikce-->
            <v-layout row class="no_margin top">
                <v-flex xs4 class="float_left">
                    <span class="text">2. {{ $t('reservations.restrict_reason') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-select
                            id="restriction-reason"
                            v-model="restrictionSelect"
                            :items=getReasonsAccordingToAgenda
                            :hide-details="true"
                            :rules="[v => !!v || $t('reservations.item_required')]"
                            :item-text="restrictionText"
                            required
                            return-object
                            solo
                    ></v-select>
                </v-flex>
            </v-layout>

            <!--Datum Restrikce-->
            <v-layout row class="no_margin top">
                <v-flex xs4 class="float_left">
                    <span class="text">3. {{ $t('reservations.restrict_date') }}</span>
                </v-flex>

                <!--Čas od-->
                <v-row xs4 class="float_left">
                    <v-flex xs2>
                        <v-menu
                                v-model="menu_calendar_from"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="computedDateFormattedFrom"
                                        v-on="on"
                                        class="no_margin_no_padding"
                                        solo
                                        aria-required="true"
                                        :rules="dateRules"
                                        :hide-details="true"
                                ></v-text-field>
                            </template>
                            <v-date-picker locale="cs"
                                           v-model="date_from"
                                           no-title
                                           :min="today"
                                           :allowed-dates="allowedDates"
                                           @input="menu_calendar_from = false"></v-date-picker>
                        </v-menu>
                    </v-flex>

                    <!--                    &lt;!&ndash;Text do&ndash;&gt;-->
                    <!--                    <v-flex row xs1 align-content-center justify-center class="overdrive">-->
                    <!--                        <span class="doSpan"> do </span>-->
                    <!--                    </v-flex>-->

                    <!--                    &lt;!&ndash;Čas do&ndash;&gt;-->
                    <!--                    <v-flex xs2 class="margin_left">-->
                    <!--                        <v-menu-->
                    <!--                                v-model="menu_calendar_to"-->
                    <!--                                :close-on-content-click="false"-->
                    <!--                                :nudge-right="40"-->
                    <!--                                transition="scale-transition"-->
                    <!--                                offset-y-->
                    <!--                                min-width="290px">-->
                    <!--                            <template v-slot:activator="{ on }">-->
                    <!--                                <v-text-field-->
                    <!--                                        v-model="computedDateFormattedTo"-->
                    <!--                                        readonly-->
                    <!--                                        v-on="on"-->
                    <!--                                        class="no_margin_no_padding"-->
                    <!--                                        solo-->
                    <!--                                        aria-required="true"-->
                    <!--                                        :rules="dateRules"-->
                    <!--                                        :hide-details="true"-->
                    <!--                                ></v-text-field>-->
                    <!--                            </template>-->
                    <!--                            <v-date-picker locale="cs"-->
                    <!--                                           v-model="date_to"-->
                    <!--                                           no-title-->
                    <!--                                           :min="today"-->
                    <!--                                           @input="menu_calendar_to = false"></v-date-picker>-->
                    <!--                        </v-menu>-->
                    <!--                    </v-flex>-->
                </v-row>
            </v-layout>

            <!--Long Term action option-->
            <v-layout row class="no_margin top">
                <v-flex xs4>
                    <span class="text">4. {{ $t('reservations.restrict_time') }} </span>
                </v-flex>

                <v-flex>
                    <v-flex xs5 class="float_left">
                        <v-radio-group class="no_margin_no_padding " v-model="radioGroupTimeRestriction" column>
                            <v-radio color="#000" :label="$t('reservations.whole_day') +`(${getOpenTime} - ${getCloseTime})`" value="all"></v-radio>
                            <v-radio color="#000" :label="$t('reservations.specific_timespan')" value="personal"></v-radio>
                        </v-radio-group>

                        <!--Čas od-->
                        <v-flex row
                                v-if="radioGroupTimeRestriction === 'personal'"
                                class="no_margin_no_padding bottom">
                            <v-flex xs2>
                                <v-menu
                                        ref="menu_time_from"
                                        v-model="menu_time_from2"
                                        :close-on-content-click="false"
                                        :nudge-right="65"
                                        :return-value.sync="time_from"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                v-model="time_from"
                                                :label="$t('reservations.time_from')"
                                                v-on="on"
                                                class="text_align"
                                                readonly
                                                aria-required="true"
                                                :rules="timeRules"
                                                :hide-details="true"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                            v-if="menu_time_from2"
                                            v-model="time_from"
                                            full-width
                                            format="24hr"
                                            scrollable
                                            header-color="rgb(118, 190, 26)"
                                            @click:minute="saveTimeFrom(time_from)"
                                            :min="getOpenTime"
                                            :max="time_to || getCloseTime"
                                    ></v-time-picker>
                                </v-menu>
                            </v-flex>

                            <!--Text do-->
                            <v-flex row xs2 align-content-center align-center justify-center class="overdrive">
                                <span class="doSpan2">{{ $t('reservations.untill') }}</span>
                            </v-flex>

                            <!--Cas do-->
                            <v-flex xs2 class="margin_left">
                                <v-menu
                                        ref="menu_time_to"
                                        v-model="menu_time_to2"
                                        :close-on-content-click="false"
                                        :nudge-right="65"
                                        :return-value.sync="time_to"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                v-model="time_to"
                                                :label="$t('reservations.time_to')"
                                                v-on="on"
                                                readonly
                                                class="text_align"
                                                aria-required="true"
                                                :rules="timeRules"
                                                :hide-details="true"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                            v-if="menu_time_to2"
                                            v-model="time_to"
                                            full-width
                                            format="24hr"
                                            scrollable
                                            header-color="rgb(118, 190, 26)"
                                            @click:minute="saveTimeTo(time_to)"
                                            :min="time_from || getOpenTime"
                                            :max="getCloseTime"
                                    ></v-time-picker>
                                </v-menu>
                            </v-flex>
                        </v-flex>
                    </v-flex>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">5. {{ $t('reservations.longterm_action') }} </span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-select
                            id="restriction-long-term-action"
                            v-model="longTermAction.option"
                            :items="options"
                            :rules="[v => !!v || $t('entities.required')]"
                            required
                            solo
                            item-value="value"
                            return-object
                            :disabled="radioGroupWho === 'department'"
                    ></v-select>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin" v-if="returnLongTermActionOption() === 'delegate'">
                <v-flex xs4 class="float_left">
                    <span class="text">6. {{ $t('reservations.delegation') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-select
                            id="restriction-long-term-delegate"
                            v-model="longTermAction.delegatedAssistant"
                            :items="getValidAssistant(computedDateFormattedFrom, time_from, time_to)"
                            :rules="[v => !!v || $t('entities.required')]"
                            required
                            solo
                            item-value="value"
                            return-object
                            v-if="radioGroupWho === 'person'"
                    ></v-select>
                </v-flex>
            </v-layout>

            <!-- Affected items -->

            <v-layout row class="no_margin">
                <v-flex>
                    <span class="text">{{returnCorrectNumbering(6)}}. {{ $t('reservations.restrict_meetings') }} ({{numberOfItems}})</span>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin">
                <v-flex v-if="numberOfItems !== 0">
                    <v-data-table
                            :headers="headers"
                            :items="dataToDataTable"
                            class="elevation-1"
                            :calculate-widths="true"
                            :items-per-page=10
                            :loading="isLoading"
                            :sort-by="['ticketNumber']"
                            :sort-desc="[false]"
                    >

                        <template v-slot:item.option="{ item }">
                            <v-select
                                    :id="`ticket-${item.ticketNumber}-action`"
                                    v-model="item.option"
                                    :items="options"
                                    :rules="[v => !!v || $t('reservations.item_required')]"
                                    required
                                    :disabled="disableOptionForSomeoneElse(getValidAssistant(item.wantedDay, item.openTime, item.closeTime))"
                            >
                            </v-select>
                        </template>

                        <template v-slot:item.delegate="{ item }">
                            <v-select
                                    :id="`ticket-${item.ticketNumber}-delegate`"
                                    v-model="item.assistantId"
                                    :items="getValidAssistant(item.wantedDay, item.openTime, item.closeTime)"
                                    :rules="conditionalRuleForDelegate(item.option)"
                                    required
                                    :disabled="setDisableIfCancel(item.option, item.id, getValidAssistant(item.wantedDay, item.openTime, item.closeTime))"
                            >
                            </v-select>
                        </template>
                        <!--                        <template v-slot:item.otherTime="{ item }">-->
                        <!--                            <v-select-->
                        <!--                                    v-model="item.otherTime"-->
                        <!--                                    :items="getTimeRangeForRestriction(time_from, time_to, item.otherTimeOriginal)"-->
                        <!--                                    :rules="conditionalRuleForDelegate(item.option)"-->
                        <!--                                    required-->
                        <!--                                    :disabled="setDisableIfCancel(item.option, item.id)"-->
                        <!--                            >-->
                        <!--                            </v-select>-->
                        <!--                        </template>-->
                    </v-data-table>
                </v-flex>

                <v-flex v-else>
                    <span>{{ $t('reservations.no_restrict_meetings') }}</span>
                </v-flex>
            </v-layout>

        </v-form>
    </v-container>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import moment from 'moment';
    import { post } from '@/services/api'
    import Loading from "../../../../../components/Loading";

    export default {
        components: {Loading},

        name: "NewRestriction",

        props: {
            selectedDay: null,
            assistantId: null,
        },

        data() {
            return {
                radioGroupWho: 'person',
                radioGroupTimeRestriction: 'all',

                personSelect: parseInt(this.assistantId),
                restrictionSelect: null,

                date_from: this.selectedDay,
                date_to: null,

                menu_calendar_from: false,
                menu_calendar_to: false,

                menu_time_from: false,
                menu_time_from2: false,

                menu_time_to: false,
                menu_time_to2: false,

                time_from: null,
                time_to: null,

                all_day_from: null,
                all_day_to: null,

                valid: true,
                form: null,

                numberOfItems: 0,

                dateRules: [
                    v => !!v || this.$t('reservations.date_required'),
                ],

                timeRules: [
                    v => !!v || this.$t('reservations.time_required'),
                ],

                today: moment().toISOString(),

                headers: [
                    {text: this.$t('headers.ticket_id'), value: 'ticketNumber', sortable: false, width: "8%"},
                    {text: this.$t('headers.request'), value: 'studyProblems', sortable: false, width: "5%"},
                    {text: this.$t('headers.date'), value: 'wantedDay', sortable: false},
                    {text: this.$t('headers.time'), value: 'time', sortable: false},
                    {text: this.$t('headers.student'), value: 'studentName', sortable: false, width: "15%"},
                    {text: this.$t('headers.options'), value: 'option', sortable: false, width: "13%"},
                    {text: this.$t('headers.delegate'), value: 'delegate', sortable: false, width: "23%"},
                    //  {text: this.$t('headers.sub_time'), value: 'otherTime', sortable: false, width: "13%"},
                ],

                options: [
                    {text: this.$t('reservations.cancel'), value: 'cancel'},
                    {text: this.$t('reservations.delegate'), value: 'delegate'},
                ],

                longTermAction: {
                    option: {text: this.$t('reservations.cancel'), value: 'cancel'},
                    delegatedAssistant: null,
                },

                didCreate: true,

                dataToDataTable: [],

                affected: [],
                oldAffected: [],
                interval: null,
                isLoading: false,
            }
        },

        destroyed() {
            clearInterval(this.interval);
        },

        async created() {
            this.setSelectedDayFromInfobar();

            await Promise.all([
                this.fetchStudyOpenHours(),
                this.fetchClosedHoursReasons(),
                this.fetchAssistants(),
                this.fetchStudyFields(),
                this.fetchStudyProblemsItems(),
                this.fetchStudyProblemsCategories(),
                this.fetchClosedHours(),
            ])

            this.setTimes();

            await this.fetchConcernedReservations();

            this.interval = setInterval(() =>
                    this.fetchConcernedReservations()
            , 2000)
        },

        computed: {
            ...mapGetters('assistant', ['getAssistantsForDropdown', 'getAssistants', 'didLoadedAssistants']),
            ...mapGetters('changedQueues', ['getChangedQueues', 'didLoadedChangedQueues']),
            ...mapGetters('queueItemStatuses', ['getQueueItemStatus', 'didLoadedQueueItemStatuses']),
            ...mapGetters('closedHours', ['getTypeClosedHoursByDate', 'didLoadedClosedHours']),
            ...mapGetters('queueItems', ['getQueueItem', 'didLoadedQueueItems']),
            ...mapGetters('studyProblemsCategories', ['didLoadedStudyProblemsCategories']),
            ...mapGetters('closedHoursReason', ['getClosedHoursReasons', 'didLoadedClosedHoursReasons']),
            ...mapGetters('StudyOpenHours', ['getStudyOpenHour', 'didLoadedStudyOpenHours', 'getStudyOpenHours']),
            ...mapGetters('studyProblemsItems', ['getStudyProblemsItem', 'didLoadedStudyProblemsItems']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),
            ...mapGetters('studyFields', ['getStudyField', 'didLoadedStudyFields' ]),

            reasons() {
                return this.getClosedHoursReasons.map(item => ({
                  id: item.id,
                  name: item.reason,
                  type: item.type,
                }))
            },

            canFetchAffectedItems() {
                return (
                    this.didLoadedAllForAffectedItems &&
                    this.radioGroupWho !== null &&
                    this.date_from !== null &&
                    this.date_to !== null &&
                    this.timeFrom !== null &&
                    this.timeTo !== null &&
                    (this.personSelect !== null || this.radioGroupWho === "department")
                )
            },

            getReasonsAccordingToAgenda() {
                let data = null;

                if (this.radioGroupWho === "person") {
                    data = this.reasons.filter(obj => obj.type === "person");
                }

                if (this.radioGroupWho === "department") {
                    data = this.reasons.filter(obj => obj.type === "department");
                }

                return data;
            },

            returnAssistantRole() {
                return this.getAssistantStorage.role;
            },

            getRightRestrictionTimeFrom() {
                if (this.radioGroupTimeRestriction === "all") {
                    return this.all_day_from;
                } else {
                    return this.time_from
                }
            },

            getRightRestrictionTimeTo() {
                if (this.radioGroupTimeRestriction === "all") {
                    return this.all_day_to;
                } else {
                    return this.time_to;
                }
            },

            getOpenTime() {
                let closedHours = this.getStudyOpenHours;
                let today = new Date(this.date_from);
                //TODO let today = new Date(this.selectedDay);

                return closedHours.find(closedHours => closedHours.dayNumber === (today.getDay()).toString() && closedHours.purpose === "mobile").openTime;
            },

            getCloseTime() {
                let closedHours = this.getStudyOpenHours;
                let today = new Date(this.date_from);
                //TODO let today = new Date(this.selectedDay);

                return closedHours.find(closedHours => closedHours.dayNumber === (today.getDay()).toString() && closedHours.purpose === "mobile").closeTime;
            },

            computedDateFormattedFrom() {
                return this.formatDate(this.date_from);
            },

            computedDateFormattedTo() {
                return this.formatDate(this.date_to);
            },

            didLoadedAllForForm() {
                return this.didLoadedClosedHoursReasons && this.didLoadedStudyOpenHours && this.didLoadedAssistants && this.didLoadedStudyFields && this.didLoadedStudyProblemsItems && this.didLoadedStudyProblemsCategories
                    && this.didLoadedClosedHours;
            },

            didLoadedAllForAffectedItems() {
                return this.didLoadedAllForForm && this.didLoadedQueueItemStatuses && this.didLoadedQueueItems && this.didLoadedChangedQueues;
            },
        },

        methods: {
            ...mapActions('assistant', ['fetchAssistants']),
            ...mapActions('changedQueues', ['fetchChangedQueuesByWantedDay']),
            ...mapActions('queueItemStatuses', ['fetchQueueItemStatusesByWantedDay']),
            ...mapActions('queueItems', ['fetchQueueItemsByWantedDay']),
            ...mapActions('studyProblemsCategories', ['fetchStudyProblemsCategories']),
            ...mapActions('closedHoursReason', ['fetchClosedHoursReasons']),
            ...mapActions('closedHours', ['fetchClosedHours']),
            ...mapActions('StudyOpenHours', ['fetchStudyOpenHours']),
            ...mapActions('studyProblemsItems', ['fetchStudyProblemsItems']),
            ...mapActions('studyFields', ['fetchStudyFields']),

            restrictionText: item => item.name,

            async fetchQueueItemsForSelectedDay() {
                await Promise.all([
                    this.fetchQueueItemsByWantedDay(this.date_from),
                    this.fetchQueueItemStatusesByWantedDay(this.date_from),
                    this.fetchChangedQueuesByWantedDay(this.date_from),
                ])
            },

            getValidAssistant(wantedDay, startTime, endTime) {
                let restrictions = this.getTypeClosedHoursByDate(moment(wantedDay, "DD.MM.YYYY").format("YYYY-MM-DD"))
                let assistants = this.getAssistantsForDropdown.filter(ass => ass.value !== this.personSelect);

                let toDelete = [];

                if (restrictions.length !== 0) {
                    for (let restriction of restrictions) {
                        if (moment(restriction.timeFrom, "HH:mm").isSameOrBefore(moment(startTime, "HH:mm")) && moment(restriction.timeTo, "HH:mm").isSameOrAfter(moment(endTime, "HH:mm"))) {
                            toDelete.push(restriction.assistantId)
                        }
                    }
                }

                return assistants.filter(ass => !toDelete.includes(ass.value))
            },

            returnLongTermActionOption() {
                return this.longTermAction.option.value;
            },

            returnCorrectNumbering(number) {
                if (this.returnLongTermActionOption() === "cancel") {
                    return number;
                } else {
                    return number + 1;
                }
            },

            setTimes() {
                this.time_from = this.getOpenTime;
                this.time_to = this.getCloseTime;
                this.all_day_from = this.getOpenTime;
                this.all_day_to = this.getCloseTime;
            },

            formatDate(date) {
                if (!date) return null;

                const [year, month, day] = date.split('-');
                return `${day}.${month}.${year}`;
            },

            setSelectedDayFromInfobar() {
                this.date_from = this.selectedDay.get("year").toString() + '-'
                    + this.addZeroBefore((this.selectedDay.get("month") + 1).toString()) + '-'
                    + this.setDay(this.selectedDay.get("date"));

                this.date_to = this.selectedDay.get("year").toString() + '-'
                    + this.addZeroBefore((this.selectedDay.get("month") + 1).toString()) + '-'
                    + this.setDay(this.selectedDay.get("date")).toString();
            },

            setDay(day) {
                if (parseInt(day) < 10) {
                    return ('0' + day).toString();
                } else {
                    return (day).toString();
                }
            },

            addZeroBefore(dayMonth) {
                if (dayMonth.length === 1) {
                    dayMonth = '0' + dayMonth;
                }
                return dayMonth
            },

            goBack(assistantId) {
                this.$router.push({name: "CalendarDetail", params: {assistantID: assistantId}});
            },

            async fetchConcernedReservations() {
                if (!this.canFetchAffectedItems) return;
                this.isLoading = true;
                await this.fetchQueueItemsForSelectedDay();
                await post('study_department/assistant/closed_hours/affected/', {
                    'type': this.radioGroupWho,
                    'assistantId': this.personSelect,
                    'dateFrom': this.date_from,
                    'dateTo': this.date_from,
                    // TODO 'dateTo': this.date_to,
                    'timeFrom': this.getRightRestrictionTimeFrom,
                    'timeTo': this.getRightRestrictionTimeTo,
                }).then((response) => {
                    this.affected = response.data.items;
                    this.updateAffectedItems();
                    this.isLoading = false;
                }).catch(e => alert(e.message));
            },

            addNewRestrictionToBackend: function () {
                this.didCreate = false;

                post('study_department/assistant/closed_hours/', {
                    "assistantId": this.personSelect,
                    'dateFrom': this.date_from,
                    'dateTo': this.date_from,
                    //TODO 'dateTo': this.date_to,
                    'timeFrom': this.getRightRestrictionTimeFrom,
                    'timeTo': this.getRightRestrictionTimeTo,
                    'closedHoursReasonId': this.restrictionSelect.id,
                    'toChange': this.dataToDataTable,
                    'longTermOption': this.longTermAction.option.value,
                    'longTermAssistantId': this.returnDelegatedAssistant(),
                }).then((response) => {
                    if (response !== null) {
                        this.didCreate = true;
                    }
                }).catch(e => alert(e.message));
            },

            returnDelegatedAssistant() {
              if (this.longTermAction.delegatedAssistant !== null) {
                  return this.longTermAction.delegatedAssistant.value;
              }  else {
                  return null;
              }
            },

            sendNew() {
                this.addNewRestrictionToBackend();
                this.goBack(this.assistantId);
            },

            setDisableIfCancel(item, itemId, assistants) {
                if ((item === 'cancel' && this.dataToDataTable.length > 0) || this.radioGroupWho === "department" || assistants.length === 0) {
                    for (var itm of this.dataToDataTable) {
                        if (itm.id === itemId) {
                            itm.assistantId = null;
                            itm.otherTime = itm.otherTimeOriginal;
                        }
                    }
                    return true;
                } else {
                    return false;
                }
            },

            disableOptionForSomeoneElse(assistants) {
                return this.radioGroupWho === 'department' || assistants.length === 0;
            },

            getTimeRangeForRestriction(startTime, endTime, toAdd) {
                let timerange = [toAdd];
                let step = 30; //minutes
                let startHours = parseInt(startTime.substring(0, 2));
                let endHours = parseInt(endTime.substring(0, 2));

                let minDiff = 2;

                for (let hour = startHours; hour < endHours; hour++) {
                    for (let min = 0; min < minDiff; min++) {
                        timerange.push(this.addZeroBefore(hour.toString()) + ":" + this.addZeroBefore((min * step).toString()));
                    }
                }

                return timerange.sort(function (a, b) {
                    return a > b;
                });
            },


            saveTimeFrom(time_from) {
                this.$refs.menu_time_from.save(time_from);
            },

            saveTimeTo(time_to) {
                this.$refs.menu_time_to.save(time_to);
            },

            resetTimeFromTo() {
                let realTimeFrom = this.getOpenTime;
                let realTimeTo = this.getCloseTime;

                this.time_from = realTimeFrom;
                this.time_to = realTimeTo;
            },

            resetRestrictionSelect() {
                this.restrictionSelect = null;
            },

            resetLongTermAction() {
                this.longTermAction = {
                    option: {text: this.$t('reservations.cancel'), value: 'cancel'},
                    delegatedAssistant: null,
                };
            },

            conditionalRuleForDelegate(item) {
                if (item === 'delegate') {
                    return [v => !!v || this.$t('reservations.item_required')];
                } else {
                    return [];
                }
            },

            setItemsToTable(items, rewrite) {

                // rewrite when created, don't rewrite in interval
                if (rewrite) {
                    this.dataToDataTable = [];
                }

                let queueItems = [];
                let changedQueuesItemStatusIds = [];
                let notChangedQueueItems = [];
                let changedQueues = this.getChangedQueues;

                for (let item of items) {
                    queueItems.push(this.getQueueItem(item));
                }

                for (let itemCq of changedQueues) {
                    changedQueuesItemStatusIds.push(itemCq.queueItemStatusId);
                }

                for (let item of queueItems) {
                    if (!changedQueuesItemStatusIds.includes(item.queueItemStatusId)) {
                        notChangedQueueItems.push(item);
                    }
                }

                for (let queueItem of notChangedQueueItems) {
                    let dataPrototype = {
                        ticketNumber: null,
                        studyProblems: null,
                        wantedDay: null,
                        time: null,
                        studentName: null,
                        option: this.setStateOnAgendaChange(this.radioGroupWho),
                        assistantId: null,
                        otherTime: null,
                        queueItemStatusId: null,
                        queueItemId: null,
                        otherTimeOriginal: null,
                        openTime: null,
                        closeTime: null,
                        id: Math.floor((Math.random() * 100000)),
                    };

                    let queueStatus = this.getQueueItemStatus(queueItem.queueItemStatusId);
                    let studyProblems = "";

                    if (queueItem.studyProblemsItemsIds.length !== 0) {
                        for (let problem of queueItem.studyProblemsItemsIds) {
                            studyProblems += this.getStudyProblemsItem(problem).name + ' ';
                        }
                    } else {
                        studyProblems = this.getStudyField(queueItem.studyFieldId).name;
                    }

                    if (studyProblems.length > 90) {
                        studyProblems = studyProblems.slice(0, 87) + "..."
                    }

                    dataPrototype.ticketNumber = queueStatus.ticketNumber;
                    dataPrototype.studentName = this.returnName(queueItem);
                    dataPrototype.wantedDay = this.formatDate(queueStatus.wantedDay);
                    dataPrototype.time = queueStatus.actualMeetTime + ' - ' + queueStatus.actualMeetEnd;

                    dataPrototype.openTime = queueStatus.actualMeetTime;
                    dataPrototype.closeTime = queueStatus.actualMeetEnd;

                    dataPrototype.otherTime = queueStatus.actualMeetTime;
                    dataPrototype.otherTimeOriginal = queueStatus.actualMeetTime;
                    dataPrototype.queueItemStatusId = queueItem.queueItemStatusId;
                    dataPrototype.queueItemId = queueItem.id;
                    dataPrototype.studyProblems = studyProblems;

                    this.dataToDataTable.push(dataPrototype);
                }
                this.numberOfItems = this.dataToDataTable.length;
            },

            returnName: function (queueItem) {
                if (queueItem.xname === null && queueItem.nameSurnameTitle != null) {
                    return queueItem.nameSurnameTitle;
                } else if (queueItem.nameSurnameTitle === null && queueItem.xname != null) {
                    return '(' + queueItem.xname + ')';
                } else if (queueItem.nameSurnameTitle === "Empty" && queueItem.xname === "Empty") {
                    return this.$t('reservations.anon_student')
                } else {
                    return queueItem.nameSurnameTitle + ' (' + queueItem.xname + ')';
                }
            },

            allowedDates(apiDate) {
                const openHour = this.getStudyOpenHour(moment(apiDate, "YYYY-MM-DD").day().toString());

                return !((openHour.openTime === "" || openHour.openTime === null) && openHour.closeTime === "" || (openHour.closeTime === null));
            },

            setStateOnAgendaChange(state) {
                if (state === "department") {
                    return 'cancel'
                }

                if (state === "person") {
                    return 'delegate'
                }
            },

            compareArrays(arrA, arrB) {

                if (arrA.length !== arrB.length) return false;

                var cA = arrA.slice().sort().join(",");
                var cB = arrB.slice().sort().join(",");

                return cA === cB;
            },

            updateAffectedItems() {
                // nothing has changed
                if (this.compareArrays(this.oldAffected, this.affected)) return;

                // New queueItems added during creating the restriction
                let newItems = this.affected.filter(a => !this.oldAffected.includes(a));

                // All queueItems deleted during creating the restriction
                let removedItemIds = this.oldAffected.filter(a => !this.affected.includes(a));

                // Remove all deleted queueItems from dataTable
                this.dataToDataTable = this.dataToDataTable.filter(a => !removedItemIds.includes(a.queueItemId))

                // Set new queueItems to dataTable
                this.setItemsToTable(newItems, false);

                this.oldAffected = this.affected;
            },

            cancelAllAffectedItems() {
                for (const item of this.dataToDataTable) {
                    item.option = 'cancel'
                }
            },
        },

        watch: {
            radioGroupWho: async function () {
                this.resetLongTermAction();
                this.resetRestrictionSelect();
                await this.fetchConcernedReservations();
                if (this.radioGroupWho === 'department') {
                    this.cancelAllAffectedItems()
                }
            },

            personSelect: async function () {
                await this.fetchConcernedReservations();
            },

            date_from: async function () {
                this.setTimes();
                await this.fetchQueueItemsForSelectedDay();
                await this.fetchConcernedReservations();
            },

            date_to: async function () {
                this.setTimes();
                await this.fetchQueueItemsForSelectedDay();
                await this.fetchConcernedReservations();
            },

            time_from: async function () {
                await this.fetchConcernedReservations();
            },

            time_to: async function () {
                await this.fetchConcernedReservations();
            },

            radioGroupTimeRestriction: async function () {
                if (this.radioGroupTimeRestriction === 'all') {
                    this.resetTimeFromTo();
                }
                await this.fetchConcernedReservations();
            },
        }
    }

</script>

<style scoped>
    .no_margin {
        margin: 0;
    }

    .button {
        width: 7rem;
    }

    .close_button {
        background-color: #e6e6e6 !important;
        border-radius: 20px;
    }

    .button_group {
        display: flex;
        justify-content: center;
        width: fit-content;
    }

    .margin_left {
        margin-left: 0.7rem;
    }

    .float_left {
        float: left;
        margin: 0;
        padding: 0;
    }

    .header_div {
        width: fit-content;
    }

    .v_flex_span_date {
        width: 25rem;
    }

    .no_margin_no_padding {
        margin: 0;
        padding: 0;
    }

    .text {
        font-size: large;
        color: black !important;
    }

    .v-input__slot {
        margin: 0 !important;
    }

    .top {
        padding-top: 1rem;
    }

    .bottom {
        padding-bottom: 1rem;
    }

    .doSpan {
        font-size: large;
        padding-bottom: 1.2rem;
        padding-left: 0.5rem;
    }

    .overdrive {
        margin: 0 !important;
        padding: 0 !important;
    }

    .doSpan2 {
        font-size: large;
        padding-bottom: 1.2rem;
        padding-left: 0.5rem;
    }

    .margin_left {
        padding-left: 0.1rem;
    }
</style>
