<template>
    <div>
        <Loading v-if="didLoadData === false" />
        <div v-else>
            <GamificationDialog 
               :model="showDialog" 
                @confirm="saveBadge"
                @close="showDialog = false" 
                :attributeField="'badge_name'" 
                :dialogTitle="$t('gamification.edit_badge')" 
                :target="badgeItem"
            >
            </GamificationDialog>
            <v-container>
                <v-row>
                    <v-col>
                        <h1>{{$t('gamification.badges_headline')}}</h1>
                    </v-col>
                </v-row>

                <v-data-table v-model="selectedItems" :headers="headers" :items="getAllBadges"
                    loading-text="Načítání..." style="width: 60%;">

                    <template v-slot:item.imageUrl="{ item }">
                        <v-img max-height="40" max-width="40" :src="item.imageUrl"></v-img>
                    </template>

                    <template v-slot:item.localizedAttributes.cz.name="{ item }">
                        <span class="text-xs-left"
                            style="font-size: 1rem !important">{{ item.localizedAttributes.cz.name }}</span>
                    </template>

                    <template v-slot:item.edit="{ item }" style="display: flex;">
                        <div>
                            <v-icon @click="showEditDialog(item)">
                                edit
                            </v-icon>
                        </div>

                    </template>
                </v-data-table>
            </v-container>
        </div>
    </div>


</template>

<script>
import Loading from "../../components/Loading";
import { mapActions, mapGetters } from "vuex";
import GamificationDialog from "./GamificationDialog.vue";


export default {
    name: "Badges",


    components: {
        Loading,
        GamificationDialog,
    },

    created() {
        this.fetchAllBadges();

    },

    data() {
        return {
            headers: [
                {
                    text: this.$t('gamification.image'),
                    sortable: false,
                    value: 'imageUrl',
                    width: '15%'
                },
                {
                    text: this.$t('gamification.badge_name'),
                    align: 'left',
                    sortable: true,
                    value: 'localizedAttributes.cz.name',
                    width: '65%'
                },
                {
                    text: this.$t('headers.action'),
                    align: 'center',
                    sortable: false,
                    value: 'edit',
                    width: '10%'
                }
            ],
            selectedItems: [],
            showDialog: false,
            badgeItem: null,

        }
    },


    computed: {
        ...mapGetters('Badges', ['didLoadBadges', 'getBadges']),

        getAllBadges() {
            return this.getBadges;
        },

        didLoadData() {
            return this.didLoadBadges
        },
    },

    methods: {
        ...mapActions('Badges', ['fetchAllBadges', 'updateBadge']),

        showEditDialog(item) {
            this.showDialog = true
            this.badgeItem = this._.cloneDeep(item)
        },

        async saveBadge(item) {
            await this.updateBadge(item)
            this.showDialog = false;
        }
    }

}
</script>