<template>
    <v-container class="text-center">
        <TestingLight />
        <v-row>
            <v-col>
                <h1>{{ $t('gamification.dashboard_welcome_title') }}</h1>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <p>{{ $t('gamification.dashboard_welcome_subtitle') }}</p>
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-2">
            <v-col>
                <PrimaryButton :to="{name: 'Subjects'}">{{ $t('gamification.to_subjects_btn') }}</PrimaryButton>
            </v-col>
        </v-row>
        <v-row class="mt-10">
            <v-col>
                <h2>{{ $t('gamification.tutorial_heading') }}</h2>
            </v-col>
        </v-row>
        <v-row class="text-left">
            <v-col cols="12" md="4">
                <v-card
                    class="pa-4"
                    elevation="0"
                    outlined
                >
                    <v-row>
                        <v-col cols="auto">
                            <AddSubject />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h2>{{ $t('gamification.first_card_headline') }}</h2>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <p>{{ $t('gamification.first_card_paragraph') }}</p>
                      </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card
                    class="pa-4"
                    elevation="0"
                    outlined
                >
                    <v-row>
                        <v-col cols="auto">
                            <AddTest />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h2>{{ $t('gamification.second_card_headline') }}</h2>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p>{{ $t('gamification.second_card_paragraph') }}</p>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card
                    class="pa-4"
                    elevation="0"
                    outlined
                >
                    <v-row>
                        <v-col cols="auto">
                            <ActiveTest />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h2>{{ $t('gamification.third_card_headline') }}</h2>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p>{{ $t('gamification.third_card_paragraph') }}</p>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-2 mb-6">
            <v-col>
                <LightButton @click="$router.push({name:'support'})">{{ $t('gamification.have_question') }}</LightButton>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-6">
            <v-col>
                <h2>{{ $t('gamification.gamification_items') }}</h2>
            </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <p>{{ $t('gamification.gamification_items_subheading') }}</p>
          </v-col>
        </v-row>
        <v-row class="justify-center" no-gutters>
          <v-col cols="auto">
            <LightButton @click="$router.push({name:'Badges'})">{{ $t('gamification.badges_headline') }}</LightButton>
          </v-col>
          <v-col cols="auto">
            <LightButton @click="$router.push({name:'Activities'})">{{ $t('gamification.activities_headline') }}</LightButton>
          </v-col>
          <v-col cols="auto">
            <LightButton>{{ $t('gamification.levels_headline') }}</LightButton>
          </v-col>
        </v-row>
    </v-container>
</template>
<script>
import TestingLight from '../../assets/gamification/testing_light.svg';
import AddSubject from '../../assets/gamification/add_subject_light.svg';
import AddTest from '../../assets/gamification/add_test_light.svg';
import ActiveTest from '../../assets/gamification/active_test_light.svg';
import PrimaryButton from "../../components/buttons/PrimaryButton";
import LightButton from "../../components/buttons/LightButton";

export default {
    name: 'GamificationDashboard',

    components: {
        TestingLight,
        AddSubject,
        AddTest,
        ActiveTest,
        PrimaryButton,
        LightButton
    }
}
</script>
<style scoped>

    h1, p, h2 {
        color: #3F4349;
    }

    .v-card {
        border-radius: 8px !important;
        height: 100%;
    }
</style>