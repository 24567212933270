import {get, post, del, put} from '@/services/api'
import Vue from "vue";
import {localizedAttributesFactory} from "../../../localization/languages";

const state = {
    didLoadQuestions: false,
    questions: []
};

const getters = {
    didLoadQuestions: state => state.didLoadQuestions,
    questions: state => state.questions,
    questionById: state => id => state.questions.find(q => q.id === id),
    prepareNewQuestion: () => () => ({
        entryValidFrom: null,
        entryValidTo: null,
        categoryID: null,
        hidden: false,
        localizedAttributes: localizedAttributesFactory({
            question: null,
            answer: null
        })
    })
};

const actions = {
    async fetchQuestions({commit}, includeHidden = true) {
        commit('startedDataDownload');
        const response = await get('faq/questions/?includeHidden=' + includeHidden);
        commit('setQuestions', response.data.items);
        commit('finishedDataDownload');
    },

    fetchQuestionById({commit}, id) {
        commit('errors/clear', null, { root: true });
        commit('startedDataDownload');
        get('faq/questions/' + id)
        .then( response => {
            commit('updateQuestion', response.data.content);
            commit('finishedDataDownload');
        })
        .catch(e => {
            if (e.statusCode === 404) {
                commit('errors/objectNotFound', null, { root: true });
            } else {
                throw e;
            }
        })        
    },

    async createQuestion({commit}, {question, notify = false}) {
        const response = await post(`faq/questions/?notifyUsers=${notify ? "true" : "false"}`, question);
        const newQuestion = response.data.content;
        commit('addQuestion', newQuestion);
        return newQuestion.id;
    },

    async updateQuestion({commit}, {question, notify = false}) {
        const response = await put(`faq/questions/${question.id}?notifyUsers=${notify ? "true" : "false"}`, question);
        commit('updateQuestion', response.data.content);
    },

    async deleteQuestion({commit}, id) {
        await del(`faq/questions/${id}`);
        commit('filterQuestions', id);
    },
};

const mutations = {
    startedDataDownload: state => state.didLoadQuestions = false,
    finishedDataDownload: state => state.didLoadQuestions = true,
    setQuestions: (state, questions) => (state.questions = questions),
    updateQuestion: (state, item) => {
        const existing = state.questions.findIndex(q => q.id === item.id);
        if (existing > -1) {
            Vue.set(state.questions, existing, item);
        } else {
            state.questions.push(item);
        }
    },
    filterQuestions: (state, id) => (state.questions = state.questions.filter(questions => questions.id !== id)),
    addQuestion: (state, item) => (state.questions.push(item)),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
