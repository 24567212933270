<template>
    <v-icon @click="this.onClick" class="mx-1">
        edit
    </v-icon>
</template>

<script>
    export default {
        name: 'EditIcon',

        methods: {
            onClick() {
                this.$emit('click');
            }
        }
    }
</script>

<style scoped>

</style>