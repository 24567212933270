<template>
    <div>
        <span class="title-text grey--text"> <slot name="title"/></span>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
            <v-card elevation="0" v-on="on" v-bind="attrs">
            <div class="d-flex mb-0" >
                <v-chip-group style="height:40px" class="chip-array">
                    <v-chip v-for="tag in tags" 
                        @click:close="deleteTag(tag)"
                        :key="tag"
                        small
                        label
                        close
                        close-icon="close"
                        class="px-2"
                        style="font-size:10px;font-weight:bold"
                    >
                    {{ tag }}
                    </v-chip>
                </v-chip-group>
        
                <v-icon color="grey" small>arrow_left</v-icon>
            </div>
            <v-divider/>
            </v-card>
            </template>
        
                <v-list style="max-height: 240px" class="overflow-y-auto">
                    <v-list-item-group mandatory v-model="model" @change="onChange()" @click="onChange()">
                        <v-list-item
                            v-for="(item, index) in items"
                            :key="index"
                            dense
                        >
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
        </v-menu>
        
    </div>


</template>

<script>

export default {

    name: "MobileFiltering",
    props: {
        items : {
            type: Array,
            required: false
        },
        allow_multiple: {
            type: Boolean,
            required: false,
            default: false
        }
    },


    data: () => ({
        tags: [],
        model: 1,
    }),
    methods : {

        onChange(){
            const newKey = this.items[this.model].text
            if (this.allow_multiple){
                if (!this.tags.includes(newKey)) this.tags.push(newKey) 
            } else {
                this.tags = [newKey]
                this.$emit('input', this.items[this.model].value)
            }
        },
        deleteTag(tag){
            this.tags = this.tags.filter(e => e != tag)
            this.$emit('input', null)
        }

    }

};
</script>

<style scoped>

.title-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 9px;
    font-weight: bold;
}
.select {
    font-size: 12px;
    z-index: 3;
}
#v-list{
  height:200px;/* or any height you want */
  overflow-y:auto
}
.chip-array {
    width: calc(100% - 16px);
}


</style>