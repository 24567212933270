import moment from "moment";
import {firstUpper} from "@/filters/firstUpper";
import i18n from "@/localization/i18n";

export const TimeUnit = {
    HOUR: (unitValues) => formatHours(unitValues),
    DAY: (unitValues) => formatDays(unitValues),
    MONTH: (unitValues) => formatMonths(unitValues),

    HOUR_OF_DAY: (unitValues) => formatHoursOfDay(unitValues),
    DAY_OF_WEEK: (unitValues) => formatDaysOfWeek(unitValues),
    DAY_OF_MONTH: (unitValues) => formatDaysOfMonth(unitValues),
    MONTH_OF_YEAR: (unitValues) => formatMonthsOfYear(unitValues),
};

const formatHours = (unitValues) => unitValues.map(
    unitValue => formatUnitValue(unitValue, "YY/MM/DD HH:00", "H[h]", "dd HH:00–⁠HH:59")
)

const formatDays = (unitValues) => unitValues.length > 14
    ? unitValues.map(
        unitValue => formatUnitValue(unitValue, "YY/MM/DD", "D", "dd DD.MM.YYYY"))
    :
    unitValues.map(
        unitValue => formatUnitValue(unitValue, "YY/MM/DD", "dd DD.MM.YYYY"))


const formatMonths = (unitValues) => unitValues.map(
    unitValue => formatUnitValue(unitValue, "YY/MM", "MMMM", "MMMM YY")
)

const formatHoursOfDay = (unitValues) => unitValues.map(
    unitValue => formatUnitValue(unitValue, "HH:00", "H[h]", "HH:00–⁠HH:59")
)

const formatDaysOfWeek = (unitValues) => unitValues.map(
    unitValue => formatUnitValue(unitValue, "E", "dddd")
)

// There is only exception, when we formatUnitValue cannot be used.
// Moment library is trying to parse format to exact moment - so it choose this month and just adjust day of month
// BUT... Days in month are only variable unit (31, 30, 28, 29)
// So, in some months invalid object is being returned.

const formatDaysOfMonth = (unitValues) => unitValues.map(
    unitValue => ({
        graphValue: unitValue['value'],
        tableValue: `${unitValue['value'].replace(/^0+/, '')}. ${i18n.t('statistics.day_of_month')}`,
        count: unitValue['count']
    })
)

const formatMonthsOfYear = (unitValues) => unitValues.map(
    unitValue => formatUnitValue(unitValue, "MM", "MMMM")
)


const formatUnitValue = (unitValue, originalFormat, newGraphFormat, newTableFormat) => ({
    graphValue:
        firstUpper(moment(unitValue["value"], originalFormat).format(newGraphFormat)),
    tableValue:
        newTableFormat !== undefined ? firstUpper(moment(unitValue["value"], originalFormat).format(newTableFormat)) : undefined,
    count: unitValue['count']
})