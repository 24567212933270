<template>
    <div>
        <h5 class="red--text" id="insufficient-permissions-error">{{ message || $t('errors.insufficient_permissions') }}</h5>
        <v-btn color="primary" @click="goToDashboard()" class="mt-3">
            Přejít na rozcestník
            <v-icon>chevron_right</v-icon>
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: "UnauthorizedState",

        props: {
            message: {
                type: String,
                default: null
            },
        },

        methods: {
            goToDashboard() {
                this.$router.push({name: "dashboard"});
            },
        }
    }
</script>

<style scoped>
</style>
