<template>
  <v-dialog v-model="showDialog" max-width="95vw" persistent scrollable :fullscreen="$vuetify.breakpoint.smAndDown">
    <v-card height="90vh">
        <v-card-title>
            <span class="headline">Úprava geometrie</span>
        </v-card-title>
        <v-card-text>

          <div class="row grow-vertically">

            <div class="col-md-4 d-flex flex-column left-column">

                <!-- GEOMETRY TYPE -->
                <div v-if="allowGeometryTypeChange" class="mb-5">
                  <h3>
                    Typ geometrie
                  </h3>
                  <v-btn-toggle v-model="geometryType">
                    <v-btn v-if="isAllowed('marker')" value="point">
                      <v-icon left>place</v-icon> Bod
                    </v-btn>
                    <v-btn v-if="isAllowed('polygon')" value="polygon">
                      <v-icon left>crop</v-icon> Polygon
                    </v-btn>
                  </v-btn-toggle>
                  <v-alert v-if="!geometryType" color="orange" text type="info" class="mt-4">
                    Začněte tvořit geometrii vybráním jejího typu výše nebo pomocí tlačítek v mapě.
                  </v-alert>
                </div>

                <!-- CENTROID -->
                <div v-if="showCentroid && isPolygon" class="mb-3">
                  <h3 class="mb-0">
                    Střed objektu
                  </h3>
                  <div color="secondary" class="mb-4"><i>Vyberte vizuálně význačný střed místa.</i></div>
                  <coordinate-field
                    :coordinate="editedCentroid"
                    id="coordinate-centroid"
                    @change="v => updateCentroidPosition(v)"
                  />
                </div>

                <!-- COORDINATES OF POINT / POLYGON -->
                  <h3 class="mb-5" v-if="geometryType" >
                      Souřadnice
                  </h3>

                  <div class="coordinates-wrapper" v-if="geometryType" >
                    <coordinate-field
                      v-for="(coordinate, key) in editedPosition"
                      :key="key"
                      :id="`coordinate-${key}`"
                      :coordinate="coordinate"
                      :showDelete="true"
                      @change="v => updateCoordinate(key, v)"
                      @remove="removeCoordinate(key)"
                    />
                    <div v-if="isPolygon && editedPosition.length < 3" class="error--text mb-3">Polygon musí mít minimálně 3 souřadnice.</div>
                    <v-btn v-if="isPolygon" :disabled="isEditingInMap" color="success" outlined depressed dense v-on:click="addCoordinate()">
                      <v-icon>add</v-icon>
                    </v-btn>
                    <v-alert v-if="!isValid" color="orange" text type="info" class="mt-4">
                      <span v-if="showCentroid">Přidejte <b>střed</b> a <b>obrys</b> objektu pomocí mapy nebo zadáním souřadnic výše.</span>
                      <span v-else>Přidejte <b>bod</b> nebo <b>polygon</b> pomocí mapy nebo zadáním souřadnic výše.</span>
                    </v-alert>
                  </div>
            </div>

            <div class="col-md-8">
              <MapWidget
                ref="widget"
                :places="backgroundObjects"
                :editablePlaces="editedPlaces"
                :selected="['edited-object', 'edited-centroid']"
                :creatableGeometryTypes="allowedGeometryTypes"
                :center="mapCenter"
                @updatePosition="updatePosition"
                @start-editing="isEditingInMap = true"
                @end-editing="isEditingInMap = false"
              ></MapWidget>
            </div>

          </div>
        </v-card-text>

        <v-card-actions>
            <v-spacer/>
            <v-btn color="secondary" text @click="close">Zrušit</v-btn>
            <v-btn :disabled="!isValid" color="primary" text @click="confirm" id="save-geometry-btn">Potvrdit</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import MapWidget from "@/components/map/MapWidget";
    import CoordinateField from "./CoordinateField";
    import { mapGetters } from "vuex";
    import { sortedCoordinates } from '../../utils';

    export default {
        name: 'GeometryDialog',

        components: {
          MapWidget,
          CoordinateField,
        },

        props: {
          /**
           * Whether to show or hide this dialog.
           */
          showDialog: Boolean,

          /**
           * The place type is optional.
           * If it is set, then it used for colors and icons of the edited geometries.
           */
          placeType: String,

          /**
           * The place name is optional.
           * If it is set, then it is displayed on the map at edited geometries.
           */
          placeName: {
              type: String,
              default: null,
          },

          /**
           * An array of coordinates to be edited.
           * Each array item is an object with `lat` and `lon` attributes.
           * For points, the array has one element. For polygon at least three.
           */
          position: {
              type: Array,
              default: () => [],
          },

          /**
           * Additionaly to the edited position, a centroid may be edited.
           * It is an object with `lat` and `lon` attributes representing, e.g., the center of a building's polygon.
           */
          centroidPosition: {
              type: Object,
          },

          /**
           * Additionaly to the edited position, a centroid may be edited.
           * Set this to true if you want to enable editing the centroid.
           */
          showCentroid: {
              type: Boolean,
              default: false,
          },

          /**
           * If true, the geometry can be changed from point to polygon and vice versa.
           */
          allowGeometryTypeChange: {
              type: Boolean,
              default: true,
          },

          /**
           * The default geometry type if the geometry is empty yet.
           * @values marker, polygon, null
           */
          defaultGeometryType: {
              type: String,
              default: null,
              validator: v => ['marker', 'polygon'].includes(v)
          },

          /**
           * Which types of geometries can be edited / created.
           * @values an array of marker, polygon or empty
           */
          allowedGeometryTypes: {
              type: Array,
              default: () => ['polygon', 'marker'],
              validator: a => a.every(i => ["marker", "polygon"].includes(i))
          },

          /**
           * Other objects displayed on the map along with the edited one.
           * These objects cannot be edited.
           * The object must have a form according to the MapWidget's prop `places`.
           */
          backgroundObjects: {
              type: Array,
              default: () => [],
          },
        },

        data() {
          return {
            geometryType: null,
            editedPosition: null,
            editedCentroid: null,
            isEditingInMap: false,
          }
        },

        watch: {
          showDialog(newValue) {
              if (newValue) {
                this.editedPosition = this.lodash.cloneDeep(this.position); // copy to prevent mutating the original position
                this.editedCentroid = this.lodash.cloneDeep(this.centroidPosition); // copy to prevent mutating the original position

                this.detectGeometryType();
                this.fixPositionDimension(); // validate position array size

                this.editedPosition = sortedCoordinates(this.editedPosition);
              }
          },

          geometryType() {
              this.fixPositionDimension();
          }
        },

        computed: {
          ...mapGetters('places', ['createEmptyCoordinate']),

          isPolygon() {
            return this.geometryType === 'polygon';
          },

          isValid() {
             if (!this.geometryType) {
               return false;
             }

             if (this.geometryType === 'polygon' && this.editedPosition.length < 3) {
               return false;
             }

             if (this.geometryType === 'point' && this.editedPosition.length !== 1) {
               return false;
             }

             for (let coordinate of this.editedPosition) {
               if (!coordinate.lat || !coordinate.lon) {
                  return false;
               }
             }

             if (this.showCentroid && !this.hasValidCentroid) {
               return false;
             }

             return true;
          },

          hasValidCentroid() {
              return !!(this.editedCentroid && this.editedCentroid.lat && this.editedCentroid.lon);
          },

          editedPositionWithoutBlanks() {
            if (this.editedPosition) {
              return this.editedPosition.filter(p => !!p.lat && !!p.lon);
            } else {
              return [];
            }
          },

          editedPlaces() {
            if (!this.geometryType) { return []; }

            let places = [{
                id: 'edited-object',
                name: this.placeName,
                type: this.placeType,
                position: this.editedPositionWithoutBlanks,
                hideLabel: this.showCentroid && this.hasValidCentroid,
            }];

            if (this.showCentroid && this.hasValidCentroid) {
                places.push({
                    id: 'edited-centroid',
                    name: this.placeName,
                    type: this.placeType,
                    position: [this.editedCentroid],
                });
            }

            return places;
          },

          mapCenter() {
              if (this.editedPositionWithoutBlanks.length > 0) {
                  return this.editedPositionWithoutBlanks;
              } else {
                  return null;
              }
          }
        },

        methods: {
          detectGeometryType() {
              if (!this.editedPosition || this.editedPosition.length === 0) {
                  this.geometryType = this.defaultGeometryType;
              } else if (this.editedPosition.length === 1) {
                  this.geometryType = 'point';
              } else {
                this.geometryType = 'polygon';
              }
          },

          addCoordinate() {
              let newPosition = this.createEmptyCoordinate();
              const lastCoordinate = this.editedPosition[this.editedPosition.length - 1];
              newPosition.order = lastCoordinate ? lastCoordinate.order + 1 : 0;
              this.editedPosition = [...this.editedPosition, newPosition];
          },

          updateCoordinate(index, coordinate) {
            let copy = [...this.editedPosition];
            copy[index] = coordinate;
            this.editedPosition = copy;
          },

          removeCoordinate(index) {
            let copy = [...this.editedPosition];
            copy.splice(index, 1);
            this.editedPosition = copy;
          },

          updateCentroidPosition(position) {
              if (!position) { // centroid deleted
                  this.editedCentroid = this.createEmptyCoordinate();
              } else {
                  this.editedCentroid = {
                      ...position,
                      order: 0, // fix order (required on server)
                  };
              }
          },

          updatePosition({ id, position }) {
              if (this.showCentroid && (position.length === 1 || id === 'edited-centroid')) {
                  this.updateCentroidPosition(position[0]);
              } else {
                  this.editedPosition = position;
                  this.detectGeometryType();
              }
          },

          fixPositionDimension() {
              if (this.editedPosition === null) {
                this.editedPosition = [];
              }

              // ensure the correct number of coordinates is in the place position
              if (this.geometryType === 'point') { // point must have always exactly one coordinate
                this.editedPosition = this.editedPosition.slice(0, 1);
                if (this.editedPosition.length === 0) {
                  this.addCoordinate();
                }
              } else if (this.geometryType === 'polygon') { // polygon must always have at least three coordinates
                if (this.editedPosition.length < 3) {
                  this.editedPosition = []; // reset position before creating a polygon
                  for (let i = this.editedPosition.length; i < 3; i++) {
                    this.addCoordinate();
                  }
                }
              }

              // ensure centroid is not empty
              if (this.showCentroid && (!this.editedCentroid || this.editedCentroid.length === 0)) {
                this.editedCentroid = this.createEmptyCoordinate();
              }
          },

          confirm() {
            this.$refs.widget.saveChanges()

            if (this.isEditingInMap) {
              alert('Nejprve prosím potvrďte nebo zrušte editaci v mapě.');
            } else {
              this.$emit('updatePosition', this.editedPosition, this.editedCentroid);
              this.close();
            }
          },

          close(){
            this.$refs.widget.revertChanges();
            this.$emit('close');
          },

          isAllowed( type ) {
            return this.allowedGeometryTypes.includes(type);
          },

        },
    }
</script>

<style scoped>

  .grow-vertically {
    height: 100%;
    position: relative;
  }

  /* Fixed height for map on mobiles */
  #map-widget {
    height: 60vh;
  }

  /* Auto height for map on desktops */
  @media (min-width: 960px) {
    #map-widget {
      height: 100%;
    }
  }

  /* Scrollable coordinates on desktops. */
  @media (min-width: 960px) {
    .left-column {
      height: 100%;
    }
    .coordinates-wrapper {
      padding-top: 0.5rem; /* always show labels */
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

</style>
