<template>
    <div class="mt-3">
        <module-header>
            <h1>Předměty</h1>
            <PrimaryButton @click="createSubject()" class="mr-8">
                {{ this.$vuetify.breakpoint.smAndUp ? 'Přidat předmět' : 'Přidat'}}
            </PrimaryButton>
        </module-header>

        <div class="mt-10">

            <v-data-table
              :headers="headers"
              :items="getAllSubjects"
              loading-text="Načítání..."
              class="d-none d-sm-block"
              :sort-by.sync="$query.sort"
              :sort-desc.sync="$query.desc"
              :items-per-page.sync="$query.rowsPerPage"
              :page.sync="$query.page"
            >

                <template v-slot:no-data>
                    Žádná dostupná data
                </template>

                <template v-slot:item.localizedAttributes.cz.name="{item}">
                    <b class="text-xs-left pointer light-green--text"
                       @click="goToPortal(item.id)">{{ item.localizedAttributes.cz.name }}</b>
                </template>
                <template v-slot:item.code="{item}">
                    <span>{{ item.code }}</span>
                </template>
                <template v-slot:item.admin="{item}">
                    <span>{{ item.admin }}</span>
                </template>
                <template v-slot:item.edit="{item}">
                    <v-icon @click="goToDetail(item.id)">
                        edit
                    </v-icon>
                </template>
            </v-data-table>

            <!-- ONLY FOR SMALLER SCREENS -->
            <v-data-iterator
                :items="getAllSubjects"
                :hide-default-footer="true"
                :disable-pagination="true"
                :hide-default-header="false"
                class="d-block d-sm-none"
            >
                <template v-slot:no-data>
                        Žádná dostupná data
                </template>
                <template v-slot:default="props">
                <v-row>
                <v-col v-for="item in props.items" :key="item.id" cols="12">
                    <mobile-data-table-row
                        @click="goToPortal(item.id)"
                        @action="goToDetail(item.id)"
                    >
                        <template v-slot:title>{{ item.localizedAttributes.cz.name }}</template>
                        <template v-slot:label_header1> SPRÁVCE </template>
                        <template v-slot:label_content1>{{ item.admin }}</template>
                        <template v-slot:label_header2> KÓD </template>
                        <template v-slot:label_content2>{{ item.code }}</template>
                       <template v-slot:action_button>edit</template>
                    </mobile-data-table-row>
                </v-col>
                </v-row>
            </template>
        </v-data-iterator>

        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ModuleHeader from "@/components/layout/ModuleHeader";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import MobileDataTableRow from '../../components/layout/MobileDataTableRow.vue';

export default {
    name: "Subjects",

    components: {PrimaryButton, ModuleHeader, MobileDataTableRow},

    created() {
        this.fetchAllSubjects();
    },

    data() {
        return {
            dialog: false,
            headers: [
                {
                    text: 'Název',
                    align: 'left',
                    sortable: true,
                    value: 'localizedAttributes.cz.name',
                    width: '64%'
                },
                {
                    text: 'Kód',
                    align: 'left',
                    sortable: true,
                    value: 'code',
                    width: '12%'
                },
                {
                    text: 'Správce',
                    align: 'left',
                    sortable: true,
                    value: 'admin',
                    width: '14%'
                },
                {
                    text: 'Upravit',
                    align: 'left',
                    sortable: false,
                    value: 'edit',
                    width: '10%'
                }
            ]
        }
    },

    computed: {
        ...mapGetters('Subjects', ['getSubjects', 'getSubjectById']),

        getAllSubjects() {
            return this.getSubjects;
        }
    },

    methods: {
        ...mapActions('Subjects', ['fetchAllSubjects', 'deleteSubjectById']),

        createSubject() {
            this.$router.push({name: 'SubjectDetail', params: {subjectId: "new"}})
        },

        goToDetail(subjectId) {
            this.$router.push({name: 'SubjectDetail', params: {subjectId: subjectId}})
        },

        goToPortal(subjectId) {
            this.$router.push({
                name: 'Gamification',
                params: {subjectId: subjectId, tab: 'tests'}
            });
        },
        getSubjectName(id) {
            return this.getSubjectById(id).localizedAttributes.cz.name;
        },
        updateDialog() {
            this.dialog = false;
        },
    }
}
</script>

<style scoped>

</style>