<template>
  <div
    :class="images.length > 0 ? 'image-picker mb-7' : 'image-picker'"
    @drag="preventEvent"
    @dragstart="preventEvent"
    @dragend="preventEvent"
    @dragover="preventEvent"
    @dragenter="preventEvent"
    @dragleave="preventEvent"
    @drop="onDrop"
  >
    <ConfirmDialog 
      :model="sizeExceeded"
      :onConfirm="() => sizeExceeded = false"
      title="Překročena maximální velikost souboru"
      :okOnly="true">
      Maximální velikost nahrávaného souboru je {{ maxImageSize / 1000000 }} MB.
    </ConfirmDialog>
    <ConfirmDialog 
      :model="countExceeded"
      :onConfirm="() => countExceeded = false"
      title="Překročen maximální počet souborů"
      :okOnly="true">
      Maximální počet souborů je {{ maxImages }}. Některé z vybraných souborů nemusely být nahrány.
    </ConfirmDialog>

    <div v-if="!images.length" class="image-container" style="width: 240px">
      <div v-if="isDragover" class="drag-upload-cover centered" @drop="onDrop">
        <v-icon large color="primary">add_photo_alternate</v-icon>
      </div>
      <div v-else class="click-to-add" @dragover.prevent="onDragover">
        <v-icon large>upload</v-icon>
        <div class="click-text">{{clickText}}</div>
        <div class="image-input">
          <label :for="idUpload" class="clickable-input-label"></label> <!-- makes the input clickable -->
        </div>
      </div>
      <div class="empty-actions">
        <v-btn v-if="initialImages.length > 0 && imagesChanged" x-small rounded depressed class="restore" @click="restore">Obnovit původní</v-btn>
      </div>
    </div>
    
    <div class="image-container not-empty" v-else>
      <div v-if="isDragover" class="drag-upload-cover-edit centered" @drop="onDrop">
        <v-icon class="vertical-centered" large color="primary">add_photo_alternate</v-icon>
      </div>

      <div @dragover.prevent="onDragover">
        <div v-if="isVideoFile(highlightedFile)" class="preview-video">
          <video class="preview-image" controls :src="highlightedFile.path" />
        </div>
        <div v-else class="preview-image">
          <img :src="highlightedFile.path" alt="Image preview" />
        </div>

        <div class="image-actions">
          <v-btn v-if="initialImages.length > 0 && imagesChanged" x-small rounded depressed class="restore" @click="restore">Obnovit původní</v-btn>
          <label class="button-modify-image" :for="idEdit">
            <v-icon small class="button-modify-icon mr-1" color="white">edit</v-icon>
          </label>
          <a class="button-modify-image" @click.prevent="deleteImage(currentIndexImage)">
            <v-icon small class="button-modify-icon" color="white">delete</v-icon>
          </a>
        </div>
      </div>
    </div>
    <div v-if="images.length && multiple" class="image-list-container">
      <div
        class="image-list-item"
        :class="image.highlight ? 'image-highlight' : ''"
        v-for="(image, index) in images"
        :key="index"
        @click="changeHighlight(index)"
      >
        <div v-if="isVideoFile(image)">
          <video class="img-responsive" :src="image.path" />
        </div>
        <div v-else>
          <img class="img-responsive" :src="image.path" />
        </div>
      </div>
      <div class="image-list-item button-add-image" v-if="(images.length < maxImages)">
        <v-icon>add</v-icon>
        <div class="label-add-image">
          <label :for="idUpload" class="clickable-input-label"></label>
        </div>
      </div>
    </div>

    <div>
      <input
        class="display-none"
        :id="idUpload"
        @change="uploadFieldChange"
        name="files"
        :multiple="multiple"
        :accept="accept"
        type="file"
        :disabled="disabled"
      >
      <input
        class="display-none"
        :id="idEdit"
        @change="editFieldChange"
        name="file"
        :accept="accept"
        type="file"
        :disabled="disabled"
      >
    </div>
  </div>
</template>

<script>
import { forEach, cloneDeep } from 'lodash';
import { videoPostfixes, videoTypes } from '@/config';
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";

export default {
  name: 'AnnouncementImagePicker',

  components: {
    ConfirmDialog
  },

  props: {
    clickText: {
      type: String,
      default: 'Klikněte pro nahrání'
    },
    accept: {
      type: String,
      default: 'image/jpeg,image/png,image/jpg,video/mp4,video/x-msvideo,video/x-matroska'
    },
    initialImages: {
      type: Array,
      default: () => {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      default: true
    },
    maxImages: {
      type: Number,
      default: 10
    },
    maxImageSize: {
      type: Number,
      default: 100000000 // 100 MB
    },
    idUpload: {
      type: String,
      default: 'image-upload'
    },
    idEdit: {
      type: String,
      default: 'image-edit'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    imagesChanged: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      currentIndexImage: 0,
      images: [],
      isDragover: false,
      sizeExceeded: false,
      countExceeded: false,
    };
  },

  computed: {
    highlightedFile() {
      const highlightedIndex = this.images.findIndex(file => file.highlight);
      const highlightedFile = this.images[highlightedIndex];
      return highlightedFile ? highlightedFile : this.images[0];
    },
  },

  methods: {
    preventEvent(event) {
      event.preventDefault();
      event.stopPropagation();
    },

    onDrop(event) {
      this.isDragover = false;
      this.preventEvent(event);
      let files = event.dataTransfer.files;
      if (!files.length) {
        return false;
      }
      this.handleFileDrop(files);
    },

    handleFileDrop(files) {
      if (!this.isValidNumberOfImages(files.length + this.images.length)) {
        this.countExceeded = true;
      }
      for (let i = 0; i < Math.min(this.maxImages - this.images.length, files.length); i++) {
        this.createFile(files[i]);
        if (!this.multiple) {
          return false;
        }
      }
      document.getElementById(this.idUpload).value = [];
    },

    createFile(file) {
      if (this.disabled) return;
      if (file.size > this.maxImageSize) {
        this.sizeExceeded = true;
        return;
      }
      const reader = new FileReader();

      reader.onload = event => {
        const dataURI = event.target.result;
        if (dataURI) {
          if (!this.images.length) {
            this.images.push({ file, path: dataURI, highlight: true });
            this.currentIndexImage = 0;
          } else {
            this.images.push({ file, path: dataURI, highlight: false });
          }
          this.$emit('change', this.images);
        }
      };

      reader.readAsDataURL(file);
    },

    isVideoFile(file) {
      return videoTypes.includes(file.type ?? file?.file?.type) || videoPostfixes.includes(file.postfix);
    },

    editFile(file) {
      if (this.disabled) return;
      if (file.size > this.maxImageSize) {
        this.sizeExceeded = true;
        return;
      }

      const reader = new FileReader();
      reader.onload = event => {
        const dataURI = event.target.result;
        if (dataURI && this.images[this.currentIndexImage]) {
          this.images[this.currentIndexImage].file = file;
          this.images[this.currentIndexImage].path = dataURI;
          this.images[this.currentIndexImage].highlight = this.images[this.currentIndexImage].highlight;
          this.images[this.currentIndexImage].id = undefined;
          this.images[this.currentIndexImage].postfix = undefined;
        }
      };

      reader.readAsDataURL(file);
      this.$emit('change', this.images);
    },

    uploadFieldChange(event) {
      const files = event.target.files || event.dataTransfer.files;
      this.handleFileDrop(files);
    },

    editFieldChange(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (!files.length || !this.isValidNumberOfImages(files.length)) {
        return false;
      }
      forEach(files, value => {
        this.editFile(value);
      });
      document.getElementById(this.idEdit).value = '';
    },

    changeHighlight(currentIndex) {
      this.currentIndexImage = currentIndex;
      const updatedImages = this.images.map((item, index) => {
        item.highlight = currentIndex === index;
        return item;
      });
      this.images = updatedImages;
      this.$emit('select', this.images);
    },

    restore() {
      this.images = cloneDeep(this.initialImages);
      this.$emit('restore');
    },

    deleteImage(currentIndex) {
      this.images.splice(currentIndex, 1);
      this.currentIndexImage = 0;
      if (this.images.length) {
        this.images[0].highlight = true;
      }
      this.$emit('change', this.images);
    },

    isValidNumberOfImages(amount) {
      if (amount > this.maxImages) {
        this.countExceeded = true;
        return false;
      }
      return true;
    },
  },

  mounted() {
    document.body.addEventListener('dragleave', event => {
      event.stopPropagation();
      event.preventDefault();
      this.isDragover = false;
    });
  },

  created() {
    this.images = cloneDeep(this.initialImages);
  },
};
</script>

<style scoped>
  .image-picker {
    display: flex;
    flex-direction: row;
  }
  .centered {
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .vertical-centered {
    top: 35%;
  }
  .click-to-add {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image-input {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
  }
  .image-input label {
    display: block;
  }
  .preview-image {
    background-color: #d6d6d6;
    position: relative;
    width: 240px;
    height: 135px;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-actions {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    bottom: -35px;
    left: 0;
    height: 30px;
    padding: 0;
    box-sizing: border-box;
  }
  .empty-actions {
    position: relative;
    bottom: -8px;
    left: -15px;
  }
  .image-list-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    max-width: 100%;
    height: min-content;
  }
  .img-responsive {
    display: block;
    min-width: 30px;
    min-height: 30px;
    object-fit: cover;
  }
  .preview-image img {
    max-height: 100%;
    max-width: 100%;
    border-radius: 2px;
  }
  .image-list-container .image-list-item {
    position: relative;
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
  }
  .image-list-container .image-list-item .img-responsive {
    max-width: 30px;
    max-height: 30px;
    border-radius: 3px;
  }
  .image-list-container .image-list-item:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .image-container {
    position: relative;
    text-align: center;
    width: auto;
    height: 135px;
    border: 1px dashed #d6d6d6;
    border-radius: 4px;
  }
  .not-empty {
    border: none;
  }
  .drag-upload-cover-edit {
    width: 100%;
    height: 135px;
    text-align: center;
    position: absolute;
    background: #fcfeff;
    opacity: 0.9;
    z-index: 1;
    border: 2px dashed var(--primary-color);
    font-weight: 400;
    font-size: 20px;
  }
  .drag-upload-cover {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fcfeff;
    opacity: 0.9;
    z-index: 1;
    margin: 5px;
    border: 2px dashed var(--primary-color);
    font-weight: 400;
    font-size: 20px;
  }
  .click-text {
    text-align: center;
    padding-top: 5px;
    color: #777;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.5;
  }
  .button-modify-image {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  .button-modify-icon {
    background-color: var(--primary-color);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding-left: 1px;
    padding-bottom: 0px;
  }
  .button-modify-icon.left {
    margin-right: 150px;
  }
  .restore {
    margin-right: 68px;
  }
  .label-add-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .button-add-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-list-container .image-highlight {
    border: 1px solid var(--primary-color);
  }
  .clickable-input-label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .display-none {
    display: none;
  }
</style>
