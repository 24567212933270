import {get,} from '@/services/api'
import Vue from "vue";

const state = {
    didLoadedQueueItemStatuses: false,
    queueItemStatuses: []
};
const getters = {
    didLoadedQueueItemStatuses: state => state.didLoadedQueueItemStatuses,
    getQueueItemStatuses: state => state.queueItemStatuses,
    getQueueItemStatus: state => id => {
        return state.queueItemStatuses.find(queueItemStatus => queueItemStatus.id === id);
    },
};
const actions = {

    async fetchQueueItemStatusById({commit}, id) {
        commit('setDidLoadedQueueItemStatuses', false);
        const response = await get('queue/demand/status/' + id);
        commit('updateQueueItemStatus', response.data.content);
        commit('setDidLoadedQueueItemStatuses', true);
    },

    async fetchQueueItemStatusesByWantedDay({commit}, wantedDay) {
        commit('setDidLoadedQueueItemStatuses', false);
        const response = await get('queue/demand/status/?wantedDay=' + wantedDay);
        commit('setQueueItemStatuses', response.data.items);
        commit('setDidLoadedQueueItemStatuses', true);
    },

    async fetchQueueItemStatusesByWantedDayAndAssistant({commit}, {wantedDay, assistantId}) {
        commit('setDidLoadedQueueItemStatuses', false);
        const response = await get('queue/demand/status/?wantedDay=' + wantedDay + '&assistant=' + assistantId);
        commit('setQueueItemStatuses', response.data.items);
        commit('setDidLoadedQueueItemStatuses', true);
    },

    setQueueItemStatusesUpdate({commit}, items) {
        commit('setQueueItemStatuses', items);
    },

    setDidLoadedQueueItemStatusesStorage({commit}, val) {
        commit('setDidLoadedQueueItemStatuses', val);
    },

    pushToQueueItemStatusesStorage({commit}, item) {
        commit('setDidLoadedQueueItemStatuses', false);
        commit('pushQueueItemStatus', item);
        commit('setDidLoadedQueueItemStatuses', true);
    },

    deleteFromQueueItemStatusesStorage({commit}, item) {
        commit('setDidLoadedQueueItemStatuses', false);
        commit('removeQueueItemStatus', item);
        commit('setDidLoadedQueueItemStatuses', true);
    },

    updateQueueItemStatusesStorage({commit}, item) {
        commit('setDidLoadedQueueItemStatuses', false);
        commit('updateQueueItemStatus', item);
        commit('setDidLoadedQueueItemStatuses', true);
    },


};

const mutations = {
    setDidLoadedQueueItemStatuses: (state, value) => (state.didLoadedQueueItemStatuses = value),
    setQueueItemStatuses: (state, queueItemStatuses) => (state.queueItemStatuses = queueItemStatuses),

    pushQueueItemStatus: (state, queueItemStatus) => (state.queueItemStatuses.push(queueItemStatus)),
    removeQueueItemStatus: (state, queueItemStatusId) => {
        const index = state.queueItemStatuses.findIndex(i => i.id === queueItemStatusId);
        Vue.delete(state.queueItemStatuses, index)
    },
    updateQueueItemStatus: (state, queueItemStatus) => {
        const index = state.queueItemStatuses.findIndex(i => i.id === queueItemStatus.id);
        if (index !== -1) {
            Vue.set(state.queueItemStatuses, index, queueItemStatus);
        } else {
            state.queueItemStatuses.push(queueItemStatus)
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
