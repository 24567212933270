<template>
    <div>
        <span class="title-text grey--text"> <slot name="title"/></span>
        <v-card elevation="0">
        <div class="d-flex">
            <div class="pt-2" style="height:40px" @click="reverse()">
                <v-chip
                    small
                    label
                    close 
                    :close-icon="this.arrowIcon" 
                    class="px-2"
                    @click:close="reverse()"
                    style="font-size:10px;font-weight:bold"
                    >{{ this.tag }}</v-chip>
            </div>
        </div>
        <v-divider/>
        </v-card>
        
    </div>


</template>

<script>

export default {

    name: "MobileSorting",
    props: {
        tag: {
            type: String,
            required: true
        }

    },


    data: () => ({
        ascending: true
    }),
    methods : {
        reverse(){
            this.ascending = !this.ascending
            this.$emit('sort')
        }
    },
    computed : {
        arrowIcon(){
            return this.ascending ? 'arrow_upward' : 'arrow_downward'
        },

    }

};
</script>

<style scoped>

.title-text {
    font-size: 9px;
    font-weight: bold;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.select {
    font-size: 12px;
    z-index: 3;
}



</style>