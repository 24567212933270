import axios from 'axios';

const state = {
    modelTypes: [],
    modelTypeDetail: null
};
const getters = {
    getModelTypes: state => state.modelTypes,
    getModelType: state => state.modelTypeDetail
};
const actions = {
    //Fetch ALL
    async fetchModelTypes({commit}) {
        const response = await axios
            .get('https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model-type');
        commit('setModelTypes', response.data.modelTypes);
    },

    //Fetch ONE
    async fetchModelType({commit}, id) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model-type/${id}`);
        commit('setModelType', response.data);
    },

    //Add ONE
    async createModelType({commit}, modelType) {
        await axios
            .post(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model-type`, modelType).then((newModelType) => {
                commit('addModelType', newModelType.data);
            });
    },

    //Update ONE
    async updateModelType({commit}, {id, modelType}) {
        commit('updateModelType', id, modelType);
        await axios
            .put(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model-type/${id}`, modelType);
    },

    //Delete ONE
    async deleteModelType({commit}, id) {
        commit('filterModelType', id);
        await axios
            .delete(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model-type/${id}`);
    }
};

const mutations = {
    setModelTypes: (state, modelType) => (state.modelTypes = modelType),
    setModelType: (state, modelType) => (state.modelTypeDetail = modelType),
    addModelType: (state, modelType) => (state.modelTypes.push(modelType)),
    updateModelType: (state, id, modelType) => {
        let index = state.modelTypes.filter(modelTypes => modelTypes.id === id);
        state.modelTypes[index] = modelType
    },
    filterModelType: (state, id) => (state.modelTypes = state.modelTypes.filter(modelTypes => modelTypes.id !== id))
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
