import Vuex from 'vuex';
import Vue from 'vue';
import reports from './modules/Reports';
import user from './modules/User';
import userlist from './modules/UserList';
import FAQCategories from './modules/faq/FAQCategories';
import queueItems from './modules/studyDepartmentModules/QueueItems';
import queueItemStatuses from './modules/studyDepartmentModules/QueueItemStatuses';
import studyFields from './modules/studyDepartmentModules/StudyFields'
import studyProblemsItems from './modules/studyDepartmentModules/StudyProblemsItems'
import studyProblemsCategories from './modules/studyDepartmentModules/StudyProblemsCategories'
import announcements from './modules/displays/Announcements';
import announcementTypes from './modules/displays/AnnouncementTypes';
import announcementImages from './modules/displays/AnnouncementImages';
import displays from './modules/displays/Displays';
import displaysAdmins from "./modules/displays/Administrators";
import displaysFaculties from "./modules/displays/Faculties";
import stops from './modules/kordis/Stops';
import facilities from './modules/food/Facilities';
import FAQQuestions from './modules/faq/FAQQuestions';
import places from './modules/map/Places';
import leavePrevent from './modules/LeavePrevent';
import previousPathMemory from "@/store/modules/PreviousPathMemory";
import localization from './modules/Localization';
import FAQTasks from './modules/faq/FAQTasks';
import assistant from './modules/studyDepartmentModules/Assistant';
import closedHours from './modules/studyDepartmentModules/ClosedHours';
import changedQueues from './modules/studyDepartmentModules/ChangedQueueItems';
import pageStorage from "./modules/studyDepartmentModules/pageStorage/pageStorage";
import StudyOpenHours from "./modules/studyDepartmentModules/StudyOpenHours";
import AssistantPause from "./modules/studyDepartmentModules/AssistantPause";
import Warnings from "./modules/warnings/Warnings";
import Beacons from "./modules/beacons/Beacons";
import BeaconPurposes from "./modules/beacons/BeaconPurposes";
import rents from './modules/rents/Rents';
import requests from './modules/rents/Requests';
import modelTypes from './modules/rents/ModelTypes';
import models from './modules/rents/Models';
import devices from './modules/rents/Devices';
import controls from './modules/rents/Controls';
import users from './modules/rents/Users';
import ReportImages from "./modules/reports/ReportImages";
import closedHoursReason from "./modules/studyDepartmentModules/ClosedHoursReasons"
import buildings from './modules/map/Buildings';
import floors from './modules/map/Floors';
import Subjects from './modules/gamification/Subjects';
import Tests from "./modules/gamification/Tests";
import Badges from "./modules/gamification/Badges"
import Questions from "./modules/gamification/Questions";
import Answers from "./modules/gamification/Answers";
import Activities from "./modules/gamification/Activities";
import Locations from "./modules/onboarding/Locations";
import Scenarios from "./modules/onboarding/Scenarios";
import Snackbar from "./modules/Snackbar";
import firebase from "./modules/notification/firebase";
import errors from './modules/Errors';
import schedules from "./modules/schedules/schedules";
import SubjectCategories from "./modules/gamification/SubjectCategories"
import IotPlaces from './modules/iot/IotPlaces';
import Helpdesk from './modules/helpdesk/Helpdesk';
import Statistics from "./modules/studyDepartmentModules/statistics/Statistics";
import Faculties from "./modules/news/Faculties";
import Software from './modules/iot/Software';
import Newsfeed from "@/store/modules/news/Newsfeed";
import Computers from './modules/iot/Computers';
import Administrators from "./modules/news/Administrators";
import ConnectionState from "@/store/modules/ConnectionState";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    reports,
    FAQCategories,
    announcements,
    announcementTypes,
    announcementImages,
    displays,
    stops,
    facilities,
    displaysAdmins,
    displaysFaculties,
    user,
    leavePrevent,
    previousPathMemory,
    userlist,
    queueItems,
    queueItemStatuses,
    studyFields,
    studyProblemsItems,
    studyProblemsCategories,
    FAQQuestions,
    rents,
    requests,
    modelTypes,
    models,
    devices,
    controls,
    users,
    places,
    localization,
    FAQTasks,
    assistant,
    closedHours,
    changedQueues,
    pageStorage,
    StudyOpenHours,
    AssistantPause,
    Warnings,
    Beacons,
    ReportImages,
    BeaconPurposes,
    closedHoursReason,
    buildings,
    floors,
    Subjects,
    Tests,
    Questions,
    Badges,
    Answers,
    Activities,
    Locations,
    Scenarios,
    ConnectionState,
    Snackbar,
    firebase,
    errors,
    schedules,
    SubjectCategories,
    IotPlaces,
    Helpdesk,
    Statistics,
    Faculties,
    Software,
    Newsfeed,
    Computers,
    Administrators
  }
});
