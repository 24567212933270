<template>
    <div class="map-places">
        <RefreshButton v-if="($route.params.tab === 'buildings')" @click="refreshBuildings">
            <h2>Budovy</h2>
        </RefreshButton>
        <h2 v-else-if="($route.params.tab === 'map')"> Interaktivní mapa </h2>
        <h2 v-else>Místa</h2>

    <div class="map-places-head align-center d-none d-sm-flex">
      <v-tabs icons-and-text color="light-green">
        <v-tab :to="{name: 'map', params: {'tab': 'places'}}">
          Místa
          <v-icon>room</v-icon>
        </v-tab>
        <v-tab :to="{name: 'map', params: {'tab': 'buildings'}}">
          Budovy
          <v-icon>home</v-icon>
        </v-tab>
        <v-tab :to="{name: 'map', params: {'tab': 'map'}}">
          Mapa
          <v-icon>map</v-icon>
        </v-tab>
      </v-tabs>
      <v-spacer/>
      <PrimaryButton v-if="($route.params.tab !== 'buildings' && $route.params.tab !== 'map' )" @click="goToDetail('place','new')">Přidat místo</PrimaryButton>
      <PrimaryButton v-if="($route.params.tab === 'buildings')" @click="goToDetail('building', 'new')">Přidat Budovu</PrimaryButton>
    </div>


    <!-- ONLY FOR SMALLER SCREENS -->
    <div class="d-block d-sm-none">
      <v-btn
        class="my-3"
        small
        color="primary"
        v-if="$route.params.tab !== 'buildings' && $route.params.tab !== 'map'"
        @click="goToDetail('place', 'new')"
        ><v-icon small class="mr-1">add</v-icon> Přidat</v-btn
      >
      <v-btn
        class="my-3"
        small
        color="primary"
        v-if="$route.params.tab !== 'buildings'"
        @click="goToDetail('building', 'new')"
        >Přidat</v-btn
      >

      <div class="d-flex d-sm-none mt-2" style="justify-content: space-between">
        <mobile-tab to="places" :active="$route.params.tab === 'places' || ($route.params.tab !== 'buildings' && $route.params.tab !== 'map')">
          <template v-slot:icon>place</template>
          <template v-slot:title>MÍSTA</template>
        </mobile-tab>
        <mobile-tab to="buildings" :active="$route.params.tab !== 'buildings'">
          <template v-slot:icon>home</template>
          <template v-slot:title>BUDOVY</template>
        </mobile-tab>
        <mobile-tab to="map" :active="$route.params.tab !== 'map'">
          <template v-slot:icon>map</template>
          <template v-slot:title>MAPA</template>
        </mobile-tab>
      </div>
    </div>

        <v-tabs-items :value="$route.params.tab" :touchless="true">
            <v-tab-item value="places">
                <Places></Places>
            </v-tab-item>
            <v-tab-item value="buildings">
                <Buildings ref="buildingsRef"></Buildings>
            </v-tab-item>
            <v-tab-item value="map">
                <MapInteractive></MapInteractive>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import MapInteractive from "./MapInteractive";
    import PrimaryButton from "../../components/buttons/PrimaryButton";
    import RefreshButton from "../../components/buttons/RefreshButton";
    import Places from "./Places.vue";
    import Buildings from "./Buildings.vue";
    import MobileTab from "../../components/layout/MobileTab.vue";

    export default {
        name: "Map",

        components: {
             MapInteractive,
             PrimaryButton,
             RefreshButton,
             Buildings,
             Places,
             MobileTab,
         },

        data() {
            return {
                tab:null,
                map: null
            }
        },

        methods: {
            goToDetail(placeType, PlaceId) {
                this.$router.push({name:'mapDetail',params:{type: placeType, id: PlaceId}});
            },

            refreshBuildings() {
                this.$refs.buildingsRef.fetchAllBuildings();
            }
        }
    }
</script>

<style scoped>
    .map-places-head {
        padding-bottom: 1rem;
    }

    .map-places {
        width: 100%;
        display: block;
    }
</style>
