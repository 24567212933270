<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <div v-else class="stack">

        <h3>{{ $t('checkIn.not_served') }}</h3>
                    
        <img :src="imageSrc" class="animal-image">

        <div class="block-with-icon">
            <v-icon>confirmation_number</v-icon>
            <span> {{modelToShow.ticketNumber}}</span>
        </div>

        <div class="block-with-icon list-items-wrapper">
            <v-icon>list_alt</v-icon>
            <div class="list-items">
                <div v-for="item in modelToShow.studyProblemsFull" :key="item">
                    {{item}}
                </div>
            </div>
        </div>

        <div class="block-with-icon">
            <v-icon>schedule</v-icon>
            <span>{{ $t('checkIn.scheduled_time') }} {{modelToShow.time}}</span>
        </div>

        <div class="block-with-icon">
            <v-icon>person</v-icon>
            <span> {{modelToShow.studentXlogin}} </span>
        </div>

        <v-btn rounded dark color="secondary" class="mt-5" @click="endMeeting()" id="end-call-in-btn"> {{ $t('checkIn.stop') }}</v-btn>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Loading from "../../../../components/Loading";
    import moment from 'moment';
    import {config} from "@/config";

    export default {
        name: "CallInDidNotCome",

        components: {Loading},

        data() {

            return {
                selected: [""],

                modelToShow: {
                    ticketNumber: null,
                    time: null,
                    studyProblems: null,
                    studyProblemsFull: [],
                    studentXlogin: null,
                    imagesId: null,
                },
            }
        },

        mounted() {
            this.setModelToShow();
        },

        computed: {
            ...mapGetters('queueItems', ['didLoadedQueueItems', 'getQueueItem']),
            ...mapGetters('queueItemStatuses', ['didLoadedQueueItemStatuses', 'getQueueItemStatus']),
            ...mapGetters('studyProblemsItems', ['didLoadedStudyProblemsItems', 'getStudyProblemsItem']),
            ...mapGetters('pageStorage', ['getCallInItemIdToShow']),
            ...mapGetters('studyFields', ['getStudyField']),

            didLoadedAll() {
                return this.didLoadedStudyProblemsItems && this.didLoadedQueueItemStatuses && this.didLoadedQueueItems;
            },

          imageSrc() {
            return `${config.serverUrl}study_department/images/${this.modelToShow.imagesId}`;
          }
        },

        methods: {

            endMeeting() {
                // goes to CheckInTable
                this.$root.$emit('callInStartItsOn');
            },

            onScroll(e) {
                this.offsetTop = e.target.scrollTop
            },

            setModelToShow() {
                let queueItem = this.getQueueItem(this.getCallInItemIdToShow);
                let queueItemStatus = this.getQueueItemStatus(queueItem.queueItemStatusId);
                let studyProblems = "";

                this.modelToShow.studyProblemsFull = [];

                if (queueItem.studyProblemsItemsIds.length !== 0) {
                    for (let problem of queueItem.studyProblemsItemsIds) {
                        studyProblems += this.getStudyProblemsItem(problem).name + ' ';
                        this.modelToShow.studyProblemsFull.push(this.getStudyProblemsItem(problem).name)
                    }
                } else {
                    studyProblems = this.getStudyField(queueItem.studyFieldId).name;
                    this.modelToShow.studyProblemsFull.push(this.getStudyField(queueItem.studyFieldId).name)
                }

                if (studyProblems.length > 90) {
                    studyProblems = studyProblems.slice(0, 87) + "..."
                }

                this.modelToShow.ticketNumber = queueItemStatus.ticketNumber;
                this.modelToShow.time = moment(queueItemStatus.actualMeetTime, "HH:mm:ss").format("HH:mm");
                this.modelToShow.studyProblems = studyProblems;
                this.modelToShow.imagesId = queueItem.imagesId;

                if (queueItem.xname != null && queueItem.xname != 'Empty' && queueItem.xname != undefined && queueItem.xname != "") {
                    this.modelToShow.studentXlogin = queueItem.xname;
                } else {
                    this.modelToShow.studentXlogin = this.$t('checkIn.anon_student');
                }
            }
        }
    }
</script>

<style scoped>
    .main_layout {
        height: 42rem;
        border-left: 2px solid #c4c4c4 !important;
        border-bottom: 2px solid #c4c4c4 !important;;
        background-color: #F6F6F6;
    }

    .no_margin_no_padding {
        margin: 0 !important;
        padding: 0 !important;
    }

    .no_margin {
        margin-right: 0;
    }

    .no_margin_info {
        margin: 1rem 0 0 0;
    }

    .span_info_text {
        font-weight: 400;
        font-size: large;
        margin-top: 0 !important;
    }

    .div_layout {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .call_in_span {
        font-weight: 500;
        font-size: x-large;
        margin: 1rem 0 1rem 0;
    }

    .call_in_button {
        width: 50% !important;
        text-transform: capitalize;
        font-size: large !important;
        font-weight: 400;
        letter-spacing: normal;
        background-color: #757575 !important;
        color: white !important;
    }

    .div_icon {
        width: 10%;
    }

    .div_info {
        width: 75%;
    }

    .div_button_come {
        padding-top: 5.7rem;
    }

    .main_cont {
        height: 30rem;
    }

    .container_scroll {
        max-height: 15rem;
        width: 100%;
        display: block;
        margin-top: 0rem;
        padding-top: 0 !important;
        padding-left: 1rem;

    }

    .list-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 0.5rem;
    }

    .list-items:not(:last-child) {
        border-bottom: #e0e0e0 1px solid;
        margin-bottom: 0.2em;
        padding-bottom: 0.2em;
    }

    .animal-image {
        width: 30%;
        height: 30%;
    }

    .animal-image-div {
        margin-bottom: -1.5em;
    }

</style>
