<template>
  <Loading v-if="didLoadedAll === false"></Loading>

  <div v-else class="stack">
    <div v-if="isClosedToday(EB_setSelectedDate_moment)">
      {{ $t('reservations.we_closed') }}
    </div>
    <v-expansion-panels
        v-if="showReservationDivCount(EB_setSelectedDate_moment.format('YYYY-MM-DD')) > 0 || !isClosedToday(EB_setSelectedDate_moment)"
        v-model="panel"
        accordion
        class="date_divs"
        multiple>

      <!--Study department restriction-->
      <v-expansion-panel id="study-restrictions-panel">

        <v-expansion-panel-header
            v-if="showStudyRestrictionDivCount(EB_setSelectedDate_moment.format('YYYY-MM-DD')) === 0"
            class="expansion_header_text"> {{ $t('reservations.study_restrictions') }}
        </v-expansion-panel-header>

        <v-expansion-panel-header v-else class="expansion_header_text">{{ $t('reservations.study_restrictions') }}
          ({{ showStudyRestrictionDivCount(EB_setSelectedDate_moment.format('YYYY-MM-DD')) }})
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <div v-if="showStudyRestrictionDiv(EB_setSelectedDate_moment.format('YYYY-MM-DD')).length !== 0">

            <div
                v-for="item in showStudyRestrictionDiv(EB_setSelectedDate_moment.format('YYYY-MM-DD'))"
                id="study-restriction-details"
                :key="item.id"
            >

              <div class="info_text_div"
                   type="button"
                   @click="goToRestrictionDetail(item.id, assistantId)">

                <div :class="[(validateEditButton(EB_setSelectedDate_moment)) ? 'closed_history' : 'closed']"
                     class="left_bar">
                </div>

                <div class="right_bar">
                  <div class="date_divs">
                    <span class="info_text_div_time_span">
                      {{ formatTime(item.timeFrom) }} - {{ formatTime(item.timeTo) }}
                    </span>
                  </div>

                  <div class="date_divs">
                    <span class="info_text_div_item_span">
                      {{ getClosedHoursReason(item.closedHoursReasonId).reason }}
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div v-else class="date_divs justify_content_center">
            <span class="you_dont_have">
              {{ $t('reservations.no_study_restrictions_day') }}
            </span>
          </div>

          <div>

            <v-divider
                v-if="showStudyRestrictionDivHistory(EB_setSelectedDate_moment.format('YYYY-MM-DD')).length !== 0"
                class="history_divider"/>

            <span
                v-if="historyBig === false && showStudyRestrictionDivHistory(EB_setSelectedDate_moment.format('YYYY-MM-DD')).length !== 0"
                class="delete_this"
                @click=" setHistoryBigRestriction">
              {{ $t('reservations.show_history') }}
            </span>

            <span
                v-if="historyBig === true && showStudyRestrictionDivHistory(EB_setSelectedDate_moment.format('YYYY-MM-DD')).length !== 0"
                class="delete_this"
                @click=" setHistoryBigRestriction">
              {{ $t('reservations.hide_history') }}
            </span>

            <v-divider
                v-if="historyBig === true && showStudyRestrictionDivHistory(EB_setSelectedDate_moment.format('YYYY-MM-DD')).length !== 0"
                class="history_divider"/>

            <div
                v-if="showStudyRestrictionDivHistory(EB_setSelectedDate_moment.format('YYYY-MM-DD')).length !== 0 && historyBig === true">

              <div v-for="item in showStudyRestrictionDivHistory(EB_setSelectedDate_moment.format('YYYY-MM-DD'))"
                   id="study-restriction-history-details"
                   :key="item.id">

                <div class="info_text_div"
                     type="button"
                     @click="goToRestrictionDetail(item.id, assistantId)">

                  <div class="left_bar reservation_history"></div>
                  <div class="right_bar">
                    <div class="date_divs">
                      <span class="info_text_div_time_span">
                        {{ formatTime(item.timeFrom) }} - {{ formatTime(item.timeTo) }}
                      </span>
                    </div>

                    <div class="date_divs">
                      <span class="info_text_div_item_span">
                        {{ getClosedHoursReason(item.closedHoursReasonId).reason }}
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!--Personal restrictions-->
      <v-expansion-panel id="personal-restrictions-panel">

        <v-expansion-panel-header
            v-if="showPersonalRestrictionDivCount(EB_setSelectedDate_moment.format('YYYY-MM-DD')) === 0"
            class="expansion_header_text">
          {{ $t('reservations.personal_restrictions') }}
        </v-expansion-panel-header>

        <v-expansion-panel-header v-else class="expansion_header_text">
          {{ $t('reservations.personal_restrictions') }}
          ({{ showPersonalRestrictionDivCount(EB_setSelectedDate_moment.format('YYYY-MM-DD')) }})
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <div v-if="showPersonalRestrictionDiv(EB_setSelectedDate_moment.format('YYYY-MM-DD')).length !== 0">
            <div v-for="item in showPersonalRestrictionDiv(EB_setSelectedDate_moment.format('YYYY-MM-DD'))"
                 id="personal-restriction-details"
                 :key="item.id">

              <div class="info_text_div"
                   type="button"
                   @click="goToRestrictionDetail(item.id, assistantId)">

                <div :class="[(validateEditButton(EB_setSelectedDate_moment)) ? 'reservation_history' : 'reservation']"
                     class="left_bar">
                </div>

                <div class="right_bar">

                  <div class="date_divs">
                    <span class="info_text_div_time_span">
                      {{ formatTime(item.timeFrom) }} - {{ formatTime(item.timeTo) }}
                    </span>
                  </div>

                  <div class="date_divs_text">
                    <span class="info_text_div_item_span">
                      {{ getClosedHoursReason(item.closedHoursReasonId).reason }}
                    </span>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div v-else class="date_divs justify_content_center">
            <span class="you_dont_have">
              {{ $t('reservations.no_personal_restrictions') }}
            </span>
          </div>

          <div>
            <v-divider
                v-if="returnBoolForShowPersonalRestrictionDivHistoryLength()" class="history_divider"/>

            <span v-if="historySmall === false && returnBoolForShowPersonalRestrictionDivHistoryLength()"
                  class="delete_this"
                  @click="setHistorySmallRestriction">
              {{ $t('reservations.show_history') }}
            </span>

            <span v-if="historySmall === true && returnBoolForShowPersonalRestrictionDivHistoryLength()"
                  class="delete_this"
                  @click="setHistorySmallRestriction">
              {{ $t('reservations.hide_history') }}
            </span>

            <v-divider
                v-if="historySmall === true && returnBoolForShowPersonalRestrictionDivHistoryLength()"
                class="history_divider"/>

            <div v-if="returnBoolForShowPersonalRestrictionDivHistoryLength() && historySmall === true">
              <div v-for="item in showPersonalRestrictionDivHistory(EB_setSelectedDate_moment.format('YYYY-MM-DD'))"
                   id="personal-restriction-history-details"
                   :key="item.id">

                <div class="info_text_div" type="button"
                     @click="goToRestrictionDetail(item.id, assistantId)">

                  <div class="reservation_history left_bar"></div>

                  <div class="right_bar">
                    <div class="date_divs">
                      <span class="info_text_div_time_span">
                        {{ formatTime(item.timeFrom) }} - {{ formatTime(item.timeTo) }}
                      </span>
                    </div>

                    <div class="date_divs_text">
                      <span class="info_text_div_item_span">
                        {{ getClosedHoursReason(item.closedHoursReasonId).reason }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!--Reservation-->
      <v-expansion-panel data-cy="reservation-expansion-panel">

        <v-expansion-panel-header
            v-if="showReservationDivCount(EB_setSelectedDate_moment.format('YYYY-MM-DD')) === 0"
            class="expansion_header_text">
          {{ $t('reservations.reservations') }}
        </v-expansion-panel-header>

        <v-expansion-panel-header v-else class="expansion_header_text">
          {{ $t('reservations.reservations') }}
          ({{ showReservationDivCount(EB_setSelectedDate_moment.format('YYYY-MM-DD')) }})
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <div v-if="showReservations(EB_setSelectedDate_moment.format('YYYY-MM-DD')).length !== 0">

            <div v-for="item in showReservations(EB_setSelectedDate_moment.format('YYYY-MM-DD'))"
                 id="reservation-details"
                 :key="item.id">

              <div class="info_text_div" type="button"
                   @click="goToReservationDetail(item.id, assistantId)">
                <div
                    :class="[(validateEditButton(EB_setSelectedDate_moment)) ? 'informative_msg_history' : 'informative_msg']"
                    class="left_bar">
                </div>

                <div class="right_bar">
                  <div class="date_divs">
                    <span class="info_text_div_time_span">
                      {{ formatTime(item.actualMeetTime) }} - {{ formatTime(item.actualMeetEnd) }}
                    </span>

                    <span class="info_text_div_ticket_span">
                      {{ item.ticketNumber }}
                    </span>
                  </div>

                  <div class="date_divs_text">
                    <span class="info_text_div_item_span">
                      {{ returnNoNameIfNotXname(item.id) }} - {{ setTopicForReservation(item.id) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="date_divs justify_content_center">
            <span class="you_dont_have">
              {{ $t('reservations.no_reservations') }}
            </span>
          </div>

          <div>
            <v-divider v-if="returnBoolForShowReservationsHistoryLength()"
                       class="history_divider"/>

            <span v-if="historyReservation === false && returnBoolForShowReservationsHistoryLength() "
                  class="delete_this"
                  @click="setHistoryReservation">
              {{ $t('reservations.show_history') }}
            </span>
            <span v-if="historyReservation === true && returnBoolForShowReservationsHistoryLength()"
                  class="delete_this"
                  @click="setHistoryReservation">
              {{ $t('reservations.hide_history') }}
            </span>

            <v-divider
                v-if="historyReservation === true && returnBoolForShowReservationsHistoryLength()"
                class="history_divider"/>

            <div v-if="(returnBoolForShowReservationsHistoryLength() && historyReservation === true)">
              <div v-for="item in showReservationsHistory(EB_setSelectedDate_moment.format('YYYY-MM-DD'))"
                   :key="item.id">

                <div class="info_text_div "
                     type="button"
                     @click="goToReservationDetail(item.id, assistantId)">

                  <div class="left_bar informative_msg_history">
                  </div>

                  <div class="right_bar">

                    <div class="date_divs">
                      <span class="info_text_div_time_span">
                        {{ formatTime(item.actualMeetTime) }} - {{ formatTime(item.actualMeetEnd) }}
                      </span>

                      <span class="info_text_div_ticket_span">
                        {{ item.ticketNumber }}
                      </span>
                    </div>

                    <div class="date_divs">
                      <span class="info_text_div_item_span">
                        {{ returnNoNameIfNotXname(item.id) }} - {{ setTopicForReservation(item.id) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-btn id="add-reservation"
           :disabled="disableIfBeforeCurrentDate()"
           class="mt-5"
           color="primary"
           rounded
           @click="goToNewReservation(EB_setSelectedDate_moment, assistantId)">
      {{ $t('reservations.add_reservation') }}
    </v-btn>
    <v-btn
        id="add-restriction-btn"
        :disabled="disableIfBeforeCurrentDateRestriction()"
        class="mt-3 mb-3" color="normal"
        rounded
        @click="goToNewRestriction(EB_setSelectedDate_moment, assistantId)">
      {{ $t('reservations.add_restriction') }}
    </v-btn>

    <notification-setting class="mt-5" label="na nové rezervace"/>

  </div>
</template>

<script>
import moment from 'moment';
import {mapActions, mapGetters} from 'vuex';
import Loading from "../../../../components/Loading";
import Holidays from 'date-holidays';
import NotificationSetting from '../../../../components/notifications/NotificationSetting.vue';

export default {
  name: "CalendarInfobar",

  components: {
    Loading,
    NotificationSetting,
  },

  props: {
    assistantId: [Number],
  },

  data() {
    return {
      today: moment(),
      EB_setSelectedDate_moment: moment(),

      panel: [],

      historyBig: false,
      historySmall: false,
      historyReservation: false,

      holidays: new Holidays('CZ')
    }
  },

  created() {
    this.$root.$on('setSelectedDate', (moment) => {
      this.EB_setSelectedDate_moment = moment;
    });
    this.setSelectedDateFromStorage();
  },

  mounted() {
    this.loadPanelMemory();
  },

  destroyed() {
    this.savePanelMemory();
  },

  computed: {
    ...mapGetters('queueItemStatuses', ['getQueueItemStatuses', 'didLoadedQueueItemStatuses']),
    ...mapGetters('closedHours', ['getTypeClosedHoursByDate', 'getClosedHours', 'getTypeClosedHours', 'didLoadedClosedHours']),
    ...mapGetters('queueItems', ['getQueueItemByQueueItemStatusId', 'getQueueItems', 'getQueueItemByQueueItemStatusId', 'getQueueItem', 'didLoadedQueueItems']),
    ...mapGetters('studyProblemsCategories', ['didLoadedStudyProblemsCategories']),
    ...mapGetters('closedHoursReason', ['getClosedHoursReason', 'getTypeClosedHoursReasons']),
    ...mapGetters('StudyOpenHours', ['getStudyOpenHour', 'getStudyOpenHours', 'didLoadedStudyOpenHours']),
    ...mapGetters('studyProblemsItems', ['didLoadedStudyProblemsItems', 'getStudyProblemsItem']),
    ...mapGetters('pageStorage', ['getAssistantStorage', 'calendarInfobarPanelStorage', 'getSelectedDayStorage']),
    ...mapGetters('studyFields', ['getStudyField', 'didLoadedStudyFields']),

    didLoadedAll() {
      return this.didLoadedStudyOpenHours
          && this.didLoadedClosedHours
          && this.didLoadedStudyFields
          && this.didLoadedStudyProblemsItems
          && this.didLoadedStudyProblemsCategories
          && this.didLoadedQueueItemStatuses
          && this.didLoadedQueueItems;
    },

    validateUserRoles() {
      return this.getAssistantStorage.role === 'STUDY_SPY';
    }
  },

  methods: {
    ...mapActions('pageStorage', ['setVtabsActiveCompomentStorage', 'setCalendarInfobarPanelStorage']),

    setSelectedDateFromStorage() {
      if (this.getSelectedDayStorage !== null) {
        this.EB_setSelectedDate_moment = this.getSelectedDayStorage;
      }
    },

    isClosedToday(apiDate) {
      // Study department open hours returns a dayNumber as String... so.
      const openHoursForDate = this.getStudyOpenHour(moment(apiDate, "YYYY-MM-DD").day().toString());
      return openHoursForDate.some(openHour =>
          (openHour.openTime === "" || openHour.openTime === null) &&
          (openHour.closeTime === "" || openHour.closeTime === null) &&
          (openHour.purpose === "mobile")
      );
    },

    validateEditButton(apiDate) {
      return moment(this.today).toISOString() >= moment(apiDate).add(1, "days").add(59, "minutes").add(59, "seconds").toISOString();
    },

    goToReservationDetail(Rid, assistantId) {
      this.setVtabsActiveCompomentStorage('ReservationDetail');
      this.$router.push({name: 'ReservationDetail', params: {Rid: Rid, assistantId}});
    },

    goToRestrictionDetail(Rid, assistantId) {
      this.setVtabsActiveCompomentStorage('RestrictionDetail');
      this.$router.push({name: 'RestrictionDetail', params: {Rid: Rid, assistantId}});
    },

    goToNewReservation(selectedDay, assistantId) {
      this.setVtabsActiveCompomentStorage('ReservationDetailEdit');
      this.$router.push({name: 'ReservationDetailEdit', params: {Rid: "new", selectedDay, assistantId}});
    },

    goToNewRestriction(selectedDay, assistantId) {
      this.setVtabsActiveCompomentStorage('NewRestriction');
      this.$router.push({name: 'NewRestriction', params: {selectedDay, assistantId}});
    },

    showReservations(apiDate) {
      let reservations = this.getQueueItemStatuses;
      let returnReservations = [];

      for (let reservation of reservations)
        if (
            reservation.wantedDay === apiDate
            && reservation.status !== 'cancel'
            && reservation.status !== 'abort'
            && reservation.status !== 'done'
            && reservation.origin !== 'panel'
            && (
                this.validateUserRoles
                || this.getQueueItemByQueueItemStatusId(reservation.id).assistantId === this.assistantId
            )
        )
          returnReservations.push(reservation);

      return returnReservations.sort((a, b) =>
          moment(`${a.actualMeetTime } ${a.wantedDay}`, "HH:mm YYYY-MM-DD").diff(
              moment(`${b.actualMeetTime } ${b.wantedDay}`, "HH:mm YYYY-MM-DD")
          )
      );
    },

    showReservationsHistory(apiDate) {
      let reservations = this.getQueueItemStatuses;
      let returnReservations = [];

      for (let reservation of reservations)
        if (
            reservation.wantedDay === apiDate
            && (
                reservation.status === 'done'
                || reservation.status === 'abort'
                || reservation.status === 'cancel'
            )
            && reservation.origin !== 'panel'
            && (
                this.validateUserRoles
                || this.getQueueItemByQueueItemStatusId(reservation.id).assistantId === this.assistantId
            )
        )
          returnReservations.push(reservation);

      return returnReservations.sort((a, b) =>
          moment(`${a.actualMeetTime } ${a.wantedDay}`, "HH:mm YYYY-MM-DD").diff(
            moment(`${b.actualMeetTime } ${b.wantedDay}`, "HH:mm YYYY-MM-DD")
        )
      );
    },

    showPersonalRestrictionDiv(apiDate) {
      let returnReservations = [];
      let reasons = this.getTypeClosedHoursReasons('person');
      let restrictions = this.getClosedHours.filter((item) => reasons.find(({id}) => item.closedHoursReasonId === id));

      for (let restriction of restrictions)
        if (
            restriction.date === apiDate
            && (
                this.validateUserRoles
                || restriction.assistantId === this.assistantId
            )
            && moment(restriction.date + ' ' + restriction.timeTo, "YYYY-MM-DD HH:mm").isAfter(moment().format("YYYY-MM-DD HH:mm"))
        )
          returnReservations.push(restriction);


      return returnReservations;
    },

    showPersonalRestrictionDivHistory(apiDate) {
      let returnReservations = [];
      let reasons = this.getTypeClosedHoursReasons('person');
      let restrictions = this.getClosedHours.filter((item) => reasons.find(({id}) => item.closedHoursReasonId === id));

      for (let restriction of restrictions)
        if (
            restriction.date === apiDate
            && (
                this.validateUserRoles
                || restriction.assistantId === this.assistantId
            )
            && moment(restriction.date + ' ' + restriction.timeTo, "YYYY-MM-DD HH:mm").isBefore(moment().format("YYYY-MM-DD HH:mm"))
        )
          returnReservations.push(restriction);

      return returnReservations;
    },

    showStudyRestrictionDiv(apiDate) {
      let returnReservations = [];
      let reasons = this.getTypeClosedHoursReasons('department');
      let restrictions = this.getClosedHours.filter((item) => reasons.find(({id}) => item.closedHoursReasonId === id));

      for (let restriction of restrictions)
        if (
            restriction.date === apiDate
            && moment(restriction.date + ' ' + restriction.timeTo, "YYYY-MM-DD HH:mm").isAfter(moment().format("YYYY-MM-DD HH:mm"))
        )
          returnReservations.push(restriction)

      return returnReservations;
    },

    showStudyRestrictionDivHistory(apiDate) {
      let returnReservations = [];
      let reasons = this.getTypeClosedHoursReasons('department');
      let restrictions = this.getClosedHours.filter((item) => reasons.find(({id}) => item.closedHoursReasonId === id));

      for (let restriction of restrictions)
        if (
            restriction.date === apiDate
            && moment(restriction.date + ' ' + restriction.timeTo, "YYYY-MM-DD HH:mm").isBefore(moment().format("YYYY-MM-DD HH:mm"))
        )
          returnReservations.push(restriction);

      return returnReservations;
    },

    formatTime(time) {
      return time.slice(0, 5)
    },

    returnNoNameIfNotXname(id) {
      if (this.getQueueItemByQueueItemStatusId(id).xname !== null && this.getQueueItemByQueueItemStatusId(id).xname !== 'Empty')
        return this.getQueueItemByQueueItemStatusId(id).xname;

      return this.$t('reservations.anon_student');
    },

    setTopicForReservation(id) {
      let topic = "";
      if (this.getQueueItemByQueueItemStatusId(id).studyProblemsItemsIds.length !== 0) {
        for (let item of (this.getQueueItemByQueueItemStatusId(id).studyProblemsItemsIds)) {
          if (topic.length < 15) {
            if (this.getStudyProblemsItem(item).name.length < 20) {
              topic += this.getStudyProblemsItem(item).name + ", ";
            } else {
              return this.getStudyProblemsItem(item).name.slice(0, 40) + "...";
            }
          }
        }
        topic += "..."

      } else {
        topic += this.getStudyField(this.getQueueItemByQueueItemStatusId(id).studyFieldId).name
      }
      return topic;
    },

    disableIfBeforeCurrentDate() {
      return this.EB_setSelectedDate_moment.format("YYYYMMDD") < this.today.format("YYYYMMDD") || this.validateUserRoles;
      //return this.EB_setSelectedDate_moment.format("YYYYMMDD") < this.today.format("YYYYMMDD") || this.isAfterTodayClosingHour() || this.isClosedToday(this.EB_setSelectedDate_moment);
    },

    disableIfBeforeCurrentDateRestriction() {
      return this.EB_setSelectedDate_moment.format("YYYYMMDD") < this.today.format("YYYYMMDD") || this.validateUserRoles || this.isAfterTodayClosingHour() || this.isClosedToday(this.EB_setSelectedDate_moment);
    },

    isAfterTodayClosingHour() {
      let closedHours = this.getStudyOpenHours;
      let today = new Date(this.today);

      let closedHoursForToday = closedHours.find(closedHours => closedHours.dayNumber === (today.getDay()).toString() && closedHours.purpose === "mobile").closeTime;

      if (this.EB_setSelectedDate_moment.format("YYYYMMDD") === this.today.format("YYYYMMDD"))
        return closedHoursForToday < moment().format("HH:mm");

      return false;
    },

    getStateHolidayName(date) {
      //TODO - Make it work
      let holidays = new Holidays('CZ');
      let holiday = holidays.isHoliday(moment(date).toDate());

      if (holiday !== false && holiday.type === 'public') {
        return holiday.name;
      } else {
        return null;
      }
    },

    showStudyRestrictionDivCount(apiDate) {
      let reasons = this.getTypeClosedHoursReasons('department');
      let restrictions = this.getClosedHours.filter((item) => reasons.find(({id}) => item.closedHoursReasonId === id));
      let returnNum = 0;

      for (let restriction of restrictions)
        if (
            restriction.date === apiDate
            && moment(restriction.date + ' ' + restriction.timeTo, "YYYY-MM-DD HH:mm").isBefore(moment().format("YYYY-MM-DD HH:mm"))
        )
          returnNum += 1

      return returnNum;
    },

    showReservationDivCount(apiDate) {
      let reservations = this.getQueueItemStatuses;
      let returnNum = 0;

      for (let reservation of reservations)
        if (
            reservation.wantedDay === apiDate
            && reservation.status !== 'done'
            && reservation.status !== 'cancel'
            && reservation.status !== 'abort'
            && reservation.origin !== "panel"
            && (
                this.validateUserRoles
                || this.getQueueItemByQueueItemStatusId(reservation.id).assistantId === this.assistantId
            )
        )
          returnNum += 1;

      return returnNum;
    },

    showPersonalRestrictionDivCount(apiDate) {
      let reasons = this.getTypeClosedHoursReasons('person');
      let restrictions = this.getClosedHours.filter((item) => reasons.find(({id}) => item.closedHoursReasonId === id));
      let returnNum = 0;

      for (let restriction of restrictions)
        if (
            restriction.date === apiDate
            && (
                this.validateUserRoles
                || restriction.assistantId === this.assistantId
            )
            && moment(restriction.date + ' ' + restriction.timeTo, "YYYY-MM-DD HH:mm").isAfter(moment().format("YYYY-MM-DD HH:mm"))
        )
          returnNum += 1


      return returnNum;
    },

    savePanelMemory() {
      this.setCalendarInfobarPanelStorage(this.panel);
    },

    loadPanelMemory() {
      this.panel = this.calendarInfobarPanelStorage;
    },

    setHistoryReservation() {
      this.historyReservation = !this.historyReservation;
    },

    setHistorySmallRestriction() {
      this.historySmall = !this.historySmall;
    },

    setHistoryBigRestriction() {
      this.historyBig = !this.historyBig;
    },

    returnBoolForShowReservationsHistoryLength() {
      return this.showReservationsHistory(this.EB_setSelectedDate_moment.format('YYYY-MM-DD')).length !== 0;
    },

    returnBoolForShowPersonalRestrictionDivHistoryLength() {
      return this.showPersonalRestrictionDivHistory(this.EB_setSelectedDate_moment.format('YYYY-MM-DD')).length !== 0;
    },
  },
}
</script>

<style scoped>

.date_divs {
  width: 100%;
  display: block;
}

.date_divs_text {
  width: 100%;
  display: flex;
}

.justify_content_center {
  justify-content: center;
}

.info_text_div {
  height: 4.5rem;
  display: block;
  border-radius: 8px;
  margin-bottom: 0.2rem;
  overflow: hidden;
  border: rgba(151, 147, 147, 0.97) 1px solid;
}

.info_text_div_time_span {
  color: #000000;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1;
  width: 8rem;
}

.info_text_div_ticket_span {
  color: #000000;
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1;
  float: right;
}


.info_text_div_item_span {
  margin-left: 0.5rem;
  margin-top: 0;
  font-size: 1em;
  font-weight: 400;
  line-height: 1;
  text-align: left;
}

.reservation {
  background-color: #78BE13;
}

.informative_msg {
  background-color: #F7B500;
}

.closed {
  background-color: #FA6400;
}

.reservation_history {
  background-color: #c0c0c0;
}

.informative_msg_history {
  background-color: #acacac;
}

.closed_history {
  background-color: #9d9d9d;
}

.you_dont_have {
  width: 80%;
  text-align: center;
}

.expansion_header_text {
  font-weight: 400;
  font-size: 1em;
}

.delete_this {
  font-size: medium;
  font-weight: bold;
  color: #4c4c4c;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.history_divider {
  margin: 1rem 0 0.5rem 0 !important;
}

.left_bar {
  width: 5%;
  display: inline-block;
  float: left;
  height: 100%;
}

.right_bar {
  width: 94%;
  display: inline-block;
}
</style>
