import Vue from 'vue';
import translator from '../localization/translator.js';

const translateWord = word => {
  if (!word) return '';
  return translator.translate(word)
};

Vue.filter('translate', translateWord);

export default translateWord;