<template>
    <Loading v-if="!didLoadedAll"></Loading>
    <div v-else class="mt-5">
        <v-data-table
          v-model="selectedItems"
          :headers="headers"
          :items="getAllQuestions"
          loading-text="Načítání..."
          show-select
          :search="$query.questionsSearch"
          :sort-by.sync="$query.sort"
          :sort-desc.sync="$query.desc"
          :items-per-page.sync="$query.rowsPerPage"
          :page.sync="$query.page"
        >

            <template v-slot:top>
                <v-row>
                    <v-col>
                        <v-text-field
                          color="light-green darken-1"
                          v-model="$query.questionsSearch"
                          prepend-icon="search"
                          label="Hledat"
                          clearable
                          hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-checkbox
                          v-model="$query.onlyStudentQuestions"
                          label="Zobrazit jenom neschválené otázky"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:no-data>
                Žádná dostupná data
            </template>

            <template v-slot:item.localizedAttributes.cz.text="{item}">
                <b @click="goToDetail(item.id)"
                      style="font-size: 1rem !important"
                      class="text-xs-left pointer light-green--text">{{ item.localizedAttributes.cz.text }}</b>
            </template>
            <template v-slot:item.points="{item}">
                <span>{{ item.points }}</span>
            </template>
            <template v-slot:item.date="{item}">
                <span>{{ formatDate(item.date) }}</span>
            </template>
            <template v-if="selectedItems.length!==0" v-slot:body.append>
                <v-row>
                    <v-btn small class="actionsDetails mx-5 my-5"
                           @click.stop="dialog = true"
                           :disabled="selectedItems.length === 0"
                    >Smazat
                    </v-btn>
                    <DeleteDialog :method="deleteQuestions" :dialog="dialog"
                                  v-on:childToParent="updateDialog"></DeleteDialog>
                </v-row>
            </template>

        </v-data-table>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import DeleteDialog from "@/components/map/DeleteDialog";
import Loading from "@/components/Loading";

export default {
    name: "Questions",

    components: {DeleteDialog, Loading},

    created() {
        this.fetchAllQuestions();
    },

    data() {
        return {
            selectedItems: [],
            dialog: false,
            headers: [
                {
                    text: 'Název',
                    align: 'left',
                    sortable: true,
                    value: 'localizedAttributes.cz.text',
                    width: '65%'
                },
                {
                    text: 'Body',
                    align: 'left',
                    sortable: true,
                    value: 'points',
                    width: '15%'
                },
                {
                    text: 'Upraveno',
                    align: 'left',
                    sortable: true,
                    value: 'date',
                    width: '20%'
                }
            ]
        }
    },

    computed: {
        ...mapGetters('Questions', ['getQuestions', 'getQuestionsBySubjectId', 'didLoadedQuestions']),

        getAllQuestions() {
            if (this.$query.onlyStudentQuestions) {
                return this.getQuestionsBySubjectId(this.subjectId).filter(q => q.approved === false);
            }
            return this.getQuestionsBySubjectId(this.subjectId);
        },

        didLoadedAll() {
          return this.didLoadedQuestions;
        },

      subjectId(){
        return Number(this.$route.params.subjectId);
      }

    },

    methods: {
        ...mapActions('Questions', ['fetchAllQuestions', 'deleteQuestionById']),


        goToDetail(questionId) {
            this.$router.push({
                name: 'QuestionDetail',
                params: {questionId: questionId, subjectId: this.subjectId}
            });
        },
        formatDate(date) {
            return moment(date).format('lll')
        },
        deleteQuestions() {
            this.selectedItems.forEach((item) => {
                this.deleteQuestionById(item.id);
            });
            this.selectedItems = [];
            this.dialog = false;
        },
        updateDialog() {
            this.dialog = false;
        }
    }

}
</script>

<style scoped>

.actionsDetails {
    background: rgba(0, 0, 0, 0.45) !important;
    color: white !important;
}

</style>