import {get} from "@/services/api";

const state = {
    didLoadedAssistantActivePause: false,
    activePause: null,
};
const getters = {
    didLoadedAssistantActivePause: state => state.didLoadedAssistantActivePause,

    getActivePause: state => state.activePause,

};
const actions = {

    //Fetch ALL
    async fetchActivePause({commit}, assistantId) {
        commit('setDidLoadedAssistantActivePause', false);
        get('study_department/assistant/pause/active/' + assistantId).then((response) => {
            if (response.data.content.id !== null) {
                commit('setActivePause', response.data.content);
            }
            commit('setDidLoadedAssistantActivePause', true);
        });
    },

    unsetActivePause({commit}) {
        commit('unsetActivePause');
    },

    setActivePauseStorage({commit}, value) {
        commit('setActivePause', value);
    }
};

const mutations = {
    setDidLoadedAssistantActivePause: (state, value) => (state.didLoadedAssistantActivePause = value),
    setActivePause: (state, activePause) => (state.activePause = activePause),
    unsetActivePause: (state) => (state.activePause = null),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
