<template>
    <v-container>
        <v-dialog
            @keydown.esc="$emit('close')"
            @keydown.enter="saveQuestion()"
            @click:outside="$emit('close')"
            v-model="show"
            max-width="1200px"
        >
            <v-alert
                v-if="resetAnswers"
                transition="slide-y-transition"
                type="success"
            >
                {{$t('gamification.question_saved_successfully')}}
            </v-alert>
            <v-card>
                <v-row class="align-center ma-0" justify="space-between">
                    <v-col class="py-0" v-if="isNew" cols="auto">
                        <v-card-title class="pb-0">{{$t('gamification.new_question')}}</v-card-title>
                    </v-col>
                    <v-col class="py-0" v-else cols="auto">
                        <v-card-title class="pb-0">{{$t('gamification.test_question')}}</v-card-title>
                    </v-col>
                    <v-col cols="auto">
                        <v-row class="align-center">
                            <v-col class="pb-0">
                                <InfoButton large>{{$t('gamification.question_help_text')}}</InfoButton>
                            </v-col>
                            <v-col class="pb-0">
                                <v-icon style="font-size: 2em;" @click="$emit('close')">
                                    close
                                </v-icon>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <hr class="hor-line">
                <v-card elevation="0" class="px-8" id="inner-card">
                    <v-form @submit.prevent="saveQuestion()" ref="form">
                        <v-row justify="space-between">
                            <v-col cols="12" md="6">
                                <v-text-field
                                    :label="nameOfQuestion"
                                    v-model="copiedQuestion.localizedAttributes.cz.text"
                                    :rules="[required_rule]"
                                    color="#78BE13"
                                    tabindex="0"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field
                                    label="Content of the question"
                                    v-model="copiedQuestion.localizedAttributes.en.text"
                                    :rules="[required_rule]"
                                    color="#78BE13"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="space-between" >
                            <v-col cols="12" md="6">
                                <v-select
                                    :label="$t('gamification.type_of_question')"
                                    :items="typeOfQuestionsList"
                                    v-model="copiedQuestion.type"
                                    color="#78BE13"
                                    @change="copiedQuestion.type !== this.questionTypes.SingleAnswer.value ? copiedQuestion.answers.map((ans) => ans.correct = false) : ''"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field
                                    :label="$t('gamification.number_of_points')"
                                    v-model.number="copiedQuestion.points"
                                    :rules="[required_rule]"
                                    type="number"
                                    color="#78BE13"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <AnswersComponent :answers="this.copiedQuestion.answers" :typeOfAnswers="copiedQuestion.type" :resetedAnswers="resetAnswers" @update-answers="updateAnswers"></AnswersComponent>
                    <v-row class="mx-md-4 mx-0 mb-4 align-center" justify="end">
                        <v-col cols="auto" class="pl-6 pl-md-2">
                            <v-row v-if="copiedQuestion.type !== this.questionTypes.TrueFalse.value">
                                <v-col cols="auto" class="pr-0">
                                    <PrimaryButton @click="addNewAnswer">
                                        <v-icon class="mr-md-2">
                                            add
                                        </v-icon>
                                        {{$t('gamification.add_new_answer')}}
                                    </PrimaryButton>
                                </v-col>
                                <v-col cols="auto">
                                    <InfoButton right>{{$t('gamification.answers_info')}}</InfoButton>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="text-md-right text-left pl-6">
                            <span class="small-desc">{{$t('gamification.what_you_choose')}}</span>
                        </v-col>
                    </v-row>
                </v-card>
                <v-row v-if="!ansFilled || !hasCorrectAns" class="px-6 ma-0 align-center" justify="start">
                    <v-col cols="auto">
                        <v-icon color="#ff5252">error</v-icon>
                    </v-col>
                    <v-col cols="10">
                        <span class="small-alert">{{$t('gamification.not_filled_question_fields')}}</span>
                    </v-col>
                </v-row>
                <hr class="hor-line">
                <v-row class="pa-4 ma-0" justify="end">
                    <v-btn color="#78BE13" text @click="saveQuestion()">
                        {{$t('entities.save')}}
                    </v-btn>
                </v-row>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import AnswersComponent from "@/components/gamification/AnswersComponent.vue";
import InfoButton from "@/components/buttons/InfoButton.vue";

export default {
    name: "CraftingQuestionDialog",
    components: {
        InfoButton,
        PrimaryButton,
        AnswersComponent
    },

    data() {
        return {
            question: null,
            show: this.showDialog,
            hasCorrectAns: true,
            required_rule: value => !!value || 'Povinné',
            ansFilled: true,
            resetAnswers: false,
            copiedQuestion: null,
            questionTypes: null,
            nameOfQuestion: "Obsah otázky",
        }
    },

    props: {
        showDialog: {
            type: Boolean,
            defaultValue: false
        },

        isNew: {
            type: Boolean,
            defaultValue: false
        },

        questionId: {
            type: Number,
            defaultValue: 0,
        },
    },

    watch: {
        resetAnswers(newVal, oldVal) {
            if (newVal !== oldVal) {
                setTimeout(() => this.resetAnswers = false, 4000)

            }
        },
    },

    async created() {
        this.questionTypes = this.getQuestionTypes

        if (!this.isNew) {
            this.question = this.getQuestionById(this.questionId)

            // creates deep copy of question so it can be reverted when user clicks cross
            this.copiedQuestion = JSON.parse(JSON.stringify(this.question))
        } else {
            this.prepareQuestionFromScratch()

            // creates 2 empty answer because question has to have at least two answers
            await this.addNewAnswer()
            await this.addNewAnswer()
        }
    },

    computed: {
        ...mapGetters('Questions', ['getQuestionById', 'getEmptyQuestion', 'getQuestionTypes']),
        ...mapGetters('Answers', ['prepareNewAnswer']),

        typeOfQuestionsList() {
            return Object.values(this.questionTypes).map(({ text, value }) => ({
                text,
                value: value,
            }))
        },

        subjectId() {
            return Number(this.$route.params.subjectId)
        },
    },

    methods: {
        ...mapActions('Questions', ['updateQuestion', 'fetchAllQuestions', 'createQuestion', 'updateQuestionInStore', 'prepareNewQuestion']),
        ...mapActions('Answers', ['createAnswer', 'updateAnswer', 'deleteAnswerById']),


        async saveQuestion() {
            this.checkAnswers()

            // exit if not all answers are filled or there is no correct answer
            if (!this.$refs.form.validate() || !this.hasCorrectAns || !this.ansFilled) {
                return
            }

            if (this.isNew) {
                this.copiedQuestion.subjectId = this.subjectId
                this.copiedQuestion.id = await this.createQuestion({item: this.copiedQuestion})
            }

            let newYesNoAnswers = []

            for (let i = 0; i < this.copiedQuestion.answers.length; i++) {
                const answer = this.copiedQuestion.answers[i]
                const isTrueFalseQuestion = this.copiedQuestion.type === this.questionTypes.TrueFalse.value

                // if answer id is smaller than 0 it means NEW answer was prepared and it needs to be created on backend so we can get correct id of answer
                if (isTrueFalseQuestion && answer.id < 0 && !answer.toDelete) {
                    answer.questionId = this.copiedQuestion.id
                    answer.id = await this.createAnswer({item: answer})
                    newYesNoAnswers.push(answer)
                }

                if (isTrueFalseQuestion && answer.toDelete && !this.isNew) {
                    await this.deleteAnswerById(answer.id)
                }

                if (!isTrueFalseQuestion && answer.id < 0) {
                    answer.questionId = this.copiedQuestion.id
                    answer.id = await this.createAnswer({item: answer})
                } else if (!answer.toDelete) {
                    await this.updateAnswer({item: answer})
                }
            }

            if (this.copiedQuestion.type === this.questionTypes.TrueFalse.value && newYesNoAnswers.length > 0) {
                this.copiedQuestion.answers = newYesNoAnswers
            }

            await this.updateQuestion({item: this.copiedQuestion})

            if (!this.isNew) {
                this.$emit('close')
            } else {
                this.prepareQuestionFromScratch()
                this.resetAnswers = true
            }
            this.$refs.form.resetValidation()
        },

        addNewAnswer() {
            const answer = this.prepareNewAnswer()
            answer.id = -this.copiedQuestion.answers.length-1
            this.copiedQuestion.answers.push(answer)
        },

        updateAnswers(newAnswers) {
            this.copiedQuestion.answers = newAnswers
        },

        prepareQuestionFromScratch() {
            this.prepareNewQuestion()
            this.copiedQuestion = this.getEmptyQuestion
            this.copiedQuestion.subjectId = this.subjectId
            this.copiedQuestion.type = this.questionTypes.SingleAnswer.value
        },

        checkAnswers() {
            // check if there is at least one correct answer
            this.hasCorrectAns = this.copiedQuestion.answers.some(ans => ans.correct && !ans.toDelete)

            // check if all answers have filled text fields
            this.ansFilled = this.copiedQuestion.answers.every(ans => {
                return (ans.toDelete || (ans.localizedAttributes.cz.text && ans.localizedAttributes.en.text))
            });
        }
    }
}
</script>
<style scoped>
    .small-desc {
        font-size: 0.8em;
        margin: 0;
        color: #6D7278;
    }

    .small-alert {
        margin: 0;
        color: #ff5252;
    }

    #inner-card {
        border-radius: 14px;
        max-height: 62vh;
        overflow-y: auto;
    }

    .hor-line {
        border: 1px solid #eaeaea;
        margin-top: 2vh;
    }
</style>