<template>
  <div>
    <!-- Dialogs -->
    <ConfirmDialog 
      :model="showDeleteDialog" 
      :onConfirm="attemptDeleteDisplay"
      :onCancel="() => displayToDelete = undefined">
      <div v-if="displayToDelete">{{`Přejete si smazat obrazovku s názvem "${displayToDelete.name}"?`}}</div>
      Tato operace je nevratná.
    </ConfirmDialog>
    <Alert 
      :model="errorOnDelete" 
      :onConfirm="() => errorOnDelete = false">
      Zvolenou obrazovku se nepodařilo smazat.
    </Alert>
    <!-- Module header -->
    <BackButton :target="{name: 'displaysUnits'}">Výběr fakult a součástí</BackButton>
    <ModuleHeader>
      <h2 id="dashboard-header">Informační panely{{didLoadAdministrators ? ` - ${selectedUnit.localizedAttributes.cz.name}` : ''}}</h2>
      <v-btn id="displays-guide" outlined rounded color="grey darken-1" @click="goToGuide()">
        <v-icon left>info</v-icon> Jste tu poprvé?
      </v-btn>  
    </ModuleHeader>
    <!-- Module navigation -->
    <LightButton @click="$router.push({name:'announcements'});" class="mt-1 pl-0 pull-left">Spravovat oznámení</LightButton>
    <LightButton @click="$router.push({name:'announcementTypes'});" class="mt-1 pull-left">Spravovat typy oznámení</LightButton>
    <!-- Dashboard -->
    <Loading v-if="!didLoadDashboard && !didLoadDisplays" class="mt-12"/>
    <v-container fluid>
      <v-container class="text-center mt-4" v-if="getDisplays.length === 0 && didLoadDisplays">
        <Placeholder id="no-displays-placeholder" />
        <v-row>
            <v-col>
              <p class="mb-10">Dosud nebyly přidány žádné obrazovky...</p>
            </v-col>
        </v-row>
        <PrimaryButton id="add-display" :to="{name: 'displayDetail', params: {displayId: 'new'}}">+ Nová obrazovka</PrimaryButton>
      </v-container>
      <div v-if="getDisplays.length > 0 && didLoadDisplays">
        <div v-if="responseMessage.activeDisplays.length === 0 && didLoadDashboard" class="mt-4">Aktuálně nejsou v provozu žádné obrazovky...<InfoButton bottom>Zobrazování obsahu je na cílovém zařízení možné spustit přechodem na webovou adresu přidruženou zvolené obrazovce.</InfoButton></div>
        <div v-if="responseMessage.activeDisplays.length > 0">
          <ModuleHeader class="mt-4 mb-8">
            <RefreshButton noMargin customIcon="reset_tv" @click="refreshDevices" title="Vynutit obnovení prezentace na cílových zařízeních">
              <h4 class="mr-4">Právě v provozu</h4>
            </RefreshButton>
          </ModuleHeader>
          <v-layout wrap>
            <v-flex xs12 sm6 md4 lg3
              v-for="(display, idx) in responseMessage.activeDisplays"
              :key="`display-${idx}-${display.name}`"
            >
              <DashboardItem :display="display" />
            </v-flex>
          </v-layout>
        </div>
        <Loading v-if="!didLoadDashboard" class="mt-12"/>
        <!-- List of displays -->
        <ModuleHeader class="mt-4 mb-3">
          <RefreshButton noMargin @click="refetchDisplays" title="Aktualizovat data">
            <h4 class="mr-4">Neaktivní obrazovky</h4>
          </RefreshButton>
          <PrimaryButton id="add-display" :to="{name: 'displayDetail', params: {displayId: 'new'}}">+ Nová</PrimaryButton>
        </ModuleHeader>
        <template class="displays-table">
          <v-data-table
            class="d-none d-sm-block"
            :headers="headers"
            :items="inactiveDisplays"
          >
            <template v-slot:item.name="{ item }">
              <TitledText id="display-name" :title="item.name" :text="item.name | ellipsis(55)" />
            </template>
            <template v-slot:item.id="{ item }">
              <a class="link light-green--text" v-bind:href="getDisplayUrl(item.id)" target="blank" title="Otevřít obrazovku v novém okně">{{getDisplayUrl(item.id)}}</a>
            </template>
            <template v-slot:item.mode="{ item }">
              <TitledText :title="`Maximálně ${item.maxParallels} paralelní oznámení`" :text="item.maxParallels.toString()" />
            </template>
            <template v-slot:item.period="{ item }">
              <TitledText :title="`Obnova po ${item.refreshPeriod} vteřinách`" :text="item.refreshPeriod.toString()" />
            </template>
            <template v-slot:item.actions="{ item }">
              <EditIcon id="edit-display" @click="goToDetail(item.id)" title="Upravit obrazovku"/>
              <DeleteIcon id="remove-display" @click="() => displayToDelete = item" title="Smazat obrazovku"/>
            </template>
          </v-data-table>
          <!-- List of displays for smaller screens -->
          <v-data-iterator
            class="d-block d-sm-none mt-1"
            :items="getDisplays"
            :hide-default-footer="true"
            :disable-pagination="true"
            :hide-default-header="false"
          >
            <template v-slot:no-data>Žádná dostupná data</template>
            <template v-slot:default="props">
              <v-row>
                <v-col v-for="item in props.items" :key="item.id" cols="12" class="pt-2">
                  <mobile-data-table-row
                    @click="goToDetail(item.id)"
                    @action="() => displayToDelete = item"
                  >
                    <template v-slot:title>{{ item.name  }}</template>
                    <template v-slot:icon1>link</template>
                    <template v-slot:subtitle1> <a v-bind:href="getDisplayUrl(item.id)">{{ getDisplayUrl(item.id)  }}</a></template>
                    <template v-slot:action_button>delete_outline</template>
                    <template v-slot:label_header1>PARALEL. ZOBRAZENÍ</template>
                    <template v-slot:label_content1>{{ item.maxParallels }}</template>
                    <template v-slot:label_header2>PERIODA OBNOVY [s]</template>
                    <template v-slot:label_content2>{{ item.refreshPeriod }}</template>
                  </mobile-data-table-row>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </template>
      </div>
    </v-container>  
  </div>
</template>

<script>
  import { config } from '@/config';
  import { mapActions, mapGetters } from 'vuex';
  import { uuid } from 'vue-uuid';
  import SockJS from "sockjs-client";
  import Stomp from "webstomp-client";
  import { displaysHeaders } from './displays-helpers';
  import Alert from '../../components/dialogs/Alert';
  import ConfirmDialog from '../../components/dialogs/ConfirmDialog';
  import DashboardItem from "../../components/displays/DashboardItem";
  import LightButton from "../../components/buttons/LightButton";
  import InfoButton from "../../components/buttons/InfoButton";
  import RefreshButton from "../../components/buttons/RefreshButton";
  import PrimaryButton from "../../components/buttons/PrimaryButton";
  import BackButton from "../../components/layout/BackButton";
  import Loading from "../../components/Loading";
  import ModuleHeader from "../../components/layout/ModuleHeader";
  import DeleteIcon from "../../components/buttons/DeleteIcon";
  import EditIcon from "../../components/buttons/EditIcon";
  import MobileDataTableRow from '../../components/layout/MobileDataTableRow.vue';
  import TitledText from "../../components/text/TitledText";
  import Placeholder from '../../assets/displays/no_displays.svg';

  export default {
    name: "Displays",

    components: {
      Alert,
      BackButton,
      ConfirmDialog,
      DashboardItem,
      LightButton,
      Loading,
      DeleteIcon,
      EditIcon,
      MobileDataTableRow,
      ModuleHeader,
      TitledText,
      InfoButton,
      PrimaryButton,
      Placeholder,
      RefreshButton
    },
      
    data() {
      return {
        connected: false,
        connectionId: undefined,
        didLoadDashboard: false,
        requestMessage: {
          connectionId: undefined,
          unitId: undefined,
        },
        responseMessage: {
          activeDisplays: []
        },
        displayToDelete: undefined,
        errorOnDelete: false,
        headers: displaysHeaders
      };
    },

    async created() {
      await this.fetchAdministrators();
      await this.fetchDisplays(this.$route.params.unitId);
      this.requestMessage.connectionId = uuid.v4();
      this.requestMessage.unitId = this.$route.params.unitId;
      window.addEventListener('beforeunload', this.handler);
      this.connect();
    },

    destroyed() {
      this.handler();
    },

    methods: {
      ...mapActions('displaysAdmins', [
        'fetchAdministrators'
      ]),

      ...mapActions('displays', [
        'fetchDisplays', 
        'deleteDisplay',
        'refreshDisplays'
      ]),

      refetchDisplays() {
        this.fetchDisplays(this.$route.params.unitId);
      },

       refreshDevices() {
        this.refreshDisplays();
      },

      attemptDeleteDisplay() {
        this.deleteDisplay(this.displayToDelete.id).catch(() => {
          this.errorOnDelete = true;
        })
        this.displayToDelete = undefined;
      },

      goToDetail(displayId) {
        this.$router.push({
          name: "displayDetail",
          params: { displayId: displayId }
        });
      },

      goToGuide() {
        this.$router.push({
          name: "displaysGuide",
        });
      },

      getDisplayUrl(id) {
        return `${config.displaysUrl}?id=${id}`
      },

      connect() {
        if (this.requestMessage.connectionId) {
          this.socket = new SockJS(config.serverUrl + "socket");
          this.stompClient = Stomp.over(this.socket);
          this.stompClient.connect({}, 
            () => {
              this.connected = true;
              this.stompClient.subscribe(`/displays/dashboard`, message => {
                this.didLoadDashboard = true;
                this.setMessage(message.body);
              });
              if (this.stompClient && this.stompClient.connected) {
                this.stompClient.send("/app/displays/request-dashboard", JSON.stringify(this.requestMessage), {});
              }
            },
            error => {
              console.error('Socket connection error:', error);
              console.info('Reconnecting websocket in 10 seconds.');
              this.connected = false;
              this.didLoadDashboard = false;
              setTimeout(() => this.connect(), 10000);
            },
          );
        }
      },

      setMessage(msg) {
        if (JSON.stringify(this.responseMessage) !== JSON.stringify(JSON.parse(msg))) {
          this.responseMessage = JSON.parse(msg);
        }
      },

      handler: function handler() {
        if (this.stompClient) {
          this.stompClient.send("/app/displays/disconnect-dashboard", JSON.stringify(this.requestMessage), {});
          this.stompClient.disconnect(); 
        }
        this.connected = false;
      },

      openDashboard() {
        window.open(
          window.location.host.includes('mm.mendelu.cz')
            ? 'https://tv.mm.mendelu.cz/'
            : window.location.host.includes('debug.mm.spatialhub.cz')
            ? 'https://tv.debug.mm.spatialhub.cz/'
            : 'http://localhost:8083',
          '_blank'
        );
      }
    },

    computed: {
      ...mapGetters('displaysAdmins', [
        'didLoadAdministrators', 
        'getAdministrators'
      ]),

      ...mapGetters('displays', [
        'getDisplays', 
        'didLoadDisplays'
      ]),

      ...mapGetters('user', ['username']),

      showDeleteDialog() {
        return this.displayToDelete !== undefined
      },

      inactiveDisplays() {
        return this.getDisplays.filter(display => !this.responseMessage.activeDisplays.some(other => other.id === display.id));
      },

      selectedUnit() {
        return this.getAdministrators.find(user => user.xlogin === this.username)?.faculties?.find(faculty => faculty.id === Number(this.$route.params.unitId));
      }
    },
  }
</script>

<style scoped>
  p {
      color: #3F4349;
  }

  .displays-table {
    margin-top: 1rem;
    width: 100%;
  }

  a.link {
    text-decoration: none;
    font-weight: bold;
  }

  .refresh-icon {
    height: fit-content;
    margin: 0;
  }
</style>