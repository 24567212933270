<template>
	<div class="scenarios">
		<v-data-table
			id="scenarios"
			:headers="getScenarioHeaders"
			:items="scenarioList"
			:custom-sort="customSort"
			show-select
			v-model="selectedItems"
			no-data-text = "Žádné údaje"
			:loading = "loading && !didLoadScenarios"
			loading-text="Načítání..."
			no-results-text="Žádné údaje"
      :sort-by.sync="$query.sort"
      :sort-desc.sync="$query.desc"
      :items-per-page.sync="$query.rowsPerPage"
      :page.sync="$query.page"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-dialog v-model="deleteSingleDialog" max-width="500px">
						<v-card>
							<v-card-title><h5 class="text-h5 ma-auto">Opravdu chcete smazat vybrané prohlídky?</h5></v-card-title>
							<v-card-actions>
								<v-spacer/>
								<v-btn color="blue darken-1" text @click="deleteCancel">Zrušit</v-btn>
								<v-btn dark color="red darken-1" text @click="deleteConfirm">Potvrdit</v-btn>
								<v-spacer/>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<template v-slot:item.name="{ item }" >
				<div v-if="item.localizedAttributes.cz">
					<b class="pointer light-green--text" @click="goToDetail(item.id)">{{ item.localizedAttributes.cz.name }}</b>
				</div>
			</template>
			<template v-slot:item.description="{ item }">
				{{ item.localizedAttributes.cz.description | ellipsis(70) }}
			</template>
			<template v-slot:item.amount="{ item }">
				{{ getNumberOfLocations(item) }}
			</template>
			<template v-if="selectedItems.length > 0" v-slot:footer>
				<v-toolbar flat>
					<v-btn dark color="red darken-1" text @click="deleteItems">Smazat vybrané prohlídky</v-btn>
				</v-toolbar>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from "vuex";

	export default {
		name: "Scenarios",
		data(){
			return {
				loading: true,
				scenarioList: [],
				deleteSingleDialog: false,
				selectedItems: [],
			}
		},

		mounted() {
			this.reloadData();
		},

		computed: {
			...mapGetters('Scenarios', ['scenarios', 'getScenarioHeaders', 'didLoadScenarios']),

			getScenarios(){
				return this.scenarios;
			},
		},

		watch: {
			getScenarios() {
				this.scenarioList = this.getScenarios;
			}
		},

		methods: {
			...mapActions('Scenarios', ['fetchScenarios', 'deleteScenario']),


			/**
			 * Redirect to scenario detailby it's ID.
			 * @param scenarioId
			 */
			goToDetail(scenarioId){
				this.$router.push({name: 'scenarioDetail', params: {id: scenarioId}});
			},
			/**
			 * Helper method for localised string comparison.
			 */
			comp(a, b){
				return ('' + a).localeCompare(b);
			},
			/**
			 * Custom method for sorting data.
			 */
			customSort(items, index, isDesc) {
				items.sort((a, b) => {
					if ( index[0] === 'name' ){
						if ( !isDesc[0] ){
							return this.comp(a.localizedAttributes.cz.name, b.localizedAttributes.cz.name);
						} else {
							return this.comp(b.localizedAttributes.cz.name, a.localizedAttributes.cz.name);
						}
					} else if ( index[0] === 'description' ){
						if ( !isDesc[0] ){
							return this.comp(a.localizedAttributes.cz.description, b.localizedAttributes.cz.description);
						} else {
							return this.comp(b.localizedAttributes.cz.description, a.localizedAttributes.cz.description);
						}
					} else if ( index[0] === 'amount' ){
						if ( !isDesc[0] ){
							return this.comp(this.getNumberOfLocations(a), this.getNumberOfLocations(b));
						} else {
							return this.comp(this.getNumberOfLocations(b), this.getNumberOfLocations(a));
						}
					}
				})
				return items
			},
			/**
			 * Helper method to calculate number of locations within scenario.
			 * @param scenario
			 * @returns {number}
			 */
			getNumberOfLocations(scenario){
				return Object.keys(scenario.locations).length;
			},

			/**
			 * Method that downloads actual data from server.
			 * @returns {Promise<void>}
			 */
			async reloadData() {
				await this.fetchScenarios().then(() => {
					this.scenarioList = this.getScenarios;
					this.loading = false;
				});
			},

			/**
			 * Opens dialog for deletion of scenarios.
			 */
			deleteItems(){
				this.deleteSingleDialog = true;
			},

			/**
			 * Proceeds to delete selected scenarios.
			 */
			deleteConfirm(){
				this.scenarioList.forEach( (item) => {
					if ( this.selectedItems.includes(item)) {
						this.deleteScenario(item.id);
					}
				});
				this.deleteSingleDialog = false;
				this.selectedItems = [];
			},

			/**
			 * Cancels scenario deletion.
			 */
			deleteCancel(){
				this.deleteSingleDialog = false;
			},
		},
	}
</script>

<style scoped>
	.no-data {
		display: flex;
		align-items: center;
		justify-content: center;
		color: rgba(0,0,0,0.75);
	}
	.actions i, .actions button {
		font-size: 16px;
		margin: 3px;
	}
</style>