import Vue from 'vue';

export const getLocalizedAttribute = (object, key, language) => {
  if (
      object.localizedAttributes &&
      object.localizedAttributes[language]
  ) {
    return object.localizedAttributes[language][key];
  } else {
    return null;
  }
};

Vue.filter('localized', getLocalizedAttribute);