import { get, post, del } from '@/services/api'
import FormData from 'form-data'

const state = {
  didLoadAnnouncementImages: false,
  announcementImagesInfo: [],
};

const getters = {
  didLoadAnnouncementImages: state => state.didLoadAnnouncementImages,
  getAnnouncementImages: state => state.announcementImagesInfo
};

const actions = {
  fetchAnnouncementImagesInfo({ commit }, data) {
    commit('startedDataDownload');
    return new Promise((resolve) => {
        get(`displays/images/${data.announcementId}`)
          .then((response) => {
            commit('setAnnouncementImagesInfo', response.data.items)
            commit('finishedDataDownload');
            resolve();
          });
      });
  },

  deleteAnnouncementImages({ commit }, data) {
    return new Promise((resolve, reject) => {
      del(`displays/images/${data.announcementId}/${data.imagesIds}`)
        .then((response) => {
          commit('filterAnnouncementImagesInfo', data.imageId)
          resolve(response.data);
        })
        .catch(e => {
            console.log(e)
            reject();
        });
    });
  },

  createAnnouncementImages({ commit }, data) {
    let formData = new FormData();
    data.images.forEach((data) => formData.append('files', data.file, data.file.name));
    return new Promise((resolve, reject) => {
      post('displays/images/' + data.id, formData, { 'content-type': 'multipart/form-data'})
        .then((response) => {
            commit('addAnnouncementImageInfo', response.data);
            resolve(response.data.content);
        })
        .catch(e => {
            console.log(e)
            reject();
        });
    });
  },
};

const mutations = {
  startedDataDownload: state => state.didLoadAnnouncementImages = false,
  finishedDataDownload: state => state.didLoadAnnouncementImages = true,
  setAnnouncementImagesInfo: ( state, data ) => (state.announcementImagesInfo = data),
  filterAnnouncementImagesInfo: ( state, id ) => (state.announcementImagesInfo = state.announcementImagesInfo.filter(image => image.id !== id)),   // used for delete
  addAnnouncementImageInfo: ( state, item ) => (state.announcementImagesInfo.push(item)),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
