<template>
    <div class="reports">
        <h2>
            Uživatelé
            <i>(Vyžaduje separátní přihlašovací údaje.)</i>
        </h2>
        <iframe :src="userManagementUrl"/>
    </div>
</template>

<script>
    import {config} from "../../config";

    export default {
        name: "Users",
        computed: {
            userManagementUrl() {
                return config.authServer.url + '/admin/master/console/#/realms/' + config.authServer.realm + '/users';
            }
        },
    }
</script>

<style scoped>
    i {
        font-size: small;
    }
    iframe {
        width: 100%;
        height: 80vh;
    }
</style>
