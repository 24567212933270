<template>
  <Loading v-if="!didLoadData"/>
  <div v-else>
    <!-- Dialogs -->
    <ConfirmDialog 
      :model="showDeleteDialog" 
      :onConfirm="attemptDeleteAnnouncement"
      :onCancel="() => announcementToDelete = undefined">
      <div v-if="announcementToDelete">{{`Přejete si smazat oznámení s titulkem "${announcementToDelete.title}"?`}}</div>
      Tato operace je nevratná.
    </ConfirmDialog>
    <Alert 
      :model="errorOnDelete" 
      :onConfirm="() => errorOnDelete = false">
      Zvolené oznámení se nepodařilo smazat.
    </Alert>
    <BackButton :target="{name: 'unitDetail'}">Informační panely</BackButton>
    <v-flex>
      <!-- Module header -->
      <ModuleHeader v-if="getLocalizedAnnouncements.length > 0">
        <RefreshButton @click="refreshAnnouncements" title="Aktualizovat data"><h2>Oznámení{{didLoadAdministrators ? ` - ${selectedUnit.localizedAttributes.cz.name}` : ''}}</h2></RefreshButton>
        <PrimaryButton id="add-announcement" :to="{name: 'announcementDetail', params: {announcementId: 'new'}}">+ Nové</PrimaryButton>                
      </ModuleHeader>
      <v-container v-if="getLocalizedAnnouncements.length > 0">
        <!-- List of announcements -->
        <v-row>
          <v-col class="d-none d-sm-block pt-0" lg="4" md="6" sm="12">
            <AnnouncementTypeSelect 
              :announcementTypes="getAnnouncementTypes"
              :clearable="true"
              :value="$query.search"
              v-on:change="convertFilter"
            />
          </v-col>
        </v-row>
        <v-data-table
          class="d-none d-sm-block"
          :search="$query.search.toString()"
          :custom-filter="announcementTypesFilter"
          :headers="headers"
          :items="getLocalizedAnnouncements"
          :sort-by.sync="$query.sort"
          :sort-desc.sync="$query.desc"
          :disable-pagination="true"
          :hide-default-footer="true"
        >
          <template v-slot:item.title="{ item }">
            <b id="announcement-title" v-bind:title="item.title" class="pointer light-green--text" @click="goToDetail(item.id)">
              {{ item.title | ellipsis(40) }}
            </b>
          </template>
          <template v-slot:item.isPermanent="{ item }">
            <v-icon>{{item.isPermanent ? 'done' : 'block'}}</v-icon>
          </template>
          <template v-slot:item.blockParallel="{ item }">
            <v-icon>{{item.blockParallel ? 'block' : 'done'}}</v-icon>
          </template>
          <template v-slot:item.displayTo="{ item }">
            <span id="announcement-displayings" v-if="item.displayings.length > 0">
              {{`od ${formatDate(item.displayFrom)} ${item.displayTo ? ` do ${formatDate(item.displayTo)}` : ''}`}}
            </span>
            <span v-else id="announcement-displayings" class="warning-text">nevybrán čas</span>
          </template>
          <template v-slot:item.announcementTypeId="{ item }">{{ getAnnouncementTypeNameFromId(item.announcementTypeId) }}</template>
          <template v-slot:item.action="{ item }">
            <v-icon id="edit-announcement" @click="goToDetail(item.id)" title="Upravit oznámení">tune</v-icon>
            <DeleteIcon id="remove-announcement" @click="() => announcementToDelete = item" title="Smazat oznámení"></DeleteIcon>
          </template>
        </v-data-table>
        <!-- List of announcements for smaller screens -->
        <MobileFiltering
          class="d-block d-sm-none"
          :items="dataForMobileSelect"
          v-on:input="convertFilter"
        >
          <template v-slot:title>FILTROVÁNÍ</template>
        </MobileFiltering>
        <MobileSorting
          class="d-block d-sm-none py-2"
          tag="Titulek"
          @sort="reverseSortByTitle()"
        >
          <template v-slot:title>ŘAZENÍ</template>
        </MobileSorting>
        <v-data-iterator
          class="d-block d-sm-none pt-1"
          :items="getLocalizedAnnouncements"
          :search="this.$query.search.toString()"
          :custom-filter="filterAllByType"
          :hide-default-footer="true"
          :disable-pagination="true"
          :hide-default-header="false"
          :sort-by.sync="$query.sort"
          :sort-desc.sync="$query.desc"
        >
          <template v-slot:no-data>Žádná dostupná data</template>
          <template v-slot:default="props">
            <v-row>
              <v-col v-for="item in props.items" :key="item.name" cols="12">
                <mobile-data-table-row @click="goToDetail(item.id)" @action="() => announcementToDelete = item">
                  <template v-slot:number>{{item.id}}</template>
                  <template v-slot:title>{{ item.title | ellipsis(24) }}</template>
                  <template v-slot:subtitle1>stále viditelné</template>
                  <template v-slot:subtitle2>paralelní zobrazování</template>
                  <template v-slot:icon1>{{ item.isPermanent ? 'done' : 'block'}}                </template>
                  <template v-slot:icon2>{{ item.blockParallel ? 'block' : 'done'}} </template>
                  <template v-slot:label_header1>ZOBRAZOVÁNÍ</template>
                  <template v-slot:label_header2>TYP</template>
                  <template v-slot:label_content1>{{`${formatDate(item.displayFrom)} ${item.displayTo ? ` - ${formatDate(item.displayTo)}` : ''}`}}</template>
                  <template v-slot:label_content2>{{ getAnnouncementTypeNameFromId(item.announcementTypeId) }}</template>
                  <template v-slot:action_button>delete_outline</template>
                </mobile-data-table-row>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-container>
      <v-container class="text-center mt-12" v-else>
        <Placeholder id="no-announcements-placeholder" />
        <v-row>
            <v-col>
              <p class="mt-6 mb-10">Dosud nebyla vytvořena žádná oznámení...</p>
            </v-col>
        </v-row>
        <PrimaryButton id="add-announcement" :to="{name: 'announcementDetail', params: {announcementId: 'new'}}">+ Nové oznámení</PrimaryButton>
      </v-container>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';
import Alert from '../../../components/dialogs/Alert';
import { announcementsHeaders } from './announcements-helpers';
import AnnouncementTypeSelect from '../../../components/displays/AnnouncementTypeSelect';
import BackButton from "../../../components/layout/BackButton";
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import DeleteIcon from "../../../components/buttons/DeleteIcon";
import Loading from "../../../components/Loading";
import MobileDataTableRow from '../../../components/layout/MobileDataTableRow.vue';
import MobileFiltering from '../../../components/layout/MobileFiltering.vue';
import MobileSorting from '../../../components/layout/MobileSorting.vue';
import ModuleHeader from "../../../components/layout/ModuleHeader";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import RefreshButton from "../../../components/buttons/RefreshButton";
import Placeholder from '../../../assets/displays/no_announcements.svg';

export default {
  name: "Announcements",

  components: {
    AnnouncementTypeSelect,
    BackButton,
    DeleteIcon,
    Loading,
    ModuleHeader,
    PrimaryButton,
    RefreshButton,
    MobileDataTableRow,
    MobileFiltering,
    MobileSorting,
    ConfirmDialog,
    Alert,
    Placeholder
  },

  async created() {
    await this.fetchAdministrators();
    await this.fetchAnnouncements(this.$route.params.unitId);
    await this.fetchAnnouncementTypes(this.$route.params.unitId);
  },

  data() {
    return {
      selected: [],
      headers: announcementsHeaders,
      announcementToDelete: undefined,
      errorOnDelete: false,
    };
  },

  computed: {
    ...mapGetters('displaysAdmins', [
      'didLoadAdministrators', 
      'getAdministrators'
    ]),

    ...mapGetters('announcementTypes', [
      "getAnnouncementTypes", 
      "didLoadAnnouncementTypes"
    ]),

    ...mapGetters('announcements', [
      "getLocalizedAnnouncements",
      "didLoadAnnouncements"
    ]),

    ...mapGetters('user', ['username']),

    didLoadData() { 
      return this.didLoadAnnouncements && this.didLoadAnnouncementTypes;
    },

    showDeleteDialog() {
      return this.announcementToDelete !== undefined;
    },

    search(){
      return Number.isNaN(this.$query.search) ? null : this.$query.search
    },

    dataForMobileSelect(){
      return this.getAnnouncementTypes.map(i => ({text: i.name, value: i.id}))
    },

    selectedUnit() {
      return this.getAdministrators.find(user => user.xlogin === this.username)?.faculties?.find(faculty => faculty.id === Number(this.$route.params.unitId));
    }
  },

  methods: {
    ...mapActions('displaysAdmins', [
      'fetchAdministrators'
    ]),
    
    ...mapActions('announcements', [
      "deleteAnnouncement",
      "fetchAnnouncements"
    ]),

    ...mapActions('announcementTypes', ["fetchAnnouncementTypes"]),

    refreshAnnouncements() {
      this.fetchAnnouncements(this.$route.params.unitId);
      this.fetchAnnouncementTypes(this.$route.params.unitId);
    },

    reverseSortByTitle(){
      this.$query.desc = !this.$query.desc;
    },

    announcementTypesFilter(value, search, item) {
      return this.search === null || item.announcementTypeId === this.search
    },

    filterAllByType(items){
      return items.filter( i => this.search === null || i.announcementTypeId === this.search)
    },

    // select returns number but filter requires string
    convertFilter(value) {
      this.$query.search = value === null ? null : value.toString();
    },

    attemptDeleteAnnouncement() {
      this.deleteAnnouncement(this.announcementToDelete.id).catch(() => {
        this.errorOnDelete = true;
      })
      this.announcementToDelete = undefined;
    },

    goToDetail(announcementId) {
      this.$router.push({
        name: "announcementDetail",
        params: { announcementId }
      });
    },

    getAnnouncementTypeNameFromId(id) {
      return this.getAnnouncementTypes.find(type => type.id == id)?.name || "-";
    },

    formatDate(timestamp) {
      return moment.unix(timestamp).format('ll');
    }
  }
};
</script>

<style scoped>
  .justify-center {
    display: -webkit-box;
  }

  .warning-text {
    color: rgb(255, 82, 82);
  }

  .container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    min-width: 100%;
  }
</style>
