<template>
    <div v-if="numberOfSoftware">
        <v-row class="align-center justify-sm-center justify-md-start" no-gutters>
            <v-col cols="auto" class="pr-2">
                <v-icon class="pr-0">category</v-icon>
            </v-col>
            <v-col class="pl-0">
                <Autocomplete
                    :label="categoryLabel()"
                    :items="filteredSoftware.categoryNames"
                    :value="categorySearch"
                    hide-details=true
                    :clearable=true
                    @change="setCategorySearch"
                />
            </v-col>
        </v-row>
        <v-row class="align-center justify-sm-center justify-md-start" no-gutters>
            <v-col cols="auto" class="pr-2">
                <v-icon class="pr-0">search</v-icon>
            </v-col>
            <v-col class="pl-0 pb-4">
                <Autocomplete
                    :label="softwareLabel()"
                    :items="filteredSoftware.softwareNames"
                    :value="softwareSearch"
                    hide-details=true
                    :clearable=true
                    @change="setSoftwareSearch"
                />
            </v-col>
        </v-row>
        <v-row class="pl-2 mt-4" >
            <v-col class="software-wrapper pa-0">
                <v-list>
                    <v-list-item
                        v-for="softwareItem in filteredSoftware.items" :key="softwareItem.id"
                    >
                        <v-lazy width="100%">
                            <v-card class="mb-10">
                                <v-row class="align-center ml-2">
                                    <v-col cols="auto" class="py-0">
                                        <v-icon outlined color="primary">
                                            widgets
                                        </v-icon>
                                    </v-col>
                                    <v-col class="py-0">
                                        <v-list-item-content>
                                            <v-card-title v-text="softwareItem.name" style="word-break: break-word;" class="pl-0 py-1" />
                                            <v-card-text v-if="softwareItem.categoryName" v-text="softwareItem.categoryName" class="pl-0 pt-2 mt-n4 grey-text" />
                                        </v-list-item-content>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-lazy>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
        <v-row v-if="filteredSoftware.items.length === 0">
            <v-row>
                <v-col>
                    <Placeholder />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p>{{$t('iotSensors.no_match_for_software')}}</p>
                </v-col>
            </v-row>
        </v-row>
    </div>
    <div v-else>
        <v-row>
            <v-col class="justify-center" justify="center">
                <NoData />
            </v-col>
        </v-row>
    </div>

</template>
<script>
import Autocomplete from "@/components/Autocomplete";
import NoData from "@/components/NoData";
import Placeholder from "@/assets/placeholder.svg"
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'IotSoftwareComponent',

    components: {
        Autocomplete,
        NoData,
        Placeholder
    },

    props: {
        numberOfSoftware: {
        }
    },

    data() {
        return {
            softwareSearch: null,
            categorySearch: null,
        }
    },

    async created() {
        await this.fetchSoftwareCategories()
        await this.fetchSoftwareById(this.$route.params.id)
    },

    computed: {
        ...mapGetters('Software', ['placeSoftware', 'categoryNameById']),

        filteredSoftware() {
            let software = {}
            software.items = this.placeSoftware
            // Adds category name to software items which have softwareCategoryId
            software.items.map(filteredItem => {
                if (filteredItem.softwareCategoryId) {
                    filteredItem.categoryName = this.categoryNameById(filteredItem.softwareCategoryId).localizedAttributes.cz.name
                }
            })

            if (this.categorySearch) {
                software.items = software.items.filter(softwareItem => softwareItem.softwareCategoryId && this.categoryNameById(softwareItem.softwareCategoryId).localizedAttributes.cz.name === this.categorySearch)
            }

            if (this.softwareSearch) {
                software.items = software.items.filter(softwareItem => softwareItem.name === this.softwareSearch)
            }

            // creates names that are used inside autocomplete and select
            software.softwareNames = []
            software.categoryNames = []
            software.items.map(filteredItem => {
                software.softwareNames.push(filteredItem.name)
                if (filteredItem.categoryName) {
                    software.categoryNames.push(filteredItem.categoryName)
                }
            })

            return software
        }
    },


    methods: {
        ...mapActions('Software', ['fetchSoftwareCategories', 'fetchSoftwareById']),

        setSoftwareSearch(value) {
            this.softwareSearch = value
        },

        setCategorySearch(value) {
            this.categorySearch = value
        },

        softwareLabel() {
            return this.$t('iotSensors.search_software')
        },

        categoryLabel() {
            return this.$t('iotSensors.search_category')
        },

    }
}
</script>
<style>
.software-wrapper {
    max-height: 55vh;
    overflow-y: auto;
}

.grey-text {
    color: #6D7278;
}


</style>