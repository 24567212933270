<template>
  <Loading v-if="!didLoadData"/>
  <v-container fluid v-else>
    <ModuleHeader>
      <RefreshButton @click="refreshBeacons">
        <div class="text-h5 font-weight-bold">Majáky</div>
      </RefreshButton>
      <PrimaryButton :to="{name: 'BeaconDetail', params: {id: 'new'}}">+ Nový
      </PrimaryButton>
    </ModuleHeader>
    <v-row>
      <v-col cols="12">
        <v-data-table
          class="d-none d-sm-block"
            v-model="selectedBeacons"
            show-select
            :headers="dataTableHeaders"
            :items="filteredBeacons"
            no-results-text="Žádné údaje"
            :search="$query.search"
            :header-props="{sortByText: 'Řadit podle'}"
            :footer-props="{
              itemsPerPageText: 'Počet řádků na stránku',
              itemsPerPageAllText: 'Všechny',
              pageText: '{0}-{1} z {2}'
            }"
            :sort-by.sync="$query.sort"
            :sort-desc.sync="$query.desc"
            :items-per-page.sync="$query.rowsPerPage"
            :page.sync="$query.page"
        >

          <template v-slot:no-data>
            Žádná dostupná data
          </template>

          <template v-slot:top>
            <v-container fluid class="pb-4">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      color="light-green darken-1"
                      v-model="$query.search"
                      prepend-icon="search"
                      label="Hledat"
                      single-line
                      hide-details
                      clear-icon="clear"
                      :clearable="true"
                  />
                </v-col>
              </v-row>
              <v-row class="d-flex align-end">
                <v-col cols="12" sm="6" class="d-flex justify-lg-start justify-sm-center">
                  <LightButton @click="switchFilter">
                    <v-icon>filter_alt</v-icon>
                    Filtrovat
                  </LightButton>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-lg-end justify-sm-center">
                  <LightButton @click="$router.push({name:'BeaconPurposes'})">
                    Spravovat účely majáků
                  </LightButton>
                </v-col>
              </v-row>
            </v-container>

            <v-container fluid v-if="$query.filtering">
              <v-row>
                <v-col>
                  <div class="subtitle-2">Lokace</div>
                </v-col>
              </v-row>

              <v-row class="mt-n4">
                <v-col cols="12" sm="4">
                  <autocomplete
                      :value="$query.placeType"
                      label="Typ místa"
                      :items="getSortedPlacesTypes"
                      @change="setPlaceType"
                  />
                </v-col>

                <v-col cols="12" sm="4">
                  <v-select
                      v-model="$query.buildingId"
                      label="Budova"
                      :items="buildingsList"
                      clearable
                      clear-icon="clear"
                      @click:clear="clearBuilding"
                      color="light-green darken-1"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Výsledek nenalezen
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" sm="4">
                  <!--                TODO: MAKE NEW COMPOMENT - Localized v-select!-->
                  <v-select
                      v-model="$query.floorId"
                      label="Podlaží"
                      type="number"
                      :items="floorsList"
                      :disabled="!buildingSelected"
                      clearable
                      clear-icon="clear"
                      color="light-green darken-1"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Výsledek nenalezen
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="mt-n4">
                <v-col>
                  <div class="subtitle-2">Technická specifikace</div>
                </v-col>
              </v-row>
              <v-row class="mt-n4">
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="$query.state"
                      label="Stav"
                      :items="statesList"
                      clearable
                      clear-icon="clear"
                      color="light-green darken-1"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      v-model="$query.protocol"
                      label="Protokol"
                      :items="protocolsList"
                      clearable
                      clear-icon="clear"
                      color="light-green darken-1"
                  />
                </v-col>
                <v-col>
                  <v-select
                      v-model="$query.purposeId"
                      :items="beaconPurposesList"
                      label="Účel"
                      clearable
                      clear-icon="clear"
                      color="light-green darken-1"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-n4">
                <v-col>
                  <div class="subtitle-2">Data</div>
                </v-col>
              </v-row>
              <v-row class="mt-n4">
                <v-col cols="12" sm="6">
                  <DatePicker
                      title="Výměna baterie od "
                      :show-current="true"
                      :clearable="true"
                      :initial-value="$query.batteryPlacementFrom"
                      v-on:dateChanged="setBatteryPlacementFrom"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <DatePicker
                      title="Výměna baterie do "
                      :show-current="true"
                      :clearable="true"
                      :initial-value="$query.batteryPlacementTo"
                      v-on:dateChanged="setBatteryPlacementTo"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item.beaconId="{ item }">
            <b class="pointer light-green--text" @click="goToDetail(item.id)">{{ item.beaconId| ellipsis(15) }}</b>
          </template>

          <template v-slot:item.state="{ item }">
            <v-icon v-if="item.state === 'active'" color="light-green darken-1">wifi_tethering</v-icon>
            <v-icon v-else color="red lighten-1">portable_wifi_off</v-icon>
          </template>

          <template v-slot:item.purposeName="{ item }">
            {{ getPurposeName(item) }}
          </template>

          <template v-slot:item.spot.placeName="{item}">
            {{ getPlaceName(item) }}
          </template>

          <template v-slot:item.spot.buildingName="{item}">
            {{ getBuildingName(item) }}
          </template>

          <template v-slot:item.dateOfPlacement="{ item }">
            <span v-if="item.dateOfPlacement !== null">{{ formatDateTime(item.dateOfPlacement, 'll') }} </span>
          </template>

          <template v-slot:item.dateOfBatteryReplacement="{ item }">
            <span v-if="item.dateOfBatteryReplacement !== null">{{
                formatDateTime(item.dateOfBatteryReplacement, 'll')
              }} </span>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-btn
          color="red"
          class="d-none d-sm-block white--text"
          elevation="0"
          rounded
          v-if="selectedBeacons.length !== 0"
          @click="deleteSelectedBeacons"
        >
          <delete-icon />
          Smazat
        </v-btn>
      </v-col>
    </v-row>

    <!-- ONLY FOR SMALLER SCREENS -->
    <v-text-field
      color="light-green darken-1"
      class="rounded-lg make-it-small d-block d-sm-none mb-1"
      v-model="$query.search"
      prepend-inner-icon="search"
      label="Hledat"
      filled
      dense
      flat
      single-line
      hide-details
    />

    <MobileFiltering
      class="d-block d-sm-none py-2"
      tag_icon="close"
      :items="getSortedPlacesTypes"
      v-model="$query.placeType"
    >
      <template v-slot:title>FILTROVAT</template>
    </MobileFiltering>


    <v-data-iterator
      :items="filteredBeacons"
      :search="$query.search"
      :sort-by.sync="$query.sort"
      :sort-desc.sync="$query.desc"
      :hide-default-footer="true"
      no-results-text="Žádné údaje"
      :disable-pagination="true"
      :hide-default-header="false"
      class="d-block d-sm-none"
    >
      <template v-slot:no-data> Žádná dostupná data </template>
      <template v-slot:default="props">
        <v-row>
          <v-col v-for="item in props.items" :key="item.name" cols="12">
            <mobile-data-table-row
            :tagColor="item.state === 'active' ? 'light-green' : 'orange'"
              :extra="prepareDataForExtra(item)"
              @click="goToDetail(item.id)"
              @action="deleteItem(item)"
            >
              <template v-slot:tag>{{
                item.state === "active" ? "aktivní" : "pasivní"
              }}</template>
              <template v-slot:title>{{
                item.beaconId | ellipsis(24)
              }}</template>
              <template v-slot:icon1>place</template>
              <template v-slot:subtitle1>{{ getPlaceName(item) }}</template>
              <template v-slot:label_header1>ÚČEL</template>
              <template v-slot:label_header2>UMÍSTĚNO</template>
              <template v-slot:label_content1>{{
                getPurposeName(item)
              }}</template>
              <template v-slot:label_content2>{{
                formatDateTime(item.dateOfPlacement, "ll")
              }}</template>
              <template v-slot:action_button>delete_outline</template>
            </mobile-data-table-row>
          </v-col>
        </v-row>
      </template>
      </v-data-iterator>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import RefreshButton from "../../components/buttons/RefreshButton";
import ModuleHeader from "../../components/layout/ModuleHeader";
import moment from "moment";
import DeleteIcon from "../../components/buttons/DeleteIcon";
import LightButton from "@/components/buttons/LightButton";
import Loading from "@/components/Loading";
import DatePicker from "../../components/date-picker/DatePicker";
import Autocomplete from "../../components/Autocomplete";
import MobileDataTableRow from "../../components/layout/MobileDataTableRow.vue";
import MobileFiltering from '../../components/layout/MobileFiltering.vue';

export default {
  name: "Beacons",

  components: {
    Autocomplete,
    DatePicker,
    Loading,
    LightButton,
    DeleteIcon,
    ModuleHeader,
    PrimaryButton,
    RefreshButton,
    MobileDataTableRow,
    MobileFiltering
  },

  async created() {
    await Promise.all([this.fetchBeacons(), this.fetchBeaconPurposes(), this.fetchAllBuildings(), this.fetchAllFloors()])
  },

  data() {
    return {
      hasFilterOn: false,
      selectedBeacons: [],

      dataTableHeaders: [
        {
          text: 'Stav',
          value: 'state',
          width: "10%",
          sortable: true,
          filterable: false,
        },
        {
          text: 'UID',
          value: 'beaconId',
          width: "10%",
          sortable: false,
          filterable: true
        },
        {
          text: 'Účel',
          value: 'purposeName',
          width: "20%",
          sortable: false,
          filterable: false,
        },
        {
          text: 'Budova',
          value: 'spot.buildingName',
          width: "10%",
          sortable: false,
          filterable: false,
        },
        {
          text: 'Podlaží',
          value: 'spot.floorLevel',
          width: "5%",
          align: "center",
          sortable: false,
          filterable: false,
        },
        {
          text: 'Místo',
          value: 'spot.placeName',
          width: "10%",
          sortable: false,
          filterable: false,
        },
        {
          text: 'Umístěno',
          value: 'dateOfPlacement',
          width: "15%",
          sortable: true,
          filterable: false,
        },
        {
          text: 'Baterie vyměněna',
          value: 'dateOfBatteryReplacement',
          width: "20%",
          sortable: true,
          filterable: false,
        },
      ],
    }
  },

  computed: {
    ...mapGetters('Beacons', ['beacons', 'beaconById', "didLoadBeacons", "beaconStates", "beaconProtocols"]),
    ...mapGetters("BeaconPurposes", ["purposes", "purposeName", "didLoadBeaconPurposes"]),
    ...mapGetters('places', ["didLoadedPlaces", "placeByBeaconId", "getPlacesTypes", "getTypeName"]),
    ...mapGetters('buildings', ["buildingByFloorId", "getBuildingNames"]),
    ...mapGetters('floors', ['getBuildingFloors', "floorByPlaceId"]),

    getBeacons() {
      return this.beacons;
    },

    getSortedPlacesTypes() {
      let placesTypes = this.getPlacesTypes;
      placesTypes = placesTypes.sort((a, b) => {
        return a.text.localeCompare(b.text);
      });
      return placesTypes;
    },

    // Use following functions to read data from query
    purposeId(){
      return Number.isInteger(this.$query.purposeId)? this.$query.purposeId : null
    },

    buildingId(){
      return Number.isInteger(this.$query.buildingId)? this.$query.buildingId : null
    },
     floorId(){
       return Number.isInteger(this.$query.floorId)? this.$query.floorId : null
     },

    filteredBeacons() {
      let beacons = this.beacons.filter((i) => {
        return this.purposeId === null || (i.purposeId === this.purposeId);
      })

      beacons = beacons.filter((i) => {
        return !this.$query.protocol || (i.eddystoneSupport && this.$query.protocol.toUpperCase() === 'EDDYSTONE')
            || (i.ibeaconSupport && this.$query.protocol.toUpperCase() === 'IBEACON')
      })

      beacons = beacons.filter((i) => {
        return !this.$query.state || (i.state.toUpperCase() === this.$query.state.toUpperCase())
      })

      beacons = beacons.filter((i) => {
        return !this.batteryPlacementFrom
            || (this.compareDates(i.dateOfBatteryReplacement, this.batteryPlacementFrom, "YYYY-MM-DD", false))
      })

      beacons = beacons.filter((i) => {
        return !this.batteryPlacementTo
            || (this.compareDates(i.dateOfBatteryReplacement, this.batteryPlacementTo, "YYYY-MM-DD", true))
      })

      beacons = beacons.filter((i) => {
        return !this.$query.placeType || (i.spot.placeType === this.$query.placeType)
      })

      beacons = beacons.filter((i) => {
        return this.buildingId === null || (i.spot.buildingId === this.buildingId)
      })

      beacons = beacons.filter((i) => {
        return this.floorId === null|| (i.spot.floorId === this.floorId);
      })

      return beacons
    },

    statesList() {
      return this.beaconStates
    },

    protocolsList() {
      return this.beaconProtocols
    },

    beaconPurposesList() {
      return this.purposes.map(purpose => ({text: purpose.name, value: purpose.id}))
    },

    buildingsList() {
      return this.getBuildingNames
    },

    floorsList() {
      return this.getBuildingFloors(this.buildingId).map(floor => ({text: `${floor.level}. patro`, value: floor.id}))
    },

    buildingSelected() {
      return this.buildingId !== null;
    },

    didLoadData() {
      return (this.didLoadBeaconPurposes && this.didLoadBeacons)
    },
  },
  methods: {
    ...mapActions('Beacons', ['fetchBeacons', 'fetchBeaconById', 'createBeacon', 'updateBeacon', 'deleteBeacon']),
    ...mapActions("BeaconPurposes", ["fetchBeaconPurposes"]),
    ...mapActions('floors', ["fetchAllFloors"]),
    ...mapActions('buildings', ["fetchAllBuildings"]),

    async refreshBeacons() {
      return await Promise.all([this.fetchBeacons(), this.fetchBeaconPurposes(), this.fetchAllBuildings(), this.fetchAllFloors()])
    },

    switchFilter() {
      this.$query.filtering = !this.$query.filtering
    },

    goToDetail(beaconId) {
      this.$router.push({name: 'BeaconDetail', params: {id: beaconId}});
    },

    formatDateTime(timestamp, format) {
      return moment(timestamp).format(format);
    },

    getPurposeName(item) {
      // this is necessary. Beacon includes only purposeId
      return this.purposeName(item)
    },

    getPlaceName(item) {
      return item.spot.placeId && item.spot.floorId && item.spot.buildingId ? item.spot.placeName : this.getTypeName(item.spot.placeType)
    },

    getBuildingName(item) {
      return item.spot.buildingId !== null ? item.spot.buildingName : "Exteriér"
    },

    setBatteryPlacementFrom(value) {
      this.$query.batteryPlacementFrom = value ? moment(value).format('YYYY-MM-DD') : null;
    },

    setBatteryPlacementTo(value) {
      this.$query.batteryPlacementTo = value ? moment(value).format('YYYY-MM-DD') : null;
    },

    setPlaceType(value) {
      this.$query.placeType = value
    },

    clearBuilding() {
      this.$query.buildingId = null
      this.$query.floorId = null
    },

    deleteSelectedBeacons() {
      if (confirm("Opravdu chcete smazat tyto majáky?")) {
        this.selectedBeacons.forEach((beacon) => {
          this.deleteBeacon(beacon.id)
        })
        this.selectedBeacons = []
      }
    },

    deleteItem(item){
      if (confirm("Opravdu chcete smazat maják " + item.beaconId + "?")) {
        this.deleteBeacon(item.id)
      }
    },
    
    prepareDataForExtra(item){
      return item.dateOfBatteryReplacement ? [{title: moment.unix(item.dateOfBatteryReplacement).format('ll'), iconName: 'battery_charging_full'}] : []
    },

    compareDates(dateA, dateB, format, lessThan) {
      let formattedA = this.formatDateTime(dateA, format);
      return lessThan === false ? formattedA >= dateB : formattedA <= dateB
    },
  },
}
</script>