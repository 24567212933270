<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-container fluid v-else>
        <back-button :target="{name: 'EntitiesPortal'}">{{ $t('entities.entity_portal') }}</back-button>

        <module-header>
            <h2>{{ $t('entities.studyItem.portal_name') }}</h2>
            <v-spacer/>
            <primary-button v-if="isStudyAdmin" @click="goToNew()" class="ml-5">+ {{ $t('entities.add') }}</primary-button>
        </module-header>

      <v-btn @click="goToCategories()" class="mx-0 mb-5"  text style="  color: #65a61a;"> Spravovat kategorie</v-btn>

      <v-data-table
                :headers="customHeaders"
                :items="notArchivedItems"
                :disable-pagination="true"
                :hide-default-footer="true"
                :no-data-text="$t('entities.studyItem.no_study_req')"
                class="elevation-1"
                :sort-by.sync="$query.sort"
                :sort-desc.sync="$query.desc"
        >
            <template v-slot:item.name="{ item }">
              <b class="pointer light-green--text"  @click="goToDetail(item.id)">{{item.name}}</b>
            </template>
            <template v-slot:item.isShown="{ item }">
                <v-icon v-if="item.isShown" class="mr-2" @click="showOrHide(item.id)">
                    visibility
                </v-icon>
                <v-icon v-else class="mr-2" @click="showOrHide(item.id)">
                    visibility_off
                </v-icon>
            </template>
        </v-data-table>

        <h5 class="mt-10 mb-3">Archivované požadavky</h5>

        <v-data-table
                :headers="customHeaders"
                :items="archivedItems"
                :sort-by="['id']"
                :sort-desc="[true]"
                :hide-default-header="true"
                :disable-pagination="true"
                :hide-default-footer="true"
                :no-data-text="$t('entities.studyItem.no_study_req')"
                class="elevation-1 archived-table"
        >
          <template v-slot:item.name="{ item }">
            <b class="pointer light-green--text"  @click="goToDetail(item.id)">{{item.name}}</b>
          </template>
          <template v-slot:item.isShown="{ item }">
                <v-icon v-if="item.isShown" class="mr-2" @click="showOrHide(item.id)">
                    visibility
                </v-icon>
                <v-icon v-else class="mr-2" @click="showOrHide(item.id)">
                    visibility_off
                </v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import Loading from "../../../../../components/Loading";
    import BackButton from "../../../../../components/layout/BackButton";
    import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
    import {put} from "@/services/api";
    import ModuleHeader from '../../../../../components/layout/ModuleHeader.vue';

    export default {
        name: "StudyProblemsItemPortal",

        components: {
            BackButton,
            Loading,
            ModuleHeader,
            PrimaryButton,
        },

        data() {
            return {
                headers: [
                    {
                        text: this.$t('headers.title'),
                        align: 'left',
                        value: 'name',
                        width: '17%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.category'),
                        align: 'left',
                        value: 'category',
                        width: '17%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.code'),
                        align: 'left',
                        value: 'short_code',
                        width: '15%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.approx_time_mins'),
                        align: 'left',
                        value: 'default_time',
                        width: '15%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.assistant'),
                        align: 'left',
                        value: 'assistant',
                        width: '15%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.show'),
                        align: 'center',
                        value: 'isShown',
                        width: '7%',
                        sortable: false,
                    },
                ],
            }
        },

        mounted() {
            this.fetchStudyProblemsCategories();
            this.fetchStudyProblemsItems();
            this.fetchAssistants();
        },

        computed: {
            ...mapGetters('studyProblemsCategories', ['getStudyProblemsCategory', 'didLoadedStudyProblemsCategories']),
            ...mapGetters('studyProblemsItems', ['getStudyProblemsItems', 'getStudyProblemsItem', 'didLoadedStudyProblemsItems']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),
            ...mapGetters('assistant', ['getAssistant']),

            didLoadedAll() {
                return this.didLoadedStudyProblemsCategories && this.didLoadedStudyProblemsItems;
            },

            isStudyAdmin() {
                return this.$auth.getStudyAssistantAndRole()?.role === 'STUDY_ADMIN';
            },

            items() {
                return this.getStudyProblemsItems.map(item => ({
                    name: item.name,
                    name_en: item.name_en,
                    category: this.getStudyProblemsCategory(item.studyProblemsCategoryId).name,
                    short_code: item.shortCode,
                    default_time: item.defaultTime,
                    id: item.id,
                    assistant: item.assistantId !== null ? this.getAssistant(item.assistantId).name : '',
                    isShown: item.isShown,
                    archived: item.archived,
                }));
            },

            notArchivedItems() {
                return this.items.filter(f => !f.archived);
            },

            archivedItems() {
                return this.items.filter(f => f.archived);
            },

            customHeaders() {
                return this.isStudyAdmin ? this.headers : this.headers.slice(0, -1);
            },
        },

        methods: {
            ...mapActions('studyProblemsCategories', ['fetchStudyProblemsCategories']),
            ...mapActions('studyProblemsItems', ['fetchStudyProblemsItems']),
            ...mapActions('assistant', ['fetchAssistants']),

            goToNew() {
                this.$router.push({name: 'StudyProblemsItemDetail', params: {Rid: "new"}});
            },

            goToDetail(Rid) {
                this.$router.push({name: 'StudyProblemsItemDetail', params: {Rid: Rid}});
            },
            showOrHide(Rid) {
                let studyItem = this.getStudyProblemsItem(Rid);

                studyItem.isShown = studyItem.isShown == true ? false : true

                put(`study_department/study_problems_item/${Rid}`, {
                        "name": studyItem.name,
                        "name_en": studyItem.name_en,
                        "description": studyItem.description,
                        "description_en": studyItem.description_en,
                        "defaultTime": studyItem.defaultTime,
                        "shortCode": studyItem.shortCode,
                        "studyProblemsCategoryId": studyItem.studyProblemsCategoryId,
                        "isShown": studyItem.isShown,
                        archived: studyItem.archived,
                }).then(response => {
                    this.setStudyProblemsItemsStorage(response.data.items)
                }).catch(e => {
                    if (e.statusCode > 200 && e.statusCode < 500) {
                        this.could_save = false;
                        alert(this.$t('entities.couldnt_save'));
                    } else if (e.statusCode === 500) {
                        this.could_save = false
                        alert(this.$t('entities.couldnt_save'))
                    }
                });
            },
          goToCategories(){
            this.$router.push({name: "StudyProblemsCategoryPortal"})
          }
        }
    }
</script>

<style scoped>

.archived-table >>> td:not(:last-child) {
    color: rgb(167, 167, 167) !important;
}

</style>
