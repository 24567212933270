<template>
    <Loading v-if="!didLoadData"/>
    <v-container v-else-if="!administrator && !isNew">
        <v-row>
            <v-col>
                <BackButton :target="{name: 'displaysAdministrators'}">Seznam uživatelů</BackButton>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <PlaceHolder
                    path="/icons/something-went-wrong.png"
                    text="Je nám líto, něco se pokazilo. Zkuste znovu načíst stránku."
                    title="Něco se polámalo">
                </PlaceHolder>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else>
        <v-row class="font-weight-medium mb-3">
            <v-col cols="12" md="8" class="pa-0">
                <BackButton :target="{name: 'displaysAdministrators'}">Seznam uživatelů</BackButton>
            </v-col>
            <v-col cols="12" md="4" align-self="center" class="d-flex justify-md-end justify-start pa-0">
                <SaveButton
                    v-if="!isSaved"
                    :disabled="isFormInvalid || updatedUser"
                    @click="saveUser()"
                    label="Uložit"
                    data-cy="save"
                />
                <v-btn v-else outlined>
                    <v-icon left>check</v-icon>
                    Uloženo
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pa-0">
                <h1 v-if="!isNew" class="font-weight-medium">{{ administrator.fullName }}</h1>
                <div v-else>
                    <h1 class="font-weight-medium">Nový uživatel</h1>
                    <span class="subtitle">Zadejte login a jméno uživatele a přiřaďte mu potřebná práva</span>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="!isNew" class="mb-1">
            <v-col class="pa-0">
                <span class="subtitle">{{ administrator.xlogin }}</span>
            </v-col>
        </v-row>
        <v-row v-else-if="!controlData" class="mr-1">
            <v-col cols="6" class="pl-0">
                <v-text-field
                    color="light-green darken-1"
                    v-model="xlogin"
                    label="xlogin uživatele"
                    :rules="[required_rule]"
                    data-cy="xlogin"
                >
                </v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    color="light-green darken-1"
                    v-model="fullName"
                    label="Jméno a příjmení uživatele"
                    :rules="[required_rule]"
                    data-cy="fullName"
                >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="!controlData" class="mt-7">
            <v-col class="pa-0">
                <v-container fluid>
                    <v-row class="mb-1">
                        <v-col class="pa-0">
                            <h3 class="font-weight-medium">Práva k fakultám a součástem</h3>
                            <div class="subtitle">Vyberte práva, která chcete uživateli přiřadit</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pa-0">
                            <FacultiesCards :input="getFaculties" data="faculty" @clicked="onClickChild"
                                :selectedItems="selectedFaculties" data-cy="card-faculty">
                            </FacultiesCards>
                        </v-col>
                    </v-row>
                    <v-row v-if="!isNew">
                        <v-col class="pa-0">
                            <span class="removeButton mt-3" @click="attemptToDeleteAdministrator(administratorId)">
                                ODSTRANIT UŽIVATELE
                            </span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <PlaceHolder 
                    path="/icons/something-went-wrong.png"
                    text="Je nám líto, něco se pokazilo. Zkuste znovu načíst stránku."
                    title="Něco se polámalo">
                </PlaceHolder>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import BackButton from "@/components/layout/BackButton";
import Loading from "@/components/Loading";
import {mapActions, mapGetters} from "vuex";
import FacultiesCards from "@/components/displays/FacultiesCards";
import SaveButton from "@/components/buttons/SaveButton";
import PlaceHolder from "@/components/news/PlaceHolder";

export default {
    name: "AdministratorDetail",

    components: {
        PlaceHolder,
        SaveButton,
        FacultiesCards,
        BackButton,
        Loading,
    },

    async created() {
        await Promise.all([
            this.fetchAllFaculties(),
            this.fetchAdministrators()
        ]);
    },

    data() {
        return {
            facultiesIds: [],
            updatedUser: true,
            isSaved: false,
            xlogin: "",
            fullName: "",
            required_rule: value => !!value || 'Povinné',
        }
    },

    methods: {
        ...mapActions('displaysAdmins', ['fetchAdministrators', 'createAdministrator', 'updateAdministrator', 'deleteAdministrator']),
        ...mapActions('displaysFaculties', ['fetchAllFaculties']),

        async attemptToDeleteAdministrator(login) {
            if (window.confirm(`Opravdu chcete smazat užívatele ${this.administrator.fullName} ?`)) {
                await Promise.all([
                    await this.deleteAdministrator(login),
                    await this.$router.push({name: 'displaysAdministrators'})
                ])
            }
        },

        onClickChild(obj) {
            this.facultiesIds = obj.value;
            this.updatedUser = false;
        },

        async saveUser() {
            if (!this.isNew) {
                await this.updateAdministrator({
                    id: this.administratorId,
                    request: {
                        facultyIds: this.facultiesIds,
                        fullName: this.administrator.fullName,
                        xlogin: this.administrator.xlogin,
                    }
                });
            } else {
                await this.createAdministrator({
                    facultyIds: this.facultiesIds,
                    fullName: this.fullName,
                    xlogin: this.xlogin,
                });
            }
            this.isSaved = true;
            await this.$router.push({name: 'displaysAdministrators'});
            window.scrollTo(0, 0);
        }
    },


    computed: {
        ...mapGetters('displaysAdmins', ['getAdministratorById', 'didLoadAdministrators']),
        ...mapGetters('displaysFaculties', ['getFaculties', 'didLoadFaculties']),

        didLoadData() {
            return this.didLoadFaculties && this.didLoadAdministrators;
        },

        administratorId() {
            return parseInt(this.$route.params.administratorId);
        },

        isNew() {
            return this.$route.params.administratorId === "new";
        },

        administrator() {
            return this.getAdministratorById(this.administratorId);
        },

        isFormInvalid() {
            const invalidCards = this.facultiesIds.length === 0;
            return this.isNew ?  (invalidCards || !this.xlogin || !this.fullName) : invalidCards;
        },

        selectedFaculties() {
            return !this.isNew ? this.getFaculties.filter(faculty => this.administrator.faculties.some(item => item.id === faculty.value.id)) : [];
        },

        controlData() {
            return this.getFaculties?.length === 0;
        }
    },


}
</script>

<style scoped>

.subtitle {
    font-size: 16px;
    color: #6D7278;
}

.removeButton {
    color: #E02020;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

</style>
