<template>
  <Loading v-if="!didLoadAnnouncementTypes"/>
  <div v-else>
    <!-- Dialogs -->
    <ConfirmDialog 
      :model="showDeleteDialog" 
      :onConfirm="attemptDeleteAnnouncementType"
      :onCancel="() => announcementTypeToDelete = undefined">
      <div v-if="announcementTypeToDelete">{{`Přejete si smazat typ oznámení s názvem "${announcementTypeToDelete.name}"?`}}</div>
      Tato operace je nevratná.
    </ConfirmDialog>
    <Alert 
      :model="errorOnDelete" 
      :onConfirm="() => errorOnDelete = false">
      Zvolený typ oznámení nelze smazat, je využíván!
    </Alert>
    <!-- Module header -->
    <BackButton :target="{name: 'unitDetail'}">Informační panely</BackButton>
    <ModuleHeader v-if="getAnnouncementTypes.length > 0">
      <RefreshButton @click="refreshAnnouncementTypes" title="Aktualizovat data">
        <h2>Typy oznámení{{didLoadAdministrators ? ` - ${selectedUnit.localizedAttributes.cz.name}` : ''}}</h2>
      </RefreshButton>
      <PrimaryButton id="add-announcement-type" :to="{name: 'announcementTypeDetail', params: {announcementTypeId: 'new'}}">+ Nový</PrimaryButton>
    </ModuleHeader>
    <ModuleHeader v-else>
      <h2>Typy oznámení</h2>
    </ModuleHeader>
    <v-container v-if="getAnnouncementTypes.length > 0">
      <!-- Announcement types data table -->
      <template class="announcement-types-table">
        <v-data-table
          :headers="headers"
          :items="getAnnouncementTypes"
          :sort-by.sync="$query.sort"
          :sort-desc.sync="$query.desc"
          :disable-pagination="true"
          :hide-default-footer="true"
          class="d-none d-sm-block"
        >
          <template v-slot:item.iconName="{ item }">
            <v-icon :style="{'--base-color': item.color}" class="announcement-type-icon" :title="item.iconName">{{item.iconName}}</v-icon>
          </template>
          <template v-slot:item.name="{ item }">
            <TitledText id="type-name" :title="item.name" :text="item.name | ellipsis(55)" />
          </template>
          <template v-slot:item.displaysNames="{ item }">
            <div v-if="(item.displaysNames.length || 0) > 0" :style="{'padding': '0.2em 0 0.2em 0'}">
              <v-chip x-small
                class="display-chip"
                v-for="(name, index) in item.displaysNames" 
                v-bind:key="`display-${index}`" 
              >{{name}}</v-chip>
            </div>
            <span v-else class="no-displays-text">typ oznámení není zobrazován</span>
          </template>
          <template v-slot:item.serviceName="{ item }">
            <TitledText :title="item.serviceName" :text="item.serviceName ? getServiceName(item.serviceName) : '-'" />
          </template>
          <template v-slot:item.actions="{ item }">
            <EditIcon id="edit-announcement-type" title="Upravit typ oznámení" @click="goToDetail(item.id)"/>
            <DeleteIcon id="remove-announcement-type" title="Smazat typ oznámení" @click="() => announcementTypeToDelete = item"/>
          </template>
        </v-data-table>
        <!-- Announcement types table for smaller screens -->
        <v-data-iterator
          :items="getAnnouncementTypes"
          :sort-desc.sync="$query.desc"
          :sort-by.sync="$query.sort"
          :hide-default-footer="true"
          :disable-pagination="true"
          :hide-default-header="false"  
          class="d-block d-sm-none pt-1"
        >
          <template v-slot:no-data>Žádná dostupná data</template>
          <template v-slot:default="props">
            <v-row>
              <v-col v-for="item in props.items" :key="item.name" cols="12">
                <mobile-data-table-row
                  @click="goToDetail(item.id)"
                  @action="() => announcementTypeToDelete = item"
                  :extra="getDiplayNamesForMobile(item.displaysNames)"
                >
                  <template v-slot:number>{{item.id}}</template>
                  <template v-slot:title>{{ item.name | ellipsis(24) }}</template>
                  <template v-slot:label_header1>SYMBOL</template>
                  <template v-slot:label_header2>GENERUJÍCÍ SLUŽBA</template>
                  <template v-slot:label_content1><v-icon :style="{'--base-color': item.color}" class="announcement-type-icon" :title="item.iconName">{{item.iconName}}</v-icon></template>
                  <template v-slot:label_content2>{{ item.serviceName ? getServiceName(item.serviceName) : '-' }}</template>
                  <template v-slot:action_button>delete_outline</template>
                </mobile-data-table-row>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </template>
    </v-container>
    <v-container class="text-center" v-else>
        <Placeholder id="no-types-placeholder" />
        <v-row>
            <v-col>
              <p class="mb-10">Dosud nebyly definovány žádné typy oznámení...</p>
            </v-col>
        </v-row>
        <PrimaryButton id="add-announcement-type" :to="{name: 'announcementTypeDetail', params: {announcementTypeId: 'new'}}">+ Nový typ oznámení</PrimaryButton>
      </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { displaysServices } from '@/config';
  import { announcementTypesHeaders } from './announcement-types-helpers';
  import Alert from '../../../components/dialogs/Alert';
  import BackButton from "../../../components/layout/BackButton";
  import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
  import DeleteIcon from "../../../components/buttons/DeleteIcon";
  import EditIcon from "../../../components/buttons/EditIcon";
  import Loading from "../../../components/Loading";
  import MobileDataTableRow from '../../../components/layout/MobileDataTableRow.vue';
  import ModuleHeader from "../../../components/layout/ModuleHeader";
  import PrimaryButton from "../../../components/buttons/PrimaryButton";
  import RefreshButton from "../../../components/buttons/RefreshButton";
  import TitledText from '../../../components/text/TitledText';
  import Placeholder from '../../../assets/displays/no_announcement_types.svg';

  export default {
    name: "announcementTypes",
    components: {
      BackButton,
      Loading,
      EditIcon,
      DeleteIcon,
      ModuleHeader,
      PrimaryButton,
      RefreshButton,
      TitledText,
      MobileDataTableRow,
      ConfirmDialog,
      Alert,
      Placeholder
    },

    async created() {
      await this.fetchAdministrators();
      await this.fetchAnnouncementTypes(this.$route.params.unitId);
    },

    data(){
      return {
        headers: announcementTypesHeaders,
        announcementTypeToDelete: undefined,
        errorOnDelete: false,
      }
    },

    methods: {
      ...mapActions('displaysAdmins', [
        'fetchAdministrators'
      ]),

      ...mapActions('announcementTypes', [
        'fetchAnnouncementTypes', 
        'deleteAnnouncementType'
      ]),

      refreshAnnouncementTypes() {
        this.fetchAnnouncementTypes(this.$route.params.unitId);
      },

      attemptDeleteAnnouncementType() {
        this.deleteAnnouncementType(this.announcementTypeToDelete.id).catch(() => {
          this.errorOnDelete = true;
        })
        this.announcementTypeToDelete = undefined;
      },

      goToDetail(announcementTypeId) {
        this.$router.push({
          name: "announcementTypeDetail",
          params: { announcementTypeId }
        });
      },

      getServiceName(id) {
        const foundService = displaysServices.find(item => item.id === id);
        return foundService ? foundService.name : '-';
      },

      getDiplayNamesForMobile(displaysNames){
        const result= []
        displaysNames.forEach(element => {
          result.push({title: element});
        })
        return result;
      }
    },

    computed: {
      ...mapGetters('displaysAdmins', [
        'didLoadAdministrators', 
        'getAdministrators'
      ]),

      ...mapGetters('announcementTypes', [
        'getAnnouncementTypes',
        'didLoadAnnouncementTypes'
      ]),

      ...mapGetters('user', ['username']),

      showDeleteDialog() {
        return this.announcementTypeToDelete !== undefined;
      },

      selectedUnit() {
        return this.getAdministrators.find(user => user.xlogin === this.username)?.faculties?.find(faculty => faculty.id === Number(this.$route.params.unitId));
      }
    },
  }
</script>

<style scoped>
  .announcement-types-table {
    margin-top: 1rem;
    width: 100%;
  }

  .announcement-type-icon {
    background-color: var(--base-color);
    font-size: 1.5em !important;
    padding: 0.3em;
    border-radius: 50%;
    color: white !important;
  }

  .display-chip {
    margin: 0.2em;
  }

  .no-displays-text {
    padding: 0.2em;
  }

  .container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    min-width: 100%;
  }
</style>
