import { render, staticRenderFns } from "./ReasonsDetail.vue?vue&type=template&id=c1ff78c4&scoped=true&"
import script from "./ReasonsDetail.vue?vue&type=script&lang=js&"
export * from "./ReasonsDetail.vue?vue&type=script&lang=js&"
import style0 from "./ReasonsDetail.vue?vue&type=style&index=0&id=c1ff78c4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1ff78c4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VContainer,VFlex,VForm,VIcon,VLayout,VList,VListItem,VListItemTitle,VMenu,VSelect,VTextField})
