<template>
    <Loading v-if="!didLoadTasks && !isNewQuestion"/>
    <div v-else>
        <v-simple-table fixed-header v-if="tasks.length > 0">
            <thead>
            <tr>
                <th scope="col"
                    v-for="(name, key) in languages"
                    :key="key"
                >
                    {{name}}
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="task in tasks" :key="task.id">
                <td v-for="(key) in languages" :key="key">
                    <v-textarea
                            @change="changeWatch"
                            :rules="[required_rule]"
                            :rows="1"
                            auto-grow
                            placeholder="Úkol"
                            v-model="task.localizedAttributes[key].name"
                    ></v-textarea>
                </td>
                <td class="py-3">
                    <DeleteIcon @click="deleteQuestionTask(task)"></DeleteIcon>
                </td>
            </tr>
            </tbody>
        </v-simple-table>
        <LightButton @click="addTask()">+ Přidat úkol</LightButton>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import Loading from "../../components/Loading";
    import {getLocalizedLanguages} from "../../localization/languages";
    import LightButton from "../../components/buttons/LightButton";
    import DeleteIcon from "../../components/buttons/DeleteIcon";

    export default {
        name: "FAQQuestionDetailTasks",

        components: {
            DeleteIcon,
            LightButton,
            Loading,
        },

        props: {
            question: {type: Object, required: true},
        },

        mounted() {
            this.loadData();
        },

        watch: {
            'question.id': function() {
                this.loadData();
            }
        },

        data() {
            return {
                tasks: [],
                deletedTaskIds: [],
                required_rule: value => !!value || 'Povinné',
            }
        },

        computed: {
            ...mapGetters('localization', ['currentLanguage']),
            ...mapGetters('FAQTasks', ['tasksForQuestion', 'didLoadTasks', 'prepareNewTask']),

            languages() { return getLocalizedLanguages(); },
            isNewQuestion() { return this.question.id === undefined; },
        },

        methods: {
            ...mapActions('leavePrevent', ['madeUnsavedChanges', 'didSaveChanges']),
            ...mapActions('FAQTasks', ['fetchTasksForQuestion', 'createTask', 'updateTask', 'deleteTask']),

            loadData() {
                if (!this.isNewQuestion) {
                    this.fetchTasksForQuestion(this.question).then(() =>
                        this.loadTasks()
                    );
                }
            },

            changeWatch() {
                this.madeUnsavedChanges();
            },

            loadTasks() {
                this.tasks = this.tasksForQuestion(this.question);
            },

            async save() {
                // inject parent question
                this.tasks.forEach(t => t.questionID = this.question.id);

                // add or update all tasks on the server
                await Promise.all(
                    this.tasks.map(t =>
                        t.id === undefined
                            ? this.createTask(t)
                            : this.updateTask(t)
                    )
                );

                // delete tasks on the server
                await Promise.all(
                    this.deletedTaskIds.map(id => this.deleteTask(id))
                );
                this.deletedTaskIds = [];

                // reload tasks from store to get ids of newly added tasks
                this.loadTasks();
            },

            addTask() {
                this.tasks.push({...this.prepareNewTask()});
                this.madeUnsavedChanges();
            },

            deleteQuestionTask(task) {
                const index = this.tasks.indexOf(task);
                this.tasks.splice(index, 1);
                if (task.id !== undefined) {
                    this.deletedTaskIds.push(task.id);
                }
                this.madeUnsavedChanges();
            }
        }
    }
</script>

<style scoped>
    th {
        font-size: 1rem !important;
    }
    td {
        vertical-align: top;
    }
    td:nth-child(0), td:nth-child(1), th:nth-child(0), th:nth-child(1) {
        width: 50%;
    }
    thead {
      border-top: none;
    }
</style>
