import {del, get, post, put} from "@/services/api"
import Vue from "vue";

const state = {
    tests: [],
    didLoadedTests: false,
    didLoadTest: false,
    defaultTest: {
        activated: false,
        categoryId: null,
        localizedAttributes: {
            "cz": {
                name: "",
            },
            "en": {
                name: "",
            }
        },
        subjectId: null,
        showAnswers: false,
        hidden: false,
        questions: []
    },
    newTest: null
};

const getters = {
    getTests: state => state.tests,
    getTestsBySubjectId: state => subjectId => state.tests.filter(t => t.subjectId === subjectId),
    getTestById: state => id => state.tests.find(t => t.id === id),
    getEmptyTest: state => state.newTest,

    didLoadedTests: state => state.didLoadedTests,
    didLoadTest: state => state.didLoadTest,
};

const actions = {

    async fetchAllTests({commit}) {
        commit('setDidLoadedTests', false);
        await get('gamification/tests/')
            .then((response) => {
                commit('setTests', response.data.items);
                commit('setDidLoadedTests', true);
            });
    },

    async fetchTestById({commit}, id) {
        commit('errors/clear', null, { root: true });
        commit('setDidLoadTest', false);
        const response = await get('gamification/tests/' + id);
        commit('updateTest', response.data.content);
        commit('setDidLoadTest', true);
    },

    async createTest({commit}, {item}) {
        const response = await post('gamification/tests/', item);
        const newTest = response.data.content;
        commit('addTest', newTest);
        return newTest.id;
    },

    async deleteTestById({commit}, id) {
        await del(`gamification/tests/${id}`)
        commit('filterTests', id);
    },

    async updateTest({commit}, {item}) {
        await put(`gamification/tests/${item.id}`, item)
            .then((response) => {
                commit('updateTest', response);
            })
            .catch(e => alert(e.message));
    },

    prepareNewTest({ commit }) {
        commit('prepareNewTest');
    }
};

const mutations = {
    setDidLoadedTests: (state, value) => (state.didLoadedTests = value),
    setDidLoadTest: (state, value) => (state.didLoadTest = value),
    addTest: (state, item) => (state.tests.push(item)),
    setTests: (state, tests) => (state.tests = tests),
    filterTests: (state, id) => (state.tests = state.tests.filter(tests => tests.id !== id)),
    updateTest: (state, item) => {
        const existing = state.tests.findIndex(t => t.id === item.id);
        if (existing > -1) {
            Vue.set(state.tests, existing, item);
        } else {
            state.tests.push(item);
        }
    },

    prepareNewTest: ( state ) => {
        state.newTest = JSON.parse(JSON.stringify(state.defaultTest));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}