<template>
    <a id="back-button" data-cy="back-button" class="mb-2 py-2 pr-3 d-inline-flex align-center justify-start" @click="decideMethodForGoingBack()">
        <i class="fas fa-chevron-left mr-4"></i>
        <b><slot/></b>
    </a>
</template>

<script>
    import PreviousPathMemory from "@/services/PreviousPathMemory";

    export default {
        name: 'BackButton',
        props: {
            target: {
                type: Object,
                required: true
            },
          explicitPush: {
              type: Boolean,
              default: false
          }
        },
      methods: {
          decideMethodForGoingBack(){
            return this.nextModuleMatchesPrevious() ? this.$router.go(-1) : this.$router.push(this.target)
          },

          nextModuleMatchesPrevious(){
            return PreviousPathMemory.get().name === this.target.name && !this.explicitPush
          }
      }
    }
</script>

<style scoped>
    b {
        font-size: 0.9rem;
        color: #65a61a;
        margin-left: 1.3rem;
        display: inline-block;
    }
    .fas {
        font-size: 1.4rem;
        color: #65a61a;
        position: absolute;
    }
    a {
        display: inline-block;
        text-decoration: none !important;
        position: relative;
    }
    a:hover {
        background-color: #65a61a1a;
        border-radius: 30px;
    }
    a:hover i {
        font-size: 1.7rem;
        font-weight: bold;
    }
</style>