<template>
	<v-dialog
        v-model="model"
        @click:outside="close"
                width="800"
        @keydown.esc="close"
	>
    <v-card v-if="target">
        <v-card-title>
            <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>

        <v-form
            v-model="valid"
            ref="form" 
        >
                <v-textarea
                    v-for="language in languages"
                    :key="language"
                    class="mt-6 mx-6 mb-1"
                    :label="$t(`gamification.${language}_${attributeField}`)"
                    v-model="target.localizedAttributes[language][editedAttribute]"
                    :rows="1"
                    :maxlength="150"
                    auto-grow
                    :rules="[required_rule]"
                ></v-textarea>

                <div v-if="descriptionAttribute">
                    <v-textarea
                        v-for="lang in languages"
                        :key="lang"
                        class="mt-6 mx-6 mb-1"
                        :label="$t(`gamification.${lang}_${attributeField}_${descriptionAttribute}`)"
                        v-model="target.localizedAttributes[lang][descriptionAttribute]"
                        :rows="1"
                        :maxlength="150"
                        auto-grow
                        :rules="[required_rule]"
                    ></v-textarea>
                </div>

                <v-card-text id="dialog-text" style="padding: 16px 24px 10px">
                {{$t('gamification.input_warning')}}
                </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                :color="'secondary'"
                text
                @click="close"
            >
                {{ buttonCancelLabel }}

            </v-btn>
            <v-btn
                color="primary"
                text
                :disabled = !valid
                @click="confirm"
            >
                {{ buttonConfirmLabel }}
            </v-btn>

            </v-card-actions>
        </v-form>
      </v-card>

	</v-dialog>
</template>
<script>
  import { languages } from '../../localization/languages';

  export default {
    name: "GamificationDialog",
    data() {
      return {
        required_rule: value => !!value || 'Povinné',
        valid: false,
      }
    },

    computed: {
        languages() { return languages; },
    },

    methods: {
        async confirm() {
            this.$emit('confirm', {item: this.target});
        },

        close() {
            this.$emit('close')
        }
    },
        
    props: {
      model: {
          type: Boolean,
          required: true
      },

      /**
       * Modify this props to change which attribute inside the target's localizedAttributes is being edited. 
       */
      editedAttribute: {
          type: String,
          default: "name"
      },
      
      descriptionAttribute: {
          type: String,
      },

      buttonConfirmLabel: {
          type: String,
          default: "Uložit"
      },

      buttonCancelLabel: {
          type: String,
          default: "Zavřít"
      },

      attributeField: {
          type: String,
          default: ""
      },

      dialogTitle: {
        type: String,
      },

      target: {
          type: Object,
      },
    },
  }
</script>
<style scoped>
</style>