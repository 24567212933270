import {del, get, post} from '@/services/api'
import Vue from "vue";
import translator from "@/localization/translator";


const state = {
    webReportImages: [],
    webReports: [],
    didLoadWebReports: false,
    didLoadWebImages: false,
    types: [
        "idea" ,
        "problem" ,
        "question" ,
    ],
}

const getters = {
    getReportImages: state => state.webReportImages,
    didLoadWebReports: state => state.didLoadWebReports,
    getWebReports: state => {return state.webReports.sort((a, b) => b.id - a.id);
    },
    getSingleWebReport: state => id => {
        return state.webReports.find(report => report.id == id)
    },
    getWebTypes: state => {
        return state.types.map( type => ({text: translator.translate(type), value: type}));
    },


}

const actions = {
    //Fetch ALL
    async fetchWebReports({ commit }) {
        commit('startedDataDownload');
        await get('helpdesk/')
            .then((response) => {
                commit('setWebReports', response.data.items);
                commit('finishedDataDownload');
            });
    },

    async fetchWebReportById({commit}, id) {
        commit('errors/clear', null, { root: true });
        commit('startedDataDownload');
        try {
            const response = await get('helpdesk/' + id)
            commit('updateReport', response.data.content);
            commit('finishedDataDownload');
        } catch (e) {
            if (e.statusCode == 404) {
                commit('errors/objectNotFound', null, { root: true });
            } else {
                throw e;
            }
        }
    },

    addImage({commit}, image) {
        commit('addImage', image);
    },

    async createReport({commit}, item) {
        const response = await post('helpdesk/', item);
        const newReport = response.data.content;
        commit('addReport', newReport);
        return newReport.id;
    },

    async deleteWebReport({ commit},  id) {
        del(`helpdesk/${id}`)
            .then(() => {
                commit('filterWebReport', id);
            });
    },
}

const mutations = {
    startedDataDownload: state => state.didLoadWebReports = false,
    finishedDataDownload: state => state.didLoadWebReports = true,
    addImage: (state, image) => (state.webReportImages.push(image)),
    addReport: (state, item) => (state.webReports.push(item)),
    setWebReports: ( state, reports ) => { state.webReports = reports },
    filterWebReport: ( state, id ) => (state.webReports = state.webReports.filter(reports => reports.id !== id)),
    updateReport: ( state, item ) => {
        let index = state.webReports.findIndex(report => report.id == item.id);
        if (index > -1) {
            Vue.set(state.webReports, index, item)
        } else {
            state.webReports.push(item)
        }
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}