const state = {
    hasConnection: true,
    isServerAvailable: true,
};

const getters = {
    hasConnection: state => state.hasConnection,
    isServerAvailable: state => state.isServerAvailable,
    isSyncWorking: state => state.hasConnection && state.isServerAvailable,
};

const actions = {
    setOnline({ commit }) {
        commit('setHasConnection', true);
        
        // Even if we do not know yet if the server is reachable, we expect so to prevent a duplicate error message after the computer's state has become online.
        // It is quite improbable that both things happen at the same time (the computer is offline and the server is down). Even if it happens, it is set back 
        // to false in a while when the next check in ConnectionMonitor takes place.
        commit('setServerAvailable', true); 
    },
    setOffline({ commit }) {
        commit('setHasConnection', false);
    },
    setServerAvailabile({ commit }) {
        commit('setServerAvailable', true);
    },
    setServerUnavailabile({ commit }) {
        commit('setServerAvailable', false);
    },
};

const mutations = {
  setHasConnection: (state, connectionState) => state.hasConnection = connectionState,
  setServerAvailable: (state, available) => state.isServerAvailable = available,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
