<template>
    <div v-if="iconName === null" class="no-icon">?</div>
    <icon :color="color" v-else :name="iconName" />
</template>

<script>
    import {icons, faq_icons} from "./FAQCategoriesIcons";
    import Icon from "../icons/Icon";

    export default {
        name: 'FAQCategoryIcon',

        components: {Icon},

        props: {
            category: Object,
            color: String,
        },

        computed: {
            iconName() {
                if (this.category) {
                    const icon = icons.concat(faq_icons).find(i => i.mobileIcon === this.category.iconName);
                    return icon ? icon.webIcon : null;
                } else {
                    return null;
                }
            }
        }
    }
</script>

<style scoped>
    b {
        font-size: 0.9rem;
    }
    .no-icon {
        font-size: 1.4rem;
        font-weight: bold;
        color: lightgray;
    }
</style>