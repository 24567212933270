<template>
  <div class="preview-container">
    <!-- Header for small screens -->
    <h4 v-if="isSmall" class="preview-header">Náhled oznámení</h4>
    
    <!-- Tools -->
    <div v-if="!isLoading" class="preview-tools">
      <v-slide-group mandatory v-model="previewLanguage">
        <div v-if="selectedLanguages.length > 1">
          <v-slide-item
            v-for="language in selectedLanguages"
            :key="language"
            :value="language"
            v-slot="{ active, toggle }"
          >
            <v-btn x-small rounded depressed
              :id="`preview-${language}-btn`"
              class="mr-1 mb-2"
              :color="active ? 'primary' : 'normal'"
              @click="toggle"
            >
              {{ language }}
            </v-btn>
          </v-slide-item>
        </div>
      </v-slide-group>

      <v-slide-group v-if="!blockParallel" mandatory v-model="previewOrientation">
        <v-slide-item key="blockParallel" value="blockParallel" v-slot="{ active, toggle }">
          <v-btn
            id="preview-blockParallel-btn" x-small rounded depressed
            class="mr-1 mb-2 p-0"
            :color="active ? 'primary' : 'normal'"
            @click="toggle"
          >
            celá obrazovka
          </v-btn>
        </v-slide-item>
        <v-slide-item
          key="portrait"
          value="portrait"
          v-slot="{ active, toggle }"
        >
          <v-btn
            id="preview-portrait-btn"
            x-small rounded depressed
            class="mr-1 mb-2 p-0"
            :color="active ? 'primary' : 'normal'"
            @click="toggle"
          >
            na výšku
          </v-btn>
        </v-slide-item>
        <v-slide-item
          key="landscape"
          value="landscape"
          v-slot="{ active, toggle }"
        >
          <v-btn
            id="preview-landscape-btn" x-small rounded depressed
            class="mr-0 mb-2 p-0"
            :color="active ? 'primary' : 'normal'"
            @click="toggle"
          >
            na šířku
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </div>
    
    <!-- Preview iframe wrapper -->
    <div id="preview-wrapper" class="preview-wrapper">
      <iframe
        :src="`${config.displaysUrl}preview`"
        :class="`preview preview-${previewOrientation}${(isLargeDevice && inDialog) ? '-lg' : (isSmall ? '-sm' : '')}`"
        :id="`preview-frame${inDialog ? '-dialog' : ''}`"
        v-on:load="() => { 
          onUpdateContent();
          this.isLoading = false;
        }"
        scrolling="no"
      ></iframe>
      
      <v-btn
        v-if="!inDialog && !isLoading"
        id="zoom-preview-btn"
        class="zoom-icon"
        color="white"
        @click="$emit('zoomIn')"
        icon x-large
      >
        <v-icon large dark>zoom_in</v-icon>
      </v-btn>
      
      <Loading v-if="isLoading" class="iframe-loader"/>
    </div>
  </div>
</template>

<script>
import { config, displaysMaxValues } from '@/config';
import Loading from "../Loading";

/**
 * Announcement preview component.
 */
export default {
  name: 'AnnouncementPreview',

  components: {
    Loading
  },

  props: {
    announcement: Object,
    selectedAnnouncementType: Object,
    selectedLanguages: Array,
    textLength: Function,
    getShortTitle: Function,
    getShortText: Function,
    announcementImagePath: String,
    announcementImageIsVideo: Boolean,
    blockParallel: Boolean,
    inDialog: Boolean,
    isSmall: Boolean,
    initialLanguage: String,
    initialOrientation: String,
  },

  data() {
    return {
      previewLanguage: this.initialLanguage,
      previewOrientation: this.initialOrientation,
      isLoading: true,
    };
  },

  watch: {
    previewLanguage() { this.updateContentAndEmit('languageChanged', this.previewLanguage); },
    previewOrientation() { this.updateContentAndEmit('orientationChanged', this.previewOrientation); },
    previewText() { this.onUpdateContent(); },
    previewShortText() { this.onUpdateContent(); },
    selectedAnnouncementTypeServiceName() { this.onUpdateContent(); },
    selectedLanguages(newLanguages) {
      if (!newLanguages.includes(this.previewLanguage)) {
        this.previewLanguage = newLanguages[0];
      }
    },
    announcementImagePath() { this.onUpdateContent(); },
    blockParallel(newValue) {
      this.previewOrientation = newValue ? 'blockParallel' : this.previewOrientation;
    }
  },

  methods: {
    onUpdateContent() {
const iframe = document.getElementById(`preview-frame${this.inDialog ? '-dialog' : ''}`);
      const previewLanguage = this.previewLanguage || this.selectedLanguages[0];
      
      iframe?.contentWindow.postMessage({
        announcement: {
          id: this.announcement.id,
          blockParallel: this.announcement.blockParallel,
          color: this.selectedAnnouncementType.color,
          iconName: this.selectedAnnouncementType.iconName,
          language: previewLanguage,
          announcementImagePath: this.announcementImagePath,
          announcementImageIsVideo: this.announcementImageIsVideo,
          title: this.announcement.localizedAttributes[previewLanguage]?.title,
          shortTitle: this.getShortTitle(previewLanguage),
          text: this.announcement.localizedAttributes[previewLanguage]?.text,
          shortText: this.getShortText(previewLanguage),
          url: this.announcement.localizedAttributes[previewLanguage]?.url,
          previewOrientation: this.previewOrientation,
          serviceConfiguration: this.announcement.serviceConfiguration || {},
          serviceName: this.selectedAnnouncementTypeServiceName
        }
      }, config.displaysUrl);
    },

    updateContentAndEmit(event, value) {
      this.onUpdateContent();
      this.$emit(event, value);
    },

    setPreviewLanguage(language) {
      this.previewLanguage = language;
      this.onUpdateContent();
    },

    setPreviewOrientation(orientation) {
      this.previewOrientation = orientation;
      this.onUpdateContent();
    }
  },

  computed: {
    config() {
      return config;
    },

    previewText() {
      return this.announcement.localizedAttributes[this.previewLanguage || this.selectedLanguages[0]].text;
    },

    previewShortText() {
      const language = this.previewLanguage || this.selectedLanguages[0];
      return this.textLength(language) > displaysMaxValues.announcement.shortText
        ? this.announcement.localizedAttributes[language].shortText
        : undefined;
    },

    selectedAnnouncementTypeServiceName() {
      return this.selectedAnnouncementType ? this.selectedAnnouncementType.serviceName : null;
    },

    isLargeDevice() {
      return !['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name);
    }
  }
};
</script>

<style scoped>
  .preview-wrapper {
    overflow: hidden;
    position: relative;
  }

  .preview {
    border: none;
  }

  .preview-landscape-sm {
    width: 760px;
    height: calc(0.93 * 427.5px);
    margin-left: calc(-0.5 * 760px);
    margin-top: calc(-0.5 * 0.935 * 427.5px);
    margin-bottom: 0.1vw;
  }

  .preview-landscape {
    width: max(75vw, 700px);
    height: calc(0.93 * max(42.2vw, 393.75px));
    margin-left: calc(-0.5 * max(75vw, 700px));
    margin-top: calc(-0.5 * 0.935 * max(42.2vw, 393.75px));
    margin-bottom: 0.1vw;
  }

  .preview-landscape-lg {
    width: max(75vw, 1400px);
    height: calc(0.93 * max(42.2vw, 787.5px));
    margin-left: calc(-0.5 * max(75vw, 1400px));
    margin-top: calc(-0.5 * 0.935 * max(42.2vw, 787.5px));
    margin-bottom: 0.1vw;
  }

  .preview-blockParallel-sm {
    width: 380px;
    height: calc(0.93 * 213.75px);
  }
  
  .preview-blockParallel {
    width: max(37.5vw, 350px);
    height: calc(0.93 * max(21.1vw, 196.875px));
  }

  .preview-blockParallel-lg {
    width: max(75vw, 700px);
    height: calc(0.93 * max(42.1875vw, 393.75px));
  }

  .preview-portrait-sm {
    width: 760px;
    height: calc(0.93 * 427.5px);
    margin-right: calc(-0.5 * 760px);
  }
  
  .preview-portrait {
    width: 720px;
    height: calc(0.93 * 405px);
    margin-right: calc(-0.5 * 720px);
  }

  .preview-portrait-lg {
    width: 1440px;
    height: calc(0.93 * 810px);
    margin-right: calc(-0.5 * 1440px);
  }

  .preview-tools {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .preview-container {
    width: fit-content;
  }

  .zoom-icon {
    background: rgba(100,100,100,0.5);
  }

  #preview-wrapper > button {
    position: absolute;
    float: left;
    left: 30px;
    bottom: 25px;
  }

  #preview-wrapper > .iframe-loader {
    position: absolute;
    float: left;
    left: calc(50% - 35px);
    bottom: calc(50% - 45px);
  }

  .preview-header {
    font-size: .75rem
  }
</style>