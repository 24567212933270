import { get, post, put, del} from '@/services/api'
import Vue from "vue";

const state = {
  didLoadAnnouncements: false,
  announcements: [],
  allFilter: [{value: "", text: "Všechny kategorie"}]
};

const getters = {
  didLoadAnnouncements: state => state.didLoadAnnouncements,
  getAnnouncements: state => state.announcements,
  getLocalizedAnnouncements: state => state.announcements.map(announcement => {
    const attrs = announcement.localizedAttributes;
    const firstKey = Object.keys(attrs)[0];
    const localized = attrs["cz"] || attrs[firstKey];

    return {
      ...announcement,
      title: localized.title
    }
  }),
  getAnnouncementById: state => id => {
    return state.announcements.find(announcement => announcement.id == id)
  },
};

const actions = {
  fetchAnnouncements({ commit }, unitId) {
    commit('startedDataDownload');
    return new Promise((resolve, reject) => {
      get(`displays/announcements/unit/${unitId}`)
        .then((response) => {
          commit('setAnnouncements', response.data.items)
          commit('finishedDataDownload');
          resolve();
        })
        .catch(e => {
          console.log(e.message);
          reject();
        });
    });
  },

  async fetchAnnouncementById({commit}, id) {
    commit('errors/clear', null, { root: true });
    commit('startedDataDownload');
    try {
      const response = await get('displays/announcements/' + id)
      commit('updateAnnouncement', response.data.content);
      commit('finishedDataDownload');
    } catch (e) {
      if (e.statusCode == 404) {
            commit('errors/objectNotFound', null, { root: true });
      } else {
        throw e;
      }
    }
  },

  deleteAnnouncement({ commit }, id) {
    return new Promise((resolve, reject) => {
      del(`displays/announcements/${id}`)
        .then(() => {
          commit('filterAnnouncements', id);
          resolve();
        })
        .catch(e => {
          console.log(e.message);
          reject();
        });
    });
  },

  createAnnouncement({ commit }, item) {
    return new Promise((resolve, reject) => {
      post('displays/announcements/', item)
        .then((response) => {
            commit('addAnnouncement', response.data.content);
            resolve(response.data.content);
        })
        .catch(e => {
          console.log(e.message);
          reject();
        });
    });
  },

  createDisplayings({ commit }, data) {
    return new Promise((resolve, reject) => {
      post(`displays/displayings/${data.announcementId}`, data.displayings)
        .then((response) => {
          commit('setDisplayings', {
            announcementId: data.announcementId,
            displayings: response.data.items
          });
          resolve();
        })
        .catch(e => {
          console.log(e.message);
          reject();
        });
    });
  },

  deleteDisplayings({ commit }, announcementId) {
    return new Promise((resolve, reject) => {
      del(`displays/displayings/${announcementId}`)
        .then((response) => {
            commit('setDisplayings', {
              announcementId,
              displayings: []
            });
            resolve(response.data.items);
        })
        .catch(e => {
          console.log(e.message);
          reject();
        });
    });
  },

  editAnnouncement({ commit }, item) {
    return new Promise((resolve, reject) => {
      put(`displays/announcements/${item.id}`, item)
        .then(() => {
          commit('updateAnnouncement', item);
          resolve();
        }).catch(e => {
          console.log(e.message);
          reject();
        });
    });
  },

};

const mutations = {
  startedDataDownload: state => state.didLoadAnnouncements = false,
  finishedDataDownload: state => state.didLoadAnnouncements = true,
  setAnnouncements: ( state, announcements ) => (state.announcements = announcements),
  filterAnnouncements: ( state, id ) => (
    state.announcements = state.announcements.filter(announcement => announcement.id !== id)
  ),
  addAnnouncement: ( state, item ) => (state.announcements.push(item)),
  updateAnnouncement: (state, item) => {
    const existing = state.announcements.findIndex(announcement => announcement.id === item.id);
    if (existing > -1) {
        Vue.set(state.announcements, existing, item);
    } else {
        state.announcements.push(item);
    }
  },
  setDisplayings: (state, data) => {
    const existing = state.announcements.findIndex(announcement => announcement.id === data.announcementId);
    if (existing > -1) {
      state.announcements[existing].displayings = data.displayings
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
