var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.didLoadedAll === false)?_c('Loading'):_c('div',{staticClass:"b-calendar"},[_c('v-row',[_c('v-col',{staticStyle:{"padding-top":"0 !important","padding-bottom":"0 !important"},attrs:{"lg":"11"}},[_c('div',{staticClass:"b-calendar__calendar"},[_c('div',{staticClass:"mt-6 mb-3 d-flex align-center"},[_c('v-btn',{staticClass:"arrow arrow-left",attrs:{"id":"subtractMonthBtn","icon":"","small":"","variant":"light"},on:{"click":_vm.subtractMonth}},[_c('i',{staticClass:"fa fa-fw fa-chevron-left"})]),_c('div',{staticClass:"mx-3 text-center"},[_vm._v(" "+_vm._s(_vm._f("firstUpper")(_vm.month))+" "+_vm._s(_vm.year)+" ")]),_c('v-btn',{attrs:{"id":"addMonthBtn","icon":"","small":"","variant":"light"},on:{"click":_vm.addMonth}},[_c('i',{staticClass:"fa fa-fw fa-chevron-right"})]),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.todayInCurrentMonthAndYear || !_vm.todayIsEqualSelectDate),expression:"!todayInCurrentMonthAndYear || !todayIsEqualSelectDate"}],staticClass:"go-today-btn",attrs:{"id":"goTodayBtn","small":""},on:{"click":_vm.goToday}},[_vm._v(" "+_vm._s(_vm.$t('reservations.today'))+" ")])],1),_c('div',{staticClass:"b-calendar__weekdays"},_vm._l((_vm.getDayNamesAccordingToLocalization()),function(day,index){return _c('div',{key:index,staticClass:"weekday"},[_c('strong',[_vm._v(_vm._s(day))])])}),0),_c('div',{staticClass:"b-calendar__dates"},_vm._l((_vm.dateList),function(date){return _c('div',{key:date.key,staticClass:"date text-right",class:{
                 'selected': _vm.dateIsEqualSelectDate(date),
                 'today': date.today,
                 'blank': date.blank,
                 'weekend': date.weekend,
                 'closed_block': date.closed,
               },attrs:{"data-date":date.date}},[_c('a',{staticClass:"link",on:{"click":function($event){return _vm.setSelectedDate(date.moment)}}}),_c('div',{staticClass:"not_holiday"},[_c('span',{staticClass:"day",class:{'dot': date.today}},[_vm._v(" "+_vm._s(date.dayNumber)+" ")]),(date.holidayName !== null)?_c('v-tooltip',{staticClass:"holiday_tooltip",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"isHolidayIcon holiday_tooltip",attrs:{"dense":""}},on),[_vm._v(" event_note ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(date.holidayName)+" ")])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(date.additional),expression:"date.additional"}],staticClass:"additional"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(date.additional.year),expression:"date.additional.year"}],staticClass:"year"},[_vm._v(" "+_vm._s(date.additional.year)+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(date.additional.month),expression:"date.additional.month"}],staticClass:"month"},[_vm._v(" "+_vm._s(date.additional.month)+" ")])])],1),_c('div',{staticClass:"info_graphic"},[(_vm.showStudyRestrictionDiv(date.apiDate) !== 0)?_c('div',{staticClass:"info_graphic_item closed",class:[(_vm.validateEditButton(date.apiDate)) ? 'closed_history' : 'closed']},[_c('span',{staticClass:"info_graphic_item_text"},[_vm._v(" "+_vm._s(_vm.$tc('reservations.study', _vm.showStudyRestrictionDiv(date.apiDate)))+" ")])]):_vm._e(),(_vm.showPersonalRestrictionDiv(date.apiDate) !== 0)?_c('div',{staticClass:"info_graphic_item ",class:[(_vm.validateEditButton(date.apiDate)) ? 'informative_msg_history' : 'informative_msg']},[_c('span',{staticClass:"info_graphic_item_text"},[_vm._v(" "+_vm._s(_vm.$tc('reservations.personal', _vm.showPersonalRestrictionDiv(date.apiDate)))+" ")])]):_vm._e(),(_vm.showReservationDiv(date.apiDate) !== 0)?_c('div',{staticClass:"info_graphic_item",class:[(_vm.validateEditButton(date.apiDate)) ? 'reservation_history' : 'reservation']},[_c('span',{staticClass:"info_graphic_item_text",attrs:{"data-cy":"calendar-day-reservations"}},[_vm._v(" "+_vm._s(_vm.$tc('reservations.res_calendar', _vm.showReservationDiv(date.apiDate)))+" ")])]):_vm._e()])])}),0)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }