<template>
    <div>
        <Loading v-if="!didLoadData"/>
        <div v-else>
            <ConfirmDialog 
                :model="showDeleteDialog" 
                :onConfirm="attemptToDeleteCategory"
                :onCancel="() => categoryToDelete = undefined"
                yesColor="red"
                yes="Smazat"
            >
                <div v-if="categoryToDelete">{{`Přejete si smazat oznámení s titulkem "${categoryToDelete.localizedAttributes.cz.name}"?`}}</div>
                Tato operace je nevratná.
            </ConfirmDialog>
            <GamificationDialog
                :model="showDialog"
                @confirm="saveCategory"
                @close="showDialog = false"
                :dialogTitle= "dialogTitle" 
                :target="editedCategory"
                :attributeField="'category_name'" 
            />
            <v-container>
                <BackButton
                        :target="{name: 'Gamification', params: {subjectId: this.subjectId, subjectName: this.subjectName}}"
                        >
                        {{subjectName}}
                </BackButton>
                <v-row>
                    <v-col>
                        <span class="topHeading">Kategorie</span>
                    </v-col>
                    <v-col style="max-width: 100px;">
                        <PrimaryButton class="newCategory"
                            @click="showCreateDialog"
                            >
                            Nová
                        </PrimaryButton>
                    </v-col>
                </v-row>


                
                <v-data-table
                v-model="selectedItems"
                :headers="headers"
                :items="getAllCategories"
                loading-text="Načítání..."
                :sort-by.sync="$query.sort"
                :sort-desc.sync="$query.desc"
                :items-per-page.sync="$query.rowsPerPage"
                :page.sync="$query.page"
                >
                    <template v-slot:item.localizedAttributes.cz.name="{item}">
                        <span class="text-xs-left"
                        style="font-size: 1rem !important">{{item.localizedAttributes.cz.name}}</span>
                    </template>

                    <template v-slot:item.edit="{item}" style="display: flex;"> 
                        <div style="display: flex;  justify-content: center;">
                            <v-icon  @click="showEditDialog(item)">
                                edit
                            </v-icon>
                                <DeleteIcon @click="() => categoryToDelete = item"></DeleteIcon>
                        </div>

                    </template>
                </v-data-table>
            </v-container>
        </div>
    </div>

    
</template>

<script>
import PrimaryButton from "@/components/buttons/PrimaryButton";
import BackButton from "@/components/layout/BackButton";
import DeleteIcon from "../../components/buttons/DeleteIcon";
import Loading from "../../components/Loading";
import {mapActions, mapGetters} from "vuex";
import GamificationDialog from "./GamificationDialog.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog"




export default {
    name: "SubjectCategories",

    
    components: {
        BackButton,
        PrimaryButton,
        DeleteIcon,
        Loading,
        GamificationDialog,
        ConfirmDialog
    },

    created() {
        this.fetchCategoriesBySubjectId(this.subjectId);
        this.fetchAllSubjects();
        this.fetchSubjectById(this.subjectId);
        
    },

    data() {    
        return {
            headers: [
                {
                    text: 'Název kategorie',
                    align: 'left',
                    sortable: true,
                    value: 'localizedAttributes.cz.name',
                    width: '65%'
                },
                {
                    text: 'Akce',
                    align: 'center',
                    sortable: false,
                    value: 'edit',
                    width: '10%'
                }
            ],
            selectedItems: [],
            showDialog: false,
            dialogTitle: "",
            editedCategory: null,
            categoryToDelete: undefined,
            errorOnDelete: false,
            isCreating: false,
        }
    },
    

    computed: {
        ...mapGetters('SubjectCategories', ['getCategoriesBySubjectId', 'didLoadCategories', 'getEmptyCategory']),
        ...mapGetters('Subjects', ['getSubjectById', 'didLoadSubjects']),

        

        getAllCategories() {
            return this.getCategoriesBySubjectId(this.subjectId);
        },

         subjectId() {
            return Number(this.$route.params.subjectId);
        },

        subjectName() {
            return this.getSubjectById(this.subjectId)?.localizedAttributes.cz?.name;
        },

        didLoadData() { 
            return this.didLoadCategories && this.didLoadSubjects
        },

        showDeleteDialog() {
            return this.categoryToDelete !== undefined
        },

        
    },

    methods: {
        ...mapActions('SubjectCategories', ['fetchCategoriesBySubjectId', 'deleteCategoryById', 'prepareNewCategory', 'createCategory', 'updateCategory']),
        ...mapActions('Subjects', ['fetchSubjectById', 'fetchAllSubjects']),

        showCreateDialog() {
            this.isCreating = true;
            this.prepareNewCategory();
            this.editedCategory = this.getEmptyCategory;
            this.dialogTitle = this.$t('gamification.new_category')
            this.showDialog = true
        },

        showEditDialog(item) {
            this.isCreating = false;
            this.dialogTitle = this.$t('gamification.edit_category')
            this.editedCategory = this._.cloneDeep(item)
            this.showDialog = true
        },

        attemptToDeleteCategory() {
            this.deleteCategoryById(this.categoryToDelete.id).catch(() => {
                this.errorOnDelete = true;
            })
            this.categoryToDelete = undefined;
        },

        async saveCategory(item) {
            if (this.isCreating) {
                item.item.subjectId = this.subjectId;
                await this.createCategory(item)
            } else {
                await this.updateCategory(item)
            }
            this.showDialog = false;
        },
   
    }

}
</script>
<style scoped>
    .topHeading {
        font-weight: 500 !important;
        font-size: 2rem;
        color: black;
        line-height: 1.2;
    }

    .v-application p {
        margin-bottom: 0 !important;
    }

    .v-icon {
        padding: 0 0.25em;
    }
</style> 