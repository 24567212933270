/**
 * notification.js
 * common code used by service worker and main app
 */


/**
 * Gets parameters for new Notification() or ServiceWorkerRegistration.showNotification() from firebase message
 * @param payload from firebase message
 * @returns array of params title and options
 */
export function getNotificationParamsFromMessage(payload) {
    const notification = JSON.parse(payload.data.notification);
    const language = /^cs\b/.test(navigator.language) ? 'cz' : 'en';
    const title = notification[language].title;
    const options = {
        body: notification[language].text,
        icon: '/favicon-32x32.png',
    };
    return [title, options];
}

export const BACKGROUND_MESSAGE_EVENT = 0;