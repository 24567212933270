import {get, post, put} from '@/services/api'
import Vue from "vue";

const state = {
    warnings: [],
    warningsStatistics: [],
    didLoadWarnings: false,
    didLoadWarningsStatistics: false,
    newWarning: null
};

const getters = {
    warnings: state => state.warnings,
    warningById: state => id => {
        return state.warnings.find(w => w.id === id)
    },
    getLive: state => {
        return state.warnings.filter(warning => warning.archived === false)
    },
    getArchived: state => {
        return state.warnings.filter(warning => warning.archived === true)
    },
    didLoadWarnings: state => state.didLoadWarnings,
    didLoadWarningsStatistics:  state => state.didLoadWarningsStatistics,
    getWarningsStatistics: state => state.warningsStatistics,
    audienceNames : state => state.warningsStatistics.audiences.map(x => x.name),
    audienceAbsolutes : state => state.warningsStatistics.audiences.map(x => x.absolute),
    getNewWarning: state => state.newWarning
};

const actions = {
    async fetchWarnings({commit}) {
        commit('startedDataDownload');
        const response = await get('warnings/');
        commit('setWarnings', response.data.items);
        commit('finishedDataDownload');
    },

    async fetchWarningById({commit}, id) {
        commit('errors/clear', null, { root: true });
        commit('startedDataDownload');
        try {
            const response = await get('warnings/' + id)
            commit('updateWarning', response.data.content);
            commit('finishedDataDownload');
        } catch (e) {
            if (e.statusCode === 404) {
                commit('errors/objectNotFound', null, { root: true });
            } else {
                throw e;
            }
        }
    },

    async createWarning({commit}, {warning, notify}) {
        const response = await post(`warnings/?notify=${notify ? "true" : "false"}`, warning);
        const newWarning = response.data.content;
        commit('addWarning', newWarning);
        return newWarning.id;
    },

    async updateWarning({commit}, {warning, notify}) {
        const response = await put(`warnings/${warning.id}?notify=${notify ? "true" : "false"}`, warning);
        commit('updateWarning', response.data.content);
    },

    async fetchWarningsStatistics({commit}) {
        commit('setDidLoadWarningsStatistics', false);
        const response = await get(`warnings/statistics/`)
        commit('setWarningsStatistics', response.data.content);
        commit('setDidLoadWarningsStatistics', true);
    },

    prepareNewWarning({commit}) {
        commit('prepareNewWarning')
    }

};

const mutations = {
    setWarnings: (state, warnings) => (state.warnings = warnings),
    updateWarning: (state, item) => {
        const existing = state.warnings.findIndex(w => w.id === item.id);
        if (existing > -1) {
            Vue.set(state.warnings, existing, item);
        } else {
            state.warnings.push(item);
        }
    },
    addWarning: (state, item) => (state.warnings.push(item)),
    startedDataDownload: state => state.didLoadWarnings = false,
    finishedDataDownload: state => state.didLoadWarnings = true,
    setDidLoadWarningsStatistics: (state, status) => (state.didLoadWarningsStatistics = status),
    setWarningsStatistics: (state, statistics) => (state.warningsStatistics = statistics),
    prepareNewWarning: (state) => (state.newWarning = {
        archived: false,
        localizedAttributes: {
            cz: {
                title: null,
                description: "## Podnadpis  \n" +
                    "**Hrubý text**  \n" +
                    "*Kurzíva*  \n" +
                    "[odkaz](https://mendelu.cz/)"
            },
            en: {
                title: null,
                description: "## Subtitle \n" +
                    "**Bold text**  \n" +
                    "*Cursive*  \n" +
                    "[link](https://mendelu.cz/)"
            }
        },
        audienceIds: []
    }),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
