<template>
  <div>
      <h4>Fotografie</h4>
      <div v-if="images.length === 0">Žádná Fotografie.</div>
      <div v-else>
        <div v-bind:key="image.id" v-for="(image, i) in images">
          <div :key="i" class="image-box">
            <v-img
                    class="report-img"
                    @click="redirect(getImageUrl(image.id))"
                    :src="getImageUrl(image.id)"
                    aspect-ratio="1"
            ></v-img>
              <v-icon @click="removeImage(image.id)" class="trash">
                  delete
              </v-icon>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { config } from '@/config';

    export default {
        name: "ReportImages",
        props: {
            images: {type: Array, required: true},
            report: {type: Object, required: true},
        },

        methods: {
          ...mapActions('ReportImages', ['deleteReportImage']),
          
          getImageUrl(imageId){
              return config.serverUrl + "reports/images/" + this.report.id + "/" + imageId;
          },
          redirect(url){
            window.open(url, '_blank');
          },
          removeImage( imageId ){
            if (confirm("Opravdu smazat tuto fotografii?")) {
              this.deleteReportImage({id:this.report.id, imageId: imageId})
            }
          }
        }
    }
</script>

<style scoped>
.report-img {
    width: 150px;
    float: left;
    cursor: pointer;
}

.image-box {
  float: left;
  width: 150px;
  height: 150px;
  margin: 2px;
}

.image-box:hover .trash{
  opacity: 1;
  color: #dc3545 !important;
}

.trash {
  width: 30px;
  height: 30px;
  float:left;
  position: relative;
  right: -117px;
  top: -145px;
  background-color: white;
  border-radius: 25px;
  color: #212121 !important;
  opacity: 0.5;
}

</style>
