<template>
  <v-select
    v-bind:id="id || 'facility'"
    :rules="rules"
    :items="allFacilities"
    item-value="id"
    item-text="name"
    :label="label || 'Výdejna stravy'"
    :value="selectedFacility"
    @change="onChange"
    />
</template>

<script>
  export default {
    /**
     * Announcement facility select component.
     */
    name: 'AnnouncementFacilitySelect',

    props: {
      /**
       * ID passed to the input.
       */
      id: String,
      /**
       * Array of facility objects.
       */
      allFacilities: Array,
      /**
       * Input label.
       */
      label: String,
      /**
       * Input validation rules.
       */
      rules: Array,
      /**
       * Initial values.
       */
      initialValues: Object,
    },

    data() {
      return {
        selectedFacility: Number(this.initialValues?.facility),
      }
    },

    mounted() {
      this.$emit('facilityChanged', { pristine: this.lodash.isEqual(this.initialValues?.facility, this.selectedFacility), value: { facility: this.selectedFacility }, valid: typeof this.selectedFacility === 'number' });
    },

    methods: {
      onChange(value) {
        this.$emit('facilityChanged', { pristine: this.lodash.isEqual(this.initialValues?.facility, value), value: { facility: value }, valid: typeof value === 'number' });
      }
    }
  }
</script>

<style scoped>
</style>