<template>
  <v-container fluid>
      <BackButton class="mb-3" :target="{name: 'EntitiesPortal'}">{{ $t('entities.entity_portal') }}</BackButton>
    <v-row>
      <v-col
          v-for="item in statisticsCategories"
          :key="item.key"
          cols="12"
          sm="4">
        <v-card outlined>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-avatar class="mb-5">
                <v-icon color="primary" x-large>{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="text-center text-h6 text-break text-wrap">
                {{ $t(item.key) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions class="justify-center">
            <router-link :to="{path: item.to}">
              <v-btn color="primary" text>{{$t('statistics.enter')}}</v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

import BackButton from "@/components/layout/BackButton";

export default {
  name: "StatisticsOverview",
  components:{
    BackButton
  },
  data() {
    return {
      statisticsCategories: [
        {
          key: 'statistics.occupation',
          target: "StatisticsOccupation",
          to: "occupation",
          icon: "event"
        }
      ]
    }
  }
}
</script>