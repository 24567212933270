<template>
    <div class="margin">
        <v-row>
            <slot/>
            <v-icon v-if="onClick" v-bind:class="{ 'refresh-icon': !noMargin, 'refresh-icon-plain': noMargin }" @click="onClick">{{customIcon ? customIcon : 'refresh'}}</v-icon>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'RefreshButton',

        props: {
        /**
         * No margin.
         */
        noMargin: Boolean,
        /**
         * Custom icon.
         */
        customIcon: String
      },

        methods: {
            onClick() {
                this.$emit('click');
            }
        }
    }
</script>

<style scoped>
    a {
        text-decoration: none !important;
    }

    .margin {
        margin-left: 12px;
        margin-top: 10px;
    }

    .refresh-icon {
        height: fit-content;
        margin: 8px 0 10px 18px;
    }

    .refresh-icon-plain {
        height: fit-content;
        margin: 0;
    }
</style>