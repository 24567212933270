<template>
  <Loading v-if="isLoading"></Loading>

  <UnauthorizedState v-else-if="isNotAssistant" :message="$t('queueManagement.not_an_assistant')"/>

  <v-layout v-else justify-start wrap column class="fill-height">
    <!--top menu-->
    <v-tabs class="top_menubar flex-grow-0" dense flat v-model="activeTab">
      <v-tab class="vtabs" grow @click="goToCalendarDetail()" id="tab-calendar">{{
          $t('queueManagement.reservations')
        }}
      </v-tab>
      <v-tab class="vtabs" grow @click="goToCheckIn()" id="tab-call-in">{{ $t('queueManagement.checkins') }}
        <v-chip
            data-cy="study-department-tab-chip"
            class="ma-2"
            color="red"
            text-color="white"
            v-if="dataToShow && activeTab !== 1">
          {{ dataToShow }}
        </v-chip>
        <v-chip
            class="ma-2"
            color="grey lighten-4"
            text-color="primary"
            v-else-if="activeTab !== 1">
          {{ dataToShow === undefined ? ".." : dataToShow }}
        </v-chip>
      </v-tab>
       <v-tab class="vtabs" grow @click="goToStatistics()" v-if="hasStatisticsAccess">{{ $t('queueManagement.stats') }}</v-tab>
      <v-tab class="vtabs" grow @click="goToBasicEntities()" id="tab-entities">{{
          $t('queueManagement.default_entities')
        }}
      </v-tab>
    </v-tabs>

    <!--middle menu-->
    <router-view/>
  </v-layout>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex';

import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import {config} from '@/config';
import moment from 'moment';
import Loading from "../../components/Loading";
import UnauthorizedState from "@/components/states/UnauthorizedState";

const crudOperations = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
}

export default {
  components: {
    UnauthorizedState,
    Loading,
  },

  name: "QueueManagement",

  data() {
    return {
      activeTab: null,
      dataToShow: 0,
      URL: config.serverUrl,
      today: moment(),
      connected: false,
    }
  },

  async created() {
    await this.loadAssistant();
    this.setPageAccordingToActiveVtabs();
    this.connect();
  },

  mounted() {
    this.setPageAccordingToActiveVtabs();
  },

  destroyed() {
    this.disconnect();
  },

  computed: {
    ...mapGetters('queueItemStatuses', ['getQueueItemStatus']),
    ...mapGetters('pageStorage', ['getAssistantStorage', 'getAssistantIdStorage', 'getVtabsActiveCompoment', 'didLoadAssistant']),
    ...mapState('pageStorage', ['selectedMonth']),
    ...mapState('queueItems', ['queueItems']),

    isLoading() {
      return !this.didLoadAssistant || !this.connected;
    },

    isNotAssistant() {
      return this.getAssistantStorage === null;
    },

    hasStatisticsAccess() {
      return this.$router.getRoutes()
        .find(r => r.name === "StatisticsOverview").meta.allowedRoles
        .includes(this.$auth.getStudyAssistantAndRole().role)
    }
  },

  methods: {
    ...mapActions('queueItems', ['setQueueItemsStorage', 'setDidLoadedQueueItemsStorage', 'pushToQueueItemsStorage', 'deleteFromQueueItemsStorage', 'updateQueueItemsStorage']),
    ...mapActions('Snackbar', ['showErrorSnackbar', 'hideSnackbar']),
    ...mapActions('queueItemStatuses', ['setDidLoadedQueueItemStatusesStorage', 'setQueueItemStatusesUpdate', 'pushToQueueItemStatusesStorage', 'deleteFromQueueItemStatusesStorage', 'updateQueueItemStatusesStorage']),
    ...mapActions('closedHours', ['setClosedHoursStorage', 'setDidLoadedClosedHoursStorage', 'pushToClosedHoursStorage', 'deleteFromClosedHoursStorage', 'updateClosedHoursStorage']),
    ...mapActions('pageStorage', ['fetchAssistantByXlogin', 'setVtabsActiveCompomentStorage', 'setSelectedMonth']),

    async loadAssistant() {
      if (this.getAssistantStorage === null) {
        let assistant = this.$auth.getStudyAssistantAndRole();
        if (assistant !== null) {
          await this.fetchAssistantByXlogin(assistant.xlogin);
        }
      }
    },

    goToCalendarDetail() {
      this.setVtabsActiveCompomentStorage('CalendarDetail');
      if (this.$route.name !== 'CalendarDetail') {
        this.$router.push({name: 'CalendarDetail', params: {assistantID: this.getAssistantIdStorage}});
      }
    },
    goToCheckIn() {
      this.setVtabsActiveCompomentStorage('CheckIn');
      this.$router.push({name: 'CheckIn', params: {assistantID: this.getAssistantIdStorage}});
    },
    goToStatistics() {
      this.setVtabsActiveCompomentStorage('StatisticsOverview');
      // this.$router.push({name: 'Statistics', params: {assistantID: this.getAssistantIdStorage}});
      this.$router.push({name: 'StatisticsOverview'});
    },
    goToBasicEntities() {
      this.setVtabsActiveCompomentStorage('BasicEntities');
      this.$router.push({name: 'EntitiesPortal', params: {assistantID: this.getAssistantIdStorage}});
    },

    setPageAccordingToActiveVtabs() {
      switch (this.getVtabsActiveCompoment) {
        case 'CalendarDetail':
          this.activeTab = 0;
          this.goToCalendarDetail();
          break;
        case 'CheckIn':
          this.activeTab = 1;
          this.goToCheckIn();
          break;
        case 'StatisticsOverview':
          this.activeTab = 2;
          this.goToStatistics();
          break;
        case 'BasicEntities':
          this.activeTab = 3;
          this.goToBasicEntities();
          break;
        case 'NewReservation':
          this.activeTab = 0;
          break;
        case 'NewRestriction':
          this.activeTab = 0;
          break;
        case 'RestrictionDetail':
          this.activeTab = 0;
          break;
        case 'ReservationDetail':
          this.activeTab = 0;
          break;
        case 'StudyProblemsCategoryPortal':
          this.activeTab = 3;
          break;
        case 'StudyOpenHoursPortal':
          this.activeTab = 3;
          break;
        case 'studyFieldPortal':
          this.activeTab = 3;
          break;
        case 'StudyProblemsItemPortal':
          this.activeTab = 3;
          break;
        case 'AssistantPortal':
          this.activeTab = 3;
          break;
        case 'ReasonsPortal':
          this.activeTab = 3;
          break;
      }
    },

    connect() {
      const token = this.$auth.getToken();
      const url = `${this.URL}socket?access_token=${token}`;

      this.socket = new SockJS(url);
      this.stompClient = Stomp.over(this.socket);
      if (config.env === "production") {
        this.stompClient.debug = () => {
        };
      }

      this.stompClient.connect({},
          () => {
            this.connected = true;
            this.stompClient.subscribe(`/study-department/calendar/${this.getAssistantIdStorage}`, message => {
              this.setDidLoadedQueueItemStatusesStorage(false);
              this.setDidLoadedQueueItemsStorage(false);
              this.setDidLoadedClosedHoursStorage(false);

              let item = JSON.parse(message.body);

              this.setQueueItemStatusesUpdate(item.queueItemStatuses);
              this.setQueueItemsStorage(item.queueItems);
              this.setClosedHoursStorage(item.closedHoursResponses);

              this.setDidLoadedQueueItemStatusesStorage(true);
              this.setDidLoadedQueueItemsStorage(true);
              this.setDidLoadedClosedHoursStorage(true);

              this.dataToShow = this.getCreatedOnly(item.queueItems);


            });

            this.stompClient.subscribe(`/study-department/calendar-updates`, this.handleCalendarUpdate);
            this.stompClient.subscribe(`/study-department/calendar-updates/${this.getAssistantIdStorage}`, this.handleCalendarUpdate);

            this.connected = true;
            this.hideSnackbar();

            this.requestMonthData();
          },
          error => {
            console.error('Socket connection error:', error);
            console.info('Reconnecting websocket in 10 seconds.');

            this.connected = false;
            this.showErrorSnackbar("Aj, ztratili jsme spojení.");

            setTimeout(() => this.connect(), 10000);
          },
      );
    },

    handleCalendarUpdate(message) {
      let item = JSON.parse(message.body);

      this.setDidLoadedQueueItemStatusesStorage(false);
      this.setDidLoadedQueueItemsStorage(false);
      this.setDidLoadedClosedHoursStorage(false);

      if (item.operation === crudOperations.CREATE && item.queueItem !== null && item.queueItemStatus !== null) {
        this.pushToQueueItemStatusesStorage(item.queueItemStatus)
        this.pushToQueueItemsStorage(item.queueItem)

      } else if (item.operation === crudOperations.DELETE && item.queueItem !== null) {
        this.deleteFromQueueItemStatusesStorage(item.queueItem.queueItemStatusId)
        this.deleteFromQueueItemsStorage(item.queueItem)

      } else if (item.operation === crudOperations.UPDATE && item.queueItem !== null && item.queueItemStatus !== null) {
        this.updateQueueItemStatusesStorage(item.queueItemStatus)
        this.updateQueueItemsStorage(item.queueItem)

      } else if (item.operation === crudOperations.UPDATE && item.queueItem === null && item.queueItemStatus !== null) {
        this.updateQueueItemStatusesStorage(item.queueItemStatus);
        switch (item.queueItemStatus.status) {
          case "active":
            // goes to CheckInTable
            this.$root.$emit('CallInNextInLineRemoveChosen');
            // goes to CheckInInfobar
            this.$root.$emit('callInNext');
            break;
          case "doing":
            this.$root.$emit('resetTimerValues');
            this.$root.$emit('callInDidCome');
            break;
          case "done":
            this.$root.$emit('callInStartItsOn');
            break;
          case "cancel":
            this.$root.$emit('resetTimerValues')
            this.$root.$emit('callInDidNotCome');
            break;
          case "abort":
            this.$root.$emit('resetTimerValues');
            this.$root.$emit('callInDidNotCome');
            break;
        }
      } else if (item.operation === crudOperations.CREATE && item.closedHours !== null) {
        this.pushToClosedHoursStorage(item.closedHours)

      } else if (item.operation === crudOperations.UPDATE && item.closedHours !== null) {
        this.updateClosedHoursStorage(item.closedHours)

      } else if (item.operation === crudOperations.DELETE && item.closedHours !== null) {
        this.deleteFromClosedHoursStorage(item.closedHours)
      }

      this.setDidLoadedQueueItemStatusesStorage(true);
      this.setDidLoadedQueueItemsStorage(true);
      this.setDidLoadedClosedHoursStorage(true);

      this.dataToShow = this.getCreatedOnly(this.queueItems);
    },

    getCreatedOnly(items) {
      let filtered = items.filter(item => item.assistantId === this.getAssistantIdStorage)
      let queueItems = [];

      for (let item of filtered) {
        let itemQS = this.getQueueItemStatus(item.queueItemStatusId);

        if ((itemQS.status.toLowerCase() === 'created' || itemQS.status.toLowerCase() === 'active' || itemQS.status.toLowerCase() === 'doing') && itemQS.wantedDay === this.today.format('YYYY-MM-DD')) {
          queueItems.push(item);
        }
      }
      return queueItems.length;
    },

    requestMonthData() {
      if (this.stompClient && this.stompClient.connected) {
        const monthTimeStamp = this.selectedMonth.toDate().getTime();
        this.stompClient.send("/app/study-department/request-calendar", `{"monthTimeStamp":${monthTimeStamp}}`, {});
      }
    },

    disconnect() {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
      this.connected = false;
    },
  },
  watch: {
      selectedMonth() {
          this.requestMonthData()
      },
      activeTab(val) {
        if (val === 0) {
            this.requestMonthData();
        }
      }
  }
}

</script>

<style scoped lang="scss">
.top_menubar {
  border-bottom: 2px solid #c4c4c4 !important;
}

.vtabs {
  color: black !important;
  font-weight: 400;
}

.vtabs:visited {
  text-decoration: none;
}

.vtabs:hover {
  text-decoration: none;
}

.vtabs:visited {
  text-decoration: none;
}

::v-deep .wrapper {
  /* Position relative makes the study-overlay to be only inside the parent, not over the whole screen. */
  position: relative;
}

::v-deep .right-panel {
  border-left: 2px solid #c4c4c4 !important;
  background-color: #F6F6F6;
  padding: 1rem 1rem;

  @media (min-width: 960px) {
    min-height: 80vh;
    overflow: auto;
  }

  .stack {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .block-with-icon {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .block-with-icon .v-icon {
    float: left;
    margin-right: 0.5rem;
  }

  .list-items {
    margin-left: 2rem;
  }

  .list-items div:not(:last-child) {
    border-bottom: #e0e0e0 1px solid;
    margin-bottom: 0.2em;
    padding-bottom: 0.2em;
  }

  .animal-image {
    width: 5rem;
    height: 5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
