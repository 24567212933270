<template>
  <v-card class="box">
    <v-radio-group v-model="selectedSize">
      <v-radio
           v-for="n in exportTypes"
           :key = "n.value"
           :label = "n.text"
           :value = "n.value"
         ></v-radio>
      </v-radio-group>
      <div v-if="selectedSize == 3">
        <v-text-field v-model="customSize.width" label="Šířka v pixelech"/>
        <v-text-field v-model="customSize.height" label="Výška v pixelech"/>
      </div>
      <v-btn rounded color="secondary mr-sm-4" @click="closeDialog" >Zrušit</v-btn>
      <SaveButton
          @click="exportData"
          label="Exportovat"
      />
  </v-card>
</template>


<script>
    import SaveButton from "@/components/buttons/SaveButton";
    export default {
        name: 'ExportSize',

        components: {
          SaveButton,
        },

        props: {
            value: String,
        },

        data() {
          return {
            exportTypes: [
              { text: 'Velký (1920x1080)', value: 0 },
              { text: 'Střední (1280x720)', value: 1 },
              { text: 'Malý (860x640)', value: 2 },
              { text: 'Vlastní rozměry', value: 3 },
            ],
            customSize: {
              width: 1920,
              height: 1080,
            },
            selectedSize: null,
            exportSizes: [
              { width: 1920, height: 1080 },
              { width: 1280, height: 720 },
              { width: 860, height: 640 },
            ]
          }
        },

        methods: {
          closeDialog() {
              this.$emit('closeDialog');
          },

          exportData() {
            const size = this.selectedSize == 3 ? this.customSize : this.exportSizes[this.selectedSize];
            this.$emit('export', size);
          }
        },
    }
</script>

<style scoped>
  .box {
    padding: 20px;
  }
</style>
