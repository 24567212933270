import {get, put} from "@/services/api"
import Vue from "vue";

const state = {
    badges: [],
    didLoadBadges: false,
    didLoadBadge: false,
};

const getters = {
    getBadges: state => state.badges,
    getBadgeById: state => id => state.badges.find(t => t.id === id),
    didLoadBadges: state => state.didLoadBadges,
};

const actions = {
    async fetchAllBadges({commit}) {
        commit('startedDataDownload');
        await get('gamification/badges/')
        .then((response) => {
            commit('setBadges', response.data.items);
            commit('finishedDataDownload');
        });
    },

    async updateBadge({commit}, {item}) {
        const response = await put(`gamification/badges/${item.id}`, item);
        commit('updateBadge', response.data.content);
    },
};

const mutations = {
    startedDataDownload: state => state.didLoadBadges = false,
    finishedDataDownload: state => state.didLoadBadges = true,
    setDidLoadBadge: (state, value) => (state.didLoadBadge = value),
    setBadges: (state, badges) => (state.badges = badges), 
    updateBadge: (state, item) => {
        const existing = state.badges.findIndex(q => q.id === item.id);
        if (existing > -1) {
            Vue.set(state.badges, existing, item);
        } else {
            state.badges.push(item);
        }
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}