<template>
  <v-select
      :label="label"
      color="dark"
      :items="items"
      :value="localValue"
      :clearable="clearable"
      @input="onChange"
      @click:clear="onClear"
  />
</template>

<script>
export default {
  name: 'FAQCategorySelect',

  props: {
    categories: Array,
    clearable: Boolean,
    language: String,
    label: String,
    value: Number,
  },

  data() {
    return {
      localValue: this.value,
    }
  },

  computed: {
    items() {
      let items = this.categories.map(c => ({
        text: c.localizedAttributes[this.language].name,
        value: c.id
      }));
      items.unshift({
        text: "Bez kategorie",
        value: null
      });
      return items;
    },
  },

  methods: {
    onChange(value) {
      this.localValue = value
      this.$emit('input', value); // don't touch me
    },

    onClear() {

      /**HACKING Vuetify (https://stackoverflow.com/a/67075218)
       *
       * Basically when clearable is enabled, Vuetify ALWAYS sets value to null, but we have null reserved for "No category".
       * So When @click.clear is used, it is also overridden by Vuetify, so basically localValue is set as null and in nextTick ⏰, it's set to undefined.
       * https://github.com/vuetifyjs/vuetify/blob/a1f7e067a524ec1e3064617cbe74dc666e8e3a19/packages/vuetify/src/components/VSelect/VSelect.ts#L300
       **/

      this.$nextTick(() => {
        this.localValue = undefined;
        this.$emit('input', this.localValue); // don't touch me
      });
    },

  }
}
</script>
