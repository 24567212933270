import { get,} from '@/services/api'

const state = {
    didLoadedAssistants: false,
    assistants: [],
};

const getters = {
    didLoadedAssistants: state => state.didLoadedAssistants,

    getAssistants: state => state.assistants,

    getAssistant: state => id => {
        return state.assistants.find(assistant => assistant.id === id);
    },

    getAssistantByXlogin: state => xlogin => {
        return state.assistants.find(assistant => assistant.xlogin === xlogin);
    },

    getAssistantsForDropdown: state => {
        return state.assistants.filter(function(ass) {return ass.defaultRoom !== 'spy'}).map(assistant => ({value: assistant.id, text: assistant.name}));
    },

    areAssistantsFilled: state => {
        return state.assistants.length !== 0;
    }
};

const actions = {

    //Fetch ALL
    async fetchAssistants({ commit }) {
        commit('setDidLoadedAssistants', false);
        await get('study_department/assistant/all')
            .then((response) => {
                commit('setAssistants', response.data.items);
                commit('setDidLoadedAssistants', true);
            });
        return null;
    },

    setAssistantsStorage({commit}, items) {
        commit('setAssistants', items);
    },
};

const mutations = {
    setDidLoadedAssistants: (state, value) => (state.didLoadedAssistants = value),
    setAssistants: ( state, assistants ) => (state.assistants = assistants),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
