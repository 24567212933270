<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-container v-else class="max_width_none" align-content-start>
        <v-layout row class="no_margin no_padding width_max">
            <v-flex>
                <div>
                    <BackButton :target="{name: 'AssistantPortal'}">{{ $t('entities.assistants.portal_name') }}</BackButton>
                    <div v-if="isNewAssistant">
                        <h2 class="h2_header">{{ $t('entities.assistants.new_study_ref') }}</h2>
                        <save-button class="new_button" :disabled="!valid" :is-saving="isSaving" @click=" addNewStudyProblemsCategory"
                                     :label="$t('entities.save')"></save-button>
                    </div>
                    <div v-else>
                        <h2 v-if="!validateUserRoles" class="h2_header">{{ $t('entities.assistants.detail_study_ref') }}</h2>
                        <h2 v-else class="h2_header">{{ $t('entities.assistants.detail_study_ref') }}</h2>

                       <v-menu top :offset-x="true" :offset-y="true" v-if="!validateUserRoles && !assistantModel.archived">
                           <template v-slot:activator="{ on }">
                               <v-btn class="new_button" icon v-on="on"><v-icon>more_vert</v-icon></v-btn>
                           </template>
                           <v-list>
                               <v-list-item @click="deleteAssistant()">
                                   <v-list-item-title class="primary&#45;&#45;text">{{ $t('entities.assistants.remove_study_ref') }}</v-list-item-title>
                               </v-list-item>
                           </v-list>
                        </v-menu>

                        <save-button v-if="!validateUserRoles" class="new_button" :disabled="buttonDisabledValidate" :is-saving="isSaving"
                                     @click="editAssistant"
                                     :label="$t('entities.save')"></save-button>
                    </div>
                </div>
            </v-flex>
        </v-layout>

        <v-form ref="form" v-model="valid">
            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">1. {{ $t('entities.assistants.ref_name') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  :rules="nameRules"
                                  required
                                  v-model="assistantModel.name"
                                  :label="$t('entities.assistants.type_ref_name')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">2. {{ $t('entities.assistants.door_num') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  :rules="[v => !!v || $t('entities.assistants.door_num_required') ]"
                                  required
                                  v-model="assistantModel.default_room"
                                  :label="$t('entities.assistants.door_num')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">3. {{ $t('entities.assistants.Xlogin') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  :rules="[v => !!v || $t('entities.assistants.xlogin_required')]"
                                  required
                                  v-model="assistantModel.xlogin"
                                  :label="$t('entities.assistants.Xlogin_type')"
                                  :disabled="validateUserRoles || blockXlogin">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout v-if="this.isFaculty && this.isAdmin && !this.isNewAssistant" row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">5. {{ $t('entities.assistants.faculty_assign') }}</span>
                </v-flex>
                <v-flex xs4 class="float_left">
                    <v-select solo
                              color="light-green darken-1"
                              :items="getFacultiesAll"
                              v-model="assistantModel.faculty"
                              :label="$t('entities.assistants.faculty')"
                              :menu-props="{offsetY: true , maxHeight: '60vh'}"
                              :rules="[v => !!v || $t('entities.assistants.faculty_required')]"
                              :disabled="validateUserRoles"
                    ></v-select>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin top mb-8">
                <v-flex xs4>
                    <span class="text">4. {{ $t('entities.assistants.ref_assign') }} </span>
                </v-flex>

                <v-layout v-if="dataToDataTable.length !== 0" row class="no_margin padding_top width_max">
                    <v-flex>
                        <v-data-table
                                :headers="customHeaders"
                                :items="dataToDataTable"
                                :sort-by="['name']"
                                :sort-desc="true"
                                :disable-pagination="true"
                                :hide-default-footer="true"
                                class="elevation-1"
                        >
                            <template v-slot:item.assign="{ item }">
                                <v-simple-checkbox :disabled="validateUserRoles" v-model="item.assign"></v-simple-checkbox>
                            </template>

                            <template v-slot:item.action="{ item }">
                                <v-icon class="mr-2" @click="goToDetail(item)">
                                    edit
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-flex>
                </v-layout>

                <v-layout v-else row class="no_margin padding_top width_max">
                    <v-flex>
                        <h2 v-if="isNewAssistant">{{ $t('entities.assistants.study_ref_not_assigned') }}</h2>
                        <h2 v-else>{{ $t('entities.assistants.not_assigned_study_ref') }}</h2>
                    </v-flex>
                </v-layout>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
    import BackButton from "../../../../../components/layout/BackButton";
    import SaveButton from "../../../../../components/buttons/SaveButton";

    import { put, post, del } from '@/services/api'
    import Loading from "../../../../../components/Loading";

    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: "AssistantDetail",

        components: {
            BackButton,
            SaveButton,
            Loading,
        },

        data() {
            let Rid = this.$route.params.Rid;

            return {
                proRid: Rid,

                valid: false,
                isSaving: false,

                resp: null,

                nameRules: [
                    v => !!v || this.$t('entities.assistants.name_required'),
                    v => (v && v.length >= 5) || this.$t('entities.assistants.name_too_short'),
                ],

                assistantModel: {
                    name: null,
                    default_room: null,
                    id: null,
                    xlogin: null,
                    archived: false,
                    faculty: null,
                },

                assistantModelCopy: null,

                headers: [
                    {
                        text: this.$t('headers.field_code'),
                        align: 'left',
                        value: 'short_code',
                        width: '',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.field_name'),
                        align: 'left',
                        value: 'name',
                        width: '',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.field_name_en'),
                        align: 'left',
                        value: 'name_en',
                        width: '',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.study_deg'),
                        align: 'left',
                        value: 'study_type_localized',
                        width: '',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.study_form'),
                        align: 'left',
                        value: 'type_localized',
                        width: '',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.assign'),
                        align: 'left',
                        value: 'assign',
                        width: '',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.action'),
                        align: 'left',
                        value: 'action',
                        width: '',
                        sortable: false,
                    },
                ],

                dataToDataTable: [],
                dataToDataTableCopy: [],
            }
        },

        created() {
            this.fetchAssistants();
            this.fetchStudyFields();
            this.fetchAllFaculties();

            if (!this.isNewAssistant) {
                this.loadModel();
                this.setItemsToTable();
            }

            this.createCopyOfOriginal();
        },

        computed: {
            ...mapGetters('assistant', ['getAssistantByXlogin', 'getAssistants', 'getAssistant', 'didLoadedAssistants']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),
            ...mapGetters('studyFields', ['didLoadedStudyFields', 'getStudyFields', ]),
            ...mapGetters('Faculties',['getFacultiesAll']),

            isStudyAdmin() {
                return this.$auth.getStudyAssistantAndRole()?.role === "STUDY_ADMIN";
            },

            customHeaders() {
                return this.isStudyAdmin ? this.headers : this.headers.filter(h => h.value !== 'action');

            },

            isNewAssistant() {
                return this.proRid === 'new';
            },

            didLoadedAll() {
                return this.didLoadedAssistants && this.didLoadedStudyFields;
            },

            isAdmin() {
                return this.$auth.getRoles().includes('ADMIN');
            },

            isFaculty(){
                return this.$route.query.tab === "faculty";
            },

            buttonDisabledValidate() {
                return !this.valid || (this.compareOriginalAndStateNow() && this.compareOriginalAndStateNowTable());
            },

            validateUserRoles() {
                return this.$auth.getStudyAssistantAndRole()?.role === 'STUDY';
            },

            blockXlogin() {
              return this.getAssistantByXlogin(this.getAssistantStorage.xlogin).id === this.proRid
            }
        },

        methods: {
            ...mapActions('studyFields', ['fetchStudyFields']),
            ...mapActions('assistant', ['fetchAssistants', 'setAssistantsStorage']),
            ...mapActions('Faculties',['fetchAllFaculties']),

            createCopyOfOriginal: function () {
                this.assistantModelCopy = {...this.assistantModel};
                this.dataToDataTableCopy = JSON.parse(JSON.stringify(this.dataToDataTable));
            },

            compareOriginalAndStateNow: function () {
                return JSON.stringify(this.assistantModel) === JSON.stringify(this.assistantModelCopy);
            },


            returnAssignArray(array) {
                let assignArray = [];

                for (let item of array) {
                    assignArray.push(item.assign);
                }

                return assignArray;
            },

            compareOriginalAndStateNowTable: function () {
                return JSON.stringify(this.returnAssignArray(this.dataToDataTable)) === JSON.stringify(this.returnAssignArray(this.dataToDataTableCopy));
            },

            editAssistant: function () {
                if (this.$refs.form.validate()) {
                    this.isSaving = true;

                    put(`study_department/assistant/${this.proRid}`, {
                        "name": this.assistantModel.name,
                        "defaultRoom": this.assistantModel.default_room,
                        "xlogin": this.assistantModel.xlogin,
                        "studyFieldIds": this.getIdsOfStudyFields(),
                        "archived": this.assistantModel.archived,
                        "facultyId": this.assistantModel.faculty.id,
                    }).then(response => {
                        this.setAssistantsStorage(response.data.items);
                        this.isSaving = false;
                        this.pushToAssistantPortal();
                    }).catch(e => {
                        if (e.statusCode > 200 && e.statusCode < 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save'));
                        }
                        if (e.statusCode === 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                    });
                }
            },

            addNewStudyProblemsCategory: function () {
                if (this.$refs.form.validate()) {
                    this.isSaving = true;

                    post('study_department/assistant/', {
                        "name": this.assistantModel.name,
                        "defaultRoom": this.assistantModel.default_room,
                        "xlogin": this.assistantModel.xlogin,
                        "studyFieldIds": this.getIdsOfStudyFields(),
                    }).then(response => {
                        this.setAssistantsStorage(response.data.items);
                        this.isSaving = false;
                        this.pushToAssistantPortal();
                    }).catch(e => {
                        if (e.statusCode > 200 && e.statusCode < 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                        if (e.statusCode === 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                    });
                }
            },

            deleteFromVuex(id) {
                let items = this.getAssistants;
                return items.filter(studyProblemsItem => studyProblemsItem.id !== id);
            },

            deleteAssistant: function () {
                if (confirm(this.$t('entities.assistants.confirm_deletion'))) {
                    this.setAssistantsStorage(this.deleteFromVuex(this.proRid));
                    del('study_department/assistant/' + this.proRid)
                        .then(() => {
                            this.pushToAssistantPortal()
                        })
                }
            },

            getIdsOfStudyFields() {
                let items = []
                for (let item of this.dataToDataTable) {
                    if (item.assign === true) {
                        items.push(item.id)
                    }
                }
                return items;
            },

            loadModel() {
                if (!this.isNewAssistant) {
                    let item = this.getAssistant(this.proRid);
                    this.assistantModel.name = item.name;
                    this.assistantModel.default_room = item.defaultRoom;
                    this.assistantModel.id = item.id;
                    this.assistantModel.xlogin = item.xlogin;
                    this.assistantModel.archived = item.archived;
                    this.assistantModel.faculty = item.faculty;
                }
            },

            setItemsToTable() {
                this.dataToDataTable = [];

                let studyFields = this.getStudyFields;

                for (let item of studyFields) {
                    let dataPrototype = {
                        name: null,
                        name_en: null,
                        short_code: null,
                        type: null,
                        study_type: null,
                        study_type_localized: null,
                        id: null,
                        assign: null,
                    };

                    dataPrototype.name = item.name;
                    dataPrototype.name_en = item.name_en;
                    dataPrototype.short_code = item.shortCode;

                    dataPrototype.id = item.id;
                    dataPrototype.study_type = item.studyType;
                    dataPrototype.study_type_localized = this.returnCorectTraslationForStudyType(item.studyType);
                    dataPrototype.type = item.type;
                    dataPrototype.type_localized = this.returnCorectTraslationForType(item.type);

                    dataPrototype.assign = !!this.getAssistant(this.proRid).studyFieldIds.some(e => e === item.id);

                    this.dataToDataTable.push(dataPrototype);
                }
            },

            returnCorectTraslationForType(type) {
                switch (type) {
                    case 'weekly_type':
                        return this.$t('entities.study_types.weekly_type');
                    case 'daily_type':
                        return this.$t('entities.study_types.daily_type');
                    case 'other_type':
                        return this.$t('entities.study_types.other_type');
                }
            },

            returnCorectTraslationForStudyType(type) {
                switch (type) {
                    case 'Bachelor':
                        return this.$t('entities.study_types.Bachelor');
                    case 'Master':
                        return this.$t('entities.study_types.Master');
                    case 'Doctoral':
                        return this.$t('entities.study_types.Doctoral');
                    case 'Other':
                        return this.$t('entities.study_types.other_type');
                }
            },

            goToDetail(item) {
              this.$router.push({name: 'StudyFieldDetail', params: {Rid: item.id, from: "assistant", id: this.proRid}});
            },

            deleteFromDataToTable(id) {
                if (confirm(this.$t('entities.assistants.confirm_deletion_with_save'))) {
                    let items = this.dataToDataTable;
                    this.dataToDataTable = items.filter(studyProblemsItem => studyProblemsItem.id !== id);
                }
            },

            pushToAssistantPortal(){
                if (this.isFaculty && this.isAdmin){
                    this.$router.push({name: "AssistantPortal",query: {'tab': 'faculty'}})
                }else{
                    this.$router.push({name: "AssistantPortal"})
                }
            }

        }
    }
</script>

<style scoped>
    .new_button {
        float: right;
    }

    .max_width_none {
        max-width: none !important
    }

    .no_padding {
        padding: 0;
    }

    .no_margin {
        margin: 0;
    }

    .width_max {
        width: 100%;
    }

    .h2_header {
        margin-bottom: 1.5rem;
        width: 60%;
        float: left;
    }

    .text {
        font-size: large;
    }

    .no_margin {
        margin: 0;
    }

    .float_left {
        float: left;
        margin: 0;
        padding: 0;
    }

    .delete_this {
        font-size: large;
        font-weight: bold;
        color: red;
        cursor: pointer;
    }

    .padding_top {
        padding-top: 2rem;
    }
</style>
