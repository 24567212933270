<template>
  <v-btn-toggle v-model="preferredPosition" class="my-4">
    <v-btn value="portrait" id="announcement-preferred-portrait" title="Na výšku">
      <span class="hidden-sm-and-down">Na výšku</span>
      <v-icon class="hidden-md-and-up" color="grey darken-3">stay_primary_portrait</v-icon>
    </v-btn>
    <v-btn value="landscape" id="announcement-preferred-landscape" title="Na šířku">
      <span class="hidden-sm-and-down">Na šířku</span>
      <v-icon class="hidden-md-and-up" color="grey darken-3">stay_primary_landscape</v-icon>
    </v-btn>
    <v-btn value="none" id="announcement-preferred-none" title="Bez preference">
      <span class="hidden-sm-and-down">Bez preference</span>
      <v-icon class="hidden-md-and-up" color="grey darken-3">close</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
  export default {
    /**
     * Announcement preferred position select component.
     */
    name: 'AnnouncementPreferredPosition',

    props: {
      /**
       *  Preferred position value.
       */
      position: String,
    },

    data() {
      return {
        preferredPosition: this.position
      }
    },

    watch: {
      preferredPosition(value) {
        this.$emit('change', value);
      }
    }
  }
</script>

<style scoped>
</style>