import {makeRequest} from "@/services/api/axios-helper";
import Vue from "vue";
import ApiError from "@/services/api/ApiError";

export async function operation(method, uri, data, headers) {
    await renewAccessToken();
    const headersWithAuth = {...authorizationHeader(), ...headers};
    const response = await makeRequest(method, uri, data, headersWithAuth);
    await checkResponse(response);
    return response;
}

async function checkResponse(res) {
    if (res.status === 401) {
        throw new ApiError(401, "Uživatel není přihlášen.", res);
    } else if (res.status === 403) {
        throw new ApiError(403, "Uživatel nemá dostatečná oprávnění.", res);
    } else if (res.status === 404) {
        throw new ApiError(404, "Cílový objekt neexistuje.", res);
    } else if (res.status === 409) { // Conflict must be handled inside modules
        throw new ApiError(409, null, res);
    } else if (res.status >= 304) { // other errors
        const message = res.method === 'get' ? 'Nepodařilo se načíst data.' : 'Nepodařilo se uložit data.';
        throw new ApiError(res.status, message, res);
    }
}

const authorizationHeader = () => {
    if(Vue.$auth.isLoggedIn()) {
        return { "Authorization" : "Bearer " + Vue.$auth.getToken() };
    }
    return {};
};

async function renewAccessToken() {
    if (!Vue.$auth.isLoggedIn()) {
        return;
    }

    try {
        await Vue.$auth.renewToken();
    } catch (e) {
        throw new ApiError(401, 'Could not renew access token', e);
    }
}
