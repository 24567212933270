<template>
    <v-container>
        <v-row>
            <v-col>
                <BackButton :target="{name: 'Schedules'}">Schedules</BackButton>
            </v-col>
        </v-row>

        <Loading v-if="!getDidLoadedSchedulesByFaculty"></Loading>

        <v-row v-else>
            <v-col>
                <moduleHeader>
                    <h1>Update schedules for {{ faculty }}</h1>

                    <div>
                        <v-btn
                          @click="startParser(faculty)"
                          color="white" rounded
                          class="mr-3"
                        >
                            Start parser
                        </v-btn>

                        <SaveButton
                          :disabled="!isFormValid"
                          @click="save"
                          :is-saving="isSaving"
                          label="Save"
                        />

                    </div>
                </moduleHeader>
            </v-col>
        </v-row>

        <v-row class="pt-5">
            <v-col>

                <v-form @submit.prevent="save" ref="form" v-model="isFormValid">
                    <v-row>
                        <v-col v-for="(schedule, index) in schedules" :key="schedule.id">
                            <v-row>
                                <v-col>
                                    <h3> {{index === 0 ? "Winter" : "Summer"}} </h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select
                                      label="Code"
                                      :items="index === 0 ? codesForZS : codesForLS"
                                      required
                                      v-model="schedule.code"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                   <DatePicker
                                     title="Start of semester"
                                     :rules="[required_rule]"
                                     :clearable="true"
                                     v-on:dateChanged="updateStartOfSemester($event, schedule)"
                                     :initialValue="formatDate(schedule.starts)"
                                   ></DatePicker>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <DatePicker
                                      title="End of semester"
                                      :rules="[required_rule]"
                                      :clearable="true"
                                      v-on:dateChanged="updateEndOfSemester($event, schedule)"
                                      :initialValue="formatDate(schedule.ends)"
                                      :minDate="formatDate(schedule.starts)"
                                    ></DatePicker>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                      :rules="[required_rule]"
                                      type="number"
                                      min="0"
                                      label="Count of weeks"
                                      v-model="schedule.countOfWeeks"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-checkbox
                                      label="First week is odd"
                                      v-model="schedule.firstWeekIsOdd"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                </v-form>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
import BackButton from "@/components/layout/BackButton";
import ModuleHeader from "@/components/layout/ModuleHeader";
import SaveButton from "@/components/buttons/SaveButton";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import DatePicker from "@/components/date-picker/DatePicker";
import Loading from "@/components/Loading";

export default {
    name: "SchedulesDetail",

    components: {Loading, DatePicker, SaveButton, ModuleHeader, BackButton},

    data() {
        return {
            isSaving: false,
            isFormValid: false,
            year: moment().year(),
            required_rule: value => !!value || 'Povinné',
        }
    },

    async created() {
        await this.loadData();
    },

    watch: {
        faculty() {
          this.loadData();
        }
    },

    computed: {
        ...mapGetters('schedules', ['getSchedulesByFaculty', 'getDidLoadedSchedulesByFaculty']),

        faculty() {
            return this.$route.params.faculty;
        },

        schedules() {
            return this.getSchedulesByFaculty;
        },

        codesForZS() {
            return [
                "ZS-" + this.year + "/" + (this.year + 1),
                "ZS-" + (this.year - 1) + "/" + this.year
            ]
        },

        codesForLS() {
            return [
                "LS-" + this.year + "/" + (this.year + 1),
                "LS-" + (this.year - 1) + "/" + this.year
            ]
        }
    },

    methods: {
        ...mapActions('schedules', ['fetchSchedulesByFaculty', 'parseSchedulesByFaculty', 'updateSchedulesByFaculty']),

        async loadData() {
            await this.fetchSchedulesByFaculty(this.faculty);
        },

        async startParser(faculty) {
            await this.parseSchedulesByFaculty(faculty);
        },

        async save() {
            if (this.$refs.form.validate()) {
                this.isSaving = true;

                this.schedules.forEach(s => s.faculty = this.faculty);
                await this.updateSchedulesByFaculty( {item: this.schedules, faculty: this.faculty});

                this.isSaving = false;
            }
        },

        updateStartOfSemester(value, schedule) {
            let index = this.schedules.findIndex((s => s.id === schedule.id));
            this.schedules[index].starts = moment(value).unix();
        },

        updateEndOfSemester(value, schedule) {
            let index = this.schedules.findIndex((s => s.id === schedule.id));
            this.schedules[index].ends = moment(value).unix();
        },

        formatDate(date) {
            return date ? moment.unix(date).format('YYYY-MM-DD') : null;
        },
    }
}
</script>

<style scoped>

</style>