<template>
    <ItemNotFoundState v-if="notFound" :objectName="'Předmět'">
        <template #backButton>
            <BackButton :target="{name: 'Subjects'}">Předměty</BackButton>
        </template>
    </ItemNotFoundState>
    <v-container v-else-if="!isLoading">
        <v-row>
            <v-col>
                <BackButton :target="{name: 'Subjects'}">Předměty</BackButton>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <ModuleHeader class="header">
                    <h1 v-if="isNew">Přidat předmět</h1>
                    <h1 v-else>
                        Úprava předmětu
                    </h1>

                    <div class="d-sm-block" style="display: grid">
                        <v-btn
                          v-if="!isNew"
                          @click="deleteSubject(subjectId)"
                          color="white" elevation="0" rounded
                          class="mr-0 mr-md-8"
                        >
                            Smazat předmět
                        </v-btn>

                        <SaveButton
                          :disabled="!isFormValid"
                          :is-saving="isSaving"
                          @click="save"
                          label="Uložit"
                        />
                    </div>

                </ModuleHeader>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-form @submit.prevent="save" ref="form" v-model="isFormValid" class="formWidth">
                    <v-row>
                        <v-col>
                            <v-simple-table>
                                <tbody>
                                <tr>
                                    <td>
                                        <v-text-field
                                          label="Název (čeština)"
                                          @change="changeWatch"
                                          :rules="[required_rule]"
                                          v-model.trim="subject.localizedAttributes.cz.name"
                                          :maxlength="100"
                                          counter
                                        >
                                        </v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-text-field
                                          label="Název (angličtina)"
                                          @change="changeWatch"
                                          :rules="[required_rule]"
                                          v-model.trim="subject.localizedAttributes.en.name"
                                          :maxlength="100"
                                          counter
                                        >
                                        </v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-text-field
                                          label="Kód"
                                          @change="changeWatch"
                                          :rules="[required_rule]"
                                          v-model.trim="subject.code"
                                          :maxlength="10"
                                          counter
                                        >
                                        </v-text-field>
                                    </td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>

                    <v-row class="m-0 p-0 width_max requirement_padding">
                        <v-col cols="12" sm="5" md="7">
                            <h2 class="d-flex">Správci</h2>
                        </v-col>

                        <v-col cols="12" sm="7" md="5">
                            <v-row>
                                <v-col class="mt-0 pt-0">
                                    <v-text-field
                                      class="float-left"
                                      label="xlogin"
                                      v-model.trim="newUser"
                                      :maxlength="20"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <PrimaryButton class="float-right" @click="addNewUser(newUser)"> + PŘIDAT
                                    </PrimaryButton>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row v-show="subject.users.length !== 0" v-for="(user, index) in subject.users" :key="index">
                        <v-col>
                            <v-icon class="mr-1">
                                person
                            </v-icon>
                            {{user}}
                        </v-col>
                        <v-col>
                            <v-icon class="float-right" @click="removeUserFromSubject(user)">
                                clear
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row v-show="subject.users.length === 0">
                        <v-col>
                            {{$t('gamification.insert_login')}}
                        </v-col>
                    </v-row>

                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ModuleHeader from "@/components/layout/ModuleHeader";
import BackButton from "@/components/layout/BackButton";
import SaveButton from "@/components/buttons/SaveButton";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import ItemNotFoundState from '@/components/states/ItemNotFoundState.vue';

export default {
    name: "SubjectDetail",

    components: {
        SaveButton,
        ModuleHeader,
        BackButton,
        PrimaryButton,
        ItemNotFoundState
    },

    data() {
        return {
            required_rule: value => !!value || 'Povinné',
            isSaving: false,
            isFormValid: false,
            newUser: null,
            isLoading: true,
            notFound: false,
        }
    },

    async created() {
        await this.loadData()
        this.isLoading = false
    },

    watch: {
        subjectId() {
            this.loadData();
        }
    },
    computed: {
        ...mapGetters('Subjects', ['getSubjects', 'getSubjectById', 'prepareNewSubject']),

        subjectId() {
            const urlId = this.$route.params.subjectId;
            return urlId === "new" ? null : Number(urlId);
        },

        isNew() {
            return this.subjectId === null;
        },

        subject() {
            if (this.isNew) {
                return this.prepareNewSubject();
            } else {
                return this.getSubjectById(this.subjectId);
            }
        }
    },

    methods: {
        ...mapActions('leavePrevent', ['madeUnsavedChanges', 'didSaveChanges']),
        ...mapActions('Subjects', ['fetchAllSubjects', 'fetchSubjectById', 'createSubject', 'updateSubject', 'deleteSubjectById']),

        async loadData() {
            if (!this.isNew) {
                try {
                    await this.fetchSubjectById(this.subjectId);
                } catch (e) {
                    if (e.statusCode === 404) {
                        this.notFound = true;
                    } else {
                        throw e;
                    }
                }
            }
        },

        changeWatch() {
            this.madeUnsavedChanges();
        },

        deleteSubject(id) {
            if (window.confirm("Opravdu chcete smazat předmět? Smažou se tím i všechny jeho testy a otázky!")) {
                this.deleteSubjectById(id)
                this.$router.push({name: 'Subjects'})
            }
        },

        removeUserFromSubject(user) {
            this.subject.users = this.subject.users.filter(u => u.localeCompare(user))
        },

        addNewUser(user) {
            if (user !== "" && user != null) {
                if (!this.subject.users.includes(user)) {
                    this.subject.users.push(user)
                }
                this.newUser = ""
            }
        },

        async save() {
            if (this.$refs.form.validate()) {
                this.isSaving = true;
                if (this.isNew) {
                    this.subject.id = await this.createSubject({item: this.subject});
                } else {
                    await this.updateSubject({item: this.subject});
                }

                this.didSaveChanges();

                if (this.isNew) {
                    await this.$router.push({name: 'Subjects'});
                }

                this.isSaving = false;
            }
        }
    }

}
</script>

<style scoped>

.header {
    max-width: 800px;
    width: 100%;
}

.formWidth {
    max-width: 800px;
}

thead {
    border-top: none;
}

th, tr, td {
    border: none !important;
    padding: 0 !important;
}

th {
    font-size: 1rem !important;
}

</style>