var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('module-header',[_c('h1',[_vm._v("Předměty")]),_c('PrimaryButton',{staticClass:"mr-8",on:{"click":function($event){return _vm.createSubject()}}},[_vm._v(" "+_vm._s(this.$vuetify.breakpoint.smAndUp ? 'Přidat předmět' : 'Přidat')+" ")])],1),_c('div',{staticClass:"mt-10"},[_c('v-data-table',{staticClass:"d-none d-sm-block",attrs:{"headers":_vm.headers,"items":_vm.getAllSubjects,"loading-text":"Načítání...","sort-by":_vm.$query.sort,"sort-desc":_vm.$query.desc,"items-per-page":_vm.$query.rowsPerPage,"page":_vm.$query.page},on:{"update:sortBy":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.$query, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.$query, "desc", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.$query, "rowsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.$query, "rowsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.$query, "page", $event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Žádná dostupná data ")]},proxy:true},{key:"item.localizedAttributes.cz.name",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"text-xs-left pointer light-green--text",on:{"click":function($event){return _vm.goToPortal(item.id)}}},[_vm._v(_vm._s(item.localizedAttributes.cz.name))])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.code))])]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.admin))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.goToDetail(item.id)}}},[_vm._v(" edit ")])]}}])}),_c('v-data-iterator',{staticClass:"d-block d-sm-none",attrs:{"items":_vm.getAllSubjects,"hide-default-footer":true,"disable-pagination":true,"hide-default-header":false},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Žádná dostupná data ")]},proxy:true},{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12"}},[_c('mobile-data-table-row',{on:{"click":function($event){return _vm.goToPortal(item.id)},"action":function($event){return _vm.goToDetail(item.id)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(item.localizedAttributes.cz.name))]},proxy:true},{key:"label_header1",fn:function(){return [_vm._v(" SPRÁVCE ")]},proxy:true},{key:"label_content1",fn:function(){return [_vm._v(_vm._s(item.admin))]},proxy:true},{key:"label_header2",fn:function(){return [_vm._v(" KÓD ")]},proxy:true},{key:"label_content2",fn:function(){return [_vm._v(_vm._s(item.code))]},proxy:true},{key:"action_button",fn:function(){return [_vm._v("edit")]},proxy:true}],null,true)})],1)}),1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }