<template>
    <v-container fluid>
        <v-row v-if="input.length !== 0">
            <v-col cols="12" md="4" lg="2" sm="6" v-for="(entity, i) in input" :key="i" class="px-0 py-0">
                <v-container class="pa-0">
                    <v-hover v-slot="{ hover }">
                        <v-card class="mr-5 mb-5 card"
                                :style="setCardStyle(entity, hover)"
                                outlined
                                :disabled="disabled"
                                @click="selectCard(entity)"
                        >
                            <v-card-actions class="d-block my-3">
                                <v-avatar color="#F5F5F5">
                                    <v-icon v-if="entity.iconName"
                                            :style="activeStatus(entity) ? 'color:#79bf12' : '' ">
                                        {{ entity.iconName }}
                                    </v-icon>
                                    <v-img v-else
                                           :src="controlImg(entity.imageUrl)"
                                           max-height=45
                                           max-width="45"
                                    />
                                </v-avatar>
                                <v-card-text class="mt-1 pa-0 font-weight-regular">
                                    {{ entity.localizedAttributes.cz.name }}
                                </v-card-text>
                            </v-card-actions>
                        </v-card>
                    </v-hover>
                </v-container>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <span class="font-weight-regular mb-6">Objekt neexistuje</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "NewsCards",

    props: {
        selectedItems: {
            type: Array,
            default: () => []
        },
        input: Array,
        data: String,
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            selected: this.selectedItems || []
        }
    },

    computed: {
        prepareOutput() {
            return this.selected ? this.selected.map(r => r.id) : [];
        }
    },

    methods: {
        controlImg(icon) {
            return icon ? icon : '/icons/mendelu_icon.png'
        },

        selectCard(entity) {
            if (this.activeStatus(entity)) {
                this.selected = this.selected.filter(x => x.id !== entity.id)
            } else {
                this.selected.push(entity);
            }

            this.$emit('clicked', {
                'type': this.data,
                'value': this.prepareOutput
            })
        },

        activeStatus(entity) {
            return this.selected.find((x) => entity.id === (x.id))
        },

        setCardStyle(entity, hover) {
            const activeStyle = 'border-radius: 15px; box-shadow:inset 0px 0px 0px 4px #79bf12;';
            const hoverStyle = 'border-radius: 15px; box-shadow:inset 0px 0px 0px 2px #79bf12;';
            const defaultStyle = 'border-radius:15px; box-shadow:inset 0px 0px 0px 1px #eeeeef';

            return this.activeStatus(entity) ? activeStyle : hover ? hoverStyle : defaultStyle;
        }
    }

}
</script>

<style scoped>

.card {
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
}


</style>
