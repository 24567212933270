<template>
    <div class="stack">
        <h3>{{ $t('checkIn.on_break') }} </h3>
        <span class="mt-10 text-center">{{ $t('checkIn.break_time') }} {{ $tc('checkIn.minutes', timeToShow) }}</span>
        <span class="mt-2 text-center">{{ $t('checkIn.break_duration') }} {{ $tc('checkIn.minutes', getActivePause.duration) }}</span>
        <v-btn rounded dark color="red" class="mt-5" @click="callInNext()"> {{ $t('checkIn.stop_break') }}</v-btn>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import { put } from '@/services/api'
    import moment from 'moment';

    export default {
        name: "CallInPause",

        data() {
            return {
                timeToShow: 0,
                interval: null,
            }
        },

        created() {
            this.timeToShow = moment().utc("HH:mm:ss").diff(moment.utc(this.getActivePause.startTime, "HH:mm:ss"), 'minutes');

            setInterval(() => {
                this.timeToShow = moment().utc("HH:mm:ss").diff(moment.utc(this.getActivePause.startTime, "HH:mm:ss"), 'minutes');
            }, 61000);
        },

        mounted() {
            this.timeToShow = moment().utc("HH:mm:ss").diff(moment.utc(this.getActivePause.startTime, "HH:mm:ss"), 'minutes');

            setInterval(() => {
                this.timeToShow = moment().utc("HH:mm:ss").diff(moment.utc(this.getActivePause.startTime, "HH:mm:ss"), 'minutes');
            }, 61000);
        },

        computed: {
            ...mapGetters('AssistantPause', ['getActivePause']),
        },

        methods: {
            ...mapActions('AssistantPause', ['unsetActivePause']),

            callInNext() {
                // goes to CheckInTable
                this.$root.$emit('callInStartItsOn');

                this.setPauseInactive();
            },

            setPauseInactive() {
                put('study_department/assistant/pause/' + this.getActivePause.id, {
                    "assistantId": this.getActivePause.assistantId,
                    "date": this.getActivePause.date,
                    "duration": this.getActivePause.duration,
                    "note": this.getActivePause.note,
                    "startTime": this.getActivePause.startTime,
                    "status": "inactive"
                }).then(
                    this.unsetActivePause
                ).catch(e => alert(e.message));
            }
        }
    }
</script>

<style scoped>
    .main_layout {
        height: 42rem;
        border-left: 2px solid #c4c4c4 !important;
        border-bottom: 2px solid #c4c4c4 !important;;
        background-color: #F6F6F6;
    }

    .no_margin {
        margin-right: 0;
    }

    .div_layout {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .div_layout_info_text {
        width: 80%;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .call_in_span {
        font-weight: 500;
        font-size: x-large;
        margin: 1rem 0 1rem 0;
    }

    .call_in_button {
        margin: 1rem 0 1rem 0 !important;
        width: 50% !important;
        text-transform: capitalize;
        font-size: large !important;
        font-weight: 400;
        background: #EF6C00 !important;
        letter-spacing: normal;
        color: white !important;
    }

    .call_in_info_text {
        margin: 1rem 0 1rem 0 !important;
        font-size: 115%;
    }

    .to_middle {
        padding-top: 13rem;
    }
</style>
