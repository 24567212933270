<template>
  <span v-bind:id="id" v-bind:title="title">
    {{text}}
  </span>
</template>

<script>
  /**
   * Text with title displayed on mouse hover.
   */
  export default {
    name: 'TitledText',
    
    props: {
      /**
       * Element ID passed to the span element.
       */
      id: String,
      /**
       * Text content.
       */
      text: String,
      /**
       * Title displayed on hover.
       */
      title: String
    }
  }
</script>

<style scoped>
</style>