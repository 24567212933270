var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.didLoadedAll)?_c('Loading'):_c('div',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getAllTests,"loading-text":"Načítání...","show-select":"","sort-by":_vm.$query.sort,"sort-desc":_vm.$query.desc,"items-per-page":_vm.$query.rowsPerPage,"page":_vm.$query.page},on:{"update:sortBy":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.$query, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.$query, "desc", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.$query, "rowsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.$query, "rowsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.$query, "page", $event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Žádná dostupná data ")]},proxy:true},{key:"item.localizedAttributes.cz.name",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"text-xs-left pointer light-green--text",staticStyle:{"font-size":"1rem !important"},on:{"click":function($event){return _vm.goToDetail(item.id)}}},[_vm._v(_vm._s(item.localizedAttributes.cz.name))])]}},{key:"item.categories",fn:function(ref){
var categories = ref.categories;
return [_c('span',[_vm._v(" - ")])]}},{key:"item.points",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.points))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date)))])]}},(_vm.selectedItems.length!==0)?{key:"body.append",fn:function(){return [_c('v-row',[_c('v-btn',{staticClass:"actionsDetails mx-5 my-5",attrs:{"small":"","disabled":_vm.selectedItems.length === 0},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},[_vm._v("Smazat ")]),_c('DeleteDialog',{attrs:{"method":_vm.deleteTests,"dialog":_vm.dialog},on:{"childToParent":_vm.updateDialog}})],1)]},proxy:true}:null],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }