const state = {
  // unsaved changes flag 
  hasUnsavedChanges: false,
  // redirection function for LeavePrevent dialog 
  redirect: undefined,
};

const getters = {
  hasUnsavedChanges: state => state.hasUnsavedChanges,
  redirect: state => state.redirect,
};

const actions = {
  madeUnsavedChanges({ commit }) {
    commit('setHasUnsavedChanges', true);
  },
  didLeavePage({ commit }, redirect) {
    commit('setRedirect', redirect);
  },
  // cancel function for LeavePrevent dialog 
  didCancelLeave({ commit }) {
    commit('setRedirect', undefined);
  },
  // confirm function for LeavePrevent dialog 
  didConfirmLeave({ commit }) {
    state.redirect();
    commit('setRedirect', undefined);
    commit('setHasUnsavedChanges', false);
  },
  didSaveChanges({ commit }) {
    commit('setHasUnsavedChanges', false);
  },
};

const mutations = {
  setHasUnsavedChanges: (state, status)=> {
    state.hasUnsavedChanges = status;
  },
  setRedirect: (state, redirect) => {
    state.redirect = redirect;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
