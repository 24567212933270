<template>
    <v-dialog
            v-model="dialog"
            width="500">
        <v-card>
            <v-card-title
                    class="headline grey lighten-2"
                    primary-title
            >Potvrdit smazání?
            </v-card-title>
            <v-card-text class="pt-4 pb-1">
                Změny nebude možné vrátit.
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-4">
                <v-spacer></v-spacer>
                <v-btn color="light" @click="emitDialogClosed">Zrušit</v-btn>
                <v-btn color="red" dark @click="method">Ano</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "DeleteDialog",
        data(){
            return{
            }
        },

        props: {
            method: Function,
            dialog: Boolean,
        },

        methods: {
            emitDialogClosed(){
                this.$emit('childToParent');
            }
        }
    }
</script>

<style scoped>

</style>