<template>
	<ItemNotFoundState v-if="objectNotFound" :objectName="'Prohlídka'">
		<template #backButton>
      <BackButton :target="{name: 'OnboardingMaster', query: { tab: 'scenarios'}}">Prohlídky</BackButton>
		</template>
	</ItemNotFoundState>
	<Loading v-else-if="!didLoadData || !scenario"/>
	<div v-else>
    <BackButton :target="{name: 'OnboardingMaster', query: { tab: 'scenarios'}}">Prohlídky</BackButton>
		<ModuleHeader id="header">
			<h2 v-if="isNew">Nová prohlídka</h2>
			<h2 v-else>Úprava prohlídky</h2>
			<v-spacer/>
			<SaveButton
				:is-saving="isSaving"
				:disabled="!isFormValid"
				@click="save()"
				label="Uložit" />
		</ModuleHeader>
		<v-alert v-if="!isFormValid" type="warning" max-width="600px" class="mt-4 mb-0">Pro přidání prohlídky je potřebný název v obou jazycích a přidání alespoň jedné lokace</v-alert>

		<v-form @submit.prevent="save" ref="form" :value="isFormValid">
			<div class="language-selector mb-5">

				<div class="d-flex align-center">
					<v-tabs
						v-model="currentLanguage"
						color="light-green darken-1 pull-right"
					>
						<v-tab id="cz" :href="'#' + this.languages[0].value" class="small" :key="languages[0].value">
							<div class="d-flex align-center">
								<p class="mb-0 ml-2">{{this.languages[0].text}}</p>
							</div>

						</v-tab>

						<v-tab id="en" :href="'#' + languages[1].value" class="small" :key="languages[1].value">
							<div class="d-flex align-center">
								<p class="mb-0 ml-2">{{this.languages[1].text}}</p>
							</div>
						</v-tab>
					</v-tabs>

					<v-tabs-items v-model="currentLanguage">
						<v-tab-item
							:value="languages[0].value"
						></v-tab-item>
						<v-tab-item
							:value="languages[1].value"
						></v-tab-item>
					</v-tabs-items>

				</div>
			</div>
			<v-layout>
				<v-row>
					<v-col cols="12">
						<h4 class="mb-3 title">O prohlídce</h4>
						<v-row>
							<v-col cols="12" :md="isNew ? 12 : 8">
								<v-row>
									<v-col cols="12">
										<v-text-field
											id="scenario-name"
											label="Název prohlídky"
											:rules="[required_rule]"
											v-model="scenario.localizedAttributes[currentLanguage].name"
											:rows="1"
											:maxlength="50"
											counter
											auto-grow
											color="light-green darken-1"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12">
										<v-text-field
											label="Krátký popis"
											v-model="scenario.localizedAttributes[currentLanguage].description"
											:maxlength="150"
											counter
											auto-grow
											:rows="1"
											color="light-green darken-1"
										></v-text-field>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="12" :md="isNew ? 0 : 4">
								<div id="photos-component" v-if="!isNew">
									<template v-if="!isNew && getScenarioImages.length > 0">
										<v-card id="images">
											<v-carousel height="23vw" v-model="carouselActiveImage">
												<v-btn v-if="!isNew" fab dark small color="error" @click="attemptToDeleteActiveImage()" class="ml-5 mb-3 pull-right delete-image">
													<v-icon>fa-trash-alt</v-icon>
												</v-btn>
												<v-carousel-item class="photo-item"
													v-for="(image ,i) in getScenarioImages"
													:key="i"
													:src="getImageUrl(image.id)"
													@click="redirect(getImageUrl(image.id))"
													reverse-transition="fade-transition"
													transition="fade-transition"
												></v-carousel-item>
											</v-carousel>
										</v-card>
									</template>
									<template>
										<vue-dropzone
											ref="dropzone"
											id="uploader"
											:options="dropzoneImageOptions"
											@vdropzone-complete="finishSaving"
											@vdropzone-file-added="imagesUploading = true"
											@vdropzone-success="onFileSucessfullyUploaded"
											@vdropzone-error="fileUploadError"
										></vue-dropzone>
									</template>
								</div>
							</v-col>
							<v-col cols="12">
								<h4 class="mb-3 title">Lokace</h4>
								<v-row>
									<v-col cols="12" md="6">
										<span class="mb-3 label">Dostupné lokace</span>
										<v-text-field
											v-model="search"
											prepend-icon="search"
											label="Hledat"
											single-line
											hide-details
											color="light-green darken-1"
										></v-text-field>
										<v-data-table
											:headers="locationHeaders"
											:items="getLocations"
											:search="search"
											class="elevation-2 location-table">
											<template v-slot:item.id="{ item }">
												<v-icon class="addLocation" color="primary" @click="addLocation(item)">add_circle</v-icon>
											</template>
										</v-data-table>
									</v-col>
									<v-col cols="12" md="6">
										<span class="mb-3 label">Vybrané lokace</span>
										<Draggable
											id="selectedLocations"
											:list="iterableLocations"
											:disabled="false"
											class="list-group">
											<div class="list-group-item" v-for="(item, index) in iterableLocations" :key="index">
												<div style="width: 15%;"><v-icon>drag_handle</v-icon></div>
												<div style="width: 70%;">{{item.localizedAttributes[currentLanguage].name}}</div>
												<div style="width: 15%;"><v-icon class="removeLocation" color="red" @click="removeLocation(index)">clear</v-icon></div>
											</div>
										</Draggable>
									</v-col>
								</v-row>
								<v-btn id="deleteScenario" class="no-padding-btn" v-if="!isNew" color="red" text @click="attemptDeleteScenario">Smazat prohlídku</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-dialog v-model="deleteScenarioDialog" max-width="500px">
					<v-card>
						<v-card-title><h5 class="text-h5 ma-auto">Opravdu chcete smazat tuto prohlídku?</h5></v-card-title>
						<v-card-actions>
							<v-spacer/>
							<v-btn color="blue darken-1" text @click="deleteCancel">Zrušit</v-btn>
							<v-spacer/>
							<v-btn id="deleteConfirm" color="red darken-1" text @click="deleteConfirm">Potvrdit</v-btn>
							<v-spacer/>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-layout>
		</v-form>
	</div>
</template>

<script>

	import {mapGetters, mapActions} from 'vuex';
	import vue2Dropzone from 'vue2-dropzone'
	import ModuleHeader from "@/components/layout/ModuleHeader";
	import BackButton from "@/components/layout/BackButton";
	import SaveButton from "@/components/buttons/SaveButton";
	import {getLocalizedLanguages} from "@/localization/languages";
	import ItemNotFoundState from '@/components/states/ItemNotFoundState.vue';
	import Loading from "@/components/Loading";
	import Draggable from "vuedraggable";
	import {config} from "@/config";
	import Vue from "vue";

	export default {
		name: "ScenarioDetail",

		components: {
			SaveButton,
			BackButton,
			ModuleHeader,
			ItemNotFoundState,
			Loading,
			vueDropzone: vue2Dropzone,
			Draggable,
		},

		data(){
			const allowedFileTypes = ".jpg,.jpeg,.png";
			const id = this.$route.params.id;
			const defaultDropzoneOptions = {
				url: config.serverUrl + 'onboarding/scenarios/images/' + id,
				method: 'post',
				addRemoveLinks: false,
				autoProcessQueue: true,
				filesizeBase: 1000, // use 1MB=1000KB as the metric for maxFilesize
				maxFilesize: 29, // MB, must be lower than limit in nginx proxy and lower than limit on backend!
				paramName: "file",
				headers: { "Authorization" : "Bearer " + Vue.$auth.getToken() },
			}
			return {
				required_rule: value => (value !== "") || 'Povinné',
				isSaving: false,
				currentLanguage: "cz",
				search: "",
				type: null,
				locationHeaders: [
					{
						text: '',
						value: 'id',
						width: '15%'
					},
					{
						text: 'Název',
						value: 'localizedAttributes.cz.name',
						width: "50%"
					},
					{
						text: 'Typ lokace',
						value: 'locationType.name',
						width: "35%"
					},
				],
				ownLocationHeaders: [
					{
						text: 'poradi',
						value: 'order',
						width: '15%'
					},
					{
						text: 'Název',
						value: 'localizedAttributes.cz.name',
						width: "70%"
					},
					{
						text: 'akce',
						value: 'action',
						width: "15%"
					},
				],
				carouselActiveImage: 0,
				dropzoneImageOptions: {
					...defaultDropzoneOptions,
					acceptedFiles: allowedFileTypes,
					thumbnailWidth: 200,
					thumbnailHeight: 200,
					createImageThumbnails: true,
					maxThumbnailFilesize: 9999, // always generate thumbnails, otherwise we are not able to check minimal dimensions
					dictDefaultMessage: "<i class='fas fa-paperclip' style='font-size: 36px; opacity: 0.6;'></i><br>Přetáhněte fotky pro nahrání",
					dictFileTooBig: "Příliš velký soubor ({{filesize}} MB). Max. {{maxFilesize}} MB.",
					dictInvalidFileType: "Obrázek musí být ve formátu " + allowedFileTypes,
					dictResponseError: "Chyba serveru {{statusCode}}",
					init: function () {
						this.on("thumbnail", function(file) {
							if (file.acceptDimensions && file.rejectDimensions) {
									file.acceptDimensions();
							}
						});
					},
					accept: function(file, done) {
						file.acceptDimensions = done;
						file.rejectDimensions = function() { done("Obrázek je příliš malý, min. 1000px!"); };
					}
				},
				iterableLocations: [],
				deleteScenarioDialog: false,
			}
		},

		created() {
			this.loadData();
		},

		watch: {
			scenarioId() {
				this.loadData();
			},
		},

		computed: {
			...mapGetters('Scenarios', ['scenarioById', 'getScenario', 'emptyScenario', 'didLoadScenario', 'getScenarioImages']),
			...mapGetters('Locations', ['locations', 'didLoadLocations']),
			...mapGetters('errors', ['objectNotFound']),


			/**
			 * Returns array of unselected locations.
			 * @returns {*[]}
			 */
			getLocations(){
				const alreadyUsed = [];
				this.iterableLocations.forEach((location) => {
					alreadyUsed.push(location.id);
				});

				return this.locations.filter((l) => !alreadyUsed.includes(l.id))
			},
			isNew() { return this.scenarioId === null; },

			didLoadData() {
				return (this.didLoadScenario || this.isNew) && this.didLoadLocations
			},

			scenarioId() {
				const urlId = this.$route.params.id;
				return urlId === "new" ? null : Number(urlId);
			},

			scenario(){
				return this.isNew ? this.emptyScenario : this.getScenario;
			},

			languages() {
				const langs = getLocalizedLanguages();
				const selectArr = [];
				Object.keys(langs).map((lng) => {
					selectArr.push({
						'text': langs[lng],
						'value': lng,
					});
				});
				return selectArr;
			},

			/**
			 * Form validation methods.
			 * @returns {boolean}
			 */
			isFormValid() {
				return (
					this.hasAllRequiredFields && this.hasAtLeastOneLocation
				);
			},
			hasAllRequiredFields() {
				return this.scenario.localizedAttributes[this.languages[0].value].name !== ""
					&& this.scenario.localizedAttributes[this.languages[1].value].name !== "";
			},
			hasAtLeastOneLocation() {
				return this.iterableLocations.length > 0;
			}
		},

		methods: {
      ...mapActions('leavePrevent',['didSaveChanges']),
			...mapActions('Scenarios', ['fetchScenarioById', 'createScenario', 'updateScenario', 'deleteScenario', 'addImage', 'deleteImage']),
			...mapActions('Locations', ['fetchLocations']),
			...mapActions('errors', ['clear']),


			/**
			 * Loads actual data from server or prepares a blank scenario.
			 * @returns {Promise<void>}
			 */
			async loadData(){
				await this.fetchLocations()
				if ( !this.isNew ) {
					await this.fetchScenarioById(this.scenarioId)
						.then(() => {
							this.getIterableLocations();
						})
				}
				else {
					this.clear();
					this.getIterableLocations();
				}

			},

			/**
			 * Attempts to save or update a scenario.
			 * @returns {Promise<void>}
			 */
			async save(){
				if (this.$refs.form.validate()) {
					this.isSaving = true;

					let scenarioRequest = {
						'localizedAttributes': this.scenario.localizedAttributes,
						'locations': {}
					}

					this.iterableLocations.forEach((l, i) => {
						scenarioRequest.locations[i] = l.id;
					});

					if ( this.isNew ){
						await this.createScenario({scenario: scenarioRequest});
            await this.$router.push({name: 'OnboardingMaster', query: {tab: 'scenarios'}})
					}
					else {
						scenarioRequest['id'] = this.scenarioId;
						await this.updateScenario({scenario: scenarioRequest}).then(() => {
							this.isSaving = false;
						});
					}
				}
			},

			/**
			 * Callback function that handles file upload.
			 */
			finishSaving() {
				this.didSaveChanges();
				this.imagesUploading = false;
				this.hasRecentlyUpdated = true;
				this.isUpdating = false;
				setTimeout(() => this.hasRecentlyUpdated = false, 2000);
				if(this.isNew){
					setTimeout(() => {
						this.setProcessing(false);
            this.$router.push({name: 'OnboardingMaster', query: { tab: 'scenarios'}});
					}, 1500);
				}
			},

			/**
			 * Callback function that handles response result after a file upload.
			 * @param file
			 * @param response
			 */
			onFileSucessfullyUploaded(file, response) {
				// add the new uploaded image to the carousel
				this.addImage(response.content);
				// after a while, remove the uploaded image from dropzone
				setTimeout(() => {
					this.$refs.dropzone.removeFile(file);
				}, 2000);
			},

			/**
			 * Callback function that handles removing the file after an unsuccessful upload.
			 */
			fileUploadError(file) {
				setTimeout(() => {
					this.$refs.dropzone.removeFile(file);
				}, 2000);
			},

			/**
			 * Helper method to get the URL of an image.
			 * @param id
			 * @returns {string}
			 */
			getImageUrl(id){
				return config.serverUrl+"onboarding/scenarios/images/"+this.scenario.id+"/"+ id;
			},

			/**
			 * Helper method that opens an image in a new window.
			 * @param src
			 */
			redirect(src){
				window.open(src, '_blank');
			},

			/**
			 * Helper method that attempts to delete currently selected image.
			 */
			attemptToDeleteActiveImage() {
				if (window.confirm('Opravdu chcete smazat tento obrázek natrvalo?')) {
					this.deleteImage({
						scenarioId: this.scenario.id,
						id: this.getScenarioImages[this.carouselActiveImage].id
					});
				}
			},

			/**
			 * Helper method that adds a location to scenario.
			 * @param location
			 */
			addLocation(location){
				this.iterableLocations.push(location);
			},

			/**
			 * Helper method that removes a location from scenario.
			 * @param position
			 */
			removeLocation(position) {
				this.iterableLocations.splice(position, 1);
			},

			/**
			 * Helper method that attempts to delete a scenario.
			 */
			attemptDeleteScenario(){
				this.deleteScenarioDialog = true;
			},

			/**
			 * Helper method that proceeds with scenario deletion.
			 */
			deleteConfirm(){
				this.deleteScenario(this.scenario.id).then(() => {
					this.deleteScenarioDialog = false;
          this.$router.push({name: 'OnboardingMaster', query: { tab: 'scenarios'}});
				})
			},

			/**
			 * Helper method that cancels scenario deletion.
			 */
			deleteCancel(){
				this.deleteScenarioDialog = false;
			},

			/**
			 * Helper method that transforms an incoming ordered locations dictionary into an iterable list of locations.
			 */
			getIterableLocations() {
				let arr = [];
				if ( this.scenario === null ) return arr;
				for ( let key in this.scenario.locations ){
					arr.push( {
						...this.scenario.locations[key],
						order: key }
					);
				}
				this.iterableLocations = arr;
			},
		}
	}
</script>

<style scoped>
	.location-table {
		margin-top: 2em;
	}
	.notice {
		max-width: 250px;
	}
	#photos-component{
		margin-bottom: 20px;
	}
	#photos-component > div {
		max-width: 45vw;
	}
	@media screen and (max-width: 600px){
		#photos-component {
			margin: 0 auto;
		}
		#photos-component > div {
			max-width: 88vw;
		}
	}

	.delete-image {
		position: absolute;
		z-index: 9;
		margin-top: 15px;
		right: 15px;
	}
	.vue-dropzone {
		border-radius: 15px;
		border: 3px dotted #cfcfcf;
		background-color: #efefef;
	}
	#images {
		margin-bottom: 20px;
	}
	.list-group {
		display: -ms-flexbox;
		display: -webkit-box;
		display: flex;
		-ms-flex-direction: column;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		padding-left: 0;
		margin-bottom: 0;
	}
	.list-group-item {
		cursor: move;
		position: relative;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		padding: 1.25rem;
		margin-bottom: -1px;
		background-color: #fff;
		border: 1px solid rgba(0,0,0,.125);
	}
	.list-group-item:first-child {
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
	}
	.list-group-item:last-child {
		margin-bottom: 0;
		border-bottom-right-radius: 0.25rem;
		border-bottom-left-radius: 0.25rem;
	}
	.ghost {
		cursor: move;
		opacity: 0.5;
		background: #c8ebfb;
	}
	.label {
		color: rgba(0,0,0,0.6) !important;
		line-height: 50px;
	}
	.no-padding-btn {
		padding: 0 !important;
	}
</style>
