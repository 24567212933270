<template>
    <router-link class="light" v-if="to !== undefined" :to="to">
        <v-btn color="light-green darken-1" text rounded><slot/></v-btn>
    </router-link>
    <v-btn v-else @click="onClick" color="light-green darken-1" text rounded><slot/></v-btn>
</template>

<script>
    export default {
        name: 'LightButton',

        props: {
            to: Object
        },

        methods: {
            onClick() {
                this.$emit('click');
            }
        }
    }
</script>

<style scoped>
    .light {
        text-decoration: none;
    }
</style>