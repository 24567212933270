import axios from 'axios';

const state = {
    requests: [],
    requestDetail: null,
    technicianRequests: []
};
const getters = {
    getRequests: state => state.requests,
    getRequest: state => state.requestDetail,
    getTechnicianRequests: state => state.technicianRequests,
    getGarantRequests: state => state.requests.filter(request => request.isGarant === true),
    getDecidedRequests: state => state.requests.filter(requests => requests.decided === true),
    getRequestsToDecide: state => state.requests.filter(requests => requests.decided === false)
};
const actions = {

    //Fetch ALL
    async fetchRequests({ commit }) {
        const response = await axios
            .get('https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/request/my-request');
        const garantRequests = response.data.requests;
        garantRequests.forEach(request => {
            request.isGarant = false;
        });
        commit('setRequests', response.data.requests);
    },

    //Fetch ONE
    async fetchRequest({ commit }, id) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/request/${id}`);
        commit('setRequest', response.data);
    },

    //Fetch technician Requests
    async fetchTechnicianRequests({ commit }) {
        const response = await axios
            .get('https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/request/technician-request');
        commit('setTechnicianRequests', response.data.requests);
    },

    //Fetch garant Requests
    async fetchGarantRequests({ commit }) {
        const response = await axios
            .get('https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/request/to-approve');
        const requests = response.data.requests;
        requests.forEach(request => {
            request.isGarant = true;
        });
        commit('setGarantRequests', response.data.requests);
    },

    //Create request
    async createRequest({ commit }, request) {
        await axios
            .post(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/request`, request).then((newRequest) => {
                commit('addRequest', newRequest.data.requests);
            });
    },

    //Cancel request
    async cancelRequest({ commit }, { id, request }) {
        await axios
            .patch(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/request/${id}`, request)
            .then(() => {
                commit('filterRequest', id);
            });
    },

    //Update ONE
    async updateRequest({ commit }, {id, request} ) {
        await axios
            .put(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/request/${id}`, request)
            .then(() => {
                commit('updateRequest', id, request);
            });
    },

    async approveRequest(request) {
        await axios
            .post(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/request/to-approve`, request);
    },
};

const mutations = {
    setRequests: (state, request) => (state.requests = request),
    setRequest: (state, request) => (state.requestDetail = request),
    setTechnicianRequests: (state, request) => (state.technicianRequests = request),
    setGarantRequests: (state, request) => (state.requests = request),
    filterRequest: (state, id) => (state.requests = state.requests.filter(requests => requests.id !== id)),
    addRequest: (state, request) => (state.requests.push(request)),
    updateRequest: (state, id, request) => {
        let index = state.requests.filter(requests => requests.id === id);
        state.requests[index] = request
    },
    approveRequest: (state, request) => {
        let index = state.requests.filter(requests => requests.requestId === request.id);
        state.requests[index].state = 1;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
