<template>
    <v-container v-if="objectNotFound">
        <v-row>
            <v-col>
                <h1 class="font-weight-medium mb-5">{{ $t('news.users_and_permissions') }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <PlaceHolder
                    :path="'/icons/something-went-wrong.png'"
                    :text="$t('news.something_went_wrong_text')"
                    :title="$t('news.something_went_wrong_title')">
                </PlaceHolder>
            </v-col>
        </v-row>
    </v-container>
    <Loading v-else-if="!didLoadData"/>
    <v-container v-else>
        <v-row class="mr-1">
            <v-col cols="12" md="8" class="pa-0 mt-3">
                <div class="text-h4 font-weight-medium mr-4" data-cy="administrators-heading">{{ $t('news.users_and_permissions') }}</div>
                <div class="subTitle">{{ $t('news.users_and_permissions_subtitle') }}</div>
            </v-col>
            <v-col cols="12" md="4" align-self="center" class="d-flex justify-md-end justify-start pa-0">
                <PrimaryButton @click="createNew('new')" data-cy="add-new">
                    <v-icon class="mr-1">add</v-icon>
                    {{ $t('news.new_user') }}
                </PrimaryButton>
            </v-col>
        </v-row>
        <v-row class="mr-1" justify="start">
            <v-col cols="12" md="5" lg="4" class="pl-0 pb-0">
                <v-text-field
                    color="light-green darken-1"
                    v-model="search"
                    prepend-icon="search"
                    :label="$t('news.who_you_looking_for')"
                    clearable
                ></v-text-field>
            </v-col>
            <v-col>
                <v-container class="pa-0">
                    <v-row justify="end">
                        <v-col cols="12" md="4">
                            <v-select color="light-green darken-1"
                                      :items="getSortedFaculties"
                                      v-model="newsFaculties"
                                      type="string"
                                      :label="$t('news.part')"
                                      multiple
                                      clearable
                                      :menu-props="{offsetY: true , maxHeight: '60vh' }"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span class="mr-1" v-if="index < maxDisplayOnSelect">{{ item.text }}</span>
                                    <span class="grey--text caption ml-1" v-if="index === maxDisplayOnSelect"
                                    >(+{{ numberFaculties }})</span>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select color="light-green darken-1"
                                      :items="getSortedAudiences"
                                      v-model="newsAudiences"
                                      type="string"
                                      :label="$t('news.target_group')"
                                      multiple
                                      clearable
                                      :menu-props="{offsetY: true , maxHeight: '60vh'}"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span class="mr-1" v-if="index < maxDisplayOnSelect">{{ item.text }}</span>
                                    <span class="grey--text caption ml-1" v-if="index === maxDisplayOnSelect"
                                    >(+{{ numberAudiences }})</span>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row v-if="filteredAdministrator.length !== 0">
            <v-col cols="12" lg="4" v-for="(entity, i) in filteredAdministrator" :key="i" class="pa-0">
                <v-card class="mr-5 mb-5" @click="goToDetailEdit(entity.id)" outlined
                        style="border: 2px solid #eeeeef;" data-cy="card-administrator">
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="mb-1 titleCard ">
                                    {{ entity.fullName }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="mb-1 subTitleCard">
                                    {{ entity.xlogin }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="mt-3">
                                    <v-divider></v-divider>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="mb-2 titleChips">
                                    {{ $t('news.faculties') }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-card-text class="row font-weight-medium ma-0 pa-0">
                                        <v-chip v-for="(faculty, i) in entity.faculties" :key="i"
                                                color="#eeeeef"
                                                text-color="#6c7278"
                                                small
                                                class="mr-1 mb-1">
                                            <span>{{ faculty.localizedAttributes.cz.name }}</span>
                                        </v-chip>
                                    </v-card-text>
                                </v-list-item-subtitle>
                                <v-list-item-title class="my-2 titleChips">
                                    {{ $t('news.target_groups') }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-card-text class="row font-weight-medium ma-0 pa-0">
                                        <v-chip v-for="(audience, i) in entity.audiences" :key="i"
                                                color="#eeeeef"
                                                text-color="#6c7278"
                                                small
                                                class="mr-1 mb-1">
                                            <span>{{ audience.localizedAttributes.cz.name }}</span>
                                        </v-chip>
                                    </v-card-text>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-card-actions class="mx-3 mb-2">
                            <span class="button font-weight-medium ">{{ $t('news.edit') }}</span>
                        </v-card-actions>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <PlaceHolder :path="'/icons/no-users.png'"
                             :text="$t('news.no_users_text')"
                             :title="$t('news.no_users_title')">
                </PlaceHolder>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PrimaryButton from "@/components/buttons/PrimaryButton";
import Loading from "@/components/Loading";
import {mapActions, mapGetters} from "vuex";
import PlaceHolder from "@/components/news/PlaceHolder";

export default {
    name: "Administrators",

    components: {
        PrimaryButton,
        Loading,
        PlaceHolder
    },

    async created() {
        await Promise.all([
            await this.fetchAllAudiences(),
            await this.fetchAllFaculties(),
            await this.fetchAllAdministrators()
        ]);
    },

    data() {
        return {
            newsFaculties: '',
            newsAudiences: '',
            search: '',
            maxDisplayOnSelect: 2,
        }
    },

    computed: {
        ...mapGetters('Administrators', ['getSortedFaculties', 'getSortedAudiences', 'getSortedAdministrators', 'didLoadAudiences', 'didLoadFaculties', 'didLoadAdministrators']),
        ...mapGetters('errors', ['objectNotFound']),

        didLoadData() {
            return this.didLoadAudiences && this.didLoadFaculties && this.didLoadAdministrators;
        },

        numberFaculties() {
            return this.newsFaculties.length - this.maxDisplayOnSelect
        },

        numberAudiences() {
            return this.newsAudiences.length - this.maxDisplayOnSelect
        },

        filteredAdministrator() {
            let administrators = this.getSortedAdministrators.filter((obj) =>
                obj.faculties.find((x) => this.newsFaculties.includes(x.id)) ||
                !this.newsFaculties.length
            );
            administrators = administrators.filter((obj) =>
                obj.audiences.find((x) => this.newsAudiences.includes(x.id)) ||
                !this.newsAudiences.length
            );

            administrators = administrators.filter((obj) =>
                !this.search ||
                obj.fullName.toUpperCase().includes((this.search.toUpperCase())) ||
                obj.xlogin.toUpperCase().includes((this.search.toUpperCase()))
            );

            return administrators;
        },

    },

    methods: {
        ...mapActions('Administrators', ['fetchAllAudiences', 'fetchAllFaculties', 'fetchAllAdministrators']),

        goToDetailEdit(id) {
            this.$router.push({name: 'AdministratorDetail', params: {id: id}});
        },

        createNew() {
            this.$router.push({name: 'AdministratorDetail', params: {id: 'new'}});
        },

    }


}
</script>

<style scoped>

.button {
    color: #78BE13;
    font-weight: bold;
    font-size: 19px;
}

.titleCard {
    padding-left: 0;
    padding-top: 0;
    font-weight: 500;
    line-height: 1.2;
    word-break: normal;
    font-size: 24px;
}

.subTitleCard {
    padding: 0;
    line-height: 1.2;
    font-size: 20px;
}

.titleChips {
    padding: 0;
    line-height: 1.2;
    font-size: 14px;
    font-weight: 500;
    color: #6c7278;
}

.subTitle {
    font-size: 19px;
    color: #6D7278;
}

</style>
