<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <div v-else class="stack">
        <h3>{{ $t('checkIn.next_in_line') }}</h3>
         
        <img :src="imageSrc" class="animal-image">

        <div class="block-with-icon">
            <v-icon>confirmation_number</v-icon>
            <span>{{modelToShow.ticketNumber}}</span>
        </div>

        <div class="block-with-icon list-items-wrapper">
            <v-icon>list_alt</v-icon>
            <div class="list-items">
                <div v-for="item in modelToShow.studyProblemsFull" :key="item">
                    {{item}}
                </div>
            </div>
        </div>

        <div class="block-with-icon">
            <v-icon>schedule</v-icon>
            <span>{{ $t('checkIn.scheduled_time') }} {{modelToShow.time}}</span>
        </div>

        <div class="block-with-icon">
            <v-icon>person</v-icon>
            <span>{{modelToShow.studentXlogin}}</span>
        </div>

        <v-btn rounded large dark color="primary" class="mt-5" @click="callInNext()" id="call-in-btn"> {{ $t('checkIn.call_in') }}</v-btn>
        
        <div class="mt-5">
            <pause-dialog></pause-dialog>
        </div>

    </div>
</template>

<script>
    import PauseDialog from "../Dialogs/PauseDialog";
    import {mapActions, mapGetters} from 'vuex';
    import Loading from "../../../../components/Loading";
    import { put } from '@/services/api'
    import moment from 'moment';
    import {config} from '@/config';

    export default {
        name: "CallInNextInLine",

        components: {PauseDialog, Loading},

        data() {
            return {

                selected: [""],

                modelToShow: {
                    ticketNumber: null,
                    time: null,
                    studyProblems: null,
                    studyProblemsFull: [],
                    studentXlogin: null,
                    imagesId: null,
                },
            }
        },

        mounted() {
            this.setModelToShow();

            // from checkInTable
            this.$root.$on('callInNextInLine', () => {
                this.setModelToShow();
            });

            this.$root.$on('callInNextInLineReload', () => {
                this.setModelToShow();
            });
        },

        computed: {
            ...mapGetters('queueItems', ['didLoadedQueueItems', 'getQueueItem']),
            ...mapGetters('queueItemStatuses', ['didLoadedQueueItemStatuses', 'getQueueItemStatus']),
            ...mapGetters('studyProblemsItems', ['didLoadedStudyProblemsItems', 'getStudyProblemsItem']),
            ...mapGetters('pageStorage', ['getCallInItemIdToShow']),
            ...mapGetters('studyFields', ['getStudyField']),

            didLoadedAll() {
                return this.didLoadedStudyProblemsItems && this.didLoadedQueueItemStatuses && this.didLoadedQueueItems;
            },

          imageSrc() {
              return `${config.serverUrl}study_department/images/${this.modelToShow.imagesId}`;
          }
        },

        methods: {
            ...mapActions('queueItemStatuses', ['setQueueItemStatusesUpdate']),

            callInNext() {
                this.setQueueStatusActive();
            },

            onScroll(e) {
                this.offsetTop = e.target.scrollTop
            },

            setQueueStatusActive() {
                let queueItem = this.getQueueItem(this.getCallInItemIdToShow);
                let queueItemStatus = this.getQueueItemStatus(queueItem.queueItemStatusId);

                put(`queue/demand/status/${queueItemStatus.id}`, {
                    "actualMeetEnd": queueItemStatus.actualMeetEnd,
                    "actualMeetStart": queueItemStatus.actualMeetTime,
                    "duration": queueItemStatus.duration,
                    "queueItemId": queueItem.id,
                    "status": "active",
                    "ticketNumber": queueItemStatus.ticketNumber,
                    "wantedDay": queueItemStatus.wantedDay,
                    "userUUID": queueItemStatus.userUUID,
                    "queueItemUUID": queueItemStatus.queueItemUUID,
                    "priority": false,
                }).catch(e => alert(e.message));
            },

            setModelToShow() {
                let queueItem = this.getQueueItem(this.getCallInItemIdToShow);
                let queueItemStatus = this.getQueueItemStatus(queueItem.queueItemStatusId);
                let studyProblems = "";

                this.modelToShow.studyProblemsFull = [];

                if (queueItem.studyProblemsItemsIds.length !== 0) {
                    for (let problem of queueItem.studyProblemsItemsIds) {
                        studyProblems += this.getStudyProblemsItem(problem).name + ' ';
                        this.modelToShow.studyProblemsFull.push(this.getStudyProblemsItem(problem).name)
                    }
                } else {
                    studyProblems = this.getStudyField(queueItem.studyFieldId).name;
                    this.modelToShow.studyProblemsFull.push(this.getStudyField(queueItem.studyFieldId).name)
                }

                if (studyProblems.length > 90) {
                    studyProblems = studyProblems.slice(0, 87) + "..."
                }

                this.modelToShow.ticketNumber = queueItemStatus.ticketNumber;
                this.modelToShow.time = moment(queueItemStatus.actualMeetTime, "HH:mm:ss").format("HH:mm");
                this.modelToShow.studyProblems = studyProblems;
                this.modelToShow.imagesId = queueItem.imagesId;

              if (queueItem.xname != null && queueItem.xname != 'Empty' && queueItem.xname != undefined && queueItem.xname != "") {
                this.modelToShow.studentXlogin = queueItem.xname;
              } else {
                this.modelToShow.studentXlogin = this.$t('checkIn.anon_student');
              }
            }
        }
    }
</script>

<style scoped>
</style>