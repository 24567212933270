<template>
  <v-select
    :autofocus="autofocus"
    v-bind:id="id || 'announcement-type'"
    :rules="rules"
    :items="announcementTypes"
    item-value="id"
    item-text="name"
    :label="label || 'Typ oznámení'"
    :value="value"
    :clearable="clearable"
    @change="onChange"
  ></v-select>
</template>

<script>
  export default {
    /**
     * Announcement types select component.
     */
    name: 'AnnouncementTypeSelect',

    props: {
      /**
       * ID passed to the input.
       */
      id: String,
      /**
       * Array of announcement types objects.
       */
      announcementTypes: Array,
      /**
       * Identifies if the select is clearable.
       */
      clearable: Boolean,
      /**
       * Input label.
       */
      label: String,
      /**
       * Input validation rules.
       */
      rules: Array,
      /**
       * Input value.
       */
      value: Number,
      /**
       * Input autofocus.
       */
      autofocus: Boolean
    },

    methods: {
      onChange(value) {
        this.$emit('change', value);
      }
    }
  }
</script>

<style scoped>
</style>