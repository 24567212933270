<template>
    <Loading v-if="!didLoadData"/>
    <v-container v-else-if="!hasFacultyAccess || !singleDetail">
        <v-row>
            <v-col>
                <BackButton :target="{name: 'Newsfeed'}">{{ $t('news.news_list') }}</BackButton>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <PlaceHolder
                    :path="'/icons/something-went-wrong.png'"
                    :text="$t('news.something_went_wrong_text')"
                    :title="$t('news.something_went_wrong_title')">
                </PlaceHolder>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else>
		<v-dialog
			v-model="showNotificationDialog"
			width="500"
			@keydown.esc="() => showNotificationDialog = false"
			@click:outside="() => showNotificationDialog = false"
		>
			<v-card>
				<v-row justify="end">
					<v-col @click="() => showNotificationDialog = false" class="pt-6 pr-6" cols="auto">
						<v-icon style="font-size: 1.9em; cursor: pointer;">highlight_off</v-icon>
					</v-col>
				</v-row>
				<v-card-title class="pt-0">
					<span class="text-h5">{{ $t('news.notification_dialog_title') }}</span>
				</v-card-title>

				<v-card-text id="dialog-text">
					{{ $t('news.notification_dialog_description') }}
					<br/>
					<img src="@/assets/warning-notification.jpeg" style="width: 100%; margin-top: 1rem;" />
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						data-cy="dialog-yes-btn"
						color="grey"
						text
						@click="onConfirmSave(true)"
					>
						{{ $t('entities.alert') }}
					</v-btn>
          <v-btn
            color="green"
            text
            @click="onConfirmSave(false)"
          >
            {{ $t('entities.not_alert') }}
          </v-btn>

				</v-card-actions>
			</v-card>
		</v-dialog>
        <Alert
            :model="getNewsfeedDetailError"
            :onConfirm="() => setErr(false)">
            {{ $t('news.something_went_wrong') }}
        </Alert>
        <v-row>
            <v-col class="py-0">
                <BackButton :target="{name: 'Newsfeed'}">{{ $t('news.news_list') }}</BackButton>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="8">
                <v-container fluid>
                    <v-row align="center">
                        <div class="text-h4 font-weight-medium mr-4" data-cy="newsfeed-detail-heading">
                            {{ isNew ? $t('news.new_post') : title }}
                        </div>

                        <v-chip v-if="singleDetail.isArchived" color="#F1D1BB" text-color="#D3661A">
                            <v-icon class="mr-3 ml-1" size="18">inventory_2</v-icon>
                            <span class="mr-4">{{ $t('news.archived') }}</span>
                        </v-chip>
                        <v-chip v-else-if="!singleDetail.isConcept" color="#e9f2dc" text-color="#79bf12">
                            <v-icon class="mr-3 ml-1" size="18">fa-check-circle</v-icon>
                            <span class="mr-4">{{ $t('news.published') }}</span>
                        </v-chip>
                        <v-chip v-else color="#eeeeef" text-color="#6c7278">
                            <v-icon class="mr-3 ml-1" size="18">edit</v-icon>
                            <span class="mr-4">{{ $t('news.concept') }}</span>
                        </v-chip>
                    </v-row>
                </v-container>
            </v-col>
            <v-col align-self="center" class="d-flex justify-md-end justify-start" cols="12" md="4"
                   v-if="this.singleDetail.isConcept">
                <v-btn
                    v-if="!savedConcept"
                    :disabled="isFormInvalid"
                    color="light-green darken-1"
                    outlined
                    rounded
                    style="border-width: 2px"
                    @click="savePost('concept')"
                    data-cy="save-concept"
                >
                    {{ $t('news.save_concept') }}
                </v-btn>
                <v-btn v-else outlined>
                    <v-icon left>check</v-icon>
                    {{ $t('news.saved') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="7">
                <v-tabs
                    v-model="tab"
                    color="light-green"
                    icons-and-text
                >
                    <v-tab to="#Text">
                        {{ $t('news.textual') }}
                        <v-icon>question_answer</v-icon>
                    </v-tab>
                    <v-tab to="#Poster">
                        {{ $t('news.poster') }}
                        <v-icon>image</v-icon>
                    </v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <v-alert v-if="isFormInvalid" text outlined shaped type="warning"
                         class="mt-4 mb-0">
                    <span>{{ $t('news.post_hint') }}</span>
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col>
                                <h2 class="font-weight-medium">{{ $t('news.post') }}</h2>
                            </v-col>
                        </v-row>
                        <v-container fluid>
                            <v-row>
                                <v-col class="px-0" cols="12">
                                    <v-simple-table fixed-header>
                                        <thead>
                                        <tr>
                                            <th>
                                                {{ $t('entities.czech')}}
                                            </th>
                                            <th>
                                                {{ $t('entities.english')}}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <v-textarea
                                                    data-cy="title-cz"
                                                    :label="$t('news.news_title')"
                                                    :rules="[required_rule]"
                                                    v-model.trim="singleDetail.localizedAttributes.cz.title"
                                                    :rows="1"
                                                    :maxlength="100"
                                                    counter
                                                    auto-grow
                                                > </v-textarea>
                                            </td>
                                            <td>
                                                <v-textarea
                                                    data-cy="title-en"
                                                    label="News title"
                                                    :rules="[required_rule]"
                                                    v-model.trim="singleDetail.localizedAttributes.en.title"
                                                    :rows="1"
                                                    :maxlength="100"
                                                    counter
                                                    auto-grow
                                                > </v-textarea>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <v-row v-if="this.isTextual">
                                <v-col cols="12" class="pa-0">
                                    <MarkDownEditor v-if="markdownTextLoaded" :text-cz="markdownedTextCz" :text-en="markdownedTextEn" @update:cz="changeCzText" @update:en="changeEnText" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pa-0 mt-2">
                                    <h2 class="font-weight-medium">{{ $t('news.additional_info') }}</h2>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pa-0 mt-2">
                                    <v-alert outlined shaped type="warning"
                                             class="mt-4 mb-0">
                                        <span>{{ $t('news.detail_hint') }}</span>
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row justify="space-between">
                                <v-col cols="12" md="6" class="pl-0">
                                    <v-combobox
                                        data-cy="place"
                                        v-model="place"
                                        :items="getNewsfeedPlaces"
                                        :label="$t('news.place')"
                                        color="light-green darken-1"
                                        prepend-icon="location_on"
                                        clearable
                                        return-object
                                    ></v-combobox>
                                </v-col>
                                <v-col v-if="isCustomPlace" cols="12" md="6" class="pr-0">
                                    <v-text-field
                                        v-model="placeEN"
                                        :label="$t('news.placeEN')"
                                        color="light-green darken-1"
                                        prepend-icon="location_on"
                                        clearable
                                        :rules="[required_rule]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="align-center">
                                <v-col cols="12" md="6" class="pl-0">
                                    <DatePicker
                                        data-cy="event-date"
                                        :initial-value="this.singleDetail.eventDate"
                                        :clearable="true"
                                        :minDate="currentDate"
                                        :title="$t('news.date')"
                                        v-on:dateChanged="(value) => updateDate(value)"
                                    />
                                </v-col>
                                <v-col cols="12" md="5" class="pr-0">
                                    <TimePicker
                                        :title="$t('news.time')"
                                        v-on:timeChanged="(value) => singleDetail.eventTime = value"
                                        :initialValue="singleDetail.eventTime"
                                        :disabled="!this.singleDetail.eventDate"
                                        :clearable="true"
                                        :prepend-icon="true"
                                    >
                                    </TimePicker>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>

                    <v-col v-if="postUploading" cols="12">
                        <v-container fluid>
                            <v-row class="align-center">
                                <v-col cols="auto">
                                    <v-dialog class="rounded-lg" v-model="postUploading" max-width="500px" persistent>
                                        <v-card class="rounded-lg pa-6">
                                            <v-row justify="space-between" class="align-center">
                                                <v-col cols="auto" class="pa-0">
                                                    <v-card-title class="pl-1 pt-0">
                                                        <span>{{ $t('news.uploading') }}</span>
                                                    </v-card-title>
                                                </v-col>
                                                <v-col @click="closeUploadDialog" cols="auto" class="pt-0 pr-1">
                                                    <v-icon style="font-size: 2em; cursor: pointer;">close</v-icon>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="$refs.dropzoneTitleImg && $refs.dropzoneTitleImg.getUploadingFiles().length > 0" justify="start" class="align-center">
                                                <v-col cols="auto" class="py-0">
                                                    <b>
                                                        {{ $t('news.uploading_title') }}
                                                    </b>
                                                </v-col>
                                                <v-col cols="auto" class="py-0">
                                                    <v-progress-circular
                                                        :rotate="-90"
                                                        color="#78BE13"
                                                        size="55"
                                                        v-model="uploadingTitleImagePercentage"
                                                    >
                                                        <template v-slot:default> <b style="font-size: 0.75em;">{{ uploadingTitleImagePercentage.toFixed(0) }}&thinsp;%</b></template>
                                                    </v-progress-circular>
                                                </v-col>
                                            </v-row>
											<v-row v-else justify="center" class="align-center">
												<v-col cols="auto" class="py-0">
													<v-progress-circular indeterminate></v-progress-circular>
												</v-col>
											</v-row>
                                            <v-row v-if="areAdditionalFilesSuccessfullyUploaded && areAdditionalFilesSuccessfullyUploaded.length !== 0" class="align-center" justify="start">
                                                <v-col cols="auto" class="py-0">
                                                    <b>
                                                        {{ $t('news.uploading_additional') }}
                                                    </b>
                                                </v-col>
                                                <v-col cols="auto" class="py-0">
                                                    <v-progress-circular
                                                        :rotate="-90"
                                                        color="#78BE13"
                                                        size="55"
                                                        v-model="uploadingAdditionalImagesPercentage"
                                                    >
                                                        <template v-slot:default> <b style="font-size: 0.75em;">{{ uploadingAdditionalImagesPercentage.toFixed(0) }}&thinsp;%</b></template>
                                                    </v-progress-circular>
                                                </v-col>
                                            </v-row>
											<v-row>
												<v-col cols="auto" class="pb-0">
													<v-alert
														type="info"
														transition="fade-transition"
														color="#555"
														outlined
														class="rounded-lg pt-2 pb-1 px-3"
													>
														{{ $t('news.info_images_upload') }}
													</v-alert>
												</v-col>
											</v-row>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                                <v-col class="px-1" v-if="showSuccessTitleUpload" cols="auto">
                                    <v-chip outlined color="#e9f2dc" text-color="#79bf12">
                                        <v-icon class="mr-3 ml-1" size="18">fa-check-circle</v-icon>
                                        <span class="mr-4">{{ $t('news.uploaded')}}</span>
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>

        <v-row v-if="this.isTextual">
            <v-col cols="auto">
                <h2 class="font-weight-medium">{{ $t('news.images_title') }}</h2>
            </v-col>
			<v-col class="px-1" v-if="showSuccessAdditionalUpload" cols="auto">
				<v-chip outlined color="#e9f2dc" text-color="#79bf12">
					<v-icon class="mr-3 ml-1" size="18">fa-check-circle</v-icon>
					<span class="mr-4">{{ $t('news.uploaded')}}</span>
				</v-chip>
			</v-col>
        </v-row>
        <v-row v-if="this.isTextual">
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="auto" class="pr-1">
                        <h3 v-if="!isTextual" class="font-weight-medium">{{ $t('news.poster') }}</h3>
                        <h3 v-else class="font-weight-medium"> {{ $t('news.title_image') }}</h3>
                    </v-col>
                </v-row>
                <v-row v-if="titleImage">
                    <v-col>
                        <v-img cover
                               :src="getImage(titleImage)"
                               contain
                               transition="fade-transition"
                               class="photos-component"
                               data-cy="title-img"
                        >
                            <template v-slot:placeholder>
                                <div class="d-flex align-center justify-center fill-height">
                                    <v-progress-circular
                                        color="#79bf12"
                                        indeterminate
                                    ></v-progress-circular>
                                </div>
                            </template>
                            <v-btn v-if="!isNew" class="delete-image" fab dark small color="red"
                                   @click="attemptToDeleteActiveImage(titleImage)" data-cy="title-img-trash">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </v-img>
                    </v-col>
                </v-row>
                <v-row v-if="isNew || !titleImage">
                    <v-col cols="12">
                        <vue-dropzone
                            data-cy="dropzone-title"
                            ref="dropzoneTitleImg"
                            id="dropzoneTitleImg"
                            class="dropzone"
                            :options="dropzoneTitleOptions"
                            @vdropzone-file-added="fileAdded('dropzoneTitleImg')"
                            @vdropzone-sending="(file, xhr, formData) => addImgParam(file, xhr, formData, true)"
                            @vdropzone-success="onFileSucessfullyUploaded"
                            @vdropzone-complete="finishSaving"
                            @vdropzone-error="fileUploadError"
                        ></vue-dropzone>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12">
                        <h3 class="font-weight-medium">{{ $t('news.additional_images') }}</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-if="!isNew && additionalImages.length !== 0">
                        <v-container class="photos-component" >
                            <v-carousel
                                height="300"
                                v-model="carouselActiveImage"
                                :show-arrows="additionalImages.length !== 1"
                                :data-cy="`additional-img-${additionalImages.length}`"
                            >
                                <v-btn v-if="!isNew" fab dark small color="red" @click="attemptToDeleteActiveImage()"
                                       class="delete-image" data-cy="additional-img-trash">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                                <v-carousel-item
                                    v-for="(obj ,i) in additionalImages"
                                    :key="i"
                                    :src="getImage(obj)"
                                    reverse-transition="fade-transition"
                                    transition="fade-transition"
                                    cover
                                >
                                </v-carousel-item>
                            </v-carousel>
                        </v-container>
                    </v-col>
                    <v-col cols="12">
                        <vue-dropzone
                            ref="dropzoneAdditionalImg"
                            id="dropzoneAdditionalImg"
                            class="dropzone"
                            :options="dropzoneAdditionalOptions"
                            @vdropzone-file-added="fileAdded('dropzoneAdditionalImg')"
                            @vdropzone-sending="(file, xhr, formData) => addImgParam(file, xhr, formData, false)"
                            @vdropzone-success="onFileSucessfullyUploaded"
                            @vdropzone-complete="finishSaving"
                            @vdropzone-error="fileUploadError"
                        ></vue-dropzone>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-alert
                    outlined type="info"
                    color="#555"
                    max-width="600px"
                    class="mt-4 mb-0 rounded-lg"
                >{{$t('news.info_images')}}</v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h2 class="font-weight-medium">{{ $t('news.further_information') }}</h2>
            </v-col>
        </v-row>

        <v-row justify="end">
            <v-col align-self="center" cols="12" md="8">
                <h3 class="font-weight-medium">{{ $t('news.links') }}</h3>
            </v-col>
            <v-col align-self="center" class="d-flex justify-md-end justify-start" cols="12" md="4">
                <v-btn color="primary" dark @click="openDialog('link')" data-cy="btn-add-link">
                    {{ $t('news.add_link') }}
                </v-btn>
                <v-dialog v-model="showDialogLink"
                          max-width="600px"
                          persistent
                >
                    <NewsfeedDialog
                        :status="isNewCard"
                        :textDetail="itemCard"
                        type='link'
                        :label="$t('news.name_link')"
                        :onClose="() => closeDialog('link')"
                        :onSave="(text,index,status) => saveCard(text,index,status,'link')"
                        :titleNew="$t('news.add_link')"
                        :titleEdit="$t('news.edit_link')"
                    />
                </v-dialog>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <NewsfeedCards
                    data-cy="card-links"
                    type='odkaz'
                    :input=this.singleDetail.links
                    @deleteCard="removeItem"
                    @editCard="(text, index) => prepareEditCard(text, index, 'link')"
                />
            </v-col>
        </v-row>

        <v-row justify="end">
            <v-col align-self="center" cols="12" md="8">
                <h3 class="font-weight-medium">{{ $t('news.contact_data') }}</h3>
            </v-col>
            <v-col align-self="center" class="d-flex justify-md-end justify-start" cols="12" md="4">
                <v-btn color="primary" dark @click="openDialog('contact')" data-cy="btn-add-contact">
                    {{ $t('news.add_contact') }}
                </v-btn>
                <v-dialog v-model="showDialogContact"
                          max-width="600px"
                          persistent
                >
                    <NewsfeedDialog
                        :status="isNewCard"
                        :textDetail="itemCard"
                        type='contact'
                        :label="$t('news.name_contact')"
                        :onClose="() => closeDialog('contact')"
                        :onSave="(text,index,status) => saveCard(text,index,status,'contact')"
                        :titleNew="$t('news.add_contact')"
                        :titleEdit="$t('news.edit_contact')"
                    />
                </v-dialog>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <NewsfeedCards
                    data-cy="card-contacts"
                    type='kontakt'
                    :input=this.singleDetail.contacts
                    @deleteCard="removeItem"
                    @editCard="(text, index) => prepareEditCard(text, index, 'contact')"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h2 class="font-weight-medium">{{ $t('news.post_targeting') }}</h2>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h3 class="font-weight-medium">{{ $t('news.post_topic') }}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <NewsCards
                    data-cy="card-category"
                    :input="getCategoriesNew"
                    data="category"
                    @clicked="onClickChild"
                    :selectedItems="selectedCategories"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h3 class="font-weight-medium">{{ $t('news.target_groups') }}</h3>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <NewsCards
                    data-cy="card-audience"
                    :input="getAudiencesByUser"
                    data="audience"
                    @clicked="onClickChild"
                    :selectedItems="selectedAudiences"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h3 class="font-weight-medium">{{ $t('news.target_parts') }}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <NewsCards
                    data-cy="card-faculty"
                    :input="getFacultiesByUser"
                    data="faculty"
                    @clicked="onClickChild"
                    :selectedItems="selectedFaculties"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container class="footerBackground pa-8">
                    <v-row>
                        <v-col>
                            <h2 class="font-weight-medium">{{ $t('news.publication') }}</h2>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <h4 class="font-weight-medium footerText">
                                {{ $t('news.time_range_publication') }}
                            </h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4" class="px-3 py-0">
                            <DatePicker
                                data-cy="date-from"
                                :initial-value="this.singleDetail.startDate ? this.singleDetail.startDate : null"
                                :clearable="true"
                                :minDate="currentDate"
                                :title="$t('news.date_from')"
                                v-on:dateChanged="(value) => {this.singleDetail.startDate = value ? value.format('YYYY-MM-DD') : undefined;}"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4" class="px-3 py-0">
                            <DatePicker
                                data-cy="date-to"
                                :initial-value="this.singleDetail.endDate ? this.singleDetail.endDate : null"
                                :clearable="true"
                                :minDate="this.singleDetail.startDate ? this.singleDetail.startDate : currentDate"
                                :title="$t('news.date_to')"
                                v-on:dateChanged="(value) => {this.singleDetail.endDate = value ? value.format('YYYY-MM-DD') : undefined;}"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn
                                style=" background-color: #78BE13; color: white"
                                :disabled="isFormInvalid"
                                large
                                @click="() => askAboutNotification()"
                                data-cy="publish"
                            >
                                <span v-if="singleDetail.isConcept || singleDetail.isArchived">
                                    {{ $t('news.publish_post') }}
                                </span>
                                <span v-else> {{ $t('news.update_post') }}</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                             <span v-if="this.singleDetail.isArchived" class="footerTextArchived">
                                 {{ $t('news.post_is_archived') }}
                            </span>
                            <span v-else-if="this.singleDetail.isConcept" class="footerText">
                                {{ $t('news.post_is_not_published') }}
                            </span>
                            <span v-else class="footerTextSuccess">
                                {{ $t('news.post_is_published') }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <span v-if="!savedArchived && !singleDetail.isArchived"
                                  data-cy="archive"
                                  :class="setButtonClass"
                                  @click="() => { if (!isFormInvalid) savePost('archive')}">
                                     {{ $t('news.archive_post') }}
                            </span>
                            <v-btn v-else-if="savedArchived" outlined>
                                <v-icon left>check</v-icon>
                                {{ $t('news.archived_post') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BackButton from "@/components/layout/BackButton";
import Loading from "@/components/Loading";
import {getLocalizedLanguages} from "@/localization/languages";
import moment from "moment";
import DatePicker from "@/components/date-picker/DatePicker";
import NewsfeedCards from "@/components/news/NewsfeedCards";
import {mapActions, mapGetters} from "vuex";
import NewsCards from "@/components/news/NewsCards";
import NewsfeedDialog from "@/components/news/NewsfeedDialog";
import Alert from "@/components/dialogs/Alert";
import PlaceHolder from "@/components/news/PlaceHolder";
import Vue from "vue";
import {config} from "@/config";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import MarkDownEditor from "@/components/MarkDownEditor.vue";
import TimePicker from "../../components/time-picker/TimePicker";
import MarkdownIt from "markdown-it";


export default {
    name: "NewsfeedDetail",

    components: {
        DatePicker,
        BackButton,
        NewsfeedCards,
        Loading,
        NewsCards,
        NewsfeedDialog,
        Alert,
        PlaceHolder,
        vueDropzone: vue2Dropzone,
        MarkDownEditor,
        TimePicker
    },

    async beforeMount() {
        this.isNew ? this.preparePost() : await this.fetchNewsById(this.newsId);
        this.loadPostStatus();
    },

    async created() {
        await Promise.all([
            await this.fetchAllFacultiesByUser(),
            await this.fetchAllCategories(),
            await this.fetchAllAudiencesByUser(),
            await this.fetchNewsfeedImages(this.$route.params.idNews)
        ]);
        this.controlFacultyAccess();
        this.fetchData();
        window.scrollTo(0, 0);

        // assign the localized text to the markdownedText
        this.markdownedTextCz = this.singleDetail.localizedAttributes.cz.text ? this.singleDetail.localizedAttributes.cz.text : ""
        this.markdownedTextEn = this.singleDetail.localizedAttributes.en.text ? this.singleDetail.localizedAttributes.en.text : ""
        this.markdownTextLoaded = true
    },

    data() {
        const allowedFileTypes = ".jpg,.jpeg,.png";
        const dropzoneDefaultOptions = {
            url: `${config.serverUrl}newsfeed/news/images/0`,
            createImageThumbnails: true,
            addRemoveLinks: true,
            acceptedFiles: allowedFileTypes,
            autoProcessQueue: false,
            filesizeBase: 1000, // use 1MB=1000KB as the metric for maxFilesize
            maxFilesize: 15,    // MB, must be lower than limit in nginx proxy and lower than limit on backend!
            paramName: "files",
            headers: {"Authorization": "Bearer " + Vue.$auth.getToken()},
            dictFileTooBig: "Příliš velký soubor ({{filesize}} MB). Max. {{maxFilesize}} MB.",
            dictInvalidFileType: "Obrázek musí být ve formátu " + allowedFileTypes,
            dictResponseError: "Chyba serveru {{statusCode}}",
            timeout: 500000,
            maxThumbnailFilesize: 9999, // always generate thumbnails, otherwise we are not able to check minimal dimensions
            thumbnailWidth: 200,
            thumbnailHeight: 200,
        };
        return {
            showNotificationDialog: false,
            tab: () => !!this.singleDetail.isPoster,
            currentLanguage: "cz",
            showDialogLink: false,
            showDialogContact: false,
            published: false,
            required_rule: value => !!value || 'Povinné',
            savedConcept: false,
            savedArchived: false,
            errorOnCreate: false,
            hasFacultyAccess: true,
            facultyIds: [],
            audienceIds: [],
            categoryIds: [],
            uploadingTitleImagePercentage: 0,
            uploadingAdditionalImagesPercentage: 0,
            place: null,
            placeEN: null,
            itemCard: {},
            isNewCard: null,
            successfulImageUpload: false,
            title: null,
            showSuccessTitleUpload: false,
            postUploading: false,
            uploader: null,
            isTitleFileSuccessfullyUploaded: null,
            showSuccessAdditionalUpload: false,
            areAdditionalFilesSuccessfullyUploaded: null,
            carouselActiveImage: 0,
            dropzoneAdditionalOptions: {
                ...dropzoneDefaultOptions,
                dictDefaultMessage: "<i class='fa fa-image' style='font-size: 36px; opacity: 0.6;'></i><br>Klikněte nebo přetáhněte fotky pro nahrání",
                maxFiles: 10,
            },
            dropzoneTitleOptions: {
                ...dropzoneDefaultOptions,
                maxFiles: 1,
                dictDefaultMessage: "<i class='fa fa-image' style='font-size: 36px; opacity: 0.6;'></i><br>Klikněte nebo přetáhněte fotku pro nahrání",
            },
            markdownedTextCz: '',
            markdownedTextEn: '',
            markdownTextLoaded: false,
        }
    },

    computed: {
        ...mapGetters('Newsfeed', ['getNewsfeedDetailError', 'getCategoriesNew', 'getAudiencesByUser', 'getFacultiesByUser', 'getEmptyPost', 'didLoadCategories', 'didLoadFaculties', 'didLoadAudiences', 'getNewsById', 'getNewsfeedImages']),
        ...mapGetters('places', ['getPlaces']),

        didLoadData() {
            return this.didLoadFaculties && this.didLoadCategories && this.didLoadAudiences
        },

        singleDetail() {
            return !this.isNew ? this.getNewsById(this.newsId) : this.getEmptyPost;
        },

        newsId() {
            return parseInt(this.$route.params.idNews);
        },

        facultyId() {
            return parseInt(this.$route.params.id);
        },

        isCustomPlace() {
            this.setPlace()
            return this.place && !this.singleDetail.placeId;
        },

        languages() {
            const langs = getLocalizedLanguages();
            return Object.keys(langs).map((lng) => ({
                    'text': langs[lng],
                    'value': lng
                }),
            );
        },

        currentDate() {
            return moment(Date.now()).format('YYYY-MM-DD');
        },

        isFormInvalid() {
            this.setPlace()
            let rules = this.facultyIds.length === 0
                || this.audienceIds.length === 0
                || this.categoryIds.length === 0
                || !this.singleDetail.localizedAttributes.cz.title
                || !this.singleDetail.localizedAttributes.en.title

            if (this.singleDetail.placeId === null && this.place) {
                rules = this.facultyIds.length === 0
                    || this.audienceIds.length === 0
                    || this.categoryIds.length === 0
                    || !this.singleDetail.localizedAttributes.cz.title
                    || !this.singleDetail.localizedAttributes.en.title
                    || !this.placeEN
            }

            return this.tab === 'Poster' ? rules :
                rules
                || !this.singleDetail.localizedAttributes.cz.text
                || !this.singleDetail.localizedAttributes.en.text
        },

        isTextual() {
            return this.tab !== 'Poster'
        },

        selectedFaculties() {
            return !this.isNew ? this.singleDetail.faculty : [{id: this.facultyId}]
        },

        selectedAudiences() {
            return !this.isNew ? this.singleDetail.audience : []
        },

        selectedCategories() {
            return !this.isNew ? this.singleDetail.category : []
        },

        isNew() {
            return this.$route.params.idNews === 'new';
        },

        getNewsfeedPlaces() {
            return this.getPlaces.map(x => ({
                text: x.localizedAttributes.cz.name,
                value: x.id
            }));
        },

        setButtonClass() {
            return !this.isFormInvalid ? 'archiveActive' : 'archiveInactive';
        },

        additionalImages() {
            return this.getNewsfeedImages.filter(x => !x.isTitle)
        },

        titleImage() {
            return this.getNewsfeedImages.find(x => x.isTitle)
        },

    },

    methods: {
        ...mapActions('Newsfeed', ['createPost', 'setErr', 'fetchAllAudiencesByUser', 'fetchAllFacultiesByUser', 'fetchAllCategories', 'preparePost', 'fetchNewsById', 'updatePost', 'fetchNewsfeedImages', 'deleteImage', 'addTitleImage', 'addAdditionalImage']),
        ...mapActions('places', ['fetchFilteredPlaces', 'fetchPlacesByPageAndItemsPerPage']),

        onClickChild(obj) {
            if (obj.type === "faculty") {
                this.facultyIds = obj.value
            }
            if (obj.type === "audience") {
                this.audienceIds = obj.value
            }
            if (obj.type === "category") {
                this.categoryIds = obj.value
            }
        },

        closeUploadDialog() {
			if (window.confirm(`${this.$t('news.confirm_cancel_upload')}`)) {
				this.postUploading = false;
				if (this.$refs.dropzoneTitleImg) {
					this.$refs.dropzoneTitleImg.removeAllFiles(true);
				}

				if (this.$refs.dropzoneAdditionalImg) {
					this.$refs.dropzoneAdditionalImg.removeAllFiles(true);
				}
			}
        },

        changeCzText(text) {
            this.markdownedTextCz = text
            this.singleDetail.localizedAttributes.cz.text = text
        },

        changeEnText(text) {
            this.markdownedTextEn = text
            this.singleDetail.localizedAttributes.en.text = text
        },

        removeItem(text, type, index) {
            if (window.confirm(`${this.$t('news.delete')} ${type} ${text} ?`)) {
                if (type === "odkaz") {
                    this.singleDetail.links = this.singleDetail.links.filter((element, i) => i !== index);
                } else {
                    this.singleDetail.contacts = this.singleDetail.contacts.filter((element, i) => i !== index);
                }
            }
        },

        openDialog(cardType) {
            if (cardType === 'contact') this.showDialogContact = true;
            if (cardType === 'link') this.showDialogLink = true;
            this.isNewCard = true;
        },

        closeDialog(cardType) {
            if (cardType === 'contact') {
                this.showDialogContact = false;
            }
            if (cardType === 'link') {
                this.showDialogLink = false;
            }
            this.itemCard = {};
        },

        prepareEditCard(text, index, cardType) {
            if (cardType === 'contact') {
                this.itemCard = {text: text, index};
                this.showDialogContact = true;
            }
            if (cardType === 'link') {
                this.itemCard = {text: text.replace(/^https?:\/\//i, ''), index};
                this.showDialogLink = true;
            }
            this.isNewCard = false;
        },

        saveCard(text, index, isNew, cardType){
            if (cardType === 'contact') {
                isNew ? this.singleDetail.contacts.unshift(text) : Vue.set(this.singleDetail.contacts, index, text);
            }
            if (cardType === 'link') {
                isNew ? this.singleDetail.links.unshift(text) : Vue.set(this.singleDetail.links, index, text);
            }
        },

		askAboutNotification() {
			if (!this.singleDetail.startDate && (!this.isNew && !this.singleDetail.isConcept)) {
				this.showNotificationDialog = true
			} else {
				this.savePost('publish', false)
			}
		},

        checkDateAndTime() {
            if (this.singleDetail.eventDate === null) {
                this.singleDetail.eventTime = null
            }
        },

		onConfirmSave(notify) {
			this.showNotificationDialog = false
			this.savePost('publish', notify)
		},

        async savePost(type, notify = false) {
            this.postUploading = true
            this.setValues(type)
            this.checkDateAndTime()

            if (!this.singleDetail.placeId && this.place && this.placeEN) {
                this.singleDetail.localizedAttributes.cz.customPlace = this.place
                this.singleDetail.localizedAttributes.en.customPlace = this.placeEN
            } else {
                this.singleDetail.localizedAttributes.cz.customPlace = null
                this.singleDetail.localizedAttributes.en.customPlace = null
            }

            if (this.isNew) {
                const postId = await this.createPost(this.singleDetail);
                await this.uploadNewsImage(postId)
            } else {
                await this.updatePost({id: this.newsId, request: this.singleDetail, notify: notify});
                await this.uploadNewsImage(this.newsId)
            }

			// check if dropzone for title image is not empty and has files in it
			if (await this.$refs.dropzoneTitleImg && await this.$refs.dropzoneTitleImg.getUploadingFiles().length > 0) {
				this.isTitleFileSuccessfullyUploaded = await this.$refs.dropzoneTitleImg.getUploadingFiles()
				this.uploadingTitleImagePercentage = this.isTitleFileSuccessfullyUploaded[0].upload.progress
			}

			// check if dropzone for additional images is not empty
			if (await this.$refs.dropzoneAdditionalImg) {
				this.areAdditionalFilesSuccessfullyUploaded = await this.$refs.dropzoneAdditionalImg.getUploadingFiles()

				this.areAdditionalFilesSuccessfullyUploaded.forEach(file => {
					if (file.upload.progress !== 100) {
						this.uploadingAdditionalImagesPercentage += file.upload.progress
					}
				})
			}

			// calculate the percentage of the uploading additional images
            if (this.areAdditionalFilesSuccessfullyUploaded && this.areAdditionalFilesSuccessfullyUploaded.length > 0) {
                this.uploadingAdditionalImagesPercentage = this.uploadingAdditionalImagesPercentage / this.areAdditionalFilesSuccessfullyUploaded.length
            }


			// set interval to check the progress of the uploading images
            let intervalID = setInterval(async () => {
				// check if the uploading is not finished (100%) or if there are any files in the dropzone
                if ((this.uploadingTitleImagePercentage !== 100 && this.isTitleFileSuccessfullyUploaded) || (this.uploadingAdditionalImagesPercentage !== 100 && this.areAdditionalFilesSuccessfullyUploaded && this.areAdditionalFilesSuccessfullyUploaded.length !== 0)) {
                    if (this.isTitleFileSuccessfullyUploaded && this.isTitleFileSuccessfullyUploaded.length > 0) {
                        this.uploadingTitleImagePercentage = this.isTitleFileSuccessfullyUploaded[0].upload.progress
						this.isTitleFileSuccessfullyUploaded = await this.$refs.dropzoneTitleImg.getUploadingFiles()

                    }

					// update the percentage of the uploading additional images
                    if (this.$refs.dropzoneAdditionalImg) {
                        this.areAdditionalFilesSuccessfullyUploaded = await this.$refs.dropzoneAdditionalImg.getUploadingFiles()

                        this.uploadingAdditionalImagesPercentage = 0
                        this.areAdditionalFilesSuccessfullyUploaded.forEach(file => {
                            if (file.upload.progress !== 100) {
                              this.uploadingAdditionalImagesPercentage += file.upload.progress
                            }
                        })
                    }

					// update the percentage of the uploading additional images
                    if (this.areAdditionalFilesSuccessfullyUploaded && this.areAdditionalFilesSuccessfullyUploaded.length > 0) {
                        this.uploadingAdditionalImagesPercentage = this.uploadingAdditionalImagesPercentage / this.areAdditionalFilesSuccessfullyUploaded.length
                    } else {
                        this.uploadingAdditionalImagesPercentage = 100
                    }

                } else {
                    this.postUploading = false;
					clearInterval(intervalID)
					// Check if the current route is already redirected before navigating
					if (this.$route.path.includes("/newsfeed/")) {
						await this.$router.push({ name: 'Newsfeed' })
						window.scrollTo(0, 0);
					}
                }
            }, 1000)

        },

        setValues(type) {
            if (type === 'publish') {
                this.singleDetail.isConcept = false;
                this.singleDetail.isArchived = false;
            }
            if (type === 'archive') {
                this.singleDetail.isArchived = true;
                this.singleDetail.isConcept = false;
                this.savedArchived = true;
            }
            if (type === 'concept') {
                this.savedConcept = true;
            }
            if (this.singleDetail.isPoster) {
                this.singleDetail.localizedAttributes.cz.text = null
                this.singleDetail.localizedAttributes.en.text = null
            }

            if (this.singleDetail.localizedAttributes.cz.text !== null){
              this.singleDetail.localizedAttributes.cz.unformattedText = this.markdownToPlainText(this.singleDetail.localizedAttributes.cz.text)
            }

            if (this.singleDetail.localizedAttributes.en.text !== null){
              this.singleDetail.localizedAttributes.en.unformattedText = this.markdownToPlainText(this.singleDetail.localizedAttributes.en.text)
            }

            this.singleDetail.facultyIds = this.facultyIds;
            this.singleDetail.audienceIds = this.audienceIds;
            this.singleDetail.categoryIds = this.categoryIds;
            this.setPlace();
        },

        setPlace() {
            this.singleDetail.placeId = (this.place  && typeof this.place === 'object') ? this.place.value : null;
        },

        controlFacultyAccess() {
            this.hasFacultyAccess = this.getFacultiesByUser.find(faculty => faculty.id === this.facultyId);
        },

        fetchData() {
            if (this.isNew) {
                this.facultyIds = [this.facultyId];
            } else if (this.singleDetail) {
                this.facultyIds = this.singleDetail.faculty.map(obj => obj.id);
                this.audienceIds = this.singleDetail.audience.map(obj => obj.id);
                this.categoryIds = this.singleDetail.category.map(obj => obj.id);
                this.title = this.singleDetail.localizedAttributes.cz.title
                this.loadPlace();
            }
        },

        loadPlace() {
            if (this.singleDetail.place) {
                this.place = {text: this.singleDetail.place.localizedAttributes.cz.name, value: this.singleDetail.place.id}
            } else {
                this.place = this.singleDetail.localizedAttributes.cz.customPlace
                this.placeEN = this.singleDetail.localizedAttributes.en.customPlace
            }
        },

        loadPostStatus() {
            if (this.singleDetail && !window.location.href.includes("#Text") && !this.singleDetail.isPoster) {
                this.$router.push("#Text")
            }
            if (this.singleDetail && !window.location.href.includes("#Poster") && this.singleDetail.isPoster) {
                this.$router.push("#Poster")
            }
        },

        async fetchPlaces() {
            await this.fetchPlacesByPageAndItemsPerPage({
                searchString: !this.place ? "" : this.place.text,
                language: "cz",
                buildingId: null,
                floorId: null,
                exterior: null,
                placeType: null,
                page: null,
                itemsPerPage: null,
            });
        },

        updateDate(value) {
            this.singleDetail.eventDate = value ? value.format('YYYY-MM-DD') : null;
            this.checkDateAndTime()
        },

        async fileAdded(origin) {
            this.uploader = origin;

			const uploadedTitleImg = (await this.$refs.dropzoneTitleImg && await this.$refs.dropzoneTitleImg.getAcceptedFiles().length > 0)
			const uploadedAdditionalImg = (await this.$refs.dropzoneAdditionalImg && await this.$refs.dropzoneAdditionalImg.getAcceptedFiles().length > 0)

            let elements = document.getElementsByClassName("dz-progress")
            if (elements) {
				elements.forEach(element => {
					element.style.display = "none"
				})
            }

            if (uploadedTitleImg) {
                this.showSuccessTitleUpload = true
            }

			if (uploadedAdditionalImg) {
				this.showSuccessAdditionalUpload = true
			}


            let elementsStyle = document.getElementsByClassName("dz-image")
			elementsStyle.forEach(elementStyle => {
				elementStyle.style.borderRadius = "10px";
			})

        },

        addImgParam(file, xhr, formData, isTitle) {
            formData.append('isTitle', isTitle);
        },

        onFileSucessfullyUploaded(file, response) {
            this.uploader === "dropzoneAdditionalImg" ? this.addAdditionalImage(response) : this.addTitleImage(response)
            if (this.$refs[this.uploader]) this.$refs[this.uploader].removeFile(file);
            this.uploader = null;
        },

        finishSaving() {
            this.hasRecentlyUpdated = true;
            setTimeout(() => this.hasRecentlyUpdated = false, 2000);
        },

        fileUploadError(file) {
            setTimeout(() => {
                if (this.$refs[this.uploader]) this.$refs[this.uploader].removeFile(file);
                this.uploader = null;
            }, 2000);
        },

        async uploadNewsImage(id) {
            if(this.$refs.dropzoneAdditionalImg && this.$refs.dropzoneAdditionalImg.getQueuedFiles().length !== 0){
                this.$refs.dropzoneAdditionalImg.setOption('url', `${config.serverUrl}newsfeed/news/images/${id}`);
                await this.$refs.dropzoneAdditionalImg.processQueue();
            }
            if(this.$refs.dropzoneTitleImg && this.$refs.dropzoneTitleImg.getQueuedFiles().length !== 0){
                this.$refs.dropzoneTitleImg.setOption('url', `${config.serverUrl}newsfeed/news/images/${id}`);
                await this.$refs.dropzoneTitleImg.processQueue();
            }
            this.successfulImageUpload = true

        },

        attemptToDeleteActiveImage(titleImage) {
            if (window.confirm(`${this.$t('news.confirm_deletion_img')}`)) {
                this.deleteImage({
                    newsId: this.newsId,
                    imgId: titleImage ? titleImage.id : this.additionalImages[this.carouselActiveImage].id
                });
            }
        },

        getImage(obj) {
            if(obj.id){
                return `${config.serverUrl}newsfeed/news/images/${this.newsId}/${obj.id}`
            }
        },
      markdownToPlainText(markdown) {
        const md = new MarkdownIt();
        const html = md.render(markdown);

        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;

        return tempElement.textContent || tempElement.innerText || '';
      }

    },

    watch: {
        tab() {
            this.singleDetail.isPoster = this.tab === 'Poster'
        },

        place() {
            this.fetchPlaces();
        },

    }

}
</script>

<style scoped>

.footerText {
    color: #6D7278;
}

.footerBackground {
    background-color: #F5F5F5;
}

.footerTextSuccess {
    color: #78BE13;
}

.footerTextArchived {
    color: #D3661A
}

.archiveActive {
    color: #E02020;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.archiveInactive {
    color: silver;
    font-size: 18px;
    font-weight: 500;
    cursor: default;
}

.dropzone {
    border: dotted;
    border-width: 4px;
    border-radius: 10px;
    background-color: rgb(245, 245, 245);
}

.photos-component {
    margin: 0;
    max-width: 35vw;
}

@media screen and (max-width: 600px) {
    .photos-component {
        margin: 0 auto;
        max-width: 100vw;
    }
}

@media screen and (min-width: 601px) {
    .photos-component {
        margin: 0 auto;
        max-width: 50vw;
    }
}

.delete-image {
    position: absolute;
    z-index: 9;
    margin-top: 15px;
    right: 15px;
}

</style>
