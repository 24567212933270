<template>
  <Loading v-if="didLoadedAll === false"/>
  <div v-else class="b-calendar">
    <v-row>
      <v-col
          lg="11"
          style="padding-top: 0 !important; padding-bottom: 0 !important;">
        <div class="b-calendar__calendar">
          <!--Start -- Top row of calendar -> month picker etc.-->
          <div class="mt-6 mb-3 d-flex align-center">
            <v-btn
                id="subtractMonthBtn"
                class="arrow arrow-left"
                icon
                small variant="light"
                @click="subtractMonth">
              <i class="fa fa-fw fa-chevron-left"/>
            </v-btn>

            <div class="mx-3 text-center">
              {{ month | firstUpper }} {{ year }}
            </div>

            <v-btn id="addMonthBtn"
                   icon
                   small
                   variant="light"
                   @click="addMonth">
              <i class="fa fa-fw fa-chevron-right"/>
            </v-btn>

            <v-spacer/>

            <v-btn
                v-show="!todayInCurrentMonthAndYear || !todayIsEqualSelectDate"
                id="goTodayBtn" class="go-today-btn"
                small
                @click="goToday">
              {{ $t('reservations.today') }}
            </v-btn>
          </div>
          <!--End -- Top row of calendar -> month picker etc.-->

          <!--Start -- Second Top row of calendar -> days etc.-->
          <div class="b-calendar__weekdays">
            <div
                v-for="(day, index) in getDayNamesAccordingToLocalization()"
                :key="index"
                class="weekday">
              <strong>{{ day }}</strong>
            </div>
          </div>
          <!--End -- Second Top row of calendar -> days etc.-->

          <!--Start -- Calendar-->
          <div class="b-calendar__dates">
            <div v-for="date in dateList"
                 :key="date.key"
                 :class="
                 {
                   'selected': dateIsEqualSelectDate(date),
                   'today': date.today,
                   'blank': date.blank,
                   'weekend': date.weekend,
                   'closed_block': date.closed,
                 }"
                 :data-date="date.date"
                 class="date text-right">

              <a class="link" v-on:click="setSelectedDate(date.moment)"/>

              <div class="not_holiday">
                <span :class="{'dot': date.today}" class="day">
                  {{ date.dayNumber }}
                </span>

                <v-tooltip v-if="date.holidayName !== null" class="holiday_tooltip" top>
                  <template v-slot:activator="{ on }">
                    <v-icon class="isHolidayIcon holiday_tooltip" dense v-on="on">
                      event_note
                    </v-icon>
                  </template>

                  <span>
                    {{ date.holidayName }}
                  </span>
                </v-tooltip>

                <div v-show="date.additional" class="additional">
                  <span v-show="date.additional.year" class="year">
                    {{ date.additional.year }}
                  </span>

                  <span v-show="date.additional.month" class="month">
                    {{ date.additional.month }}
                  </span>
                </div>
              </div>

              <div class="info_graphic">
                <!--Study department restriction-->
                <div v-if="showStudyRestrictionDiv(date.apiDate) !== 0"
                     :class="[(validateEditButton(date.apiDate)) ? 'closed_history' : 'closed']"
                     class="info_graphic_item closed">
                  <span class="info_graphic_item_text">
                    {{ $tc('reservations.study', showStudyRestrictionDiv(date.apiDate)) }}
                  </span>
                </div>

                <!--Personal restriction-->
                <div v-if="showPersonalRestrictionDiv(date.apiDate) !== 0"
                     :class="[(validateEditButton(date.apiDate)) ? 'informative_msg_history' : 'informative_msg']"
                     class="info_graphic_item ">
                  <span class="info_graphic_item_text">
                    {{ $tc('reservations.personal', showPersonalRestrictionDiv(date.apiDate)) }}
                  </span>
                </div>

                <!--Reservation-->
                <div v-if="showReservationDiv(date.apiDate) !== 0"
                     :class="[(validateEditButton(date.apiDate)) ? 'reservation_history' : 'reservation']"
                     class="info_graphic_item">
                  <span class="info_graphic_item_text" data-cy="calendar-day-reservations">
                    {{ $tc('reservations.res_calendar', showReservationDiv(date.apiDate)) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--End -- Calendar-->
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import {mapActions, mapGetters} from 'vuex';
import Loading from "../../../../components/Loading";
import Holidays from 'date-holidays';

export default {
  name: "Calendar",

  components: {
    Loading,
  },

  props: {
    assistantId: [Number],
  },

  data() {
    return {
      // Today constant
      today: moment().locale('cs'),

      // Changes when switch month
      dateContext: moment().locale('cs'),

      // Day which is selected in calendar
      selectedDate: moment().locale('cs'),

      windowWidth: window.outerWidth,

      // Czech holidays constant (called only once)
      holidays: new Holidays('CZ')
    }
  },

  mounted() {
    this.getSelectedDateFromStorage();
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
  },

  methods: {
    ...mapActions('pageStorage', ['setSelectedDayStorage', 'setSelectedMonth']),

    // Shows number of reservations in chip component
    showReservationDiv(apiDate) {
      let reservations = this.getQueueItemStatuses;
      let returnNum = 0;

      for (let reservation of reservations)
        if (
            reservation.wantedDay === apiDate
            && reservation.status !== 'cancel'
            && reservation.status !== 'abort'
            && reservation.origin !== 'panel'
            && (
                reservation.status !== 'done'
                || moment(apiDate, "YYYY-MM-DD").isBefore(moment().subtract(1, "days"))
            )
            && (
                this.validateUserRoles
                || this.getQueueItemByQueueItemStatusId(reservation.id).assistantId === this.assistantId
            )
        )
          returnNum++;

      return returnNum;
    },

    // Shows number of personal restrictions in chip component
    showPersonalRestrictionDiv(apiDate) {
      let reasons = this.getTypeClosedHoursReasons('person');
      let restrictions = this.getClosedHours.filter((item) => reasons.find(({id}) => item.closedHoursReasonId === id));
      let returnNum = 0;

      for (let restriction of restrictions)
        if (
            restriction.date === apiDate
            && (
                this.validateUserRoles
                || restriction.assistantId === this.assistantId
            )
        )
          returnNum++;

      return returnNum;
    },

    // Shows number of study department restrictions in chip component
    showStudyRestrictionDiv(apiDate) {
      let reasons = this.getTypeClosedHoursReasons('department')
      let restrictions = this.getClosedHours.filter((item) => reasons.find(({id}) => item.closedHoursReasonId === id));
      let returnNum = 0;

      for (let restriction of restrictions)
        if (restriction.date === apiDate)
          returnNum++;

      return returnNum;
    },


    // Checks if is possible to edit.
    validateEditButton(apiDate) {
      return moment(this.today).toISOString() >= moment(apiDate).add(1, "days").add(59, "minutes").add(59, "seconds").toISOString();
    },

    /** Date functions */

    // Changing date list
    addMonth() {
      this.dateContext = this.nextMonth;
      this.setSelectedMonth(this.dateContext);
      this.setSelectedDate(this.dateContext);
    },

    subtractMonth() {
      this.dateContext = this.previousMonth;
      this.setSelectedMonth(this.dateContext);
      this.setSelectedDate(this.dateContext);
    },

    // Changing selected date
    setSelectedDate(moment) {
      if (this.selectedDate.format("YYYYMMDD") !== moment.format("YYYYMMDD")) {
        this.selectedDate = moment;

        this.$root.$emit('setSelectedDate', moment);
        this.setSelectedDayStorage(moment);
      }
    },

    getSelectedDateFromStorage() {
      if (this.getSelectedDayStorage !== null) {
        this.selectedDate = this.getSelectedDayStorage;
        this.dateContext = this.selectedMonth;
        this.$root.$emit('setSelectedDate', this.selectedDate);
      } else {
        this.isTodaySelectedDay();
      }
    },

    // Set selected date as today
    goToday() {
      this.dateContext = this.today;
      this.setSelectedMonth(this.today);
      this.setSelectedDate(this.today);
    },

    // Add 0 before [1..9] numbers in day
    twoDigitDay(day) {
      return ("0" + day).slice(-2);
    },

    isWeekend(day) {
      return day.getDay() === 0 || day.getDay() === 6;
    },

    //Compares Moment objects in given format
    areMomentsDatesEqual(momentA, momentB) {
      return momentA.format('YYYYMMDD') === momentB.format('YYYYMMDD')
    },

    //Selected date is compared with date
    dateIsEqualSelectDate(date) {
      return (this.areMomentsDatesEqual(this.selectedDate, date.moment));
    },

    isTodaySelectedDay() {
      if (this.areMomentsDatesEqual(this.selectedDate, this.today)) {
        this.$root.$emit('setSelectedDate', moment());
      }
    },

    // dow = Day of week
    getDayNamesAccordingToLocalization() {
      return [1, 2, 3, 4, 5, 6, 7].map(dow => moment().locale('cs').isoWeekday(dow).format('dddd'));
    },

    getIfClosedHours(apiDate) {
      const openHour = this.getStudyOpenHour(moment(apiDate, "YYYY-MM-DD").day().toString());
      return (openHour.openTime === "" || openHour.openTime === null)
          && openHour.closeTime === "" || (openHour.closeTime === null);
    },


    getStateHolidayName(date) {
      let holiday = this.holidays.isHoliday(moment(date).toDate());

      if (holiday !== false && holiday.type === 'public'){
        return holiday.name;
      }

      return null;
    },

  },

  computed: {
    ...mapGetters('queueItems', ['getQueueItem', 'getQueueItemByQueueItemStatusId', 'didLoadedQueueItems']),
    ...mapGetters('studyProblemsCategories', ['didLoadedStudyProblemsCategories']),
    ...mapGetters('closedHoursReason', ['getTypeClosedHoursReasons', 'getClosedHoursReasons']),
    ...mapGetters('closedHours', ['getClosedHours', 'getTypeClosedHours', 'didLoadedClosedHours']),
    ...mapGetters('StudyOpenHours', ['getStudyOpenHour', 'didLoadedStudyOpenHours']),
    ...mapGetters('queueItemStatuses', ['getQueueItemStatuses', 'didLoadedQueueItemStatuses']),
    ...mapGetters('studyProblemsItems', ['didLoadedStudyProblemsItems']),
    ...mapGetters('pageStorage', ['getAssistantStorage', 'getSelectedDayStorage', 'selectedMonth']),
    ...mapGetters('studyFields', ['didLoadedStudyFields']),

    validateUserRoles() {
      return this.getAssistantStorage.role === 'STUDY_SPY';
    },
    didLoadedAll() {
      return this.didLoadedStudyOpenHours
          && this.didLoadedClosedHours
          && this.didLoadedStudyFields
          && this.didLoadedStudyProblemsItems
          && this.didLoadedStudyProblemsCategories
          && this.didLoadedQueueItemStatuses
          && this.didLoadedQueueItems;
    },

    /** Date computed functions */
    year() {
      return this.dateContext.format("Y");
    },

    month() {
      return this.dateContext.format("MMMM");
    },

    daysInMonth() {
      return this.dateContext.daysInMonth();
    },

    // Returns the weekday number of the first day of month
    firstDayOfMonth() {
      return (moment(this.dateContext).startOf('month').weekday())
    },

    previousMonth() {
      const now = moment()
      const timestamp =  moment(this.dateContext).subtract(1, "month");

      if (timestamp.month() !== now.month()) {
        timestamp.date(1)
      } else {
        timestamp.date(now.date())
      }

      return timestamp;
    },

    previousMonthAsString() {
      return this.previousMonth.format("MMMM");
    },

    nextMonth() {
        const now = moment()
        const timestamp = moment(this.dateContext).add(1, "month");

        if (timestamp.month() !== now.month()) {
          timestamp.date(1)
        } else {
          timestamp.date(now.date())
        }

        return timestamp
    },

    nextMonthAsString() {
      return this.nextMonth.format("MMMM");
    },

    daysInPreviousMonth() {
      return this.previousMonth.daysInMonth();
    },

    // Number of days calendar took from previous month to show
    daysFromPreviousMonth() {
      let daysFromPreviousMonth = [];
      let count = this.daysInPreviousMonth - this.firstDayOfMonth;
      while (count < this.daysInPreviousMonth) {
        daysFromPreviousMonth.push(++count);
      }
      return daysFromPreviousMonth
    },

    // Creates calendar list to display
    dateList() {
      let dateList = [];

      let previousMonth = this.previousMonth;
      let nextMonth = this.nextMonth;

      //dates for display
      let formattedCurrentMonth = this.dateContext.format("MM");
      let formattedCurrentYear = this.year;

      let formattedPreviousMonth = previousMonth.format("MM");
      let formattedPreviousYear = previousMonth.format("Y");

      let formattedNextMonth = nextMonth.format("MM");
      let formattedNextYear = nextMonth.format("Y");

      //counters
      let countDayInCurrentMonth = 0;
      let countDayInPreviousMonth = 0;

      //filling in dates from the previous month
      this.daysFromPreviousMonth.forEach((dayFromPreviousMonth) => {
        countDayInCurrentMonth++;
        countDayInPreviousMonth++;

        let formattedDay = this.twoDigitDay(dayFromPreviousMonth);
        let previousMonth =
            this.daysFromPreviousMonth.length === countDayInPreviousMonth
                ? this.previousMonthAsString
                : false;
        let previousYear =
            formattedCurrentYear !== formattedPreviousYear
            && this.daysFromPreviousMonth.length === countDayInPreviousMonth
                ? formattedPreviousYear
                : false;

        let additional = {
          month: previousMonth,
          year: previousYear
        };

        let apiDate = formattedPreviousYear + "-" + formattedPreviousMonth + "-" + formattedDay;
        let holidayName = this.getStateHolidayName(apiDate);
        let closed = this.getIfClosedHours(apiDate);

        if (!previousMonth && !previousYear)
          additional = false;

        dateList.push({
          key: countDayInCurrentMonth,
          dayNumber: formattedDay,
          apiDate: apiDate,
          date:
              formattedDay + "." + formattedPreviousMonth + "." + formattedPreviousYear,
          blank: true,
          today: false,
          additional: additional,
          moment: moment(formattedPreviousYear + formattedPreviousMonth + formattedDay),
          holidayName: holidayName,
          closed: closed,
        });
      });

      //filling in dates from the current month
      while (countDayInCurrentMonth < (this.firstDayOfMonth + this.daysInMonth)) {
        countDayInCurrentMonth++;

        let day = countDayInCurrentMonth - countDayInPreviousMonth;
        let formattedDay = this.twoDigitDay(day);
        let weekend =
            this.isWeekend(
                new Date(formattedCurrentYear + "-" + formattedCurrentMonth + "-" + formattedDay)
            );

        let apiDate =
            formattedCurrentYear + "-" + formattedCurrentMonth + "-" + formattedDay;
        let holidayName = this.getStateHolidayName(apiDate);
        let closed = this.getIfClosedHours(apiDate);

        dateList.push({
          key: countDayInCurrentMonth,
          dayNumber: formattedDay,
          apiDate: apiDate,
          date:
              formattedDay + "." + formattedCurrentMonth + "." + formattedCurrentYear,
          blank: false,
          today:
              formattedDay === this.todayDate && this.todayInCurrentMonthAndYear,
          additional: false,
          moment:
              moment(formattedCurrentYear + formattedCurrentMonth + formattedDay),
          weekend: weekend,
          holidayName: holidayName,
          closed: closed,
        });
      }

      let daysInNextMonth = 7 - (countDayInCurrentMonth % 7);
      let countDayInCurrentMonthSaved = countDayInCurrentMonth;
      let day = 0;

      //filling in dates from the next month
      if (daysInNextMonth < 7) {
        while (countDayInCurrentMonth < (countDayInCurrentMonthSaved + daysInNextMonth)) {
          countDayInCurrentMonth++;
          day++;

          let formattedDay = this.twoDigitDay(day);
          let nextMonth = day === 1 ? this.nextMonthAsString : false;
          let nextYear =
              formattedCurrentYear !== formattedNextYear && day === 1
                  ? formattedNextYear
                  : false;
          let additional = {
            month: nextMonth,
            year: nextYear,
          };

          if (!nextMonth && !nextYear)
            additional = false;

          let apiDate = formattedNextYear + "-" + formattedNextMonth + "-" + formattedDay;
          let holidayName = this.getStateHolidayName(apiDate);
          let closed = this.getIfClosedHours(apiDate);

          dateList.push({
            key: countDayInCurrentMonth,
            dayNumber: formattedDay,
            apiDate: apiDate,
            date:
                formattedDay + "." + formattedNextMonth + "." + formattedNextYear,
            blank: true,
            today: false,
            additional: additional,
            moment: moment(
                formattedNextYear +
                formattedNextMonth +
                formattedDay
            ),
            holidayName: holidayName,
            closed: closed,
          });
        }
      }

      return dateList
    },

    todayDate() {
      return this.twoDigitDay(this.today.get("date"));
    },

    todayMonth() {
      return this.today.format("MMMM");
    },

    todayYear() {
      return this.today.format("Y");
    },

    //get selected day and month
    todayInCurrentMonthAndYear() {
      return (
          (this.month === this.todayMonth)
          && (this.year === this.todayYear)
      );
    },

    //comparison today with selectedDate
    todayIsEqualSelectDate() {
      return (
          this.selectedDate.format("YYYYMMDD") === this.today.format("YYYYMMDD")
      );
    },
  },
}
</script>

<style lang="scss" scoped>
.b-calendar {
  display: flex;
  align-items: center;
  margin: 0 0;

  &__information {
    background-color: rgba(0, 123, 255, 0.2);
    border-radius: 1.2rem 0 0 1.2rem;
    height: 100%;

    .today {
      flex-direction: column;
      padding-top: 3em;

      .weekDay {
        font-size: 1.2em;
        font-weight: 200;
        padding-bottom: 0.5em;
      }

      .month {
        font-size: 2em;
        font-weight: 200;
        line-height: 1;
        user-select: none;
      }
    }
  }

  &__calendar {
    /*min-height: 30rem;*/
    padding-bottom: 3px;
    display: block;
  }

  &__header {
    margin-bottom: 0;

    .month {
      font-size: 1.25em;
      font-weight: 300;
      text-transform: capitalize;
      user-select: none;
    }
  }

  &__weekdays {
    display: flex;
    margin-bottom: 0;
    margin-top: 0;

    .weekday {
      width: calc(100% / 7);
      padding: 0.25rem 0.5rem;
      text-transform: capitalize;
      user-select: none;
      text-align: center;
    }
  }

  &__dates {
    display: flex;
    flex-wrap: wrap;
    position: center;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      background-color: #fff;
      height: 1px;
      width: 100%;
      z-index: 1;
    }

    .date {
      border: 1px solid rgba(0, 0, 0, 0.05);
      font-weight: 400;
      min-height: 6rem;
      padding: 0.15rem;
      position: relative;
      width: calc(100% / 7);

      &.blank {
        background-color: rgba(0, 0, 0, 0.08);
        color: rgba(30, 30, 30, 0.6);
      }

      &.no-border-right {
        border-right: none;
      }

      &.selected {
        background-color: #c7c4c4 !important;
        color: #fff;
      }

      .link {
        cursor: pointer;
        position: absolute;
        top: 0.05rem;
        left: 0.05rem;
        bottom: 0.05rem;
        right: 0.05rem;
        z-index: 50;
      }

      .additional {
        font-size: 0.85em;
        position: absolute;
        top: 0.30rem;
        left: 0.5rem;
        user-select: none;

        .year {
          padding-right: 0.25rem;
          font-size: 0.75em;
        }
      }
    }
  }
}

.info_graphic {
  height: 75%;
  width: 100%;
  position: absolute;
  z-index: 2;
  margin-top: 0.1rem;
}

.info_graphic_item {
  height: calc(100% / 3.3);
  margin-top: 1px;
  border-radius: 9px;
  text-align: center;
  width: 95%;
}

.info_graphic_item_text {
  color: white;
  font-size: 0.75em;
  float: left;
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  user-select: none;
}

.reservation {
  background-color: #78BE13;
}

.informative_msg {
  background-color: #F7B500;
}

.closed {
  background-color: #FA6400;
}

.reservation_history {
  background-color: #c0c0c0;
}

.informative_msg_history {
  background-color: #acacac;
}

.closed_history {
  background-color: #9d9d9d;
}

.weekend {
  color: gray;
}

.holiday {
  background-color: rgba(85, 124, 255, 0.82) !important;
  width: 100%;
  border-radius: 9px;
  height: 25%;
}

.not_holiday {
  width: 100%;
  border-radius: 9px;
  height: 25%;
}

.day {
  right: 0.5rem;
  position: absolute;
  user-select: none;
}

.isHolidayIcon {
  right: 1.8rem;
  position: absolute;
  user-select: none;
}

.holiday_tooltip {
  z-index: 55;
}

.dot {
  height: 23px;
  width: 23px;
  background-color: #6D7278;
  border-radius: 50%;
  display: inline-block;
  color: white;
  text-align: center;
}

.closed_block {
  background-color: #ffffff;
  color: gray;
}
</style>
