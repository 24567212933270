<template>
    <div class="text-center mt-5 mb-5">
        <v-progress-circular
                :size="small ? 20 : 70"
                :width="small ? 3 : 7"
                color="#76be1a"
                indeterminate
        ></v-progress-circular>
    </div>
</template>

<script>
    export default {
        name: "Loading",

        props: {
            small: {
                type: Boolean,
                default: false,
            }
        }
    }
</script>

<style scoped>
</style>
