<template>
	<div class="locations">
		<v-data-table
			id="locations"
			:headers="getLocationHeaders"
			:items="locationList"
			:custom-sort="customSort"
			show-select
			v-model="selectedItems"
			no-data-text = "Žádné údaje"
			:loading = "loading && !didLoadLocations"
			loading-text="Načítání..."
			no-results-text="Žádné údaje"
      :sort-by.sync="$query.sort"
      :sort-desc.sync="$query.desc"
      :items-per-page.sync="$query.rowsPerPage"
      :page.sync="$query.page"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-dialog v-model="deleteSingleDialog" max-width="500px">
						<v-card>
							<v-card-title><h5 class="text-h5 ma-auto">Opravdu chcete smazat vybrané lokace?</h5></v-card-title>
							<v-card-actions>
								<v-spacer/>
								<v-btn color="blue darken-1" text @click="deleteCancel">Zrušit</v-btn>
								<v-btn dark color="red darken-1" text @click="deleteConfirm">Potvrdit</v-btn>
								<v-spacer/>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<template v-slot:item.name="{ item }" >
				<div v-if="item.localizedAttributes.cz">
					<b class="pointer light-green--text" @click="goToDetail(item.id)">{{ item.localizedAttributes.cz.name }}</b>
				</div>
			</template>
			<template v-slot:item.points="{ item }">
				{{ item.testQuestion ? item.testQuestion.points : 0 }}
			</template>
			<template v-slot:item.locationType="{ item }">
				{{ item.locationType.name }}
			</template>
			<template v-slot:item.augmentedRealityModelAvailability="{ item }">
        <span v-for="platform in Object.keys(item.augmentedRealityModelAvailability)" :key="platform">
          <v-icon>{{ platformIcon(platform) }}</v-icon>
        </span>
			</template>
			<template v-slot:item.scanningType="{ item }">
				{{ item.scanningType.name }}
			</template>
			<template v-slot:item.promptDescription="{ item }" >
				<div v-if="item.localizedAttributes.cz">
					<span>{{ item.localizedAttributes.cz.promptDescription }}</span>
				</div>
			</template>
			<template v-if="selectedItems.length > 0" v-slot:footer>
				<v-toolbar flat>
					<v-btn dark color="red darken-1" text @click="deleteItems">Smazat vybrané lokace</v-btn>
				</v-toolbar>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import {mapActions, mapGetters} from "vuex";

	export default {
		name: "Locations",
		data(){
			return {
				locationList: [],
				loading: true,
				deleteSingleDialog: false,
				selectedItems: [],
			}
		},

		mounted() {
			this.reloadData();
		},

		computed: {
			...mapGetters('Locations', ['locations', 'getLocationHeaders', 'didLoadLocations']),

			getLocations(){
				return this.locations;
			},
		},

		watch: {
			getLocations() {
				this.locationList = this.getLocations;
			}
		},

		methods: {
			...mapActions('Locations', ['fetchLocations', 'deleteLocation']),

			/**
			 * Method that downloads actual data from server.
			 * @returns {Promise<void>}
			 */
			async reloadData() {
				await this.fetchLocations().then(() => {
					this.locationList = this.getLocations;
					this.loading = false;
				})
			},

			/**
			 * Redirect to scenario detail by its ID.
			 * @param locationId
			 */
			goToDetail(locationId){
				this.$router.push({name: 'locationDetail', params: {id: locationId}});
			},
			/**
			 * Helper method for localised string comparison.
			 */
			comp(a, b){
				return ('' + a).localeCompare(b);
			},
			/**
			 * Custom method for sorting data.
			 */
			customSort(items, index, isDesc) {
				items.sort((a, b) => {
					if ( index[0] === 'name' ){
						if ( !isDesc[0] ){
							return this.comp(a.localizedAttributes.cz.name, b.localizedAttributes.cz.name);
						} else {
							return this.comp(b.localizedAttributes.cz.name, a.localizedAttributes.cz.name);
						}
					} else if ( index[0] === 'promptDescription' ){
						if ( !isDesc[0] ){
							return this.comp(a.localizedAttributes.cz.promptDescription, b.localizedAttributes.cz.promptDescription);
						} else {
							return this.comp(b.localizedAttributes.cz.promptDescription, a.localizedAttributes.cz.promptDescription);
						}
					} else if ( index[0] === 'points' ){
						if ( !isDesc[0] ){
							return this.comp(a.testQuestion?.points, b.testQuestion?.points);
						} else {
							return this.comp(b.testQuestion?.points, a.testQuestion?.points);
						}
					} else if ( index[0] === 'locationType' ){
						if ( !isDesc[0] ){
							return this.comp(a.locationType.name, b.locationType.name);
						} else {
							return this.comp(b.locationType.name, a.locationType.name);
						}
					} else if ( index[0] === 'scanningType' ){
						if ( !isDesc[0] ){
							return this.comp(a.scanningType.name, b.scanningType.name);
						} else {
							return this.comp(b.scanningType.name, a.scanningType.name);
						}
					} else if ( index[0] === 'augmentedRealityModelAvailability' ){
                        if ( !isDesc[0] ){
							return this.platformSetSum(a) - this.platformSetSum(b);
						} else {
							return this.platformSetSum(b) - this.platformSetSum(a);
						}
					}
				})
				return items
			},

      /**
       * calculates number encoding available platforms as set for sorting, shoutout to 🐟
       * [] -> 0
       * [android] -> 1
       * [ios] -> 2
       * [android, ios] -> 3
       * @param location
       * @returns {number}
       */
      platformSetSum(location) {
        return Object.keys(location.augmentedRealityModelAvailability).reduce((sum, e) => {
          if (e === 'android') return sum+1; else return sum+2;
        },0)
      },

			/**
			 * Opens dialog for deletion of locations.
			 */
			deleteItems(){
				this.deleteSingleDialog = true;
			},

			/**
			 * Proceeds to delete selected locations.
			 */
			deleteConfirm(){
				this.locationList.forEach( (item) => {
					if ( this.selectedItems.includes(item)) {
						this.deleteLocation(item.id);
					}
				});
				this.deleteSingleDialog = false;
				this.selectedItems = [];
			},

			/**
			 * Cancels location deletion.
			 */
			deleteCancel(){
				this.deleteSingleDialog = false;
			},

      /**
       * @param platform
       * @returns material icon of platform
       */
      platformIcon(platform) {
        if (platform === 'ios') return 'phone_iphone';
        else return 'android';
      }
		},
	}
</script>

<style scoped>
	.locations {
		width: 100%;
	}
	.points {
		font-weight: bold;
		font-size: 20px;
	}
	.highlight i {
		color: white;
	}
	.no-data {
		display: flex;
		align-items: center;
		justify-content: center;
		color: rgba(0,0,0,0.75);
	}
	.actions i, .actions button {
		font-size: 16px;
		margin: 3px;
	}
</style>
