import {del, get, post, put} from '@/services/api'
import Vue from "vue";

const state = {
    didLoadFaculties: false,
    didLoadAudiences: false,
    didLoadAdministrators: false,
    faculties: [],
    audiences: [],
    administrators: [],
    error: false
};

const getters = {
    didLoadFaculties: state => state.didLoadFaculties,
    didLoadAudiences: state => state.didLoadAudiences,
    didLoadAdministrators: state => state.didLoadAdministrators,
    getAdministratorById: state => id => state.administrators.find(obj => obj.id === id),
    getSortedFaculties: state => {
        return state.faculties
            .map(obj => ({
                text: obj.localizedAttributes.cz.name,
                value: obj.id,
            }))
            .sort((a, b) => a.text.localeCompare(b.text))
    },
    getFacultiesForEdit: state => state.faculties,
    getSortedAudiences: state => {
        return state.audiences
            .map(obj => ({
                text: obj.localizedAttributes.cz.name,
                value: obj.id,
            }))
            .sort((a, b) => a.text.localeCompare(b.text))
    },
    getAudiencesForEdit: state => state.audiences,
    getSortedAdministrators: state => {
        return state.administrators.sort((a, b) => b.id - a.id);
    },
    getErr: state => state.error,
};

const actions = {
    async fetchAllAudiences({commit}) {
        commit('setDidLoadAudiences', false);
        await get(`audience/`).then((response) => {
            commit('setAudiences', response.data.items);
            commit('setDidLoadAudiences', true);
        });
    },
    async fetchAllFaculties({commit}) {
        commit('setDidLoadFaculties', false);
        await get(`faculty/`).then((response) => {
            commit('setFaculties', response.data.items);
            commit('setDidLoadFaculties', true);
        });
    },
    async fetchAllAdministrators({commit}) {
        commit('setDidLoadAdministrators', false);
        try{
            await get(`newsfeed/admin/`).then((response) => {
                commit('setAdministrators', response.data.items);
                commit('setDidLoadAdministrators', true);
            });
        } catch (e) {
            if (e.statusCode !== 403) throw e
            commit('errors/objectNotFound', null, {root: true});
        }



    },
    async deleteAdministrator({commit}, id) {
        await del(`newsfeed/admin/${id}`).then(() => {
            commit('filterAdministrator', id);
        });
    },
    async updateAdministrator({commit}, {id, request}) {
        const response = await put(`newsfeed/admin/${id}`, request);
        commit('updateAdministrator', response.data.content);
    },

    async createAdministrator({commit}, request) {
        commit('setError', false);
        try {
            const response = await post(`newsfeed/admin/`, request);
            commit('setAdministrator', response.data.content);
        } catch (e) {
            if (e.error === 409) {
                commit('setError', true);
            } else {
                throw e;
            }
        }
    },

    async setErr({commit}, request) {
        commit('setError', request);
    },

};

const mutations = {
    setDidLoadFaculties: (state, status) => (state.didLoadFaculties = status),
    setDidLoadAudiences: (state, status) => (state.didLoadAudiences = status),
    setDidLoadAdministrators: (state, status) => (state.didLoadAdministrators = status),
    setAudiences: (state, audience) => (state.audiences = audience),
    setFaculties: (state, faculty) => (state.faculties = faculty),
    setAdministrators: (state, administrator) => (state.administrators = administrator),
    filterAdministrator: (state, id) => (state.administrators = state.administrators.filter(obj => obj.id !== id)),
    updateAdministrator: (state, item) => {
        let index = state.administrators.findIndex(user => user.id === item.id);
        Vue.set(state.administrators, index, item);
    },
    setAdministrator: (state, item) => (state.administrators.push(item)),
    setError: (state, err) => (state.error = err),

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
