var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preview-container"},[(_vm.isSmall)?_c('h4',{staticClass:"preview-header"},[_vm._v("Náhled oznámení")]):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"preview-tools"},[_c('v-slide-group',{attrs:{"mandatory":""},model:{value:(_vm.previewLanguage),callback:function ($$v) {_vm.previewLanguage=$$v},expression:"previewLanguage"}},[(_vm.selectedLanguages.length > 1)?_c('div',_vm._l((_vm.selectedLanguages),function(language){return _c('v-slide-item',{key:language,attrs:{"value":language},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mr-1 mb-2",attrs:{"x-small":"","rounded":"","depressed":"","id":("preview-" + language + "-btn"),"color":active ? 'primary' : 'normal'},on:{"click":toggle}},[_vm._v(" "+_vm._s(language)+" ")])]}}],null,true)})}),1):_vm._e()]),(!_vm.blockParallel)?_c('v-slide-group',{attrs:{"mandatory":""},model:{value:(_vm.previewOrientation),callback:function ($$v) {_vm.previewOrientation=$$v},expression:"previewOrientation"}},[_c('v-slide-item',{key:"blockParallel",attrs:{"value":"blockParallel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mr-1 mb-2 p-0",attrs:{"id":"preview-blockParallel-btn","x-small":"","rounded":"","depressed":"","color":active ? 'primary' : 'normal'},on:{"click":toggle}},[_vm._v(" celá obrazovka ")])]}}],null,false,1682844475)}),_c('v-slide-item',{key:"portrait",attrs:{"value":"portrait"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mr-1 mb-2 p-0",attrs:{"id":"preview-portrait-btn","x-small":"","rounded":"","depressed":"","color":active ? 'primary' : 'normal'},on:{"click":toggle}},[_vm._v(" na výšku ")])]}}],null,false,305388073)}),_c('v-slide-item',{key:"landscape",attrs:{"value":"landscape"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mr-0 mb-2 p-0",attrs:{"id":"preview-landscape-btn","x-small":"","rounded":"","depressed":"","color":active ? 'primary' : 'normal'},on:{"click":toggle}},[_vm._v(" na šířku ")])]}}],null,false,1404401283)})],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"preview-wrapper",attrs:{"id":"preview-wrapper"}},[_c('iframe',{class:("preview preview-" + _vm.previewOrientation + ((_vm.isLargeDevice && _vm.inDialog) ? '-lg' : (_vm.isSmall ? '-sm' : ''))),attrs:{"src":((_vm.config.displaysUrl) + "preview"),"id":("preview-frame" + (_vm.inDialog ? '-dialog' : '')),"scrolling":"no"},on:{"load":function () { 
        _vm.onUpdateContent();
        this$1.isLoading = false;
      }}}),(!_vm.inDialog && !_vm.isLoading)?_c('v-btn',{staticClass:"zoom-icon",attrs:{"id":"zoom-preview-btn","color":"white","icon":"","x-large":""},on:{"click":function($event){return _vm.$emit('zoomIn')}}},[_c('v-icon',{attrs:{"large":"","dark":""}},[_vm._v("zoom_in")])],1):_vm._e(),(_vm.isLoading)?_c('Loading',{staticClass:"iframe-loader"}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }