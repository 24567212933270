import { get, post, put, del } from '@/services/api';
import Vue from "vue";

const state = {
  didLoadAnnouncementTypes: false,
  announcementTypes: []
};

const getters = {
  didLoadAnnouncementTypes: state => state.didLoadAnnouncementTypes,
  getAnnouncementTypes: state => state.announcementTypes,
  getAnnouncementTypeById: state => id => {
    return state.announcementTypes.find(announcementType => announcementType.id == id)
  },
  prepareNewAnnouncementType: () => () => ({
    name: "",
    color: "#80CC16",
    iconName: "",
    displayOnMobile: false,
    displaysIds: []
  })
};

const actions = {
  fetchAnnouncementTypes({ commit }, unitId) {
    commit('startedDataDownload');
    return new Promise((resolve, reject) => {
      get(`displays/announcement_types/unit/${unitId}`)
      .then((response) => {
        commit('setAnnouncementTypes', response.data.items)
        commit('finishedDataDownload');
        resolve();
      })
      .catch(e => {
        console.log(e.message);
        reject();
      });
    });
  },

  async fetchAnnouncementTypeById({commit}, id) {
    commit('errors/clear', null, { root: true });
    commit('startedDataDownload');
    try {
      const response = await get(`displays/announcement_types/${id}`);
      commit('updateAnnouncementType', response.data.content)
      commit('finishedDataDownload');
    } catch(e) {
      if (e.statusCode == 404) {
        commit('errors/objectNotFound', null, { root: true });
      } else {
        throw e;
      }
    }
  },

  deleteAnnouncementType({ commit }, id) {
    return new Promise((resolve, reject) => {
      del(`displays/announcement_types/${id}`)
        .then((response) => {
          commit('filterAnnouncementTypes', id)
          resolve(response.data);
        })
        .catch(() => {
            reject();
        });
    });
  },

  createAnnouncementType({ commit }, item) {
    return new Promise((resolve, reject) => {
      post('displays/announcement_types/', item)
        .then((response) => {
            commit('addAnnouncementType', response.data.content);
            resolve(response.data.content);
        })
        .catch(e => {
          console.log(e.message);
          reject();
        });
    });
  },

  updateAnnouncementType({ commit }, item) {
    return new Promise((resolve, reject) => {
      put(`displays/announcement_types/${item.id}`, item)
        .then(() => {
          commit('updateAnnouncementType', item);
          resolve();
        }).catch(e => {
          console.log(e.message);
          reject();
        });
    });
  },
};

const mutations = {
  startedDataDownload: state => state.didLoadAnnouncementTypes = false,
  finishedDataDownload: state => state.didLoadAnnouncementTypes = true,
  addAnnouncementType: ( state, item ) => (state.announcementTypes.push(item)),
  setAnnouncementTypes: ( state, announcementTypes ) => (state.announcementTypes = announcementTypes),
  filterAnnouncementTypes: ( state, id ) => (state.announcementTypes = state.announcementTypes.filter(announcementType => announcementType.id !== id)),   // used for delete
  updateAnnouncementType: (state, item) => {
    const existing = state.announcementTypes.findIndex(announcementType => announcementType.id === item.id);
    if (existing > -1) {
        Vue.set(state.announcementTypes, existing, item);
    } else {
        state.announcementTypes.push(item);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
