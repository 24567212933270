import { get } from '@/services/api'

const state = {
    faculties: [],
    didLoadData: false,
};

const getters = {
    didLoadFaculties: state => state.didLoadData,
    getFaculties: state => state.faculties
        .map(obj => ({
            text: obj.localizedAttributes.cz.name,
            value: obj,
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
};

const actions = {
    async fetchFaculties({commit}) {
        commit('changeLoadedState', false);
        commit('errors/clear', null, {root: true});
        try {
            await get('faculty/by_user').then((response) => {
                commit('setFaculties', response.data.items);
                commit('changeLoadedState', true);
            });
        } catch (e) {
            if (e.statusCode == 404) {
                commit('errors/objectNotFound', null, {root: true});
            } else {
                throw e;
            }
        }
    },

    async fetchAllFaculties({commit}) {
        commit('changeLoadedState', false);
        const response = await get('faculty/');
        commit('setFaculties', response.data.items);
        commit('changeLoadedState', true);
    },
};

const mutations = {
    changeLoadedState: (state, status) => (state.didLoadData = status),
    setFaculties: (state, faculties) => (state.faculties = faculties),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
