<template>
    <v-btn id="save-button" data-cy="save-button" v-if="isSaving || state === SaveButtonState.saving" outlined rounded color="dark" :disabled="true">
        {{ savingLabel }}
    </v-btn>
    <v-btn id="save-button" data-cy="save-button" v-else-if="hasRecentlyUpdated && state === SaveButtonState.error" outlined rounded color="error" @click="onClick" :disabled="disabled">
        <v-icon left>error</v-icon>
        {{ errorLabel }}
    </v-btn>
    <v-btn id="save-button" data-cy="save-button" v-else-if="hasRecentlyUpdated" outlined rounded color="primary" @click="onClick" :disabled="disabled">
        <v-icon left>check</v-icon>
        {{ savedLabel }}
    </v-btn>
    <v-btn id="save-button" data-cy="save-button" v-else rounded color="primary" @click="onClick" :disabled="disabled">
        {{ label }}
    </v-btn>
</template>

<script>
    import {SaveButtonState} from "@/components/buttons/SaveButtonState";

    export default {
        name: 'SaveButton',
        computed: {
            SaveButtonState() {
                return SaveButtonState
            }
        },

        props: {
            disabled: Boolean,

            /**
             * DEPRECATED: Use `state` instead
             */
            isSaving: Boolean,

            /**
             * Do not use `isSaving` and `state` together
             * This is preferred over `isSaving`
             */
            state: {
                type: String,
                validator: function (value) {
                    return Object.values(SaveButtonState).includes(value)
                }
            },

            label: {
                type: String,
                default: "Uložit"
            },
            savedLabel: {
                type: String,
                default: "Uloženo"
            },
            errorLabel: {
                type: String,
                default: "Chyba"
            },
            savingLabel: {
                type: String,
                default: "Ukládání..."
            },
        },

        data() {
            return {
                hasRecentlyUpdated: false,
            }
        },

        watch: {
            isSaving: function(newValue, oldValue) {
                if (oldValue && !newValue) {
                    this.hasRecentlyUpdated = true;
                    setTimeout(() => this.hasRecentlyUpdated = false, 3000);
                }
            },
            state: function(newValue) {
                if (newValue === SaveButtonState.saved || newValue === SaveButtonState.error) {
                    this.hasRecentlyUpdated = true;
                    setTimeout(() => this.hasRecentlyUpdated = false, 3000);
                }
            }
        },

        methods: {
            onClick() {
                this.$emit('click');
            }
        }
    }
</script>

<style scoped>

</style>
