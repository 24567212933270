<template>

    <Loading v-if="!getDidLoadedAllSchedules"></Loading>
    <div v-else class="schedules-page mt-3">
        <moduleHeader>
            <h1>Schedules</h1>
        </moduleHeader>

        <div class="d-flex flex-wrap">

            <!--  Cards  -->
            <div class="faculty-card d-flex flex-column justify-start align-center pt-6" v-for="faculty in faculties"
                 :key="faculty" @click="goToDetail(faculty)">

                <div class="faculty-text my-3">
                    {{ faculty }}
                </div>

                <!--  Schedules info  -->
                <div class="d-flex flex-column align-center justify-center my-4"
                     v-for="schedule in schedulesByFaculty(faculty)" :key="schedule.id">

                    <div>
                        <b>{{schedule.code}}</b>
                    </div>

                    <div>
                        <b>
                        <span v-if="schedule.starts !== null">
                            {{ formatDate(schedule.starts) }}
                        </span>
                            <span v-else class="red--text">
                            Not set
                        </span>
                            <span>–</span>
                            <span v-if="schedule.ends !== null">
                            {{ formatDate(schedule.ends) }}
                        </span>
                            <span v-else class="red--text">
                            Not set
                        </span>
                        </b>
                    </div>

                    <div>
                        <span v-if="schedule.countOfWeeks !== null">
                            {{ schedule.countOfWeeks }} weeks,
                        </span>
                        <span v-else class="red--text">
                            Weeks not set,
                        </span>
                        <span v-if="schedule.firstWeekIsOdd !== null">
                            1st week is <b>{{ schedule.firstWeekIsOdd === true ? 'odd' : 'even' }}</b>
                        </span>
                        <span v-else class="red--text">
                            Not set
                        </span>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ModuleHeader from "@/components/layout/ModuleHeader";
import Loading from "@/components/Loading";
import moment from "moment";

export default {
    name: "Schedules",

    components: {Loading, ModuleHeader},

    data() {
        return {
            isLoading: true,
        }
    },

    async created() {
        await this.fetchAllSchedules();
        this.isLoading = false;
    },

    computed: {
        ...mapGetters('schedules', ['getAllSchedules', 'getFaculties', 'getDidLoadedAllSchedules']),

        schedules() {
            return this.getAllSchedules;
        },

        faculties() {
            return this.getFaculties;
        }
    },

    methods: {
        ...mapActions('schedules', ['fetchAllSchedules']),

        schedulesByFaculty(faculty) {
            return this.schedules.filter(f => f.faculty === faculty);
        },

        goToDetail(faculty) {
            this.$router.push({name: 'SchedulesDetail', params: {faculty: faculty}});
        },

        formatDate(date) {
            return date ? moment.unix(date).format('DD.MM.YYYY') : null;
        }

    }
}
</script>

<style scoped>

.schedules-page {
    width: 100%;
    height: 100%;
    max-width: 100%;
}

.faculty-card {
    width: 250px;
    min-width: 250px;
    height: 350px;
    border: #C4C4C4 1px solid;
    border-radius: 5px;
    background: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: 10px;
    cursor: pointer;
}

.faculty-text {
    font-size: 2rem;
}

</style>