<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-dialog v-else v-model="dialog" persistent max-width="700px">
        <template v-slot:activator="{ on }">
            <v-text-field
                    id="study-dialog-field"
                    v-model="dialogModel"
                    :label=" $t('reservations.pick_type_field')"
                    readonly
                    v-on="on"
                    solo
                    aria-required="true"
                    :rules="[v => !!v || 'Item is required']"
                    :hide-details="true">
            </v-text-field>
        </template>
        <v-card>
            <v-form ref="form"
                    v-model="valid">
                <v-card-title>
                    <span class="headline">{{ $t('reservations.pick_req_ref') }}</span>
                </v-card-title>

                <v-card-text>
                    <v-divider></v-divider>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="4" class="no_margin_no_padding">
                                <span class="text">{{ $t('reservations.pick_study_type') }}</span>
                            </v-col>
                            <v-col cols="12" sm="8" class="no_margin_no_padding">
                                <v-select
                                        id="study-type"
                                        v-model="chosenType"
                                        :items="types"
                                        :rules="[v => !!v || 'Item is required']"
                                        required
                                        solo
                                        return-object
                                        @change="chosenField = null"
                                ></v-select>
                            </v-col>

                            <v-col cols="12" sm="4" class="no_margin_no_padding">
                                <span class="text">{{ $t('reservations.pick_study_field') }}</span>
                            </v-col>
                            <v-col cols="12" sm="8" class="no_margin_no_padding">
                                <v-select
                                        id="study-field"
                                        v-model="chosenField"
                                        :items="selectStudyFields()"
                                        :rules="[v => !!v || 'Item is required']"
                                        required
                                        solo
                                        :item-text="text"
                                        return-object
                                        :disabled="setDisabled()"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog(false)">{{ $t('reservations.close') }}</v-btn>
                    <v-btn id="save-study-type" color="blue darken-1" :disabled="!valid" text @click="closeDialogAndSetModel()">{{ $t('reservations.add') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Loading from "../../../../components/Loading";

    export default {
        components: {Loading},

        name: "StudyTypeFieldDialog",

        props: {
            fieldId: {
                type: Number,
                default: -20,
            },
        },

        data() {
            return {
                valid: true,
                form: null,

                dialogModel: null,
                dialog: false,

                chosenType: null,
                chosenField: null,

                types: [{"text": this.$t('entities.study_types.daily_type'), "value": "daily_type"},
                    {"text": this.$t('entities.study_types.weekly_type'), "value": "weekly_type"},
                    {"text": this.$t('entities.study_types.other_type'), "value": "other_type"}],
            }
        },

        created() {
            this.preloadModel();
        },

        watch: {
            fieldId() {
              this.preloadModel()
            }
        },

        computed: {
            ...mapGetters('queueItems', ['didLoadedQueueItems']),
            ...mapGetters('studyProblemsCategories', ['didLoadedStudyProblemsCategories']),
            ...mapGetters('closedHours', ['didLoadedClosedHours']),
            ...mapGetters('assistant', ['didLoadedAssistants']),
            ...mapGetters('queueItemStatuses', ['didLoadedQueueItemStatuses']),
            ...mapGetters('studyProblemsItems', ['didLoadedStudyProblemsItems']),
            ...mapGetters('studyFields', ['getStudyFields', 'getStudyField', 'didLoadedStudyFields',]),

            didLoadedAll() {
                return this.didLoadedAssistants && this.didLoadedStudyFields && this.didLoadedStudyProblemsItems && this.didLoadedStudyProblemsCategories
                    && this.didLoadedQueueItemStatuses && this.didLoadedQueueItems && this.didLoadedClosedHours;
            },
        },

        methods: {
            closeDialog: function (value) {
                this.resetModelCreate();
                this.resetModelEdit();
                this.dialog = value;
            },

            closeDialogAndSetModel: function () {
                if (this.chosenType != null && this.chosenField != null) {
                    this.dialogModel = this.chosenType.text + ' - ' + this.chosenField.name;
                    this.dialog = false;
                    this.setFieldIDinNewReservation();
                }
            },

            selectStudyFields: function () {
                let studyFields = this.getStudyFields.filter(item => !item.archived);
                let retArray = [];

                if (this.chosenType === null) {
                    return studyFields;
                } else {
                    for (let field of studyFields) {
                        if (field.type === this.chosenType.value) {
                            retArray.push(field);
                        }
                    }
                    return retArray;
                }
            },

            text(item) {
                return this.translateStudyType(item.studyType) + ' - ' + item.name;
            },

            setDisabled: function () {
                return this.chosenType === null;
            },

            translateStudyType(status) {
                switch (status) {
                    case 'Master':
                        return this.$t('reservations.Master');
                    case 'Bachelor':
                        return this.$t('reservations.Bachelor');
                    case 'Other':
                        return this.$t('reservations.Other');
                }
            },

            setFieldIDinNewReservation: function () {
                let toEmit = this.chosenField.id;
                this.$root.$emit('setFieldId', toEmit);
            },

            preloadModel: function () {
                if (this.fieldId !== -20) {
                    let field = this.getStudyField(this.fieldId);
                    this.types.forEach((item) => {
                        if (item.value === field.type) {
                            this.chosenType = item;
                        }
                    });
                    this.chosenField = field;
                    this.dialogModel = this.chosenType.text + ' - ' + this.chosenField.name;
                }
            },

            resetModelCreate() {
                if (this.fieldId === -20) {
                    this.chosenType = null;
                    this.chosenField = null;
                }
            },

            resetModelEdit() {
                if (this.fieldId !== -20) {
                    let field = this.getStudyField(this.fieldId);
                    this.types.forEach((item) => {
                        if (item.value === field.type) {
                            this.chosenType = item;
                        }
                    });
                    this.chosenField = field;
                }
            },
        },
    }
</script>

<style scoped>
</style>
