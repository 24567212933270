<template>
    <ItemNotFoundState v-if="notFound" :objectName="'Test'">
        <template #backButton>
            <BackButton
                :target="{name: 'Gamification', params: {subjectId: subjectId, tab: 'tests'}}">
                Testy
            </BackButton>
        </template>
    </ItemNotFoundState>
    <Loading v-else-if="!didLoadAll || isLoading"></Loading>
    <v-container v-else>
        <CraftingQuestionDialog v-if="selectedQuestion" :showDialog="showCraftingQuestionDialog" :questionId="selectedQuestion.id" @close="selectedQuestion = null; setQuestions()" />
        <v-dialog v-model="qrDialog" max-width="700px">
            <v-card>
                <v-row no-gutters class="align-center">
                    <v-col cols="auto" class="pr-0">
                        <v-card-title class="pr-0">{{$t('gamification.qr_code')}}</v-card-title>
                    </v-col>
                    <v-col cols="auto">
                        <InfoButton right>{{$t('gamification.QR_code_dialog_info')}}</InfoButton>
                    </v-col>
                </v-row>
                <v-row justify="center" no-gutters>
                    <qrcode
                        ref="qr_code"
                        :value="showQrData"
                        :options="{
                        width: 300,
                        color: {
                            dark: '#000000',
                            light: '#eeeeee',
                        },
                    }"
                        tag="img"
                    ></qrcode>
                </v-row>
                <v-row class="pa-4 ma-0" justify="end" no-gutters>
                    <v-btn plain @click="toggleQrDialog()">
                        {{$t('entities.close')}}
                    </v-btn>
                </v-row>
            </v-card>
        </v-dialog>
        <ConfirmDialog
            :model="showDeleteDialog"
            :onConfirm="deleteTest"
            :onCancel="() => this.showDeleteDialog = false"
            yesColor="red"
            yes="Smazat"
        >
            {{$t('gamification.really_delete')}}
        </ConfirmDialog>
        <v-row>
            <v-col>
                <BackButton
                  :target="{name: 'Gamification', params: {subjectId: subjectId, tab: 'tests'}}">
                    {{ $t('gamification.list_of_tests')}}
                </BackButton>
            </v-col>
        </v-row>
        <v-row>
        </v-row>

        <v-row class="align-center">
            <v-col>
                <ModuleHeader class="maxWidth" data-cy="module-header">
                    <v-row class="align-center" justify="center" justify-md="space-between">
                        <v-col cols="auto">
                            <v-row no-gutters justify="center">
                                <v-col cols="12" md="auto"  v-if="isNew">
                                    <h1>{{ $t('gamification.add_test')}}</h1>
                                </v-col>
                                <v-col cols="12" md="auto"  v-else-if="!test.activated">
                                    <h1>{{ $t('gamification.edit_test')}}</h1>
                                </v-col>
                                <v-col cols="12" md="auto"  v-else>
                                    <h1>{{ $t('gamification.test_detail')}}</h1>
                                </v-col>
                                <v-col class="mt-md-2" cols="auto">
                                    <InfoButton right>{{ $t('gamification.test_info')}}</InfoButton>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="auto">
                            <v-row justify-md="end">
                                <v-col cols="12" md="auto" class="centered-btn" v-if="!test.hidden && !test.activated">
                                    <v-btn
                                        :disabled="testNameAlert === 'error' || subjectCategoryAlert === 'error' || testQuestionsAlert === 'error'"
                                        color="white" elevation="0" rounded
                                        @click="test.hidden = !test.hidden; unsavedChanges = true"
                                    >
                                        {{ $t('gamification.hide_test')}}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="auto" class="centered-btn" v-if="test.hidden && !test.activated">
                                    <v-btn
                                        color="primary" elevation="0" rounded
                                        @click="test.hidden = !test.hidden"
                                    >
                                        {{ $t('gamification.show_test')}}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="auto" class="centered-btn" v-if="!isNew && !test.activated">
                                    <v-btn
                                        @click="showDeleteDialog = true"
                                        elevation="0" rounded
                                        class="red--text"
                                    >
                                        {{ $t('gamification.delete_test')}}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="auto" class="centered-btn">
                                    <SaveButton
                                        :disabled="testNameAlert === 'error' || subjectCategoryAlert === 'error' || testQuestionsAlert === 'error'"
                                        :is-saving="isSaving"
                                        @click="save()"
                                        :label="$t('entities.save')"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </ModuleHeader>
            </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col v-if="test.activated" cols="12" md="5" lg="3">
            <v-alert dense text type="info">{{$t('gamification.deactivate_to_edit')}}</v-alert>
          </v-col>
          <v-col v-else cols="12" md="6" lg="3">
            <v-alert dense text :type="testActivationAlert">{{$t('gamification.activate_test')}}</v-alert>
          </v-col>
        </v-row>

        <v-row class="align-center py-0" v-if="isNew || !test.activated" no-gutters>
            <v-col class="mr-4" cols="12" md="5" lg="3" v-if="test.activated">
                <v-alert type="info" dense text>{{$t('gamification.warning_edit')}}</v-alert>
            </v-col>
            <v-col class="mr-4" cols="12" md="5" lg="3">
                <v-alert dense text :type="testNameAlert">{{$t('gamification.test_name_filled')}}</v-alert>
            </v-col>
            <v-col class="mr-4" cols="12" md="5" lg="3">
                <v-alert class="align-center" dense text :type="subjectCategoryAlert">{{$t('gamification.test_category_selected')}}</v-alert>
            </v-col>
            <v-col class="mr-4" cols="12" md="5" lg="3">
                <v-alert dense text :type="testQuestionsAlert">{{$t('gamification.test_questions_filled')}}</v-alert>
            </v-col>
        </v-row>
        <v-row no-gutters justify="center" justify-md="start">
            <v-col cols="auto" class="language-selector">
                <v-tabs
                    v-model="currentLanguage"
                    color="light-green darken-1"
                >
                    <v-tab :href="'#' + this.languages[0].value" :key="hasCzAttributesSet + languages[0].value" class="px-1 px-sm-2">
                        <div class="d-flex align-center ">
                            <p class="mb-0">{{this.languages[0].text}}</p>
                            <v-icon v-if="!hasCzAttributesSet" color="red" class="ml-sm-2 ">fas fa-exclamation-circle</v-icon>
                        </div>
                    </v-tab>

                    <v-tab :href="'#' + this.languages[1].value" :key="hasEnAttributesSet + languages[1].value" class="px-1 px-sm-2">
                        <div class="d-flex align-center">
                            <p class="mb-0">{{this.languages[1].text}}</p>
                            <v-icon v-if="!hasEnAttributesSet" color="red" class="ml-sm-2">fas fa-exclamation-circle</v-icon>
                        </div>
                    </v-tab>
                </v-tabs>

            </v-col>
            <v-col cols="auto" class="mt-2 ml-n2">
                <InfoButton right>{{$t('gamification.language_test_info')}}</InfoButton>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-form @submit.prevent="save()" ref="form" class="maxWidth">
                    <v-row>
                        <v-col>
                            <v-text-field
                                :label="$t('gamification.test_name')"
                                @change="changeTestNameWatch"
                                :rules="[required_rule]"
                                :id="`name-${currentLanguage}`"
                                :disabled="test.activated"
                                v-model="testName[currentLanguage]"
                                color="#78BE13"
                            ></v-text-field>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                :label="$t('gamification.course')"
                                @change="changeWatch"
                                v-model="subjectName[currentLanguage]"
                                :disabled="test.activated"
                                color="#78BE13"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-row class="align-center">
                                <v-col cols="auto" class="pr-0">
                                    <InfoButton right>{{$t('gamification.category_info')}}</InfoButton>
                                </v-col>
                                <v-col cols="8" sm="auto" md="10" class="pl-0">
                                    <v-select
                                        :items="subjectCategories[currentLanguage]"
                                        :label="$t('gamification.category')"
                                        @change="setSubjectCategoryId"
                                        :rules="[required_rule]"
                                        v-model="subjectCategory[currentLanguage]"
                                        :disabled="test.activated"
                                        color="#78BE13"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row v-if="!isNew" class="align-center">
                        <v-col cols="12" md="6">
                            <v-row class="align-center">
                                <v-col cols="auto">
                                    <v-icon>
                                        person
                                    </v-icon>
                                </v-col>
                                <v-col cols="auto">
                                    <v-row no-gutters>
                                        <p class="small-desc">{{$t('gamification.created_by')}}</p>
                                    </v-row>
                                    <v-row no-gutters>
                                        {{test.admin}}
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-row class="align-center">
                                <v-col cols="auto">
                                    <v-icon>
                                        calendar_today
                                    </v-icon>
                                </v-col>
                                <v-col cols="auto">
                                    <v-row no-gutters>
                                        <p class="small-desc">{{$t('gamification.created')}}</p>
                                    </v-row>
                                    <v-row no-gutters>
                                        {{formatDateTime(test.date)}}
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row class="activated-container mt-10 text-center text-md-left">
                        <v-col>
                            <v-row no-gutters justify="center" justify-md="start">
                                <v-col cols="auto">
                                    <h2 class="text-center text-md-left">{{$t('gamification.launch')}}</h2>
                                </v-col>
                                <v-col cols="auto">
                                    <InfoButton right>{{$t('gamification.test_activation_info')}}</InfoButton>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="align-center justify-center justify-md-space-between">
                                <v-col cols="auto">
                                    <v-switch
                                        v-model="test.activated"
                                        :label="test.activated ? $t('gamification.test_activated') : $t('gamification.test_deactivated')"
                                        @change="handleActivationAlert"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="auto">
                                    <v-row class="align-center">
                                        <v-col cols="auto" class="pr-0">
                                            <InfoButton left>{{$t('gamification.QR_code_info')}}</InfoButton>
                                        </v-col>
                                        <v-col @click="toggleQrDialog()" style="cursor: pointer;">
                                            <qrcode
                                                ref="qr_code"
                                                :value="showQrData"
                                                :options="{
                                                    width: 50,
                                                    color: {
                                                        dark: '#000000',
                                                        light: '#eeeeee',
                                                    },
                                                }"
                                                tag="img"
                                            ></qrcode>
                                        </v-col>
                                        <v-col>
                                            <PrimaryButton @click="downloadQRCode">{{$t('entities.download')}}</PrimaryButton>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <span class="small-desc">{{$t('gamification.cannot_edit_when_active')}}</span>
                            </v-row>
                        </v-col>
                    </v-row>

                    <QuestionDialog :testQuestions="questionsByTest" :showDialog="isDialogVisible" @close="toggleDialog()" @save="saveNewQuestions" />

                    <v-row class="m-0 mt-12 mb-2 p-0 align-center justify-center justify-md-space-between" no-gutters>
                        <v-col class="p-0">
                            <v-row no-gutters justify="center" justify-md="start">
                                <v-col cols="12" md="auto" class="pr-0">
                                    <h2 class="text-center text-md-left">{{$t('gamification.test_questions')}}</h2>
                                </v-col>
                                <v-col cols="auto" class="pl-0">
                                    <InfoButton right>{{$t('gamification.questions_info')}}</InfoButton>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="auto" v-if="!test.activated">
                            <PrimaryButton @click="goToNew()">{{$t('gamification.assign_question')}}
                            </PrimaryButton>
                        </v-col>
                    </v-row>
                    <v-row class="justify-center justify-md-start" no-gutters v-if="questionsByTest.length > 0">
                        <v-col cols="auto">
                           <b class="highlight-text">{{questionsByTest.length}} {{questionWord}} {{pointsByTest}} {{pointWord}}</b>
                        </v-col>
                    </v-row>

                    <v-row
                        no-gutters
                    >
                        <v-col>
                            <draggable
                                class="mb-2"
                                v-show="questionsByTest.length !== 0"
                                :move="moved"
                                :list="questionsByTest"

                            >
                                    <v-row class="question" v-for="question in this.questionsByTest" :key="question.order">
                                        <v-card elevation="0" outlined class="question-card">
                                            <v-row justify="space-between">
                                                <v-col cols="8" md="9" class="pr-0">
                                                    <v-row no-gutters>
                                                        <v-col cols="auto" class="px-0">
                                                            <v-icon class="mr-sm-2">
                                                                drag_handle
                                                            </v-icon>
                                                        </v-col>
                                                        <v-col cols="8" class="px-0 questionText">
                                                            <span>{{question.localizedAttributes.cz.text}}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>

                                                <v-col v-if="!test.activated" cols="auto" class="pl-0">
                                                    <v-icon class="mr-4" color="#6D7278" @click="toggleEditQuestionDialog(question.id)">
                                                        edit
                                                    </v-icon>
                                                    <v-icon class="mr-2" color="red" @click="removeQuestionFromTest(question.id)">
                                                        delete_outline
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-card>

                                    </v-row>
                            </draggable>
                        </v-col>
                    </v-row>


                    <v-row class="justify-center justify-md-start" v-show="questionsByTest.length === 0">
                        <v-col cols="auto">
                            {{$t('gamification.no_questions')}}
                        </v-col>
                    </v-row>

                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-checkbox
                    :label="$t('gamification.show_correct_answers')"
                    @change="changeWatch"
                    v-model="test.showAnswers"
                    :disabled="test.activated"
                ></v-checkbox>
            </v-col>
        </v-row>
        <div v-if="!isNew">
            <v-row justify="space-between">
                <v-col cols="auto">
                    <v-row>
                        <v-col cols="auto" class="pr-0">
                            <h2>{{$t('gamification.users_heading')}}</h2>
                        </v-col>
                        <v-col cols="auto" class="pl-0">
                            <InfoButton right>{{$t('gamification.users_info')}}</InfoButton>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto" style="text-align: right;">
                    <LightButton>{{$t('entities.export')}}</LightButton>
                </v-col>
            </v-row>
            <v-row class="align-center justify-sm-center justify-md-start" no-gutters>
                <v-col cols="auto" class="pr-2">
                    <v-icon class="pr-0">search</v-icon>
                </v-col>
                <v-col cols="10" md="5" class="pl-0">
                    <Autocomplete
                        :label="$t('gamification.search_for_users')"
                        :items="filteredUsers.map(user => user.login)"
                        :value="searchedUser"
                        @change="setSearchedUser"
                        hide-details=true
                        :clearable=true
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-data-table
                    :headers="headers"
                    :items="filteredUsers"
                    :loading-text="$t('entities.loading')"
                >

                    <template v-slot:item.score="{item}">
                        <span>{{item.score}}/{{item.maxPoints}}</span>
                    </template>

                    <template v-slot:item.date="{item}">
                        <span>{{formatDateTime(item.date)}}</span>
                    </template>

                </v-data-table>
            </v-row>
        </div>


    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ModuleHeader from "@/components/layout/ModuleHeader";
import BackButton from "@/components/layout/BackButton";
import SaveButton from "@/components/buttons/SaveButton";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import Loading from "@/components/Loading";
import QuestionDialog from "@/components/gamification/QuestionDialog";
import {getLocalizedLanguages} from "@/localization/languages";
import draggable from 'vuedraggable'
import ItemNotFoundState from '@/components/states/ItemNotFoundState.vue';
import moment from "moment/moment";
import LightButton from "../../components/buttons/LightButton.vue";
import Autocomplete from "@/components/Autocomplete";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import CraftingQuestionDialog from "@/components/gamification/CraftingQuestionDialog.vue";
import InfoButton from "@/components/buttons/InfoButton";



export default {
    name: "TestDetail",

    components: {
        ModuleHeader,
        BackButton,
        SaveButton,
        PrimaryButton,
        Loading,
        QuestionDialog,
        draggable,
        ItemNotFoundState,
        LightButton,
        Autocomplete,
        ConfirmDialog,
        CraftingQuestionDialog,
        InfoButton
    },

    data() {
        return {
            required_rule: value => !!value || 'Povinné',
            isSaving: false,
            isDialogVisible: false,
            currentLanguage: "cz",
            currentQuestionLanguage: "cz",
            questionsByTest: [],
            pointsByTest: 0,
            questionsBySubject: [],
            questionsToDialog: [],
            subjectCategory: {cz: "", en: ""},
            testName: {cz: "", en: ""},
            subjectCategoryAlert: "error",
            testNameAlert: "error",
            testActivationAlert: "info",
            testQuestionsAlert: "error",
            usersResults: [],
            searchedUser: "",
            questionWord: "",
            qrDialog: false,
            selectedQuestion: null,
            selectedAnswer: null,
            showDeleteDialog: false,
            showCraftingQuestionDialog: false,
            pointWord: "",
            unsavedChanges: false,
            isLoading: false,
            headers: [
                {text: this.$t('gamification.login'), value: 'login'},
                { text: this.$t('gamification.scored_points'), value: 'score'},
                { text: this.$t('gamification.date_sent_test'), value: 'date'},
            ],
            notFound: false,
        }
    },

    async created() {
        this.isLoading = true
        try {
            await this.loadData()
            await this.fetchAllTests()
            await this.fetchAllQuestions()
            this.setQuestions()
            this.setPointsAndQuestions()
            await this.fetchSubjectById(this.subjectId)
            await this.fetchCategoriesBySubjectId(this.subjectId)

            this.checkAlerts()
            this.setSubjectCategory()
            this.isLoading = false

        } catch (e) {
            if (e.statusCode === 404) {
                this.notFound = true;
            } else {
                throw e;
            }
        }
    },

    watch: {
        testId() {
            this.loadData();
        },

        currentLanguage(curr) {
            if(!this.test.localizedAttributes[curr]) {
                this.test.localizedAttributes[curr] = {
                    name: "",
                }
            }
        },

        subjectCategory() {
            this.checkSubjectCategory()
        },

    },

    computed: {
        ...mapGetters('Tests', ['getTestById', 'didLoadTest', 'didLoadedTests', 'getEmptyTest']),
        ...mapGetters('Questions', ['didLoadedQuestions', 'getQuestionsBySubjectId', 'getQuestionById', 'getQuestionsByTestId']),
        ...mapGetters('Subjects', ['getSubjectById']),
        ...mapGetters('SubjectCategories', ['getCategoriesBySubjectId', 'getCategoryByName']),

        testId() {
            const urlId = this.$route.params.testId;
            return urlId === "new" ? null : Number(urlId);
        },

        subjectId() {
            return Number(this.$route.params.subjectId);
        },

        subjectName(){
          const subject = this.getSubjectById(this.subjectId)
          return subject ? {cz: subject.localizedAttributes.cz.name, en: subject.localizedAttributes.en.name} : ''
        },

        subjectCategories(){
            const categories = this.getCategoriesBySubjectId(this.subjectId)

            let objectOfCategoryNames = {cz: [], en: []}
            categories.forEach(category => {
              objectOfCategoryNames.cz.push(category.localizedAttributes.cz.name)
              objectOfCategoryNames.en.push(category.localizedAttributes.en.name)
            })


            return categories ? objectOfCategoryNames : ''
        },

        isNew() {
            return this.testId === null;
        },

        test() {
            if (this.isNew) {
                return this.getEmptyTest;
            } else if (this.getTestById(this.testId)) {
                return this.getTestById(this.testId);
            } else {
                return null;
            }
        },

        hasCzAttributesSet(){
            const localized = this.test.localizedAttributes;
            const czech = this.languages[0].value;

            return (
              localized &&
              localized[czech] &&
              localized[czech].name !== "" &&
              this.subjectCategory[czech]
            );
        },

        hasEnAttributesSet(){
            const localized = this.test.localizedAttributes;
            const english = this.languages[1].value;


            return (
              localized &&
              localized[english] &&
              localized[english].name !== "" &&
              this.subjectCategory[english]
            );
        },

        languages() {
            const langs = getLocalizedLanguages();
            const selectArr = [];
            Object.keys(langs).map((lng) => {
                selectArr.push({
                    'text': langs[lng],
                    'value': lng,
                });
            });
            return selectArr;
        },

        didLoadAll() {
            return (this.didLoadTest || this.isNew) && this.didLoadedTests && this.didLoadedQuestions;
        },

        getQuestionsToDialog() {
            return this.questionsToDialog;
        },

        filteredUsers() {
            let filteredItems = []
            for (let i = 0; i < this.test.userResults.length; i++) {
                filteredItems.push(this.test.userResults[i])
            }
            if (this.searchedUser) {
                return filteredItems.filter(user => user.login === this.searchedUser)
            } else {
                return filteredItems
            }
        },

        /**
         * Generates QR code data.
         * @returns {string}
         */
        showQrData() {
            let qrCodeToShowData = {
                testId: this.testId
            };
            return JSON.stringify(qrCodeToShowData);
        },

    },

      methods: {
        ...mapActions('leavePrevent', ['madeUnsavedChanges', 'didSaveChanges']),
        ...mapActions('Questions', ['fetchAllQuestions']),
        ...mapActions('Tests', ['fetchTestById', 'createTest', 'updateTest', 'deleteTestById', 'fetchAllTests', 'prepareNewTest']),
        ...mapActions('Subjects', ['fetchSubjectById']),
        ...mapActions('SubjectCategories', ['fetchCategoriesBySubjectId']),

        moved(evt) {
            this.questionsByTest = evt.relatedContext.list
        },

        async loadData() {
          if (!this.isNew) {
              await this.fetchTestById(this.testId);
              if (this.test) {
                  this.testName.cz = this.test.localizedAttributes.cz.name
                  this.testName.en = this.test.localizedAttributes.en.name
              }
              this.setQuestions();
          } else {
              this.prepareNewTest();
          }
        },

        setSubjectCategory() {
          if (!this.isNew) {
            const categories = this.getCategoriesBySubjectId(this.subjectId)
            const filteredCategory = categories.find(category => category.id === this.test.categoryId)
            this.subjectCategory.cz = filteredCategory.localizedAttributes.cz.name
            this.subjectCategory.en = filteredCategory.localizedAttributes.en.name
          }
        },

        setPointsAndQuestions() {
            this.pointsByTest = 0
            this.questionsByTest.map(question => {
                this.pointsByTest += question.points
            })
            this.questionWord = (this.questionsByTest.length === 1) ? this.$t('gamification.questions1') : (this.questionsByTest.length < 5) ? this.$t('gamification.questions2') : this.$t('gamification.questions3')
            this.pointWord = (this.pointsByTest === 1) ? this.$t('gamification.points1') : (this.pointsByTest < 5) ? this.$t('gamification.points2') : this.$t('gamification.points3');
        },

        setQuestions() {
            this.questionsByTest = this.getQuestionsByTestId(this.testId)
            this.test.questions.map(testQuestion => {
                this.questionsByTest.map(question => {
                    if (testQuestion.id === question.id) question.order = testQuestion.order
                })
            })
            this.questionsByTest = this.questionsByTest.sort((a, b) => a.order - b.order)
            this.setPointsAndQuestions()
            this.questionsBySubject = this.getQuestionsBySubjectId(this.subjectId)

            // return all questions that test doesn't have (but subject yes)
            this.questionsToDialog = this.questionsBySubject.filter(a => !this.questionsByTest.map(b => b.id).includes(a.id))
        },

        //Remove a question from test
        removeQuestionFromTest(questionId) {
            this.questionsByTest = this.questionsByTest.filter(question => question.id !== questionId)
            this.questionsToDialog = this.questionsBySubject.filter(a => !this.questionsByTest.map(b => b.id).includes(a.id))
            this.setPointsAndQuestions()
            this.checkTestQuestions()
        },

        //Opens question dialog from test
        toggleEditQuestionDialog(questionId) {
            this.selectedQuestion = this.getQuestionById(questionId)
            this.showCraftingQuestionDialog = true
        },

        //Assign questions to test
        saveNewQuestions(questionsFromDialog) {
            let index = this.questionsByTest.length - 1
            let lastOrder = this.questionsByTest.length !== 0 ? parseInt(this.questionsByTest[index].order) : 0

            questionsFromDialog.forEach(q => {
                lastOrder++
                q.order = lastOrder;
            })

            this.questionsByTest.push(...questionsFromDialog);

            this.questionsToDialog = this.questionsBySubject.filter(a => !this.questionsByTest.map(b => b.id).includes(a.id))
            this.setPointsAndQuestions()
            this.checkTestQuestions()
            this.toggleDialog()
            this.save()
        },

        async deleteTest() {
            await this.deleteTestById(this.testId)
            await this.$router.push({
                name: 'Gamification',
                params: {subjectId: this.subjectId, tab: 'tests'}
            });
        },

        async save() {
            if (this.$refs.form.validate()) {
                this.isSaving = true;

                this.questionsByTest.forEach(q => {
                    q.order = this.questionsByTest.indexOf(q) + 1;
                })

                let ordersAndIds = {};
                this.questionsByTest.forEach(q => ordersAndIds[q.order] = q.id);

                if (this.isNew) {
                    const test = this.test;
                    test.questions = ordersAndIds;
                    test.subjectId = this.subjectId;
                    this.test.id = await this.createTest({item: test});
                } else {
                    this.test.questions = ordersAndIds;
                    await this.updateTest({item: this.test});
                }

                this.didSaveChanges();

                if (this.isNew) {
                    await this.$router.push({
                        name: 'Gamification',
                        hash: '#Tests',
                        params: {subjectId: this.subjectId, name: this.subjectName}
                    });
                }
                this.isSaving = false;
            }
        },

        goToNew() {
            this.toggleDialog();
        },

        changeWatch() {
            this.madeUnsavedChanges();
        },

        changeTestNameWatch() {
            this.test.localizedAttributes.cz.name = this.testName.cz
            this.test.localizedAttributes.en.name = this.testName.en
            this.checkTestName()
            this.madeUnsavedChanges()
        },

        handleActivationAlert() {
            this.checkTestActivation()
        },

        toggleDialog() {
            this.isDialogVisible = !this.isDialogVisible
        },

        formatDateTime(timestamp) {
            return moment(timestamp).format('lll')
        },

        setSearchedUser(value) {
            this.searchedUser = value
        },

        setSubjectCategoryId() {
            this.checkSubjectCategory()
            this.test.categoryId = this.getCategoryByName(this.subjectCategory[this.currentLanguage], this.currentLanguage).id
        },

        checkSubjectCategory() {
            this.subjectCategoryAlert = "error"
            if (this.subjectCategory.cz && this.subjectCategory.en || this.test.categoryId) {
                this.subjectCategoryAlert = "success"
            }
        },

        checkTestName() {
            this.testNameAlert = "error"
            if (this.test.localizedAttributes.en.name && this.test.localizedAttributes.cz.name) {
                this.testNameAlert = "success"
            }
        },

        checkTestQuestions() {
            this.testQuestionsAlert = "error"
            if (this.questionsByTest.length > 0) {
                this.testQuestionsAlert = "success"
            }
        },

        checkTestActivation() {
            this.testActivationAlert = "info"
            if (this.test.activated) {
                this.testActivationAlert = "success"
            }
        },

        checkAlerts() {
            this.checkSubjectCategory()
            this.checkTestName()
            this.checkTestQuestions()
            this.checkTestActivation()
        },

        /**
        * Trigger download of QR code image.
        */
        downloadQRCode() {
          const download = document.createElement('a');
          download.href = this.$refs.qr_code.$el.src;
          download.download = this.test.localizedAttributes.cz.name + '.png';
          download.click()
        },

        toggleQrDialog() {
            this.qrDialog = !this.qrDialog
        }
    }
}
</script>

<style scoped>

.v-container {
    color: #3F4349;
}

.v-alert {
    font-size: 0.9em;
}

.small-desc {
    font-size: 0.8em;
    margin: 0;
    color: #6D7278;
}

.language-selector {
    height: 80px;
    padding: 0;
}

.question {
    height: 40px;
    margin: 10px 0;
}

.questionText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}

.maxWidth {
    max-width: 1200px;
    width: 100%;
    text-align: center;
    font-size: 1rem !important;
}

.activated-container {
    background-color: #F5F5F5;
    padding: 1.5vw;
}

.highlight-text {
    color: #78BE13;
}

.question-card {
    width: 100%;
    padding: 12px;
}

.centered-btn {
    margin: 0 auto;
    text-align: center;
}

</style>