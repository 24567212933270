import moment from "moment/moment";

export class Announcement {
    constructor() {
        let date = new Date;
        date.setHours(0, 0, 0, 0);

        this.id = undefined;
        this.localizedAttributes = {
            cz: {
                title: "",
                shortTitle: "",
                text: "",
                shortText: "",
                url: "",
            },
            en: {
                title: "",
                shortTitle: "",
                text: "",
                shortText: "",
                url: "",
            }
        };
        this.serviceConfiguration = undefined;
        this.blockParallel = false;
        this.isPermanent = false;
        this.preferredPosition = 'none';
        this.announcementTypeId = undefined;
        this.displayFrom = moment(date).unix();
        this.displayTo = undefined;
        this.displayings = []
    }
}