var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.didLoadData)?_c('Loading'):_c('v-container',{attrs:{"fluid":""}},[_c('BackButton',{attrs:{"target":{name: 'Beacons'}}},[_vm._v("Majáky")]),_c('ModuleHeader',[_c('RefreshButton',{on:{"click":_vm.refreshBeaconPurposes}},[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Účely majáků")])]),_c('PrimaryButton',{attrs:{"to":{name: 'BeaconPurposeDetail', params: {id: 'new'}}}},[_vm._v("+ Nový")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.dataTableHeaders,"items":_vm.purposes,"mobile-breakpoint":"0","header-props":{
            sortByText: 'Řadit podle'
          },"footer-props":{
            itemsPerPageText: 'Počet řádků na stránku',
            itemsPerPageAllText: 'Všechny',
            pageText: '{0}-{1} z {2}'
          },"sort-by":_vm.$query.sort,"sort-desc":_vm.$query.desc,"items-per-page":_vm.$query.rowsPerPage,"page":_vm.$query.page},on:{"update:sortBy":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.$query, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.$query, "desc", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.$query, "rowsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.$query, "rowsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.$query, "page", $event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Žádná dostupná data ")]},proxy:true},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('b',{staticClass:"pointer light-green--text",on:{"click":function($event){return _vm.goToDetail(item.id)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('DeleteIcon',{on:{"click":function($event){return _vm.attemptToDeleteBeaconPurpose(item)}}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }