<template>
  <Loading v-if="didLoadedPlaces !== true"/>
  <div id="map-container" v-else>
    <ModuleHeader class="no-margin">
      <v-row>
        <v-col cols="3" class=" pb-0">
          <v-select
              v-model="$query.buildingId"
              :items="buildingNamesWithExterior"
              label="Budova"
              color="light-green darken-1"
          ></v-select>
        </v-col>
        <v-col cols="2" class="pb-0">
          <v-select
              v-model="$query.floorId"
              :items="buildingFloors"
              :disabled="isExteriorSelected"
              label="Podlaží"
              color="light-green darken-1"
          ></v-select>
        </v-col>
      </v-row>
    </ModuleHeader>
    <MapWidget
        :places="mapData"
        :selectable="true"
        :center="mapCenter"
        @selectedObject="placeOnClick"
    ></MapWidget>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex';
import Loading from "../../components/Loading";
import ModuleHeader from "../../components/layout/ModuleHeader";

//Refactored
import MapWidget from "@/components/map/MapWidget";

export default {

  name: "MapInteractive",

  components: {
    ModuleHeader,
    Loading,
    MapWidget,
  },

  data() {
    return {
      mapData: [],
      buildingOutline: [],
    }
  },

  async mounted() {
    await this.fetchAllFloors()
    await this.fetchAllBuildingsIfNecessary()

    // exterior id provided
    if (this.isExteriorSelected) {
      await this.loadCorrectPlaces(null, null, 'true', null, null)

      // floor id provided
    } else if (this.floorId !== null) {
      await this.loadCorrectPlaces(this.floorId, null, null, null, null)

      // building id provided (without floor)
    } else if (this.buildingId !== null) {
      await this.loadBuildingFloors()
      if (this.building.defaultFloor !== null) this.autoSelectEntranceFloor()  // places are show thanks to triggering the floor watcher
      else this.setDidLoadedPlaces(true) // if the building doesn't have a default floor, don't load any places

      // invalid query, exterior selected
    } else {
      this.$query.buildingId = -1 // places are show thanks to triggering the floor watcher
    }

  },

  computed: {
    ...mapGetters('floors', ['getBuildingFloors']),
    ...mapGetters('buildings', ['getBuildings']),
    ...mapGetters('places', ['didLoadedPlaces', 'placesByFloor', 'getPlaces']),

    buildingNamesWithExterior() {
      let buildings = this.getBuildings.map(building => ({
        text: building.localizedAttributes.cz.name,
        value: building.id
      }));
      buildings.unshift(({text: "Exteriér", value: -1}));
      return buildings;
    },

    isExteriorSelected(){
      return this.buildingId === -1
    },

    buildingFloors() {
      return this.getBuildingFloors(this.buildingId).map(floor => ({
        text: floor.localizedAttributes.cz.name,
        value: floor.id,
      }));
    },

    building() {
      return this.getBuildings.find(building => building.id === this.buildingId);
    },

    buildingId(){
      // use this computed property for reading value of this.$query.buildingId for NaN -> null conversion
      // (this.$query.buildingId cannot store null value)
      return Number.isInteger(this.$query.buildingId) ? this.$query.buildingId : null
    },

    floorId(){
      // use this computed property for reading value of this.$query.floorId for NaN -> null conversion
      // (this.$query.floorId cannot store null value)
      return Number.isInteger(this.$query.floorId) ? this.$query.floorId : null
    },

    mapCenter() {
      if (this.building) {
        return this.building.outline;
      } else {
        return null;
      }
    },
  },

  watch: {
    '$route.query.buildingId': {
      async handler() {
        this.mapData = [];
        this.$query.floorId = null;

        if (this.isExteriorSelected) {
          this.buildingOutline = [];
          await this.loadCorrectPlaces(null, null, true, null, null)

        } else { // building
          await this.loadBuildingFloors()
          if (this.building.defaultFloor !== null) this.autoSelectEntranceFloor()  // places are show thanks to triggering the floor watcher
        }
      }
    },
    '$route.query.floorId' : {
      handler () {
        if (this.floorId !== null)
        this.loadCorrectPlaces(this.floorId, null, null, null, null)
      }
    }
  },

  methods: {
    ...mapActions('floors', ['fetchFloorById', 'clearFloors', 'fetchAllFloors']),
    ...mapActions('places', ['fetchFilteredPlaces', 'fetchFloor']),
    ...mapActions('buildings', ['fetchAllBuildingsIfNecessary']),
    ...mapMutations('places', ['setDidLoadedPlaces']),

    async loadBuildingFloors() {
      this.clearFloors();
      const building = this.building;
      if (building) {
        const floorPromises = building.floorIds.map(async id => await this.fetchFloorById(id));
        await Promise.all(floorPromises);
        this.buildingOutline = building.outline;
      }
    },

    async loadCorrectPlaces(floorId = null, buildingId = null, exterior = null, language = null, placeName = null){
      await this.fetchFilteredPlaces({floorId, buildingId, exterior, language, placeName})
      this.showPlaces()
    },

    autoSelectEntranceFloor() {
      this.$query.floorId = this.building.defaultFloor
    },

    showPlaces() {
      this.mapData = this.placesByFloor(this.floorId).map(p => ({
        id: p.id,
        type: p.type,
        position: p.position,
        searchable: p.searchable,
        name: p.localizedAttributes.cz.name,
      }))
    },

    placeOnClick(place) {
      if (!place._popup) {
        this.createPopup(place);
        place.openPopup();
      }
    },

    createPopup(mapObject) {
      const type = mapObject.type;
      const place = this.placeById(mapObject.id);
      const route = this.$router.resolve({name: 'mapDetail', params: {type: 'place', id: mapObject.id}});

      let html = `<h4>${place.localizedAttributes.cz.name || type}</h4>`;

      if (place) {
        html += `<div>${type}</div>`;
      }

      html += `<a href="${route.href}" target="_blank" class="popupElement">Detail objektu</a>`;

      mapObject.bindPopup(html);
    },

  },
}
</script>

<style scoped>
.language-selector {
  height: 80px;
}

.language-selector > .v-input {
  width: 140px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 320ms;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.map-component {
  z-index: 1;
}

#map-widget {
  height: 80vh;
  z-index: 1;
}

.hidden {
  visibility: hidden;
}

</style>
