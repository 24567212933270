import {get,} from '@/services/api'

const state = {
    didLoadedStudyFields: false,
    studyFields: [],
    countDownTime: null,
    countDownTimerId: null,
    studyDegreeTypes: [
        {text: 'Bachelor', value: "Bachelor"},
        {text: 'Master', value: "Master"},
        {text: 'Doctoral', value: "Doctoral"},
        {text: 'Other', value: "Other"},
    ],
    studyFormTypes: [
        {text: 'weekly_type', value: "weekly_type"},
        {text: 'daily_type', value: "daily_type"},
        {text: 'other_type', value: "other_type"},
    ]
};
const getters = {
    didLoadedStudyFields: state => state.didLoadedStudyFields,

    getCountDownTime: state => state.countDownTime,

    getCountDownTimerId: state => state.countDownTimerId,

    getStudyFields: state => state.studyFields,

    getStudyDegreeTypes: state => state.studyDegreeTypes,

    getStudyFormTypes: state => state.studyFormTypes,

    getStudyField: state => id => {
        return state.studyFields.find(studyField => studyField.id === id);
    },

    areStudyFieldsFilled: state => {
        return state.studyFields.length !== 0;
    }
};

const actions = {

    //Fetch ALL
    async fetchStudyFields({commit}) {
        commit('setDidLoadedStudyFields', false);
        await get('study_department/study_fields/')
            .then((response) => {
                commit('setStudyFields', response.data.items);
                commit('setDidLoadedStudyFields', true);
            });
    },

    setStudyFieldsStorage({commit}, value) {
        commit('setStudyFields', value);
    },

    setCountDownTime({commit}, value) {
        commit('setCountDownTime', value);
    },

    setCountDownTimerId({commit}, value) {
        commit('setCountDownTimerId', value);
    },
};

const mutations = {
    setDidLoadedStudyFields: (state, value) => (state.didLoadedStudyFields = value),
    setStudyFields: (state, studyFields) => (state.studyFields = studyFields),
    setCountDownTime: (state, value) => (state.countDownTime = value),
    setCountDownTimerId: (state, value) => (state.countDownTimerId = value),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
