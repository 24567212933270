<template>
  <div style="width: 100%;">
    <v-dialog v-model="exportInProgress" persistent style="z-index: 9999;">
      <div class="dialog-content" >
        <h2>{{ $t('map.generator.map_is_generating') }}</h2>
        <Loading class="mb-2"/>
      </div>

    </v-dialog>
    <div class="row">
      <div class="col-md-4 pb-0">
        <v-alert type="info">
          {{ $t('map.generator.alert') }}
        </v-alert>

        <v-row class="mt-2">
          <v-col class="col-3 col-md-12 py-0">
            <v-select
              id="building-select"
              v-model="$query.selectedBuilding"
              :items="buildingNamesWithExterior"
              :label=" $t('map.generator.building_select') "
              color="light-green darken-1"
            />
          </v-col>
          <v-col class="col-3 col-md-12 py-0">
            <v-select
              id="floor-select"
              v-model="$query.selectedFloor"
              :items="buildingFloors"
              :disabled="disabledFloorSelect"
              :menu-props="{maxHeight: 'unset'}"
              :label=" $t('map.generator.floor_select') "
              color="light-green darken-1"
            />
          </v-col>
          <v-col class="col-6 col-md-12 py-0">
            <v-autocomplete
              id="place-select"
              v-model="$query.selectedPlace"
              :items="placesNames"
              :disabled="disabledPlaceSelect"
              :label=" $t('map.generator.place_select') "
              color="light-green darken-1"
              clearable
            />
          </v-col>
        </v-row>

        <div class="mt-5" v-if="existingPlaceFromQuery && center">

          <div class="iframe-copy">
            <v-textarea
              id="map-link"
              outlined
              auto-grow
              hide-details
              rows="2"
              :label=" $t('map.generator.link_to_map') "
              :value="linkToMap"
              class="mr-2"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="copyLinkToMap" v-on="on" v-bind="attrs" class="ml-2">content_copy</v-icon>
              </template>
              <span>{{ $t('map.generator.copy') }}</span>
            </v-tooltip>
          </div>
          <LightButton id="btn-for-html" class="my-4" @click="() => showCode = !showCode">{{ showCode ? this.$t('map.generator.hide_HTML_code') : this.$t('map.generator.show_HTML_code') }}</LightButton>
          <div class="iframe-copy" v-if="isSizeValid && showCode">
            <v-textarea
              id="iframe-code"
              outlined
              rows="2"
              auto-grow
              :label=" $t('map.generator.iframe_code') "
              :value="iframeCode"
              class="mr-2"
            />

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="copyIframeCode" v-on="on" class="ml-2" v-bind="attrs">content_copy</v-icon>
              </template>
              <span>{{ $t('map.generator.copy') }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <MapWidget
          :places="mapData"
          :selected="selectedPlace !== null ? [selectedPlace] : []"
          :legend="mapLegend"
          :center="mapCenter"
          @changeCenter="c => center = c"
          @zoom="z => zoom = z"
          @selectedObject="selectPlaceFromMap"
          ref="mapWidgetRef"
        />
        <v-row class="justify-end mr-3 mt-4 align-center" no-gutters>
          <v-col cols="auto" class="pa-1 pr-3">
            <v-menu left :close-on-content-click="false" v-model="exportDialogVisible">
              <template v-slot:activator="{on: exportMenu, attrs}">
                <v-btn @click="toggleExportDialog()" outlined style="backgorund-color: #9d9d9d;" v-on="{...exportMenu}" v-bind="attrs">
                  {{ $t('map.generator.save_as_image') }}
                </v-btn>
              </template>
              <ExportSize
                @export="exportPng"
                @closeDialog="toggleExportDialog()"
              />
            </v-menu>
          </v-col>
          <v-col v-if="existingPlaceFromQuery" cols="auto">
            <PrimaryButton @click="openInNewTab(linkToMap)">{{ $t('map.generator.open_map_tab') }}</PrimaryButton>
          </v-col>
        </v-row>
      </div>

    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Loading from "../../components/Loading";
import ExportSize from "../../components/map/ChooseExportSize";

//Refactored
import MapWidget from "../../components/map/MapWidget";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import {isInEnglish} from "@/services/Map/MapWidget";
import LightButton from "@/components/buttons/LightButton";

export default {

  name: "EmbeddedMapGenerator",

  components: {
    Loading,
    MapWidget,
    ExportSize,
    PrimaryButton,
    LightButton
  },

  data() {
    return {
      notNegative: value => parseInt(value, 10) >= 0 || this.$t('map.generator.not_negative'),
      mapData: [],
      placesNames: [],
      center: null,
      zoom: 16,
      exportInProgress: false,
      exportDialogVisible: false,
      iframeHeight: 300,
      iframeWidth: 500,
      showCode: false,
    }
  },

  async mounted() {
    if (isInEnglish()) this.$i18n.locale = 'en';
    await this.fetchAllBuildings()
    await  this.loadBuildingFloors()

    // if there non-existing building and is not exterior, set default = exterior
    if (!this.existingBuildingFromQuery && !this.exteriorSelected) {
      this.$query.selectedBuilding = -1
    }

    if (this.exteriorSelected) {
      this.$query.selectedFloor = null
      await this.fetchPlacesForExterior()

    } else { // building

      if (this.selectedFloor === null){
        await this.autoSelectEntranceFloor()
      } else {
        await this.fetchPlacesByFloor()
      }
    }
    this.showFloorPlaces()

    // workaround to set map-widget size to 100% after loading, but then make it resizable with iFrameHeight and iFrameWidth
    this.iframeHeight = document.getElementById("map-widget").offsetHeight;
    this.iframeWidth  = document.getElementById("map-widget").offsetWidth;

  },

  computed: {
    ...mapGetters('floors', ['getBuildingFloors']),
    ...mapGetters('buildings', ['isBuildingLoading', 'getBuildings', 'getSingleBuilding']),
    ...mapGetters('places', ['placesByFloor', 'getTypeName', 'placeById', 'getPlaces', 'didLoadedPlaces']),

    buildingNamesWithExterior() {
      let buildings = this.getBuildings.map(building => ({
        text: building.localizedAttributes.cz.name,
        value: building.id
      }));
      buildings.unshift(({text: this.$t('map.defaults.exterior'), value: -1}));
      return buildings;
    },

    isSizeValid() {
      return parseInt(this.iframeWidth, 10) >= 0 && parseInt(this.iframeHeight, 10) >= 0
    },
    
    exteriorSelected(){
      return this.$query.selectedBuilding === -1
    },

    place() {
      return this.placeById(this.selectedPlace);
    },

    existingPlaceFromQuery(){
      return this.selectedPlace !== null && this.place
    },

    existingBuildingFromQuery(){
      return this.selectedBuilding !== null && this.building
    },

    existingFloorFromQuery(){
      return this.selectedFloor !== null && this.floor
    },

    floor(){
      return this.buildingFloors.find(f => f.value === this.selectedFloor)
    },

    selectedPlace(){
      // use this computed property for reading value of this.$query.selectedPlace for NaN -> null conversion
      // (this.$query.selectedPlace cannot store null value)
      return Number.isInteger(this.$query.selectedPlace) ? this.$query.selectedPlace : null
    },

    selectedFloor(){
      // use this computed property for reading value of this.$query.selectedPlace for NaN -> null conversion
      // (this.$query.selectedFloor cannot store null value)
      return Number.isInteger(this.$query.selectedFloor) ? this.$query.selectedFloor : null
    },

    selectedBuilding(){
      // use this computed property for reading value of this.$query.selectedPlace for NaN -> null conversion
      // (this.$query.selectedBuilding cannot store null value)
      return Number.isInteger(this.$query.selectedBuilding) ? this.$query.selectedBuilding : null
    },

    building() {
      return this.getBuildings.find(building => building.id === this.selectedBuilding);
    },

    buildingFloors() {
      return this.getBuildingFloors(this.selectedBuilding).map(floor => ({
        text: floor.localizedAttributes.cz.name,
        value: floor.id,
      }))
    },

    hasFloors() {
      return this.buildingFloors.length > 0;
    },

    linkToMap() {
      return `${window.location.protocol}//${window.location.host}/mapwidget/embed?` +
          `building=${this.selectedBuilding}&floor=${this.selectedFloor}&place=${this.selectedPlace}&` +
          `center=${this.center.lat.toFixed(6) },${this.center.lon.toFixed(6) }&zoom=${Math.floor(this.zoom)}`;
    },

    iframeCode() {
      return `<iframe width="${this.iframeWidth}" height="${this.iframeHeight}" src="${this.linkToMap}"/>`;
    },

    disabledFloorSelect() {
      return  this.selectedBuilding === null || !this.hasFloors || this.exteriorSelected
    },

    disabledPlaceSelect() {
      return this.selectedFloor === null && this.hasFloors;
    },

    mapCenter() {
      // provided place id in query and places hasn't loaded yet
      if (this.selectedPlace !== null && !this.didLoadedPlaces) {
        return null
      }

      // provided valid place id
      if (this.existingPlaceFromQuery && this.place) {
        return this.place.position
      }

      // provided non-existing place id
      if (!this.existingPlaceFromQuery  && this.building) {
        return this.building.outline
      }

      // if floor id is provided, use building outline
      if (this.selectedFloor !== null && this.building) {
        return this.building.outline
      }
      
      return null

    },

    mapLegend() {
      let legend = null

      if (this.exteriorSelected) return

      if (this.building) {
        legend = this.$t('map.legend.building_name', { building: this.building.localizedAttributes.cz.name })
      }

      if (this.existingFloorFromQuery){
        legend += this.$t('map.legend.floor_name', { floor: this.floor.text })
      }

      return legend;
    },

    currentLanguage() {
      return isInEnglish() ? 'en' : 'cz';
    }

  },

  watch: {
    '$route.query.selectedBuilding': {
      async handler() {
        this.mapData = []
        this.placesNames = []
        this.$query.selectedFloor = null
        this.$query.selectedPlace = null
        this.clearFloors();

        if (this.exteriorSelected) {
          await this.fetchPlacesForExterior()
          this.showFloorPlaces();

        } else { // building
          this.loadBuildingFloors();
          if (this.selectedFloor === null){
            await this.autoSelectEntranceFloor()
            // places are shown thanks to watcher for selectedFloor
          }
        }
      }
    },

    '$route.query.selectedFloor' : {
      async handler() {
        this.$query.selectedPlace = null
        await this.fetchPlacesByFloor()
        this.showFloorPlaces();
      },
    },
  },

  methods: {
    ...mapActions('floors', ['fetchFloorById', 'clearFloors']),
    ...mapActions('buildings', ['fetchAllBuildings']),
    ...mapActions('places', ['fetchFilteredPlaces', 'fetchFloor']),

    async fetchPlacesByFloor(){
      if (this.selectedFloor !== null) {
        await this.fetchFilteredPlaces({
          floorId: this.selectedFloor,
          buildingId: null,
          exterior: null,
          language: null,
          placeName: null
        });
      }

    },

    async fetchPlacesForExterior(){
      await this.fetchFilteredPlaces({
        floorId: null,
        buildingId: null,
        exterior: true,
        language: null,
        placeName: null
      });
    },

    selectPlaceFromMap(mapObject) {
      this.$query.selectedPlace = mapObject.id;
    },

    loadBuildingFloors() { // items for selection
      if (this.building) {
        this.building.floorIds.map(floor => this.fetchFloorById(floor));
      }
    },

    async autoSelectEntranceFloor() {
      this.$query.selectedFloor = this.building.defaultFloor;
    },

    invalidateSize() {
      if (this.$refs.mapWidgetRef) {
        this.$refs.mapWidgetRef.map.invalidateSize();
      }
    },

    showFloorPlaces() {
      this.mapData = this.placesByFloor(this.selectedFloor).map(o => ({
        id: o.id,
        type: o.type,
        position: o.position,
        searchable: o.searchable,
        name: o.localizedAttributes[this.currentLanguage]?.nickname || o.localizedAttributes[this.currentLanguage]?.name,
      }));

      const placesNames = this.mapData.filter(p => p.searchable && p.name).map(p => ({
        value: p.id,
        text: p.name
      }));
      placesNames.sort((a, b) => a.text.localeCompare(b.text));
      this.placesNames = placesNames;
    },

    async copyIframeCode() {
      const html = this.iframeCode;
      await navigator.clipboard.writeText(html);
    },

    async copyLinkToMap() {
      const link = this.linkToMap;
      await navigator.clipboard.writeText(link);
    },

    toggleExportDialog() {
      this.exportDialogVisible = !this.exportDialogVisible;
    },

    exportPng(size = 0) {
      this.toggleExportDialog();
      this.$refs.mapWidgetRef.exportPng(size);
      this.exportInProgress = true;
      this.exportTimer = setInterval(() => {

        if (document.getElementsByClassName('epHolder') &&
            document.getElementsByClassName('epHolder')[0]) {

          setTimeout(() => {
            this.exportInProgress = false;
          }, 1500);
          clearInterval(this.exportTimer);
        }
      }, 1000)
    },

    openInNewTab(link) {
      window.open(link, '_blank', 'noreferrer');
    },

  },

}
</script>

<style scoped>
.content {
  padding: 1.5rem 4rem 2rem 4rem;
}

.map-container {
  margin: 0 auto;
  width: 100%;
  max-width: 850px;
  display: flex;
  flex-direction: column;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 320ms;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.map-component {
  z-index: 1;
}

#map-widget {
  z-index: 1;
  height: auto;
  flex-grow: 1;
  height: 78vh;
  margin: 0;
  border-radius: 8px;
}

.iframe-copy {
  display: flex;
  align-items: flex-start;
}

.map-printing {
  position: relative;
  top: 60px;
}

.dialog-content {
  background-color: whitesmoke;
  text-align: center;
  height: 90vh;
  padding-top: 35vh;
}

</style>
