<template>
    <div class="map-places-table">
            <v-data-table
              :headers="headers"
              :items="getBuildings"
              :loading="isBuildingLoading"
              no-data-text = "Žádné údaje"
              :custom-sort="customSort"
              class="d-none d-sm-block"
              :sort-by.sync="$query.sort"
              :sort-desc.sync="$query.desc"
              :items-per-page.sync="$query.rowsPerPage"
              :page.sync="$query.page"
            >
            <template v-slot:item.id="{ item }">
                <span class="pointer" @click="goToDetail(item.id)">{{ item.id }}</span>
            </template>
            <template v-slot:item.name="{ item }">
                <strong class="text-xs-left pointer light-green--text"  @click="goToDetail(item.id)">{{ item.localizedAttributes.cz.name }}</strong>
            </template>
            <template v-slot:item.description="{ item }">
                {{ item.localizedAttributes.cz.description | ellipsis(60) }}
            </template>
            </v-data-table>

            <!-- ONLY FOR SMALLER SCREENS -->
            <v-data-iterator
                :items="getBuildings"
                :hide-default-footer="true"
                :disable-pagination="true"
                :hide-default-header="false"
                class="d-block d-sm-none"
                :sort-by.sync="$query.sort"
                :sort-desc.sync="$query.desc"
                :items-per-page.sync="$query.rowsPerPage"
                :page.sync="$query.page"
            >
                <template v-slot:no-data>
                        Žádná dostupná data
                </template>
                <template v-slot:default="props">
                <v-row>
                <v-col v-for="item in props.items" class="pt-0" :key="item.id" cols="12">
                    <mobile-data-table-row
                        @click="goToDetail(item.id)"
                    >
                        <template v-slot:number>{{ item.id }}</template>
                        <template v-slot:title>{{ item.localizedAttributes.cz.name }}</template>
                        <template v-slot:subtitle1> {{ item.localizedAttributes.cz.description }}</template>   
                    </mobile-data-table-row>
                </v-col>
                </v-row>
            </template>
        </v-data-iterator>
    </div>
</template>

<script>
    import MobileDataTableRow from '../../components/layout/MobileDataTableRow.vue';

    import {mapActions, mapGetters} from 'vuex';
    export default {
        name: "Buildings",

        components: {
            MobileDataTableRow
        },

        data() {
            return {
              headers: [
                  {
                      text: 'Číslo',
                      align: 'left',
                      sortable: true,
                      value: 'id',
                      width: '5%'
                  },
                  {
                      text: 'Název',
                      align: 'left',
                      sortable: true,
                      value: 'name',
                      width: '10%'
                  },
                  {
                      text: 'Popis',
                      align: 'left',
                      sortable: true,
                      value: 'description',
                      width: '50%'
                  },
              ],
            }
        },

        mounted(){
          if(this.getBuildings.length === 0 ){
            this.fetchAllBuildings();
          }
        },

        computed: {
          ...mapGetters('buildings', ['getBuildings', 'isBuildingLoading']),
        },

        methods: {
            ...mapActions('buildings',['fetchAllBuildings','deleteBuilding']),
            
            goToDetail( id ) {
                this.$router.push({name: 'mapDetail', params: {type:"building", id:id}});
            },
            comp(a, b){
                return ('' + a).localeCompare(b);
            },
            customSort(items, index, isDesc) {
                items.sort((a, b) => {
                    if (index[0] === "name") {
                        if (!isDesc[0]) {
                            return this.comp(a.localizedAttributes.cz.name, b.localizedAttributes.cz.name);
                        } else {
                            return this.comp(b.localizedAttributes.cz.name, a.localizedAttributes.cz.name);
                        }
                    } else if (index[0] === "description") {
                        if (!isDesc[0]) {
                            return this.comp(a.localizedAttributes.cz.description, b.localizedAttributes.cz.description);
                        } else {
                            return this.comp(b.localizedAttributes.cz.description, a.localizedAttributes.cz.description);
                        }
                    } else if (index[0] === "floors") {
                        if (!isDesc[0]) {
                            return this.comp(a.floorIds.length, b.floorIds.length);
                        } else {
                            return this.comp(b.floorIds.length, a.floorIds.length);
                        }
                    } else {
                        if (!isDesc) {
                            return a[index] < b[index] ? -1 : 1;
                        } else {
                            return b[index] < a[index] ? -1 : 1;
                        }
                    }
                });
                return items;
            }
        },

    }
</script>

<style scoped>
.map-places-table{
    margin-top:1rem;
    width:100%;
}
.map-places-table a {
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none;
    font-size: 1.2rem;
}

.map-places-table tr {
    cursor: pointer;
}

.map-places-table >>> .v-progress-linear div{
    background-color: #7BB916 !important;
}

#deleteCard{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 5px 1px -2px rgba(0, 0, 0, 0.2),
                0px 5px 2px 0px rgba(0, 0, 0, 0.14),
                0px 5px 5px 0px rgba(0, 0, 0, 0.12);
}

.actionsDetails{
    background: rgba(0, 0, 0, 0.45) !important;
    color: white !important;
}

.map-places-table >>> .v-data-table__checkbox i{
    color: rgba(0,0,0,.48);
}
</style>
