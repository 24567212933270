<template>
  <div class="service-status d-flex" :style="{'--color': color}" v-bind:title="description || ''">
    <div class="service-status-icon mr-2" />
    <div class="service-status-text">
      {{ text.toUpperCase() }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceStatus",

  props: {
    color: String,
    text: String,
    description: String
  }
}
</script>

<style scoped>
  .service-status {
    white-space: nowrap;
    align-items: center;
  }

  .service-status-icon {
    width: 0.55rem;
    height: 0.55rem;
    background-color: var(--color);
    border-radius: 0.55rem;
  }

  .service-status-text {
    color: var(--color);
    font-size: 0.75rem;
  }
</style>
