export const config = {
    env: process.env.VUE_APP_ENV,
    serverUrl: process.env.VUE_APP_SERVER_URL,
    defaultLanguage: 'cz',
    displaysUrl: process.env.VUE_APP_DISPLAYS_SERVER_URL,

    authServer: {
        url: process.env.VUE_APP_AUTH_SERVER_URL,
        realm: process.env.VUE_APP_AUTH_REALM,
        clientId: 'mymendelu-web-administration',
    },

    bitlyToken: 'a87493d86e4b7cc0d95a23963f27f7a949ccbd50',
    mapboxToken: 'pk.eyJ1IjoibXltZW5kZWx1IiwiYSI6ImNra3k5amNzZTVhcmkyb3F0bzFnZWZsZTQifQ.SaZ2oxJmKgaBqr0jEh3s0A', // account: mymendelu

    firebase: {
        config: process.env.VUE_APP_FIREBASE_CONFIG ? JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG) : null, // the config is also used in cypress where env vars are not loaded, so there must be a condition to prevent an exception when parsing "undefined" as JSON.
        vapidKey: process.env.VUE_APP_FIREBASE_VAPID,
    },
};

export const displaysMaxValues = {
    announcementType: {
        name: 30,
        color: 7,
        iconName: 255
    },
    announcement: {
        title: 35,
        shortTitle: 20,
        text: 255,
        shortText: 105,
        url: 38,
        image: 100000000
    },
    display: {
        name: 90,
        url: 255,
        maxParallels: [ 1, 2, 3, 4 ],
        refreshPeriod: {
            max: 3600,
            min: 1
        }
    },
};

export const videoTypes = ['video/mp4', 'video/x-msvideo', 'video/x-matroska'];
export const videoPostfixes = ['.mp4', '.avi', '.mkv'];

export const mendeluColors = [
    { color: '#80CC16', label: 'MENDELU' },
    { color: '#CE9700', label: 'AF' },
    { color: '#0F8040', label: 'LDF' },
    { color: '#BF0D00', label: 'ZF' },
    { color: '#366AD9', label: 'PEF' },
    { color: '#00AAB4', label: 'ICV' },
    { color: '#BF007C', label: 'FRRMS' }
];

export const PUBLIC_TRANSPORT_SERVICE = 'PublicTransportService';
export const STUDY_DEPARTMENT_CALL_IN = 'StudyDepartmentCallIn';
export const EVENT_PLAN_SERVICE = 'EventPlanService';
export const LESSON_PLAN_SERVICE = 'LessonPlanService';
export const FULL_SCREEN_IMAGE_SERVICE = 'FullScreenImageService';
export const FOOD_SERVICE = 'FoodService';

export const displaysServices = [
    {
        id: STUDY_DEPARTMENT_CALL_IN,
        name: 'Vyvolávací systém studijního oddělení'
    },
    {
        id: EVENT_PLAN_SERVICE,
        name: 'Harmonogram'
    },
    {
        id: LESSON_PLAN_SERVICE,
        name: 'Plán výuky'
    },
    {
        id: PUBLIC_TRANSPORT_SERVICE,
        name: 'Odjezdy linek MHD'
    },
    {
        id: FULL_SCREEN_IMAGE_SERVICE,
        name: 'Multimédia na celou obrazovku'
    },
    {
        id: FOOD_SERVICE,
        name: 'Menza'
    }
]

// evaluated attributes: short-title, text, url, images, events, stops, facility
export const announcementAttributesByType = {
    null: ['short-title', 'text', 'url', 'images'],
    [STUDY_DEPARTMENT_CALL_IN]: ['short-title'],
    [EVENT_PLAN_SERVICE]: ['short-title', 'events'],
    [LESSON_PLAN_SERVICE]: ['short-title'],
    [PUBLIC_TRANSPORT_SERVICE]: ['short-title', 'stops'],
    [FULL_SCREEN_IMAGE_SERVICE]: ['short-title', 'images'],
    [FOOD_SERVICE]: ['facility']
};
