import {get} from '@/services/api'

const state = {
    placeSoftware: [],
    softwareCategories: [],
};

const getters = {
    placeSoftware: state => state.placeSoftware,
    categoryNameById: state => id => state.softwareCategories.find(category => category.id === id)
};

const actions = {
    async fetchSoftwareCategories({commit}) {
        const response = await get('iot/software/categories/')
        const categories = response.data.items
        commit('setSoftwareCategories', categories)
    },

    async fetchSoftwareById({commit}, id) {
        const response = await get(`iot/software/?placeId=${id}`)
        const placeSoftware = response.data.items
        commit('setPlaceSoftware', placeSoftware)
    },

};

const mutations = {
    setSoftwareCategories: (state, softwareCategories) => (state.softwareCategories = softwareCategories),
    setPlaceSoftware: (state, placeSoftware) => (state.placeSoftware = placeSoftware),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}