<template>
  <!-- Item not found -->
  <ItemNotFoundState v-if="objectNotFound" :objectName="'Oznámení'">
    <template #backButton>
      <BackButton :target="{name: 'announcements'}">Oznámení</BackButton>
    </template>
  </ItemNotFoundState>
  <Loading v-else-if="!didLoadData"/>
  <div v-else>
    <!-- Module header -->
    <BackButton :target="{name: 'announcements'}">Oznámení</BackButton>
    <ModuleHeader>
      <div id="header-title">
        <h2>{{`${isNew ? 'Přidání' : 'Úprava'} oznámení`}}</h2>
      </div>
      <v-spacer/>
      <SaveButton
        id="save-button"
        class="ml-5 mb-6"
        :disabled="!isFormValid || !isTextValid || selectedLanguages.length === 0 || ((hasAttribute('events') || hasAttribute('stops')) && !isServiceConfigValid)"
        :is-saving="isSaving"
        @click="saveDetail"/>
    </ModuleHeader>
    <!-- Announcement preview dialog -->
    <v-dialog id="preview-modal" v-model="dialog" width="fit-content">
      <v-card>
        <v-card-title>
          <span>Náhled oznámení</span>
          <v-spacer></v-spacer>
          <v-btn id="close-preview-btn" icon @click="() => dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <AnnouncementPreview
            id="announcement-preview"
            ref="previewDialog"
            :announcement="announcement"
            :announcementImagePath="previewImagePath"
            :announcementImageIsVideo="announcementImageIsVideo"
            :blockParallel="announcement.blockParallel"
            :getShortText="getShortText"
            :getShortTitle="getShortTitle"
            :initialLanguage="previewLanguage"
            :initialOrientation="previewOrientation"
            :selectedAnnouncementType="selectedAnnouncementType"
            :selectedLanguages="selectedLanguages"
            :textLength="textLength"
            @languageChanged="togglePreviewLanguage"
            @orientationChanged="togglePreviewOrientation"
            inDialog
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Announcement detail -->
    <h5 class="mt-8 mb-5">Obsah oznámení</h5>
    <v-form @submit.prevent="save" ref="form" v-model="isFormValid">
      <div v-bind:class="{ grid: !wrapPreview, 'grid-sm': wrapPreview }">
        <div class="content v-data-table v-data-table--fixed-header pl-0 pr-2 pt-2 pb-0 ">
          <div class="table v-data-table__wrapper">
            <table>
              <tbody>
                <tr>
                  <th scope="row" class="table-header">Typ oznámení</th>
                  <td>
                    <AnnouncementTypeSelect
                      :rules="[required_rule]"
                      :announcementTypes="getAnnouncementTypes"
                      :value="announcement.announcementTypeId"
                      v-on:change="changeAnnouncementType"
                    />
                  </td>
                </tr>
                <tr v-if="hasAttribute('stops') && selectedStops.length <= 1">
                  <th scope="row" class="table-header"></th>
                  <td>
                    <v-checkbox
                      id="hide-stop-column"
                      v-model="hideStopColumn"
                      :value="!hideStopColumn"
                      @change="setHideColumnConfig"
                      label="Nezobrazovat sloupec s názvem zastávky">
                    </v-checkbox>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="table-header">Jazyk oznámení</th>
                  <td class="d-flex flex-row mb-3">
                    <AnnouncementLanguageSelect 
                      :initialValues="selectedLanguages" 
                      v-on:change="(values) => {
                        selectedLanguages = values;
                        onChange();  
                      }"
                    />
                  </td>
                </tr>
                <template v-for="language in selectedLanguages">
                  <tr v-if="!hideStopColumn" :key="`title-${language}`">
                    <th scope="row" class="table-header">Titulek ({{language.toUpperCase()}})</th>
                    <td>
                      <v-text-field
                        :id="`announcement-title-${language}`"
                        :rules="[required_rule]"
                        :maxlength="displaysConstants.announcement.title"
                        v-model="announcement.localizedAttributes[language].title"
                        :label="`${announcement.localizedAttributes[language].title.length}/${displaysConstants.announcement.title} znaků`"
                        @change="onChange">
                      </v-text-field>
                    </td>
                  </tr>
                  <tr v-if="!hideStopColumn && hasAttribute('short-title') && announcement.localizedAttributes[language].title.length > displaysConstants.announcement.shortTitle" :key="`s-title-${language}`">
                    <th scope="row" class="table-header">Zkrácený titulek ({{language.toUpperCase()}}) <InfoButton right small>Zkrácená verze titulku je využívána při paralelním zobrazování několika oznámení na jedné obrazovce.</InfoButton></th>
                    <td>
                      <v-text-field
                        :id="`announcement-short-title-${language}`"
                        :rules="[required_rule]" :maxlength="displaysConstants.announcement.shortTitle"
                        v-model="announcement.localizedAttributes[language].shortTitle"
                        :label="`${announcement.localizedAttributes[language].shortTitle ? announcement.localizedAttributes[language].shortTitle.length : 0}/${displaysConstants.announcement.shortTitle} znaků`"
                        @change="onChange">
                      </v-text-field>
                    </td>
                  </tr>
                </template>
                <template v-for="language in selectedLanguages">
                  <tr v-if="hasAttribute('text')" :key="`text-${language}`">
                    <th scope="row" class="table-header">Text ({{language.toUpperCase()}})</th>
                    <td>
                      <tiptap-vuetify
                        :id="`announcement-text-${language}`"
                        v-model="announcement.localizedAttributes[language].text"
                        :extensions="extensions"
                        :card-props="{ outlined: true }"
                        @keydown="madeUnsavedChanges">
                      </tiptap-vuetify>
                      <div class="tiptap-validation" v-bind:class="{ 'validation-error': textLength(language) > displaysConstants.announcement.text }">
                        {{`${textLength(language)}/${displaysConstants.announcement.text} znaků ${textLength(language) > displaysConstants.announcement.text && shortTextLength(language) == 0 ? '| Může docházet k chybnému zobrazování, zkraťte text nebo použijte pole pro zkrácený text!' : ''}`}}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="hasAttribute('text') && textLength(language) > displaysConstants.announcement.shortText" :key="`s-text-${language}`">
                    <th scope="row" class="table-header">Zkrácený text ({{language.toUpperCase()}}) <InfoButton right small>Zkrácená verze textu je využívána při paralelním zobrazování několika oznámení na jedné obrazovce.</InfoButton></th>
                    <td>
                      <tiptap-vuetify
                        :id="`announcement-short-text-${language}`"
                        v-model="announcement.localizedAttributes[language].shortText"
                        :extensions="extensions"
                        :card-props="{ outlined: true }"
                        @change="madeUnsavedChanges">
                      </tiptap-vuetify>
                      <div class="tiptap-validation" v-bind:class="{ 'validation-error': shortTextLength(language) > displaysConstants.announcement.shortText || shortTextLength(language) === 0 }">
                        {{`${shortTextLength(language)}/${displaysConstants.announcement.shortText} znaků ${(shortTextErrorLabel(language))}`}}
                      </div>
                    </td>
                  </tr>
                </template>
                <template>
                  <tr v-if="hasAttribute('events')">
                    <th scope="row" class="table-header">Obsah harmonogramu</th>
                    <td>
                      <SchedulePicker
                        id="announcement-events"
                        v-on:eventsChanged="(output) => {
                          setServiceConfig(output);
                          output.pristine || madeUnsavedChanges();
                          updatePreview();
                        }"
                        :initialValues="inputJSON"
                        :languages="selectedLanguages">
                      </SchedulePicker>
                    </td>
                  </tr>
                </template>
                <tr v-if="hasAttribute('stops')">
                  <th scope="row" class="table-header">Zobrazované zastávky</th>
                  <td>
                    <StopsPicker
                      v-on:stopsChanged="(output) => {
                        setServiceConfig(output);
                        output.pristine || madeUnsavedChanges();
                        updatePreview();
                      }"
                      :initialValues="inputJSON"
                      :allStops="getStops">
                    </StopsPicker>
                  </td>
                </tr>
                <tr v-if="hasAttribute('facility')">
                  <th scope="row" class="table-header">Výdejna stravy </th>
                  <td>
                    <AnnouncementFacilitySelect
                      id="announcement-facility"
                      :rules="[required_rule]"
                      v-on:facilityChanged="(output) => {
                        setServiceConfig(output);
                        output.pristine || madeUnsavedChanges();
                        updatePreview();
                      }"
                      :initialValues="inputJSON"
                      :allFacilities="getFacilities"
                    />
                  </td>
                </tr>
                <template v-for="language in selectedLanguages">
                  <tr v-if="hasAttribute('url')" :key="`url-${language}`">
                    <th scope="row" class="table-header">Web ({{language.toUpperCase()}}) <InfoButton right small>Ze zadané webové adresy bude automaticky vygenerován QR kód zobrazený v rámci obsahu oznámení.</InfoButton></th>
                    <td>
                      <URLInput 
                        v-bind:id="`announcement-url-${language}`"
                        :url="announcement.localizedAttributes[language].url"
                        v-on:change="(value) => {
                          announcement.localizedAttributes[language].url = value;
                          onChange();
                        }"
                        :maxLength="displaysConstants.announcement.url"
                      />
                    </td>
                  </tr>
                </template>
                <tr v-if="hasAttribute('images')">
                  <th scope="row" id="announcement-media-title" class="table-header">{{`${isFullScreenMediaService ? 'Média' : 'Obrázky'}`}} <InfoButton right small>{{`Nahrajte až ${maxImages} ${isFullScreenMediaService ? 'obrázků nebo videí' : 'obrázků'} o maximální velikosti ${displaysConstants.announcement.image/1000000} MB.`}}</InfoButton></th>
                  <td>
                    <AnnouncementImagePicker 
                      v-on:change="(values) => {
                        changedImages = values;
                        this.imagesChanged = true;
                        onChange();
                      }"
                      v-on:select="(values) => {
                        changedImages = values;
                        onChange();
                      }"
                      v-on:restore="(values) => {
                        changedImages = pristineImages;
                        this.imagesChanged = false;
                        onChange();
                      }"
                      :initialImages="pristineImages"
                      :maxImageSize="displaysConstants.announcement.image"
                      :maxImages="maxImages"
                      :imagesChanged="imagesChanged"
                    />
                  </td>
                </tr>
                <!-- Announcement preview for small devices -->
                <tr v-if="selectedAnnouncementType && !dialog && wrapPreview">
                  <th scope="row" class="table-header">Náhled oznámení</th>
                  <td>
                    <AnnouncementPreview
                      id="announcement-preview"
                      ref="preview"
                      :announcement="announcement"
                      :announcementImagePath="previewImagePath"
                      :announcementImageIsVideo="announcementImageIsVideo"
                      :blockParallel="announcement.blockParallel"
                      :getShortText="getShortText"
                      :getShortTitle="getShortTitle"
                      :selectedAnnouncementType="selectedAnnouncementType"
                      :selectedLanguages="selectedLanguages"
                      :textLength="textLength"
                      :initialLanguage="previewLanguage"
                      :initialOrientation="previewOrientation"
                      @zoomIn="zoomIn"
                      @languageChanged="togglePreviewLanguage"
                      @orientationChanged="togglePreviewOrientation"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="table-header">Režim zobrazení <InfoButton right small>Možnost vynutit zobrazování oznámení vždy na celé ploše informačního panelu, případně stálé viditelnosti.</InfoButton></th>
                  <td :title="serviceBlocksParallel ? 'Zvolený typ oznámení nepodporuje paralelní zobrazení.' : ''">
                    <v-checkbox
                      id="announcement-block-parallel"
                      :disabled="serviceBlocksParallel"
                      v-model="announcement.blockParallel"
                      label="Zakázat paralelní zobrazení"
                      :value="!announcement.blockParallel">
                    </v-checkbox>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="table-header"></th>
                  <td>
                    <v-checkbox
                      id="announcement-is-permanent"
                      v-model="announcement.isPermanent"
                      label="Nastavit jako stále viditelné"
                      :value="!announcement.isPermanent"
                      class="mb-4">
                    </v-checkbox>
                  </td>
                </tr>
                <tr v-if="!announcement.blockParallel">
                  <th scope="row" class="table-header">Preferovaný formát paralelního zobrazení <InfoButton right small>V rámci paralelního zobrazení více oznámení najednou bude vždy upřednostněn zvolený formát.</InfoButton></th>
                  <td>
                    <AnnouncementPreferredPosition 
                      :position="announcement.preferredPosition"
                      v-on:change="(value) => {
                        announcement.preferredPosition = value;
                        onChange();
                      }"
                    />
                  </td>
                </tr>
                <!-- Displaying scheduler -->
                <tr>
                  <th scope="row" class="table-header">Období zobrazování <InfoButton right small>Datum začátku a konce období, v rámci kterého bude oznámení zobrazováno v dále specifikované dny v týdnu a časy.</InfoButton></th>
                  <td>
                    <v-container class="p-0">
                      <v-row>
                        <v-col class="pl-0">
                          <DatePicker
                            :rules="[required_rule]"
                            title="Od"
                            :clearable="false"
                            v-on:dateChanged="(value) => {
                              announcement.displayFrom = value ? value.unix() : undefined;
                              madeUnsavedChanges();
                            }"
                            :minDate="currentDate"
                            :initialValue="formatDate(announcement.displayFrom)"
                          />
                        </v-col>
                        <v-col>
                          <DatePicker
                            title="Do"
                            :clearable="true"
                            v-on:dateChanged="(value) => {
                              announcement.displayTo = value ? value.add(1,'days').unix() - 1 : undefined;
                              madeUnsavedChanges();
                            }"
                            :minDate="announcement.displayFrom ? formatDate(announcement.displayFrom) : currentDate"
                            :initialValue="!isNew && announcement.displayTo ? formatDate(announcement.displayTo) : undefined"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>           
        </div>
        <!-- Announcement preview for large devices -->
        <AnnouncementPreview
          v-if="selectedAnnouncementType && !dialog && !wrapPreview"
          id="announcement-preview"
          class="preview ml-4" 
          isSmall
          ref="preview"
          :announcement="announcement"
          :selectedAnnouncementType="selectedAnnouncementType"
          :selectedLanguages="selectedLanguages"
          :textLength="textLength"
          :getShortTitle="getShortTitle"
          :getShortText="getShortText"
          :initialLanguage="previewLanguage"
          :initialOrientation="previewOrientation"
          :announcementImagePath="previewImagePath"
          :announcementImageIsVideo="announcementImageIsVideo"
          :blockParallel="announcement.blockParallel"
          @zoomIn="zoomIn"
          @languageChanged="togglePreviewLanguage"
          @orientationChanged="togglePreviewOrientation"
        />
        <div class="displayings v-data-table v-data-table--fixed-header">
          <v-alert
            v-if="displayingsLength <= 0"
            id="announcement-displayings-alert"
            class="mx-4"
            type="warning" outlined
            border="left"
          >
            Bez nastavení časových intervalů pro zobrazování nebude oznámení na informačních panelech viditelné.
          </v-alert>
          <div class="v-data-table__wrapper">
            <table>
              <tbody>
                <tr class="p-0">
                  <td colspan="2">
                    <WeekScheduler
                      :key="`schedule-${displayingsLength}`"
                      v-on:scheduleChanged="(value) => {
                        this.madeUnsavedChanges();
                        this.announcement.displayings = value;
                      }"
                      :daysOfWeek="weekDays"
                      :initialValues="announcement.displayings"
                      ref="scheduler">
                    </WeekScheduler>
                  </td>
                </tr>
                <tr>
                  <td class="text-right" colspan="2">
                    <v-btn-toggle class="my-2 no-active" dense>
                      <v-btn 
                        id="announcement-all-week"
                        @click="() => $refs.scheduler.setValue(weekDays.map(day => ({ dayOfWeek: day.index, timeFrom: '00:00', timeTo: '23:59' })))">
                        <span class="hidden-sm-and-down">Celý týden</span>
                      </v-btn>
                      <v-btn id="announcement-delete-displayings" @click="() => $refs.scheduler.setValue([])">
                        <span class="hidden-sm-and-down">Smazat časy</span>
                      </v-btn>
                    </v-btn-toggle>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import {
    TiptapVuetify,
    Bold,
    Italic,
    Code,
    BulletList,
    OrderedList,
    ListItem,
    HardBreak,
    HorizontalRule,
    History,
    Blockquote
  } from 'tiptap-vuetify';
  import moment from 'moment';
  import { displaysMaxValues, announcementAttributesByType, config, videoPostfixes, videoTypes, FOOD_SERVICE, FULL_SCREEN_IMAGE_SERVICE } from '@/config';
  import { removeHTML } from "../../../utils";
  import { daysOfWeek } from "./announcements-helpers";
  import AnnouncementLanguageSelect from '../../../components/displays/AnnouncementLanguageSelect';
  import AnnouncementFacilitySelect from '../../../components/displays/AnnouncementFacilitySelect';
  import AnnouncementPreferredPosition from '../../../components/displays/AnnouncementPreferredPosition';
  import AnnouncementPreview from '../../../components/displays/AnnouncementPreview';
  import AnnouncementTypeSelect from '../../../components/displays/AnnouncementTypeSelect';
  import BackButton from "../../../components/layout/BackButton";
  import InfoButton from "../../../components/buttons/InfoButton";
  import DatePicker from "../../../components/date-picker/DatePicker";
  import AnnouncementImagePicker from "../../../components/displays/AnnouncementImagePicker";
  import ItemNotFoundState from '../../../components/states/ItemNotFoundState.vue';
  import Loading from "../../../components/Loading";
  import ModuleHeader from "../../../components/layout/ModuleHeader";
  import SaveButton from "../../../components/buttons/SaveButton";
  import SchedulePicker from "../../../components/schedule-picker/SchedulePicker";
  import StopsPicker from "../../../components/kordis/StopsPicker";
  import URLInput from "../../../components/url-input/URLInput";
  import WeekScheduler from "../../../components/week-scheduler/WeekScheduler";
  import { Announcement } from "@/store/modules/displays/Announcement";

  export default {
    name: "AnnouncementDetail",

    components: {
      AnnouncementImagePicker,
      AnnouncementLanguageSelect,
      AnnouncementTypeSelect,
      AnnouncementFacilitySelect,
      AnnouncementPreferredPosition,
      AnnouncementPreview,
      BackButton,
      InfoButton,
      DatePicker,
      ItemNotFoundState,
      Loading,
      ModuleHeader,
      SaveButton,
      SchedulePicker,
      StopsPicker,
      TiptapVuetify,
      URLInput,
      WeekScheduler
    },

    async created() {
        this.isLoading = true;

      this.announcement = new Announcement();
      window.addEventListener("resize", this.resizeHandler);
      await this.fetchAnnouncementTypes(this.$route.params.unitId);
      await this.fetchStops();
      await this.fetchFacilities();
      if (!this.isNew) {
        await this.fetchAnnouncementById(this.announcementId);
        if (this.objectNotFound)
          return;
        await this.fetchAnnouncementImagesInfo({ 'announcementId': this.announcementId });
        this.announcement = this.getAnnouncementById(this.announcementId);
        this.hideStopColumn = this.inputJSON?.hideStopColumn;
        this.localize = {
          [this.cz]: !!this.announcement.localizedAttributes[this.cz],
          [this.en]: !!this.announcement.localizedAttributes[this.en]
        };
        this.selectedLanguages = this.selectedLanguages.filter(key => !!this.localize[key]);
        this.previewLanguage = this.selectedLanguages[0];
        this.changedImages = this.pristineImages;
        this.announcement.localizedAttributes = {
          [this.cz]: this.announcement.localizedAttributes[this.cz] || this.emptyAttributes,
          [this.en]: this.announcement.localizedAttributes[this.en] || this.emptyAttributes
        };
      } else {
        this.clear();
      }

      this.isLoading = false;
    },

    destroyed() {
      window.removeEventListener("resize", this.resizeHandler);
    },

    data() {
      const cz = 'cz';
      const en = 'en';
      const emptyAttributes = {
        title: "",
        shortTitle: "",
        text: "<p></p>",
        shortText: "<p></p>",
        url: "",
      };

      return {
          isLoading: true,
        isSaving: undefined,
        isFormValid: false,
        isServiceConfigValid: false,
        cz, en, emptyAttributes,
        selectedLanguages: [cz, en],
        required_rule: value => !!value || 'Povinné' ,
        changedImages: [],
        imagesChanged: false,
        maxImages: 20,
        announcement: {
          localizedAttributes: {
            cz: emptyAttributes,
            en: emptyAttributes
          },
          serviceConfiguration: undefined,
          blockParallel: false,
          isPermanent: false,
          preferredPosition: 'none',
          announcementTypeId: undefined,
          displayFrom: undefined,
          displayTo: undefined,
          displayings: []
        },
        hideStopColumn: false,
        extensions: [
          History,
          Bold,
          Italic,
          ListItem,
          BulletList,
          OrderedList,
          Code,
          Blockquote,
          HorizontalRule,
          HardBreak
        ],
        dialog: false,
        previewLanguage: cz,
        previewOrientation: 'blockParallel',
        sectionWidth: document.getElementById("auth-section")?.getBoundingClientRect()?.width || 1500   // default value for tests
      }
    },

    computed: {
      ...mapGetters('announcements', [
        'getAnnouncementById',
        'didLoadAnnouncements'
      ]),

      ...mapGetters('announcementTypes', [
        'getAnnouncementTypes',
        'didLoadAnnouncementTypes'
      ]),

      ...mapGetters('announcementImages', [
        'getAnnouncementImages',
        'didLoadAnnouncementImages'
      ]),

      ...mapGetters('stops', [
        'getStops',
        'didLoadStops'
      ]),

      ...mapGetters('facilities', [
        'getFacilities',
        'didLoadFacilities'
      ]),

      ...mapGetters('errors', ['objectNotFound']),

      weekDays() {
        return daysOfWeek(this.announcement.displayFrom, this.announcement.displayTo);
      },

      previewImage() {
        return this.changedImages?.find(image => image.highlight);
      },

      previewImagePath() {
        return this.previewImage?.path;
      },

      announcementImageIsVideo() {
        return this.isVideoFile(this.previewImage);
      },

      announcementId() {
        const urlId = this.$route.params.announcementId;
        return urlId === "new" ? null : Number(urlId);
      },

      serverUrl() {
        return config.serverUrl;
      },

      isNew() { return this.announcementId === null; },

      usesServiceConfig() {
        return this.hasAttribute('events') || this.hasAttribute('stops') || this.hasAttribute('facility');
      },

      didLoadData() {
        return (
          (this.didLoadAnnouncements && this.didLoadAnnouncementImages || this.isNew) &&
          this.didLoadAnnouncementTypes && this.didLoadStops && this.didLoadFacilities
        ) && !this.isLoading;
      },

      displaysConstants: () => (
        displaysMaxValues
      ),

      pristineImages() {
        return this.isNew ? [] : this.getAnnouncementImages.map((image, index) => ({ ...image, path: `${this.serverUrl}displays/images/${this.announcementId}/${image.id}`, highlight: index === 0}));
      },

      currentDate() {
        return moment(Date.now()).format('YYYY-MM-DD');
      },

      isTextValid() {
        const overflow = this.selectedLanguages.filter(language => this.textLength(language) > this.displaysConstants.announcement.shortText);
        if (this.hasAttribute('text') && overflow.length > 0) {
          return overflow.every(language => this.shortTextLength(language) > 0);
        }
        if (this.hasAttribute('text')) return this.selectedLanguages.every(language => this.textLength(language) > 0);
        return true;
      },

      isLargeDevice() {
        return !['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name)
      },

      selectedAnnouncementType () {
        return this.getAnnouncementTypes.find(item => item.id === this.announcement.announcementTypeId);
      },

      serviceBlocksParallel() {
        return this.selectedAnnouncementType?.serviceName === FOOD_SERVICE;
      },

      attributesByType() {
        return announcementAttributesByType[this.selectedAnnouncementType?.serviceName ?? null];
      },

      isFullScreenMediaService() {
        return this.selectedAnnouncementType?.serviceName === FULL_SCREEN_IMAGE_SERVICE;
      },

      inputJSON() {
        try {
          return JSON.parse(this.announcement.serviceConfiguration);
        } catch(e) {
          return this.announcement.serviceConfiguration;
        }
      },

      selectedStops() {
        return this.inputJSON?.stops || [];
      },

      displayingsLength() {
        return this.announcement.displayings?.length ?? 0;
      },

      wrapPreview() {
        return this.sectionWidth < 1270;
      }
    },

    methods: {
      ...mapActions('leavePrevent', ['madeUnsavedChanges', 'didSaveChanges']),

      ...mapActions('announcements', [
        'createAnnouncement',
        'fetchAnnouncements',
        'fetchAnnouncementById',
        'editAnnouncement',
        'createDisplayings',
        'deleteDisplayings'
      ]),

      ...mapActions('announcementImages', [
        'fetchAnnouncementImagesInfo',
        'deleteAnnouncementImages',
        'createAnnouncementImages'
      ]),

      ...mapActions('announcementTypes', ['fetchAnnouncementTypes']),

      ...mapActions('stops', ['fetchStops']),

      ...mapActions('facilities', ['fetchFacilities']),

      ...mapActions('errors', ['clear']),

      resizeHandler() {
        this.sectionWidth = document.getElementById("auth-section")?.getBoundingClientRect()?.width || 1500   // default value for tests
      },

      isVideoFile(file) {
        return videoTypes.includes(file?.type ?? file?.file?.type) || videoPostfixes.includes(file?.postfix);
      },

      textLength(language) {
        const text = this.announcement.localizedAttributes[language].text;
        return text && removeHTML(text).length || 0;
      },

      getServiceBlocksParallel(announcementTypeId) {
        return this.getAnnouncementTypes.find(item => item.id === announcementTypeId)?.serviceName === FOOD_SERVICE;
      },

      shortTextLength(language) {
        const shortText = this.announcement.localizedAttributes[language].shortText;
        return shortText && removeHTML(shortText).length || 0;
      },

      shortTextErrorLabel(language) {
         if (this.shortTextLength(language) > this.displaysConstants.announcement.shortText) return '| Může docházet k chybnému zobrazování, zkraťte text!';
         if (this.shortTextLength(language) === 0) return '| Povinné';
         return '';
      },

      formatDate(date) {
        return date ? moment.unix(date).format('YYYY-MM-DD') : undefined;
      },

      updatePreview() {
        this.$refs.preview && this.$refs.preview.onUpdateContent();
      },

      onChange() {
        this.madeUnsavedChanges()
        this.updatePreview();
      },

      changeAnnouncementType(id) {
        this.announcement.announcementTypeId = id;
        if (!this.hasAttribute('text')) {
          this.announcement.text = '';
          this.announcement.shortText = '';
        }
        if (!this.hasAttribute('url')) this.announcement.url = '';
        if (this.getServiceBlocksParallel(id)) this.announcement.blockParallel = true;
        this.onChange();
      },
  
      setServiceConfig(output) {
        this.isServiceConfigValid = output?.valid;
        this.announcement.serviceConfiguration = { ...output?.value || {}, ...(this.hasAttribute('stops') ? { hideStopColumn: this.hideStopColumn && output.value?.stops?.length <= 1 } : {}) };
        if (output.value?.stops?.length > 1) { this.hideStopColumn = false; }
      },

      setHideColumnConfig(value) {
        this.announcement.serviceConfiguration = { ...(this.announcement.serviceConfiguration || {}), ...(this.hasAttribute('stops') ? { hideStopColumn: value } : {})}
        this.updatePreview();
      },

      hasAttribute(attributeName) {
        return this.attributesByType.includes(attributeName);
      },

      getShortTitle(language) {
        return this.announcement.localizedAttributes[language].title.length > this.displaysConstants.announcement.shortTitle
          ? this.announcement.localizedAttributes[language].shortTitle
          : undefined;
      },

      getShortText(language) {
        return this.textLength(language) > this.displaysConstants.announcement.shortText
          ? this.announcement.localizedAttributes[language].shortText
          : undefined;
      },

      saveDetail() {
        if (this.isFormValid && (!this.hasAttribute('events') || this.isServiceConfigValid)) {
          this.isSaving = true;
          let promises = [];
          const localizedAttributes = {
            ...(this.selectedLanguages.reduce((acc, language) => ({
              ...acc,
              [language]: {
                ...this.announcement.localizedAttributes[language],
                shortTitle: this.getShortTitle(language),
                shortText: this.getShortText(language)
              }
            }), {}))
          };
          if (this.isNew) {
            this.createAnnouncement({
              ...this.announcement,
              unitId: this.$route.params.unitId,
              serviceConfiguration: this.usesServiceConfig ? JSON.stringify(this.announcement.serviceConfiguration) : undefined,
              localizedAttributes
            }).then(responseData => {
              this.imagesChanged && this.hasAttribute('images') && promises.push(this.createAnnouncementImages({
                id: responseData.id,
                images: this.changedImages
              }));
              this.displayingsLength > 0 && promises.push(this.createDisplayings({
                announcementId: responseData.id,
                displayings: this.announcement.displayings
              }));
              Promise.all(promises).then(() => {
                this.isSaving = false;
                this.didSaveChanges();
                this.$router.push({name: "announcements"});
              });
            });
          } else {
            this.editAnnouncement(({
              ...this.announcement,
              unitId: this.$route.params.unitId,
              serviceConfiguration: this.usesServiceConfig ? JSON.stringify(this.announcement.serviceConfiguration) : undefined,
              localizedAttributes
            })).then(() => {
              if (this.hasAttribute('images') && this.imagesChanged) {
                const imagesToDelete = this.pristineImages.filter(oldImage => !this.changedImages.some(newImage => newImage.id === oldImage.id)).map(image => image.id);
                const imagesToCreate = this.changedImages.filter(image => !image.id);

                imagesToDelete.length > 0 && promises.push(this.deleteAnnouncementImages({ announcementId: this.announcementId, imagesIds: imagesToDelete}));
                imagesToCreate.length > 0 && promises.push(this.createAnnouncementImages({ id: this.announcementId, images: imagesToCreate }).then(() => {
                  this.fetchAnnouncementImagesInfo({ 'announcementId': this.announcementId });
                }));
              }
              promises.push(this.deleteDisplayings(this.announcementId));
              this.announcement.displayings && promises.push(this.createDisplayings({
                announcementId: this.announcementId,
                displayings: this.announcement.displayings
              }));
              Promise.all(promises).then(() => {
                this.isSaving = false;
                this.didSaveChanges();
                this.changedImages = undefined;
                this.imagesChanged = false;
              })
            }).catch((e) => {
              console.log(e)
            });
          }
        }
      },

      zoomIn() {
        this.dialog = true;
      },

      togglePreviewLanguage(language) {
        this.previewLanguage = language;
        this.$refs.previewDialog && this.$refs.previewDialog.setPreviewLanguage(language);
      },

      togglePreviewOrientation(orientation) {
        this.previewOrientation = orientation;
        this.$refs.previewDialog && this.$refs.previewDialog.setPreviewOrientation(orientation);
      },
    }
  }
</script>

<style>
  .grid {
    display: grid;
    grid-template: 
        "content preview"
        "displayings displayings";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
  }

  .grid-sm {
    display: grid;
    grid-template: 
        "content"
        "displayings";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .content {
    grid-area: content;
    grid-auto-rows: max-content;
  }

  .table {
    width: 100%;
  }

  .preview {
    grid-area: preview;
    grid-auto-rows: min-content;
  }

  .displayings {
    grid-area: displayings
  }

  .table-header {
    font-size: 16px;
    width: 22%;
  }

  .tiptap-vuetify-editor {
    margin-top: 0.5rem;
  }

  .tiptap-validation {
    margin-top: 0.1rem;
    margin-bottom: 0.5rem;
    color: rgba(0,0,0,.6);
    font-size: 12px;
  }

  .validation-error {
    color: rgb(255, 82, 82);
  }

  .tiptap-vuetify-editor div.ProseMirror {
    max-height: 200px;
    overflow: initial;
    margin: 10px !important;
  }

  .tiptap-vuetify-editor__content .ProseMirror p {
    margin: 0px !important;
  }

  div.no-active .v-btn--active::before {
    opacity: 0;
  }
</style>