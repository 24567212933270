export default {
  // common
  'noName': 'No name',

  // languages
  'cz': 'Czech',
  'en': 'English',

  //report type
  'idea': 'idea',
  'problem': 'problem',

  //report state
  'pending': 'pending',
  'inProgress': 'in progress',
  'solved': 'solved',
  'rejected' : 'rejected',

  //Component block translations
  'empty-category' : '-No category-',

  //Place Access types
  'Neomezený přístup' : 'Access without any restrictions',
  'Přístup pouze s odpovědnou osobou' : 'Access only with responsible person',
  'Přístup pouze s kartou' : 'Access with card only',
}
