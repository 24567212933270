<template>
  <ItemNotFoundState v-if="objectNotFound" :objectName="'Typ oznámení'">
    <template #backButton>
        <BackButton :target="{name: 'announcementTypes'}">Typy oznámení</BackButton>
    </template>
  </ItemNotFoundState>
  <Loading v-else-if="!didLoadData"/>
  <div v-else>
    <BackButton :target="{name: 'announcementTypes'}">Typy oznámení</BackButton>
    <ModuleHeader>
      <div id="header-title">
        <h2 v-if="isNew">Přidání typu oznámení</h2>
        <h2 v-else>Úprava typu oznámení</h2>
      </div>
      <v-spacer/>
      <SaveButton class="ml-5 mb-6" :disabled="!isFormValid" :is-saving="isSaving" @click="saveDetail"/>
    </ModuleHeader>
    <div class="mb-5 mt-5">
        <v-form @submit.prevent="updateAnnouncementType" v-model="isFormValid" ref="form">
          <div class="v-data-table v-data-table--fixed-header">
            <div class="v-data-table__wrapper">
              <table>
                  <tbody>
                    <tr>
                      <th scope="row" class="table-header">Název</th>
                      <td >
                        <v-text-field
                          :autofocus="isNew"
                          id="type-name"
                          :rules="[required_rule]" 
                          color="dark" 
                          v-model="announcementType.name" 
                          :maxlength="displaysConstants.announcementType.name"
                          :label="`${announcementType.name.length}/${displaysConstants.announcementType.name} znaků`"
                          @change="changeWatch">
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="table-header">Ikona</th>
                      <td >
                        <IconPicker
                          :autofocus="true"
                          id="type-icon"
                          :rules="[required_rule]" 
                          ref="iconPicker" 
                          :initialValue="announcementType.iconName" 
                          v-on:iconChanged="changeIcon">
                        </IconPicker>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="table-header">Barva</th>
                      <td>
                        <v-swatches
                          id="type-color"
                          @input="changeWatch"
                          class="color-swatch"
                          inline
                          v-model="announcementType.color" 
                          :swatches="mendeluColors"
                          show-labels
                          shapes='circles'
                          :swatch-size='35'
                          :spacing-size='15'
                        ></v-swatches>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="table-header">Generující služba <InfoButton right small>Zajišťuje přípravu obsahu dynamicky generovaných oznámení. Pro typy oznámení s ručně definovaným obsahem ji není třeba specifikovat.</InfoButton></th>
                      <td>
                        <v-select
                          id="type-service"
                          color="dark"
                          :items="displaysServices"
                          :disabled="!isNew"
                          item-value="id"
                          item-text="name"
                          v-model="announcementType.serviceName"
                          :label="isNew || announcementType.serviceName ? 'Generující služba' : 'Generující služba není nastavena'"
                          :clearable="true"
                          @change="changeWatch"
                        >
                        </v-select>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="table-header">Mobilní náhled</th>
                      <td>
                        <v-checkbox
                          id="type-mobile-toggle"
                          v-model="announcementType.displayOnMobile"
                          label="Povolit náhled v mobilní aplikaci My MENDELU?"
                          :value="announcementType.displayOnMobile">
                        </v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" class="table-header">Obrazovky <InfoButton right small>Obrazovky, na kterých bude daný typ oznámení zobrazován.</InfoButton></th>
                      <td>
                        <v-select
                          id="type-displays"
                          v-model="announcementType.displaysIds"
                          :items="getDisplays"
                          item-text="name"
                          item-value="id"
                          chips
                          label="Obrazovky"
                          multiple
                          :clearable="true"
                          :menu-props="{ offsetY: true }"
                          color="default"
                          @change="changeWatch"
                        >
                          <template v-if="getDisplays.length > 0" v-slot:prepend-item>
                            <v-list-item
                              ripple
                              @click="toggleSelectedDisplays"
                            >
                              <v-list-item-action>
                                <v-icon :color="announcementType.displaysIds.length > 0 ? 'blue darken-2' : ''">{{ selectAllIcon }}</v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>{{allDisplaysAdded ? 'Zrušit výběr' : 'Vybrat vše'}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-select>
                      </td>
                    </tr>
                  </tbody>
              </table>
            </div>
          </div>
        </v-form>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import {displaysMaxValues, displaysServices, mendeluColors} from '@/config';
  import Loading from "../../../components/Loading";
  import IconPicker from "../../../components/icon-picker/IconPicker";
  import SaveButton from "../../../components/buttons/SaveButton";
  import BackButton from "../../../components/layout/BackButton";
  import InfoButton from "../../../components/buttons/InfoButton";
  import ModuleHeader from "../../../components/layout/ModuleHeader";
  import VSwatches from 'vue-swatches';
  import ItemNotFoundState from '../../../components/states/ItemNotFoundState.vue';

  export default {
    name: "AnnouncementDetail",
    components: {
      BackButton, 
      IconPicker,
      Loading,
      ModuleHeader,
      InfoButton,
      SaveButton, 
      VSwatches,
      ItemNotFoundState,
    },

    async created() {
      await this.fetchDisplays(this.$route.params.unitId);
      if (!this.isNew) {
        await this.fetchAnnouncementTypeById(this.announcementTypeId);
      } else {
        this.clear();
      }
    },

    data() {
      return {
        isSaving: false,
        isFormValid: false,
        required_rule: value => !!value || 'Povinné' ,
        announcementType: {
          name: "",
          color: "#80CC16",
          iconName: "",
          serviceName: undefined,
          displayOnMobile: false,
          displaysIds: []
        }
      }
    },

    watch: {
      announcementTypeId() {
        this.announcementType = this.initialAnnouncementType;
      },

      initialAnnouncementType() {
        this.announcementType = this.initialAnnouncementType;
      }
    },

    computed: {
      ...mapGetters('announcementTypes', [
        'getAnnouncementTypeById',
        'prepareNewAnnouncementType',
        'didLoadAnnouncementTypes'
      ]),

      ...mapGetters('displays', [
        'getDisplays',
        'didLoadDisplays'
      ]),
      ...mapGetters('errors', ['objectNotFound']),

      displaysConstants: () => (
        displaysMaxValues
      ),

      mendeluColors: () => (
        mendeluColors
      ),

      announcementTypeId() {
        const urlId = this.$route.params.announcementTypeId;
        return urlId === "new" ? null : Number(urlId);
      },

      isNew() { return this.announcementTypeId === null; },

      didLoadData() { 
        return (
          (this.didLoadAnnouncementTypes || this.isNew) &&
          this.didLoadDisplays
        )
      },

      initialAnnouncementType() {
        return this.isNew
          ? this.prepareNewAnnouncementType()
          : this.getAnnouncementTypeById(this.announcementTypeId);
      },

      selectAllIcon () {
        if (this.allDisplaysAdded) return 'check_box'
        if (this.someDisplaysAdded) return 'indeterminate_check_box'
        return 'check_box_outline_blank'
      },

      allDisplaysAdded () {
        return this.announcementType.displaysIds.length === this.getDisplays.length
      },

      someDisplaysAdded () {
        return this.announcementType.displaysIds.length > 0 && !this.allDisplaysAdded
      },

      displaysServices: () => (
        displaysServices
      )
    },

    methods: {
      ...mapActions('leavePrevent', ['madeUnsavedChanges', 'didSaveChanges']),

      ...mapActions('announcementTypes', [
        'fetchAnnouncementTypeById',
        'createAnnouncementType',
        'updateAnnouncementType'
      ]),

      ...mapActions('displays', [
        'fetchDisplays'
      ]),

      ...mapActions('errors', ['clear']),

      changeIcon(icon) {
        this.announcementType.iconName = icon;
        this.changeWatch();
      },

      changeWatch() {
        this.madeUnsavedChanges();
      },

      toggleSelectedDisplays () {
        this.$nextTick(() => {
          if (this.allDisplaysAdded) {
            this.announcementType.displaysIds = []
          } else {
            this.announcementType.displaysIds = this.getDisplays.map((item) => item.id)
          }
        })
      },

      saveDetail() {
        if (this.isFormValid) {
          this.isSaving = true;
          if (this.isNew) {
            this.createAnnouncementType({...this.announcementType, unitId: this.$route.params.unitId}).then(() => {
              this.isSaving = false;
              this.didSaveChanges();
              this.$router.push({name: "announcementTypes"});
            });
          } else {
            this.updateAnnouncementType({
              ...this.announcementType
            }).then(() => {
              this.isSaving = false;
              this.didSaveChanges();
            }).catch((e) => {
              console.log(e)
            });
          }   
        }
      }
    }
  }
</script>

<style scoped>
  li {
    list-style-type: none;
  }

  .table-header {
    font-size: 16px;
    width: 15%;
  }

  .color-swatch {
    margin-bottom: 24px;
  }
</style>