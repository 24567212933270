<template>
    <Loading v-if="!didLoadedAll"></Loading>
    <div v-else class="mt-5">
        <v-data-table
          v-model="selectedItems"
          :headers="headers"
          :items="getAllTests"
          loading-text="Načítání..."
          show-select
          :sort-by.sync="$query.sort"
          :sort-desc.sync="$query.desc"
          :items-per-page.sync="$query.rowsPerPage"
          :page.sync="$query.page"

        >

            <template v-slot:no-data>
                Žádná dostupná data
            </template>

            <template v-slot:item.localizedAttributes.cz.name="{item}">
                    <b class="text-xs-left pointer light-green--text"
                          style="font-size: 1rem !important"
                          @click="goToDetail(item.id)">{{ item.localizedAttributes.cz.name }}</b>
            </template>
            <template v-slot:item.categories="{categories}">
                <span> - </span>
            </template>
            <template v-slot:item.points="{item}">
                <span>{{ item.points }}</span>
            </template>
            <template v-slot:item.date="{item}">
                <span>{{ formatDate(item.date) }}</span>
            </template>
            <template v-if="selectedItems.length!==0" v-slot:body.append>
                <v-row>
                    <v-btn small class="actionsDetails mx-5 my-5"
                           @click.stop="dialog = true"
                           :disabled="selectedItems.length === 0"
                    >Smazat
                    </v-btn>
                    <DeleteDialog :method="deleteTests" :dialog="dialog"
                                  v-on:childToParent="updateDialog"></DeleteDialog>
                </v-row>
            </template>

        </v-data-table>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import DeleteDialog from "@/components/map/DeleteDialog";
import Loading from "@/components/Loading";

export default {
    name: "Tests",

    components: {DeleteDialog, Loading},

    created() {
        this.fetchAllTests();
    },

    data() {
        return {
            selectedItems: [],
            dialog: false,
            headers: [
                {
                    text: 'Název',
                    align: 'left',
                    sortable: true,
                    value: 'localizedAttributes.cz.name',
                    width: '35%'
                },
                {
                    text: 'Kategorie testu',
                    sortable: true,
                    value: 'categories',
                    width: '35%'
                },
                {
                    text: 'Body celkem',
                    align: 'left',
                    sortable: true,
                    value: 'points',
                    width: '18%'
                },
                {
                    text: 'Upraveno',
                    align: 'left',
                    sortable: true,
                    value: 'date',
                    width: '15%'
                }
            ]
        }
    },

    computed: {
        ...mapGetters('Tests', ['getTests', 'getTestsBySubjectId', 'didLoadedTests']),

        getAllTests() {
            return this.getTestsBySubjectId(this.subjectId);
        },

        didLoadedAll() {
            return this.didLoadedTests;
        },

       subjectId(){
          return Number(this.$route.params.subjectId)
       }
    },

    methods: {
        ...mapActions('Tests', ['fetchAllTests', 'deleteTestById', 'fetchAllTests']),

        goToDetail(testId) {
            this.$router.push({
                name: 'TestDetail',
                params: {testId: testId, subjectId: this.subjectId}
            });
        },
        formatDate(date) {
            return moment(date).format('lll')
        },
        deleteTests() {
            this.selectedItems.forEach((item) => {
                this.deleteTestById(item.id);
            });
            this.selectedItems = [];
            this.dialog = false;
        },
        updateDialog() {
            this.dialog = false;
        }
    }
}
</script>

<style scoped>

.actionsDetails {
    background: rgba(0, 0, 0, 0.45) !important;
    color: white !important;
}

</style>