<template>
    <v-layout class="mt-2">
        <router-view/>
    </v-layout>
</template>

<script>
    export default {
        name: "BasicEntities",
    }
</script>

<style scoped>

</style>