<template>
    <v-container>
        <v-row>
            <v-col>
                <BackButton :target="{name: 'Subjects'}">Předměty</BackButton>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between align-center">
            <v-col cols="12" md="auto" class="subjectName"><span>{{ this.subjectName }}</span></v-col>
            <v-col cols="12" md="auto" >
              <v-tabs class="d-flex justify-content-center"
                      icons-and-text
                      color="light-green"
                      :value="$query.tab === 'questions' ? 1 : 0"
              >
                    <v-tab @click="changeTabToTests()">
                        Testy
                        <v-icon>fact_check</v-icon>
                    </v-tab>
                    <v-tab @click="changeTabToQuestions()">
                        Otázky
                        <v-icon>question_answer</v-icon>
                    </v-tab>
                </v-tabs>
            </v-col>

            <v-col class="d-flex justify-content-center" cols="12" md="auto" style="width: 200px;">
                <PrimaryButton v-if="$query.tab === 'tests'"
                               @click="goToTestDetail('new')" >
                    Přidat test
                </PrimaryButton>
                <PrimaryButton v-if="$query.tab === 'questions' "
                               @click="goToQuestionDetail('new')" >
                    Přidat otázku
                </PrimaryButton>
            </v-col>
        </v-row>
        <v-row cols="12" md="auto" class="categories"><LightButton @click="goToCategories()">spravovat kategorie</LightButton></v-row>

        <v-row>
            <v-col>
                <v-tabs-items v-model="$query.tab" :touchless="true">
                    <v-tab-item value="tests">
                        <Tests :subjectId="subjectId"></Tests>
                    </v-tab-item>
                    <v-tab-item value="questions">
                        <Questions></Questions>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PrimaryButton from "@/components/buttons/PrimaryButton";
import BackButton from "@/components/layout/BackButton";
import Tests from "@/views/Gamification/Tests";
import Questions from "@/views/Gamification/Questions";
import LightButton from "../../components/buttons/LightButton.vue";
import {mapActions, mapGetters} from "vuex";


export default {
    name: "Gamification",

    components: {Questions, Tests, BackButton, PrimaryButton, LightButton},


  async mounted() {
      await this.fetchSubjectById(this.subjectId)
  },


  computed: {
        ...mapGetters('Subjects', ['getSubjectById']),


        subjectId() {
            return Number(this.$route.params.subjectId);
        },
        subjectName() {
            return this.getSubjectById(this.subjectId)?.localizedAttributes.cz?.name;
        },
    },

    methods: {
      ...mapActions('Subjects', ['fetchSubjectById']),

        goToTestDetail(testId) {
            this.$router.push({
                name: 'TestDetail',
                params: {testId: testId, subjectId: this.subjectId}
            });
        },

        goToCategories() {
            this.$router.push({
                name: 'SubjectCategories',
                params: {subjectId: this.subjectId},
            });

        },
        goToQuestionDetail(questionId) {
            this.$router.push({
                name: 'QuestionDetail',
                params: {questionId: questionId, subjectId: this.subjectId}
            });
        },

      changeTabToTests(){
        this.$query.tab = 'tests'
      },
      changeTabToQuestions(){
        this.$query.tab = 'questions'
      }
    }
}
</script>

<style scoped>

.subjectName {
    font-weight: 500 !important;
    font-size: 2rem;
    max-width: 350px;
    color: black;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.categories b {
    text-transform: uppercase;
    padding: 0 12px;
    font-size: 1.2em;
    cursor: pointer;
}

</style>