<template>
    <v-container class="text-center">
        <v-row>
            <v-col>
                <v-img
                    class="img mb-2"
                    :max-height="this.height"
                    :max-width="this.width"
                    :src="this.path"
                />
                <div class="text-h6 font-weight-medium mb-1">{{ this.title }}</div>
                <div class="font-weight-regular">{{ this.text }}</div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "PlaceHolder",

    props: {
        path: String,
        text: String,
        title: String,
        width: {
            type: Number,
            default: 450
        },
        height: {
            type: Number,
            default: 250
        }
    }

}
</script>

<style scoped>

.img {
    display: block;
    margin: 0 auto;
}

</style>
