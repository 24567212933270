<template>
  <div v-if="searchFinished !== true" class="d-flex align-center justify-center w-100">
    <Loading/>
  </div>

  <div v-else-if="mode === 'unknown'" class="ma-3 w-100">
    <v-alert prominent type="error">
        {{ $t('map.generator.bad_url') }}
      <p v-html="$t('map.generator.contact')"/>
    </v-alert>
  </div>
  <div v-else-if="notFound" class="ma-3 w-100">
    <v-alert prominent type="error">
        <p v-html="$t('map.generator.not_found', { placeName: searchedPlaceName})"/>
        <p v-html="$t('map.generator.contact')"/>
    </v-alert>
  </div>
  <div v-else>
    <div class="infobox" v-if="isIndoors">
        <b>{{mapLegend}}</b>
    </div>
    <MapWidget
        :places="mapData"
        :selected="[selectedPlaceId]"
        :center="center"
        :zoom="zoom"
    />
  </div>
</template>

<script>

import {mapGetters, mapActions} from 'vuex';

//Refactored
import MapWidget from "@/components/map/MapWidget";
import Loading from "@/components/Loading";
import {isInEnglish} from "@/services/Map/MapWidget";

export default {

  name: "EmbeddedMap",

  components: {
    MapWidget,
    Loading,
  },

  data() {
    return {
      searchFinished: false,
      notFound: false,
      isIndoors: null,
      selectedBuildingId: null,
      selectedFloorId: null,
      selectedPlaceId: null,
      center: null,
      zoom: null,
      mapData: [],
    }
  },

  async created() {
    if (isInEnglish()) this.$i18n.locale = 'en';

    // It must be called before start of fetching, to parse data from URL
    if (this.mode === 'direct') {
      this.setPlaceFromURL();
      await this.findFloor();
      await this.findBuilding();
    }

    if (this.selectedFloorId !== null) {
      await this.fetchFilteredPlaces(
          {
            floorId: this.$data.selectedFloorId,
            buildingId: null,
            exterior: null,
            placeName: null,
            language: null
          })
    } else if (this.searchedPlaceName !== undefined) {
      await this.fetchFilteredPlaces(
          {
            floorId: null,
            buildingId: null,
            exterior: null,
            placeName: this.searchedPlaceName,
            language: this.currentLanguage
          })
    } else {
      await this.fetchFilteredPlaces(
          {
            floorId: null,
            buildingId: null,
            exterior: true,
            placeName: null,
            language: null
          })
    }

    // It must be called after fetching, because after it will know the real params.
    if (this.mode === 'search') await this.findPlaceByName();

    this.showPlaces();
    this.searchFinished = true;
  },

  computed: {
    ...mapGetters('floors', ['getFloorById']),
    ...mapGetters('buildings', ['getBuildings']),
    ...mapGetters('places', [ 'placesByFloor',  'getFirstPlace', 'didLoadedPlaces']),

    /**
     * Returns the current mode of map.
     * `search` - There is only a place name in the URL. The place must be searched and all other parameters must be deduced, e.g. map's center, zoom, etc.
     * `direct` - All parameters, e.g., map's center or zoom, are entered directly in the URL.
     * `unknown` - Missing parameters in URL, cannot render the map.
     */
    mode() {
      if (this.$route.query.placeName) {
        return 'search';
      } else if (
          this.$route.query.building && this.$route.query.building.match(/^(ext|-1|[0-9]+$)/) && // `ext` or `-1` for exterior places or `123`
          this.$route.query.floor && this.$route.query.floor.match(/^(null|[0-9]+$)/) && // `null` for places without floor or `123`
          this.$route.query.place && this.$route.query.place.match(/^[0-9]+$/) && // `123`
          this.$route.query.center && this.$route.query.center.match(/^[0-9.]+,[0-9.]+$/) && // `12.34,56.78`
          this.$route.query.zoom && this.$route.query.zoom.match(/^[0-9]+$/) // `123`
      ) {
        return 'direct';
      } else {
        return 'unknown';
      }
    },

    building() {
      return this.getBuildings.find(building => building.id === this.selectedBuildingId);
    },

    hasFloor() {
      return this.selectedFloorId !== null && !isNaN(this.selectedFloorId);
    },

    floor() {
      return this.getFloorById(this.selectedFloorId);
    },

    buildingName() {
      return this.building && this.building.localizedAttributes[this.currentLanguage] ? this.building.localizedAttributes[this.currentLanguage].name : this.$t('map.defaults.no_name');
    },

    floorName() {
      return this.floor && this.floor.localizedAttributes[this.currentLanguage] ? this.floor.localizedAttributes[this.currentLanguage].name : this.$t('map.defaults.no_name');
    },

    searchedPlaceName() {
      return this.$route.query.placeName
    },

    mapLegend() {
      let legend = this.$t('map.legend.building_name', { building: this.buildingName });
      if ( this.hasFloor ){
        legend += this.$t('map.legend.floor_name', { floor: this.floorName });
      }
      return legend;
    },
    currentLanguage() {
      return isInEnglish() ? 'en' : 'cz';
    }
  },


  methods: {
    ...mapActions('floors', ['fetchFloorById']),
    ...mapActions('buildings', ['fetchBuildingById']),
...mapActions('places', ['fetchFilteredPlaces']),

    async findPlaceByName() {

      // There can be more places with same name, I need only one.
      const place = this.getFirstPlace;

      if (!place) {
        this.notFound = true;
      } else {
        this.notFound = false;
        this.isIndoors = place.floorId !== null;
        this.selectedFloorId = place.floorId;
        this.selectedPlaceId = place.id;
        this.center = place.position;
        this.zoom = null;

        // From first fetch I get ID, after when isIndoors I also need the rest of floorPlaces.
        if (this.isIndoors && this.hasFloor) {
          await this.fetchFilteredPlaces(
              {
                floorId: this.$data.selectedFloorId,
                buildingId: null,
                exterior: null,
                placeName: null,
                language: null
              })
          await this.findFloor()
          await this.findBuilding()
        }
      }
    },

    setPlaceFromURL() {
      this.isIndoors = this.$route.query.building !== "ext" && this.$route.query.building !== "-1";
      this.selectedFloorId = this.$route.query.floor === 'null' ? null : parseInt(this.$route.query.floor);
      this.selectedPlaceId = parseInt(this.$route.query.place);
      this.selectedBuildingId = this.isIndoors ? parseInt(this.$route.query.building) : null;

      const coordinateParts = this.$route.query.center.split(",");
      this.center = [{
        lat: parseFloat(coordinateParts[0]),
        lon: parseFloat(coordinateParts[1])
      }];

      this.zoom = parseInt(this.$route.query.zoom);
    },

    async findFloor() {
      if (this.isIndoors && this.hasFloor) {
        await this.fetchFloorById(this.selectedFloorId);
        this.selectedBuildingId = this.floor.buildingId;
      }
    },

    async findBuilding() {
      if(this.isIndoors) {
        await this.fetchBuildingById(this.selectedBuildingId)
      }
    },

    showPlaces() {
      this.mapData = this.placesByFloor(this.selectedFloorId).map(p => ({
        id: p.id,
        type: p.type,
        position: p.position,
        name: p.localizedAttributes[this.currentLanguage]?.name ?? this.$t('map.defaults.no_name')
      }));
    },

  },
}
</script>

<style scoped>

.w-100 {
  width: 100%;
}

.map-component {
  z-index: 1;
}

#map-widget {
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.wrapper >>> .content {
  padding: 0;
  margin: 0;
}

.infobox {
  position: fixed;
  z-index: 2;
  right: 15px;
  top: 10px;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 10px;
}

.v-alert a {
  color: white !important;
}

</style>
