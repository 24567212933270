<template>
    <div class="loggout-box">
        <h2>Byl(a) jste odhlášen(a).</h2>
        <PrimaryButton :to="{name: 'dashboard'}">Přihlásit se</PrimaryButton>
    </div>
</template>

<script>
    import PrimaryButton from "../../components/buttons/PrimaryButton";

    export default {
        name: "LoggedOut",

        components: {
            PrimaryButton
        },
    }
</script>

<style scoped>
.loggout-box {
  margin: 32vh auto;
  text-align: center;
}
</style>
