const state = {
  objectNotFound: false,
};

const getters = {
  objectNotFound: state => state.objectNotFound,
};

const actions = {
  clear({ commit }) {
    commit('clear');
  },
};

const mutations = {
  clear: state => state.objectNotFound = false,
  objectNotFound: state => state.objectNotFound = true
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
