import {get, put} from "@/services/api";

const state = {
    schedules: [],
    schedulesByFaculty: [],

    faculties: ['AF', 'LDF', 'PEF', 'ZF', 'FRRMS', 'ICV'],

    didLoadedAllSchedules: false,
    didLoadedSchedulesByFaculty: false,
};

const getters = {
    getAllSchedules: state => state.schedules,
    getSchedulesByFaculty: state => state.schedulesByFaculty,
    getFaculties: state => state.faculties,
    getDidLoadedAllSchedules: state => state.didLoadedAllSchedules,
    getDidLoadedSchedulesByFaculty: state => state.didLoadedSchedulesByFaculty,
};

const actions = {

    // Fetch from DB
    async fetchAllSchedules({ commit }) {
        commit('setDidLoadedAllSchedules', false);
        const response = await get('schedules/')
        commit('setSchedules', response.data.items);
        commit('setDidLoadedAllSchedules', true);
    },

    // Fetch from DB by faculty
    async fetchSchedulesByFaculty({ commit }, faculty) {
        commit('errors/clear', null, { root: true });
        commit('setDidLoadedSchedulesByFaculty', false);
        try {
            const response = await get('schedules/' + faculty)
            commit('setSchedulesByFaculty', response.data.items);
            commit('setDidLoadedSchedulesByFaculty', true);
        } catch(e) {
            if (e.statusCode === 404)
                commit('errors/objectNotFound', null, { root: true });
            else throw e;
        }
    },

    // Parse from uis and return, but don's save to DB/FB
    async parseSchedulesByFaculty({ commit }, faculty) {
        commit('errors/clear', null, {root: true});
        commit('setDidLoadedSchedulesByFaculty', false);
        try {
            const response = await get('schedules/download/' + faculty)
            commit('setSchedulesByFaculty', response.data.items);
            commit('setDidLoadedSchedulesByFaculty', true);
        } catch(e) {
            if (e.statusCode === 404)
                commit('errors/objectNotFound', null, { root: true });
            else throw e;
        }
    },  

    async updateSchedulesByFaculty({ commit }, { item, faculty }) {
        try {
            const response = await put('schedules/' + faculty, item)
            commit('setSchedulesByFaculty', response.data.items);
        } catch(e) {
            if (e.statusCode === 404)
                commit('errors/objectNotFound', null, { root: true });
            else throw e;
        }
    }

};

const mutations = {

    setSchedules: (state, schedules) => {
        state.schedules = schedules;
    },

    setSchedulesByFaculty: (state, schedulesByFaculty) => {
        state.schedulesByFaculty = schedulesByFaculty;
    },

    setDidLoadedAllSchedules: (state, value) => {
        state.didLoadedAllSchedules = value;
    },

    setDidLoadedSchedulesByFaculty: (state, value) => {
        state.didLoadedSchedulesByFaculty = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}