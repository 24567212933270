<template>
    <v-container fluid>
        <v-row
            v-for="answer in filteredAnswers"
            :key="answer.id"
        >
            <v-card class="mb-6 mx-3 answer-card" :style="isCorrect(answer) ? 'border: 3px solid #78BE13;' : ''" elevation="0">
                <v-row class="align-center py-4" justify="space-between">
                    <v-col class="align-center pl-md-6" cols="auto">
                        <!-- if type of answer is one correct answer or true false answer, radio select is shown -->
                        <v-radio-group v-if="typeOfAnswers === questionTypes.TrueFalse.value || typeOfAnswers === questionTypes.SingleAnswer.value" class="py-0" v-model="correctAnswer" @change="updateAnswers()">
                            <v-radio
                                class="py-0"
                                :value="answer.id"
                            >
                            </v-radio>
                        </v-radio-group>
                        <!-- if type of answer is multiple correct answers, checkbox select is shown -->
                        <v-checkbox
                            v-if="typeOfAnswers === questionTypes.MultipleAnswers.value"
                            class="py-0"
                            v-model="multipleCorrectAnswers"
                            :value="answer.id"
                            @change="updateAnswers()"
                        >
                        </v-checkbox>
                    </v-col>
                    <v-col cols="6" sm="8" md="10" class="px-0">
                        <v-row>
                            <v-col cols="12" md="6" v-for="language in answerLanguages" :key="language">
                                <v-text-field
                                    class="py-0"
                                    hide-details
                                    color="#78BE13"
                                    :label="isCorrect(answer) ? language === 'cz' ? $t('gamification.correct') : $t('gamification.correctEN') : language === 'cz' ? $t('gamification.wrong') : $t('gamification.wrongEN')"
                                    v-model="answer.localizedAttributes[language].text"
                                    :rules="[required_rule]"
                                    @change="updateAnswers()"
                                    :readonly="typeOfAnswers === questionTypes.TrueFalse.value"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="align-center pr-md-6" cols="auto">
                        <!-- we want to make sure user cannot get to state where there is less than two answer that's why delete icon is not shown in that case -->
                        <v-icon v-if="typeOfAnswers !== questionTypes.TrueFalse.value && filteredAnswers.length > 2" color="#f34a43" @click="removeAnswer(answer.id)">
                            delete
                        </v-icon>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
    </v-container>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export  default {
    name: "AnswersComponent",

    data() {
        return {
            selectedCorrectAnswer: 0,
            selectedMultipleCorrectAnswers: [],
            componentAnswers: [],
            answerLanguages: ["cz", "en"],
            yesNoAnswers: [],
            hasTrueFalse: false,
            required_rule: value => !!value || 'Povinné',
            questionTypes: null
        }
    },

    props: {
        answers: [],

        typeOfAnswers: {
            type: Number,
            defaultValue: 1
        },

        resetedAnswers: {
            type: Boolean,
            defaultValue: false
        }
    },

    created() {
        this.questionTypes = this.getQuestionTypes
        this.componentAnswers = [...this.answers]

        // we want to check if there are yes / no answers created already. in that case we will not create them in future because it would override the old ones
        if (this.typeOfAnswers === this.questionTypes.TrueFalse.value) {
            this.hasTrueFalse = true
        }

        this.displayCorectivity()
    },

    computed: {
        ...mapGetters('Answers', ['prepareNewAnswer', 'getYesNoText']),
        ...mapGetters('Questions', ['getQuestionTypes']),

        filteredAnswers() {
            if (this.resetedAnswers) {
                this.resetAnswers()
            }

            this.checkTypeOfAnswers()

            if (this.typeOfAnswers === this.questionTypes.TrueFalse.value && !this.hasTrueFalse) {
                this.updateAnswers()
                return this.yesNoAnswers
            } else {
                this.updateAnswers()
                return this.componentAnswers
            }
        },

        correctAnswer: {
            set(val) {
                this.selectedCorrectAnswer = val
                if (this.typeOfAnswers === this.questionTypes.TrueFalse.value && !this.hasTrueFalse) {
                    this.yesNoAnswers.map(ans => {
                        ans.correct = this.selectedCorrectAnswer === ans.id;
                    })
                } else {
                    this.componentAnswers.map(ans => {
                        ans.correct = this.selectedCorrectAnswer === ans.id;
                    })
                }
                this.updateAnswers()
            },
            get() {
                return this.selectedCorrectAnswer
            }
        },

        multipleCorrectAnswers: {
            set(val) {
                this.selectedMultipleCorrectAnswers = val
                this.componentAnswers.map(ans => {
                    ans.correct = this.selectedMultipleCorrectAnswers.some(multipleAnswer => multipleAnswer === ans.id)
                })
            },
            get() {
                return this.selectedMultipleCorrectAnswers
            }
        }

    },

    methods: {
        ...mapActions('Answers', ['createAnswer', 'deleteAnswerById']),

        async removeAnswer(answerId) {
            this.componentAnswers = this.componentAnswers.filter(answer => answer.id !== answerId)
            if (answerId >= 0) {
                await this.deleteAnswerById(answerId)
            }
            this.updateAnswers()
        },

        prepareYesNoAnswers() {
            this.yesNoAnswers = []
            const textForYesNoAnswers = this.getYesNoText
            textForYesNoAnswers.map(answer => {
                const preparedAnswer = this.prepareNewAnswer()
                preparedAnswer.id = answer.id
                preparedAnswer.localizedAttributes.cz.text = answer.cz
                preparedAnswer.localizedAttributes.en.text = answer.en
                this.yesNoAnswers.push(preparedAnswer)
            })
            this.selectedCorrectAnswer = 0
            this.selectedMultipleCorrectAnswers = []

        },

        updateAnswers() {
            // sets parameter toDelete to true for questions that are not yes/no so they will be deleted and only yes/no questions will remain
            if (this.typeOfAnswers === this.questionTypes.TrueFalse.value) {
                this.componentAnswers.map(ans => {
                    ans.toDelete = !this.hasTrueFalse
                })
                this.yesNoAnswers.map(ans => {
                    ans.toDelete = false
                })

                // we have to send also questions from componentAnswers so they can be deleted on backend
                let answersCombined = [...this.componentAnswers, ...this.yesNoAnswers]
                this.$emit('update-answers', answersCombined)
            } else {
                this.componentAnswers.map(ans => {
                    ans.toDelete = false
                })
                this.$emit('update-answers', this.componentAnswers)
            }
        },

        isCorrect(answer) {
            if (this.typeOfAnswers === this.questionTypes.MultipleAnswers.value) {
                let isFound = this.selectedMultipleCorrectAnswers.some(multipleAnswer => multipleAnswer === answer.id)
                return !!isFound;
            } else {
                return (this.selectedCorrectAnswer === answer.id)
            }
        },

        addNewAnswer() {
            const answer = this.prepareNewAnswer()
            answer.id = -this.componentAnswers.length-1
            this.componentAnswers.push(answer)
        },

        resetAnswers() {
            this.componentAnswers = []
            this.selectedCorrectAnswer = 0
            this.selectedMultipleCorrectAnswers = []
            this.addNewAnswer()
            this.addNewAnswer()
        },

        displayCorectivity() {
            if (this.typeOfAnswers !== this.questionTypes.MultipleAnswers.value) {
                this.componentAnswers.map(answer => answer.correct ? this.selectedCorrectAnswer = answer.id : '')
            } else {
                this.componentAnswers.map(answer => answer.correct ? this.selectedMultipleCorrectAnswers.push(answer.id) : '')
            }
        },

        checkTypeOfAnswers() {
            // if type of answers is set to true/false we want to creates two answers with yes/no filled text fields
            if (this.typeOfAnswers === this.questionTypes.TrueFalse.value && !this.hasTrueFalse) {
                this.prepareYesNoAnswers()
            }

            this.displayCorectivity()

        }
    }
}
</script>
<style scoped>
    .answer-card {
        background-color: #F5F5F5 !important;
        border-radius: 8px;
        width: 100%;
    }
</style>