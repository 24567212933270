<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <div v-else class="stack">

        <h3 data-cy="call-in-turn-heading">{{ $t('checkIn.wating_for') }}</h3>

        <img :src="imageSrc" class="animal-image">

        <div class="block-with-icon">
            <v-icon>confirmation_number</v-icon>
            <span data-cy="call-in-turn-ticket-number"> {{ modelToShow.ticketNumber }}</span>
        </div>

        <div class="block-with-icon list-items-wrapper">
            <v-icon>list_alt</v-icon>
            <div class="list-items">
                <div v-for="item in modelToShow.studyProblemsFull" :key="item">
                    {{ item }}
                </div>
            </div>
        </div>

        <div class="block-with-icon">
            <v-icon>schedule</v-icon>
            <span> {{ $t('checkIn.scheduled_time') }} {{ modelToShow.time }} </span>
        </div>

        <div class="block-with-icon">
            <v-icon>person</v-icon>
            <span> {{ modelToShow.studentXlogin }} </span>
        </div>

        <Timer></Timer>

        <v-btn rounded large color="primary" class="mt-5" @click="didCome()" id="student-come-btn"> {{ $t('checkIn.arrived') }}</v-btn>
        <v-btn rounded dark color="red" class="mt-3" @click="didNotCome()" id="student-did-not-come-btn"> {{ $t('checkIn.hasnt_arrived') }}</v-btn>

    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Loading from "../../../../components/Loading";
import { put } from '@/services/api'
import moment from "moment";
import Timer from "./Timer/Timer";
import {config} from "@/config";

export default {
    name: "CallInTurn",

    components: {Loading, Timer},

    data() {
        return {
            modelToShow: {
                ticketNumber: null,
                time: null,
                studyProblems: null,
                studyProblemsFull: [],
                studentXlogin: null,
                imagesId: null,
            },
        }
    },

    mounted() {
        this.setModelToShow();
    },

    computed: {
        ...mapGetters('queueItems', ['didLoadedQueueItems', 'getQueueItem']),
        ...mapGetters('queueItemStatuses', ['didLoadedQueueItemStatuses', 'getQueueItemStatus']),
        ...mapGetters('studyProblemsItems', ['didLoadedStudyProblemsItems', 'getStudyProblemsItem']),
        ...mapGetters('pageStorage', ['getCallInItemIdToShow']),
        ...mapGetters('studyFields', ['getStudyField', 'getCountDownTimerId']),

        didLoadedAll() {
            return this.didLoadedStudyProblemsItems && this.didLoadedQueueItemStatuses && this.didLoadedQueueItems;
        },

      imageSrc() {
        return `${config.serverUrl}study_department/images/${this.modelToShow.imagesId}`;
      }
    },

    methods: {
        ...mapActions('queueItemStatuses', ['setQueueItemStatusesUpdate']),

        didCome: function () {
            this.setQueueStatusDone();
        },

        didNotCome: function () {
            this.setQueueStatusCancel();
        },

        onScroll(e) {
            this.offsetTop = e.target.scrollTop
        },

        setModelToShow() {
            if (this.getQueueItem(this.getCallInItemIdToShow) !== null) {
                let queueItem = this.getQueueItem(this.getCallInItemIdToShow);
                let queueItemStatus = this.getQueueItemStatus(queueItem.queueItemStatusId);
                let studyProblems = "";

                if (queueItem.studyProblemsItemsIds.length !== 0) {
                    for (let problem of queueItem.studyProblemsItemsIds) {
                        studyProblems += this.getStudyProblemsItem(problem).name + ' ';
                        this.modelToShow.studyProblemsFull.push(this.getStudyProblemsItem(problem).name)
                    }
                } else {
                    this.modelToShow.studyProblemsFull.push(this.getStudyField(queueItem.studyFieldId).name)
                    studyProblems = this.getStudyField(queueItem.studyFieldId).name;
                }

                if (studyProblems.length > 90) {
                    studyProblems = studyProblems.slice(0, 87) + "..."
                }

                this.modelToShow.ticketNumber = queueItemStatus.ticketNumber;
                this.modelToShow.time = moment(queueItemStatus.actualMeetTime, "HH:mm:ss").format("HH:mm")
                this.modelToShow.studyProblems = studyProblems;
                this.modelToShow.imagesId = queueItem.imagesId;

                if (queueItem.xname != null && queueItem.xname != 'Empty' && queueItem.xname != undefined && queueItem.xname != "") {
                    this.modelToShow.studentXlogin = queueItem.xname;
                } else {
                    this.modelToShow.studentXlogin = this.$t('checkIn.anon_student');
                }
            }
        },

        setQueueStatusDone() {
            let queueItem = this.getQueueItem(this.getCallInItemIdToShow);
            let queueItemStatus = this.getQueueItemStatus(queueItem.queueItemStatusId);

            put(`queue/demand/status/${queueItemStatus.id}`, {
                "actualMeetEnd": queueItemStatus.actualMeetEnd,
                "actualMeetStart": queueItemStatus.actualMeetTime,
                "duration": queueItemStatus.duration,
                "queueItemId": queueItem.id,
                "status": "doing",
                "ticketNumber": queueItemStatus.ticketNumber,
                "wantedDay": queueItemStatus.wantedDay,
                "userUUID": queueItemStatus.userUUID,
                "queueItemUUID": queueItemStatus.queueItemUUID,
                "priority": false,
            }).catch(e => alert(e.message));
        },

        setQueueStatusCancel() {
            let queueItem = this.getQueueItem(this.getCallInItemIdToShow);
            let queueItemStatus = this.getQueueItemStatus(queueItem.queueItemStatusId);

            put(`queue/demand/status/${queueItemStatus.id}`, {
                "actualMeetEnd": queueItemStatus.actualMeetEnd,
                "actualMeetStart": queueItemStatus.actualMeetTime,
                "duration": queueItemStatus.duration,
                "queueItemId": queueItem.id,
                "status": "cancel",
                "ticketNumber": queueItemStatus.ticketNumber,
                "wantedDay": queueItemStatus.wantedDay,
                "userUUID": queueItemStatus.userUUID,
                "queueItemUUID": queueItemStatus.queueItemUUID,
                "priority": false,
            }).catch(e => alert(e.message));
        },
    },
}

</script>

<style scoped>
</style>
