<template>
    <div class="stack">
        <h3>{{ $t('checkIn.waiting') }}</h3>

        <span class="mt-2 text-center">{{ $t('checkIn.no_waiting_hold') }}</span>

        <div class="mt-10">
            <pause-dialog></pause-dialog>
        </div>
    </div>
</template>

<script>
    import PauseDialog from "../Dialogs/PauseDialog";
    export default {
        name: "CallInFallback",
        components: {PauseDialog},
        methods: {

            resetButton() {
                // to CheckInTable
                this.$root.$emit('CallInFallBackCallReset');
            }

        }
    }
</script>

<style scoped>
</style>