import {get} from '@/services/api'

const state = {
    placeComputers: [],
    placeComputerSoftware: []
};

const getters = {
    placeComputers: state => state.placeComputers,
};

const actions = {
    async fetchComputersById({commit}, id) {
        const response = await get(`iot/computers/place/${id}`)
        const placeComputers = response.data.items
        commit('setPlaceComputers', placeComputers)
    },
};

const mutations = {
    setPlaceComputers: (state, placeComputers) => (state.placeComputers = placeComputers),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}