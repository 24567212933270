import {del, get, post, put} from '@/services/api'
import Vue from "vue";

const state = {
    didLoadAdministrators: false,
    administrators: [],
    error: false
};

const getters = {
    didLoadAdministrators: state => state.didLoadAdministrators,
    getAdministrators: state => state.administrators,
    getAdministratorById: state => id => state.administrators.find(obj => obj.id === id),
    getErr: state => state.error,
};

const actions = {
    async fetchAdministrators({commit}) {
        commit('setDidLoadAdministrators', false);
        try{
            await get(`displays/admin/`).then((response) => {
                commit('setAdministrators', response.data.items);
                commit('setDidLoadAdministrators', true);
            });
        } catch (e) {
            if (e.statusCode !== 403) throw e
            commit('errors/objectNotFound', null, {root: true});
        }
    },
    async deleteAdministrator({commit}, id) {
        await del(`displays/admin/${id}`).then(() => {
            commit('filterAdministrator', id);
        });
    },
    async updateAdministrator({commit}, {id, request}) {
        const response = await put(`displays/admin/${id}`, request);
        commit('updateAdministrator', response.data.content);
    },

    async createAdministrator({commit}, request) {
        commit('setError', false);
        try {
            const response = await post(`displays/admin/`, request);
            commit('setAdministrator', response.data.content);
        } catch (e) {
            if (e.error === 409) {
                commit('setError', true);
            } else {
                throw e;
            }
        }
    },

    async setErr({commit}, request) {
        commit('setError', request);
    },

};

const mutations = {
    setDidLoadAdministrators: (state, status) => (state.didLoadAdministrators = status),
    setAdministrators: (state, administrator) => (state.administrators = administrator),
    filterAdministrator: (state, id) => (state.administrators = state.administrators.filter(obj => obj.id !== id)),
    updateAdministrator: (state, item) => {
        let index = state.administrators.findIndex(user => user.id === item.id);
        Vue.set(state.administrators, index, item);
    },
    setAdministrator: (state, item) => (state.administrators.push(item)),
    setError: (state, err) => (state.error = err),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
