export default {
  // common
  'noName': 'Bez názvu',

  // languages
  'cz': 'čeština',
  'en': 'angličtina',

  //report type
  'idea': 'nápad',
  'problem': 'problém',

  //report state
  'pending': 'čekající',
  'inProgress': 'v řešení',
  'solved': 'vyřešeno',
  'rejected' : 'zamítnuto',

  //Component block translations
  'empty-category' : '-Bez kategorie-',

  //Place Access types
  'Neomezený přístup' : 'Neomezený přístup',
  'Přístup pouze s odpovědnou osobou' : 'Přístup pouze s odpovědnou osobou',
  'Přístup pouze s kartou' : 'Přístup pouze s kartou',
}
