<template>

    <Loading v-if="validateLoading"></Loading>

    <div v-else class="mt-4">

        <module-header>
            <h2 v-if="isNewReservation">{{ $t('reservations.create_new_res') }}</h2>
            <h2 v-else>{{ $t('reservations.res_editing') }} ({{queueItemAndStatus.ticketNumber}})</h2>

            <v-spacer/>

            <v-btn @click="goBack(assistantId)" class="close_button button margin_left">{{ $t('reservations.close') }}</v-btn>

            <v-dialog v-model="dialog" persistent max-width="290">
                <template v-slot:activator="{ on }">
                    <v-btn v-if="isNewReservation" :disabled="!isFormValid"
                            id="save-reservation"
                            @click="sendNew"
                            color="primary"
                            rounded
                            class="button margin_left"
                            v-on="on">
                        {{ $t('reservations.save') }}
                    </v-btn>
                    <v-btn v-else :disabled="!isFormValid"
                            id="save-reservation-edit"
                            @click="sendEdit"
                            color="primary"
                            rounded
                            class="button margin_left"
                            v-on="on">
                        {{ $t('reservations.save') }}
                    </v-btn>
                </template>

                <v-card v-if="httpError !== 409">
                    <v-card-title class="headline">{{ $t('reservations.res_saved') }}</v-card-title>
                    <v-card-text v-if="isNewReservation"> {{ $t('reservations.res_saved_id') }}
                        {{queueItemAndStatus.ticketNumber}}
                    </v-card-text>
                    <v-card-text v-else> {{ $t('reservations.res_changed') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="goBackSuccesfully(assistantId)">{{ $t('reservations.ok') }}</v-btn>
                    </v-card-actions>
                </v-card>

                <v-card v-else>
                    <v-card-title class="headline">{{ $t('reservations.res_not_save') }}</v-card-title>
                    <v-card-text v-if="isNewReservation"> {{ $t('reservations.res_not_save_error') }}.
                    </v-card-text>
                    <v-card-text v-else> {{ $t('reservations.res_not_saved') }}.
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text> {{ $t('reservations.if_you_want') }}.
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" style="float: left" text @click="sendNewBypass()">{{ $t('reservations.yes') }}
                        </v-btn>
                        <v-btn color="green darken-1" text @click="goBackUnsuccesfully()">{{ $t('reservations.cancel_unsuccesful_modal')
                            }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-menu v-if="!isNewReservation" top :offset-x="true">
                <template v-slot:activator="{ on }">
                    <v-btn id="edit-menu-btn" icon v-on="on"><v-icon>more_vert</v-icon></v-btn>
                </template>
                <v-list>
                    <v-list-item id="delete-reservation-btn" @click="deleteQueueItem">
                        <v-list-item-title class="primary--text">{{ $t('reservations.res_delete') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </module-header>

        <v-form ref="form"
                v-model="valid" style="width: 100%;">
            <v-container>
                <v-row>
                    <v-col cols="8">
                        <v-layout row class="no_margin">
                            <v-flex xs6 class="v_flex_span_date float_left">
                                <span class="text">1. {{ $t('reservations.res_date') }}</span>
                            </v-flex>
                            <v-flex xs6 class="float_left">
                                <v-menu
                                        v-model="menu_calendar"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                v-model="computedDateFormatted"
                                                :label="$t('reservations.pick_res_date')"
                                                readonly
                                                v-on="on"
                                                class="no_margin_no_padding"
                                                solo
                                                aria-required="true"
                                                :rules="dateRules"
                                                :hide-details="true"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker locale="cs"
                                                v-model="queueItemAndStatus.wanted_day"
                                                no-title
                                                :min="today"
                                                :allowed-dates="allowedDates"
                                                @input="menu_calendar = false"></v-date-picker>
                                </v-menu>
                            </v-flex>
                        </v-layout>

                        <v-layout row class="no_margin top">
                            <v-flex xs6 class="float_left">
                                <span class="text">2. {{ $t('reservations.res_person') }}</span>
                            </v-flex>

                            <v-flex v-if="isNewReservation" xs6 class="float_left">
                                <v-text-field solo
                                            id="person-name"
                                            :rules="nameRules"
                                            required
                                            v-model="queueItemAndStatus.name"
                                            :label="$t('reservations.type_res_person')">
                                </v-text-field>
                            </v-flex>

                            <v-flex v-else xs6 class="float_left">
                                <v-text-field solo
                                            disabled
                                            v-model="queueItemAndStatus.name">
                                </v-text-field>
                            </v-flex>
                        </v-layout>

                        <v-layout row class="no_margin">
                            <v-flex xs6 class="float_left">
                                <span class="text">3. {{ $t('reservations.xname') }}</span>
                            </v-flex>

                            <v-flex v-if="isNewReservation" xs6 class="float_left">
                                <v-text-field solo
                                            id="person-xname"
                                            :rules="xnameRules"
                                            v-model="queueItemAndStatus.xname"
                                            :label="$t('reservations.type_xname')"
                                            >
                                </v-text-field>
                            </v-flex>

                            <v-flex v-else xs6 class="float_left">
                                <v-text-field solo
                                            disabled
                                            v-model="queueItemAndStatus.xname">
                                </v-text-field>
                            </v-flex>
                        </v-layout>

                        <v-layout row class="no_margin">
                            <v-flex xs6>
                                <span class="text">4. {{ $t('reservations.res_stud_field') }}</span>
                            </v-flex>

                            <v-flex>
                                <v-flex class="no_margin_no_padding" xs12 row>
                                    <v-flex xs12>
                                        <study-type-field-dialog v-if="isNewReservation"></study-type-field-dialog>
                                        <study-type-field-dialog v-else
                                                v-bind:fieldId="queueItemAndStatus.studyFieldId"></study-type-field-dialog>
                                    </v-flex>
                                </v-flex>
                            </v-flex>
                        </v-layout>

                        <v-layout v-if="!isOtherSelected" row class="top_plus">
                            <v-flex xs6>
                                <span class="text">5. {{ $t('reservations.request') }} </span>
                            </v-flex>

                            <v-flex>
                                <v-flex class="no_margin_no_padding" xs12 row v-for="(requiremen, index) in requirements"
                                        :key="requiremen.id">
                                    <v-flex xs11 class="top_plus">
                                        <requirement-dialog v-if="isNewReservation" @setCategoryAndItem="setRequirementsAtIndex"
                                                            v-bind:index="index"
                                                            from="New"
                                                            v-bind:requirem="requiremen"
                                                            v-bind:idOfSelectedItems="sencondaryGetIdsOfProblems"></requirement-dialog>
                                        <requirement-dialog v-else @setCategoryAndItemEdit="setRequirementsAtIndex"
                                                            v-bind:index="index"
                                                            v-bind:requirem="requiremen"
                                                            from="Edit"
                                                            v-bind:idOfSelectedItems="sencondaryGetIdsOfProblems"
                                                            required
                                        ></requirement-dialog>
                                    </v-flex>
                                    <v-flex xs1 class="float_left top_plus">
                                        <v-icon class="minus_sign" @click="removeRequirement(index)">fas fa-minus-circle</v-icon>
                                    </v-flex>
                                </v-flex>
                                <v-flex id="add-request" class="no_margin_no_padding top_plus" xs12 row>
                                    <span class="add_new_reruirement_text pointer" @click="addNewRequirement">
                                        <v-icon left>fas fa-plus-circle</v-icon>
                                        {{ $t('reservations.add_request') }}
                                    </span>
                                </v-flex>
                            </v-flex>
                        </v-layout>

                      <v-layout v-if="!isNewReservation" row class="no_margin">
                        <v-flex xs6 class="float_left">
                          <span class="text">{{ returnCorrectNumbering(6)}}. {{ $t('reservations.type_ticket') }}</span>
                        </v-flex>

                            <v-flex xs6 class="float_left">
                                <v-select
                                        data-cy="reservation-edit-status-select"
                                        v-model="queueItemAndStatus.state"
                                        :items="statusItems"
                                        :rules="[v => !!v || $t('entities.required')]"
                                        required
                                        solo
                                        item-value="value"
                                        return-object
                                ></v-select>
                            </v-flex>
                        </v-layout>


                        <v-layout v-if="!isNewReservation" row class="no_margin">
                          <v-flex xs6 class="float_left">
                            <span class="text">{{ returnCorrectNumbering(7)}}. {{ $t('reservations.assigned_assistant') }}</span>
                          </v-flex>

                            <v-flex xs6 class="float_left">
                                <v-select
                                        data-cy="reservation-edit-assistant-select"
                                        v-model="queueItemAndStatus.assistant"
                                        :items="getAssistantsForDropdown"
                                        :rules="[v => !!v || $t('entities.required')]"
                                        required
                                        solo
                                        item-value="value"
                                        return-object
                                ></v-select>
                            </v-flex>
                        </v-layout>


                          <v-layout row class="time_range_text">
                            <v-flex xs6>
                              <span v-if="!isOtherSelected" class="text">{{returnCorrectNumbering(isNewReservation ? 6 : 8)}}. {{ $t('reservations.timespan_request') }}</span>
                              <span v-else class="text">{{isNewReservation ? returnCorrectNumbering(6) : 7}}. {{ $t('reservations.timespan_request') }}</span>
                            </v-flex>

                            <!--Cas od-->

                            <v-flex row xs1
                                    class="no_margin_no_padding">
                                <v-flex>
                                    <v-menu
                                            ref="menu_time_from2"
                                            v-model="menu_time_from2"
                                            :close-on-content-click="false"
                                            :nudge-right="65"
                                            :return-value.sync="queueItemAndStatus.time_from"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="queueItemAndStatus.time_from"
                                                    :label="$t('reservations.time_from')"
                                                    v-on="on"
                                                    class="text_align"
                                                    readonly
                                                    aria-required="true"
                                                    :rules="timeRules"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                                v-if="menu_time_from2"
                                                v-model="queueItemAndStatus.time_from"
                                                full-width
                                                format="24hr"
                                                scrollable
                                                header-color="rgb(118, 190, 26)"
                                                @click:minute="$refs.menu_time_from2.save(queueItemAndStatus.time_from)"
                                                :min="getOpenTime"
                                                :max="getCloseTime"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-flex>
                            </v-flex>

                            <v-flex row
                                    class="no_margin_no_padding ml-3 secondary--text">
                                    <small>({{$t('reservations.time_length')}} {{this.computeTimeLasting()}} min)</small>
                            </v-flex>
                        </v-layout>


                        <v-layout row class="no_margin">
                            <v-flex xs6>
                                <span class="text display_block">{{returnCorrectNumbering(isNewReservation ? 7 : 9)}}. {{ $t('reservations.req_note') }}</span>
                                <span class="text text_smoll display_block"> ({{ $t('reservations.req_note_no_stud') }})</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-textarea
                                        v-model="queueItemAndStatus.note"
                                        solo
                                        auto-grow
                                        dense
                                        :hide-details="true"
                                        counter>
                                </v-textarea>
                            </v-flex>
                        </v-layout>
                    </v-col>

                    <v-col cols="4">
                        <v-card>
                            <v-card-title>{{ $t('reservations.timeslots_free') }}</v-card-title>
                            <v-card-text>
                                <div v-if="freeTimeSlots.length > 0">
                                    <div
                                            v-for="(item, i) in freeTimeSlots"
                                            :key="i"
                                            class="mb-2"
                                    >
                                        <span>{{ $t('reservations.timeslots_from') }}: {{formatTime(item.startOfFreeSlot)}} {{ $t('reservations.timeslots_to') }}: {{formatTime(item.endOfFreeSlot)}} </span>
                                    </div>
                                </div>

                                <div v-else>
                                    {{ $t('reservations.timeslots_no_free') }}
                                </div>

                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
import {del, post, put} from '@/services/api'
    import RequirementDialog from "../../Dialogs/RequirementDialog";
    import StudyTypeFieldDialog from "../../Dialogs/StudyTypeFieldDialog";
    import moment from 'moment';
    import {mapGetters, mapActions} from 'vuex';
    import Loading from "../../../../../components/Loading";
    import ModuleHeader from '../../../../../components/layout/ModuleHeader.vue';
    import {getOpeningTimeForReservation, getClosingTimeForReservation} from '../../utils';

    export default {
        name: "ReservationDetailEdit",

        components: {
            RequirementDialog,
            StudyTypeFieldDialog,
            Loading,
            ModuleHeader,
        },

        props: {
            assistantId: null,
            selectedDay: null
        },

        data() {
            let Rid = this.$route.params.Rid;

            return {
                proRid: Rid,

                dateRules: [
                    v => !!v || this.$t('reservations.date_required'),
                ],

                timeRules: [
                    v => !!v || this.$t('reservations.time_required'),
                ],

                nameRules: [
                    v => !!v || this.$t('reservations.name_required'),
                    v => (v && v.length >= 2) || this.$t('reservations.name_length'),
                ],

                xnameRules: [
                    v => (v && v.length >= 2 && /^[a-z0-9]+$/.test(v)) || this.$t('reservations.xname_format'),
                ],

                today: moment().toISOString(),

                valid: true,
                form: null,
                dialog: false,

                menu_calendar: false,
                menu_time_from2: false,
                menu_time_to2: false,

                could_save: true,
                resp: null,

                requirements: [],
                requirementsOrig: [],

                statusItems: [
                    {text: this.$t('reservations.not_served'), value: 'created'},
                    {text: this.$t('reservations.cancel_user'), value: 'abort'},
                    {text: this.$t('reservations.cancel_study_ref'), value: 'cancel'},
                    {text: this.$t('reservations.served'), value: 'done'},
                    {text: this.$t('reservations.next_in_line'), value: 'active'},
                    {text: this.$t('checkIn.doing'), value: 'doing'},
                ],

                queueItemAndStatus: {
                    wanted_day: null,
                    name: null,
                    studyFieldId: null,
                    studyProblemsItemsIds: [],
                    note: null,
                    time_from: null,
                    time_to: null,
                    xname: null,
                    ticketNumber: null,
                    state: null,
                    assistant: null,
                },

                queueItemAndStatusOrig: {
                    wanted_day: null,
                    name: null,
                    studyFieldId: null,
                    studyProblemsItemsIds: [],
                    note: null,
                    time_from: null,
                    time_to: null,
                    xname: null,
                    ticketNumber: null,
                    state: null,
                    assistant: null,
                },

                hasPreparedModel: false,

                freeTimeSlots: [],

                panel: 0,

                httpError: null,
            }
        },

        async created() {
            if (this.isNewReservation) {
                await Promise.all([
                    this.fetchAssistants(),
                    this.fetchStudyFields(),
                    this.fetchStudyProblemsItems(),
                    this.fetchStudyProblemsCategories(),
                    this.fetchStudyOpenHours(),
                ])

                this.setSelectedDayFromInfobar();
                this.setAssistantIdFromInfobar();

                this.returnCorrectTime(this.selectedDay);
            } else {
                await this.createdByPropsDefined();
                this.getFreeTimeSlotsForAssistant(this.queueItemAndStatus.wanted_day);
            }

            this.hasPreparedModel = true;
        },

        mounted() {
            this.$root.$on('setFieldId', (item) => {
                this.queueItemAndStatus.studyFieldId = item;
            });
        },

        computed: {
            ...mapGetters('assistant', ['getAssistantsForDropdown', 'getAssistant', 'didLoadedAssistants']),
            ...mapGetters('queueItemStatuses', ['getQueueItemStatus', 'didLoadedQueueItemStatuses']),
            ...mapGetters('queueItems', ['getQueueItem', 'didLoadedQueueItems']),
            ...mapGetters('studyProblemsCategories', ['getStudyProblemsCategory', 'didLoadedStudyProblemsCategories']),
            ...mapGetters('StudyOpenHours', ['getStudyOpenHour', 'didLoadedStudyOpenHours', 'getStudyOpenHours']),
            ...mapGetters('studyProblemsItems', ['getStudyProblemsItem', 'didLoadedStudyProblemsItems']),
            ...mapGetters('studyFields', ['getStudyField', 'didLoadedStudyFields']),

            computedDateFormatted() {
                return this.formatDate(this.queueItemAndStatus.wanted_day);
            },

    isOtherSelected() {
      if (this.queueItemAndStatus.studyFieldId !== null) {
        if (this.findOtherStudyField(this.queueItemAndStatus.studyFieldId)) {
          this.resetRequirements();
        }
        return this.findOtherStudyField(this.queueItemAndStatus.studyFieldId);
      } else {
        return false;
      }
    },

            isNewReservation() {
                return this.proRid === "new";
            },

            queueItemStatus() {
                return this.getQueueItemStatus(this.proRid);
            },

            queueItem() {
                return this.getQueueItem(this.queueItemStatus.queueItemId);
            },

            studyField() {
                return this.getStudyField(this.queueItem.studyFieldId);
            },

            getOpenTime() {
              let openHours = this.getStudyOpenHours;
              let today = new Date(this.isNewReservation ? this.selectedDay : this.queueItemStatus.wantedDay);
              return getOpeningTimeForReservation(openHours, today);
            },

            getCloseTime() {
              let openHours = this.getStudyOpenHours;
              let today = new Date(this.isNewReservation ? this.selectedDay : this.queueItemStatus.wantedDay);
              return getClosingTimeForReservation(openHours, today);
            },

            sencondaryGetIdsOfProblems: function () {
                let arr = [];

                if (this.requirements !== []) {
                    this.requirements.forEach(item => {
                        if (item.item_object !== null && item.category_object !== null) {
                            arr.push(item.item_object.id);
                        }
                    });
                }

                return arr;
            },

            didLoadedAll() {
                return this.hasPreparedModel && this.didLoadedStudyOpenHours && this.didLoadedAssistants && this.didLoadedStudyFields && this.didLoadedStudyProblemsItems && this.didLoadedStudyProblemsCategories && this.didLoadedQueueItemStatuses && this.didLoadedQueueItems;
            },

            hasValidRequirements() {
                // at least one requirement is not empty
                return this.requirements.some(r =>
                    (r.category_object !== null && r.item_object !== null)
                );
            },

            isChanged() {
                return !this.compareOriginalAndStateNow() || !this.compareOriginalAndStateNowRequirements();
            },

            isFormValid() {
              if (this.isNewReservation) {
                if (this.isOtherSelected) {
                  return this.valid && this.queueItemAndStatus.time_from <= this.queueItemAndStatus.time_to;
                } else {
                  return this.valid && this.hasValidRequirements && this.requirements.length > 0;
                }
              }

                if (this.isOtherSelected) {
                    return this.isChanged && this.valid && this.freeTimeSlots.length > 0;
                } else {
                    return this.isChanged && this.valid && this.hasValidRequirements && this.requirements.length > 0 && this.freeTimeSlots.length > 0;
                }
            },

            validateLoading() {
                return this.didLoadedAll === false || this.propsDefined;
            },

            propsDefined() {
                return this.assistantId === undefined;
            },
        },

        methods: {
            ...mapActions('assistant', ['fetchAssistants']),
            ...mapActions('queueItemStatuses', ['fetchQueueItemStatusById']),
            ...mapActions('queueItems', ['fetchQueueItemById']),
            ...mapActions('studyProblemsCategories', ['fetchStudyProblemsCategories']),
            ...mapActions('StudyOpenHours', ['fetchStudyOpenHours']),
            ...mapActions('studyProblemsItems', ['fetchStudyProblemsItems']),
            ...mapActions('studyFields', ['fetchStudyFields']),

            setAnonym(string) {
                if (string === "Empty") {
                    return this.$t('reservations.anon_student');
                } else {
                    return string;
                }
            },

            onScroll(e) {
                this.offsetTop = e.target.scrollTop
            },

            formatTime(time) {
                return moment(time).format("HH:mm");
            },

            setModel: function () {
                this.queueItemAndStatus.name = this.setAnonym(this.queueItem.nameSurnameTitle);
                this.queueItemAndStatus.xname = this.setAnonym(this.queueItem.xname);
                this.queueItemAndStatus.note = this.queueItem.note;
                this.queueItemAndStatus.studyFieldId = this.queueItem.studyFieldId;
                this.queueItemAndStatus.studyProblemsItemsIds = this.queueItem.studyProblemsItemsIds;

                this.queueItemAndStatus.assistant = {text: this.getAssistant(this.queueItem.assistantId), value: this.queueItem.assistantId};
                this.queueItemAndStatus.time_from = this.queueItemStatus.actualMeetTime;
                this.queueItemAndStatus.time_to = this.queueItemStatus.actualMeetEnd;
                this.queueItemAndStatus.wanted_day = this.queueItemStatus.wantedDay;
                this.queueItemAndStatus.ticketNumber = this.queueItemStatus.ticketNumber;
                this.queueItemAndStatus.state = {text: this.translateStatus(this.queueItemStatus.status), value: this.queueItemStatus.status};
            },

            translateStatus(status) {
                switch (status) {
                    case 'created':
                        return this.$t('reservations.not_served');
                    case 'abort':
                        return this.$t('reservations.cancel_user');
                    case 'cancel':
                        return this.$t('reservations.cancel_study_ref');
                    case 'done':
                        return this.$t('reservations.served');
                    case 'active':
                        return this.$t('reservations.next_in_line');
                    case 'doing':
                        return this.$t('reservations.next_in_line');
                }
            },

            textItem: item => item,

            goBackSuccesfully: function (assistantId) {
                this.dialog = false;
                this.$router.push({name: "CalendarDetail", params: {assistantID: assistantId}});
            },

            setRequirements: function () {
                if (this.queueItemAndStatus.studyProblemsItemsIds !== null) {
                    let arr = this.queueItemAndStatus.studyProblemsItemsIds;

                    arr.forEach((item) => {
                        let requirement = {
                            category_object: null,
                            item_object: null,
                            id: Math.floor((Math.random() * 100000)),
                        };

                        requirement.item_object = this.getStudyProblemsItem(item);
                        requirement.category_object = this.getStudyProblemsCategory(requirement.item_object.studyProblemsCategoryId);

                        this.requirements.push(requirement);
                    });
                }
            },

            findOtherStudyField(id) {
              this.queueItemAndStatus.time_to = moment(this.queueItemAndStatus.time_from, 'HH:mm').add(this.getStudyField(id).duration, 'minutes').format("HH:mm").toString();
              return this.getStudyField(id).studyType === 'Other';
            },

            returnCorrectNumbering(number) {
                if (!this.isOtherSelected) {
                    return number;
                } else {
                    return number - 1;
                }
            },

            defaultRoundTime(t) {
                function format(v) {
                    return v < 10 ? '0' + v : v;
                }

                let m = t.split(':').reduce(function (h, m) {
                    return h * 60 + +m;
                });

                m = Math.ceil(m / 15) * 15;
                return [Math.floor(m / 60), m % 60].map(format).join(':');
            },

            returnCorrectTime(selectedDay) {

                let selected = new Date(selectedDay).setHours(0, 0, 0, 0);
                let today = new Date().setHours(0, 0, 0, 0);

                let actualTime = moment(moment().format("HH:mm"), "HH:mm").subtract(1, "day");
                let openTime = moment(this.getOpenTime, "HH:mm").subtract(1, "day");
                let closeTime = moment(this.getCloseTime, "HH:mm");

                if ((actualTime.isAfter(openTime) && actualTime.isBefore(closeTime)) && selected === today) {
                    this.queueItemAndStatus.time_from = this.defaultRoundTime(moment().format("HH:mm").toString());
                    this.queueItemAndStatus.time_to = this.defaultRoundTime(moment().format("HH:mm").toString());
                } else {
                    this.queueItemAndStatus.time_from = openTime.format("HH:mm").toString();
                    this.queueItemAndStatus.time_to = openTime.format("HH:mm").toString();
                }
            },

            resetRequirements() {
                this.requirements = [];
                this.queueItemAndStatus.studyProblemsItemsIds = [];
            },

            createCopyOfOriginal: function () {
                this.queueItemAndStatusOrig = JSON.parse(JSON.stringify(this.queueItemAndStatus));
                this.requirementsOrig = JSON.parse(JSON.stringify(this.requirements));

                JSON.parse(JSON.stringify(this.queueItemAndStatus));
            },

            compareOriginalAndStateNow: function () {
                return JSON.stringify(this.queueItemAndStatus) === JSON.stringify(this.queueItemAndStatusOrig);
            },

            compareOriginalAndStateNowRequirements: function () {
                return JSON.stringify(this.requirements) === JSON.stringify(this.requirementsOrig);
            },

            addNewRequirement: function () {
                let requirement = {
                    category_object: null,
                    item_object: null,
                    id: Math.floor((Math.random() * 100000)),
                };

                this.requirements.push(requirement);
            },

            removeRequirement: function (index) {
                this.$delete(this.requirements, index);
            },

            goBack(assistantId) {
                if (this.isNewReservation) {
                    this.$router.push({name: "CalendarDetail", params: {assistantID: assistantId}});
                } else {
                    this.$router.push({name: "ReservationDetail", params: {Rid: this.proRid, assistantId}});
                }
            },

            goBackUnsuccesfully: function () {
                this.dialog = false;
                this.httpError = null;
            },

            formatDate(date) {
                if (!date) return null;

                const [year, month, day] = date.split('-');

                return `${day}.${this.addZeroBeforeMonth(month)}.${year}`;
            },

            addZeroBeforeMonth(dayMonth) {
                if (dayMonth.length === 1) {
                    dayMonth = '0' + dayMonth;
                }
                return dayMonth
            },

            sendEdit: function () {
                this.getIDsofStudyProblemsItems();
                this.sendQueue_ItemToBackend();
            },

            sendNew: function () {
                this.getIDsofStudyProblemsItems();
                this.addqueue_ItemToBackend();
            },

            sendNewBypass: function () {
                this.addqueue_ItemToBackend_ByPass();
                this.dialog = false;
            },

            deleteQueueItem: function () {
                if (confirm(this.$t('reservations.confirm_deletion'))) {
                    del('queue/demand/' + this.proRid)
                        .then((response) => {
                            if (response.status === 204) {
                                if (confirm(this.$t('confirms.delete_succesful'))) {
                                    this.$router.push({name: "CalendarDetail", params: {assistantID: this.assistantId}});
                                } else {
                                    this.$router.push({name: "CalendarDetail", params: {assistantID: this.assistantId}});
                                }
                            } else {
                                if (confirm(this.$t('confirms.delete_unsuccesful'))) {
                                    this.$router.push({name: "CalendarDetail", params: {assistantID: this.assistantId}});
                                } else {
                                    this.$router.push({name: "CalendarDetail", params: {assistantID: this.assistantId}});
                                }
                            }
                        })
                        .catch(e => alert(e.message));
                }
            },

            getIDsofStudyProblemsItems: function () {
                if (this.requirements !== []) {
                    this.queueItemAndStatus.studyProblemsItemsIds = [];
                    for (let item of this.requirements) {
                        if (item.category_object !== null && item.item_object !== null) {
                            this.queueItemAndStatus.studyProblemsItemsIds.push(item.item_object.id);
                        }
                    }
                }
            },

            async sendQueue_ItemToBackend() {
                try {
                    const response = await put(`queue/demand/${this.queueItem.id}`, {
                      "note": this.queueItemAndStatus.note,
                      "wantedDay": this.queueItemAndStatus.wanted_day,
                      "studyFieldId": this.queueItemAndStatus.studyFieldId,
                      "timeFrom": this.queueItemAndStatus.time_from,
                      "timeTo": this.queueItemAndStatus.time_to,
                      "studyProblemsItemsIds": this.queueItemAndStatus.studyProblemsItemsIds,
                      "bypass": "false",
                      "flag": "web",
                      "status": this.queueItemAndStatus.state.value,
                      "assistantId": this.queueItemAndStatus.assistant.value,
                    });
                    this.httpError = null;
                    this.resp = response.data.content;
                } catch (e) {
                    if (e.statusCode === 409) {
                        this.httpError = 409;
                    } else {
                        throw e; // rethrow other errors to be handled by the global error handler (services/api/error-handler.js)
                    }
                }
            },

            async addqueue_ItemToBackend() {
                try {
                    const response = await post('queue/demand/', {
                      "nameSurnameTitle": this.queueItemAndStatus.name,
                      "note": this.queueItemAndStatus.note,
                      "wantedDay": this.queueItemAndStatus.wanted_day,
                      "studyFieldId": this.queueItemAndStatus.studyFieldId,
                      "timeFrom": this.queueItemAndStatus.time_from,
                      "timeTo": this.queueItemAndStatus.time_to,
                      "studyProblemsItemsIds": this.queueItemAndStatus.studyProblemsItemsIds,
                      "xname": this.queueItemAndStatus.xname,
                      "assistantId": this.queueItemAndStatus.assistantId,
                      "flag": "web",
                      "bypass": "false",
                    })
                    this.httpError = null;
                    this.queueItemAndStatus.ticketNumber = response.data.content.ticketNumber;
                } catch(e) {
                    if (e.statusCode === 409) {
                        this.httpError = 409;
                    } else {
                        throw e; // rethrow other errors to be handled by the global error handler (services/api/error-handler.js)
                    }
                }
            },

            async addqueue_ItemToBackend_ByPass() {
                if (this.isNewReservation) {
                    const response = await post('queue/demand/', {
                        "nameSurnameTitle": this.queueItemAndStatus.name,
                        "note": this.queueItemAndStatus.note,
                        "wantedDay": this.queueItemAndStatus.wanted_day,
                        "studyFieldId": this.queueItemAndStatus.studyFieldId,
                        "timeFrom": this.queueItemAndStatus.time_from,
                        "timeTo": this.queueItemAndStatus.time_to,
                        "studyProblemsItemsIds": this.queueItemAndStatus.studyProblemsItemsIds,
                        "xname": this.queueItemAndStatus.xname,
                        "assistantId": this.queueItemAndStatus.assistantId,
                        "flag": "web",
                        "bypass": "true",
                    })
                    this.queueItemAndStatus.ticketNumber = response.data.content.ticketNumber;
                } else {
                    const response = await put(`queue/demand/${this.queueItem.id}`, {
                        "note": this.queueItemAndStatus.note,
                        "wantedDay": this.queueItemAndStatus.wanted_day,
                        "studyFieldId": this.queueItemAndStatus.studyFieldId,
                        "timeFrom": this.queueItemAndStatus.time_from,
                        "timeTo": this.queueItemAndStatus.time_to,
                        "studyProblemsItemsIds": this.queueItemAndStatus.studyProblemsItemsIds,
                        "bypass": "true",
                        "status": this.queueItemAndStatus.state.value,
                        "assistantId": this.queueItemAndStatus.assistant.value,
                    })
                    this.resp = response.data.content;
                }

                this.httpError = null;
                this.dialog = true;
            },

            getFreeTimeSlotsForAssistant: function (date) {
                post('queue/demand/timeSlots', {
                    "wantedDay": moment(date).format("YYYY-MM-DD"),
                    "assistantId": this.assistantId,
                }).then((response) => {
                    this.freeTimeSlots = response.data.items;
                }).catch(e => alert(e.message));
            },

            setRequirementsAtIndex(category, item, index) {
                this.requirements[index].category_object = category;
                this.requirements[index].item_object = item;
            },

            setSelectedDayFromInfobar() {
                this.queueItemAndStatus.wanted_day = this.selectedDay.get("year").toString() + '-'
                    + this.addZeroBeforeMonth((this.selectedDay.get("month") + 1).toString()) + '-'
                    + this.setDay(this.selectedDay.get("date"));
            },

            setAssistantIdFromInfobar() {
                this.queueItemAndStatus.assistantId = this.assistantId;
            },

            setDay(day) {
                if (parseInt(day) < 10) {
                    return ('0' + day).toString();
                } else {
                    return day.toString();
                }
            },

            goBackPropsNotDefined() {
                if (this.propsDefined) {
                    this.$router.push({name: "faq"});
                }
            },

            computeTimeLasting() {
                let time = 0;

                if (this.requirements.length > 0) {
                    for (let item of this.requirements) {
                        if (item.item_object != null) {
                            time += parseInt(item.item_object.defaultTime);
                        }
                    }
                    return time;

                } else {
                    return time;
                }
            },

            async createdByPropsDefined() {
                if (this.propsDefined) {
                    this.goBackPropsNotDefined();
                } else {
                    await Promise.all([
                        this.fetchStudyFields(),
                        this.fetchStudyProblemsItems(),
                        this.fetchStudyProblemsCategories(),
                        this.fetchStudyOpenHours(),
                        this.fetchQueueItemStatusById(this.proRid),
                    ])

                    await this.fetchQueueItemById(this.queueItemStatus.queueItemId);

                    this.setModel();
                    this.setRequirements();
                    this.createCopyOfOriginal();
                }
            },

            allowedDates(apiDate) {
                const openHour = this.getStudyOpenHour(moment(apiDate, "YYYY-MM-DD").day().toString());

                return !((openHour.openTime === "" || openHour.openTime === null) && openHour.closeTime === "" || (openHour.closeTime === null));
            },
        },

        watch: {
            'queueItemAndStatus.wanted_day': function () {
                this.getFreeTimeSlotsForAssistant(this.queueItemAndStatus.wanted_day);
            },
        }
    }
</script>

<style scoped>
    .no_margin {
        margin: 0 !important;
    }

    .button {
        width: 7rem;
    }

    .close_button {
        background-color: #e6e6e6 !important;
        border-radius: 20px;
    }

    .margin_left {
        margin-left: 0.7rem;
    }

    .display_block {
        display: inline-block;
    }

    .float_left {
        float: left;
        margin: 0;
        padding: 0;
    }

    .v_flex_span_date {
        width: 25rem;
    }

    .no_margin_no_padding {
        margin: 0;
        padding: 0;
    }

    .text {
        font-size: large;
    }

    .text_align {
        text-align: center !important;
        width: 3.5rem;
    }

    .time_range_text {
        margin: 1rem 0 0 0;
        padding: 0;
    }

    .minus_sign {
        margin-top: 0.75rem !important;
        margin-left: 1rem !important;
    }

    .doSpan {
        font-size: large;
        padding-bottom: 1.2rem;
    }

    .overdrive {
        margin: 0 !important;
        padding: 0 !important;
    }

    .add_new_reruirement_text {
        font-size: medium;
    }

    .top {
        margin: 2rem 0 0 0 !important;
    }

    .top_plus {
        margin: 1rem 0 0 0;
    }

    .text_smoll {
        font-size: small;
    }

</style>
