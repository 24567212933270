<template>
    <v-dialog v-model="model" max-width="600px" persistent>
        <v-card v-if="isSaving || category === null">
            <v-card-text class="py-6">
                <Loading/>
            </v-card-text>
        </v-card>
        <v-form v-else @submit.prevent="save" ref="form">
            <v-card>
                <v-card-title>
                    <span v-if="isNew" class="headline">Nová kategorie</span>
                    <span v-else class="headline">Úprava kategorie</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                            v-for="(name, key) in languages"
                            :key="key"
                            :rules="[required_rule]"
                            color="dark"
                            v-model="category.localizedAttributes[key].name"
                            :label="'Popis (' + name + ')'"
                    ></v-text-field>
                    <span>Ikona</span>
                    <v-radio-group
                            row
                            class="mt-2"
                            :rules="[required_rule]"
                            v-model="category.iconName"
                    >
                        <v-radio
                                v-for="icon in icons"
                                :key="icon.mobileIcon"
                                :value="icon.mobileIcon"
                                class="mb-3 mr-6"
                        >
                            <template v-slot:label>
                                <icon :name="icon.webIcon"/>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <span>Ikonky pro ICV</span>
                    <v-radio-group
                      row
                      class="mt-2"
                      :rules="[required_rule]"
                      v-model="category.iconName"
                    >
                        <v-radio
                          v-for="icon in faqIcons"
                          :key="icon.mobileIcon"
                          :value="icon.mobileIcon"
                          class="mb-3 mr-6"
                        >
                            <template v-slot:label>
                                <icon :name="icon.webIcon"/>
                            </template>
                        </v-radio>
                    </v-radio-group>

                    <v-switch
                            label="Notifikovat uživatele o změnách otázek v této kategorii"
                            v-model="category.notificationEnabledByDefault"
                            :disabled="true"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="dark" text @click="close">Zavřít</v-btn>
                    <v-btn color="primary" text type="submit">Uložit</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
    import {mapActions} from "vuex";
    import Loading from "../../components/Loading";
    import {faq_icons, icons} from '../../components/faq/FAQCategoriesIcons';
    import {getLocalizedLanguages} from "../../localization/languages";
    import Icon from "../../components/icons/Icon";

    export default {
        name: "FAQCategoryDialog",

        components: {
            Loading,
            Icon
        },

        props: {
            model: Boolean,
            category: Object,
        },

        data() {
            return {
                isSaving: false,
                required_rule: value => !!value || 'Povinné',
            }
        },

        watch: {
            model: function() {
                if (this.$refs.form) {
                    this.$refs.form.resetValidation();
                }
            }
        },

        computed: {
            isNew() { return this.category.id === undefined; },
            languages() { return getLocalizedLanguages(); },
            icons() { return icons; },
            faqIcons() { return faq_icons}
        },

        methods: {
            ...mapActions('FAQCategories', ['createCategory', 'updateCategory']),

            save() {
                if (this.$refs.form.validate()) {
                    this.isSaving = true;
                    if (this.isNew) {
                        this.createCategory(this.category).then(this.finishSaving);
                    } else {
                        this.updateCategory(this.category).then(this.finishSaving);
                    }
                }
            },

            finishSaving() {
                this.isSaving = false;
                this.close();
            },

            close() {
                this.$emit('close');
            },
        },
    }
</script>

<style scoped>
</style>
