<template>
    <ItemNotFoundState v-if="notFound" :objectName="'Otázka'">
        <template #backButton>
            <BackButton
                :target="{name: 'Gamification', params: {subjectId: subjectId, tab: 'questions'}}">
                Otázky
            </BackButton>
        </template>
    </ItemNotFoundState>
    <Loading v-else-if="!didLoadAll"></Loading>
    <v-container v-else>
        <v-row>
            <v-col>
                <BackButton
                  :target="{name: 'Gamification', params: {subjectId: subjectId, tab: 'questions'}}">
                    {{subjectName}} / Otázky
                </BackButton>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <ModuleHeader class="maxWidth" data-cy="module-header">
                        <h1 v-if="isNew">Přidat otázku</h1>
                        <h1 v-else-if="!this.question.approved">
                            Otázka od studenta
                        </h1>
                        <h1 v-else>
                            Úprava otázky
                        </h1>

                        <div class="d-md-flex pt-2" style="display: grid">

                            <v-btn
                              v-if="!isNew"
                              @click="deleteQuestion(questionId)"
                              color="white" elevation="0" rounded
                              class="mx-1"
                            >
                                Smazat otázku
                            </v-btn>

                            <v-btn
                              v-if="!question.hidden"
                              color="white" elevation="0" rounded
                              @click="question.hidden = !question.hidden"
                              class="mx-1"
                            >
                                Skrýt test
                            </v-btn>

                            <v-btn
                              v-if="question.hidden"
                              color="primary" elevation="0" rounded
                              @click="question.hidden = !question.hidden"
                              class="mx-1"
                            >
                                Zobrazit test
                            </v-btn>

                            <div v-if="!this.question.approved" class="d-flex flex-column align-center ">
                                <v-btn
                                  @click="approveQuestion()"
                                  color="primary" rounded
                                  class="mx-1 mb-2"
                                >
                                    Schválit
                                </v-btn>
                                <div style="width: 100%" class="d-flex justify-space-around px-5 pb-2">
                                    <span>
                                        <v-icon class="light-green--text">thumb_up_alt</v-icon>
                                        {{ this.question.approvalCounter }}
                                    </span>
                                    <span>
                                        <v-icon color="black-50">thumb_down_alt</v-icon>
                                        {{ this.question.denialCounter }}
                                    </span>
                                </div>
                            </div>

                            <SaveButton
                              :disabled="this.disableButton"
                              :is-saving="isSaving"
                              @click="save()"
                              class="mx-1"
                              label="Uložit"
                            />
                        </div>
                </ModuleHeader>

                <v-alert v-if="answers.length <= 1 && this.clicked && this.isNew" type="warning" max-width="600px">Otázka musí obsahovat alespoň dvě možnosti.</v-alert>
                <v-alert v-else-if="!isFormValid && this.clicked && this.isNew" type="warning" max-width="600px">Chybí překlad možností.</v-alert>
                <v-alert v-else-if="!atLeastOneCorrect && this.clicked && this.isNew" type="warning" max-width="600px">Alespoň jedna možnost musí být správna.</v-alert>
                <v-alert v-else-if="!atLeastTwoCorrect && this.clicked && this.isNew" type="warning" max-width="600px">Minimálně dvě možnosti musí být správné.</v-alert>
                <v-alert v-else-if="!onlyOneCorrect && this.clicked && this.isNew" type="warning" max-width="600px">Jenom jedna možnost může být správná.</v-alert>
            </v-col>
        </v-row>
        <v-row class="width100">
            <v-col>
                <v-form @submit.prevent="save()" ref="form" class="maxWidth">
                    <v-simple-table>
                        <tbody>
                        <tr>
                            <td>
                                <v-text-field
                                  label="Text otázky (čeština)"
                                  @change="changeWatch"
                                  :rules="[required_rule]"
                                  v-model.trim="question.localizedAttributes.cz.text"
                                  :maxlength="100"
                                  counter
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <v-text-field
                                  label="Text otázky (angličtina)"
                                  @change="changeWatch"
                                  :rules="[required_rule]"
                                  v-model.trim="question.localizedAttributes.en.text"
                                  :maxlength="100"
                                  counter
                                >
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <v-text-field
                                  type="number"
                                  label="Počet bodů"
                                  @change="changeWatch"
                                  v-model="question.points"
                                  :rules="minMaxPoints"
                                >
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <v-select
                                  class="overflow-hidden"
                                  label="Typ otázky"
                                  :items="questionTypes"
                                  item-text="text"
                                  item-value="number"
                                  :rules="selectedType == null ? [required_rule] : []"
                                  required
                                  v-model="selectedType"
                                ></v-select>

                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                    <v-row class="p-0 width_max requirement_padding">

                        <v-col cols="6" sm="3" class="d-flex align-center">
                            <h2>Možnosti</h2>
                        </v-col>
                        <v-col cols="6" sm="4" md="3">
                            <v-select
                              color="light-green darken-1 pull-right"
                              :items="languages"
                              v-model="currentLanguage"
                              label="Jazyk"
                              prepend-icon="public"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="5" md="6" class="d-flex align-center justify-center
                        justify-md-end">
                            <PrimaryButton @click="addNewAnswer()"
                                           :is-saving="isSaving"> + PŘIŘADIT MOŽNOST
                            </PrimaryButton>
                        </v-col>
                    </v-row>

                    <div class="mb-8">
                        <v-row v-show="answers.length !== 0" v-for="(answer, index) in answers" :key="index"
                               style="max-height: 60px">
                            <v-col cols="8" md="10">
                                <div class="answersLabel" v-show="index === 0">Popis</div>
                                <v-text-field
                                  @change="changeWatch"
                                  :rules="[required_rule]"
                                  v-model.trim="answer.localizedAttributes[currentLanguage].text"
                                  :maxlength="200"
                                  counter
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" md="1">
                                <div class="answersLabel checkBoxLabel" v-show="index === 0"> Správná</div>
                                <v-checkbox
                                  @change="changeWatch"
                                  v-model="answer.correct"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="2" md="1" class="d-flex align-center mb-1">
                                <v-icon class="my-auto" @click="removeAnswer(answer.id)">
                                    clear
                                </v-icon>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-show="answers.length === 0">
                        <v-col>
                            Otázka neobsahuje žádné možnosti
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ModuleHeader from "@/components/layout/ModuleHeader";
import BackButton from "@/components/layout/BackButton";
import SaveButton from "@/components/buttons/SaveButton";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import {getLocalizedLanguages} from "@/localization/languages";
import Loading from "@/components/Loading";
import ItemNotFoundState from '@/components/states/ItemNotFoundState.vue';

export default {
    name: "QuestionDetail",

    components: {
        Loading,
        ModuleHeader,
        BackButton,
        SaveButton,
        PrimaryButton,
        ItemNotFoundState,
    },

    data() {
        return {
            isSaving: false,
            clicked: false,
            answers: [],
            currentLanguage: "cz",
            isCreated: false,
            required_rule: value => !!value || 'Toto pole je povinné',
            minMaxPoints: [
                v => !!v || "Toto pole je povinné",
                v => (v && v <= 1000000) || "Nemůžete zadat víc jak milion bodů.",
            ],
            selectedType: null,
            questionTypes: [
                {
                    text: "Jedna správná odpověď",
                    number: 1
                },
                {
                    text: "Více správnych odpovědí",
                    number: 2
                },
                {
                    text: "Pravda/Nepravda",
                    number: 3
                }
            ],
            notFound: false,
        }
    },

    async created() {
        try {
            if (this.isNew) {
                this.prepareNewQuestion();
            } else {
                await this.loadData()
                this.setAnswers();
            }
            await this.fetchSubjectById(this.subjectId)
        } catch (e) {
            if (e.statusCode === 404) {
                this.notFound = true;
            } else {
                throw e;
            }
        }
    },

    watch: {
        questionId() {
            this.loadData();
        }
    },

    computed: {
        ...mapGetters('Questions', ['getQuestionById', 'getEmptyQuestion', 'didLoadedQuestions']),
        ...mapGetters('Answers', ['prepareNewAnswer', 'didLoadedAnswers',]),
        ...mapGetters('Subjects', ['getSubjectById', 'didLoadSubjects']),

        questionId() {
            const urlId = this.$route.params.questionId;
            return urlId === "new" ? null : Number(urlId);
        },

        subjectId() {
            return Number(this.$route.params.subjectId);
        },

        subjectName(){
          const subject = this.getSubjectById(this.subjectId)
          return subject ? subject.localizedAttributes.cz.name : ''
        },

        isNew() {
            return this.questionId === null;
        },

        question() {
            if (this.isNew)
                return this.getEmptyQuestion;
            else
                return this.getQuestionById(this.questionId);
        },

        atLeastTwoCorrect() {
            if (this.answers.length === 0) return true
            if (this.selectedType !== 2) return true
            let correctAnswers = 0
            for (let answer of this.answers) {
                if (answer.correct) {
                    correctAnswers++
                }
            }
            return correctAnswers > 1 && this.selectedType === 2
        },

        atLeastOneCorrect() {
            if (this.answers.length === 0) return true

            let correctAnswers = 0

            for (let answer of this.answers)
                if (answer.correct)
                    correctAnswers++

            return correctAnswers > 0
        },

        onlyOneCorrect() {
            if (this.answers.length === 0) return true
            if (this.selectedType === 2) return true

            let correctCount = 0

            for (let answer of this.answers)
                if (answer.correct)
                    correctCount++

            return correctCount <= 1
        },

        disableButton() {
            return !this.isFormValid
                || !this.onlyOneCorrect
                || !this.atLeastOneCorrect
                || !this.atLeastTwoCorrect
        },

        isFormValid() {
            for (let answer of this.answers) {
                if (answer.localizedAttributes.cz.text === null
                  || answer.localizedAttributes.en.text === null
                  || answer.localizedAttributes.cz.text === ''
                  || answer.localizedAttributes.en.text === '') {
                    return false
                }
            }
            return true
        },

        languages() {
            const langs = getLocalizedLanguages();
            const selectArr = [];
            Object.keys(langs).map((lng) => {
                selectArr.push({
                    'text': langs[lng],
                    'value': lng,
                });
            });
            return selectArr;
        },

        didLoadAll() {
            return this.didLoadSubjects && (this.didLoadedQuestions || this.isNew);
        },
    },

    methods: {
        ...mapActions('leavePrevent', ['madeUnsavedChanges', 'didSaveChanges']),
        ...mapActions('Questions', ['fetchQuestionById', 'createQuestion', 'updateQuestion', 'deleteQuestionById', 'prepareNewQuestion']),
        ...mapActions('Answers', ['fetchAllAnswers', 'createAnswer', 'updateAnswer', 'deleteAnswerById']),
        ...mapActions('Subjects', ['fetchSubjectById']),

        async loadData() {
            if (!this.isNew) {
                let questionById = await this.fetchQuestionById(this.questionId);
                if (questionById) {
                    this.selectedType = questionById.type
                }
            }
        },

        setAnswers() {
            this.answers = this.question?.answers ?? [];
        },

        changeWatch() {
            this.madeUnsavedChanges();
        },

        deleteQuestion(id) {
            if (window.confirm("Opravdu chcete smazat otázku?")) {
                this.deleteQuestionById(id)
                this.$router.push({
                    name: 'Gamification',
                    params: {subjectId: this.subjectId, tab: "questions"}
                });
            }
        },

        async removeAnswer(answerId) {
            this.answers = this.answers.filter(answer => answer.id !== answerId)
            await this.deleteAnswerById(answerId)
        },

        async addNewAnswer() {
            if (this.$refs.form.validate()) {

                if (this.isNew && !this.isCreated) {

                    const question = this.question
                    question.subjectId = this.subjectId
                    question.type = this.selectedType
                    this.question.id = await this.createQuestion({item: question})
                    this.isCreated = true
                }

                const answer = this.prepareNewAnswer();
                answer.questionId = this.question.id;
                answer.id = await this.createAnswer({item: answer})
                this.answers.push(answer);
            }
        },

        async approveQuestion() {
            const question = this.question
            question.approved = true
            await this.updateQuestion({item: this.question})
        },

        async save() {
            this.clicked = true
            if (this.$refs.form.validate() && this.atLeastOneCorrect && this.onlyOneCorrect && this.answers.length >= 2) {
                this.isSaving = true;

                for (let answer of this.answers) {
                    await this.updateAnswer({item: answer})
                }

                this.question.type = this.selectedType

                if (this.isNew && !this.isCreated) {
                    const question = this.question
                    question.subjectId = this.subjectId
                    this.question.id = await this.createQuestion({item: question})
                    this.isCreated = true
                } else {
                    await this.updateQuestion({item: this.question})
                }

                this.didSaveChanges()

                this.isSaving = false
            }
        }
    }
}
</script>

<style scoped>

h1 {
    align-self: start;
}

.maxWidth {
    max-width: 1200px;
    width: 100%;
}

.answersLabel {
    color: rgba(0, 0, 0, .6);
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    margin-bottom: -15px;
}

.checkBoxLabel {
    margin-left: -12px;
    width: 80px;
}

thead {
    border-top: none;
}

th, tr, td {
    border: none !important;
    padding: 0 !important;
}

th {
    font-size: 1rem !important;
}

</style>