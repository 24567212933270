import {del, get, post, put} from '@/services/api'
import Vue from "vue";

const state = {
    purposes: [],
    didLoadBeaconPurposes: false,
};

const getters = {
    purposes: state => state.purposes,
    purposeName: state => item => {
        const purpose = state.purposes.find(purpose => purpose.id === item.purposeId)
        return purpose ? purpose.name : ''
    },
    beaconPurposeById: state => id => {
        return state.purposes.find(w => (w.id === id))
    },
    didLoadBeaconPurposes: state => state.didLoadBeaconPurposes,
    prepareNewBeaconPurpose: () => () => ({
        id: "new",
        name: "",
    }),
};

const actions = {
    async fetchBeaconPurposes({commit}) {
        commit('startedDataDownload');
        const response = await get('iot/beacons/purposes/');
        commit('setBeaconPurposes', response.data.items)
        commit('finishedDataDownload')
    },
    async fetchBeaconPurposeById({commit}, id) {
        commit('errors/clear', null, { root: true });
        commit('startedDataDownload');
        try {
            const response = await get('iot/beacons/purposes/' + id)
            commit('updateBeaconPurpose', response.data.content)
            commit('finishedDataDownload')
        } catch(e) {
            if (e.statusCode == 404) {
                commit('errors/objectNotFound', null, { root: true });
            } else {
                throw e;
            }
        }
    },
    async createBeaconPurpose({commit}, beaconPurpose) {
        const response = await post(`iot/beacons/purposes/`, beaconPurpose);
        const newBeaconPurpose = response.data.content;
        commit('addBeaconPurpose', newBeaconPurpose);
    },
    async updateBeaconPurpose({commit}, beaconPurpose) {
        const response = await put(`iot/beacons/purposes/${beaconPurpose.id}`, beaconPurpose);
        commit('updateBeaconPurpose', response.data.content);
    },
    async deleteBeaconPurpose({commit}, id) {
        await del(`iot/beacons/purposes/${id}`)
        commit('filterBeaconPurposes', id);
    }
};

const mutations = {
    setBeaconPurposes: (state, purposes) => (state.purposes = purposes),
    updateBeaconPurpose: (state, item) => {
        const existing = state.purposes.findIndex(w => w.id === item.id);
        if (existing > -1) {
            Vue.set(state.purposes, existing, item);
        } else {
            state.purposes.push(item);
        }
    },
    addBeaconPurpose: (state, item) => (state.purposes.push(item)),
    filterBeaconPurposes: (state, id) => (state.purposes = state.purposes.filter(purpose => purpose.id !== id)),

    startedDataDownload: state => state.didLoadBeaconPurposes = false,
    finishedDataDownload: state => state.didLoadBeaconPurposes = true,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
