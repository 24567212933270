<template>

    <Loading v-if="didLoadedAll === false"></Loading>

    <v-container v-else fluid style="margin: 0">
        <v-layout row class="no_margin div_under_div">
            <v-flex lg12>
                <v-col class="float_left" :lg="7">
                    <h2 class="header_div" data-cy="reservation-detail-heading">
                        {{ $t('reservations.res_detail_calendar') }}
                    </h2>
                </v-col>
                <v-col class="float_left" :lg="4">
                    <div class="button_group">
                        <v-btn class="close_button mb-3 button margin_left" @click="goBack(assistantId)">{{ $t('reservations.close') }}</v-btn>
                        <v-btn id="edit-reservation" color="primary" :disabled="validateEditButton" dark class="mb-3 button margin_left"
                               @click="goToDetailEdit(assistantId)">{{ $t('reservations.edit') }}
                        </v-btn>
                    </div>
                </v-col>
            </v-flex>
        </v-layout>

        <v-layout row class="no_margin">
            <v-flex xs9>
                <template>
                    <v-simple-table height="550px">
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td class="text">1. {{ $t('reservations.ticket_id') }}</td>
                                <td class="text">{{queueItemStatus.ticketNumber}}</td>
                            </tr>
                            <tr>
                                <td class="text">2. {{ $t('reservations.res_date') }}</td>
                                <td class="text"> {{returnWantedFormatDate(queueItemStatus.wantedDay)}}</td>
                            </tr>
                            <tr>
                                <td class="text">3. {{ $t('reservations.assistant') }}</td>
                                <td class="text"> {{assistant.name}}</td>
                            </tr>
                            <tr>
                                <td class="text">4. {{ $t('reservations.res_status') }}</td>
                                <td data-cy="reservation-status" class="text"> {{translateStatus(queueItemStatus.status)}}</td>
                            </tr>
                            <tr>
                                <td class="text">5. {{ $t('reservations.timespan_request') }}</td>
                                <td class="text">{{queueItemStatus.actualMeetTime}} - {{queueItemStatus.actualMeetEnd}} , {{
                                    $t('reservations.time_length') }}
                                    {{computeTimeLasting()}} min.
                                </td>
                            </tr>
                            <tr>
                                <td class="text">6. {{ $t('reservations.res_person') }}</td>
                                <td class="text">{{returnName()}}</td>
                            </tr>
                            <tr>

                                <td class="text">7. {{ $t('reservations.res_stud_field') }}</td>
                                <td class="text">{{translateOtherIfMeeting(studyField.studyType)}} - {{studyField.name}}</td>
                            </tr>
                            <tr v-if="studyField.type !== 'other_type'">
                                <td class="text">8. {{ $t('reservations.request') }}</td>
                                <td class="text">{{returnStudyItems()}}</td>
                            </tr>
                            <tr>
                                <td class="text" v-if="studyField.type !== 'other_type'">9. {{ $t('reservations.req_note') }}</td>
                                <td class="text" v-else>8. {{ $t('reservations.req_note') }}</td>

                                <td class="text">
                                    <v-textarea
                                            dense
                                            auto-grow
                                            :readonly="true"
                                            v-model="note"
                                            :rounded="true"
                                    ></v-textarea>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import moment from 'moment';
    import {mapGetters, mapActions} from 'vuex';
    import Loading from "../../../../../components/Loading";

    export default {
        components: {Loading},

        name: "ReservationDetail",

        props: {
            assistantId: null,
        },

        data() {
            let Rid = this.$route.params.Rid;

            return {
                proRid: Rid,
                today: moment().toISOString(),

                note: null,
            }
        },

        async created() {
            this.proRid = this.$route.params.Rid;

            await Promise.all([
                this.fetchStudyFields(),
                this.fetchStudyProblemsItems(),
                this.fetchStudyProblemsCategories(),
                this.fetchStudyOpenHours(),
                this.fetchQueueItemStatusById(this.proRid),
            ])

            await this.fetchQueueItemById(this.queueItemStatus.queueItemId);

            this.returnNote();
        },

        computed: {
            ...mapGetters('assistant', ['getAssistant']),
            ...mapGetters('queueItemStatuses', ['getQueueItemStatus', 'didLoadedQueueItemStatuses']),
            ...mapGetters('queueItems', ['getQueueItem', 'didLoadedQueueItems']),
            ...mapGetters('studyProblemsCategories', ['didLoadedStudyProblemsCategories']),
            ...mapGetters('StudyOpenHours', ['didLoadedStudyOpenHours', 'getStudyOpenHours']),
            ...mapGetters('studyProblemsItems', ['getStudyProblemsItem', 'didLoadedStudyProblemsItems']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),
            ...mapGetters('studyFields', ['getStudyField', 'didLoadedStudyFields']),

            validateUserRoles() {
                return this.getAssistantStorage.role === 'STUDY_SPY';
            },

            queueItemStatus() {
                return this.getQueueItemStatus(this.proRid);
            },

            queueItem() {
                return this.getQueueItem(this.queueItemStatus.queueItemId);
            },

            studyField() {
                return this.getStudyField(this.queueItem.studyFieldId);
            },

            assistant() {
                return this.getAssistant(this.getQueueItem(this.queueItemStatus.queueItemId).assistantId);
            },

            didLoadedAll() {
                return this.didLoadedStudyOpenHours && this.didLoadedStudyFields && this.didLoadedStudyProblemsItems && this.didLoadedStudyProblemsCategories && this.didLoadedQueueItemStatuses && this.didLoadedQueueItems;
            },

            validateEditButton() {
                return this.queueItemStatus.status === 'abort' || this.queueItemStatus.status === 'done' || this.queueItemStatus.status === 'cancel' || this.validateUserRoles
            }
        },

        methods: {
            ...mapActions('queueItemStatuses', ['fetchQueueItemStatusById']),
            ...mapActions('queueItems', ['fetchQueueItemById']),
            ...mapActions('studyProblemsCategories', ['fetchStudyProblemsCategories']),
            ...mapActions('StudyOpenHours', ['fetchStudyOpenHours']),
            ...mapActions('studyProblemsItems', ['fetchStudyProblemsItems']),
            ...mapActions('studyFields', ['fetchStudyFields', 'fetchStudyFields']),

            computeTimeLasting() {
                var start = moment(this.queueItemStatus.actualMeetTime, "HH:mm");
                var end = moment(this.queueItemStatus.actualMeetEnd, "HH:mm");
                var minutes = end.diff(start, 'minutes');
                var interval = moment().hour(0).minute(minutes);
                return interval.format("m");
            },

            getClosingHoursForToday() {
                let closedHours = this.getStudyOpenHours;
                let today = new Date(this.queueItemStatus.wantedDay);

                let closedHoursForToday = closedHours.find(closedHours => closedHours.dayNumber === (today.getDay()).toString() && closedHours.purpose === "mobile").closeTime;

                if (moment(this.queueItemStatus.wantedDay).format("YYYYMMDD") === moment().format("YYYYMMDD")) {
                    return closedHoursForToday < moment().format("HH:mm");
                } else {
                    return false;
                }
            },

            translateStatus(status) {
                switch (status) {
                    case 'created':
                        return this.$t('reservations.not_served');
                    case 'abort':
                        return this.$t('reservations.cancel_user');
                    case 'cancel':
                        return this.$t('reservations.cancel_study_ref');
                    case 'done':
                        return this.$t('reservations.served');
                    case 'active':
                        return this.$t('reservations.next_in_line');
                    case 'doing':
                        return this.$t('checkIn.doing');
                }
            },

            goBack(assistantId) {
                this.$router.push({name: "CalendarDetail", params: {assistantID: assistantId}});
            },

            returnWantedFormatDate: function (wantedDate) {
                let date = moment(wantedDate);

                return date.format("DD") + '. '
                    + date.format("MMMM") + ' ' +
                    date.format("YYYY") + ', ' + date.format("dddd")
            },

            returnName: function () {
                if (this.queueItem.xname === null && this.queueItem.nameSurnameTitle != null) {
                    return this.queueItem.nameSurnameTitle;
                } else if (this.queueItem.nameSurnameTitle === null && this.queueItem.xname != null) {
                    return '(' + this.queueItem.xname + ')';
                } else if (this.queueItem.nameSurnameTitle === "Empty" && this.queueItem.xname === "Empty") {
                    return this.$t('reservations.anon_student')
                } else {
                    return this.queueItem.nameSurnameTitle + ' (' + this.queueItem.xname + ')';
                }
            },

            returnNote: function () {
                if (this.queueItem.note === null || !this.queueItem) {
                    this.note = this.$t('reservations.no_notes');
                } else {
                    this.note = this.queueItem.note;
                }
            },

            returnStudyItems: function () {
                let arr = this.queueItem.studyProblemsItemsIds;
                let ret = '';

                arr.forEach((item, index) => {
                    if (index !== arr.length - 1) {
                        ret += this.studyItem(item).name + ', ';
                    } else {
                        ret += this.studyItem(item).name;
                    }
                });

                return ret;
            },

            studyItem(id) {
                return this.getStudyProblemsItem(id);
            },

            goToDetailEdit(assistantId) {
                this.$router.push({name: 'ReservationDetailEdit', params: {REid: this.proRid, assistantId}});
            },

            translateOtherIfMeeting(text) {
                if (text === 'Other') {
                    return this.$t('reservations.other')
                } else {
                    return text;
                }
            }
        },

    }
</script>

<style scoped>
    .no_margin {
        margin: 0 0 0.3rem 0;
    }

    .button {
        width: 7rem;
    }

    .close_button {
        background-color: #e6e6e6 !important;
        border-radius: 20px;
    }

    .button_group {
        display: flex;
        justify-content: center;
        width: fit-content;
    }

    .margin_left {
        margin-left: 0.7rem;
    }

    .float_left {
        float: left;
        margin: 0;
        padding: 0;
    }

    .header_div {
        width: fit-content;
    }

    .text {
        font-size: large;
    }

</style>
