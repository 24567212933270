<template>
    <Loading v-if="didLoadedAll === false"></Loading>


    <v-dialog v-else v-model="dialog" persistent max-width="700px">
        <template v-slot:activator="{ on }">
            <v-btn rounded dark color="orange" v-on="on" id="call-in-delegate-btn">{{$t('reservations.delegate')}}</v-btn>
        </template>
        <v-card>
            <v-form ref="form"
                    v-model="valid">
                <v-card-title>
                    <span class="headline">{{$t('checkIn.pick_study')}}</span>
                    <span class="headline">{{$t('checkIn.pick_priority')}}</span>
                </v-card-title>

                <v-card-text v-if="loading">
                    <Loading/>
                </v-card-text>
                <v-card-text v-else>
                    <v-divider></v-divider>
                    <v-container class="mt-4">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <span class="text">{{$t('checkIn.pick_study_select')}}</span>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-select
                                        id="delegation-assistant-select"
                                        v-model="dialogModel"
                                        :rules="[v => !!v || $t('entities.required')]"
                                        :items="assistants"
                                        return-object
                                        required
                                        solo
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                    <span class="infotext">{{$t('checkIn.no_go_back')}} </span>
                    <span class="infotext">{{$t('checkIn.no_go_back_cont')}}</span>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>

                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog(false)">{{ $t('reservations.close') }}</v-btn>
                    <v-btn color="blue darken-1" :disabled="!valid" text @click="delegate()" id="confirm-delegation-btn">{{$t('reservations.delegate')}}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import Loading from "../../../../components/Loading";

    export default {
        components: {Loading},

        name: "DelegateDialog",

        data() {
            return {
                valid: true,
                form: null,

                dialogModel: null,
                assistants: null,

                dialog: false,

                loading: false,
            }
        },

        computed: {
            ...mapGetters('queueItems', ['getQueueItem']),
            ...mapGetters('assistant', ['didLoadedAssistants', 'getAssistantsForDropdown']),
            ...mapGetters('queueItemStatuses', ['getQueueItemStatus']),
            ...mapGetters('pageStorage', ['getCallInItemIdToShow', 'getAssistantIdStorage']),

            didLoadedAll() {
                return this.didLoadedAssistants;
            }
        },

        created() {
            this.setModelToShow();
        },

        methods: {
            ...mapActions('queueItems', ['delegateQueueItem']),

            setModelToShow() {
                let assistants = this.getAssistantsForDropdown;
                this.assistants = assistants.filter(ass => ass.value !== this.getAssistantIdStorage);
            },

            closeDialog: function (value) {
                this.dialog = value;
            },

            async delegate() {
                this.loading = true;

                await this.delegateQueueItem({
                    queueItemId: this.getCallInItemIdToShow,
                    assistantId: this.dialogModel.value,
                });

                this.dialogModel = null;
                this.dialog = false;
                this.loading = false;

                this.$root.$emit('callInStartItsOn');
            },
        }
    };
</script>

<style scoped>
    .no_margin_no_padding {
        margin: 0;
        padding: 0;
    }

    .text {
        font-size: large;
    }

    .call_in_button {
        width: 50% !important;
        text-transform: capitalize;
        font-size: large !important;
        font-weight: 400;
        letter-spacing: normal;
        background-color: rgba(186, 0, 0, 0.98) !important;
        color: white !important;
    }

    .infotext {
        color: red;
    }

</style>
