<template>
    <v-container>
        <v-row>
            <v-col>
                <v-tabs icons-and-text color="light-green" class="top_menubar">
                    <v-tab :to="{name: 'Faculties'}" data-cy=faculties-tab>
                        <span v-if="isNotMobile" class="mt-2"> {{ $t('news.faculties') }}</span>
                        <v-icon >meeting_room</v-icon>
                    </v-tab>
                    <v-tab :to="{name: 'Administrators'}" data-cy=administrators-tab>
                        <span v-if="isNotMobile" class="mt-2"> {{ $t('news.users_and_permissions') }}</span>
                        <v-icon>account_box</v-icon>
                    </v-tab>
                    <v-tab :to="{name: 'NewsfeedStatistics'}" data-cy=statistics-tab>
                        <span v-if="isNotMobile" class="mt-2">{{ $t('news.statistics') }}</span>
                        <v-icon>insights</v-icon>
                    </v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <router-view/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "News",

    computed: {
        isNotMobile() {
            return !this.$vuetify.breakpoint.xs;
        }
    }
}
</script>

<style scoped>

.top_menubar {
    border-bottom: 2px solid #c4c4c4;
}

</style>
