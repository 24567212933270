import {del, get, post, put} from '@/services/api'
import Vue from "vue";

const state = {
    didLoadNews: false,
    didLoadCategories: false,
    didLoadAudiences: false,
    didLoadFaculties: false,
    didLoadNewsfeedStatistics: false,
    news: [],
    categories: [],
    audiences: [],
    faculties: [],
    newsfeedStatistics: [],
    states: [
        {text: "Uveřejněné", value: "Published"},
        {text: "Koncepty", value: "Concepts"},
        {text: "Archivované", value: "Archived"},
    ],
    defaultPost: {
        audienceIds: [],
        categoryIds: [],
        contacts: [],
        endDate: null,
        eventDate: null,
        eventTime: null,
        facultyIds: [],
        isArchived: false,
        isConcept: true,
        isPoster: false,
        links: [],
        placeId: null,
        customPlace: null,
        localizedAttributes: {
            cz: {
                title: "",
                text: "",
                unformattedText: ""
            },
            en: {
                title: "",
                text: "",
                unformattedText: ""
            }
        },
        startDate: null
    },
    newPost: null,
    error: false,
    newsfeedImages: []
};

const getters = {
    didLoadNews: state => state.didLoadNews,
    didLoadCategories: state => state.didLoadCategories,
    didLoadFaculties: state => state.didLoadFaculties,
    didLoadAudiences: state => state.didLoadAudiences,
    didLoadNewsfeedStatistics:  state => state.didLoadNewsfeedStatistics,
    getNews: state => {
        return state.news.sort((a, b) => b.created - a.created);
    },
    getCategories: state => {
        return state.categories
            .map(obj => ({
                text: obj.localizedAttributes.cz.name,
                value: obj.id,
            }))
            .sort((a, b) => a.text.localeCompare(b.text))
    },
    getNewsStates: state => state.states.sort((a, b) => {
        return a.text.localeCompare(b.text);
    }),
    getNewsfeedDetailError: state => state.error,
    getFacultiesByUser: state => state.faculties,
    getAudiencesByUser: state => state.audiences,
    getCategoriesNew: state => state.categories,
    getEmptyPost: state => state.newPost,
    getNewsById: state => id => state.news.find(obj => obj.id === id),
    getNewsfeedStatistics: state => state.newsfeedStatistics,
    audienceNames : state => state.newsfeedStatistics.audiences.map(x => x.name),
    facultyNames : state => state.newsfeedStatistics.faculties.map(x => x.name),
    audienceAbsolutes : state => state.newsfeedStatistics.audiences.map(x => x.absolute),
    facultyAbsolutes : state => state.newsfeedStatistics.faculties.map(x => x.absolute),

    getNewsfeedImages: state => state.newsfeedImages,
};

const actions = {
    async fetchAllNews({commit}) {
        commit('setDidLoadNews', false);
        // const response = await get(`newsfeed/news/by_user/?faculty=${id}`);
        const response = await get(`newsfeed/news/all/`);
            commit('setNews', response.data.items);
            commit('setDidLoadNews', true);
    },

    async fetchAllCategories({commit}) {
        commit('setDidLoadCategories', false);
        const response = await get(`newsfeed/categories/`)
            commit('setCategories', response.data.items);
            commit('setDidLoadCategories', true);
    },

    async createPost({commit}, request) {
        commit('setError', false);
        try {
            const response = await post(`newsfeed/news/`, request);
            commit('setPost', response.data.content);
            return response.data.content.id;
        } catch (e) {
            if (e.statusCode === 409) {
                commit('setError', true);
            } else {
                throw e;
            }
        }
    },

    async setErr({commit}, request) {
        commit('setError', request);
    },

    async fetchAllAudiencesByUser({commit}) {
        commit('setDidLoadAudiences', false);
        try {
            const response = await get(`audience/by_user/`)
            commit('setAudiences', response.data.items);
            commit('setDidLoadAudiences', true);
        } catch (e) {
            if (e.error !== 404) throw e
            commit('setError', true);
        }
    },

    async fetchAllFacultiesByUser({commit}) {
        commit('setDidLoadFaculties', false);
        try {
            const response = await get(`faculty/by_user/`)
            commit('setFaculties', response.data.items);
            commit('setDidLoadFaculties', true);
        } catch (e) {
            if (e.error !== 404) throw e
            commit('setError', true);
        }
    },

    preparePost({commit}) {
        commit('prepareNewPost');
    },

    async fetchNewsById({commit}, id) {
        commit('setError', false);
        try {
            const response = await get('newsfeed/news/' + id);
            commit('updatePost', response.data.content);
        } catch (e) {
            if (e.error !== 404) throw e
            commit('setError', true);
        }
    },

    async updatePost({commit}, {id, request, notify}) {
        const response = await put(`newsfeed/news/${id}/?notify=${notify}`, request)
        commit('updatePost', response.data.content);
        return response.data.content.id;
    },

    addAdditionalImage({commit}, request) {
        commit('addAdditionalImage', request);
    },

    addTitleImage({commit}, request) {
        commit('setTitleImage', request);
    },

    async fetchNewsfeedStatistics({commit}) {
        commit('setDidLoadNewsfeedStatistics', false);
        const response = await get(`newsfeed/statistics/`)
        commit('setNewsfeedStatistics', response.data.content);
        commit('setDidLoadNewsfeedStatistics', true);
    },
    async fetchNewsfeedImages({commit}, id) {
        try {
            if (id !== "new"){
                const response = await get('newsfeed/news/images/' + id);
                commit('setImages', response.data.items);
            }else {
                commit('setImages', []);
            }
        } catch (e) {
            if (e.error !== 404) throw e
        }
    },

    async deleteImage({commit}, {newsId, imgId}) {
        await del(`newsfeed/news/images/${newsId}/${imgId}`)
        commit('removeImage', imgId);
    },

};

const mutations = {
    setDidLoadCategories: (state, status) => (state.didLoadCategories = status),
    setDidLoadNews: (state, status) => (state.didLoadNews = status),
    setDidLoadFaculties: (state, status) => (state.didLoadFaculties = status),
    setDidLoadAudiences: (state, status) => (state.didLoadAudiences = status),
    setDidLoadNewsfeedStatistics: (state, status) => (state.didLoadNewsfeedStatistics = status),
    setNews: (state, news) => (state.news = news),
    setCategories: (state, categories) => (state.categories = categories),
    setFaculties: (state, faculty) => (state.faculties = faculty),
    setAudiences: (state, audience) => (state.audiences = audience),
    setNewsfeedStatistics: (state, statistics) => (state.newsfeedStatistics = statistics),
    setPost: (state, item) => (state.news.push(item)),
    setError: (state, err) => (state.error = err),
    prepareNewPost: (state) => {
        state.newPost = JSON.parse(JSON.stringify(state.defaultPost));
    },
    updatePost: (state, item) => {
        const existing = state.news.findIndex(obj => obj.id === item.id);
        if (existing > -1) {
            Vue.set(state.news, existing, item);
        } else {
            state.news.push(item);
        }
    },
    setImages: (state, image) => (state.newsfeedImages = image),
    addAdditionalImage: (state, image) => (state.newsfeedImages.push(image)),
    setTitleImage: (state, image) => {
        const existing = state.newsfeedImages.find(obj => obj.isTitle);
        if (existing > -1) {
            Vue.set(state.newsfeedImages, existing, image);
        } else {
            state.newsfeedImages.push(image);
        }
    },
    removeImage: (state, id) => state.newsfeedImages = state.newsfeedImages.filter(i => i.id !== id),

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
