<template>
    <div>
        <h1>404</h1>

        <p>Požadovaná stránka nebyla nalezena.</p>
        <p class="second-line">Zkontrolujte prosím zadanou URL adresu požadované stránky.</p>
        <span>
        Můžete se <a @click="$router.go(-1)">vrátit zpět</a> a zkusit to znovu.
        </span>
    </div>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style scoped>
  .second-line {
      margin-bottom: 0px;
  }
</style>
