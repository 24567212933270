import {get, post, del, put} from '@/services/api'
import translator from '../../localization/translator';
import Vue from "vue";

const state = {
  didLoadReports: false,
  reports: [],
  pendingReportCount: null,
  types: [
      "idea" ,
      "problem" ,
  ],
  states: [
      "pending" ,
      "inProgress" ,
      "solved",
      "rejected",
  ],
};
const getters = {
  didLoadReports: state => state.didLoadReports,
  getReports: state => {
    return state.reports
      .filter(reports => reports.archived === false)
      .sort((a, b) => b.id - a.id);
  },
  getArchivedReports: state => {
    return state.reports
    .filter(reports => reports.archived === true)
    .sort((a, b) => b.id - a.id);
  },
  getSingle: state => id => {
    return state.reports.find(report => report.id == id)
  },
  getTypes: state => {
    return state.types.map( type => ({text: translator.translate(type), value: type}));
  },
  getStates: state => {
    return state.states.map( state => ({text: translator.translate(state), value: state}));
  },
  getCountOfPendingReports: state => state.pendingReportCount,
  getStateIcon: () => state => {
    switch (state) {
        case 'pending':
            return 'hourglass_empty';
        case 'inProgress':
            return 'directions_run';
        case 'solved':
            return 'check';
        case 'rejected':
            return 'close';
        default:
            return '';
    }
  }
};
const actions = {

  //Fetch ALL
  async fetchReports({ commit }) {
      commit('startedDataDownload');
      await get('reports/reports/')
        .then((response) => {
          commit('setReports', response.data.items);
          commit('countPendingReports', response.data.items);
          commit('finishedDataDownload');
        });
  },

  async fetchReportById({commit}, id) {
    commit('errors/clear', null, { root: true });
    commit('startedDataDownload');
    try {
      const response = await get('reports/reports/' + id)
      commit('updateReport', response.data.content);
      commit('finishedDataDownload');
    } catch (e) {
      if (e.statusCode == 404) {
        commit('errors/objectNotFound', null, { root: true });
      } else {
        throw e;
      }
    }
  },

  async fetchReportCount({ commit }) {
      try {
          const response = await get('reports/reports/');
          if (response) {
              commit('countPendingReports', response.data.items);
          }
      } catch (e) {
          if (e.statusCode === 401) {
              // A problem with user's token, so we log him/her out and require login again using the global error handler (services/api/error-handler.js)
              throw e;
          } else {
              // other errors ignored, no need to fetch new reports all the time
              console.log(e);
          }
      }
  },


  //Add ONE
  async createReport({ commit, getters },  item) {
    post('reports/reports/', item)
        .then((newItem) => {
            commit('addReport', newItem.data.content);
            commit('countPendingReports', getters.getReports)
        });

  },

  //Delete ONE
   async deleteReport({ commit, getters },  id) {
      del(`reports/reports/${id}`)
        .then(() => {
            commit('filterReport', id);
            commit('countPendingReports', getters.getReports)
        });
  },

  //Update ONE
  async updateReport({ commit, getters }, { item }) {
      const response = await put(`reports/reports/${item.id}`, item);
      commit('updateReport', response.data.content)
      commit('countPendingReports', getters.getReports)
  },

};


const mutations = {
  startedDataDownload: state => state.didLoadReports = false,
  finishedDataDownload: state => state.didLoadReports = true,
  setReports: ( state, reports ) => { state.reports = reports; state.reportCount = reports.length; },
  countPendingReports: ( state, reports ) => state.pendingReportCount = reports.reduce((a, v) => !v.archived && v.state === 'pending' ? a + 1 : a, 0),
  filterReport: ( state, id ) => (state.reports = state.reports.filter(reports => reports.id !== id)),
  addReport: ( state, item ) => (state.reports.push(item)),
  updateReport: ( state, item ) => {
    let index = state.reports.findIndex(report => report.id == item.id);
    if (index > -1) {
      Vue.set(state.reports, index, item)
    } else {
      state.reports.push(item)
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
