<template>
  <span class="languages">
    <v-checkbox
      id="announcement-language-cz"
      v-model="selectedLanguages"
      :value="cz"
      @change="(values) => onChange(values, en)"
      class="mr-7"
      label="čeština">
    </v-checkbox>
    <v-checkbox
      v-model="selectedLanguages"
      id="announcement-language-en"
      :value="en"
      @change="(values) => onChange(values, cz)"
      label="angličtina">
    </v-checkbox>
  </span>
</template>

<script>
  export default {
    /**
     * Announcement langugage selection component.
     */
    name: 'AnnouncementLanguageSelect',

    props: {
      /**
       * Initial languages selection.
       */
      initialValues: Array,
    },

    data() {
      const cz = 'cz';
      const en = 'en';

      return {
        selectedLanguages: this.initialValues,
        cz,
        en
      }
    },

    methods: {
      onChange(values, language) {
        if (values.length === 0) {
          this.selectedLanguages = [language];
        } else {
          this.selectedLanguages.sort();
        }
        this.$emit('change', this.selectedLanguages);
      }
    }
  }
</script>

<style scoped>
  .languages {
    display: inherit;
  }
</style>