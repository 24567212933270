<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-container v-else class="max_width_none" align-content-start>
        <v-layout row class="no_margin no_padding width_max">
            <v-flex>
                <div>
                  <BackButton :target="from === 'portal'?
                    {name: 'StudyFieldPortal'} : {name: 'AssistantDetail', params: {Rid: id}}"
                    :explicit-push="from !== 'portal'"
                  >
                    <span v-if="from === 'portal'">{{ $t('entities.studyfield.portal_name') }} </span>
                    <span v-else>{{ $t('entities.studyfield.ref_edit') }} </span>
                  </BackButton>
                    <div v-if="isNewStudyField">
                        <h2 class="h2_header">{{ $t('entities.studyfield.new_study') }}</h2>
                        <save-button class="new_button" :disabled="!valid" :is-saving="isSaving" @click="addNewStudyField"
                                        :label="$t('entities.save')"></save-button>
                    </div>
                    <div v-else>
                        <h2 v-if="!validateUserRoles" class="h2_header">{{ $t('entities.studyfield.detail_edit') }}</h2>
                        <h2 v-else class="h2_header">{{ $t('entities.studyfield.detail') }}</h2>

                        <v-menu top :offset-x="true" :offset-y="true" v-if="!validateUserRoles && !studyFieldModel.archived">
                            <template v-slot:activator="{ on }">
                                <v-btn class="new_button" icon v-on="on"><v-icon>more_vert</v-icon></v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="deleteStudyField">
                                    <v-list-item-title class="primary--text">{{ $t('entities.studyfield.delete_study_field') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <save-button v-if="!validateUserRoles" class="new_button" :disabled="buttonDisabledValidate" @click="putEditedStudyField" :is-saving="isSaving"
                                     label="Upravit"></save-button>
                    </div>
                </div>
            </v-flex>
        </v-layout>

        <v-form ref="form" v-model="valid">

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">1. {{ $t('entities.studyfield.study_code') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  :rules="codeRules"
                                  required
                                  v-model.trim="studyFieldModel.short_code"
                                  :label="$t('entities.studyfield.type_study_code')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">2. {{ $t('entities.studyfield.study_name') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  :rules="nameRules"
                                  required
                                  v-model="studyFieldModel.name"
                                  :label="$t('entities.studyfield.type_study_name')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin">
                <v-flex xs4 class="float_left">
                    <span class="text">3. {{ $t('entities.studyfield.study_name_en') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-text-field solo
                                  :rules="nameRules"
                                  required
                                  v-model="studyFieldModel.name_en"
                                  :label="$t('entities.studyfield.type_study_name_en')"
                                  :disabled="validateUserRoles">
                    </v-text-field>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin mb-10">
                <v-flex xs4 class="float_left">
                    <span class="text">4. {{ $t('entities.studyfield.study_deg') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-select
                            v-model="studyFieldModel.study_type"
                            :items=studyTypeSelect
                            :hide-details="true"
                            :rules="[v => !!v || $t('entities.required')]"
                            required
                            return-object
                            solo
                            :label="$t('entities.studyfield.type_study_deg')"
                            :disabled="validateUserRoles">
                    </v-select>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin mb-10">
                <v-flex xs4 class="float_left">
                    <span class="text">5. {{ $t('entities.studyfield.study_form') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-select
                            v-model="studyFieldModel.type"
                            :items=typeSelect
                            :hide-details="true"
                            :rules="[v => !!v || $t('entities.required')]"
                            required
                            return-object
                            solo
                            :label="$t('entities.studyfield.type_study_form')"
                            :disabled="validateUserRoles">
                    </v-select>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin mb-10">
                <v-flex xs4 class="float_left">
                    <span class="text">6. {{ $t('entities.studyfield.target_languages') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-select
                        v-model="studyFieldModel.targetLanguages"
                        :items="languages"
                        :hide-details="true"
                        required
                        solo
                        :label="$t('entities.studyfield.select_target_languages')"
                        :disabled="validateUserRoles"
                        multiple
                        :clearable="true"
                        chips
                        ></v-select>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin mb-10">
              <v-flex xs4 class="float_left">
                <span class="text">7. {{ $t('entities.studyfield.new_ref') }}</span>
              </v-flex>

              <v-flex xs4 class="float_left">
                <v-select solo
                              :items="studyAssistantsInSelect"
                              v-model="studyFieldModel.assistantId"
                              :disabled="validateUserRoles"
                              :label="$t('entities.studyfield.assign_ref')"
                              :hide-details="true"
                              >
                </v-select>
              </v-flex>
            </v-layout>

            <v-layout row class="no_margin top">
                <v-flex v-if="!isNewStudyField">
                    <span class="text">8. {{ $t('entities.studyfield.study_refs') }}</span>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin top">
                <v-flex v-if="!isNewStudyField && dataToDataTable.length !== 0">
                    <v-data-table
                            :headers="headers"
                            :items="dataToDataTable"
                            :sort-by="['name']"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            class="elevation-1"
                    >
                        <template v-slot:item.action="{ item }">
                            <v-icon class="mr-2" @click="goToDetail(item.id)">
                                edit
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-flex>

                <v-flex v-if="dataToDataTable.length === 0 && !isNewStudyField">
                    <h4>{{ $t('entities.studyfield.no_study_refs') }}</h4>
                </v-flex>
            </v-layout>

        </v-form>
    </v-container>
</template>

<script>
    import SaveButton from "../../../../../components/buttons/SaveButton";
    import { put, post, del } from '@/services/api'
    import Loading from "../../../../../components/Loading";
    import {mapGetters, mapActions} from 'vuex';
    import { languages } from '../../../../../localization/languages';
    import BackButton from "@/components/layout/BackButton";

    export default {
        name: "StudyFieldDetail",

        components: {
            BackButton,
            SaveButton,
            Loading
        },

        props: {
            from: null,
            id: null,
        },

        data() {
            let Rid = this.$route.params.Rid;

            return {
                proRid: Rid,

                valid: false,
                isSaving: false,

                codeRules: [
                    v => (!!v && String(v).trim() !== "") || this.$t('entities.reasons.code_required')
                ],

                nameRules: [
                    v => !!v || this.$t('entities.reasons.name_required'),
                ],

                studyFieldModel: {
                    short_code: null,
                    name: null,
                    name_en: null,
                    study_type: null,
                    type: null,
                    targetLanguages: [],
                    assistantId: null,
                },

                studyFieldModelCopy: null,

                studyTypeSelect: [
                    {"text": this.$t('entities.study_types.Bachelor'), "value": "Bachelor"},
                    {"text": this.$t('entities.study_types.Master'), "value": "Master"},
                    {"text": this.$t('entities.study_types.Doctoral'), "value": "Doctoral"},
                    {"text": this.$t('entities.study_types.Other'), "value": "Other"},
                ],

                typeSelect: [
                    {"text": this.$t('entities.study_types.daily_type'), "value": "daily_type"},
                    {"text": this.$t('entities.study_types.weekly_type'), "value": "weekly_type"},
                    {"text": this.$t('entities.study_types.other_type'), "value": "other_type"},
                ],

                headers: [
                    {
                        text: this.$t('headers.name'),
                        align: 'left',
                        value: 'name',
                        width: '33%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.room'),
                        align: 'left',
                        value: 'default_room',
                        width: '50%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.action'),
                        align: 'left',
                        value: 'action',
                        width: '10%',
                        sortable: false,
                    },
                ],

                dataToDataTable: []
            }
        },

        created() {
            this.fetchStudyFields();
            this.fetchAssistants();

            if (this.proRid !== "new") {
                this.loadModel();
            }
            this.setItemsToTable();

            this.createCopyOfOriginal();
        },

        computed: {
            ...mapGetters('assistant', ['getAssistants', 'didLoadedAssistants']),
            ...mapGetters('studyFields', ['didLoadedStudyFields', 'getStudyField', 'getStudyFields']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),

            didLoadedAll() {
                return this.didLoadedStudyFields && this.didLoadedAssistants;
            },

            isNewStudyField() {
                return this.proRid === 'new';
            },

            buttonDisabledValidate() {
                return !this.valid || this.compareOriginalAndStateNow();
            },

            validateUserRoles() {
                return this.$auth.getStudyAssistantAndRole()?.role === 'STUDY';
            },

            languages() {
                return languages;
            },

          studyAssistantsInSelect(){
            // this method filters out assistants that are already assigned to the subject & prepares data for v-select
            let selectArr = this.getAssistants
                .filter(a => !this.dataToDataTable.some(e => e.id === a.id))
                .map( item => ({
                      text: item.name,
                      value: item.id
                    })
            )
            // adding option for not assigning any assistant
            selectArr.push({
              text: this.$t('entities.assistants.no_study_ref_assigned'),
              value: null
            })
            return selectArr;
          },
        },

        methods: {
            ...mapActions('studyFields', ['fetchStudyFields', 'setStudyFieldsStorage']),
            ...mapActions('assistant', ['fetchAssistants']),

            loadModel() {
                let studyField = this.getStudyField(this.proRid);

                this.studyFieldModel.name = studyField.name;
                this.studyFieldModel.name_en = studyField.name_en;
                this.studyFieldModel.short_code = studyField.shortCode;
                this.studyFieldModel.study_type = this.setProperStudyType(studyField.studyType);
                this.studyFieldModel.type = this.setProperType(studyField.type);
                this.studyFieldModel.archived = studyField.archived;
                this.studyFieldModel.targetLanguages = studyField.targetLanguages;
            },

            deleteFromVuex(id) {
                let items = this.getStudyFields;
                return items.filter(studyProblemsItem => studyProblemsItem.id !== id);
            },

            deleteStudyField() {
                if (confirm(this.$t('entities.reasons.confirm_deletion'))) {
                    this.setStudyFieldsStorage(this.deleteFromVuex(this.proRid));
                    del('study_department/study_fields/' + this.proRid)
                        .then(() => {
                            this.goBack();
                        })
                        .catch(e => alert(e.message));
                }
            },

            setItemsToTable() {
                this.dataToDataTable = [];

                let studyFields = this.getAssistants;

                for (let item of studyFields) {
                    if (item.studyFieldIds.some( e => e === this.proRid)) {
                        let dataPrototype = {
                            name: null,
                            default_room: null,
                            id: null,
                        };

                        dataPrototype.name = item.name;
                        dataPrototype.default_room = item.defaultRoom;
                        dataPrototype.id = item.id;
                        this.dataToDataTable.push(dataPrototype);
                    }
                }
            },

            createCopyOfOriginal: function () {
                this.studyFieldModelCopy = JSON.parse(JSON.stringify(this.studyFieldModel));
            },

            compareOriginalAndStateNow: function () {
                return JSON.stringify(this.studyFieldModel) === JSON.stringify(this.studyFieldModelCopy);
            },

            putEditedStudyField() {
                put('study_department/study_fields/' + this.proRid, {
                    "name": this.studyFieldModel.name,
                    "name_en": this.studyFieldModel.name_en,
                    "shortCode": this.studyFieldModel.short_code,
                    "studyType": this.studyFieldModel.study_type.value,
                    "type": this.studyFieldModel.type.value,
                    "archived": this.studyFieldModel.archived,
                    "targetLanguages": this.studyFieldModel.targetLanguages,
                    "assistantId" : this.studyFieldModel.assistantId
                }).then(response => {
                    this.setStudyFieldsStorage(response.data.items)
                    this.fetchAssistants()
                    this.isSaving = false
                    this.goBack()
                }).catch(e => {
                    if (e.statusCode > 200 && e.statusCode < 500) {
                        this.could_save = false;
                        alert(this.$t('entities.couldnt_save'));
                    }
                    if (e.statusCode === 500) {
                        this.could_save = false;
                        alert(this.$t('entities.couldnt_save_server'));
                    }
                });
            },

            addNewStudyField: function () {
                if (this.$refs.form.validate()) {
                    this.isSaving = true;

                    post('study_department/study_fields/', {
                        "name": this.studyFieldModel.name,
                        "name_en": this.studyFieldModel.name_en,
                        "shortCode": this.studyFieldModel.short_code,
                        "studyType": this.studyFieldModel.study_type.value,
                        "type": this.studyFieldModel.type.value,
                        "targetLanguages": this.studyFieldModel.targetLanguages,
                      "assistantId" : this.studyFieldModel.assistantId
                    }).then(response => {
                        this.setStudyFieldsStorage(response.data.items)
                      this.fetchAssistants()
                        this.isSaving = false;
                        this.$router.push({name: "StudyFieldPortal"})
                    }).catch(e => {
                        if (e.statusCode > 200 && e.statusCode < 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                        if (e.statusCode === 500) {
                            this.could_save = false;
                            alert(this.$t('entities.couldnt_save_server'));
                        }
                    });
                }
            },

            goBack() {
                if (this.from === "portal") {
                    this.$router.push({name: "StudyFieldPortal"});
                }
                if (this.from === "assistant") {
                    this.$router.push({name: "AssistantDetail", params: {Rid: this.id}});
                }
            },

            goToDetail(Rid) {
                this.$router.push({name: 'AssistantDetail', params: {Rid: Rid, from: "field", id: this.proRid}});
            },

            setProperStudyType(studyType) {
                switch (studyType) {
                    case 'Bachelor':
                        return {
                            text: this.$t('entities.study_types.Bachelor'),
                            value: "Bachelor"
                        }
                    case 'Master':
                        return {
                            text: this.$t('entities.study_types.Master'),
                            value: 'Master'
                        }
                    case 'Doctoral':
                        return {
                            text: this.$t('entities.study_types.Doctoral'),
                            value: 'Doctoral'
                        }
                    case 'Other':
                        return {
                          text: this.$t('entities.study_types.Other'),
                          value: 'Other'
                        }
                }
            },

            setProperType(type) {
                switch (type) {
                    case 'weekly_type':
                        return {
                            text: this.$t('entities.study_types.weekly_type'),
                            value: 'weekly_type'
                        }
                    case 'daily_type':
                        return {
                            text: this.$t('entities.study_types.daily_type'),
                            value: 'daily_type'
                        }
                    case 'other_type':
                        return {
                            text: this.$t('entities.study_types.other_type'),
                            value: 'weekly_type'
                        }

                }
            }
        }
    }
</script>

<style scoped>
    .max_width_none {
        max-width: none !important
    }

    .no_padding {
        padding: 0;
    }

    .no_margin {
        margin: 0;
    }

    .width_max {
        width: 100%;
    }

    .h2_header {
        margin-bottom: 1.5rem;
        width: 40%;
        float: left;
    }

    .text {
        font-size: large;
    }

    .float_left {
        float: left;
        margin: 0;
        padding: 0;
    }

    .top {
        margin-top: 1.5rem;
        padding: 0 !important;
    }

    .new_button {
        float: right;
    }

    .delete_this {
        font-size: large;
        font-weight: bold;
        color: red;
        cursor: pointer;
    }

    .divider_margin {
        margin: 2rem 0 0 0;
    }
</style>
