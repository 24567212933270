<template>
    <Loading v-if="didLoadReports === false"/>
    <div v-else>
        <ModuleHeader>
            <RefreshButton @click="refreshReports" data-cy="refresh-reports-button">
                <h2 data-cy="reports-heading">Problémy a nápady</h2>
            </RefreshButton>
        </ModuleHeader>

      <v-tabs class="mb-3" :value="setTabValue">
        <v-tab @click="changeToNew">
          Nové
        </v-tab >
        <v-tab @click="changeToArchived">
          Archiv
        </v-tab>
        <v-tab @click="changeToWeb">
          Web
        </v-tab>
      </v-tabs>

        <v-data-table
                :items="getFilteredReports"
                :hide-default-header="true"
                class="d-none d-sm-block"
                :items-per-page.sync="$query.rowsPerPage"
                :page.sync="$query.page"
        >
            <template v-slot:item="{ item }" v-if="tabControl">

                <tr @click="goToDetail(item)" :class="item.state === 'pending' ? 'pending-report' : ''">
                    <td style="width:5%"><span class="pointer">{{ item.number }}</span></td>
                    <td>
                        <TwoLineCellWithIcon>
                            <template v-slot:icon>
                                <v-icon v-if="item.type === 'idea'" :color="item.state === 'pending' ? 'primary' : 'secondary'">emoji_objects</v-icon>
                                <v-icon v-else :color="item.state === 'pending' ? 'red' : 'secondary'">report_problem</v-icon>
                            </template>
                            <template v-slot:first>
                                {{ item.description | ellipsis(100) }}
                            </template>
                            <template v-slot:second>
                                <span class="location">
                                    <v-icon v-if="getTargetIcon(item)" small>{{ getTargetIcon(item) }}</v-icon>
                                    {{ getTargetId(item) }}
                                    {{ getTargetId(item) && item.location ? ' - ' : '' }}
                                    {{ item.location }}
                                </span>
                            </template>
                        </TwoLineCellWithIcon>
                    </td>
                    <td style="width:20%">
                        <span :class="item.state === 'inProgress' ? 'bold' : ''">
                            <small><v-icon>{{ getStateIcon(item.state) }}</v-icon></small>
                            {{ item.state | translate | firstUpper }}
                        </span>
                    </td>
                    <td style="width:20%">{{ formatDateTime(item.created) }}</td>
                </tr>
            </template>
          <template v-else v-slot:item="{ item }" >

            <tr @click="goToWebDetail(item)" :class="item.urgent === true ? 'urgent-report' : ''">
              <td style="width:5%"><span class="pointer">{{ item.id }}</span></td>
              <td>
                <TwoLineCellWithIcon>
                  <!-- contact_support -->
                  <template v-slot:icon>
                    <v-icon v-if="item.type === 'idea'" :color="item.urgent === true ? 'orange' : 'secondary'">emoji_objects</v-icon>
                    <v-icon v-else-if="item.type === 'question'" :color="item.urgent === true ? 'primary' : 'secondary'">help</v-icon>
                    <v-icon v-else :color="item.urgent === true ? 'red' : 'secondary'">report_problem</v-icon>
                  </template>
                  <template v-slot:first>
                    {{ item.description | ellipsis(100) }}
                  </template>
                </TwoLineCellWithIcon>
              </td>
              <td style="width:20%">{{ formatDateTime(item.created) }}</td>
            </tr>
          </template>
        </v-data-table>

        <!-- ONLY FOR SMALLER SCREENS -->
        <v-data-iterator
            :items="getFilteredReports"
            :hide-default-footer="true"
            :disable-pagination="true"
            :hide-default-header="false"
            class="d-block d-sm-none"
            :items-per-page.sync="$query.rowsPerPage"
            :page.sync="$query.page"
        >
        <template v-slot:no-data>
                Žádná dostupná data
        </template>
        <template v-slot:default="props">
        <v-row  v-if="tabControl">
          <v-col v-for="item in props.items" :key="item.name" cols="12" class="py-1">
            <mobile-data-table-row
                :wasRead="item.state !== 'pending'"
              @click="goToDetail(item)"
            >
              <template v-slot:number>{{ item.number }}</template>
              <template v-slot:tag>{{ item.state | translate }}</template>
              <template v-slot:title>{{
                item.description | ellipsis(100) 
              }}</template>
              <template v-slot:icon1> {{ getTargetIcon(item) }}</template>
              <template v-slot:subtitle1>
                {{ getTargetId(item) }}
                {{ getTargetId(item) && item.location ? ' - ' : '' }}
                {{ item.location }}
              </template>
              <template v-slot:label_header1>NAHLÁŠENO</template>
              <template v-slot:label_header2>TYP</template>
              <template v-slot:label_content1>{{ formatDateTime(item.created) }}</template>
              <template v-slot:label_content2>{{ getTypeTraslated(item.type)
              }}</template>
              
            </mobile-data-table-row>
          </v-col>
        </v-row>
        <v-row v-else>
            <v-col v-for="item in props.items" :key="item.name" cols="12" class="py-1">
              <mobile-data-table-row
                  :wasRead="item.urgent === false"
                  @click="goToWebDetail(item)"
              >
                <template v-slot:number>{{ item.id }}</template>
                <template v-slot:tag v-if="item.urgent === true">{{"Urgentné"}}</template>
                <template v-slot:title>{{
                    item.description | ellipsis(100)
                  }}</template>
                <template v-slot:label_header1>NAHLÁŠENO</template>
                <template v-slot:label_header2>TYP</template>
                <template v-slot:label_content1>{{ formatDateTime(item.created) }}</template>
                <template v-slot:label_content2>{{ getTypeTraslated(item.type)
                  }}</template>

              </mobile-data-table-row>
            </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import ModuleHeader from "../../components/layout/ModuleHeader";
    import Loading from "../../components/Loading";
    import RefreshButton from "../../components/buttons/RefreshButton";
    import TwoLineCellWithIcon from "../../components/layout/TwoLineCellWithIcon";
    import MobileDataTableRow from '../../components/layout/MobileDataTableRow.vue';

    export default {
        name: "Reports",
        components: {ModuleHeader, Loading, RefreshButton, TwoLineCellWithIcon, MobileDataTableRow},
        created() {
            this.fetchReports();
            this.fetchWebReports();
        },

    data() {
        return {
            dialog: false,
            loader: false,
            showArchived: false,
            required_rule: value => !!value || 'Povinné',
            headers: [
                {
                    text: 'Číslo',
                    align: 'left',
                    sortable: true,
                    value: 'number',
                    width: '5%'
                },
                {
                    text: 'Popis',
                    align: 'left',
                    sortable: false,
                    value: 'description',
                    width: '40%'
                },
                {
                    text: 'Stav',
                    align: 'left',
                    sortable: true,
                    value: 'state',
                    width: '10%'
                },
                {
                    text: 'Nahlášeno',
                    align: 'left',
                    sortable: true,
                    value: 'created',
                    width: '10%'
                }
            ],
            emptyReport: {
                description: "",
                location: "",
                type: null,
                state: "pending",
                photosCount: 0
            },
            newReport: {
                description: "",
                location: "",
                type: null,
                state: "pending",
                photosCount: 0
            }
        }
    },
    computed: {
        tabControl(){
          return (this.$route.query.tab === 'archived' || this.$route.query.tab === undefined) ;
          },

        setTabValue(){
          if (this.$route.query.tab === 'archived') return 1;
          else if (this.$route.query.tab === 'web') return 2
          else return 0;
        },
        pages () {
            if (this.pagination.rowsPerPage == null ||
                this.pagination.totalItems == null
            ) return 0;

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
        },

        getFilteredReports() {
          if(this.$query.tab === 'archived') {
            return this.getArchivedReports;
          }
          else if (this.$query.tab === 'web'){
            return this.getWebReports;
          }
          else{
            return this.getReports;
          }
        },

        ...mapGetters('reports', ['getReports', 'getArchivedReports', 'getTypes', 'didLoadReports', 'getStateIcon']), //Calls method action
        ...mapGetters('Helpdesk', ['getWebReports','getWebTypes'])
    },
    methods: {
        ...mapActions('Helpdesk', ['fetchWebReports']),
        ...mapActions('reports', ['fetchReports', 'deleteReport', 'createReport']),

        changeToNew(){
          this.$query.tab = 'new'
        },
        changeToArchived(){
          this.$query.tab = 'archived'
        },
        changeToWeb(){
          this.$query.tab = 'web'
        },

        createNewReport() {
          if(this.$refs.form.validate()){
            this.loader = true;
            this.close();
            this.createReport(this.newReport).then(() => {
              this.loader = false;
              this.newReport = Object.assign({}, this.emptyReport);
            });
          }
        },

        refreshReports() {
            this.fetchReports();
            this.fetchWebReports();
        },

        getTypeTraslated(en){
            if (this.tabControl){
              if (this.getTypes != null) {
                const result = this.getTypes.filter(i => i.value == en)
                return result ? result[0].text : ''
              }
              return ''
            }else {
              if (this.getWebTypes != null) {
                const result = this.getWebTypes.filter(i => i.value == en)
                return result ? result[0].text : ''
              }
              return ''
            }
        },

        close () {
            this.dialog = false;
        },

        filterReports() {
          this.$router.push({ path: this.$route.path, query: { page: this.$query.page, archived: this.$route.query.tab }});
        },

        goToDetail(item) {
            this.$router.push({name: 'reportDetail', params: {id: item.id}});
        },
        goToWebDetail(item) {
            this.$router.push({name: 'webReportDetail', params: {id: item.id}});
          },

        attemptToDeleteReport(id) {
            if (confirm("Opravdu smazat report #" + id + "?")) {
                this.deleteReport(id);
            }
        },

        formatDateTime(timestamp) {
            return moment(timestamp).format('lll');
        },

        getTargetParts(report) {
            return report.target ? report.target.split('/') : null;
        },

        getTargetId(report) {
            const parts = this.getTargetParts(report);
            return parts ? parts[1] : null;
        },

        getTargetIcon(report) {
            const parts = this.getTargetParts(report);

            if (parts) {
                const module = parts[0];

                if (module) {
                    switch (module) {
                        case 'faq':
                            return 'question_answer';
                        case 'place':
                            return 'place';
                    }
                }

            }

            return null;
        },
    }
}
</script>

<style scoped>
    .new-report {
        font-weight: bold;
    }
    th, td {
        cursor: pointer;
    }
    .pending-report td {
        font-weight: bold;
    }
    .urgent-report td {
        font-weight: bold;
    }
    .location {
        font-weight: normal !important;
        color: gray;
    }
    tr:not(.pending-report) {
        background-color: rgb(243, 243, 243);
    }
    .bold {
        font-weight: bold;
    }
</style>
