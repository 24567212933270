import { config } from "@/config"
import { firebaseMessaging } from "@/services/firebase/firebase";
import { post } from '../../../services/api/index'

const state = {
    isLoading: false,
    token: null,
    wantsNotifications: localStorage.getItem('notifications') === 'true',
};

const getters = {
    /**
     * Whether Firebase notifications are initialized and supported by the browser. 
     * Safari currently does not support it (July 2021).
     */
    areNotificationsSupported: () => firebaseMessaging !== null,

    /**
     * Whether the notifications are enabled, allowed in the browser and the device token was retrieved successfully.
     */
    areNotificationsEnabled: state => state.token !== null,
    
    /** 
     * True if the notification settings are being changed and some async operation takes place.
     */ 
    isLoadingNotificationSettings: state => state.isLoading,
};

const actions = {
    /**
     * Call this action to determine the current notification settings and refresh the token.
     * 
     * If the user wants notification (called subscribeNotifications before) and the user's browser has notifications enabled for this site, call subscribe to get a new token and set everything up.
     * Elsewhere call unsubscribe to reset all settings and deactivate notifications. 
     * For example, if the user wants notifications but the browser has disabled notifications, unsubscribe the user to respect the browser's settings which has the priority.
     */
    async initNotifications({ dispatch, state }) {
        if (Notification.permission === 'granted' && state.wantsNotifications) {
            await dispatch('subscribeNotifications');
        } else {
            await dispatch('unsubscribeNotifications');
        }
    },

    /**
     * Turns on notifications for this browser. 
     * 
     * This action tries to obtain a device specific token from Firebase and sends this token to MM backend to be assigned to the current user.
     * 
     * If the token retrieval fails and the browser has disabled notifications, an alert is shown with a link to a tutorial how to enable notification in the browser. 
     * After the user enables notifications in the browser and reloads the page, the notification are initialized automatically thanks to the initNotifications action.
     */
    async subscribeNotifications({ commit }) {
        commit('setLoading', true);
        commit('setWantsNotifications', true);

        try {
            let registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
            const token = await firebaseMessaging.getToken({ vapidKey: config.firebase.vapidKey, serviceWorkerRegistration: registration });
            if (!token) throw "Cannot get token.";

            commit('setToken', token);
            await post('devices/', { firebaseToken: token });

        } catch (err) {
            commit('resetToken');

            if (Notification.permission === 'denied' && window.confirm('Zakázali jste notifikace ze stránky mm.mendelu.cz ve vašem internetovém prohlížeči. Klikněte na OK pro zobrazení návodu, jak je povolit.')) {
                window.open('https://support.humblebundle.com/hc/en-us/articles/360008513933-Enabling-and-Disabling-Browser-Notifications-in-Various-Browsers');
            } else {
                console.log(err);
            }

        } finally {
            commit('setLoading', false);
        }
    },

    /**
     * Disables notification for this browser.
     * 
     * Deletes the device token and sets up local storage to prevent re-obtaining token in the future.
     */
    async unsubscribeNotifications({ commit }, setWantsNotificationsToFalse) {
        commit('setLoading', true);

        // When turning off manually
        if (setWantsNotificationsToFalse)
            commit('setWantsNotifications', false);

        commit('resetToken');

        try {
            await firebaseMessaging.deleteToken();
        } catch {
            console.log('Cannot delete Firbase notification token. But no big deal.');
        }
        
        commit('setLoading', false);
    },

};

const mutations = {
    setToken: (state, token) => state.token = token,
    resetToken: state => state.token = null,
    setWantsNotifications: (state, wants) => {
        state.wantsNotifications = wants;
        localStorage.setItem('notifications', wants);
    },
    setLoading: (state, value) => state.isLoading = value,

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
