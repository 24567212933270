<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-dialog v-else v-model="dialog" persistent max-width="700px">
        <template v-slot:activator="{ on }">
            <v-text-field
                    id="request-dialog-field"
                    v-model="dialogModel"
                    :label="$t('reservations.choose_req')"
                    readonly
                    v-on="on"
                    solo
                    aria-required="true"
                    :hide-details="true">
            </v-text-field>
        </template>
        <v-card>
            <v-form ref="form"
                    v-model="valid">
                <v-card-title>
                    <span class="headline">{{ $t('reservations.pick_req_ref') }}</span>
                </v-card-title>

                <v-card-text>
                    <v-divider></v-divider>
                    <v-container class="mt-4">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <span class="text">{{ $t('reservations.pick_req_cat') }}</span>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-select
                                        id="category-select"
                                        v-model="chosenCategory"
                                        :rules="[v => !!v || $t('entities.required')]"
                                        :items="getStudyProblemsCategories"
                                        :item-text="textCategory"
                                        return-object
                                        required
                                        solo
                                ></v-select>
                            </v-col>

                            <v-col cols="12" sm="4">
                                <span class="text">{{ $t('reservations.pick_confirm') }}</span>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-select
                                        id="category-define"
                                        v-model="chosenItem"
                                        :rules="[v => !!v || $t('entities.required')]"
                                        :items="getStudyProblemsItems"
                                        :item-text="textItem"
                                        :disabled="setDisabled()"
                                        return-object
                                        required
                                        solo
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog(false)">{{ $t('reservations.close') }}</v-btn>
                    <v-btn id="save-require-type" color="blue darken-1" :disabled="!valid" text @click="closeDialogAndSetModel()">{{ $t('reservations.add') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Loading from "../../../../components/Loading";

    export default {
        components: {Loading},

        name: "RequirementDialog",

        props: {
            index: {
                type: Number,
                default: null,
            },

            requirem: {
                type: Object,
                default: null,
            },

            from: {
                type: String,
                default: null,
            },

            idOfSelectedItems: {
                type: Array,
                default: function () {
                    return [];
                },
            },
        },

        data() {
            return {
                valid: true,
                form: null,

                dialogModel: null,
                dialog: false,

                chosenCategory: null,
                chosenItem: null,
            }
        },

        computed: {
            ...mapGetters('closedHours', ['didLoadedClosedHours']),
            ...mapGetters('assistant', ['didLoadedAssistants']),
            ...mapGetters('queueItemStatuses', ['didLoadedQueueItemStatuses']),
            ...mapGetters('queueItems', ['didLoadedQueueItems']),
            ...mapGetters('studyProblemsCategories', ['getStudyProblemsCategories', 'didLoadedStudyProblemsCategories']),
            ...mapGetters('studyProblemsItems', ['getStudyProblemsItems', 'didLoadedStudyProblemsItems']),
            ...mapGetters('studyFields', ['didLoadedStudyFields' ]),

            didLoadedAll() {
                return this.didLoadedAssistants && this.didLoadedStudyFields && this.didLoadedStudyProblemsItems && this.didLoadedStudyProblemsCategories
                    && this.didLoadedQueueItemStatuses && this.didLoadedQueueItems && this.didLoadedClosedHours;
            },
        },

        mounted() {
            this.preloadModel();
        },

        methods: {
            preloadModel: function () {
                if (this.from === "Edit" && this.requirem.category_object !== null && this.requirem.item_object !== null) {
                    this.chosenCategory = this.requirem.category_object;
                    this.chosenItem = this.requirem.item_object;
                    this.dialogModel = this.chosenCategory.name + ' - ' + this.chosenItem.name;
                }
            },

            resetModelCreate() {
                if (this.requirem.category_object === null && this.requirem.item_object === null) {
                    this.dialogModel = ""
                    this.chosenCategory = null;
                    this.chosenItem = null;
                }
            },

            resetModelEdit() {
                if (this.from === "Edit" && this.requirem.category_object !== null && this.requirem.item_object !== null) {
                    this.dialogModel = ""
                    this.chosenCategory = this.requirem.category_object;
                    this.chosenItem = this.requirem.item_object;
                }
            },

            closeDialog: function (value) {
                this.resetModelCreate();
                this.resetModelEdit();
                this.dialog = value;
            },

            setDisabled: function () {
                return this.chosenCategory === null;
            },

            closeDialogAndSetModel: function () {
                if (this.chosenCategory != null && this.chosenItem != null) {
                    this.dialogModel = this.chosenCategory.name + ' - ' + this.chosenItem.name;
                    this.dialog = false;
                    this.sendSelect();
                }
            },

            setCategoryAndItemInNewReservation: function () {
                this.$emit('setCategoryAndItem', this.chosenCategory, this.chosenItem, this.index);
            },

            setCategoryAndItemInNewReservationEdit: function () {
                this.$emit('setCategoryAndItemEdit', this.chosenCategory, this.chosenItem, this.index);
            },


            sendSelect: function () {
                if (this.from === "New") {
                    this.setCategoryAndItemInNewReservation();
                } else {
                    this.setCategoryAndItemInNewReservationEdit();
                }
            },

            textCategory: item => item.name.length > 50 ? item.name.substr(0, 47) + '...' : item.name,
            textItem: item => item.name.length > 50 ? item.name.substr(0, 47) + '...' : item.name,
        },

    }
</script>

<style scoped>
    .no_margin_no_padding {
        margin: 0;
        padding: 0;
    }

    .text {
        font-size: large;
    }

</style>
