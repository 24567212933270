<template>
  <div>
    <table v-if="days.length > 0" class="schedule-table">
        <thead>
          <tr>
            <th class="day-column text-center">Den</th>
            <th class="text-center">Časy zobrazování</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="day in days" :key="day.index" class="table-row">
            <td class="text-center">{{day.name}}</td>
            <td>
              <IntervalPicker 
                :key="getAllDayInitialValue(day.index) ? `picker-${day.index}-all-day` : `picker-${day.index}`"
                :dayOfWeek="day.index"
                v-on:intervalsChanged="(value) => updateSchedule(day.index, value)"
                :initialValues="getIntervalInitialValues(day.index)"
                :allDayInitial="getAllDayInitialValue(day.index)">
              </IntervalPicker>
            </td>
          </tr>
        </tbody>
    </table>
  </div>
</template>

<script>
  import moment from 'moment';
  import IntervalPicker from './IntervalPicker';
  export default {
    name: "WeekScheduler",
    props: {
      initialValues: Array,
      daysOfWeek: Array
    },
    components: {
      IntervalPicker
    },

    data() {
      return {
        pickerTitle: this.title ? this.title : 'Datum',
        days: this.daysOfWeek ? this.daysOfWeek : [],
        schedule: this.initialValues ? this.initialValues : []
      };
    },
    
    watch: {
      schedule(value) {
        this.lodash.isEqual(this.initialValues, value) || this.$emit('scheduleChanged', value);
      },
      daysOfWeek() {
        this.days = this.daysOfWeek;
        const indexes = this.days.map((day) => day.index);
        this.schedule = this.schedule.filter(item => indexes.includes(item.dayOfWeek));
      }
    },

    methods: {
      getIntervalInitialValues(dayOfWeek) {
        return this.initialValues ? this.initialValues.filter(item => item.dayOfWeek === dayOfWeek && (item.timeFrom !== '00:00' || item.timeTo !== '23:59')) : [];
      },
      getAllDayInitialValue(dayOfWeek) {
        return this.initialValues && !!this.initialValues.find(item => item.dayOfWeek === dayOfWeek && item.timeFrom === '00:00' && item.timeTo === '23:59');
      },
      sendValue() {
        this.$emit('dateChanged', moment(this.dateValue).startOf('day'));
      },
      setValue(value) {
        this.schedule = value;
      },
      updateSchedule(dayOfWeek, schedule) {
        this.schedule = this.schedule.filter(item => item.dayOfWeek !== dayOfWeek).concat(schedule);
      }
    }
  };
</script>

<style scoped>
  .schedule-table {
    border: 1px solid #E0E0E1;
    width: 100%;
  }

  thead {
    border: none;
    background: #E0E0E1;
  }

  .table-row {
    border: 1px solid #F5F5F5
  }

  .day-column {
    max-width: 50px;
    min-width: 35px
  }
</style>