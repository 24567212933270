<template>
    <Loading v-if="!didLoadData"/>
    <v-container v-else>
        <BackButton
            :target="{name: 'SensorsDashboard'}"
        >
            {{ $t('iotSensors.detail_back_button') }}
        </BackButton>
        <div v-if="place">
            <v-row class="text-center text-md-left">
                <v-col cols="12">
                    <h1 style="color: #373A3C;">{{ place.localizedAttributes.cz.name }}
                        {{ place.localizedAttributes.cz.nickname ? " • " + place.localizedAttributes.cz.nickname : "" }}</h1>
                </v-col>
            </v-row>
            <v-row class="text-center text-md-left" no-gutters>
                <v-col>
                    <div class="title grey--text text--darken-2 pt-0">{{ $t('places.types.' + place.type) }} •
                        {{ place.building.cz.description }} • {{ place.floor.cz.name }}. {{ $t('iotSensors.floor') }}
                    </div>
                </v-col>
            </v-row>
            <v-row class="mt-5 text-center text-md-left" justify="space-between">
                <v-col class="measurements" cols="12" md="5" sm="12">
                    <div v-if="place.measurements.length !== 0" class="mb-3 title">{{ $t('iotSensors.measurement_headline') }}</div>
                    <v-row v-if="place.measurements.length !== 0" class="text-center" justify="center" justify-lg="start">
                        <v-col v-for="measurement in place.measurements" :key="measurement.id" cols="12" sm="4">
                            <v-row>
                                <v-col>
                                    <v-card class=" py-4 px-2" outlined>
                                        <v-icon class="ma-2" v-bind:style="{ color: measurement.color }">{{ measurement.icon }}</v-icon>
                                        <p class="mb-1 body-1">{{ $t('iotSensors.measurements.' + measurement.type) }}</p>
                                        <v-card-title v-if="measurement.type === 'TEMPERATURE'" class="title justify-center pa-0">
                                            {{ measurement.numberValue }} {{ $t('iotSensors.degree_celsius') }}
                                        </v-card-title>
                                        <v-card-title v-else class="justify-center pa-0">
                                            {{ measurement.numberValue }} {{ measurement.unit }}
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-if="place.measurements.length !== 0">
                        <v-col>
                            <v-alert
                                v-if="place.measurements.length !== 0"
                                v-model="alert"
                                :color="place.color"
                                :type="place.alertType"
                                class="mt-6 mb-1"
                                style="border-radius: 12px !important;"
                                text
                            >
                                {{ $t(`iotSensors.alert.${place.alertType}`) }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row v-if="place.computerOccupancy">
                        <v-col>
                            <div class="mt-2 mb-2 mt-xl-8  title">{{ $t('iotSensors.measurement_second_headline') }}</div>
                            <v-row class="text-center" justify="center" justify-lg="start">
                                <v-col v-if="place.computerOccupancy" cols="12" sm="4">
                                    <v-card class="py-4 px-2" outlined>
                                        <v-icon class="mb-3" color="primary">desktop_windows</v-icon>
                                        <p class="mb-1 body-1">
                                            {{ $t('iotSensors.free_pcs') }}
                                        </p>
                                        <v-card-title class="justify-center pa-0">
                                            {{ place.computerOccupancy.total - place.computerOccupancy.occupied }}
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-card class=" py-4 px-2" outlined>
                                        <v-icon class="mb-3" color="primary">scanner</v-icon>
                                        <p class="mb-1 body-1">
                                            {{ $t('iotSensors.projector') }}
                                        </p>
                                        <v-card-title class="justify-center pa-0">
                                            {{ place.computerWithProjector ? $t('entities.yes') : $t('entities.no') }}
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-card class=" py-4 px-2" outlined>
                                        <v-icon class="mb-3" color="primary">cast</v-icon>
                                        <p class="mb-1 body-1">
                                            {{ $t('iotSensors.whiteboard') }}
                                        </p>
                                        <v-card-title class="justify-center pa-0">
                                            {{ place.whiteboard ? $t('entities.yes') : $t('entities.no') }}
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-else-if="!place.computerOccupancy && place.measurements.length === 0">
                        <v-col cols="12" md="5">
                            <NoData />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-0">
                            <div class="mt-4 title text-sm-center text-md-left">{{ $t('iotSensors.measurement_tech_list') }}</div>
                        </v-col>
                    </v-row>
                    <v-row v-if="!$vuetify.breakpoint.xs" class="justify-sm-center justify-md-start">
                        <v-col>
                            <RoundedSlideGroup
                                :modelValue="typeModel" @update:modelValue="newValue => typeModel = newValue"
                                :listOfItems="typesList()"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col>
                            <v-select
                                v-model="typeModel"
                                :items="typesList()"
                                :item-text="typesList().text"
                                solo
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="typeModel === 'iotSensors.technology_types.pc_list'" no-gutters class="align-center">
                        <v-col>
                            <IotComputersComponent />
                        </v-col>
                    </v-row>
                    <v-row v-else no-gutters>
                        <v-col>
                            <IotSoftwareComponent :numberOfSoftware="place.numberOfSoftware"/>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row justify="space-between" no-gutters>
                    <v-col md="6">
                      <div class="title text-left">{{ $t('map.generator.map') }}</div>
                    </v-col>
                    <v-col class="text-right" md="6">
                      <LightButton v-on:click="toggleManualDialog()" class="mb-2">{{ $t('map.generator.bigger_map') }}</LightButton>
                    </v-col>
                  </v-row>
                    <v-row no-gutters>
                        <v-col cols="12">
                          <div class="map">
                            <MapWidget
                                :places="mapData"
                                :selected="[place.id]"
                                :center="place.positionOfPlace"
                            />
            
                            <geometry-dialog
                                :showDialog="this.isManualDialogShown"
                                :position="this.place.positionOfPlace"
                                :showCentroid="false"
                                :placeType="this.place.type"
                                :placeName="this.place.localizedAttributes.cz.name"
                                :backgroundObjects="backgroundObjectsExceptShownOne"
                                @close="toggleManualDialog()"
                            />
                          </div>
                        </v-col>
                    </v-row>
                    
                </v-col>
            </v-row>




        </div>
    </v-container>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import BackButton from "@/components/layout/BackButton";
import LightButton from "../../components/buttons/LightButton.vue";
import Loading from "@/components/Loading";
import MapWidget from "@/components/map/MapWidget";
import GeometryDialog from '../../components/map/GeometryDialog.vue';
import RoundedSlideGroup from "@/components/RoundedSlideGroup";
import IotSoftwareComponent from "@/components/iot/IotSoftwareComponent";
import IotComputersComponent from "@/components/iot/IotComputersComponent";
import NoData from "@/components/NoData";


export default {

  name: 'IotPlaceDetail',

  components: {
    BackButton,
    LightButton,
    Loading,
    MapWidget,
    GeometryDialog,
    RoundedSlideGroup,
    IotSoftwareComponent,
    IotComputersComponent,
    NoData
  },

  data() {
    return {
      typeModel: 'iotSensors.technology_types.pc_list',
      place: null,
      alert: true,
      showManualDialog: false,
    }
  },
  computed: {
    ...mapGetters('IotPlaces', ['iotPlace', 'didLoadedPlace']),
    ...mapGetters('places', ['getPlace', 'placesByFloor']),
    ...mapGetters('floors', ['getFloorById']),

    isManualDialogShown(){
      return this.showManualDialog
    },


    didLoadData() {
      return this.didLoadedPlace
    },

    backgroundObjects() {
        return this.placesByFloor(this.place.floorIdOfPlace).map(place => ({
          id: place.id,
          type: place.type,
          position: place.position,
          name: place.localizedAttributes.cz.name,
        }));
      },

    backgroundObjectsExceptShownOne() {
      return this.backgroundObjects.filter(object => object.id !== this.place.id);
    },

    mapData() {
      return [
        ...this.backgroundObjectsExceptShownOne,
        {
            id: this.place.id,
            type: this.place.type,
            position: this.place.positionOfPlace,
            name: this.place.localizedAttributes.cz.nickname || this.place.localizedAttributes.cz.name
        }
      ];
    },



  },

  async created() {
    await Promise.all([
      this.fetchIotPlace(this.$route.params.id),
      this.fetchPlace(this.$route.params.id)
    ])

    this.place = this.iotPlace
    switch (this.place.color) {
      case "#78be13":
        this.place.alertType = "success"
        break
      case "#e02020":
        this.place.alertType = "error"
        break
      default:
        this.place.alertType = "warning"
    }

    this.place.positionOfPlace = this.getPlace.position
    this.place.floorIdOfPlace = this.getPlace.floorId
    await this.fetchFilteredPlaces({floorId: this.place.floorIdOfPlace, buildingId: null, exterior: null, placeName: null, language: null})
  },

  methods: {
    ...mapActions('IotPlaces', ['fetchIotPlace']),
    ...mapActions('places', ['fetchPlace', 'fetchFilteredPlaces']),
    ...mapActions('floors', ['fetchFloorById', 'fetchAllFloors']),


    building() {
      return this.getSingleBuilding(this.buildingType);
    },

    toggleManualDialog() {
      this.showManualDialog = !this.showManualDialog;
    },

    typesList() {
        return Object.entries(this.$t('iotSensors.technology_types')).map(type => {
            return {
                value: "iotSensors.technology_types." + type[0],
                text: type[1]
            }})
    },

  },
}
</script>
<style scoped>
.v-card {
    width: 65vw;
    border: 2px solid #CCCCCC !important;
    border-radius: 12px !important;
    margin: 0 auto;
    word-wrap: break-word;
}

.map {
  height: 50vh;
}

#map-widget {
  border-radius: 15px !important;
  z-index: 1;
}


</style>