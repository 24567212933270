<template>
	<ItemNotFoundState v-if="objectNotFound" :objectName="'Lokace'">
		<template #backButton>
      <BackButton :target="{name: 'OnboardingMaster'}">Lokace</BackButton>
		</template>
	</ItemNotFoundState>
	<Loading v-else-if="didLoadLocation !== true|| !location"></Loading>
	<div v-else>
    <BackButton :target="{name: 'OnboardingMaster'}">Lokace</BackButton>
		<ModuleHeader id="moduleHeader">
			<h2 v-if="isNew">Nová lokace</h2>
			<h2 v-else>Úprava lokace</h2>
			<v-spacer/>
			<SaveButton
				:is-saving="isSaving"
				:disabled="!isFormValid || !isQuestionValid"
				@click="save()"
				label="Uložit" />
		</ModuleHeader>
		<v-alert v-if="!isFormValid" type="warning" max-width="600px" class="mt-4 mb-0">Pro přidání lokace je potřebný název v obou jazycích a pozice lokace</v-alert>

		<v-form @submit.prevent="save" ref="form" :value="isFormValid">
			<div class="language-selector mb-5">

				<div class="d-flex align-center">
					<v-tabs
						v-model="currentLanguage"
						color="light-green darken-1 pull-right"
					>
						<v-tab id="cz" :href="'#' + this.languages[0].value" class="small" :key="languages[0].value">
							<div class="d-flex align-center">
								<p class="mb-0 ml-2">{{this.languages[0].text}}</p>
							</div>

						</v-tab>

						<v-tab id="en" :href="'#' + languages[1].value" class="small" :key="languages[1].value">
							<div class="d-flex align-center">
								<p class="mb-0 ml-2">{{this.languages[1].text}}</p>
							</div>
						</v-tab>
					</v-tabs>

					<v-tabs-items v-model="currentLanguage">
						<v-tab-item
							:value="languages[0].value"
						></v-tab-item>
						<v-tab-item
							:value="languages[1].value"
						></v-tab-item>
					</v-tabs-items>

				</div>
			</div>
			<v-layout>
				<v-row>
					<v-col cols="12">
						<h4 class="mb-3 title">Povinné informace</h4>
						<v-row>
							<v-col cols="12" md="8">
								<v-row>
									<v-col cols="12">
										<v-row>
											<v-col cols="12">
												<v-text-field
													id="location-name"
													label="Název lokace"
													:rules="[required_rule]"
													v-model="location.localizedAttributes[currentLanguage].name"
													:rows="1"
													maxlength="50"
													counter
													auto-grow
													color="light-green darken-1"
												> </v-text-field>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12" sm="6">
												<v-select
													color="light-green darken-1 pull-right"
													:items="locationTypes"
													v-model="location.locationType.id"
													label="Typ lokace"
												></v-select>
											</v-col>
											<v-col cols="12" sm="6">
												<v-select
													id="scanningType"
													color="light-green darken-1 pull-right"
													:items="scanningTypes"
													v-model="location.scanningType.id"
													label="Typ skenování"
												></v-select>
											</v-col>
										</v-row>
										<div v-if="location.scanningType.id === 'ic_id_qr'">
											<v-col cols="12">
												<v-btn v-if="location.scanningType.id === 'ic_id_qr'" block @click="showQrCodeDialog = true">Zobrazit QR kód</v-btn>
											</v-col>
										</div>
										<v-row v-else-if="location.scanningType.id === 'ic_id_bluetooth'">
											<v-col cols="12" lg="4">
												<v-btn id="showBeaconPickDialog" block @click="showBeaconPickDialog = true">
													{{ 'Zvolit beacon' }}
												</v-btn>
											</v-col>
											<v-col cols="12" lg="8" v-if="location.beacon !== null && location.beacon.beaconId !== null">
												<v-btn text block color="primary" @click="goToBeaconDetail(location.beacon.id)">{{ location.beacon.beaconId }}</v-btn>
											</v-col>
											<BeaconDialog :show-dialog="showBeaconPickDialog" @updateBeaconId="updateBeaconId" @close="showBeaconPickDialog = false"/>
										</v-row>
										<v-row>
											<v-col cols="12">
												<v-textarea
													label="Krátký popis"
													v-model="location.localizedAttributes[currentLanguage].promptDescription"
													:maxlength="150"
													counter
													auto-grow
													:rows="1"
													color="light-green darken-1"
												> </v-textarea>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12">
												<v-textarea
													label="Dlouhý popis"
													v-model="location.localizedAttributes[currentLanguage].mainDescription"
													auto-grow
													:rows="3"
													color="light-green darken-1"
												> </v-textarea>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="12" md="4">
								<v-row>
									<v-col sm="6" md="12" cols="12" id="photos-component" v-if="!isNew">
										<div>
											<span class="mb-3 label">Náhledový obrázek</span>
											<template v-if="!isNew && getLocationImages.length > 0">
												<v-card id="images">
													<v-carousel height="23vw" v-model="carouselActiveImage">
														<v-btn v-if="!isNew" fab dark small color="error" @click="attemptToDeleteActiveImage()" class="ml-5 mb-3 pull-right delete-image">
															<v-icon>fa-trash-alt</v-icon>
														</v-btn>
														<v-carousel-item
															class="photo-item"
															v-for="(image ,i) in getLocationImages"
															:key="i"
															:src="getImageUrl(image.id)"
															@click="redirect(getImageUrl(image.id))"
															reverse-transition="fade-transition"
															transition="fade-transition"
														></v-carousel-item>
													</v-carousel>
												</v-card>
											</template>
											<template>
												<vue-dropzone
													ref="dropzone"
													id="dropzone"
													:options="dropzoneImageOptions"
													@vdropzone-complete="finishSaving"
													@vdropzone-file-added="fileAdded('dropzone')"
													@vdropzone-success="onFileSucessfullyUploaded"
													@vdropzone-error="fileUploadError"
												></vue-dropzone>
											</template>
										</div>
									</v-col>
									<v-col sm="6" md="12" cols="12" class="map-component">
										<div class="d-flex justify-space-between align-center map-controls">
											<span class="mb-3 label">Pozice</span>
											<v-btn id="openMapDialog" color="primary" text v-on:click="toggleManualDialog()" class="pull-right">Upravit</v-btn>
										</div>
										<div class="map">
											<MapWidget
												:places="mapData"
												:center="location.position ? [location.position] : []"
												:zoom="17.5"
											/>
											<GeometryDialog
												:showDialog="showManualDialog"
												:position="location.position ? [location.position] : []"
												:showCentroid="false"
												:placeType="null"
												:placeName="location.localizedAttributes.cz.name"
												:allowedGeometryTypes="['marker']"
												@updatePosition="updatePosition"
												@close="toggleManualDialog()"
											/>
										</div>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<h4 class="mb-3 title">Doplňkové informace</h4>
								<v-row>
									<v-col cols="12" md="6" v-if="!isNew">
										<div>
											<span class="mb-3 label">AR Model - iOS</span>
											<div v-if="'ios' in location.augmentedRealityModelAvailability" class="model-card">
												<span>{{location.augmentedRealityModelAvailability.ios}}</span>
												<div>
													<v-btn icon dark @click="redirect(getModelUrl(location.augmentedRealityModel + '?platform=ios'))">
														<v-icon>fa-download</v-icon>
													</v-btn>
													<v-btn fab dark x-small color="error" @click="attemptToDeleteFile('ios')">
														<v-icon>fa-trash-alt</v-icon>
													</v-btn>
												</div>
											</div>
											<template>
												<vue-dropzone
													ref="file-dropzone-ios"
													id="file-dropzone-ios"
													:options="dropzoneFileOptionsIOS"
													@vdropzone-complete="finishSaving"
													@vdropzone-file-added="fileAdded('file-dropzone-ios')"
													@vdropzone-success="onFileSucessfullyUploaded"
													@vdropzone-error="fileUploadError"
												></vue-dropzone>
											</template>
										</div>
									</v-col>
									<v-col cols="12" md="6" v-if="!isNew">
										<div>
											<span class="mb-3 label">AR Model - Android</span>
											<div v-if="'android' in location.augmentedRealityModelAvailability" class="model-card">
												<span>{{location.augmentedRealityModelAvailability.android}}</span>
												<div>
													<v-btn icon dark @click="redirect(getModelUrl(location.augmentedRealityModel + '?platform=android'))">
														<v-icon>fa-download</v-icon>
													</v-btn>
													<v-btn fab dark x-small color="error" @click="attemptToDeleteFile('android')">
														<v-icon>fa-trash-alt</v-icon>
													</v-btn>
												</div>
											</div>
											<template>
												<vue-dropzone
													ref="file-dropzone-android"
													id="file-dropzone-android"
													:options="dropzoneFileOptionsAndroid"
													@vdropzone-complete="finishSaving"
													@vdropzone-file-added="fileAdded('file-dropzone-android')"
													@vdropzone-success="onFileSucessfullyUploaded"
													@vdropzone-error="fileUploadError"
												></vue-dropzone>
											</template>
										</div>
									</v-col>
									<v-col cols="12">
										<v-textarea
											label="Zajímavosti"
											v-model="location.localizedAttributes[currentLanguage].interests"
											auto-grow
											:rows="3"
											color="light-green darken-1"
										> </v-textarea>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Webová stránka"
											v-model.trim="location.url"
											:append-icon="location.url ? 'launch' : undefined"
											@click:append="openUrl(location.url)"
											color="light-green darken-1"
										> </v-text-field>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-row class="test-question-header">
									<v-col cols="12 d-flex justify-space-between">
										<span class="label">Testová otázka</span>
										<v-btn id="removeQuestion" class="no-padding-btn" v-if="location.testQuestion !== null" color="red" text @click="removeQuestion">Odstranit otázku</v-btn>
									</v-col>
									<v-col v-if="!isQuestionValid" cols="12">
										<v-alert id="testQuestionAlert" type="warning">Pro přidání otázky je potřebné vyplnit znění otázky a všech odpovědí v obou jazycích</v-alert>
									</v-col>
								</v-row>
								<div v-if="location.testQuestion !== null">
									<div class="test-question">
										<v-row>
											<v-col cols="12" class="d-flex align-center">
												<v-text-field
													id="testQuestionText"
													label="Název otázky"
													v-model="location.testQuestion.localizedAttributes[currentLanguage].text"
													:rows="1"
													color="light-green darken-1"
												> </v-text-field>
											</v-col>
										</v-row>
										<div id="testAnswerOptions" v-if="testAnswers.length > 0" class="d-flex justify-center flex-column">
											<v-radio-group v-model="optionSelected">
												<div v-for="(ans, index) in testAnswers" :key="index">
													<v-row class="option" :class="{'highlight' : index === optionSelected}">
														<v-col cols="1" class="d-flex align-center">
															<v-radio
																:value="index"
																:dark="index === optionSelected"
															></v-radio>
														</v-col>
														<v-col cols="10" class="d-flex align-center">
															<v-textarea
																label="Možnost"
																v-model="ans.localizedAttributes[currentLanguage].text"
																auto-grow
																:rows="1"
																:dark="index === optionSelected"
																color="light-green darken-1"
															></v-textarea>
														</v-col>
														<v-col cols="1" class="d-flex align-center">
															<v-icon
																v-if="canDeleteAnswer"
																:dark="index === optionSelected"
																@click="removeAnswer(index)"
															>close</v-icon>
														</v-col>
													</v-row>
												</div>
											</v-radio-group>
										</div>
										<div style="padding: 10px;">
											<v-btn
												id="addAnswer"
												block
												rounded
												color="primary"
												@click="addNewAnswer"
											>Přidat další možnost</v-btn>
										</div>
									</div>
									<div class="test-question-footer">
										<span>
											Výběrem buňky označíte právě 1 správnou odpověd testu.
										</span>
									</div>
								</div>
								<div v-else style="padding: 10px;">
									<v-btn
										id="addNewQuestion"
										block
										rounded
										color="primary"
										@click="addNewQuestion"
									>Přidat otázku</v-btn>
								</div>
								<v-btn id="deleteLocation" class="no-padding-btn" v-if="!isNew" color="red" text @click="attemptDeleteLocation">Smazat lokaci</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-layout>
			<v-dialog v-model="deleteLocationDialog" max-width="500px">
				<v-card>
					<v-card-title><h5 class="text-h5 ma-auto">Opravdu chcete smazat tuto lokaci?</h5></v-card-title>
					<v-card-actions>
						<v-spacer/>
						<v-btn color="blue darken-1" text @click="deleteCancel">Zrušit</v-btn>
						<v-spacer/>
						<v-btn id="deleteConfirm" color="red darken-1" text @click="deleteConfirm">Potvrdit</v-btn>
						<v-spacer/>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog v-model="showQrCodeDialog" max-width="700px">
				<v-card style="text-align: center">
					<v-card-title class="text-h5">Zobrazení QR kódu</v-card-title>
					<qrcode
						ref="qr_code"
						:value="showQrData"
						:options="{
							width: 300,
							color: {
								dark: '#000000',
								light: '#eeeeee',
							},
                        }"
						tag="img"></qrcode>
					<v-card-actions>
						<v-spacer/>
						<v-btn color="primary" rounded @click="downloadQRCode">Stáhnout</v-btn>
						<v-spacer/>
					</v-card-actions>
					<v-card-actions>
						<v-spacer/>
						<v-btn text @click="showQrCodeDialog = false">Zrušit</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-form>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
	import vue2Dropzone from 'vue2-dropzone'
	import 'vue2-dropzone/dist/vue2Dropzone.min.css'
	import ModuleHeader from "@/components/layout/ModuleHeader";
	import BackButton from "@/components/layout/BackButton";
	import SaveButton from "@/components/buttons/SaveButton";
	import {getLocalizedLanguages} from "@/localization/languages";
	import MapWidget from "@/components/map/MapWidget";
	import GeometryDialog from "@/components/map/GeometryDialog";
	import ItemNotFoundState from '@/components/states/ItemNotFoundState.vue';
	import Loading from "@/components/Loading";
	import _ from "lodash";
	import {config} from "@/config";
	import Vue from "vue";
	import BeaconDialog from "@/components/dialogs/BeaconDialog";

	export default {
		name: "LocationDetail",

		components: {
			BeaconDialog,
			GeometryDialog,
			MapWidget,
			SaveButton,
			BackButton,
			ModuleHeader,
			ItemNotFoundState,
			Loading,
			vueDropzone: vue2Dropzone,
		},

		data(){
			const allowedFileTypes = ".jpg,.jpeg,.png";
			const id = this.$route.params.id;
			const defaultDropzoneOptions = {
				method: 'post',
				addRemoveLinks: false,
				autoProcessQueue: true,
				filesizeBase: 1000, // use 1MB=1000KB as the metric for maxFilesize
				maxFilesize: 29, // MB, must be lower than limit in nginx proxy and lower than limit on backend!
				paramName: "file",
				headers: { "Authorization" : "Bearer " + Vue.$auth.getToken() },
			}
			const dropzoneFileOptions = {
				...defaultDropzoneOptions,
				url: config.serverUrl + 'onboarding/locations/images/' + id,
				createImageThumbnails: false,
				dictDefaultMessage: "<i class='fas fa-paperclip' style='font-size: 36px; opacity: 0.6;'></i><br>Přetáhněte soubory pro nahrání. (Existující soubory budou přepsány.)",
				dictFileTooBig: "Příliš velký soubor ({{filesize}} MB). Max. {{maxFilesize}} MB.",
				dictResponseError: "Chyba serveru {{statusCode}}",
			}

			return {
				required_rule: value => (value !== "") || 'Povinné',
				isSaving: false,
				uploader: null,
				man: "updated-marker",
				currentLanguage: "cz",
				showManualDialog: false,
				showBeaconPickDialog: false,
				carouselActiveImage: 0,
				dropzoneImageOptions: {
					...defaultDropzoneOptions,
					url: config.serverUrl + 'onboarding/locations/images/' + id,
					acceptedFiles: allowedFileTypes,
					thumbnailWidth: 200,
					thumbnailHeight: 200,
					createImageThumbnails: true,
					maxThumbnailFilesize: 9999, // always generate thumbnails, otherwise we are not able to check minimal dimensions
					dictDefaultMessage: "<i class='fas fa-paperclip' style='font-size: 36px; opacity: 0.6;'></i><br>Přetáhněte fotky pro nahrání",
					dictFileTooBig: "Příliš velký soubor ({{filesize}} MB). Max. {{maxFilesize}} MB.",
					dictInvalidFileType: "Obrázek musí být ve formátu " + allowedFileTypes,
					dictResponseError: "Chyba serveru {{statusCode}}",
					init: function () {
						this.on("thumbnail", function(file) {
							if (file.acceptDimensions && file.rejectDimensions) {
									file.acceptDimensions();
							}
						});
					},
					accept: function(file, done) {
						file.acceptDimensions = done;
						file.rejectDimensions = function() { done("Obrázek je příliš malý, min. 1000px!"); };
					}
				},
				dropzoneFileOptionsIOS: {
					...dropzoneFileOptions,
					acceptedFiles: ".scn",
					dictInvalidFileType: "Soubor musí být ve formátu .scn",
				},
				dropzoneFileOptionsAndroid: {
					...dropzoneFileOptions,
					acceptedFiles: ".zip",
					dictInvalidFileType: "Soubor musí být ve formátu .zip",
				},
				optionSelected: null,
				testAnswers: [],
				deleteLocationDialog: false,
				showQrCodeDialog: false,
			}
		},

		async mounted(){
			this.clear();
			await this.fetchLocations();
			await this.fetchNeededOnboardingUtils();

			if (!this.isNew) {
				await this.fetchLocationById(this.$route.params.id)
				if (this.objectNotFound)
					return;
			} else {
				this.prepareNewLocation();
				this.setDidLoadLocation(true);
			}

			if ( this.location.testQuestion !== null ){
				this.testAnswers = this.location.testQuestion.answers ? this.location.testQuestion.answers : [];
				for ( let i = 0; i < this.testAnswers.length; i++ ) {
					if ( this.testAnswers[i].correct ) this.optionSelected = i;
				}
			}
		},

		watch: {
			locationId() {
				this.loadData();
			},
			optionSelected() {
				this.testAnswers.forEach((el, i) => {
					el.correct = i === this.optionSelected
				});
			},
			deleteLocationDialog(val) {
				val || this.deleteCancel()
			},
			showQrCodeDialog(val) {
				val || (this.showQrCodeDialog = false)
			}
		},

		computed: {
			...mapState('Locations', ['currentLocation']),
			...mapGetters('Locations', ['locationById', 'getLocation', 'getNewAnswer', 'getNewQuestion', 'getLocationImages', 'didLoadLocation', 'getLocationTypes', 'getScanningTypes']),
			...mapGetters('errors', ['objectNotFound']),

			isNew() { return this.locationId === null; },

			locationId() {
				const urlId = this.$route.params.id;
				return urlId === "new" ? null : Number(urlId);
			},

			location() {
				return this.currentLocation;
			},

			languages() {
				const langs = getLocalizedLanguages();
				const selectArr = [];
				Object.keys(langs).map((lng) => {
					selectArr.push({
						'text': langs[lng],
						'value': lng,
					});
				});
				return selectArr;
			},
			locationTypes() {
				const selectArr = [];
				this.getLocationTypes.map((val) => {
					selectArr.push({
						'text': val.name,
						'value': val.id,
					});
				});
				return selectArr;
			},
			scanningTypes() {
				const selectArr = [];
				this.getScanningTypes.map((val) => {
					selectArr.push({
						'text': val.name,
						'value': val.id,
					});
				});
				return selectArr;
			},

			/**
			 * Form validation methods.
			 * @returns {*}
			 */
			isFormValid() {
				return (
					this.isPositionFilled && this.isNameLocalizedCorrectly && this.isScannableValid
				);
			},
			isPositionFilled() {
				return this.location.position;
			},
			isNameLocalizedCorrectly() {
				return this.location.localizedAttributes[this.languages[0].value].name !== "" &&
					this.location.localizedAttributes[this.languages[1].value].name !== "";
			},
			isScannableValid() {
				if ( this.location.scanningType.id === 'ic_id_bluetooth' ) {
					return this.location.beacon !== null;
				} else {
					return true;
				}
			},
			isQuestionValid() {
				return this.isQuestionFilledProperly && this.isQuestionLocalizedCorrectly;
			},
			isQuestionFilledProperly() {
				if ( this.location.testQuestion === null){
					return true;
				} else {
					return this.testAnswers.length > 1;
				}
			},
			isQuestionLocalizedCorrectly(){
				if ( this.location.testQuestion ) {
					let tq = this.location.testQuestion.localizedAttributes[this.languages[0].value].text !== "" &&
						this.location.testQuestion.localizedAttributes[this.languages[1].value].text !== "";
					let ta = true;
					this.testAnswers.forEach((a) => {
						let lta = a.localizedAttributes[this.languages[0].value].text !== "" && a.localizedAttributes[this.languages[1].value].text !== "";
						if (!lta) ta = false;
					});
					return tq && ta;
				}
				return true;
			},

			/**
			 * Checks if there is a minimum number of answers needed for a question to be complete.
			 * @returns {boolean}
			 */
			canDeleteAnswer(){
				return this.testAnswers && this.testAnswers.length > 2;
			},

			/**
			 * Prepares the location for use in MapWidget.
			 * @returns {{name, id: string, position: *[], type: null}[]}
			 */
			mapData() {
				return [
					{
						id: 'location',
						type: null,
						position: this.location.position ? [this.location.position] : [],
						name: this.location.localizedAttributes.cz.name
					}
				];
			},

			/**
			 * Generates QR code data.
			 * @returns {string}
			 */
			showQrData() {
				let qrCodeToShowData = {
					locationId: this.location.id
				};
				return JSON.stringify(qrCodeToShowData);
			},


		},

		methods: {
			...mapActions('leavePrevent', ['didSaveChanges']),
			...mapActions('Locations', ['fetchLocations', 'fetchLocationById', 'prepareNewLocation', 'createLocation', 'updateLocation', 'deleteLocation', 'addImage', 'deleteImage', 'deleteFile', 'fetchNeededOnboardingUtils']),
			...mapMutations('Locations',['setDidLoadLocation']),
			...mapActions('errors', ['clear']),

			/**
			 * Loads actual data from server or prepares a blank location.
			 * @returns {Promise<void>}
			 */
			async loadData(){
				if ( !this.isNew ) {
					await this.fetchLocationById(this.locationId);
				} else {
					this.clear();
					await this.prepareNewLocation();
				}
			},

			/**
			 * Helper method that attempts to save or update a location.
			 * @returns {Promise<void>}
			 */
			async save(){
				if (this.$refs.form.validate()) {
					this.isSaving = true;

					const locationRequest = {
						id: this.location.id !== null ? this.location.id : null,
						position: this.location.position,
						beaconId: this.location.scanningType.id === 'ic_id_bluetooth' ? this.location.beacon?.id : null,
						augmentedRealityModel: this.location.augmentedRealityModel,
						localizedAttributes: this.location.localizedAttributes,
						locationTypeId: this.location.locationType.id,
						scanningTypeId: this.location.scanningType.id,
						testQuestion: this.location.testQuestion,
						testAnswers: this.testAnswers,
						url: this.location.url,
					}

					if ( this.isNew ){
						await this.createLocation({location: locationRequest});
						// await this.$router.push({name: 'locationDetail', params: {id: id}})
						await this.$router.push({name: 'OnboardingMaster', query: {tab: 'locations'}})
          }
					else {
						await this.updateLocation({location: locationRequest}).then( () => {
							this.isSaving = false;
						});
					}
				}
			},

			/**
			 * Helper method to bring changes from GeometryDialog back into a location object.
			 * @param value
			 */
			updatePosition(value) {
				this.location.position = value[0];
			},

			/**
			 * Helper method to toggle GeometryDialog display.
			 */
			toggleManualDialog(origin) {
				this.showManualDialog = !this.showManualDialog;
				this.uploader = origin;
			},

			/**
			 * Callback function to initialize file upload.
			 */
			fileAdded(origin) {
				this.imagesUploading = true;
				this.uploader = origin;
			},

			/**
			 * Callback function that handles file upload.
			 */
			finishSaving() {
				this.didSaveChanges();
				this.imagesUploading = false;
				this.hasRecentlyUpdated = true;
				this.isUpdating = false;
				setTimeout(() => this.hasRecentlyUpdated = false, 2000);
				if(this.isNew){
					setTimeout(() => {
						this.setProcessing(false);
            this.$router.push({name: 'OnboardingMaster', query: { tab: 'locations'}});
					}, 1500);
				}
			},

			/**
			 * Callback function that handles response result after a file upload.
			 * @param file
			 * @param response
			 */
			onFileSucessfullyUploaded(file, response) {
				// add the new uploaded image to the carousel
				this.addImage(response.content);
				// after a while, remove the uploaded image from dropzone
				setTimeout(() => {
					this.$refs[this.uploader]["removeFile"](file);
					this.uploader = null;
				}, 2000);
			},

			/**
			 * Callback function that handles removing the file after an unsuccessful upload.
			 */
			fileUploadError(file) {
				setTimeout(() => {
					this.$refs[this.uploader]["removeFile"](file);
					this.uploader = null;
				}, 4000);
			},

			/**
			 * Helper method to get the URL of an image.
			 * @param id
			 * @returns {string}
			 */
			getImageUrl(id){
				return config.serverUrl+"onboarding/locations/images/"+this.location.id+"/"+ id;
			},

			getModelUrl(path) {
				return config.serverUrl + path;
			},

			/**
			 * Helper method that opens an image in a new window.
			 * @param src
			 */
			redirect(src){
				window.open(src, '_blank');
			},

			/**
			 * Helper method that opens an URL in a new window.
			 * @param src
			 */
			openUrl(src){
				if (src.includes('://')) {
					window.open(src, '_blank');
				} else {
					window.open('http://' + src, '_blank');
				}
			},

			/**
			 * Helper method that attempts to delete currently selected image.
			 */
			attemptToDeleteActiveImage() {
				if (window.confirm('Opravdu chcete smazat tento obrázek natrvalo?')) {
					this.deleteImage({
						locationId: this.location.id,
						id: this.getLocationImages[this.carouselActiveImage].id
					});
				}
			},

			attemptToDeleteFile(platform) {
				if (window.confirm('Opravdu chcete smazat tento soubor natrvalo?')) {
					this.deleteFile({
						locationId: this.locationId,
						platform: platform
					})
				}

			},

			/**
			 * Helper method that attempts to delete a location.
			 */
			attemptDeleteLocation(){
				this.deleteLocationDialog = true;
			},

			/**
			 * Helper method that proceeds with location deletion.
			 */
			deleteConfirm(){
				this.deleteLocation(this.location.id).then(() => {
					this.deleteLocationDialog = false;
					this.$router.push({name: 'OnboardingMaster', query: {tab: 'locations'}});
				})
			},

			/**
			 * Helper method that cancels location deletion.
			 */
			deleteCancel(){
				this.deleteLocationDialog = false;
			},

			/**
			 * Helper method that adds a new test question with two answers to a location.
			 */
			addNewQuestion() {
				this.location.testQuestion = JSON.parse(JSON.stringify(this.getNewQuestion));
				// also add 2 answers
				_.times(2, this.addNewAnswer)
				this.optionSelected = 0;
			},

			/**
			 * Helper method that removes a test question from a location.
			 */
			removeQuestion() {
				this.location.testQuestion = null;
				this.testAnswers = [];
				this.optionSelected = null;
			},

			/**
			 * Helper method that adds a new test answer to a test question.
			 */
			addNewAnswer() {
				this.testAnswers.push(JSON.parse(JSON.stringify(this.getNewAnswer)));
			},

			/**
			 * Helper method that removes a test answer from a test question.
			 * @param index
			 */
			removeAnswer(index) {
				this.testAnswers.splice(index, 1);
				// update selected option
				this.optionSelected = null;
				this.testAnswers.forEach((el, i) => {
					if (el.correct) this.optionSelected = i;
				});
			},

			/**
			 * Trigger download of QR code image.
			 */
			downloadQRCode() {
				const download = document.createElement('a');
				download.href = this.$refs.qr_code.$el.src;
				download.download = this.location.localizedAttributes['cz'].name + '.png';
				download.click()
			},

			/**
			 * Callback function for BeaconDialog.
			 * @param beacon
			 */
			updateBeaconId(beacon) {
				this.location.beacon = beacon;
			},

			/**
			 * Show beacon details in a new tab.
			 * @param beaconId
			 */
			goToBeaconDetail(beaconId) {
				let routeData = this.$router.resolve({name: 'BeaconDetail', params: {id: beaconId}});
				this.redirect(routeData.href);
			}
		},
	}
</script>

<style scoped>

	#smallMap, .leaflet-container, .leaflet-pane {
		z-index: 1 !important;
	}
	.map {
		height: 45vh;
	}

	#photos-component{
		margin-bottom: 20px;
	}
	#photos-component > div {
		max-width: 45vw;
	}
	@media screen and (max-width: 600px){
		#photos-component {
			margin: 0 auto;
		}
		#photos-component > div {
			max-width: 88vw;
		}
	}
	.delete-image {
		position: absolute;
		z-index: 9;
		margin-top: 15px;
		right: 15px;
	}
	.vue-dropzone {
		border-radius: 15px;
		border: 3px dotted #cfcfcf;
		background-color: #efefef;
	}
	#images {
		margin-bottom: 20px;
	}

	.test-question-header {
		margin-bottom: 16px;
	}
	.test-question-header #testQuestionAlert{
		margin-bottom: 0 !important;
	}
	.test-question {
		background-color: #F5F5F5;
		border-radius: 15px;
		padding: 15px;
	}
	.test-question-footer {
		padding: 15px 16px;
	}

	.label {
		color: rgba(0,0,0,0.6) !important;
		line-height: 50px;
	}
	.map-controls .label, .map-controls button {
		margin-bottom: 0 !important;
		padding: 0 !important;
	}
	.option {
		background-color: #ffffff;
		padding: 10px 15px;
		border-radius: 10px;
		margin: 5px 0;
	}
	.option.highlight {
		background-color: #78BE13;
	}
	.no-padding-btn {
		padding: 0 !important;
	}

	.model-card {
		width: 100%;
		background-color: #78BE13;
		color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5em 1em;
		border-radius: 15px;
		margin: 5px 0;
	}
</style>
