<template>
  <div id="map-widget" ref="mapElement"></div>
</template>

<script>
import L from 'leaflet'
import "leaflet-draw"
import "leaflet-snap"
import {mapGetters} from "vuex"
import MapWidget from '@/services/Map/MapWidget'
import Icons from '@/services/Map/Icons'
import "leaflet-easyprint"
import {hasProperties} from "@/utils"
import MapVisual from "@/services/Map/MapVisual"

export default {
  name: "MapWidget",

  data() {
    return {
      map: null,
      icons: null,

      baseLayer: null,
      editableLayer: null,
      selectionLayer: null,

      editControl: null,
      drawControl: null,
      editHandler: null,
    }
  },

  props: {
    /**
     * An array of places to be displayed on the map. These places are not editable.
     * Each place must be an object with:
     * - required attributes: id, position, type.
     * - optional attributes: name, hideLabel
     */
    places: {
      type: Array,
      default: () => [],
      validator: a => a.every(p => hasProperties(p, ['id', 'position', 'type'])),
    },

    /**
     * An array of places to be displayed on the map that are editable using the map's toolbar.
     * Each place must be an object with:
     * - required attributes: id, position, type.
     * - optional attributes: name, hideLabel
     */
    editablePlaces: {
      type: Array,
      default: () => [],
      validator: a => a.every(p => hasProperties(p, ['id', 'position', 'type'])),
    },

    /**
     * Which types of objects can be created.
     * @values an array of marker, polygon or empty
     */
    creatableGeometryTypes: {
      type: Array,
      default: () => [],
      validator: a => a.every(i => ["marker", "polygon"].includes(i))
    },

    /**
     * Whether the user can click on map objects and show their details.
     */
    selectable: {
      type: Boolean,
      default: false,
    },

    /**
     * An array of places' ids that should be selected = visually larger (thick borders, larger icons).
     * Selected are over other objects.
     * If this array is not empty, all other objects except the selected ones are visually smaller (lower opacity)
     * Each place must be an object with:
     * - required attributes: id, position, type.
     * - optional attributes: name, hideLabel
     */
    selected: {
      type: Array,
      default: () => [],
      validator: a => a.every(p => Number.isInteger(p) || p.match(/(building\d+|edited-(object|centroid))/)),
    },

    /**
     * A small block of text displayed in the corner of the map.
     */
    legend: {
      type: String,
      default: null,
    },

    /**
     * Which part of the map should be displayed.
     * Can be either:
     * a) an array with one coordinate (with lat and lon attributes) - depicting at which coordinates the map's center should be,
     * b) an array of multiple coordinates - the map's center will be the center of boundiung box of the given coordinates
     * If the `zoom` prop is null, then the zoom is changed automatically in b) to fit all given coordinates in the map view. Otherwise the given zoom is used and not changed.
     */
    center: {
      type: Array,
      default: null,
    },

    /**
     * The current zoom level of the map.
     * If it is null, the zoom is changed automatically based on the given center coordinates.
     */
    zoom: {
      type: Number,
      default: null,
    },
  },

  async mounted() {
    this.map = new MapWidget(this.$refs.mapElement);
    this.map.init();
    this.map.setMyMendeluLogo();

    this.map.createPane("selectPane", 999);

    this.icons = new Icons();

    await this.initBaseLayer();
    await this.initEditableLayer();
    await this.initSelectionLayer();

    this.recenterMap();
    this.$emit('changeCenter', this.map.getCenter());

    this.updateControls();
    this.attachListeners();

    this.map.invalidateSize();

    this.map.leafletMap.drawControl = new L.Control.Draw({edit: false})
  },

  updated() {
    // hacking - fix leaflet tiles loading after reopening the dialog
    this.map.invalidateSize();
  },

  computed: {
    ...mapGetters('places', ['getUniversityPosition', 'getTypeName']),

    selectedPlaces() {
      return this.selected
          .map(id => this.places.find(p => p.id === id))
          .filter(p => p); // remove undefined places
    },

    placesSortedByZIndex() {
      // set this to array of place types that you want to hide on map
      const blacklist = []; // example ['toilet_male']
      return this.places.filter(p => p.searchable || !blacklist.includes(p.type))
          .slice().sort((a, b) =>
              MapVisual.placeZIndex(a.type) - MapVisual.placeZIndex(b.type)
          );
    },

    placesWithoutSelectedSortedByZIndex() {
      return this.placesSortedByZIndex.filter(p => !this.selected.includes(p.id))
    }
  },

  watch: {
    places() {
      this.baseLayer?.clearLayers();
      this.initBaseLayer();
    },

    selected() {
      this.baseLayer?.clearLayers();
      this.selectionLayer?.clearLayers();
      this.initBaseLayer();
      this.initSelectionLayer();
    },

    editablePlaces() {
      this.editableLayer?.clearLayers();
      this.initEditableLayer();
    },

    legend() {
      this.map.setLegend(this.legend);
    },

    center() {
      this.recenterMap();
    },

    zoom() {
      this.recenterMap();
    },
  },

  methods: {
    // --- LAYERS ---

    initBaseLayer() {

      this.baseLayer = this.createLayer(this.placesWithoutSelectedSortedByZIndex);
      this.map.addToMap(this.baseLayer);
    },

    initEditableLayer() {
      this.editableLayer = this.createLayer(this.editablePlaces);
      this.map.addToMap(this.editableLayer);
      this.configureLeafletToolbar()
    },

    initSelectionLayer() {

      this.selectionLayer = this.createLayer(this.selectedPlaces, "selectPane");
      this.map.addToMap(this.selectionLayer);
    },

    createLayer(places, pane) {
      let layer = new L.featureGroup();

      if (pane)
        layer.options.pane = pane

      places.forEach(place => {
        if (place.position.length === 1) {
          this.addMarker(place, layer);
        } else if (place.position.length > 1) {
          this.addPolygon(place, layer);
        }
      });

      return layer;
    },

    addMarker(obj, layer) {
      const weight = this.objectVisualWeight(obj);
      const pane = layer.options.pane
      const marker = this.map.createIconMarker(obj.id, obj.position, obj.type, obj.name, weight, pane);
      marker.id = obj.id;
      marker.on('click', () => this.$emit('selectedObject', marker));
      marker.on('dragend', (e) => {
        var marker = e.target.getLatLng();
        this.map.leafletMap.panTo(new L.LatLng(marker.lat, marker.lng));
        this.updatePosition(null, [marker]);
      })
      if (this.selectable) {
        this.addPopup(marker, obj);
      }

      layer.addLayer(marker);
    },

    addPolygon(obj, layer) {
      const weight = this.objectVisualWeight(obj);
      const options = MapVisual.getPolygonOptionsAccordingToType(obj.type, weight);

      const polygon = this.map.createObject(obj.id, 'polygon', obj.position, options);
      polygon.id = obj.id;
      polygon.on('click', () => this.$emit('selectedObject', polygon));

      if (obj.hideLabel !== true) {
        this.addPolygonLabel(obj, layer, polygon, weight);
      }

      if (this.selectable) {
        this.addPopup(polygon, obj);
      }

      layer.addLayer(polygon);
    },

    addPolygonLabel(obj, layer, polygon, weight) {
      if (!obj.name) {
        // If the polygon does not have a name, then we add an icon marker in the middle.
        //   const polygonMarker = this.map.addPolygonIcon(layer, polygon, obj.type, weight);
        //   if (this.selectable) {
        //       this.addPopup(polygonMarker, obj); // the marker must be clickable too
        //   }
      } else {
        // Otherwise we add the object's name into the polygon's center.
        this.map.addPolygonLabel(polygon, obj.name, weight);
      }
    },

    addPopup(mapObject, item) {
      const type = this.getTypeName(item.type);
      const name = item.name || type;
      const route = this.$router.resolve({name: 'mapDetail', params: {type: 'place', id: item.id}});

      let html = `<h4>${name}</h4>`;

      if (item.name) {
        html += `<div>${type}</div>`;
      }

      html += `<a href="${route.href}" target="_blank" class="popupElement">Detail objektu</a>`;

      this.map.addPopup(mapObject, html);
    },


    // --- TOOLBAR CONTROLS ---

    updateControls() {
      // remove old
      this.map.removeControl(this.drawControl);

      // prepare new controls with new config
      const defaulIcon = this.icons.createIcon(this.editablePlaces[0]?.type, this.editablePlaces[0]?.name, 1);
      this.drawControl = new L.Control.Draw({
        position: 'topright',
        edit: false,
        draw: {
          polyline: false,
          circle: false,
          rectangle: false,
          circlemarker: false,
          polygon: this.creatableGeometryTypes.includes('polygon'),
          marker: this.creatableGeometryTypes.includes('marker')
              ? {icon: defaulIcon}
              : false,
        }
      });

      // add new controls to map
      if (this.creatableGeometryTypes.length > 0) {
        this.map.addControl(this.drawControl);
      }
    },


    // --- EVENTS ---

    attachListeners() {
      this.map.on("zoomend", e => {
        const zoom = e.target.getZoom();
        this.hideElementsOnZoomLevel(zoom);
        this.$emit('zoom', zoom);
      })


      this.map.on("layeradd", e => {
        this.hideElementsOnZoomLevel(e.target.getZoom());
      })

      this.map.on("moveend", () => this.$emit('changeCenter', this.map.getCenter()));

      this.map.on("draw:drawstart", () => this.$emit('start-editing'));
      this.map.on("draw:editstart", () => this.$emit('start-editing'));

      this.map.on("draw:editstop", () => this.$emit('end-editing'));
      this.map.on("draw:drawstop", () => this.$emit('end-editing'));

      this.map.on("draw:created", (e) => {
        const coordinates = e.layer._latlngs ? e.layer._latlngs[0] : [e.layer._latlng];
        this.updatePosition(null, coordinates);
      });

      this.map.on("draw:edited", (e) => {
        e.layers.eachLayer((layer) => { // can edit multiple layers at once, so we need to iterate all layers
          const coordinates = layer._latlngs ? layer._latlngs[0] : [layer._latlng];
          this.updatePosition(layer.id, coordinates);
        });
      });

      this.map.on("draw:editvertex", (e) => {
        let coordinates = [];
        e.layers.eachLayer((layer) => { // can edit multiple layers at once, so we need to iterate all layers
          if (layer._index !== undefined)
            coordinates[layer._index] = layer._latlng
        });
        coordinates.push(coordinates[0])
        this.updatePosition(null, coordinates);
      });
      this.map.on("draw:drawvertex", (e) => {
        let coordinates = [];
        e.layers.eachLayer((layer) => { // can edit multiple layers at once, so we need to iterate all layers
          coordinates.push(layer._latlng)
        });
        coordinates.push(coordinates[0])
        this.updatePosition(null, coordinates);
      });

      this.map.on("draw:deleted", () => this.deleteAllPositions());
    },

    hideElementsOnZoomLevel(zoom) {
      const maxZoomView = 20; //How far can we zoom until the labels become illegible
      let labels = document.getElementsByClassName('polygon-name');
      let icons = document.getElementsByClassName('iconText');
      labels.forEach((el) => {
        el.style.display = zoom < maxZoomView ? "none" : "block";
      });
      icons.forEach((el) => {
        el.style.display = zoom < maxZoomView ? "none" : "";
      });
    },

    updatePosition(id, latLngArray) {
      // convert latlng array to classic array of objects
      // different attribute names at backend and leaflet
      const position = latLngArray.map((latLng, index) => ({
        lon: latLng.lng,
        lat: latLng.lat,
        order: index + 1,
      }));
      // emit event
      this.$emit('updatePosition', {id: id, position: position});
    },

    deleteAllPositions() {
      this.editablePlaces.forEach(p =>
          this.$emit('updatePosition', {id: p.id, position: []})
      );
    },


    // --- OTHERS ---

    exportPng(newSize = null) {
      this.map.exportPng(newSize);
    },

    /**
     * Sets the map center.
     * If both center and zoom props are set up, the map's view reflects them.
     * If only center prop is set, then the map's center reflects it and the zoom is set automatically to fit the center position in view.
     * Otherwise, the default coordinate and zoom is used (the whole university campus is visible).
     */
    recenterMap() {
      if (this.center?.length > 0 && this.zoom) {
        this.map.setCenter(this.center, this.zoom);
        this.hideElementsOnZoomLevel(this.zoom);
      } else if (this.center?.length > 0) {
        this.map.fitInView(this.center);
      } else {
        this.map.setCenter(this.getUniversityPosition(), 16.5);
      }
    },

    /**
     * Returns the visual weight of the given object based on the currently focused object.
     * If there is no focused object, all objects have default weight 0.
     */
    objectVisualWeight(object) {
      if (this.hasEditedMarker || this.hasEditedPolygon) {
        return -1;
      } else if (this.selected.length > 0) {
        return this.selected.includes(object.id) ? 1 : -1;
      } else {
        return 0;
      }
    },
    configureLeafletToolbar() {

      if (this.editablePlaces.length > 0 && this.editablePlaces[0].position.length > 1) {
        let editControl = new L.EditToolbar({
          featureGroup: this.editableLayer,
          draw: false
        })
        this.editHandler = editControl.getModeHandlers()[0].handler
        this.editHandler._map = this.map.leafletMap
        this.editHandler.enable()
      }
    },
    saveChanges() {
      this.$emit('end-editing')
    },
    revertChanges() {
      if (this.editHandler !== null) this.editHandler.revertLayers()
    },
  },
}
</script>

<style scoped>
#map-widget {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#map-widget >>> .leaflet-bar button {
  margin: 7px 7px 7px 7px;
  transition: 0.2s !important
}

#map-widget >>> .leaflet-control-zoom a {
  color: black !important;
}

#map-widget >>> .leaflet-control-zoom a:hover,
#map-widget >>> .leaflet-bar.easy-button-container.leaflet-control:hover,
#map-widget >>> .leaflet-draw-toolbar.leaflet-bar a:hover {
  background-color: #7cb342 !important;
  color: white !important;
}

#map-widget >>> .leaflet-bar.easy-button-container.leaflet-control {
  background-color: #FFFFFF;
}

#map-widget >>> .info-title {
  padding: 10px 10px;
  font: 12px/14px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  left: 90%;
  transform: translate(-50%, -180%);
}

a {
  color: #757575 !important;
}

#map-widget >>> .leaflet-draw-actions.leaflet-draw-actions-top.leaflet-draw-actions-bottom a,
#map-widget >>> .leaflet-draw-actions.leaflet-draw-actions-top a,
#map-widget >>> .leaflet-draw-section a {
  color: white !important;
}

#map-widget >>> .map--legend {
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bold;
}

#map-widget >>> .my-mendelu-logo {
  width: 220px;
  position: absolute;
  /*space for zoom controls*/
  left: 50px;
  height: 40px;
  padding: 0.75rem 1rem;
}

/* --- Map Icons --- */

#map-widget >>> .place-marker {
  text-align: center;
}

#map-widget >>> .place-marker .icon {
  filter: invert(1);
  height: 50%;
  width: 50%;
  margin-top: 15%;
  position: absolute;
  left: 25%;
  top: 0;
}

#map-widget >>> .place-marker .pin {
  height: 100%;
  width: 100%;
}

#map-widget >>> .place-marker .pin > svg {
  height: 100%;
  width: 100%;
}

#map-widget >>> .place-marker > .text {
  /*
  The width of the text block is set automatically based on the text length.
  However, it can be max. 2x as wide as the icon.
  The transform is used to make the text always centered, even when it is wider than the icon.
  */
  font-weight: bold;
  text-shadow: white 1px 1px 5px, white 0 0 5px;
  line-height: 1.3;
  margin-top: 0;
  text-align: center;
  width: fit-content;
  max-width: 200%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#map-widget >>> .place-marker > .text.broad {
  width: max-content;
}


#map-widget >>> .place-marker.increased-weight > .text {
  font-size: 120%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  padding: 0.1rem 0.4rem;
}

#map-widget >>> .place-marker.decreased-weight {
  opacity: 0.5;
}

#map-widget >>> .place-marker.decreased-weight > .text {
  color: gray;
}

#map-widget >>> .polygon-name {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

#map-widget >>> .polygon-name.increased-weight {
  font-size: 140%;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1;
}

#map-widget >>> .polygon-name.decreased-weight {
  color: gray;
}

#map-widget >>> .leaflet-control-easyPrint {
  display: none;
}

/* --- PRINT --- */

/* -- Narrow --*/

#map-widget.print-narrow >>> .my-mendelu-logo {
  width: 264px;
  height: 48px;
}

#map-widget.print-narrow >>> .map--legend {
  font-size: 1.4rem !important;
}

#map-widget.print-narrow >>> .place-marker.increased-weight > .text {
  max-width: none;
  width: 200% !important;
  font-size: 150% !important;
}

#map-widget.print-narrow >>> .place-marker > .text {
  font-size: 130% !important;
}

#map-widget.print-narrow >>> .place-marker .pin {
  height: 125%;
  width: 125%;
  margin-left: -15%;
}

#map-widget.print-narrow >>> .place-marker .icon {
  height: 75%;
  width: 75%;
  margin-left: -15%;
  margin-top: 15%;
}

#map-widget.print-narrow >>> .polygon-name.increased-weight {
  font-size: 1.2rem;
}

/* -- Regular --*/

#map-widget.print-regular >>> .my-mendelu-logo {
  width: 330px;
  height: 55px;
}

#map-widget.print-regular >>> .map--legend {
  font-size: 1.8rem !important;
}

#map-widget.print-regular >>> .place-marker.increased-weight > .text {
  max-width: none;
  width: 220% !important;
  font-size: 180% !important;
}

#map-widget.print-regular >>> .place-marker > .text {
  font-size: 150% !important;
}

#map-widget.print-regular >>> .place-marker .pin {
  height: 120%;
  width: 120%;
  margin-left: -15%;
}

#map-widget.print-regular >>> .place-marker .icon {
  height: 70%;
  width: 70%;
  margin-left: -15%;
  margin-top: 15%;
}

#map-widget.print-regular >>> .leaflet-tooltip {
  font-size: 0.9rem;
}

/* Selected room */
#map-widget.print-regular >>> .polygon-name.increased-weight {
  font-size: 1.4rem;
}

/* -- Wide --*/

#map-widget.print-wide >>> .my-mendelu-logo {
  width: 484px;
  height: 75px;
}

#map-widget.print-wide >>> .map--legend {
  font-size: 2.5rem !important;
}

#map-widget.print-wide >>> .place-marker.increased-weight > .text {
  max-width: none;
  width: 350% !important;
  font-size: 300% !important;
}

#map-widget.print-wide >>> .place-marker > .text {
  font-size: 250% !important;
}

#map-widget.print-wide >>> .place-marker .pin {
  height: 220%;
  width: 220%;
  margin-left: -50%;
}

#map-widget.print-wide >>> .place-marker .icon {
  height: 130%;
  width: 130%;
  margin-left: -30%;
  margin-top: 30%;
}

#map-widget.print-wide >>> .leaflet-tooltip {
  font-size: 1.4rem;
}

#map-widget.print-wide >>> .polygon-name.increased-weight {
  font-size: 2.5rem;
}
</style>
