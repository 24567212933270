<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-container v-else fluid style="margin: 0">
        <v-layout row class="no_margin div_under_div">
            <v-flex lg12>
                <v-col class="float_left" :lg="9">
                    <h2 class="header_div">
                        {{ $t('reservations.restrict_detail') }}
                    </h2>
                </v-col>
                <v-col class="float_left" :lg="3">
                    <div class="button_group">
                        <v-btn class="close_button mb-3 button margin_left" @click="goBack(assistantId)">{{ $t('reservations.close') }}</v-btn>
                        <v-btn id="edit-restriction" color="primary" :disabled="validateEditButton" dark class="mb-3 button margin_left"
                               @click="goToDetailEdit(assistantId)">{{ $t('reservations.edit') }}
                        </v-btn>
                    </div>
                </v-col>
            </v-flex>
        </v-layout>


        <v-layout row class="no_margin_no_padding">
            <v-flex xs9>
                <template>
                    <v-simple-table :height=returnCorrectHeight()>
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td class="text">1. {{ $t('reservations.restrict_date') }}</td>
                                <td class="text">{{returnWantedFormatDate(getClosedHour(proRid).date)}} </td>
                            </tr>
                            <tr>
                                <td class="text">2. {{ $t('reservations.restrict_time') }}</td>
                                <td class="text"> {{returnWantedFormatTime()}} </td>
                            </tr>
                            <tr>
                                <td class="text">3. {{ $t('reservations.restrict_agend') }}</td>
                                <td class="text">{{returnWantedAgenda()}}</td>
                            </tr>
                            <tr>
                                <td class="text">4. {{ $t('reservations.restrict_reason_change') }}</td>
                                <td class="text"> {{returnReason()}} </td>
                            </tr>
                            <tr>
                                <td class="text">5. {{ $t('reservations.longterm_action') }}</td>
                                <td class="text"> {{returnLongtermAction()}} </td>
                            </tr>
                            <tr v-if="getClosedHour(proRid).longTermOption === 'delegate'">
                                <td class="text">6. {{ $t('reservations.delegation_to') }}</td>
                                <td class="text"> {{returnLongtermAssistant()}} </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
            </v-flex>
        </v-layout>

        <v-layout row class="no_margin_left_added">
            <v-flex>
                <span class="text">{{returnCorrectNumbering(6)}}. {{ $t('reservations.restrict_meetings') }} ({{getTypeChangedQueues(this.proRid).length}})</span>
            </v-flex>
        </v-layout>

        <v-layout row class="no_margin">
            <v-flex v-if="this.getTypeChangedQueues(this.proRid).length !== 0">
                <v-data-table
                        :headers="headers"
                        :items="setItemsToTable"
                        class="elevation-1"
                        :calculate-widths="true"
                        :items-per-page=10
                        :sort-by="['ticketNumber']"
                        :sort-desc="[false]"
                >
                </v-data-table>
            </v-flex>

            <v-flex v-else>
                <span>{{ $t('reservations.no_restrict_meetings') }}</span>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import moment from 'moment';
    import {mapGetters, mapActions} from 'vuex';
    import Loading from "../../../../../components/Loading";

    export default {
        components: {Loading},

        name: "RestrictionDetail",

        props: {
            assistantId: null,
        },

        data() {
            let Rid = this.$route.params.Rid;

            return {
                proRid: Rid,

                czechMonths: {
                    'January': this.$t('months.January'), 'February': this.$t('months.February'),
                    'March': this.$t('months.March'), 'April': this.$t('months.April'),
                    'May': this.$t('months.May'), 'June': this.$t('months.June'),
                    'July': this.$t('months.July'), 'August': this.$t('months.August'),
                    'September': this.$t('months.September'), 'October': this.$t('months.October'),
                    'November': this.$t('months.November'), 'December': this.$t('months.December')
                },

                czechDays: {
                    'Monday': this.$t('days.Monday'), 'Tuesday': this.$t('days.Tuesday'), 'Wednesday': this.$t('days.Wednesday'), 'Thursday': this.$t('days.Thursday'),
                    'Friday': this.$t('days.Friday'), 'Saturday': this.$t('days.Saturday'), 'Sunday': this.$t('days.Sunday')
                },

                headers: [
                    {text: this.$t('headers.ticket_id'), value: 'ticketNumber', sortable: false, width: "8%"},
                    {text: this.$t('headers.request'), value: 'studyProblems', sortable: false, width: "5%"},
                    {text: this.$t('headers.date'), value: 'wantedDay', sortable: false},
                    {text: this.$t('headers.time'), value: 'time', sortable: false},
                    {text: this.$t('headers.student'), value: 'studentName', sortable: false, width: "15%"},
                    {text: this.$t('headers.options'), value: 'option', sortable: false, width: "13%"},
                    {text: this.$t('headers.delegate'), value: 'delegate', sortable: false, width: "23%"},
                    {text: this.$t('headers.sub_time'), value: 'otherTime', sortable: false, width: "10%"},
                ],

                dataToDataTable: [],

                today: moment().toISOString(),

                options: {
                    'cancel': this.$t('reservations.cancel'),
                    'delegate': this.$t('reservations.delegate')
                },
            }
        },

        async created() {
            this.proRid = this.$route.params.Rid;

            await Promise.all([
                this.fetchAssistants(),
                this.fetchStudyProblemsItems(),
                this.fetchClosedHours(),
                this.fetchStudyOpenHours(),
                this.fetchClosedHoursReasons(),
            ])

            const closedHour = this.getClosedHour(this.proRid);

            await Promise.all([
                this.fetchQueueItemStatusesByWantedDay(closedHour.date),
                this.fetchQueueItemsByWantedDay(closedHour.date),
                this.fetchChangedQueuesByWantedDay(closedHour.date),
            ])
        },

        computed: {
            ...mapGetters('assistant', ['didLoadedAssistants', 'getAssistant']),
            ...mapGetters('changedQueues', ['didLoadedChangedQueues', 'getTypeChangedQueues' ]),
            ...mapGetters('queueItemStatuses', ['didLoadedQueueItemStatuses', 'getQueueItemStatus']),
            ...mapGetters('closedHours', ['didLoadedClosedHours', 'getTypeClosedHours', 'getClosedHour']),
            ...mapGetters('queueItems', ['getQueueItemByQueueItemStatusId', 'didLoadedQueueItems']),
            ...mapGetters('closedHoursReason', ['getClosedHoursReason', 'didLoadedClosedHoursReasons']),
            ...mapGetters('StudyOpenHours', ['didLoadedStudyOpenHours', 'getStudyOpenHours']),
            ...mapGetters('studyProblemsItems', ['didLoadedStudyProblemsItems', 'getStudyProblemsItem']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),
            ...mapGetters('studyFields', [ 'getStudyField']),
            
            validateUserRoles() {
                return this.getAssistantStorage.role === 'STUDY_SPY';
            },

            setItemsToTable() {
                let changedQueues = this.getTypeChangedQueues(this.proRid);
                let dataToDataTable = [];

                for (let item of changedQueues) {
                    let dataPrototype = {
                        ticketNumber: null,
                        studyProblems: null,
                        wantedDay: null,
                        time: null,
                        studentName: null,
                        option: null,
                        delegate: null,
                        otherTime: null,
                        id: Math.floor((Math.random() * 100000)),
                    };

                    let queueStatus = this.getQueueItemStatus(item.queueItemStatusId);
                    let queueItem = this.getQueueItemByQueueItemStatusId(item.queueItemStatusId);

                    let studyProblems = "";

                    if (queueItem.studyProblemsItemsIds.length !== 0) {
                        for (let problem of queueItem.studyProblemsItemsIds) {
                            studyProblems += this.getStudyProblemsItem(problem).name + ' ';
                        }
                    } else {
                        studyProblems = this.getStudyField(queueItem.studyFieldId).name;
                    }

                    if (studyProblems.length > 90) {
                        studyProblems = studyProblems.slice(0, 87) + "..."
                    }

                    dataPrototype.ticketNumber = queueStatus.ticketNumber;
                    dataPrototype.studyProblems = studyProblems;
                    dataPrototype.wantedDay = this.formatDate(queueStatus.wantedDay);
                    dataPrototype.time = item.originalTimeFrom + ' - ' + item.originalTimeTo;
                    dataPrototype.studentName = this.returnName(queueItem);

                    dataPrototype.option = this.options[item.option];

                    if (item.option === "cancel") {
                        dataPrototype.delegate = "- - - - - - - - - -"
                    } else {
                        dataPrototype.delegate = this.getAssistant(queueItem.assistantId).name;
                    }

                    if (item.option === "cancel") {
                        dataPrototype.otherTime = "- - - - - - - - - -"
                    } else {
                        dataPrototype.otherTime = queueStatus.actualMeetTime + ' - ' + queueStatus.actualMeetEnd;
                    }


                    dataToDataTable.push(dataPrototype);
                }

                return dataToDataTable;
            },

            didLoadedAll() {
                return this.didLoadedAssistants && this.didLoadedStudyProblemsItems && this.didLoadedQueueItemStatuses &&
                    this.didLoadedClosedHours && this.didLoadedChangedQueues && this.didLoadedClosedHoursReasons && this.didLoadedQueueItems;
            },

            validateEditButton() {
                return this.getClosingHoursForToday() || this.validateUserRoles || this.validateEditButtonBasedOnAgendaAndRole() || this.validateEditButtonBasedOnTimeEnd();
            },
        },

        methods: {
            ...mapActions('assistant', ['fetchAssistants']),
            ...mapActions('changedQueues', ['fetchChangedQueuesByWantedDay']),
            ...mapActions('queueItemStatuses', ['fetchQueueItemStatusesByWantedDay']),
            ...mapActions('queueItems', ['fetchQueueItemsByWantedDay']),
            ...mapActions('closedHoursReason', ['fetchClosedHoursReasons']),
            ...mapActions('StudyOpenHours', ['fetchStudyOpenHours']),
            ...mapActions('studyProblemsItems', ['fetchStudyProblemsItems']),
            ...mapActions('closedHours', ['fetchClosedHours']),

            returnName: function (queueItem) {
                if (queueItem.xname === null && queueItem.nameSurnameTitle != null) {
                    return queueItem.nameSurnameTitle;
                } else if (queueItem.nameSurnameTitle === null && queueItem.xname != null) {
                    return '(' + queueItem.xname + ')';
                } else if (queueItem.nameSurnameTitle === "Empty" && queueItem.xname === "Empty") {
                    return this.$t('reservations.anon_student')
                } else {
                    return queueItem.nameSurnameTitle + ' (' + queueItem.xname + ')';
                }
            },

            getClosingHoursForToday() {
                let closedHours = this.getStudyOpenHours;
                let today = new Date(this.today);

                let closedHoursForToday = closedHours.find(closedHours => closedHours.dayNumber === (today.getDay()).toString() && closedHours.purpose === "mobile").closeTime;

                if (moment(this.getClosedHour(this.proRid).date).format("YYYYMMDD") === moment().format("YYYYMMDD")) {
                    return closedHoursForToday < moment().format("HH:mm");
                } else {
                    return false;
                }
            },

            goBack(assistantId) {
                this.$router.push({name: "CalendarDetail", params: {assistantID: assistantId}});
            },

            validateEditButtonBasedOnAgendaAndRole() {
                return this.getAssistantStorage.role === 'STUDY' && this.getClosedHoursReason(this.getClosedHour(this.proRid).closedHoursReasonId).type !== 'person';
            },

            validateEditButtonBasedOnTimeEnd() {
                return moment(this.getClosedHour(this.proRid).date + ' ' + this.getClosedHour(this.proRid).timeTo, "YYYY-MM-DD HH:mm").isBefore(moment(moment(), "YYYY-MM-DD HH:mm"))
            },

            formatDate(date) {
                if (!date) return null;

                const [year, month, day] = date.split('-');
                return `${day}.${month}.${year}`;
            },

            returnWantedFormatDate: function (wantedDate) {
                let date = moment(wantedDate);

                return date.format("DD") + '. '
                    + date.format("MMMM") + ' ' +
                    date.format("YYYY") + ', ' + date.format("dddd")
            },

            returnWantedFormatTime: function () {
                let closedHour = this.getClosedHour(this.proRid);

                return closedHour.timeFrom + ' - ' + closedHour.timeTo;
            },

            returnWantedAgenda: function () {
                let closedHour = this.getClosedHour(this.proRid);

                if (this.getClosedHoursReason(this.getClosedHour(this.proRid).closedHoursReasonId).type === 'department') {
                    return this.$t('reservations.study_department');
                }

                if (this.getClosedHoursReason(this.getClosedHour(this.proRid).closedHoursReasonId).type === 'person') {
                    return this.getAssistant(closedHour.assistantId).name;
                }
            },

            returnReason: function () {
                return this.getClosedHoursReason(this.getClosedHour(this.proRid).closedHoursReasonId).reason;
            },

            returnCorrectHeight() {
                if (this.getClosedHour(this.proRid).longTermOption === "cancel") {
                    return "250px";
                } else {
                    return "300px";
                }
            },

            returnCorrectNumbering(num) {
                if (this.getClosedHour(this.proRid).longTermOption === "cancel") {
                    return num;
                } else {
                    return num + 1;
                }
            },

            returnLongtermAction() {
                if (this.getClosedHour(this.proRid).longTermOption === "cancel") {
                    return this.$t('reservations.cancel');
                } else {
                    return this.$t('reservations.delegate');
                }
            },

            returnLongtermAssistant() {
                if (this.getClosedHour(this.proRid).longTermAssistantId !== null) {
                    return this.getAssistant(this.getClosedHour(this.proRid).longTermAssistantId).name;
                }
            },

            goToDetailEdit(assistantId) {
                this.$router.push({name: 'RestrictionDetailEdit', params: {Rid: this.proRid, assistantId}});
            },
        },
    }
</script>

<style scoped>
    .no_margin {
        margin: 0;
        padding-bottom: 1rem;
    }

    .no_margin_left_added {
        margin: 0 0 0 1rem;
        padding-bottom: 1rem;
    }

    .no_margin_no_padding {
        margin: 0;
        padding-bottom: 0;
    }

    .button {
        width: 7rem;
    }

    .close_button {
        background-color: #e6e6e6 !important;
        border-radius: 20px;
    }

    .button_group {
        display: flex;
        justify-content: center;
        width: fit-content;
    }

    .margin_left {
        margin-left: 0.7rem;
    }

    .float_left {
        float: left;
        margin: 0;
        padding: 0;
    }

    .header_div {
        width: fit-content;
    }

    .text {
        font-size: large;
    }
</style>
