<template>
    <v-container>
        <v-row v-if="['displays', 'displaysUnits', 'displaysAdministrators'].includes(route) && isAdmin">
            <v-col>
                <v-tabs icons-and-text color="light-green" class="top-menubar">
                    <v-tab :to="{name: 'displaysUnits'}" data-cy=units-tab>
                        <span v-if="isNotMobile" class="mt-2">Fakulty a součásti</span>
                        <v-icon >meeting_room</v-icon>
                    </v-tab>
                    <v-tab :to="{name: 'displaysAdministrators'}" data-cy=access-tab>
                        <span v-if="isNotMobile" class="mt-2">Uživatelé a oprávnění</span>
                        <v-icon>account_box</v-icon>
                    </v-tab>
                </v-tabs>
                <v-divider/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <router-view/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "DisplaysEntry",

    computed: {
        isNotMobile() {
            return !this.$vuetify.breakpoint.xs;
        },

        route() {
            return this.$route.name;
        },

        isAdmin() {
            return this.$auth.getRoles().includes('ADMIN');
        },
    }
}
</script>

<style scoped>
    .top-menubar {
        box-shadow: 2px solid #c4c4c4;
    }
</style>
