<template>
    <div v-if="computersList.length !== 0">
        <v-row class="align-center justify-sm-center justify-md-start" no-gutters>
            <v-col cols="auto" class="pr-2">
                <v-icon class="pr-0">search</v-icon>
            </v-col>
            <v-col class="pl-0">
                <Autocomplete
                    :label="computersLabel()"
                    :items="filteredComputers.computerNames"
                    :value="computerSearch"
                    hide-details=true
                    :clearable=true
                    @change="setComputerSearch"
                />
            </v-col>
        </v-row>
        <v-row class="pl-2 mt-4" >
            <v-col class="computer-wrapper pa-0">
                <v-list>
                    <v-list-item
                        v-for="computerItem in filteredComputers.items" :key="computerItem.id"
                    >
                        <v-lazy width="100%">
                            <v-card class="mb-10">
                                <v-row class="align-center ml-2">
                                    <v-col cols="auto" class="py-0">
                                        <v-icon outlined color="primary">
                                            desktop_windows
                                        </v-icon>
                                    </v-col>
                                    <v-col class="py-0">
                                        <v-list-item-content>
                                            <v-row class="align-center">
                                                <v-col>
                                                    <v-card-title v-text="computerItem.name" style="word-break: break-word;" class="pl-0 py-1" />
                                                </v-col>
                                                <v-col class="circle" :class="computerItem.isOccupied || computerItem.isOccupied === false ? 'red' : 'green'" cols="auto" />
                                                <v-col cols="auto">
                                                    <v-card-text style="word-break: break-word;" class="pl-0 py-1"  >{{computerItem.isOccupied || computerItem.isOccupied === false ? $t('iotSensors.pc_occupied') : $t('iotSensors.pc_not_occupied')}}</v-card-text>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-lazy>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
        <v-row v-if="filteredComputers.items.length === 0 && computersList.length !== 0">
            <v-row>
                <v-col>
                    <Placeholder />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p>{{$t('iotSensors.no_match_for_computer')}}</p>
                </v-col>
            </v-row>
        </v-row>
    </div>
    <div v-else>
        <v-row>
            <v-col class="justify-center" justify="center">
                <NoData />
            </v-col>
        </v-row>
    </div>

</template>
<script>
import Autocomplete from "@/components/Autocomplete";
import Placeholder from "@/assets/placeholder.svg";
import NoData from "@/components/NoData";
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'IotComputersComponent',

    components: {
        Autocomplete,
        Placeholder,
        NoData
    },

    data() {
        return {
            computerSearch: null,
            computersList: []
        }
    },

    async created() {
        await this.fetchComputersById(this.$route.params.id)
        this.computersList = this.placeComputers

    },

    computed: {
        ...mapGetters('Computers', ['placeComputers']),

        filteredComputers() {
            let computers = {}
            computers.items = this.computersList

            if (this.computerSearch) {
                computers.items = computers.items.filter(computer => computer.name === this.computerSearch)
            }

            // creates names that are used inside autocomplete and select
            computers.computerNames = []
            computers.items.map(filteredItem => {
                computers.computerNames.push(filteredItem.name)
            })
            return computers
        }
    },


    methods: {
        ...mapActions('Computers', ['fetchComputersById']),

        setComputerSearch(value) {
            this.computerSearch = value
        },

        computersLabel() {
            return this.$t('iotSensors.search_pc')
        },

    }
}
</script>
<style>
.computer-wrapper {
    max-height: 55vh;
    overflow-y: auto;
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.green {
    background-color: #78BE13;
}

.red {
    background-color: #E02020;
}

</style>