import store from '@/store/Store';

export default class LeavePrevent {

    static init() {
        window.addEventListener('beforeunload',
            e => LeavePrevent.onBeforeUnload(e));
    }

    static onBeforeUnload(event) {
        if (store.getters['leavePrevent/hasUnsavedChanges']) {
            event.preventDefault();
            event.returnValue = 'You have unsaved changes!';
        }
    }

    static confirmRouteChange(next) {
        if (store.getters['leavePrevent/hasUnsavedChanges']) {
            // on leave page with unsaved changes pass a redirect function to a confirm modal
            store.dispatch('leavePrevent/didLeavePage', next);
        } else {
            // otherwise redirect
            next();
        }
    }

}
