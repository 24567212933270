import * as Keycloak from "keycloak-js";
import {config} from '@/config';
import store from '@/store/Store';

export default class Auth {

    constructor(store) {
        this.store = store;
        this.LOCAL_STORAGE_TOKEN_KEY = "auth_token";
        this.isKeycloakInitialized = false;
        this.onInit = () => {};

        this.keycloak = Keycloak({
            url: config.authServer.url,
            realm: config.authServer.realm,
            clientId: config.authServer.clientId,
            publicClient: true,
        });

        this.keycloak.init({
            checkLoginIframe: false
        })
            .then(() => {
                this.isKeycloakInitialized = true;
                this.onInit();
                store.commit('user/stopLoading');
            });

        this.keycloak.onAuthSuccess = () => {
            localStorage.setItem(this.LOCAL_STORAGE_TOKEN_KEY, this.keycloak.token);
            store.commit('user/logIn');
            store.commit('user/setUsername', this.keycloak.tokenParsed.preferred_username);
        };

        this.keycloak.onAuthLogout = () => {
            store.commit('user/logOut');
        }
    }

    isLoggedIn() {
        return this.keycloak.authenticated;
    }

    checkLogin(callback) {
        if (this.isKeycloakInitialized) {
            callback(this.isLoggedIn());
        } else {
            this.onInit = () => callback(this.isLoggedIn());
        }
    }

    login(redirectUri) {
        console.log('keycloak:login');
        const urlWithoutHash = redirectUri.replace(/#.*/, ''); // keycloak does not work well with a hash in the redirect URL, leading to Error 502
        this.keycloak.login({redirectUri: window.location.origin + urlWithoutHash});
    }

    getToken() {
        return localStorage.getItem(this.LOCAL_STORAGE_TOKEN_KEY);
    }

    renewToken() {
      return new Promise((resolve, reject) => {
        this.keycloak.updateToken(-1).then(() => {
            localStorage.setItem(this.LOCAL_STORAGE_TOKEN_KEY, this.keycloak.token);
            resolve({succ: true, msg: 'Token successfully updated.'});
        }).catch(() => {
            reject({succ: false,  msg: 'Token wasn\'t successfully updated.'});
        });
      });

    }

    // User manually logged out
    async manuallyLogout() {
        // Unsubscribe from notifications, but save in localStorage their actually state (turned on/off).
        await store.dispatch('firebase/unsubscribeNotifications', false);
        this.logout();
    }

    logout() {
        console.log('keycloak:logout');
        localStorage.removeItem(this.LOCAL_STORAGE_TOKEN_KEY);
        this.keycloak.logout({redirectUri: window.location.origin + '/logged-out'});
    }

    getRoles() {
        return this.keycloak.realmAccess?.roles ?? [];
    }

    /**
     * Checks whether the current user has access to the given route.
     * Uses `allowedRoles` set in router.js.
     * Checks also all parents of the given route.
     * @param {Route} targetRoute
     * @returns Boolean True if the route can be accessed, false otherwise.
     */
    canAccessRoute(targetRoute) {
        const userRoles = this.getRoles();
        const routes = targetRoute.matched; // the matched array includes the current route and all its parents
        
        // check that all matched routes have at least one role equal to one of the user's roles
        return routes.every(route => {
            if (route.meta.allowedRoles === undefined) return route
            return route.meta.allowedRoles?.some(role =>
                role === '*' || userRoles.includes(role)
            )
        });
    }

    getStudyAssistantAndRole() {

        if(this.keycloak.hasRealmRole("STUDY_ADMIN")) {
            return {
                role: "STUDY_ADMIN",
                xlogin: store.getters['user/username'],
            }
        }

        if(this.keycloak.hasRealmRole("STUDY_SPY")) {
            return {
                role: "STUDY_SPY",
                xlogin: store.getters['user/username'],
            }
        }

        if(this.keycloak.hasRealmRole("STUDY")) {
            return {
                role: "STUDY",
                xlogin: store.getters['user/username'],
            }
        }

        return null;
    }

}

// Inject Auth instance as a Vue plugin
Auth.install = (Vue, options) => {
    const auth = new Auth(options.store);
    Vue.$auth = auth;
    Vue.prototype.$auth = auth;
};
