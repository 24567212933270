import {get, put} from "@/services/api"
import Vue from "vue";

const state = {
    activities: [],
    didLoadActivities: false,
    didLoadActivity: false,
};

const getters = {
    getActivities: state => state.activities,
    getActivityById: state => id => state.activities.find(t => t.id === id),
    didLoadActivities: state => state.didLoadActivities,
};

const actions = {
    async fetchAllActivities({commit}) {
        commit('startedDataDownload');
        const response = await get('gamification/activities/');
        commit('setActivities', response.data.items);
        commit('finishedDataDownload');
    },

    async updateActivity({commit}, {item}) {
        const response = await put(`gamification/activities/${item.id}`, item);
        commit('updateActivity', response.data.content);
    },

};

const mutations = {
    startedDataDownload: state => state.didLoadActivities = false,
    finishedDataDownload: state => state.didLoadActivities = true,
    setDidLoadActivity: (state, value) => (state.didLoadActivity = value),
    setActivities: (state, activities) => (state.activities = activities), 
    updateActivity: (state, item) => {
        const existing = state.activities.findIndex(q => q.id === item.id);
        if (existing > -1) {
            Vue.set(state.activities, existing, item);
        } else {
            state.activities.push(item);
        }
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
