<template>
    <v-dialog
      v-model="model"
      width="400"
      :persistent="persistent"
      @click:outside="persistent || onConfirm()"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>

        <v-card-text id="dialog-text">
          <slot/>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="onConfirm && onConfirm()"
          >
            {{ buttonLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

    export default {
        name: 'Alert',

        props: {
            model: {
                type: Boolean,
                required: true
            },
            persistent: {
                type: Boolean,
                default: false
            },
            onConfirm: {
                type: Function,
                required: true
            },
            title: {
                type: String,
                default: 'Operace se nezdařila'
            },
            buttonLabel: {
                type: String,
                default: "OK"
            }
        },
    }
</script>

<style scoped>
  #dialog-text {
      padding-bottom: 9px;
  }
</style>