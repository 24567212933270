const SnackbarType = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
}

const state = {
    isShown: false,
    type: null,
    text: null,
};

const getters = {
    isShown: state => state.isShown,
    text: state => state.text,
    type: state => state.type,
};

const actions = {
    showSuccessSnackbar({ commit }, text) {
        commit('show', { type: SnackbarType.SUCCESS, text });
    },
    showInfoSnackbar({ commit }, text) {
        commit('show', { type: SnackbarType.INFO, text });
    },
    showErrorSnackbar({ commit }, text) {
        commit('show', { type: SnackbarType.ERROR, text });
    },
    hideSnackbar({ commit }) {
        commit('hide');
    },
};

const mutations = {
  show: (state, { type, text }) => {
        state.isShown = true;
        state.type = type;
        state.text = text;
  },
  hide: (state) => { state.isShown = false; }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
