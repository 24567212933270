import axios from "axios";
import {config} from "@/config";

export async function makeRequest(method, uri, data, headers) {
    return await axios.create({
        validateStatus: () => true // Prevent axios from throwing Promise exception, so it can be handled in operation.checkResponse().
    }).request({
        method: method,
        url: config.serverUrl + uri,
        data: data,
        headers: headers
    });
}