import axios from 'axios';

const state = {
    controlTypes: [],
    controlDetail: null,
    modelsControls: []
};
const getters = {
    getControls: state => state.controlTypes,
    getControl: state => state.controlDetail,
    getControlsOfModel: state => state.modelsControls
};
const actions = {
    //Fetch ALL
    async fetchControls({commit}) {
        const response = await axios
            .get('https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/control');
        commit('setControls', response.data.controlTypes);
    },

    //Fetch ONE
    async fetchControl({commit}, id) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/control/${id}`);
        commit('setControl', response.data);
    },

    //Add ONE
    async createControl({ commit },  control) {
        return new Promise((resolve) => {
            axios
                .post(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/control`, control).then((newControl) => {
                commit('addControl', newControl.data);
                if(newControl.status == 201) {
                    resolve();
                }
            });
        })
    },

    //Update ONE
    async updateControl({commit}, {id, control }) {
        commit('updateControl', id, control);
        await axios
            .put(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/control/${id}`, control);
    },

    //Delete ONE
    async deleteControl({commit}, id) {
        commit('filterControl', id);
        await axios
            .delete(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/control/${id}`);
    },

    async fetchControlsOfModel({commit}, modelId, rentId) {
        if (rentId !== null) {
            const response = await axios
                .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model/${modelId}/control-types`);
            commit('setControlsOfModel', response.data.controlTypes);
        } else {
            const response = await axios
                .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model/${modelId}/control-types?rentId=${rentId}`);
            commit('setControlsOfModel', response.data.controlTypes);
        }
    }
};

const mutations = {
    setControls: (state, control) => (state.controlTypes = control),
    setControl: (state, control) => (state.controlDetail = control),
    addControl: (state, control) => (state.controlTypes.push(control)),
    updateControl: (state, id, control) => {
        let index = state.controlTypes.filter(control => control.id === id);
        state.controlTypes[index] = control
    },
    filterControl: (state, id) => (state.controlTypes = state.controlTypes.filter(control => control.id !== id)),
    setControlsOfModel: (state, controls) => (state.modelsControls = controls)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}