<template>
    <v-dialog
      v-model="model"
      width="500"
      :persistent="persistent"
      @keydown.enter="confirm()"
      @keydown.esc="persistent || cancel()"
      @click:outside="persistent || cancel()"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>

        <v-card-text id="dialog-text">
          <slot/>
        </v-card-text>

        <v-card-actions v-if="okOnly">
          <v-spacer></v-spacer>
          <v-btn
            data-cy="dialog-ok-btn"
            :color="okColor"
            text
            @click="confirm()"
          >
            OK
          </v-btn>
        </v-card-actions>

        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn
            data-cy="dialog-yes-btn"
            :color="yesColor"
            text
            @click="confirm()"
          >
            {{ yes }}
          </v-btn>
          <v-btn
            :color="noColor"
            text
            @click="cancel()"
          >
            {{ no }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

    export default {
        name: 'ConfirmDialog',

        props: {
            model: {
                type: Boolean
            },
            persistent: {
                type: Boolean
            },
            okOnly: {
                type: Boolean
            },
            onConfirm: {
                type: Function
            },
            onCancel: {
                type: Function
            },
            title: {
                type: String,
                default: 'Opravdu smazat?'
            },
            yes: {
                type: String,
                default: "Ano"
            },
            no: {
                type: String,
                default: "Ne"
            },
            /**
             * This prop is used to set color for ok button
             */
            okColor: {
                type: String,
                default: "primary"
            },
            /**
             * This prop is used to set color for yes button
             */
            yesColor: {
                type: String,
                default: "primary"
            },
            /**
             * This prop is used to set color for no button
             */
            noColor: {
                type: String,
                default: "secondary"
            },
        },

        methods: {
          confirm() {
            this.$emit('yes');
            if (this.onConfirm) {
              this.onConfirm();
            }
          },
          cancel() {
            this.$emit('no');
            if (this.onCancel) {
              this.onCancel();
            }
          },
        }
    }
</script>

<style scoped>
  #dialog-text {
      padding-bottom: 9px;
  }
</style>