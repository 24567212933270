import {get, post, del, put} from '@/services/api'
import Vue from "vue";
import {localizedAttributesFactory} from "../../../localization/languages";
import {sortSanitized} from "../../../utils";

const state = {
    didLoadCategories: false,
    categories: [],
    newCategory: null,
};

const getters = {
    didLoadCategories: state => state.didLoadCategories,
    categories: state => state.categories,
    sortedCategories: state => lang => sortSanitized(state.categories, "name", lang),
    categoryById: state => id => state.categories.find(c => c.id === id),
    prepareNewCategory: () => () => ({
        iconName: null,
        notificationEnabledByDefault: false,
        localizedAttributes: localizedAttributesFactory({
            name: null
        })
    }),
};

const actions = {
    async fetchCategories({commit}) {
        commit('startedDataDownload');
        const response = await get('faq/categories/');
        commit('setCategories', response.data.items);
        commit('finishedDataDownload');
    },

    async createCategory({commit}, item) {
        const response = await post('faq/categories/', item);
        commit('addCategory', response.data.content);
    },

    async updateCategory({commit}, item) {
        const response = await put(`faq/categories/${item.id}`, item);
        commit('updateCategory', response.data.content);
    },

    async deleteCategory({commit}, id) {
        await del(`faq/categories/${id}`);
        commit('filterCategory', id)
    },
};

const mutations = {
    startedDataDownload: state => state.didLoadCategories = false,
    finishedDataDownload: state => state.didLoadCategories = true,
    setCategories: (state, categories) => (state.categories = categories),
    filterCategory: (state, id) => (state.categories = state.categories.filter(categories => categories.id !== id)),
    addCategory: (state, item) => (state.categories.push(item)),
    updateCategory: (state, item) => {
        let index = state.categories.findIndex(category => category.id === item.id);
        Vue.set(state.categories, index, item);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
