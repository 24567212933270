<template>
    <Loading v-if="isLoadingNotificationSettings" :small="true" />

    <div v-else>
        <div v-if="!areNotificationsSupported" class="secondary--text text-body-2 d-flex">
            <v-icon class="mr-2">notifications_off</v-icon>
            <div>Notifikace nejsou v tomto prohlížeči podporovány.</div>
        </div>

        <div v-else-if="!areNotificationsEnabled" class="secondary--text text-body-2 d-flex">
            <v-icon class="mr-2">notifications_off</v-icon>
            <div>Upozornění {{ label }} v prohlížeči máte vypnuté. <a @click="subscribeNotifications()">Zapnout</a>
            </div>
        </div>

        <div v-else class="secondary--text text-body-2 d-flex mt-5">
            <v-icon class="mr-2">notifications</v-icon>
            <div>Upozornění {{ label }} v prohlížeči máte zapnuté. <a
              @click="unsubscribeNotifications(true)">Vypnout</a></div>
        </div>

        <!--        Email notifications         -->
        <div v-if="!this.notificationsEnabled" class="secondary--text text-body-2 d-flex mt-5">
            <v-icon class="mr-2">subtitles_off</v-icon>
            <div>Upozornění {{ label }} e-mailem máte vypnuté.
                <a @click="subscribeEmailNotificationsForAssistant(uisLogin)">Zapnout</a>
            </div>
        </div>

        <div v-else class="secondary--text text-body-2 d-flex mt-5">
            <v-icon class="mr-2">email</v-icon>
            <div>Upozornění {{ label }} e-mailem máte zapnuté.
                <a @click="unsubscribeAssistantFromEmailNotifications(uisLogin)">Vypnout</a>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Loading from '@/components/Loading'

    export default {
        name: "NotificationSetting",

        data() {
            return {
                uisLogin: null,
            }
        },

        props: {
            /**
             * Displayed as part of the switch text.
             * E.g. the label `na nové rezervace` will be injected and the final text will be `Upozornění na nové rezervace máte vypnuto.`.
             */
            label: {
                type: String,
                default: "",
            },
        },

        components: {
            Loading,
        },

        mounted() {
            this.initNotifications();
            this.uisLogin = this.$auth.getStudyAssistantAndRole()?.xlogin;
        },

        computed: {
            ...mapGetters('firebase', ['areNotificationsSupported', 'isLoadingNotificationSettings', 'areNotificationsEnabled']),
            ...mapGetters('pageStorage', ['getEmailNotifications']),

            notificationsEnabled() {
                return this.getEmailNotifications;
            }
        },

        methods: {
            ...mapActions('firebase', ['initNotifications', 'subscribeNotifications', 'unsubscribeNotifications']),
            ...mapActions('pageStorage', ['subscribeEmailNotificationsForAssistant', 'unsubscribeAssistantFromEmailNotifications']),
        },
    }
</script>

<style scoped>
</style>
