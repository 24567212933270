import {get, put} from '@/services/api'
import moment from 'moment';

const state = {
    selectedDay: null,

    didLoadAssistant: false,
    assistantId: null,
    assistant: null,
    emailNotifications: null,

    callInItemIdToShow: null,
    callInCurrentComponent: "CallInStart",

    vtabsActiveCompoment: 'CalendarDetail',

    calendarInfobarPanel: [],

    selectedMonth: moment().locale('cs'),
};

const getters = {
    getSelectedDayStorage: state => state.selectedDay,
    didLoadAssistant: state => state.didLoadAssistant,
    getAssistantIdStorage: state => state.assistantId,
    getAssistantStorage: state => state.assistant,
    assistantName: state => state.assistant?.name,
    getEmailNotifications: state => state.emailNotifications,
    getCallInItemIdToShow: state => state.callInItemIdToShow,
    getCallInCurrentComponent: state => state.callInCurrentComponent,
    getVtabsActiveCompoment: state => state.vtabsActiveCompoment,
    calendarInfobarPanelStorage: state => state.calendarInfobarPanel,
    selectedMonth: state => state.selectedMonth,
};

const actions = {

    setCalendarInfobarPanelStorage({commit}, value) {
        commit('setCalendarInfobarPanelStorage', value);
    },

    setSelectedDayStorage({commit}, value) {
        commit('setSelectedDay', value);
    },

    async fetchAssistantByXlogin({commit}, xlogin) {
        commit('startAssistantDownload');

        try {
            const response = await get('study_department/assistant/xlogin/' + xlogin);
            commit('setAssistantId', response.data.content.id);
            commit('setAssistant', response.data.content);
            commit('setEmailNotificationPreferences', response.data.content.wantsEmailNotifications);
        } catch (e) {
            if (e.statusCode === 404) {
                commit('setAssistantId', null);
                commit('setAssistant', null);
            } else {
                throw e; // rethrow other errors to be handled by the global error handler (services/api/error-handler.js)
            }
        }

        commit('finishAssistantDownload');
    },

    async subscribeEmailNotificationsForAssistant({commit}, xlogin) {
        await put('study_department/assistant/xlogin/' + xlogin + '/emails/' + true)
            .then(() => {
                commit('setEmailNotificationPreferences', true);
            });
    },

    async unsubscribeAssistantFromEmailNotifications({commit}, xlogin) {
        await put('study_department/assistant/xlogin/' + xlogin + '/emails/' + false)
            .then(() => {
                commit('setEmailNotificationPreferences', false);
            });
    },

    setCallInItemIdToShowStorage({commit}, value) {
        commit('setCallInItemIdToShow', value);
    },

    setCallInCurrentComponentStorage({commit}, value) {
        commit('setCallInCurrentComponent', value);
    },

    setVtabsActiveCompomentStorage({commit}, value) {
        commit('setVtabsActiveCompomentStorage', value);
    },

    setSelectedMonth({commit}, value) {
        commit('setSelectedMonth', value);
    },
};

const mutations = {
    setSelectedDay: (state, value) => (state.selectedDay = value),
    startAssistantDownload: state => state.didLoadAssistant = false,
    finishAssistantDownload: state => state.didLoadAssistant = true,
    setAssistantId: (state, value) => (state.assistantId = value),
    setAssistant: (state, value) => (state.assistant = value),
    setEmailNotificationPreferences: (state, value) => (state.emailNotifications = value),
    setCallInItemIdToShow: (state, value) => (state.callInItemIdToShow = value),
    setCallInCurrentComponent: (state, value) => (state.callInCurrentComponent = value),
    setVtabsActiveCompomentStorage: (state, value) => (state.vtabsActiveCompoment = value),
    setCalendarInfobarPanelStorage: (state, value) => (state.calendarInfobarPanel = value),
    setSelectedMonth: (state, value) => (state.selectedMonth = value),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
