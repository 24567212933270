export const announcementTypesHeaders = [
  {
    text: 'Symbol',
    align: 'center',
    sortable: false,
    value: 'iconName',
    width: "1%"
  },
  {
    text: 'Název',
    align: 'left',
    sortable: true,
    value: 'name',
    width: "20%"
  },
  {
    text: 'Přiřazené obrazovky',
    align: 'left',
    sortable: false,
    value: 'displaysNames',
    width: "45%"
  },
  {
    text: 'Generující služba',
    align: 'center',
    sortable: false,
    value: 'serviceName',
    width: "20%"
  },
  {
    text: 'Akce',
    align: 'center',
    sortable: false,
    value: 'actions',
    width: "14%"
  }
];
