export const sanitize = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
};

export const sortSanitized = (arr, key, lang = null) => {
    let copy = [...arr];
    let attr = i => lang === null ? i[key] : i.localizedAttributes[lang][key];
    copy.sort((a, b) => sanitize(attr(a)) < sanitize(attr(b)) ? -1 : 1);
    return copy;
};

export const removeHTML = (str) => {
    return str.replace(/(<([^>]+)>)/gi, "")
};

export const sortedCoordinates = (array) => {
    let copy = [...array];

    if (copy.length > 0) {
        if (copy[0].order !== null) {
            copy.sort((a, b) => a.order - b.order);
        } else {
            copy.sort((a, b) => a.id - b.id);
        }
    }

    return copy;
};

export const boundingBox = (coordinates) => {
    let lats = [], lngs = [];

    coordinates.forEach(coordinate => {
        lats.push(coordinate.lat);
        lngs.push(coordinate.lon);
    });

    // calc the min and max lng and lat
    const
        minlat = Math.min.apply(null, lats),
        maxlat = Math.max.apply(null, lats),
        minlng = Math.min.apply(null, lngs),
        maxlng = Math.max.apply(null, lngs);

    // create a bounding rectangle
    return [
        {lat: minlat, lon: minlng},
        {lat: maxlat, lon: maxlng}
    ];
};

export const centroid = (coordinates) => {
    const bbox = boundingBox(coordinates);
    return {
        lat: (bbox[0].lat + bbox[1].lat) / 2,
        lon: (bbox[0].lon + bbox[1].lon) / 2,
    }
};

export const calculatePlaceCentroid = (positions) => {
    const first = positions[0], last = positions[positions.length - 1];

    if (first.lat !== last.lat || first.lon !== last.lon)
        positions.push(first);

    else return {lat: first.lat, lon: first.lon}

    let twiceArea = 0,
        lat = 0, lon = 0,
        positionsLength = positions.length,
        p1, p2, f;
    let i = 0, j = positionsLength - 1;
    for (; i < positionsLength; j = i++) {
        p1 = positions[i];
        p2 = positions[j];
        f = p1.lat * p2.lon - p2.lat * p1.lon;
        twiceArea += f;
        lat += (p1.lat + p2.lat) * f;
        lon += (p1.lon + p2.lon) * f;
    }
    f = twiceArea * 3;
    return {lat: lat / f, lon: lon / f};
};

export const hasProperties = (obj, properties) => {
    return properties.every(p => p in obj);
}

export const shadeColor = (color, percent) => {
    let R = parseInt(color.substring(1,3), 16);
    let G = parseInt(color.substring(3,5), 16);
    let B = parseInt(color.substring(5,7), 16);

    R = parseInt(R * percent);
    G = parseInt(G * percent);
    B = parseInt(B * percent);

    R = R<255 ? R : 255;  
    G = G<255 ? G : 255;  
    B = B<255 ? B : 255;  

    var RR = R.toString(16).padStart(2, '0');
    var GG = G.toString(16).padStart(2, '0');
    var BB = B.toString(16).padStart(2, '0');

    return "#" + RR + GG + BB;
}