import { get,} from '@/services/api'

const state = {
    didLoadedStudyProblemsCategories: false,
    studyProblemsCategories: [
    ]
};
const getters = {
    didLoadedStudyProblemsCategories: state => state.didLoadedStudyProblemsCategories,

    getStudyProblemsCategories: state => state.studyProblemsCategories,

    getStudyProblemsCategory: state => id => {
        return state.studyProblemsCategories.find(studyProblemsCategory => studyProblemsCategory.id === id);
    },

    areStudyProblemsCategoryFilled: state => {
        return state.studyProblemsCategories.length !== 0;
    }
};
const actions = {

    //Fetch ALL
    async fetchStudyProblemsCategories({ commit }) {
        commit('setDidLoadedStudyProblemsCategories', false);
        await get('study_department/study_problems_category/')
            .then((response) => {
                commit('setStudyProblemsCategories', response.data.items);
                commit('setDidLoadedStudyProblemsCategories', true);
            });
    },

    setStudyProblemsCategoriesStorage({commit}, value) {
        commit('setStudyProblemsCategories', value);
    }
};

const mutations = {
    setDidLoadedStudyProblemsCategories: (state, value) => (state.didLoadedStudyProblemsCategories = value),
    setStudyProblemsCategories: ( state, studyProblemsCategories ) => (state.studyProblemsCategories = studyProblemsCategories),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
