<template>
<div>
  
    <v-snackbar v-if="isShown" :value="true" :color="color" timeout="-1" center id="snackbar">
        <v-icon color="white" left>{{ icon }}</v-icon>
        {{ text }}
        <template v-slot:action="{ attrs }">
            <v-btn icon @click="hideSnackbar" class="ml-4"><v-icon>cancel</v-icon></v-btn>
        </template>
    </v-snackbar>

    <v-snackbar v-else-if="!isSyncWorking" :value="true" timeout="-1" center>
      <v-icon color="red" left>wifi_off</v-icon>
      Bez připojení k serveru. Data nemusí být aktuální.
      <v-btn text color="yellow" @click="refreshPage()" class="ml-3">Obnovit</v-btn>
    </v-snackbar>

</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Snackbar",

    computed: {
        ...mapGetters('Snackbar', ['isShown', 'text', 'type']),
        ...mapGetters('ConnectionState', ['isSyncWorking']),

        color() {
            if (this.type === "error") {
                return "red";
            } else if (this.type === "success") {
                return "green";
            } else {
                return "";
            }
        },

        icon() {
            if (this.type === "error") {
                return "sentiment_dissatisfied";
            } else if (this.type === "success") {
                return "check_circle";
            } else {
                return "info";
            }
        }
    },

    methods: {
        ...mapActions('Snackbar', ['hideSnackbar']),

        refreshPage() {
            window.location.reload();
        },
    },
}
</script>

<style scoped>
</style>
