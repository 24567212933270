<template>
  <ItemNotFoundState v-if="objectNotFound" :objectName="'Maják'">
    <template #backButton>
      <BackButton :target="{ name: 'Beacons' }">Majáky</BackButton>
    </template>
  </ItemNotFoundState>
  <Loading v-else-if="!didLoadData"/>
  <v-container fluid v-else>
    <BackButton :target="{ name: 'Beacons' }">Majáky</BackButton>

    <ModuleHeader>

      <div class="text-h5 font-weight-bold" v-if="isNew">Přidání majáku</div>

      <div class="text-h5 font-weight-bold" v-else>Úprava majáku</div>

      <v-spacer/>

      <SaveButton
          :disabled="!isFormValid"
          :is-saving="isSaving"
          @click="save"
          label="Uložit"
      />

    </ModuleHeader>

    <v-form @submit.prevent="save" ref="form" v-model="isFormValid">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="subtitle-2">Základní informace</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
                label="UID"
                @change="changeWatch"
                :rules="[required_rule]"
                v-model="beacon.beaconId"
                :rows="1"
                auto-grow
                clear-icon="clear"
                :clearable="true"
                hint="Povinné"
                persistent-hint
                color="light-green darken-1"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-select
                :rules="[required_rule]"
                @change="changeWatch"
                v-model="beacon.purposeId"
                :items="beaconPurposesList"
                clear-icon="clear"
                :clearable="true"
                label="Účel"
                hint="Povinné"
                persistent-hint
                color="light-green darken-1"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-select
                v-model="beacon.state"
                @change="changeWatch"
                label="Stav"
                :items="statesList"
                :rules="[required_rule]"
                clear-icon="clear"
                :clearable="true"
                hint="Povinné"
                persistent-hint
                color="light-green darken-1"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="subtitle-2">Lokace</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4">
            <autocomplete
                :value="this.beacon.spot.buildingId"
                label="Budova"
                :items="buildingsList"
                @change="setBuildingId"
                @clear="clearBuilding"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <autocomplete
                :value="this.beacon.spot.floorId"
                label="Podlaží"
                :items="floorsList"
                :disabled="!hasBuilding"
                @change="setFloorId"
                @clear="clearFloor"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <autocomplete
                :value="this.beacon.spot.placeId"
                label="Místo"
                :items="sortedPlacesList"
                :disabled="!hasFloor"
                @change="setLocationByPlace"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
                :rules="[float_rule]"
                v-model.number="beacon.latitude"
                label="Zem. šířka"
                color="light-green darken-1"
                clear-icon="clear"
                :clearable="true"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
                :rules="[float_rule]"
                v-model.number="beacon.longitude"
                label="Zem. délka"
                color="light-green darken-1"
                clear-icon="clear"
                :clearable="true"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="subtitle-2">Datumy</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <DatePicker
                :rules="[required_rule]"
                @change="changeWatch"
                title="Datum umístění"
                custom-prepend-icon="plumbing"
                clear-icon="clear"
                :clearable="true"
                v-on:dateChanged="updatePlacementDate"
                :initialValue="formatDate(beacon.dateOfPlacement)"
                hint="Povinné"
                persistent-hint
            />
          </v-col>

          <v-col cols="12" sm="6">
            <DatePicker
                title="Datum poslední výměny baterie"
                custom-prepend-icon="battery_charging_full"
                @change="changeWatch"
                clear-icon="clear"
                :clearable="true"
                v-on:dateChanged="updateBatteryReplacementDate"
                :initialValue="formatDate(beacon.dateOfBatteryReplacement)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="subtitle-2">Protokoly</div>
          </v-col>
        </v-row>

        <v-row class="d-flex mt-n5">
          <v-col cols="12">
            <v-checkbox
                v-model="beacon.ibeaconSupport"
                label="Podpora iBeacon"
                color="light-green darken-1"
                @click="checkIbeaconSupport"
            />
          </v-col>
        </v-row>

        <v-row class="d-flex mt-n5" v-if="hasIbeaconSupport">
          <v-col cols="12" sm="8">
            <v-text-field
                :rules="[ibeaconRule]"
                v-model="beacon.ibeaconUid"
                label="iBeacon UID"
                color="light-green darken-1"
                clear-icon="clear"
                :clearable="true"
                hint="UID je povinné, když je protokol podporován"
                persistent-hint
            />
          </v-col>

          <v-col cols="12" sm="2">
            <v-text-field
                :rules="[integer_rule]"
                v-model.number="beacon.major"
                label="Major"
                :clearable="true"
                clear-icon="clear"
                color="light-green darken-1"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
                :rules="[integer_rule]"
                v-model.number="beacon.minor"
                label="Minor"
                :clearable="true"
                clear-icon="clear"
                color="light-green darken-1"
            />
          </v-col>
        </v-row>

        <v-row class="d-flex mt-n5">
          <v-col cols="12">
            <v-checkbox
                v-model="beacon.eddystoneSupport"
                label="Podpora Eddystone"
                color="light-green darken-1"
                @click="checkEddystoneSupport"
            />
          </v-col>
        </v-row>

        <v-row class="d-flex mt-n5" v-if="hasEddystoneSupport">
          <v-col cols="12" sm="8">
            <v-text-field
                :rules="[eddystoneRule]"
                v-model="beacon.eddystoneUid"
                label="Eddystone UID"
                color="light-green darken-1"
                clear-icon="clear"
                :clearable="true"
                hint="UID je povinné, když je protokol podporován"
                persistent-hint
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
                v-model.number="beacon.url"
                label="URL"
                :clearable="true"
                clear-icon="clear"
                color="light-green darken-1"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ModuleHeader from "../../components/layout/ModuleHeader";
import BackButton from "../../components/layout/BackButton";
import SaveButton from "../../components/buttons/SaveButton";
import DatePicker from "../../components/date-picker/DatePicker";
import {calculatePlaceCentroid} from "../../utils";
import moment from "moment";
import Loading from "../../components/Loading";
import Autocomplete from "../../components/Autocomplete";
import ItemNotFoundState from '../../components/states/ItemNotFoundState.vue';

export default {
  name: "BeaconDetail",

  components: {
    Autocomplete,
    Loading,
    SaveButton,
    BackButton,
    ModuleHeader,
    DatePicker,
    ItemNotFoundState,
  },

  async created() {
    await this.loadData()
  },

  watch: {
    beaconId() {
      this.loadData()
    },
    initialBeacon() {
      this.beacon = this.initialBeacon;
    },
    async floorId() {
      if (this.floorId !== null) {
        await this.fetchFilteredPlaces({
          floorId: this.beacon.spot.floorId,
          buildingId: null,
          exterior: null,
          language: null,
          placeName: null
        });
      }
    },
    async buildingId() {
      if (this.buildingId === null) {
        await this.fetchFilteredPlaces({
          floorId: null,
          buildingId: null,
          exterior: true,
          language: null,
          placeName: null
        });
      }
    }
  },

  data: () => {
    return {
      required_rule: (value) => !!value || "Povinné",
      integer_rule: (value) => !value || !!String(value).match(/^-?[0-9]+$/) || 'Musí být celé číslo',
      float_rule: (value) => !value || !!String(value).match(/^\d+(\.\d+)+$/) || 'Musí být desetinné číslo',
      isFormValid: false,
      isSaving: false,
      beacon: {
        beaconId: "",
        dateOfBatteryReplacement: null,
        dateOfLastUpdate: null,
        dateOfPlacement: null,
        eddystoneUid: "",
        ibeaconUid: "",
        placeId: null,
        purposeId: null,
        latitude: null,
        longitude: null,
        major: null,
        minor: null,
        url: "",
        eddystoneSupport: false,
        ibeaconSupport: false,
        state: "",
        spot: {
          placeId: null,
          placeName: null,
          floorId: null,
          floorLevel: null,
          buildingId: null,
          buildingName: null
        },
      },
      places: null
    };
  },

  computed: {
    ...mapGetters("Beacons", ["beaconById", "prepareNewBeacon", "didLoadBeacons", "beaconStates", "beaconProtocols"]),
    ...mapGetters("BeaconPurposes", ["purposes", "didLoadBeaconPurposes"]),
    ...mapGetters('errors', ['objectNotFound']),
    ...mapGetters('floors', ['floorByPlaceId', 'getBuildingFloors']),
    ...mapGetters('buildings', ['getBuildingNames']),
    ...mapGetters('places', ['getPlaces', 'placesByFloor', 'didLoadedPlaces', 'getTypeName']),

    beaconId() {
      const urlId = this.$route.params.id;
      return urlId === "new" ? null : Number(urlId)
    },

    initialBeacon() {
      return this.isNew
          ? this.prepareNewBeacon()
          : this.beaconById(this.beaconId)
    },

    isNew() {
      return this.beaconId === null;
    },

    beaconPurposesList() {
      return this.purposes.map(purpose => ({text: purpose.name, value: purpose.id}))
    },

    floorId() {
      return this.beacon.spot.floorId
    },

    buildingId() {
      return this.beacon.spot.buildingId
    },

    buildingsList() {
      let buildings = this.getBuildingNames
      buildings.unshift(({text: "Exteriér", value: null}))
      return buildings;
    },

    floorsList() {
      return this.getBuildingFloors(this.beacon.spot.buildingId).map(floor => ({
        text: `${floor.level}. patro`,
        value: floor.id
      }))
    },

    sortedPlacesList() {
      let places = this.placesByFloor(this.beacon.spot.floorId).map(place => ({
        text: place.localizedAttributes.cz.name !== ""
            ? place.localizedAttributes.cz.name
            : `${place.id} - ${this.getTypeName(place.type)}`,
        value: place.id
      }))
      return places.sort((a, b) => (b.text.localeCompare(a.text)))
    },

    statesList() {
      return this.beaconStates
    },

    protocolsList() {
      return this.beaconProtocols
    },

    hasBuilding() {
      return this.beacon.spot.buildingId !== null && !this.isOutside;
    },

    hasFloor() {
      return this.beacon.spot.floorId !== null || this.isOutside;
    },

    isOutside() {
      return this.beacon.spot.buildingId === null
    },

    hasIbeaconSupport() {
      return this.beacon.ibeaconSupport === true
    },

    hasEddystoneSupport() {
      return this.beacon.eddystoneSupport === true
    },

    didLoadData() {
      return (((this.didLoadBeacons) || this.isNew) && this.didLoadBeaconPurposes)
    }
  },
  methods: {
    ...mapActions('leavePrevent', ["madeUnsavedChanges", "didSaveChanges"]),
    ...mapActions("Beacons", [
      "fetchBeaconById",
      "createBeacon",
      "updateBeacon",
    ]),
    ...mapActions("BeaconPurposes", ["fetchBeaconPurposes"]),
    ...mapActions('places', ["fetchFilteredPlaces", "fetchPlace"]),
    ...mapActions('buildings', ["fetchAllBuildingsIfNecessary"]),
    ...mapActions('floors',["fetchAllFloors"]),
    ...mapActions('errors', ['clear']),

    eddystoneRule(value) {
      if (this.hasEddystoneSupport) {
        return !!value || "UID je povinné, když je protokol podporován"
      }
    },

    ibeaconRule(value) {
      if (this.hasIbeaconSupport) {
        return !!value || "UID je povinné, když je protokol podporován"
      }
    },

    checkEddystoneSupport() {
      this.$set(this.beacon, 'eddystoneUid', "")
      this.$set(this.beacon, 'url', "")
    },

    checkIbeaconSupport() {
      this.$set(this.beacon, 'ibeaconUid', "")
      this.$set(this.beacon, 'major', null)
      this.$set(this.beacon, 'minor', null)
    },

    async loadData() {
      if (!this.isNew) {
        await this.fetchBeaconById(this.beaconId)
        if (this.objectNotFound)
          return;
      } else {
        this.clear();
        await this.fetchFilteredPlaces({
          floorId: null,
          buildingId: null,
          exterior: true,
          language: null,
          placeName: null
        });
      }
      await Promise.all([this.fetchBeaconPurposes(), this.fetchAllBuildingsIfNecessary(), this.fetchAllFloors()])
    },

    setLocationByPlace(item) {
      this.beacon.spot.placeId = item

      let place = this.getPlaces.find(place => place.id === item)

      let centroid = (place && place.position) ? calculatePlaceCentroid(place.position) : null

      centroid.lat && centroid.lon
          ? this.setPosition(centroid.lat, centroid.lon)
          : this.clearPosition()

      this.changeWatch()
    },

    updateBatteryReplacementDate(value) {
      this.beacon.dateOfBatteryReplacement = moment(value).format('YYYY-MM-DD')
      this.changeWatch()
    },

    updatePlacementDate(value) {
      this.beacon.dateOfPlacement = moment(value).format('YYYY-MM-DD')
      this.changeWatch()
    },

    setDateOfLastUpdate() {
      this.beacon.dateOfLastUpdate = moment(new Date(), "YYYY-MM-DD")
      this.changeWatch()
    },

    setBuildingId(value) {
      this.$set(this.beacon.spot, 'buildingId', value)
      this.clearFloor()
      this.changeWatch()
    },

    setFloorId(value) {
      this.$set(this.beacon.spot, 'floorId', value)
      this.clearPlace()
      this.changeWatch()
    },

    clearBuilding() {
      // No need for reactivity anymore.
      this.$set(this.beacon.spot, 'buildingId', null)
      this.$set(this.beacon.spot, 'buildingName', null)
      this.clearFloor()
    },

    clearFloor() {
      this.$set(this.beacon.spot, 'floorId', null)
      this.$set(this.beacon.spot, 'floorLevel', null)
      this.clearPlace()
    },

    clearPlace() {
      // The first one is from response, the second for POST.
      this.$set(this.beacon, 'placeId', null)
      this.$set(this.beacon.spot, 'placeId', null)
      this.$set(this.beacon.spot, 'placeName', null)
      this.clearPosition()
    },

    clearPosition() {
      this.$set(this.beacon, 'longitude', null)
      this.$set(this.beacon, 'latitude', null)
    },

    setPosition(lat, lon) {
      this.$set(this.beacon, 'longitude', lat)
      this.$set(this.beacon, 'latitude', lon)
    },

    transformToRequest() {
      this.beacon.placeId = this.beacon.spot.placeId
    },

    formatDate(date) {
      return date ? moment.unix(date / 1000).format('YYYY-MM-DD') : null;
    },

    changeWatch() {
      this.madeUnsavedChanges()
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        this.setDateOfLastUpdate()
        this.transformToRequest()
        if (this.isNew) {
          await this.createBeacon(this.beacon)
        } else {
          await this.updateBeacon(this.beacon)
        }

        this.didSaveChanges()
        this.isSaving = false;

        if (this.isNew) {
          await this.$router.push({name: "Beacons"})
        }
      }
    },
  },
}
</script>

