<template>
  <div>
    <v-form ref="form" v-model="isFormValid">
      <v-simple-table fixed-header dense>
        <thead>
          <tr>
            <th :colspan="2">Čas události</th>
            <th>Název</th>
            <th>Místo</th>
            <th class="text-center" v-if="schedule.length > 1">Zvýraznit</th>
            <th class="text-center">{{schedule.length > 1 ? 'Smazat' : 'Zvýraznit'}}</th>
          </tr>
        </thead>
        <tbody v-for="(record, idx) in schedule" :key="`row-${record.id}`">
          <tr class="schedule-row">
            <td class="schedule-picker">
              <TimePicker 
                class="pt-3"
                :id="`time-from-${record.id}`"
                :neighborId="`time-to-${record.id}`"
                title="Od"
                :clearable="true"
                :rules="[required_rule_compact]"
                v-on:timeChanged="(value) => schedule.find(r => r.id === record.id).timeFrom = value"
                :initialValue="schedule.find(r => r.id === record.id).timeFrom"
                :prependIcon="prependIcon"
                ref="fromPicker">
              </TimePicker>
            </td>
            <td class="schedule-picker">
              <TimePicker
                class="pt-3"
                :id="`time-to-${record.id}`"
                :neighborId="`time-from-${record.id}`"
                title="Do"
                :clearable="true"
                :rules="[required_rule_compact]"
                v-on:timeChanged="(value) => schedule.find(r => r.id === record.id).timeTo = value"
                :initialValue="schedule.find(r => r.id === record.id).timeTo"
                :minTime="schedule.find(r => r.id === record.id).timeFrom"
                :prependIcon="prependIcon"
                ref="toPicker">
              </TimePicker>
            </td>
            <td>
              <v-text-field
                v-bind:key="`${idx}-name`"
                class="pt-3 text-field"
                :rules="[name_rule_compact]" 
                :maxlength="nameMaxLength" 
                color="dark"
                v-model="getEventById(record.id).name"
                :label="`${(getEventById(record.id).name || '').length}/${nameMaxLength} znaků`"
                dense>
              </v-text-field>
            </td>
            <td>
              <v-text-field
                v-bind:key="`${idx}-place`"
                class="pt-3 text-field"
                :rules="[place_rule_compact]" 
                :maxlength="placeMaxLength" 
                v-model="getEventById(record.id).place"
                color="dark"
                :label="`${(getEventById(record.id).place || '').length}/${placeMaxLength} znaků`"
                dense>
              </v-text-field>
            </td>
            <td class="text-center pl-8 pb-5">
              <v-checkbox 
                v-model="getEventById(record.id).isImportant"
                :value="getEventById(record.id).isImportant"
                hide-details="true"
                true-value="true"
                false-value="false">
                dense
              </v-checkbox>
            </td>
            <td v-if="schedule.length > 1" class="py-3 schedule-action text-center">
              <DeleteIcon @click="removeItem(record.id)"></DeleteIcon>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
    <LightButton 
      @click="() => schedule = [
        ...schedule, 
        { 
          id: getUUID(),
          timeFrom: undefined,
          timeTo: undefined,
          name: undefined,
          place: undefined
        }
      ]"
    >+ Nová položka</LightButton>
  </div>
</template>

<script>
  import TimePicker from "../../components/time-picker/TimePicker";
  import LightButton from "../../components/buttons/LightButton";
  import DeleteIcon from "../../components/buttons/DeleteIcon";
  import { uuid } from 'vue-uuid';
  export default {
    name: "SchedulePicker",
    props: {
      initialValues: Object,
      prependIcon: Boolean,
    },
    components: {
      TimePicker,
      DeleteIcon,
      LightButton
    },

    data() {
      const nameMaxLength = 50;
      const placeMaxLength = 5;

      return {
        nameMaxLength,
        placeMaxLength,
        required_rule: value => !!value || 'Povinné' ,
        required_rule_compact: value => !!value || false,
        name_rule_compact: value => !!value && value.length <= nameMaxLength || false,
        place_rule_compact: value => !!value && value.length <= placeMaxLength || false,
        isFormValid: false,
        schedule: this.initialValues?.schedule 
          ? this.initialValues.schedule.map(item => ({ ...item, id: uuid.v4() })) 
          : [{ 
            id: uuid.v4(), 
            timeFrom: undefined,
            timeTo: undefined,
            name: undefined,
            place: undefined,
            isImportant: false
          }],
      };
    },
    
    watch: {
      outputObject(value) {
        this.$emit('eventsChanged', { valid: this.isFormValid, pristine: this.lodash.isEqual(this.initialOutputObject, value), value: { schedule: value } })
      },

      isFormValid(value) {
        this.$emit('eventsChanged', { valid: value, pristine: this.lodash.isEqual(this.initialOutputObject, this.outputObject), value: { schedule: this.outputObject }})
      }
    },

    computed: {
      outputObject() {
        return this.createOutputObject(this.schedule);
      },
      initialOutputObject() {
        return this.createOutputObject(this.initialValues?.schedule);
      },
    },

    methods: {
      removeItem(id) {
        this.schedule = this.schedule.filter(item => item.id !== id);
      },

      createOutputObject(schedule) {
        return schedule?.map(item => this.lodash.omit(item, 'id'));
      },

      getUUID() {
        return uuid.v4();
      },

      getEventById(id) {
        return this.schedule.find(event => event.id === id);
      }
    }
  };
</script>

<style>
  .time-from {
    margin-top: 4px;
  }

  .time-to {
    padding-bottom: 16px;
  }

  .schedule-picker {
    min-width: 100px;
    vertical-align: top;
  }

  .text-field {
    min-width: 60px;
  }

  .schedule-action {
    min-width: 100px;
    width: 10%;
  }

  .schedule-single {
    min-width: 150px;
    width: 15%;
  }

  .schedule-table {
    width: 95%;
    border-collapse: collapse;
  }

  .schedule-row {
    border-bottom: 1px solid var(--primary-color);
  }

  table {
    border-collapse: collapse;
  }
</style>