<template>
    <Loading v-if="isLoading"></Loading>

    <v-container v-else fluid style="margin: 0">
        <div class="d-flex mb-4">
            <h2>{{ $t('reservations.restrict_edit') }}</h2>

            <v-spacer/>

            <v-btn @click="goBack(assistantId)" class="close_button mb-3 button margin_left">{{ $t('reservations.cancel') }}</v-btn>

            <v-dialog v-model="dialog" persistent max-width="290">
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="buttonDisabledValidate"
                            @click="sendUpdate()"
                            color="primary"
                            class="mb-3 button margin_left"
                            v-on="on">
                        {{ $t('reservations.save') }}
                    </v-btn>
                </template>
                <v-card v-if="could_save === true">
                    <v-card-title class="headline">{{ $t('reservations.restriction_saved') }}</v-card-title>
                    <v-card-text> {{ $t('reservations.restriction_changed_succ') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="goBack(assistantId)">{{ $t('reservations.ok') }}</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card v-if="could_save === false">
                    <v-card-title class="headline">{{ $t('reservations.restriction_not_saved') }}</v-card-title>
                    <v-card-text>{{ $t('reservations.restriction_not_save_error') }}.
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="goBack(assistantId)">{{ $t('reservations.ok') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-menu top :offset-x="true">
                <template v-slot:activator="{ on }">
                    <v-btn id="edit-menu-btn" icon v-on="on">
                        <v-icon>more_vert</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item id="delete-restriction-btn" @click="deleteClosedHoursItem">
                        <v-list-item-title class="primary--text">{{ $t('reservations.restrict_delete') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <v-form ref="form" v-model="valid">
            <!--Agenda-->
            <v-layout row class="no_margin">
                <v-flex xs4 class="v_flex_span_date float_left">
                    <span class="text">1. {{ $t('reservations.restrict_agend') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-radio-group class="no_margin_no_padding " v-model="restriction.agenda" row>
                        <v-radio color="#000" :label="$t('reservations.person')" value="person"></v-radio>
                        <v-radio color="#000" :label="$t('reservations.stud_dep_pef')" value="department"></v-radio>
                    </v-radio-group>
                    <v-select
                            v-if="restriction.agenda === 'person'"
                            v-model="restriction.person"
                            :items="getAssistantsForDropdown"
                            :hide-details="true"
                            :rules="[v => !!v || $t('reservations.item_required')]"
                            required
                            solo
                            :disabled="true"
                    ></v-select>
                </v-flex>
            </v-layout>

            <!--Duvod Restrikce-->
            <v-layout row class="no_margin top">
                <v-flex xs4 class="float_left">
                    <span class="text">2. {{ $t('reservations.restrict_reason') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-select
                            id="restriction-reason"
                            v-model="restriction.reason"
                            :items=getReasonsAccordingToAgenda
                            :hide-details="true"
                            :rules="[v => !!v || 'Item is required']"
                            :item-text="restrictionText"
                            required
                            return-object
                            solo
                    ></v-select>
                </v-flex>
            </v-layout>

            <!--Datum Restrikce-->
            <v-layout row class="no_margin top">
                <v-flex xs4 class="float_left">
                    <span class="text">3. {{ $t('reservations.restrict_date') }}</span>
                </v-flex>

                <!--Time from-->
                <v-row xs4 class="float_left">
                    <v-flex xs2>
                        <v-menu
                                v-model="menu_calendar"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="computedDateFormatted"
                                        readonly
                                        v-on="on"
                                        class="no_margin_no_padding"
                                        solo
                                        aria-required="true"
                                        :rules="dateRules"
                                        :hide-details="true"
                                        :disabled="true"
                                ></v-text-field>
                            </template>
                            <v-date-picker locale="cs"
                                           v-model="restriction.date"
                                           no-title
                                           :min="today"
                                           @input="menu_calendar = false"></v-date-picker>
                        </v-menu>
                    </v-flex>

                </v-row>
            </v-layout>

            <!--Time validity-->
            <v-layout row class="no_margin top">
                <v-flex xs4>
                    <span class="text">4. {{ $t('reservations.restrict_time') }}</span>
                </v-flex>

                <v-flex>
                    <v-flex xs5 class="float_left">
                        <v-radio-group class="no_margin_no_padding " v-model="restriction.radioGroupTimeRestriction" column>
                            <v-radio color="#000" :label="$t('reservations.whole_day') + `(${getOpenTime()} - ${getCloseTime()})`"
                                     value="All"></v-radio>
                            <v-radio color="#000" :label="$t('reservations.specific_timespan')" value="Some"></v-radio>
                        </v-radio-group>

                        <!--Text from-->
                        <v-flex row
                                v-if="restriction.radioGroupTimeRestriction === 'Some'"
                                class="no_margin_no_padding">
                            <v-flex xs2>
                                <v-menu
                                        ref="menu_time_from"
                                        v-model="menu_time_from2"
                                        :close-on-content-click="false"
                                        :nudge-right="65"
                                        :return-value.sync="restriction.time_from"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                v-model="restriction.time_from"
                                                :label="$t('reservations.time_from')"
                                                v-on="on"
                                                class="text_align"
                                                readonly
                                                aria-required="true"
                                                :rules="timeRules"
                                                :hide-details="true"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                            v-if="menu_time_from2"
                                            v-model="restriction.time_from"
                                            full-width
                                            format="24hr"
                                            scrollable
                                            header-color="rgb(118, 190, 26)"
                                            @click:minute="saveTimeFrom(restriction.time_from)"
                                            :min="getOpenTime()"
                                            :max="restriction.time_to || getCloseTime()"
                                    ></v-time-picker>
                                </v-menu>
                            </v-flex>

                            <!--Text to-->
                            <v-flex row xs2 align-content-center align-center justify-center class="overdrive">
                                <span class="doSpan2">{{ $t('reservations.untill') }}</span>
                            </v-flex>

                            <!--Time to-->
                            <v-flex xs2 class="margin_left">
                                <v-menu
                                        ref="menu_time_to"
                                        v-model="menu_time_to2"
                                        :close-on-content-click="false"
                                        :nudge-right="65"
                                        :return-value.sync="restriction.time_to"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                v-model="restriction.time_to"
                                                :label="$t('reservations.time_to')"
                                                v-on="on"
                                                readonly
                                                class="text_align"
                                                aria-required="true"
                                                :rules="timeRules"
                                                :hide-details="true"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                            v-if="menu_time_to2"
                                            v-model="restriction.time_to"
                                            full-width
                                            format="24hr"
                                            scrollable
                                            header-color="rgb(118, 190, 26)"
                                            @click:minute="saveTimeTo(restriction.time_to)"
                                            :min="restriction.time_from || getOpenTime()"
                                            :max="getCloseTime()"
                                    ></v-time-picker>
                                </v-menu>
                            </v-flex>
                        </v-flex>
                    </v-flex>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin top">
                <v-flex xs4 class="float_left">
                    <span class="text">5. {{ $t('reservations.longterm_action') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left">
                    <v-select
                            id="restriction-long-term-action"
                            v-model="restriction.longTermActionOption"
                            :items="options"
                            :rules="[v => !!v || $t('entities.required')]"
                            required
                            solo
                            item-value="value"
                            return-object
                            :disabled="true"
                            :hide-details="true"
                    ></v-select>
                </v-flex>
            </v-layout>

            <v-layout row class="no_margin top" v-if="returnLongTermActionOption() === 'delegate'">
                <v-flex xs4 class="float_left">
                    <span class="text">6. {{ $t('reservations.delegation') }}</span>
                </v-flex>

                <v-flex xs4 class="float_left" >
                    <v-select
                            id="restriction-long-term-action-delegated-assistant"
                            v-model="restriction.longTermActionDelegatedAssistant"
                            :items="getValidAssistant()"
                            :rules="[v => !!v || $t('entities.required')]"
                            required
                            solo
                            item-value="value"
                            return-object
                            :disabled="true"
                            :hide-details="true"
                    ></v-select>
                </v-flex>
            </v-layout>

            <!--table-->
            <v-layout row class="no_margin top">
                <v-flex>
                    <span class="text">{{returnCorrectNumbering(6)}}. {{ $t('reservations.restrict_meetings') }} ({{numberOfItems}})</span>
                </v-flex>
            </v-layout>
            <v-layout row class="no_margin">
                <v-flex v-if="numberOfItems !== 0">
                    <v-data-table
                            :headers="headers"
                            :items="dataToDataTable"
                            class="elevation-1"
                            :calculate-widths="true"
                            :items-per-page=10
                            :loading="isLoadingReservations"
                            :sort-by="['ticketNumber']"
                            :sort-desc="[false]"
                    >

                        <template v-slot:item.ticketNumber="{ item }">
                            <v-chip :color="getColor(item)" dark>{{item.ticketNumber}}</v-chip>
                        </template>

                        <template v-slot:item.option="{ item }">
                            <span v-if="isOutOfRestrictionPeriod(item)">Nebude ovlivněna restrikcí</span>
                            <v-select
                                    v-else
                                    :id="`ticket-option-${item.ticketNumber}`"
                                    v-model="item.option"
                                    :items="options"
                                    :rules="[v => !!v || $t('reservations.item_required')]"
                                    required
                                    :disabled="disableOptionForSomeoneElse(getValidAssistant(item.wantedDay, item.openTime, item.closeTime))"
                            >
                            </v-select>
                        </template>

                        <template v-slot:item.delegate="{ item }">
                            <v-select
                                    v-if="!isOutOfRestrictionPeriod(item)"
                                    :id="`ticket-delegate-${item.ticketNumber}`"
                                    v-model="item.assistantId"
                                    :items="getValidAssistant(item.wantedDay, item.openTime, item.closeTime)"
                                    :rules="conditionalRuleForDelegate(item.option)"
                                    required
                                    :disabled="setDisableIfCancel(item.option, item.id, getValidAssistant(item.wantedDay, item.openTime, item.closeTime))"
                            >
                            </v-select>
                        </template>
                        <!--                        <template v-slot:item.otherTime="{ item }">-->
                        <!--                            <v-select-->
                        <!--                                    v-model="item.otherTime"-->
                        <!--                                    :items="getTimeRangeForRestriction(restriction.time_from, restriction.time_to, item.otherTimeOriginal)"-->
                        <!--                                    :rules="conditionalRuleForDelegate(item.option)"-->
                        <!--                                    required-->
                        <!--                                    :disabled="setDisableIfCancel(item.option, item.id)"-->
                        <!--                            >-->
                        <!--                            </v-select>-->
                        <!--                        </template>-->
                    </v-data-table>
                </v-flex>

                <v-flex v-else>
                    <span>{{ $t('reservations.no_restrict_meetings') }}</span>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import moment from 'moment';
    import { del, post, put } from '@/services/api'
    import Loading from "../../../../../components/Loading";

    export default {
        components: {Loading},

        name: "RestrictionDetailEdit",

        props: {
            assistantId: null,
        },

        data() {
            let Rid = this.$route.params.Rid;

            return {
                proRid: Rid,
                personSelect: parseInt(this.assistantId),

                headers: [
                    {text: this.$t('headers.ticket_id'), value: 'ticketNumber', sortable: false, width: "8%"},
                    {text: this.$t('headers.request'), value: 'studyProblems', sortable: false, width: "5%"},
                    {text: this.$t('headers.date'), value: 'wantedDay', sortable: false},
                    {text: this.$t('headers.time'), value: 'time', sortable: false},
                    {text: this.$t('headers.student'), value: 'studentName', sortable: false, width: "15%"},
                    {text: this.$t('headers.options'), value: 'option', sortable: false, width: "13%"},
                    {text: this.$t('headers.delegate'), value: 'delegate', sortable: false, width: "23%"},
                    //{text: this.$t('headers.sub_time'), value: 'otherTime', sortable: false, width: "10%"},
                ],

                options: [
                    {text: this.$t('reservations.cancel'), value: 'cancel'},
                    {text: this.$t('reservations.delegate'), value: 'delegate'},
                ],


                restriction: null,

                restrictionOriginal: null,

                dataToDataTable: [],

                dataToDataTableOriginal: [],

                numberOfItems: 0,

                menu_calendar: false,
                menu_time_from: false,
                menu_time_from2: false,
                menu_time_to: false,
                menu_time_to2: false,

                valid: true,
                form: null,

                all_day_from: null,
                all_day_to: null,

                could_save: true,
                resp: null,

                dialog: false,

                originalAgenda: null,
                originalRadioGroupTimeRestriction: null,
                originalReason: null,

                today: moment().toISOString(),

                dateRules: [
                    v => !!v || this.$t('reservations.date_required'),
                ],

                timeRules: [
                    v => !!v || this.$t('reservations.time_required'),
                ],


                isLoadingReservations: false,
            }
        },

        async created() {
            await this.createdByPropsDefined();
        },

        computed: {
            ...mapGetters('assistant', ['getAssistant', 'getAssistantsForDropdown', 'didLoadedAssistants']),
            ...mapGetters('changedQueues', ['getTypeChangedQueues', 'didLoadedChangedQueues']),
            ...mapGetters('queueItemStatuses', ['didLoadedQueueItemStatuses', 'getQueueItemStatus']),
            ...mapGetters('closedHours', ['getTypeClosedHoursByDate', 'didLoadedClosedHours', 'getClosedHour',]),
            ...mapGetters('queueItems', ['getQueueItem', 'getQueueItemByQueueItemStatusId', 'didLoadedQueueItems']),
            ...mapGetters('studyProblemsCategories', ['didLoadedStudyProblemsCategories']),
            ...mapGetters('closedHoursReason', ['getClosedHoursReason', 'getClosedHoursReasons', 'didLoadedClosedHoursReasons']),
            ...mapGetters('StudyOpenHours', ['didLoadedStudyOpenHours', 'getStudyOpenHours']),
            ...mapGetters('studyProblemsItems', ['getStudyProblemsItem', 'didLoadedStudyProblemsItems']),
            ...mapGetters('studyFields', ['getStudyField','didLoadedStudyFields']),

            reasons() {
                return this.getClosedHoursReasons.map(item => ({
                    id: item.id,
                    name: item.reason,
                    type: item.type,
                }))
            },

            canFetchAffectedItems() {
                return (
                      this.didLoadedAllForAffectedItems &&
                      this.restriction.reason !== null &&
                      this.restriction.agenda !== null &&
                      this.restriction.date !== null &&
                      this.getRightRestrictionTimeFrom() !== null &&
                      this.getRightRestrictionTimeTo() !== null &&
                      (this.personSelect !== null || this.restriction.agenda === "department")
                )
            },

            getReasonsAccordingToAgenda() {
                let data = null;

                if (this.restriction.agenda === "person") {
                    data = this.reasons.filter(obj => obj.type === "person");
                }

                if (this.restriction.agenda === "department") {
                    data = this.reasons.filter(obj => obj.type === "department");
                }
                return data;
            },

            computedDateFormatted() {
                return this.formatDate(this.restriction.date);
            },

            didLoadedAllForForm() {
              return this.didLoadedClosedHoursReasons && this.didLoadedStudyOpenHours && this.didLoadedAssistants && this.didLoadedStudyFields && this.didLoadedStudyProblemsItems && this.didLoadedStudyProblemsCategories
                  && this.didLoadedClosedHours && this.restriction !== null;
            },

            didLoadedAllForAffectedItems() {
              return this.didLoadedAllForForm && this.didLoadedQueueItemStatuses && this.didLoadedQueueItems && this.didLoadedChangedQueues;
            },

            buttonDisabledValidate() {
                return !this.valid || (this.compareOriginalAndStateNow() && this.compareOriginalAndStateNowRequirements());
            },

            isLoading() {
                return this.didLoadedAllForForm === false || this.propsDefined;
            },

            propsDefined() {
                return this.assistantId === undefined;
            },
        },

        methods: {
            ...mapActions('assistant', ['fetchAssistants']),
            ...mapActions('changedQueues', ['fetchChangedQueuesByWantedDay']),
            ...mapActions('queueItemStatuses', ['fetchQueueItemStatusesByWantedDay']),
            ...mapActions('closedHours', ['fetchClosedHours']),
            ...mapActions('queueItems', ['fetchQueueItemsByWantedDay']),
            ...mapActions('studyProblemsCategories', ['fetchStudyProblemsCategories']),
            ...mapActions('closedHoursReason', ['fetchClosedHoursReasons']),
            ...mapActions('StudyOpenHours', ['fetchStudyOpenHours']),
            ...mapActions('studyProblemsItems', ['fetchStudyProblemsItems']),
            ...mapActions('studyFields', ['fetchStudyFields', ]),

            restrictionText: item => item.name,

            isOutOfRestrictionPeriod(item) {
                return item.otherTime < this.restriction.time_from || item.otherTime > this.restriction.time_to;
            },

            async fetchQueueItemsForSelectedDay() {
                await Promise.all([
                    this.fetchQueueItemsByWantedDay(this.restriction.date),
                    this.fetchQueueItemStatusesByWantedDay(this.restriction.date),
                    this.fetchChangedQueuesByWantedDay(this.restriction.date),
                ])
            },

            returnLongTermActionOption() {
                return this.restriction.longTermActionOption;
            },

            returnCorrectNumbering(number) {
                if (this.returnLongTermActionOption() === "cancel") {
                    return number;
                } else {
                    return number + 1;
                }
            },

            setTimes() {
                this.all_day_from = this.getOpenTime();
                this.all_day_to = this.getCloseTime();
            },

            getOpenTime() {
                let closedHours = this.getStudyOpenHours;
                let today = new Date(this.restriction.date).getDay().toString();
                let time = null;

                for (let item of closedHours) {
                    if (item.dayNumber.toString() === today && item.purpose === "mobile") {
                        time = item.openTime;
                        break;
                    }
                }

                return time;
            },

            getCloseTime() {
                let closedHours = this.getStudyOpenHours;
                let today = new Date(this.restriction.date);
                let time = null;

                for (let item of closedHours) {
                    if (item.dayNumber.toString() === today.getDay().toString() && item.purpose === "mobile") {
                        time = item.closeTime;
                        break;
                    }
                }

                return time;
            },

            goBack(assistantId) {
                this.$router.push({name: "RestrictionDetail", params: {Rid: this.proRid, assistantId}});
            },

            createCopyOfOriginal: function () {
                this.restrictionOriginal = {...this.restriction};
                this.dataToDataTableOriginal = JSON.parse(JSON.stringify(this.dataToDataTable));
            },

            compareOriginalAndStateNow: function () {
                return JSON.stringify(this.restrictionOriginal) === JSON.stringify(this.restriction);
            },

            compareOriginalAndStateNowRequirements: function () {
                return JSON.stringify(this.dataToDataTableOriginal) === JSON.stringify(this.dataToDataTable);
            },

            setReasonInModel() {
                let closedHour = this.getClosedHour(this.proRid);
                let item = this.getClosedHoursReason(closedHour.closedHoursReasonId);

                return {name: item.reason, type: item.type, id: item.id};
            },

            loadClickableRestrictionsModel() {
                this.restriction = {
                    agenda: null,
                    person: null,
                    reason: null,
                    date: null,
                    radioGroupTimeRestriction: null,
                    time_from: null,
                    time_to: null,
                    longTermActionOption: null,
                    longTermActionDelegatedAssistant: null,
                };

                let closedHour = this.getClosedHour(this.proRid);

                this.restriction.agenda = this.getClosedHoursReason(closedHour.closedHoursReasonId).type;
                this.originalAgenda = this.getClosedHoursReason(closedHour.closedHoursReasonId).type;

                if (closedHour.type === 'department') {
                    this.restriction.person = this.personSelect;
                } else {
                    this.restriction.person = closedHour.assistantId;
                }

                this.restriction.reason = this.setReasonInModel();
                this.originalReason = this.setReasonInModel();
                this.restriction.date = closedHour.date;

                this.restriction.longTermActionOption = closedHour.longTermOption;
                this.restriction.longTermActionDelegatedAssistant = closedHour.longTermAssistantId;

                if (closedHour.timeFrom === this.getOpenTime() && closedHour.timeTo === this.getCloseTime()) {
                    this.restriction.radioGroupTimeRestriction = "All";
                    this.originalRadioGroupTimeRestriction = "All";
                    this.restriction.time_from = this.getOpenTime();
                    this.restriction.time_to = this.getCloseTime();
                } else {
                    this.restriction.radioGroupTimeRestriction = "Some";
                    this.originalRadioGroupTimeRestriction = "Some";
                    this.restriction.time_from = closedHour.timeFrom;
                    this.restriction.time_to = closedHour.timeTo;
                }
            },

            formatDate(date) {
                if (!date) return null;

                const [year, month, day] = date.split('-');
                return `${day}.${month}.${year}`;
            },

            saveTimeFrom(time_from) {
                this.$refs.menu_time_from.save(time_from);
            },

            saveTimeTo(time_to) {
                this.$refs.menu_time_to.save(time_to);
            },

            resetTimeFromTo() {
                let closedHour = this.getClosedHour(this.proRid);

                let realTimeFrom = this.getOpenTime();
                let realTimeTo = this.getCloseTime();

                if (this.originalRadioGroupTimeRestriction === "All") {
                    this.restriction.time_from = closedHour.timeFrom;
                    this.restriction.time_to = closedHour.timeTo;
                }

                if (this.originalRadioGroupTimeRestriction === "Some") {
                    this.restriction.time_from = closedHour.timeFrom;
                    this.restriction.time_to = closedHour.timeTo;
                } else {
                    this.restriction.time_from = realTimeFrom;
                    this.restriction.time_to = realTimeTo;
                }
            },

            resetRestrictionSelect() {
                let closedHour = this.getClosedHour(this.proRid);
                let item = this.getClosedHoursReason(closedHour.closedHoursReasonId);

                if (item.type !== this.restriction.agenda) {
                    this.restriction.reason = null;
                } else {
                    this.restriction.reason = {name: item.reason, type: item.type, id: item.id};
                }
            },

            getRightRestrictionTimeFrom() {
                if (this.restriction.radioGroupTimeRestriction === "All") {
                    return this.all_day_from;
                } else {
                    return this.restriction.time_from;
                }
            },

            getRightRestrictionTimeTo() {
                if (this.restriction.radioGroupTimeRestriction === "All") {
                    return this.all_day_to;
                } else {
                    return this.restriction.time_to;
                }
            },

            deleteClosedHoursItem: function () {
                if (confirm(this.$t('reservations.confirm_deletion'))) {
                    del('study_department/assistant/closed_hours/' + this.proRid)
                        .then((response) => {
                            if (response.status === 204) {
                                alert(this.$t('confirms.delete_succesful'));
                            } else {
                                alert(this.$t('confirms.delete_unsuccesful'))
                            }
                            this.$router.push({name: "CalendarDetail", params: {assistantID: this.assistantId}});
                        })
                        .catch(e => alert(e.message));
                }
            },

            disableOptionForSomeoneElse(assistants) {
                return this.restriction.agenda === 'department' || assistants.length === 0;
            },

            setDisableIfCancel(item, itemId, assistants) {
                if ((item === 'cancel' || this.restriction.agenda === "department") && this.dataToDataTable.length > 0 || assistants.length === 0) {
                    for (var itm of this.dataToDataTable) {
                        if (itm.id === itemId) {
                            itm.assistantId = null;
                            itm.otherTime = itm.otherTimeOriginal;
                        }
                    }
                    return true;
                } else {
                    return false;
                }
            },

            conditionalRuleForDelegate(item) {
                if (item === 'delegate') {
                    return [v => !!v || this.$t('reservations.item_required')];
                } else {
                    return [];
                }
            },

            addZeroBefore(dayMonth) {
                if (dayMonth.length === 1) {
                    dayMonth = '0' + dayMonth;
                }
                return dayMonth
            },

            getTimeRangeForRestriction(startTime, endTime, toAdd) {
                let timerange = [toAdd];
                let step = 30; //minutes
                let startHours = parseInt(startTime.substring(0, 2));
                let endHours = parseInt(endTime.substring(0, 2));

                let minDiff = 2;

                for (let hour = startHours; hour < endHours; hour++) {
                    for (let min = 0; min < minDiff; min++) {
                        timerange.push(this.addZeroBefore(hour.toString()) + ":" + this.addZeroBefore((min * step).toString()));
                    }
                }

                return timerange.sort(function (a, b) {
                    return a > b;
                });
            },

            setOriginalItemsToTable(items) {
                let changedQueues = this.getTypeChangedQueues(this.proRid);
                this.dataToDataTable = [];

                let changedQueuesItemStatusIds = [];
                let notChangedQueueItems = [];
                let queueItems = [];

                for (let item of items) {
                    queueItems.push(this.getQueueItem(item));
                }

                for (let itemCq of changedQueues) {
                    changedQueuesItemStatusIds.push(itemCq.queueItemStatusId);
                }

                for (let item of queueItems) {
                    if (!changedQueuesItemStatusIds.includes(item.queueItemStatusId)) {
                        notChangedQueueItems.push(item);
                    }
                }

                for (let item of changedQueues) {
                    let dataPrototype = {
                        ticketNumber: null,
                        studyProblems: null,
                        wantedDay: null,
                        time: null,
                        studentName: null,
                        option: null,
                        assistantId: null,
                        otherTime: null,
                        otherTimeOriginal: null,
                        queueItemStatusId: null,
                        queueItemId: null,
                        openTime: null,
                        closeTime: null,
                        id: null,
                        original: true,
                    };

                    let queueStatus = this.getQueueItemStatus(item.queueItemStatusId);
                    let queueItem = this.getQueueItemByQueueItemStatusId(item.queueItemStatusId);

                    let studyProblems = "";

                    if (queueItem.studyProblemsItemsIds.length === null) {
                        for (let problem of queueItem.studyProblemsItemsIds) {
                            studyProblems += this.getStudyProblemsItem(problem).name + ' ';
                        }
                    } else {
                        studyProblems = this.getStudyField(queueItem.studyFieldId).name;
                    }

                    dataPrototype.ticketNumber = queueStatus.ticketNumber;
                    dataPrototype.studyProblems = studyProblems;
                    dataPrototype.wantedDay = this.formatDate(queueStatus.wantedDay);
                    dataPrototype.time = item.originalTimeFrom + ' - ' + item.originalTimeTo;

                    dataPrototype.openTime = queueStatus.actualMeetTime;
                    dataPrototype.closeTime = queueStatus.actualMeetEnd;

                    dataPrototype.studentName = queueItem.nameSurnameTitle + ' (' + queueItem.xname + ')';
                    dataPrototype.queueItemStatusId = item.queueItemStatusId;
                    dataPrototype.queueItemId = this.getQueueItemByQueueItemStatusId(item.queueItemStatusId).id;

                    dataPrototype.option = item.option;
                    dataPrototype.original = true;

                    if (item.option === "cancel") {
                        dataPrototype.assistantId = "";
                    } else {
                        dataPrototype.assistantId = this.getAssistant(queueItem.assistantId).id;
                    }

                    if (item.option === "cancel") {
                        dataPrototype.otherTime = queueStatus.actualMeetTime;
                    } else {
                        dataPrototype.otherTime = queueStatus.actualMeetTime;
                    }

                    dataPrototype.otherTimeOriginal = item.originalTimeFrom;
                    dataPrototype.id = item.id;

                    this.dataToDataTable.push(dataPrototype);
                }

                for (let queueItem of notChangedQueueItems) {
                    let dataPrototype = {
                        ticketNumber: null,
                        studyProblems: null,
                        time: null,
                        studentName: null,
                        option: null,
                        assistantId: null,
                        otherTime: null,
                        otherTimeOriginal: null,
                        queueItemStatusId: null,
                        queueItemId: null,
                        id: null,
                        original: false,
                    };

                    let queueStatus = this.getQueueItemStatus(queueItem.queueItemStatusId);
                    let studyProblems = "";

                    if (queueItem.studyProblemsItemsIds.length !== 0) {
                        for (let problem of queueItem.studyProblemsItemsIds) {
                            studyProblems += this.getStudyProblemsItem(problem).name + ' ';
                        }
                    } else {
                        studyProblems = this.getStudyField(queueItem.studyFieldId).name;
                    }

                    if (studyProblems.length > 90) {
                        studyProblems = studyProblems.slice(0, 87) + "..."
                    }

                    dataPrototype.ticketNumber = queueStatus.ticketNumber;
                    dataPrototype.studyProblems = studyProblems;
                    dataPrototype.time = queueStatus.actualMeetTime + ' - ' + queueStatus.actualMeetEnd;
                    dataPrototype.studentName = this.returnName(queueItem);
                    dataPrototype.queueItemStatusId = queueItem.queueItemStatusId;
                    dataPrototype.queueItemId = queueItem.id;
                    dataPrototype.option = 'cancel';
                    dataPrototype.otherTime = queueStatus.actualMeetTime;
                    dataPrototype.otherTimeOriginal = queueStatus.actualMeetTime;

                    this.dataToDataTable.push(dataPrototype);
                }
                this.numberOfItems = this.dataToDataTable.length;
            },

            returnName: function (queueItem) {
                if (queueItem.xname === null && queueItem.nameSurnameTitle != null) {
                    return queueItem.nameSurnameTitle;
                } else if (queueItem.nameSurnameTitle === null && queueItem.xname != null) {
                    return '(' + queueItem.xname + ')';
                } else if (queueItem.nameSurnameTitle === "Empty" && queueItem.xname === "Empty") {
                    return this.$t('reservations.anon_student')
                } else {
                    return queueItem.nameSurnameTitle + ' (' + queueItem.xname + ')';
                }
            },

            getColor(item) {
                if (this.isOutOfRestrictionPeriod(item)) {
                    return "gray lighten-2";
                } else if (item.original === false) {
                    return 'red';
                } else {
                    return 'green';
                }
            },

            async fetchConcernedReservations() {
                if (!this.canFetchAffectedItems) return;
                this.isLoadingReservations = true;
                await this.fetchQueueItemsForSelectedDay();
                await post('study_department/assistant/closed_hours/affected/', {
                    'type': this.restriction.reason.type,
                    'assistantId': this.personSelect,
                    'dateFrom': this.restriction.date,
                    'dateTo': this.restriction.date,
                    'timeFrom': this.getRightRestrictionTimeFrom(),
                    'timeTo': this.getRightRestrictionTimeTo(),
                }).then((response) => {
                    this.setOriginalItemsToTable(response.data.items);
                    this.isLoadingReservations = false;
                })
            },

            updateRestrictionToBackend() {
                let updateData = [];
                let createData = [];

                for (let item of this.dataToDataTable) {
                    if (item.original === true) {
                        updateData.push(item);
                    } else {
                        createData.push(item);
                    }
                }

                put(`study_department/assistant/closed_hours/${this.proRid}`, {
                    "assistantId": this.personSelect,
                    'dateFrom': this.restriction.date,
                    'dateTo': this.restriction.date,
                    'timeFrom': this.getRightRestrictionTimeFrom(),
                    'timeTo': this.getRightRestrictionTimeTo(),
                    'closedHoursReasonId': this.restriction.reason.id,
                    'toChange': updateData,
                    'toCreate': createData,
                }).then((response) => {
                    if (response.status !== 202) {
                        alert(this.$t('entities.couldnt_save_server'));
                    }
                }).catch(e => alert(e.message));
            },

            sendUpdate() {
                this.updateRestrictionToBackend();
            },

            goBackPropsNotDefined() {
                if (this.propsDefined) {
                    this.$router.push({name: "faq"});
                }
            },

            async createdByPropsDefined() {
                if (this.propsDefined) {
                    this.goBackPropsNotDefined();
                } else {
                    await Promise.all([
                        this.fetchStudyOpenHours(),
                        this.fetchClosedHoursReasons(),
                        this.fetchAssistants(),
                        this.fetchStudyFields(),
                        this.fetchStudyProblemsItems(),
                        this.fetchStudyProblemsCategories(),
                        this.fetchClosedHours(),
                    ])

                    this.loadClickableRestrictionsModel();
                    this.createCopyOfOriginal();
                    this.setTimes();

                    await this.fetchConcernedReservations();
                }
            },

            getValidAssistant() {
                return this.getAssistantsForDropdown.filter(ass => ass.value !== this.personSelect);
            },

            cancelAllAffectedItems() {
                for (const item of this.dataToDataTable) {
                    item.option = 'cancel'
                }
            },
        },

        watch: {
          'restriction.agenda': async function () {
              this.resetRestrictionSelect();
              await this.fetchConcernedReservations();
              if (this.restriction.agenda === 'department') {
                  this.cancelAllAffectedItems()
              }
          },
          'restriction.radioGroupTimeRestriction': async function () {
              if (this.radioGroupTimeRestriction === 'all') {
                  this.resetTimeFromTo();
              }
              await this.fetchConcernedReservations();
          },
          'restriction.date': async function () {
              this.setTimes();
              await this.fetchQueueItemsForSelectedDay();
              await this.fetchConcernedReservations();
          },
          'restriction.time_from': async function () {
            await this.fetchConcernedReservations();
          },
          'restriction.time_to': async function () {
            await this.fetchConcernedReservations();
          },
        }
    }
</script>

<style scoped>
    .no_margin {
        margin: 0;
    }

    .button {
        width: 7rem;
    }

    .close_button {
        background-color: #e6e6e6 !important;
        border-radius: 20px;
    }

    .margin_left {
        margin-left: 0.7rem;
    }

    .float_left {
        float: left;
        margin: 0;
        padding: 0;
    }

    .v_flex_span_date {
        width: 25rem;
    }

    .no_margin_no_padding {
        margin: 0;
        padding: 0;
    }

    .text {
        font-size: large;
        color: black !important;
    }

    .top {
        padding-top: 1rem;
    }

    .overdrive {
        margin: 0 !important;
        padding: 0 !important;
    }

    .doSpan2 {
        font-size: large;
        padding-bottom: 1.2rem;
        padding-left: 0.5rem;
    }

    .margin_left {
        padding-left: 0.1rem;
    }

</style>
