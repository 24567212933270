<template>
    <v-dialog v-model="dialog" persistent max-width="550">
        <template v-slot:activator="{ on }">
            <v-btn rounded color="orange" dark v-on="on">{{ $t('checkIn.break') }}</v-btn>
        </template>
        <v-card>
            <v-form ref="form"
                    v-model="valid">
                <v-card-title class="headline">{{ $t('checkIn.new_break') }}</v-card-title>
                <v-divider class="vdivider"></v-divider>
                <div class="dialog_middle">
                    <div class="time_group">
                        <div class="vchip_group_div">
                            <span class="subheading">{{ $t('checkIn.time') }}</span>

                            <v-chip-group
                                    mandatory
                                    active-class="orange--text text--darken-3"
                                    color="white"
                                    required
                                    v-model="pauseModel.pause_time"
                            >
                                <v-chip v-for="(time, idx) in times" :key="idx" :value="time.value"
                                        class="vchip_style"
                                        :class="[returnCssClass(idx)]"
                                >
                                    {{time.text}}
                                </v-chip>
                            </v-chip-group>
                        </div>
                    </div>


                    <div class="textarea">
                        <span class="subheading">{{ $t('checkIn.message') }}</span>
                        <v-textarea
                                :auto-grow="false"
                                :counter="150"
                                dense
                                no-resize
                                v-model="pauseModel.text_area"
                                @keydown="limit( $event, pauseModel.text_area, 150)"
                                solo></v-textarea>
                    </div>
                </div>

                <v-divider class="vdivider"></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey lighten-5" class="button_text" rounded @click="cancelButton">{{ $t('checkIn.close') }}</v-btn>
                    <v-btn color="blue darken-4" :disabled="!valid" class="button_text button_text_run" rounded @click="startPause()">{{
                        $t('checkIn.start') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import { post } from '@/services/api'
    import moment from 'moment';

    export default {
        name: "PauseDialog",

        data() {
            return {
                dialog: false,

                pauseModel: {
                    pause_time: null,
                    text_area: '',
                },

                valid: true,
                form: null,

                times: [{"text": this.$t('headers.five_min'), "value": "5"},
                    {"text": this.$t('headers.ten_min'), "value": "10"},
                    {"text": this.$t('headers.fifteen_min'), "value": "15"},
                ]
            }
        },

        computed: {
            ...mapGetters('pageStorage', ['getAssistantIdStorage']),
        },


        methods: {
        ...mapActions('AssistantPause', ['setActivePauseStorage']),

            returnCssClass(ind) {
                if (ind === 0) {
                    return 'vchip_style_border_left';
                } else if (ind === 2) {
                    return 'vchip_style_border_right';
                } else {
                    return 'vchip_style_middle';
                }
            },

            limit(event, dataProp, limit) {
                if (dataProp.length >= limit) {
                    if (event.keyCode.toString() === '8' || event.keyCode.toString() === '46') {
                        null
                    } else {
                        event.preventDefault();
                    }
                }
            },

            startPause: function () {
                this.addNewPauseToBackend();
            },

            cancelButton() {
                this.dialog = false;
                this.resetModel();
            },

            endButton() {
                if (confirm(this.$t('checkIn.checkIn_confirm'))) {
                    this.dialog = false;
                    this.$root.$emit('callInStart');
                    this.resetModel();
                }
            },

            resetModel() {
                this.pauseModel.pause_time = null;
                this.pauseModel.text_area = '';
            },

            addNewPauseToBackend() {
                post('study_department/assistant/pause/', {
                    "assistantId": this.getAssistantIdStorage,
                    "date": moment().format("YYYY-MM-DD"),
                    "duration": this.pauseModel.pause_time,
                    "note": this.pauseModel.text_area,
                    "startTime": moment().format("HH:mm:ss"),
                    "status": "active"
                }).then((response) => {
                    this.setActivePauseStorage(response.data.content);
                    this.dialog = false;
                    this.$root.$emit('callInPauseStarted');
                    this.resetModel();
                }).catch(e => alert(e.message));
            }
        }
    }
</script>

<style scoped>

    .vchip_style_middle {
        border-radius: 0 !important;
        border-top: grey 1px solid;
        border-bottom: grey 1px solid;
        border-left: grey 1px solid;
    }

    .vchip_style_border_left {
        border-top: grey 1px solid;
        border-bottom: grey 1px solid;
        border-left: grey 1px solid;
        border-radius: 5px 0 0 5px !important;
    }

    .vchip_style_border_right {
        border: grey 1px solid;
        border-radius: 0 5px 5px 0 !important;
    }

    .vchip_style {
        justify-content: center;
        width: 5rem;
        margin: 0 !important;
        padding: 0 !important;
        background-color: white !important;
    }

    .vdivider {
        margin-top: 0 !important;
    }

    .dialog_middle {
        padding: 0 1rem 0 1rem;
    }

    .vchip_group_div {
        width: fit-content;
        float: left;
    }

    .time_group {
        display: inline-flex;
    }

    .button_float_left {
        float: left !important;
        text-transform: capitalize;
        width: 30%;
    }

    .textarea {
        width: 100%;
        padding-top: 1rem;
    }

    .button_text {
        text-transform: capitalize;
        width: 18%;
    }

    .button_text_run {
        color: white !important;
    }

    .button_pause {
        text-transform: capitalize;
        font-size: large !important;
        font-weight: 400;
        letter-spacing: normal;
        width: 46%;
        background-color: #0D47A1 !important;
    }

</style>
