<template>
    <div id="auth-section">
        <UnauthorizedState v-if="!hasAccess" />
        <router-view v-else :key="routeKey"/>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import UnauthorizedState from './components/states/UnauthorizedState.vue';

    export default {
        name: 'authSection',

        components: {
            UnauthorizedState,
        },

        computed: {
            ...mapGetters('pageStorage', ['getAssistantStorage']),

            routeKey() {
                const matchedRoutes = this.$route.matched;

                // Study Department uses its own internal router, do not reload the QueueManagement component each time something changes inside.
                if (matchedRoutes.find(r => r.name == "StudyDepartment")) {
                    return 'auth/study-department/';
                }

                // when supplied fullPath, the whole component is rerendered even after change only in query param
                return this.$route.path
            },

            hasAccess() {
                return this.$auth.isLoggedIn && this.$auth.canAccessRoute(this.$route);
            },
        },

    }

</script>

<style>
    #auth-section {
        overflow: hidden;
    }
</style>
