<template>
    <Loading v-if="!didLoadData"/>
    <v-container v-else>
        <v-row>
            <v-col class="pl-0 mb-3">
                <div class="text-h4 font-weight-medium">{{ $t('news.statistics') }}</div>
                <div class="subTitle">{{ $t('news.statistics_subtitle') }}</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-container class="pa-0">
                    <v-row>
                        <v-col cols="12" lg="4" class="pa-0">
                            <v-card class="mr-5 mb-5 card" outlined >
                                <v-list :height="180">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="titleCard">
                                                {{ $t('news.total_user') }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="subTitleCard my-4">
                                                {{ this.totalUsers }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle class="mt-3" style="white-space: normal;">
                                                {{ $t('news.total_user_text') }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>

                        <v-col cols="12" lg="4" class="pa-0" v-for="(graph,index) in graphs" :key="index">
                            <v-card class="mr-5 mb-5 card" outlined>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="titleCard mb-5">
                                                {{ graph.title }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="chart-legend">
                                                <ul>
                                                    <li v-for="(label, index) in graph.chartData.labels" :key="index">
                                                        <span class="legend-dot"
                                                              :style="dotColor(graph.chartData, index)"></span>
                                                        <span class="legend-labels">{{ label }}</span>
                                                    </li>
                                                </ul>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                            <v-container>
                                                <Doughnut
                                                    :chart-data="graph.chartData"
                                                    :chart-options="chartOptions"
                                                    :height="115"
                                                />
                                            </v-container>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6" lg="3" class="pa-0" v-for="(item, index) in statistics" :key="index"
                   :class="{ 'mb-5': index === 3 }">
                <v-card class="mr-5 mb-5 card" outlined>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="mb-2">
                                    <template v-if="item.iconName">
                                        <v-icon color="light-green" size="27">{{ item.iconName }}</v-icon>
                                    </template>
                                    <template v-else>
                                        <v-img :src="controlImg(item.imageUrl)" max-height="27" max-width="27"/>
                                    </template>
                                </v-list-item-title>
                                <v-list-item-title class=" titleCard ">
                                    {{ item.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="mb-4 subTitleCard">
                                    {{ item.absolute }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="mb-1">
                                    <span class="text-h7 relative">{{ item.relative.toFixed(2) }} % </span>
                                    <span class="text-h7"> {{ $t('news.all_users') }}</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Loading from "@/components/Loading";
import {Doughnut} from 'vue-chartjs/legacy'
import {
    Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
    name: "NewsfeedStatistics",

    components: {
        Loading,
        Doughnut
    },

    async created() {
        await this.fetchNewsfeedStatistics();
    },

    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    }
                },
            },
        }
    },

    computed: {
        ...mapGetters('Newsfeed', ['didLoadNewsfeedStatistics', 'getNewsfeedStatistics', 'audienceNames', 'facultyNames', 'facultyAbsolutes', 'audienceAbsolutes']),

        didLoadData() {
            return this.didLoadNewsfeedStatistics;
        },

        totalUsers() {
            return this.getNewsfeedStatistics.totalUsers;
        },

        audiencesStatistics() {
            return this.getNewsfeedStatistics.audiences;
        },
        facultiesStatistics() {
            return this.getNewsfeedStatistics.faculties;
        },

        statistics() {
            return [...this.audiencesStatistics, ...this.facultiesStatistics]
        },

        graphs() {
            return [
                {
                    title: this.$t('news.audience_overview'),
                    chartData: this.chartData('audience'),
                },
                {
                    title: this.$t('news.faculty_overview'),
                    chartData: this.chartData('faculty')
                }
            ]
        },

    },

    methods: {
        ...mapActions('Newsfeed', ['fetchNewsfeedStatistics']),

        controlImg(icon) {
            return icon ? icon : '/icons/mendelu.png';
        },

        dotColor(arr, index) {
            return {'background-color': arr.datasets[0].backgroundColor[index]}
        },

        chartData(graphType) {
            const isFaculty = graphType === 'faculty'
            const facultyGraphColors = ['#78bf12', '#cf9800', '#034e23', '#0145a1', '#8c0201', '#aa016e', '#01abb4', '#cc00ff', '#00bfff', '#d4af37', '#78bf12'];
            const audienceGraphColors = ['#8bb946', '#b3d782', '#d1e4b5', '#e9f0dc'];

            let isNotEmpty = isFaculty ? this.facultyAbsolutes.find(num => num !== 0) : this.audienceAbsolutes.find(num => num !== 0);
            let labels = isFaculty ? this.facultyNames : this.audienceNames;
            let absolutes = isFaculty ? this.facultyAbsolutes : this.audienceAbsolutes;
            let colors = isFaculty ? facultyGraphColors : audienceGraphColors

            return {
                labels: isNotEmpty ? labels : [this.$t('news.no_data')],
                datasets: [
                    {
                        backgroundColor: colors,
                        data: isNotEmpty ? absolutes : [100]
                    }
                ]
            }
        }

    },

}

</script>

<style scoped>

.subTitle {
    font-size: 19px;
    color: #6D7278;
}

.titleCard {
    font-weight: 500;
    font-size: 20px;
    white-space: normal;
}

.subTitleCard {
    font-weight: 500;
    font-size: 29px;
    white-space: normal;
}

.relative {
    color: #78BE13;
    font-weight: bold;
    font-size: 16px;
}

.chart-legend ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.chart-legend li {
    align-items: center;
    display: flex;
    margin-bottom: 5px;
    margin-right: 10px;
}

.legend-dot {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
}


.legend-labels {
    font-size: 14px;
    font-weight: 500;
    color: #6c757d;
}

.v-card.card {
    border: 2px solid #eeeeef;
}

</style>
