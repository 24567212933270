<template>
    <div>
        <ModuleHeader>
            <RefreshButton @click="refreshWarnings">
                <h2 data-cy="warnings-heading">Důležité zprávy</h2>
            </RefreshButton>
            <PrimaryButton data-cy="add-new" @click="goToDetail('new')">+ Nové</PrimaryButton>
        </ModuleHeader>

        <div class="panel">
            <div>
                <v-select
                    data-cy="archived-selector"
                    v-model="$query.archived"
                    :items="selectArchivedItems"
                    label="Zobrazit archivované"
                    class="archive-switch settings"
                    :clearable="false"
                    color="dark"/>
            </div>
        </div>

        <Loading v-if="!didLoadWarnings"/>
        <v-data-table
                v-else
                :headers="headers"
                :items="getWarnings"
                :sort-by.sync="$query.sort"
                :sort-desc.sync="$query.desc"
                :hide-default-footer="true"
                :disable-pagination="true"
                :hide-default-header="false"
                class="d-none d-sm-block"
                data-cy="table"
        >

            <template v-slot:no-data>
                Žádná dostupná data
            </template>
            <template v-slot:item.localizedAttributes.cz.title="{ item }">
                <b class="pointer light-green--text" @click="goToDetail(item.id)">{{ item.localizedAttributes.cz.title | ellipsis(60) }}</b>
            </template>
            <template v-slot:item.audience="{ item }">
                {{ formatAudience(item.audience)}}
            </template>
            <template v-slot:item.user="{ item }">
                {{ item.triggeredBy }}
            </template>
            <template v-slot:item.date="{ item }">
                {{ formatDateTime(item.date) }}
            </template>
        </v-data-table>

    <!-- ONLY FOR SMALLER SCREENS -->
    <v-data-iterator
      :items="getWarnings"
      :sort-by.sync="$query.sort"
      :sort-desc.sync="$query.desc"
      :hide-default-footer="true"
      :disable-pagination="true"
      :hide-default-header="false"
      class="d-block d-sm-none"
    >
        <template v-slot:no-data>
                Žádná dostupná data
        </template>
      <template v-slot:default="props">
        <v-row>
          <v-col v-for="item in props.items" :key="item.name" cols="12">
            <mobile-data-table-row
              @click="goToDetail(item.id)"
            >
              <template v-slot:tag>tag</template>
              <template v-slot:title>{{
                item.localizedAttributes.cz.title
              }}</template>
                <template v-slot:item.audience="{ item }">
                    {{ formatAudience(item.audience)}}
                </template>
              <template v-slot:label_header1>VYTVOŘIL</template>
              <template v-slot:label_header2>CÍLOVÁ SKUPINA</template>
              <template v-slot:label_content1>{{ item.triggeredBy }}</template>
                <template v-slot:label_content2>
                    {{ formatAudience(item.audience)}}
                </template>
            </mobile-data-table-row>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import PrimaryButton from "../../components/buttons/PrimaryButton";
    import RefreshButton from "../../components/buttons/RefreshButton";
    import ModuleHeader from "../../components/layout/ModuleHeader";
    import Loading from "../../components/Loading.vue";
    import moment from "moment";
import MobileDataTableRow from '../../components/layout/MobileDataTableRow.vue';


    const SELECT_ARCHIVED_FALSE = 0;
    const SELECT_ARCHIVED_TRUE = 1;
    const SELECT_ARCHIVED_ALL = 2;

    export default {
        name: "Warnings",

        components: {
            ModuleHeader,
            PrimaryButton,
            RefreshButton,
            MobileDataTableRow,
            Loading,
        },

        created() {
            this.fetchWarnings();
        },

        data() {
            return {
                headers: [
                    {
                        text: 'Nadpis',
                        value: 'localizedAttributes.cz.title',
                        width: "35%"
                    },
                    {
                        text: 'Cílová skupina',
                        value: 'audience',
                        width: "35%"
                    },
                    {
                        text: 'Vytvořil/a',
                        value: 'triggeredBy',
                        width: "10%"
                    },
                    {
                        text: 'Upraveno',
                        value: 'date',
                        width: "20%"
                    },
                ],
                selectArchivedItems: [
                    {text:'Ne', value:SELECT_ARCHIVED_FALSE},
                    {text:'Ano',value:SELECT_ARCHIVED_TRUE},
                    {text:'Všechny',value:SELECT_ARCHIVED_ALL}
                ],
            }
        },
        computed: {
            ...mapGetters('Warnings', ['didLoadWarnings', 'warnings', 'getArchived', 'getLive']),
            ...mapGetters('localization', ['currentLanguage']),

            getWarnings(){
                switch (this.$query.archived) {
                    case SELECT_ARCHIVED_FALSE: return this.getLive;
                    case SELECT_ARCHIVED_TRUE: return this.getArchived;
                    case SELECT_ARCHIVED_ALL: return this.warnings;
                    default: return this.getLive;
                }
            },
        },
        methods: {
            ...mapActions('Warnings', ['fetchWarnings']),

            refreshWarnings() {
                this.fetchWarnings();
            },

            goToDetail(warningId) {
                this.$router.push({name: 'messageDetail', params: {id: warningId}});
            },

            formatDateTime(timestamp) {
                return moment(timestamp).format('lll');
            },

            formatAudience(audience){
                return audience ? audience.map(x => x.localizedAttributes.cz.name).join(', ') : '-'
            }

        },

    }
</script>

<style scoped>

    .panel{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

</style>
