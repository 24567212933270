const state = {
  currentLanguage: "cz",
};

const getters = {
  currentLanguage: state => state.currentLanguage,
};

const actions = {
};

const mutations = {
  setLanguage: (state, language)=>{
    state.currentLanguage = language;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
