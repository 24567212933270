export const icons = [
    { mobileIcon: "ic_account_balance", webIcon: "account_balance" },
    { mobileIcon: "ic_assignment_turned_in", webIcon: "assignment_turned_in" },
    { mobileIcon: "ic_attach_money", webIcon: "attach_money" },
    { mobileIcon: "ic_ballot", webIcon: "ballot" },
    { mobileIcon: "ic_business", webIcon: "business" },
    { mobileIcon: "ic_calendar_today", webIcon: "calendar_today" },
    { mobileIcon: "ic_class", webIcon: "class" },
    { mobileIcon: "ic_cloud", webIcon: "cloud" },
    { mobileIcon: "ic_commute", webIcon: "commute" },
    { mobileIcon: "ic_create", webIcon: "create" },
    { mobileIcon: "ic_credit_card", webIcon: "credit_card" },
    { mobileIcon: "ic_desktop_windows", webIcon: "desktop_windows" },
    { mobileIcon: "ic_event_seat", webIcon: "event_seat" },
    { mobileIcon: "ic_flight_takeoff", webIcon: "flight_takeoff" },
    { mobileIcon: "ic_forum", webIcon: "forum" },
    { mobileIcon: "ic_hotel", webIcon: "hotel" },
    { mobileIcon: "ic_local_dining", webIcon: "restaurant" },
    { mobileIcon: "ic_local_library", webIcon: "local_library" },
    { mobileIcon: "ic_person_add", webIcon: "person_add" },
    { mobileIcon: "ic_print", webIcon: "print" },
    { mobileIcon: "ic_school", webIcon: "school" },
    { mobileIcon: "ic_sentiment_satisfied", webIcon: "sentiment_satisfied" },
    { mobileIcon: "ic_thumb_down", webIcon: "thumb_down" },
    { mobileIcon: "ic_wifi", webIcon: "wifi" },
    { mobileIcon: "ic_corona", webIcon: "ic_corona" }
];

export const faq_icons = [
    { mobileIcon: "legal_consulting", webIcon: "legal_consulting" },
    { mobileIcon: "study_consulting", webIcon: "study_consulting" },
    { mobileIcon: "psychological_consulting", webIcon: "psychological_consulting" },
    { mobileIcon: "specific_needs", webIcon: "specific_needs" },
    { mobileIcon: "career_consulting", webIcon: "career_consulting" },
]