import {get, post, del, put} from '@/services/api'

const state = {
    didLoadedPlaces: false,
    didLoadedPlace: false,
    places: [],
    types: [
        {text: "Oddělení", value: "department"},
        {text: "Kancelář", value: "office"},
        {text: "Sklad", value: "utility_room"},
        {text: "Studovna", value: "study_room"},
        {text: "Kantýna", value: "cafeteria"},
        {text: "Terasa", value: "terrace"},
        {text: "Automat na lístky", value: "ticket_machine"},
        {text: "Jídelní automat", value: "food_machine"},
        {text: "Vnitro budovy", value: "indoor_building"},
        {text: "Prázdný prostor", value: "empty"},
        {text: "Zastávka MHD", value: "transportation_stop"},
        {text: "Zahrada", value: "garden"},
        {text: "Sklad", value: "storage"},
        {text: "Zasedací místnost", value: "boardroom"},
        {text: "Koleje", value: "dormitory"},
        {text: "Pitná voda", value: "water_machine"},
        {text: "Dámské toalety", value: "toilet_female"},
        {text: "Podlaha", value: "floor"},
        {text: "Vrátnice", value: "gatehouse"},
        {text: "Toaleta", value: "toilet"},
        {text: "Strojovna", value: "engine_room"},
        {text: "Počítačový kiosek", value: "computer_kiosk"},
        {text: "Dřez", value: "sink"},
        {text: "Nouzový východ", value: "emergency_floor"},
        {text: "Učebna a laboratoř", value: "classroom_and_laboratory"},
        {text: "Kopírka", value: "copier"},
        {text: "Obvod", value: "outline"},
        {text: "Skříňka", value: "locker"},
        {text: "Šachta", value: "shaft"},
        {text: "Přízemí", value: "ground"},
        {text: "Šatna", value: "coatroom"},
        {text: "Schody", value: "stairs"},
        {text: "Výtah", value: "elevator"},
        {text: "Pánské toalety", value: "toilet_male"},
        {text: "Chodník", value: "walk"},
        {text: "Parkoviště", value: "parking"},
        {text: "Zeď", value: "wall"},
        {text: "Archiv", value: "archive"},
        {text: "Kávový automat", value: "cafe_machine"},
        {text: "Bistro", value: "bistro"},
        {text: "Kuchyně", value: "kitchen"},
        {text: "Garáž", value: "garage"},
        {text: "Brána", value: "gate"},
        {text: "Budova", value: "building"},
        {text: "Knihovna", value: "library"},
        {text: "Nouzové schody", value: "emergency_stairs"},
        {text: "Odpadkový koš", value: "trashcan"},
        {text: "Toaleta pro handicapované", value: "toilet_handicapped"},
        {text: "Pokoj", value: "room"},
        {text: "Pizzerie", value: "pizzeria"},
        {text: "Vydavatelství", value: "publishing_house"},
        {text: "Vinárna", value: "wine_shop"},
        {text: "Odpadky", value: "garbage"},
        {text: "Obvod patra", value: "base"},

        //01.10.21 TYPES
        {text: "Okenní rám", value: "window_frame"},
        {text: "Dveře", value: "door"},
        {text: "Balkón", value: "balcony"},
        {text: "Sprcha", value: "shower"},

        //18.02.22 TYPES
        {text: "Přípravná místnost", value: "preparatory"},
        {text: "Rozvodná místnost", value: "substation"},
        {text: "Lobby", value: "lobby"},
        {text: "Reprezentativní prostor", value: "representative_space"},
        {text: "Kulturní prostory", value: "cultural_space"},
        {text: "Regulační stanice", value: "regulatory_station"},

        //12.05.22 TYPES
        {text: "Nevyužívané", value: "not_used"},
        {text: "Dílna", value: "workshop"},
        {text: "Hala", value: "hall"},

        //03.08.22 TYPES
        {text: "Koupelna", value: "bathroom"},

        //30.09.22 TYPES
        {text: "Anglický dvorek", value: "english_backyard"},
        {text: "Denní místnost", value: "daily_room"},
        {text: "Sklad potravin", value: "food_storage"},
        {text: "Výdejna jídla", value: "food_serving"},
        {text: "Místnost na mytí nádobí", value: "dish_washing_room"},
        {text: "Jídelna", value: "dining_room"},
        {text: "Rampa", value: "ramp"},

        //09.08.23 TYPES
        {text: "Serverovna", value: "server_room"},
    ],
    floors: [
        {text: "5", value: 8},
        {text: "4", value: 9},
        {text: "3", value: 10},
        {text: "2", value: 11},
        {text: "1", value: 12},
        {text: "0", value: 13},
        {text: "-1", value: 14},
        {text: "-2", value: 15}
    ],
    headersOfTable: [
        {
            text: 'ID',
            align: 'start',
            value: 'id',
            width: "10%",
            filterable: true
        },
        {
            text: 'Název',
            align: 'left',
            value: 'name',
            width: "35%",
            filterable: true
        },
        {
            text: 'Typ',
            align: 'left',
            value: 'type',
            width: "20%",
            filterable: false,
        },
        {
            text: 'Podlaží',
            align: 'center',
            value: 'floorId',
            width: "15%",
            filterable: false
        },
        {
            text: 'Upraveno',
            align: 'left',
            value: 'lastUpdate',
            width: "20%",
            filterable: false
        },
    ],
    days: ["Po", "Út", "St", "Čt", "Pá", "So", "Ne"],
    place: null,
    newPlace: null,
    processing: false,
    objectsOfCertainFloor: [],
    buildings: [],
    buildingNames: ["Budova Q", "Exteriér"],
    placeImages: [],
    totalItemCount: null,
};

const getters = {
    didLoadedPlaces: state => state.didLoadedPlaces,
    didLoadedPlace: state => state.didLoadedPlace,
    isProcessing: state => state.processing,
    getPlaces: state => state.places,
    placeById: state => id => state.places.find(p => p.id === id),
    getPlace: state => state.place,
    placeByBeaconId: state => item => state.places.find(place => place.id === item.placeId),
    getPlacesTypes: state => state.types,
    getTypeName: state => typeName => {
        const type = state.types.find(el => el.value === typeName);
        return type ? type.text : '';
    },
    getHeadersOfTable: state => state.headersOfTable,
    getDays: state => state.days,
    getObjectsOfCertainFloor: state => state.objectsOfCertainFloor,
    constructNewPlace: () => () => ({
        "id": "new",
        "accessType": null,
        "clickable": false,
        "code": null,
        "computerWithProjector": null,
        "localizedAttributes": {
            "cz": {
                name: "",
                nickname: "",
                description: "",
            },
            "en": {
                name: "",
                nickname: "",
                description: "",
            },
        },
        "email": null,
        "floorId": null,
        "numberOfSeats": null,
        "numberOfImages": 0,
        "openHours": [],
        "person1Id": null,
        "person2Id": null,
        "phone": null,
        "position": [],
        "searchable": true,
        "type": null,
        "url": null,
        "whiteboard": null,
        "created": new Date().toJSON().slice(0, 10).replace(/-/g, ''),
    }),
    getNewPlace: state => state.newPlace,
    getPlaceImages: state => state.placeImages,
    createEmptyCoordinate: () => () => ({
        lat: null,
        lon: null,
        order: 0,
    }),
    getUniversityPosition: () => () => ({
        lat: 49.210759,
        lon: 16.615506,
        order: 0,
    }),
    placesByFloor: state => floorId => state.places.filter(place => place.floorId === floorId),

    /**
     * Returns the first place that matches the given name ignoring case.
     * @param {string} name
     * @param {string} language Specify, which localized version of the name to use for comparison. Default is `cz`.
     * @returns One place or undefined.
     */
    getPlaceByName: state => (name, language = 'cz') => {
        const sanitizedName = name.toLowerCase();
        return state.places.find(p =>
            p.localizedAttributes[language] && p.localizedAttributes[language].name.toLowerCase() == sanitizedName
        );
    },
    getFirstPlace: state => state.places[0],
    getTotalItemCount: state => state.totalItemCount,
};

const actions = {

    // fetches all places unless they are already in store
    async fetchPlaces({commit}) {
        commit('setDidLoadedPlaces', false);
        await get('map/places/')
            .then((response) => {
                commit('setPlaces', response.data.items);
                commit('setDidLoadedPlaces', true);
                commit('updateBuildingPlaces');
            });
    },

    async fetchPlacesByPageAndItemsPerPage({commit}, {searchString = null, language = null,
        buildingId = null, floorId = null, exterior = null, placeType = null, page = null, itemsPerPage = null}) {

        commit('setDidLoadedPlaces', false);
        try {
            let url = 'map/places/?';
            if ( searchString != null && language != null)
                url = `${url}&search=${searchString}&language=${language}`;

            if (page != null && itemsPerPage != null && itemsPerPage > 0)
                url = `${url}&page=${page - 1}&itemsPerPage=${itemsPerPage}`;

            if (buildingId == null && floorId == null && exterior === true)
                url = `${url}&exterior=true`;

            if (buildingId != null && floorId == null && exterior !== true)
                url = `${url}&buildingId=${buildingId}`;

            if (buildingId == null && floorId != null && floorId !== '' && exterior !== true)
                url = `${url}&floorId=${floorId}`;

            const placeTypeVal = placeType == null ? '' : placeType;
            url = `${url}&placeType=${placeTypeVal}`;

            const response = await get(url);

            commit('setPlaces', response.data.items);
            commit('setTotalItemCount', response.data.count);
            commit('setDidLoadedPlaces', true);
            commit('updateBuildingPlaces');
        } catch (e) {
            console.log(e)
        }
    },

    async fetchFilteredPlaces({commit}, {floorId, buildingId, exterior, placeName, language}) {
        commit('setDidLoadedPlaces', false);
        try {
            let response;
            if (floorId !== null && buildingId === null && exterior === null && placeName === null && language === null)
                response = await get(`map/places/?floorId=${floorId}`);

            else if (floorId === null && buildingId !== null && exterior === null && placeName === null && language === null)
                response = await get(`map/places/?buildingId=${buildingId}`);

            else if (floorId === null && buildingId === null && exterior !== null && placeName === null && language === null)
                response = await get(`map/places/?exterior=${exterior}`);

            else if (floorId === null && buildingId === null && exterior === null && placeName !== null && language !== null)
                response = await get(`map/places/?language=${language}&placeName=${placeName}`);
            else {
                console.log("Unexpected arguments");
                return;
            }

            commit('setPlaces', response.data.items);
            commit('setDidLoadedPlaces', true);
            commit('updateBuildingPlaces');
        } catch (e) {
            console.log(e)
        }
    },

    // fetches one places into map view
    async fetchPlace({commit}, id) {
        commit('errors/clear', null, {root: true});
        commit('setDidLoadedPlace', false);
        await Promise.all([
            get(`map/places/${id}`),
            get(`map/images/${id}`)
        ]).then((response) => {
            commit('setPlace', response[0].data.content);
            commit('setImages', response[1].data.items);
            commit('setDidLoadedPlace', true);
        }).catch(e => {
            if (e.statusCode == 404) {
                commit('errors/objectNotFound', null, {root: true});
            } else {
                throw e;
            }
        })
    },

    async fetchFloor({commit}, floor) {
        if (state.places.length === 0) {
            const response = await get('map/places/');
            const responseFloors = response.data.items.filter(place => place.floorId === floor);
            commit('setObjectsOfCertainFloor', responseFloors);
        } else {
            commit('filterFloorsIfPlacesFetched', floor);
        }
    },

    // updates place after saving changes
    async updatePlace({commit}, {id, item}) {
        return new Promise((resolve) => {
            put(`map/places/${id}`, item)
                .then((item) => {
                    commit('updateBuildingPlaces');
                    commit('updatePlace', item.data.content);
                    resolve(item.data.content);
                });
        });
    },

    // deletes one selected place
    async deletePlace({commit}, id) {
        await del(`map/places/${id}`);
        commit('updateBuildingPlaces');
        commit('filterPlaces', id);
    },

    // creates new place
    async postPlace({commit}, {place}) {
        return new Promise((resolve) => {
            commit('setProcessing', true);
            post('map/places/', place).then((response) => {
                commit('addPlace', response.data.content);
                commit('updateBuildingPlaces');
                resolve(response.data.content);
            });
        });
    },

    createNewPlace({commit}) {
        commit('setNewPlace', getters.constructNewPlace()());
    },

    deleteImage({commit}, {placeid, id}) {
        del(`map/images/${placeid}/${id}`);
        commit('removeImage', id);
    },

    addImage({commit}, image) {
        commit('addImage', image);
    },
};

const mutations = {
    setDidLoadedPlaces: (state, value) => {
        state.didLoadedPlaces = value;
    },
    setDidLoadedPlace: (state, value) => {
        state.didLoadedPlace = value;
    },
    setProcessing: (state, value) => {
        state.processing = value;
    },
    updatePlace: (state, item) => {
        let index = state.places.findIndex(place => place.id === item.id);
        state.places[index] = item;
    },
    setPlaces: (state, places) => (state.places = places),
    addImage: (state, image) => (state.placeImages.push(image)),
    setImages: (state, images) => (state.placeImages = images),
    setPlace: (state, place) => (state.place = place),
    filterPlaces: (state, id) => (state.places = state.places.filter(places => places.id !== id)),
    addPlace: (state, place) => {
        state.places.push(place);
    },
    setObjectsOfCertainFloor: (state, value) => (state.objectsOfCertainFloor = value),
    filterFloorsIfPlacesFetched: (state, floor) => (state.objectsOfCertainFloor = (state.places.filter(place => place.floorId === floor))),
    setNewPlace: (state, value) => (state.newPlace = value),
    updateBuildingPlaces: (state) => {
        let b = [];
        let o = [];
        state.buildings = [];
        state.places.forEach(place => {
            if (place.floorId !== null) {
                b.push(place);    //place is a part of bulding Q
            } else {
                o.push(place);    //place is a part of exterior
            }
        });
        state.buildings.push({text: "Budova Q", value: b});
        state.buildings.push({text: "Exterier", value: o});
    },
    removeImage: (state, id) => state.placeImages = state.placeImages.filter(i => i.id !== id),
    setTotalItemCount: (state, count) => state.totalItemCount = count,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
