export default
{
  'entities': {
    'save': 'Uložit',
    'send': 'Odeslat',
    'add': 'Přidat',
    'edit': 'Upravit',
    'close': 'Zavřít',
    'czech': 'Čeština',
    'english': 'Angličtina',
    'yes': 'Ano',
    'no': 'Ne',
    'loading': 'Načítání...',
    'download': 'Stáhnout',
    'export': 'Exportovat',
    'list': 'Seznamy základních entit',
    'couldnt_save': 'Data se nepodařilo uložit.',
    'couldnt_save_server': 'Při ukládání nastala chyba serveru.',
    'back': 'Zpět',
    'entity_portal': 'Portál entit',
    'study_faculties' : 'Správa studijního oddělení',
    'required': 'Povinná položka',
    'confirm_deletion': 'Určitě chcete smazat tento záznam?',
    'successfull_request': 'Úspěšně odesláno',
    'result_not_found': 'Výsledek nenalezen',
    'no_data': 'Nepodařilo se nám získat žádná data, zkuste to prosím později.',
    'detail': 'Detail',
    'section_in_progress': 'Táto sekce se připravuje.',
    'loadAll': 'Načíst vše',
    'description_cz': 'Popis',
    'description_en': 'Description',
    'alert': 'Upozornit',
    'not_alert': 'Neupozornit',

    'study_types': {
      'weekly_type': 'Dálkové studium',
      'weekly_type_short': 'Dálkové',
      'daily_type': 'Prezenční studium',
      'daily_type_short': 'Prezenční',
      'other_type': 'Jiné',

      'Bachelor': 'Bakalářské studium',
      'Bachelor_short': 'Bakalářské',
      'Master': 'Magisterské studium',
      'Master_short': 'Magisterské',
      'Doctoral': 'Doktorské studium',
      'Doctoral_short': 'Doktorské',
      'Other': 'Jiné',

      'study': 'Studium',
    },

    'assistants': {
      //Edit
      'portal_name': 'Portál studijních referentek',
      'detail_study_ref': 'Detail studijní referentky',
      'ref_name': 'Jméno studijní referentky',
      'type_ref_name': 'Napište jméno studijní referentky.',
      'door_num': 'Číslo  kanceláře',
      'door_num_required': 'Uveďte číslo kanceláře.',
      'Xlogin': 'Login do UISu',
      'xlogin_required': 'Uveďte přihlašovací jméno do UISu, např. xpisarov',
      'Xlogin_type': 'UIS login, např. xpisarov',
      'ref_assign': 'Přiřazení studijní referentky',
      'no_study_ref_assigned': 'Bez přiřazení',
      'not_assigned_study_ref': 'U studijních oborů zatím není přiřazena studijní referentka.',
      'study_ref_not_assigned': 'Studijní referentka zatím nebyla přiřazena k žádným studijním oborům.',
      'remove_study_ref': 'Vymazat studijní referentku',
      'name_required': 'Uveďte jméno.',
      'name_too_short': 'Název musí být delší než 5 znaků.',
      'confirm_deletion': 'Určitě chcete smazat tento záznam?',
      'confirm_deletion_with_save': 'Určitě chcete smazat tento záznam? (Nutno následně uložit.)',
      'faculty_assign':'Přiřazení fakulty',
      "faculty": "Fakulta",
      "faculty_required": "Zvolte fakultu",
      //Portal
      'no_study_ref': 'Žádné studijní referentky',
      //New Assistant
      'new_study_ref': 'Nová studijní referentka',
    },

    'reasons': {
      //Edit
      'portal_name': 'Portál restrikcí',
      'reason_type': 'Důvod restrikce',
      'type_reason_type': 'Vyberte důvod restrikce.',
      'reason_name': 'Název restrikce',
      'type_reason_name': 'Napište název restrikce.',
      'reason_name_en': 'Anglický název restrikce',
      'type_reason_name_en': 'Napište anglický název restrikce.',
      'code_required': 'Uveďte kód.',
      'code_too_short': 'Kód musí být delší než 5 znaků.',
      'name_required': 'Uveďte jméno.',
      'name_too_short': 'Jméno musí být delší než 5 znaků.',
      'reasons_edit': 'Úprava důvodu restrikce',
      'reasons_detail': 'Detail důvodu restrikce',
      'confirm_deletion': 'Určitě chcete smazat tento záznam?',
      //Portal
      'no_reasons_set': 'Žádné důvody restrikcí',
      //New
      'new_reason': 'Nový důvod restrikce',
      'person': 'Osobní',
      'department': 'Oddělení',
      'reasons_delete': 'Vymazat restrikční důvod',
    },

    'studyfield': {
      //New
      'portal_name': 'Portál studijních oborů',
      'new_study': 'Nový studijní obor',
      'study_code': 'Kód oboru',
      'type_study_code': 'Napište kód oboru',
      'study_name': 'Název oboru',
      'type_study_name': 'Napište název oboru',
      'study_name_en': 'Název oboru – anglicky',
      'type_study_name_en': 'Napište název oboru',
      'study_deg': 'Stupeň studia',
      'type_study_deg': 'Zvolte stupeň studia',
      'study_form': 'Forma studia',
      'type_study_form': 'Zvolte formu studia',
      'target_languages': 'Zobrazit pouze v jazycích',
      'select_target_languages': 'Vyberte jazyky',
      'assign_ref' : 'Vyberte studijní referentku',
     'new_ref' : 'Přiřazení studijní referentky',

      //Edit
      'ref_edit': 'Úprava studijní referentky',
      'detail_edit': 'Úprava studijního oboru',
      'detail': 'Detail studijního oboru',
      'study_refs': 'Přiřazené studijní referentky',
      'no_study_refs': 'Nemáte přiřazené žádné studijní referentky',
      'delete_study_field': 'Vymazat studijní obor',
      'confirm_deletion': 'Určitě chcete smazat tento záznam?',
      //Portal
      'no_studyfield_refs': 'Žádné studijní obory.',
    },

    'openHours': {
      'portal_name': 'Portál otevíracích hodin',
      'openHours_edit_panel': 'Úprava otevíracích hodin pro dotykový panel',
      'openHours_edit_mobile': 'Úprava otevíracích hodin pro mobilní aplikaci',
      'from': 'od',
      'to': 'do',
      'pause': 'pauza',
      'description': 'Popis otevíracích hodin',
      'no_description' : 'Neobsahuje popis otevíracích hodin',
      'cz': 'Český popisek',
      'en': 'Anglický popisek'
    },

    'studyProblems': {
      'portal_name': 'Portál kategorií studijních požadavků',
      'new_study': 'Nová kategorie studijního požadavku',
      'category_name': 'Název kategorie',
      'type_category_name': 'Napište název kategorie.',
      'category_name_en': 'Anglický název kategorie',
      'type_category_name_en': 'Napište název kategorie.',
      'category_code': 'Kód kategorie',
      'type_category_code': 'Napište kód kategorie.',
      'study_type': 'Forma studia',
      'type_study_type': 'Zvolte formu studia.',
      //Edit
      'detail_edit': 'Úprava kategorie studijního požadavku',
      'category_detail': 'Detail kategorie studijního požadavku',
      'del_req': 'Vymazat studijní požadavek',
      'study_req': 'Studijní požadavky',
      'add_req': 'Přidat požadavek',
      'no_study_req': 'Zatím nemáte přiřazené žádné studijní požadavky.',
      'no_study_category': 'Žádné kategorie studijních požadavků.',
    },

    'studyItem': {
      'portal_name': 'Portál studijních požadavků',
      'studyItem_edit': 'Úprava kategorie studijního požadavku',
      'new_studyItem': 'Nový studijní požadavek',
      'req_name': 'Název požadavku',
      'type_req_name': 'Napište název požadavku',
      'req_name_en': 'Název požadavku – anglicky',
      'type_req_name_en': 'Napište anglický název požadavku.',
      'req_category': 'Kategorie požadavků',
      'type_req_category': 'Zvolte kategorii požadavků.',
      'req_code': 'Kód požadavku',
      'type_req_code': 'Napište kód požadavku.',
      'length': 'Odhadovaný čas trvání',
      'description': 'Popis',
      'type_description': 'Uveďte popis.',
      'description_en': 'Anglický popis',
      'type_description_en': 'Napište anglický popis.',
      //Edit
      'edit_study_req': 'Úprava studijního požadavku',
      'study_req_detail': 'Detail studijního požadavku',
      'del_req': 'Vymazat studijní požadavek',
      //Portal
      'no_study_req': 'Žádné studijní požadavky.',
    }
  },

  'checkIn': {
    //Dialogs
    'break': 'Přestávka',
    'new_break': 'Nová přestávka',
    'time': 'Přibližný čas nepřítomnosti:',
    'message': 'Zpráva, která se zobrazí čekajícím (nepovinné):',
    'stop_checkIn': 'Ukončit odbavování',
    'close': 'Zavřít',
    'start': 'Spustit',
    'checkIn_confirm': 'Určitě chcete ukončit odbavování?',
    'pick_study': 'Vyberte studijní referentku, ke které chcete požadavek',
    'pick_priority': 'prioritně delegovat',
    'pick_study_select': 'Vyberte studijní referentku',
    'no_go_back': 'Tato akce je nenávratná, kliknutím na delegovat souhlasíte se změnou',
    'no_go_back_cont': 'a budete přesměrováni na výběr požadavku.',
    //CheckIn
    'que_waiting': 'Ve frontě čekají:',
    'no_waiting': 'Ve frontě aktuálně nikdo nečeká.',
    //DidCome
    'being_served': 'Odbavován',
    'scheduled_time': 'Objednán na',
    'stop': 'Ukončit',
    'anon_student': 'Anonymní student',
    //DidNah
    'not_served': 'Neodbaven',
    //Fallback
    'waiting': 'Čekáme',
    'reset': 'Resetovat',
    'or': 'nebo',
    'no_waiting_hold': 'Ve frontě aktuálně nikdo není, čekáme na studenty.',
    //NextInLine
    'next_in_line': 'Je na řadě:',
    'doing': 'Vyřizuje se',
    'call_in': 'Vyvolat',
    //Pause
    'on_break': 'Běží přestávka.',
    'stop_break': 'Ukončit',
    'break_time': 'Přestávka již trvá',
    'break_duration': 'Přestávka byla zvolena na',
    'minutes': '{n} minutu | {n} minuty | {n} minut',
    //Start
    'calling': 'Vyvolávání',
    'start_message': 'Spuštěním se aktivuje vyvolávací panel, který vyzve první tiket v pořadí.',
    //Turn
    'wating_for': 'Čekáme na',
    'waiting_left': 'zbývá čekat',
    'student_did_not_come': 'Student nepřišel, můžete ještě počkat nebo přejít na vyvolání dalšího. ',
    'sec' : '{n} sekunda | {n} sekundy | {n} sekund',
    'arrived': 'Přišel',
    'hasnt_arrived': 'Nepřišel',
  },
  'reservations': {
    //Calendar
    'today': 'Dnes',
    'study': '{n} studijní | {n} studijní | {n} studijních',
    'personal': '{n} osobní | {n} osobní | {n} osobních',
    'res_calendar': '{n} rezervace | {n} rezervace | {n} rezervací',
    'calendar': 'Rezervační kalendář',
    'my_calendar': 'Moje Kalendáře',
    'Master': 'Magisterský',
    'Bachelor': 'Bakalářský',
    'Other': 'Jiný',
    'res_ref': 'Rezervace k vyřízení požadavků',
    'restrict_ref': 'Nahlášené restrikce v úředních hodinách',
    'restrict_pef': 'Úplné restrikce studijního oddělení PEF',
    //infobar
    'study_restrictions': 'Studijní restrikce',
    'no_study_restrictions_day': 'Na tento den nejsou nahlášené žádné restrikce studijního oddělení.',
    'personal_restrictions': 'Osobní restrikce',
    'no_personal_restrictions': 'Na tento den nemáte nahlášené žádné vlastní restrikce.',
    'reservations': 'Rezervace',
    'no_reservations': 'Na tento den nemáte nahlášené žádné rezervace',
    'show_history': 'Zobrazit historii',
    'hide_history': 'Skrýt historii',
    'add_reservation': 'Přidat rezervaci',
    'add_restriction': 'Přidat restrikci',
    'anon_student': 'Anonymní student',
    'we_closed': 'Máme zavřeno.',
    //Dialogs
    'choose_req': 'Zvolte požadavek',
    'pick_req_ref': 'Výběr požadavku ke studijní referentce',
    'pick_req_cat': 'Vyberte kategorii požadavku',
    'pick_confirm': 'Vyberte konkrétní potvrzení',
    'close': 'Zavřít',
    'add': 'Vložit',
    'pick_type_field': 'Zvolte typ studia a obor.',
    'pick_study_type': 'Zvolte typ studia',
    'pick_study_field': 'Zvolte studovaný obor',
    //Children
    'create_new_res': 'Vytvoření nové rezervace',
    'save': 'Uložit',
    'res_saved': 'Rezervace uložena',
    'res_saved_id': 'Rezervace je uložena pod identifikačním číslem:',
    'res_not_save': 'Rezervace nebyla uložena',
    'res_not_save_error': 'Při ukládání došlo k chybě. Zkontrolujte požadovaný čas a volné časové sloty.',
    'ok': 'OK',
    'if_you_want': 'Pokud skutečně chcete v tomto čase vytvořit rezervaci, zvolte Ano. V opačném případě zvolte Zrušit',
    'cancel_unsuccesful_modal': 'Zrušit',
    'yes': 'Ano',
    'res_date': 'Datum rezervace',
    'pick_res_date': 'Vyberte datum',
    'res_person': 'Student/Klient',
    'type_res_person': 'Napište jméno a příjmení.',
    'xname': 'UIS login',
    'type_ticket': 'Stav požadavku',
    'assigned_assistant': 'Přiřazená studijní referentka',
    'type_xname': 'Napište UIS login.',
    'res_stud_field': 'Typ studia a obor',
    'request': 'Požadavek',
    'add_request': 'Přidat požadavek',
    'timespan_request': 'Časové vymezení vyřízení',
    'timespan_default_time': 'Zvolte čas/výchozí trvání události',
    'time_length': 'Přibližná délka trvání:',
    'time_from': 'Čas od',
    'untill': 'do',
    'time_to': 'Čas do',
    'req_note': 'Soukromá poznámka',
    'req_note_no_stud': 'Nezobrazuje se studentovi',
    'timeslots_free': 'Volné časové sloty',
    'timeslots_from': 'od',
    'timeslots_to': 'do',
    'timeslots_no_free': 'Nemáte žádné volné sloty',
    'name_required': 'Uveďte jméno',
    'name_length': 'Jméno musí být delší než 2 znaky.',
    'date_required': 'Zadejte datum.',
    'xname_format': 'Login musí mít aspoň 2 znaky, pouze malá písmena a čísla.',
    'time_required': 'Zadejte čas.',
    //Detail
    'res_detail_calendar': 'Detail rezervace v rezervačním kalendáři',
    'edit': 'Upravit',
    'ticket_id': 'Tiket ID',
    'res_status': 'Status rezervace',
    'assistant': 'Přiřazená studijní referentka',
    'not_served': 'Neodbaven',
    'cancel_user': 'Zrušen uživatelem',
    'cancel_study_ref': 'Zrušen studijní referentkou',
    'served': 'Odbaven',
    'next_in_line': 'Na řadě',
    'no_notes': 'Nemáte žádnou poznámku',
    'other': 'Jiné',
    //Edit
    'res_editing': 'Úprava vytvořené rezervace',
    'res_changed': 'Rezervace byla změněna.',
    'res_not_saved': 'Úprava rezervace nebyla uložena.',
    'res_couldnt_saved': 'Rezervaci se nepodařilo uložit.',
    'res_delete': 'Vymazat rezervaci',
    'confirm_deletion': 'Určitě chcete smazat tento záznam?',
    //Restrictions
    'restrict_calendar': 'Vytvoření nové restrikce v kalendáři rezervací',
    'restrict_agend': 'Agenda, které se restrikce týká',
    'person': 'Osoba',
    'stud_dep_pef': 'Studijní oddělení PEF',
    'restrict_reason': 'Důvod restrikce',
    'restrict_date': 'Datum restrikce',
    'restrict_time': 'Časová platnost restrikce',
    'whole_day': 'Celá úřední doba',
    'specific_timespan': 'Konkrétní časové vymezení',
    'experiment': 'Pozor, experimentální funkce! Nedoporučujeme mít více omezení ve stejné nebo překrývající se časy.',
    'restrict_meetings': 'Rezervované schůzky, kterých se restrikce týká',
    'no_restrict_meetings': 'Žádné rezervace ve vybraném termínu',
    'item_required': 'Prvek je požadován.',
    'longterm_action': 'Dlouhodobá akce',
    'delegation': 'Delegovat na:',
    'delegation_to': 'Delegováno na',
    //Detail
    'restrict_detail': 'Detail restrikce v rezervačním kalendáři',
    'restrict_reason_change': 'Důvod změny',
    'cancel': 'Zrušit',
    'delegate': 'Delegovat',
    'study_department': 'Studijní oddělení',
    //Edit
    'restrict_edit': 'Úprava vytvořené restrikce',
    'restrict_delete': 'Vymazat restrikci',
    'restriction_saved': "Restrikce upravena",
    'restriction_changed_succ': "Restrikce byla změněna.",
    'restriction_not_saved': "Restrikce nebyla uložena.",
    'restriction_not_save_error': "Při ukládaní došlo k chybě."

  },
  'queueManagement': {
    not_an_assistant: 'Máte práva pro přístup k modulu, ale zatím jste nebyl(a) nastavena jako asitent(ka) vedoucím studijního oddělení.',
    'reservations': 'Rezervace',
    'checkins': 'Odbavování',
    'stats': 'Statistiky',
    'default_entities': 'Základní entity',
  },

  'support': {
    'headline': 'Problémy a nápady',
    'message_type_input': 'Typ zprávy',
    'email_input': 'E-mail',
    'description_input': 'Popis',
    'image_drop_text': 'Přetáhněte fotky pro nahrání',
    'urgent_checkbox': 'Řešení spěchá (označte jen pokud jde o naléhavý požadavek)'
  },

  'gamification': {
    'have_question': 'Mám otázku',
    'gamification_items': 'Administrace gamifikačních prvků',
    'gamification_items_subheading': 'Spravujte gamifikační prvky pro studenty',
    'dashboard_welcome_title': 'Vítejte v modulu testování a gamifikace!',
    'dashboard_welcome_subtitle': 'Vytvořte pro své studenty vědomostní testy.',
    'launch': 'Spuštění',
    'users_heading': 'Uživatelé',
    'search_for_users': 'Hledat uživatele',
    'login': 'Login',
    'scored_points': 'Dosažené body',
    'number_of_points': 'Počet bodů',
    'type_of_question': 'Typ otázky',
    'one_right_answer': 'Jedna správná odpověď',
    'multiple_right_answers': 'Více správných odpovědí',
    'true_false': 'Pravda/Nepravda',
    'date_sent_test': 'Datum odeslání testu',
    'cannot_edit_when_active': 'Úprava nebo mazání není možné při aktivovaném testu.',
    'to_subjects_btn': 'Přejít na předměty',
    'test_name': 'Název testu',
    'course': 'Kurz',
    'category': 'Kategorie',
    'created_by': 'Vytvořil',
    'created': 'Vytvořeno / naposledy upraveno',
    'hide_test': 'Skrýt test',
    'show_test': 'Zobrazit test',
    'list_of_tests': 'Seznam testů',
    'add_test': 'Přidat test',
    'delete_test': 'Smazat test',
    'test_detail': 'Detail testu',
    'edit_test': 'Úprava testu',
    'test_activated': 'Test aktivován',
    'test_deactivated': 'Test deaktivován',
    'warning_edit': 'Chcete-li test upravit, musíte jej nejprve deaktivovat',
    'questions1': 'otázka',
    'questions2': 'otázky',
    'questions3': 'otázek',
    'points1': 'bod',
    'points2': 'body',
    'points3': 'bodů',
    'tutorial_heading': 'Návod k používání',
    'first_card_headline': '1. Přidání předmětu',
    'second_card_headline': '2. Přidání testu',
    'third_card_headline': '3. Aktivování testu',
    'assign_question': ' + PŘIŘADIT OTÁZKU',
    'no_questions': 'Test neobsahuje žádné otázky.',
    'deactivate_to_edit': 'Pro úpravu nebo mazání deaktivujte test',
    'show_correct_answers': 'Zobrazit studentům správné výsledky',
    'activate_test': 'Aktivace testu',
    'test_name_filled': 'Vyplněn název testu v obou jazycích',
    'test_category_selected': 'Zvolena kategorie testu',
    'test_questions_filled': 'Vyplněny testové otázky',
    'really_delete': 'Opravdu chcete smazat tento test?',
    'first_card_paragraph': 'Vytvořte předměty, které vyučujete. Zadejte loginy všech zaměstnanců, kteří budou předmět spravovat.',
    'second_card_paragraph': 'Vytvořte vědomostní test, zadejte otázky a označte správné odpovědi.',
    'third_card_paragraph': 'Test je na začátku deaktivován. To znamená, že ho můžete upravovat, ale studenti ho neuvidí v aplikaci.\n' + 'Po aktivování testu si ho studenti můžou spustit v mobilní aplikaci.',
    'qr_code': 'QR kód testu',
    'badges_headline': 'Odznaky',
    'activities_headline': 'Aktivity',
    'levels_headline': 'Levely',
    'image': 'Obrázek',
    'badge_name': 'Název odznaku',
    'activity_name': 'Název aktivity',
    'cz_category_name': 'Název kategorie v češtině',
    'en_category_name': 'Název kategorie v angličtině',
    'cz_badge_name': 'Název odznaku v češtině',
    'en_badge_name': 'Název odznaku v angličtině',
    'cz_activity_name': 'Název aktivity v češtině',
    'en_activity_name': 'Název aktivity v angličtině',
    'cz_activity_name_description': 'Název popisku aktivity v češtině',
    'en_activity_name_description': 'Název popisku aktivity v angličtině',
    'new_category': 'Nová kategorie',
    'edit_category': 'Úprava kategorie',
    'edit_badge': 'Úprava odznaku',
    'edit_activity': 'Úprava aktivity',
    'not_filled_answers_fields': 'Otázka musí mít alespoň dvě vyplněné odpovědi a alespoň jedna odpověď musí být správná!',
    'answers_info': 'Pokud se nejedná o otázku typu pravda/nepravda, můžete přidat další odpověď. Odpovědi můžete odstranit pouze v případě, že otázka obsahuje více než 2 odpovědi.',
    'QR_code_dialog_info': 'Kód QR slouží k načtení mobilní aplikace.',
    'test_info': 'Chcete-li test uložit, musíte vyplnit potřebné informace o testu. Informace jsou vyplněny správně, pokud se barva varování změní z červené na zelenou a pokud se nezobrazí vykřičník.',
    'language_test_info': 'Pokud se vedle jazyka zobrazí vykřičník, znamená to, že některé údaje nejsou vyplněny.',
    'test_activation_info': 'Aktivujte test, pokud má být studentům k dispozici. Pokud je test aktivován, nelze jej upravovat. Nejprve je nutné jej deaktivovat.',
    'QR_code_info': 'Kód QR slouží k načtení mobilní aplikace. Chcete-li zobrazit QR kód ve větší velikosti, klikněte na obrázek QR kódu.',
    'questions_info': 'V této části jsou zobrazeny otázky, které jsou v testu vloženy. Můžete přidat novou otázku, existující otázky nebo upravit otázky v testu.',
    'users_info': 'Abychom mohli uživatele přiřadit k testu, musíme zapsat jeho přesné přihlašovací jméno z UIS ve tvaru xlogin. Například "xgona".',
    'question_dialog_info': 'Otázky, které v testu nejsou, jsou zobrazeny zde.',
    'insert_login': 'Zadejte login uživatele, kterému chcete povolit upravovat obsah předmětu.',
    'category_info': 'Kategorie mohou být vytvořeny a upraveny pouze administrátorem. Nejprve je třeba vytvořit kategorii v sekci předmětu a poté můžete vytvořit test.',
    'no_questions_to_show': 'Žádné otázky k zobrazení',
    'input_warning': 'Zadejte název pro oba jazyky',
    'test_questions': 'Testové otázky',
    'test_question': 'Testová otázka',
    'name_of_question': 'Obsah otázky',
    'add_new_answer': 'PŘIDAT další odpověď',
    'new_question': 'Nová otázka',
    'what_you_choose': 'Výběrem bunky označíte právě 1 správnou odpověd testu.',
    'correct': 'Správná (CZ)',
    'wrong': 'Špatná (CZ)',
    'correctEN': 'Správná (EN)',
    'wrongEN': 'Špatná (EN)',
    'not_filled_question_fields': 'Otázka musí mít alespoň dvě vyplněné odpovědi, alespoň jedna odpověď musí být správná a všechny odpovědi musí mít text v polích vyplněný v obou jazycích!',
    'question_help_text': 'Při vytváření dotazů se můžete pohybovat pomocí kláves Tab, Shift+Tab, Mezerník a Enter.',
    'question_saved_successfully': 'Úspěšné uložení otázky',
  },

  'headers': {
    //Global
    'action': 'Akce',
    'edit': 'Upravit',
    'show': 'Zobrazit/Skrýt',
    'name': 'Jméno',
    'room': 'Místnost',
    //AssistantDetail - StudyFieldPortal
    'field_code': 'Kód oboru',
    'field_name': 'Název oboru',
    'field_name_en': 'Anglický název oboru',
    'study_deg': 'Stupeň studia',
    'study_form': 'Forma studia',
    'study_deg_form': 'Stupeň a forma studia',
    'assign': 'Přiřazení',
    //AssistantPortal
    'xlogin': 'UIS Login',
    'studyFieldIds': 'Přiřazené obory',
    'studyProblemItemIds': 'Přiřazené požadavky',
    'studyFaculty': 'Fakulta',
    //ReasonsPortal
    'restrict_reason': 'Důvod restrikce',
    'restrict_name': 'Název restrikce',
    'restrict_name_en': 'Anglický název restrikce',
    //StudyProblemsCategoryDetail
    'title': 'Název',
    'title_en': 'Anglický název',
    'code': 'Kód',
    'approx_time': 'Přibližný čas',
    //StudyProblemsItemPortal
    'category': 'Kategorie',
    'approx_time_mins': 'Přibližný čas (v minutách)',
    //PauseDialog
    'five_min': 'do 5 minut',
    'ten_min': 'do 10 minut',
    'fifteen_min': 'do 15 minut',
    //CheckInTable
    'ticket_id': 'Tiket ID',
    'request': 'Požadavek',
    'assistant': 'Studijní referentka',
    'time': 'Čas',
    'priority_action': 'Prioritní akce',
    //NewRestriction
    'date': 'Datum',
    'student': 'Student',
    'options': 'Možnost',
    'delegate': 'Delegováno',
    'sub_time': 'Náhradní čas',
    //EntitiesPortal
    'study_req_category_and_requests': 'Studijní požadavky a kategorie',
    'study_req_category_and_requests_text' : 'Přehled studijních požadavků a jejich kategorii',
    'open_hours': 'Otevírací hodiny',
    'open_hours_text' : 'Otevírací hodiny studijního oddělení a jejich úprava',
    'restrict_reasons': 'Důvody restrikce',
    'restrict_reasons_text' : 'Přehled důvodů restrikcií a jejich úprava ',
    'study_fields': 'Studijní obory',
    'study_fields_text' : 'Přehled studijních oborov a jejich úprava',
    'study_requests': 'Studijní požadavky',
    'statistics' : 'Statistiky',
    'statistics_text' : 'Statistiky rezervací a dalších dat studijního oddělení',
    'study_refs': 'Studijní referentky',
    'study_refs_text' : 'Přehled aktuálních studijních referentek a možnosti úpravy',
    'target_languages': 'Pro jazyky',
    'ref_count' : 'Počet referentek',
    'today_open' : 'Dnes otevřeno',
    'today_close' : 'Dnes zavřeno',
    'pause' : 'Pauza',
    'reservation_count' : 'Rezervace dnes celkem',

  },

  'days': {
    'Monday': 'Pondělí',
    'Tuesday': 'Úterý',
    'Wednesday': 'Středa',
    'Thursday': 'Čtvrtek',
    'Friday': 'Pátek',
    'Saturday': 'Sobota',
    'Sunday': 'Neděle',
  },

  'months': {
    'January': 'leden',
    'February': 'únor',
    'March': 'březen',
    'April': 'duben',
    'May': 'květen',
    'June': 'červen',
    'July': 'červenec',
    'August': 'srpen',
    'September': 'září',
    'October': 'říjen',
    'November': 'listopad',
    'December': 'prosinec'
  },

  'statistics': {
    'period': 'období',
    'interval': {
      'day': 'Posledních 24 hodin',
      'week': 'Poslední týden',
      'month': 'Poslední měsíc',
      'quarter': 'Poslední 3 měsíce',
      'half_year': 'Posledních 6 měsíců',
      'year': 'Poslední rok',
    },

    'acceptableUnits': {
      'hour_of_day': 'hodiny dne',
      'day_of_week': 'dny týdne',
      'day_of_month': 'dny měsíce',
      'week_of_month': 'týdny roku',
      'month_of_year': 'měsíce roku'
    },

    'show': 'Zobrazit',
    'enter': 'Vstoupit',
    'time_period': 'časové období',
    'busiest': 'nejvíce vytížené',

    'alert_info': {
        'period': 'Celkový počet rezervací za zvolené období',
        'busiest': 'Úhrn pracovní zátěže ve zvoleném období'
    },

    'detail_overview': 'Detailní přehled',
    'occupation': 'Vytíženost studijního oddělení',
    'reservation_total': 'Celkový počet rezervací',

    'reservation_count': 'Počet rezervací',

    'day_of_month': 'den v měsíci',

    'between': 'Mezi',
  },

  'confirms': {
    "delete_succesful": "Smazání proběhlo úspěšně.",
    "delete_unsuccesful": "Při smazání došlo k chybě."
  },

  errors: {
    insufficient_permissions: 'Nemáte práva, kontaktujte prosím vedoucího pracovníka.',
  },

  'map': {
    'generator': {
      'map': 'Mapa',
      'alert': 'Vyberte, místo které chcete zobrazit z nabídky nebo na něj klikněte v mapě.',
      'building_select': 'Oblast',
      'floor_select': 'Podlaží',
      'place_select': 'Místo',
      'iframe_code': 'Kód na vaše stránky',
      'copy': 'Kopírovat',
      'save_as_image': 'Stáhnout jako obrázek',
      'link_to_map': 'Odkaz na mapu',
      'open_map_tab': 'Otevřít mapu v novém okně',
      'hide_HTML_code': 'Skrýt HTML kód',
      'show_HTML_code': 'Zobrazit HTML kód',
      'map_is_generating': 'Mapa se generuje',
      'bad_url': 'Zadané parametry URL obsahují chybu a mapu nelze zobrazit.',
      'contact': `<a class="white--text" href="https://moje.mendelu.cz/kontakt">Kontaktujte nás prosím.</a>`,
      'not_negative': 'Záporná velikost',
      'not_found': (ctx) => `Místo <code>${ctx.named('placeName')}</code> nebylo nalezeno.`,
      'bigger_map': 'ZVĚTŠIT MAPU',
    },
    'defaults': {
      'exterior': 'Exteriér',
      'no_name': 'Bez názvu'
    },
    'legend': {
      'building_name': (ctx) => `Budova ${ctx.named('building')}`,
      'floor_name': (ctx) => `, ${ctx.named('floor')}. patro`,
    }
  },

  'places': {
    'types': {
      'department': 'Oddělení',
      'office': 'Kancelář',
      'utility_room': 'Sklad',
      'study_room': 'Studovna',
      'cafeteria': 'Kantýna',
      'terrace': 'Terasa',
      'ticket_machine': 'Automat na lístky',
      'food_machine': 'Jídelní automat',
      'indoor_building': 'Vnitro budovy',
      'empty': 'Prázdný prostor',
      'transportation_stop': 'Zastávka MHD',
      'garden': 'Zahrada',
      'storage': 'Sklad',
      'boardroom': 'Zasedací místnost',
      'dormitory': 'Koleje',
      'water_machine': 'Pitná voda',
      'toilet_female': 'Dámské toalety',
      'floor': 'Podlaha',
      'gatehouse': 'Vrátnice',
      'toilet': 'Toaleta',
      'engine_room': 'Strojovna',
      'computer_kiosk': 'Počítačový kiosek',
      'sink': 'Dřez',
      'emergency_floor': 'Nouzový východ',
      'classroom_and_laboratory': 'Učebna a laboratoř',
      'copier': 'Kopírka',
      'outline': 'Obvod',
      'locker': 'Skříňka',
      'shaft': 'Šachta',
      'ground': 'Přízemí',
      'coatroom': 'Šatna',
      'stairs': 'Schody',
      'elevator': 'Výtah',
      'toilet_male': 'Pánské toalety',
      'walk': 'Chodník',
      'parking': 'Parkoviště',
      'wall': 'Zeď',
      'archive': 'Archiv',
      'cafe_machine': 'Kávový automat',
      'bistro': 'Bistro',
      'kitchen': 'Kuchyně',
      'garage': 'Garáž',
      'gate': 'Brána',
      'building': 'Budova',
      'library': 'Knihovna',
      'emergency_stairs': 'Nouzové schody',
      'trashcan': 'Odpadkový koš',
      'toilet_handicapped': 'Toaleta pro handicapované',
      'room': 'Pokoj',
      'pizzeria': 'Pizzerie',
      'publishing_house': 'Vydavatelství',
      'wine_shop': 'Vinárna',
      'garbage': 'Odpadky',
      'base': 'Obvod patra',
    }
  },

  'iotSensors': {
    'dashboardTitle': 'Smart University',
    'detail_back_button':'Seznam místností',
    'search': 'Jakou místnost hledáte?',
    'measurement_headline': 'Aktuální podmínky',
    'measurement_second_headline': 'Dostupný hardware',
    'degree_celsius': '°C',
    'free_pcs': 'Volné počítače',
    'projector': 'Projektor',
    'whiteboard': 'Bílá tabule',
    'floor': 'patro',
    'open': 'Otevřená',
    'close': 'Zavřená',
    'measurement_tech_list': 'Seznamy technologií',
    'pc_list': 'Seznam počítačů',
    'pc_occupied': 'Obsazený',
    'pc_not_occupied': 'Volný',
    'software_list': 'Seznam software',
    'search_software': 'Jaký software hledáte?',
    'search_pc': 'Jaký počítač hledáte?',
    'search_category': 'Jakou kategorii hledáte?',
    'no_match_for_software': 'Je nám líto, zde jsme žádný software nenašli. Zkuste vyhledat jiný.',
    'no_match_for_computer': 'Je nám líto, zde jsme žádný počítač nenašli. Zkuste vyhledat jiný.',
    'roomTypes': {
      'all_rooms': 'Všechny',
      'classroom_and_laboratory': 'Učebny a laboratoře',
      'study_room': 'Studovny',
      'pc_rooms': 'Místnosti s počítači',
    },
    'technology_types': {
      'pc_list': 'Seznam počítačů',
      'software_list': 'Seznam software',
    },
    'measurements': {
      'HUMIDITY': 'Vlhkost',
      'NOISE': 'Hluk',
      'LIGHT_INTENSITY': 'Intenzita světla',
      'OCCUPANCY': 'Obsazenost',
      'WINDOW_STATE': 'Okna',
      'TEMPERATURE': 'Teplota',
    },
    'measurement_icons': {
      'HUMIDITY': 'opacity',
      'NOISE': 'record_voice_over',
      'LIGHT_INTENSITY': 'emoji_objects',
      'OCCUPANCY': 'groups',
      'OCCUPANCY_PCS': 'meeting_room',
      'WINDOW_STATE': 'window',
      'TEMPERATURE': 'thermostat',
    },
    'alert': {
      'success': 'Hodnoty jsou v normě, budete se zde cítit dobře',
      'warning': 'Některé hodnoty nejsou výborné',
      'error': 'Hodnoty v této místnosti NEJSOU vhodné',
    }
    },
    'news': {
      "faculties": "Fakulty a součásti",
      "faculties_subtitle": "Vyberte fakultu nebo součást, pro kterou chcete spravovat informační panel",
      "no_faculties_permissions_text" : "Je nám líto, momentálně nemáte priřazená práva k žádným součastem.",
      "no_faculties_permissions_title" : "Žádná práva k součástem",
      "list_faculties": "Seznam součástí",
      "no_news_text" : "Zatím zde nebyli přidány žádné novinky.",
      "no_news_title" : "Žádné novinky",
      "news_title": "Nadpis novinky",
      "new_post": "Nový příspěvek",
      "posts": "Příspěvky",
      "concept": "Koncept",
      "published": "Uveřejněn",
      "archived": "Archivován",
      "users_and_permissions" : "Uživatelé a oprávnění",
      "users_and_permissions_subtitle" : "Vyberte uživatele, kterého chcete spravovat",
      "users_and_permissions_administration" : "Správa uživatelů a oprávnění",
      "new_user": "Nový uživatel",
      "news" : "Novinky",
      "uploading" : "Nahrávání příspěvku",
      "uploaded": "Nahráno",
      "additional_info": "Doplňující informace",
      "uploading_title" : "Nahrávání titulku",
      "uploading_additional" : "Nahrávání doplňkových obrázků",
      "no_users_text" : "Je nám líto, zde momentálně nejsou žádní uživatelé.",
      "no_users_title" :"Žádní uživatelé",
      "saved" : "Uloženo",
      "users_list": "Seznam uživatelů",
      "faculties_permissions": "Práva k součástem univerzity",
      "audiences_permissions": "Práva k cílovým skupinám",
      "remove_user": "ODSTRANIT UŽIVATELE",
      "something_went_wrong_title" : 'Něco se polámalo',
      "something_went_wrong_text" : 'Je nám líto, něco se pokazilo. Zkuste znovu načíst stránku.',
      "news_list": "Seznam příspěvků",
      "post": "Příspěvek",
      "additional_images": "Doplňkové obrázky",
      "images_title": "Obrázky",
      "further_information" : "Další informace",
      "links": "Odkazy",
      "contact_data": "Kontaktní údaje",
      "post_targeting": "Cílení příspěvku",
      "post_topic": "Téma příspěvku",
      "target_groups": "Cílové skupiny",
      "target_parts": "Cílové současti",
      "publication": "Uveřejnění",
      "publish_post": "Uveřejnit příspěvek",
      "archive_post": "ARCHIVOVAT PŘÍSPĚVEK",
      "archived_post": "ARCHIVOVÁNO",
      "post_is_published": "Příspěvek je uveřejněn",
      "post_is_not_published": "Příspěvek zatím nebyl uveřejněn",
      "post_is_archived": "Příspěvek je archivován",
      "update_post":"UPRAVIT PŘÍSPĚVEK",
      "add_contact": "Přidat kontakt",
      "edit_contact": "Upravit kontakt",
      "name_contact" : "Název kontaktu",
      "add_link": "Přidat odkaz",
      "edit_link": "Upravit odkaz",
      "name_link": "Název odkazu",
      "time_range_publication": "Časový rozsah uveřejnění (nepovinné)",
      "save_concept":"Uložit koncept",
      "textual": "Textový",
      "poster": "Plakát",
      "place": "Místo konání (nepovinné)",
      "placeEN": "Místo konání v angličtině",
      "detail_hint": "Pokud zadáváte vlastní název místa konání, musíte jej zadat také v angličtině. Chcete-li zadat čas novinky, musíte nejprve zadat datum novinky.",
      "date": "Datum konání (nepovinné)",
      "time": "Čas konání (nepovinné)",
      "something_went_wrong" : "Něco se pokazilo, zkuste to znovu.",
      "post_name":"Název příspěvku",
      "description":"Popis",
      "date_from": "Datum od",
      "date_to" : "Datum do",
      "enter": "VSTOUPIT",
      "what_post": "Jaký příspěvek hledáte?",
      "topic": "Téma",
      "part": "Součást",
      "target_group": "Cílová skupina",
      "edit": "UPRAVIT",
      "user_xlogin" : "xlogin uživatele",
      "user_name": "Jméno uživatele",
      "who_you_looking_for": "Koho hledáte?",
      "save": "Uložit",
      "cancel": "Zrušit",
      "post_hint":"Pro vytvoření příspěvku je nutné zadat název a popis v obou jazycích, vybrat alespoň jedno téma příspěvku a specifikovat cílovou skupinu a cílovou součast.",
      "user_exist": "Zadaný uživatel již existuje.",
      "delete_user": "Opravdu chcete smazat užívatele",
      "delete": "Opravdu chcete smazat",
      "statistics": "Statistiky",
      "statistics_subtitle": "Počty uživatelů, kterým se zobrazí novinky pro danou skupinu",
      "total_user" : "Uživatelů celkem",
      "total_user_text" : "Celkový počet uživatelů využívajících modul novinky",
      "audience_overview" : "Přehled skupin",
      "faculty_overview" : "Přehled fakult",
      "all_users" : "všech uživatelů",
      "no_data":"Žádná data",
      "hint_dialog": "Odkazy zadávejte ve formátu \"adresa.com\".",
      "placeholder_dialog": "adresa.com",
      "no_contacts_text": "Zatím nebyli přidány žádné kontaktní údaje.",
      "no_contacts_title": "Žádné kontakty.",
      "no_links_text": "Zatím nebyli přidány žádné odkazy.",
      "no_links_title": "Žádné odkazy.",
      "title_image": "Titulní obrázek",
      "confirm_deletion_img": "Opravdu chcete smazat tento obrázek natrvalo?",
      "confirm_cancel_upload": "Kliknutím na tlačítko 'OK' se smažou všechny obrázky, které právě nahráváte.",
      "info_images": "Doporučená velikost nahrávaných obrázků je do 5 MB, maximální limit je pak 15 MB. Pokud je vaše připojení pomalé, může ukládání obrázků trvat déle.",
      "info_images_upload": "Pokud nahrávání trvá příliš dlouho, zkontrolujte rychlost internetového připojení a velikost obrázku.",
      "notification_dialog_description": "Všem uživatelům mobilní aplikace Moje MENDELU bude zasláno oznámení. Pokud jste si jisti, že chcete uživatele znovu upozornit na tuto novinku, klikněte na tlačítko „Upozornit”. Pokud chcete upravit novinku bez upozornění uživatelů, klikněte na „Neupozornit”.",
      "notification_dialog_title": "Uložit a znovu upozornit uživatele?",
    },
    'warnings': {
      "statistics": "Statistiky",
      "statistics_subtitle": "Počty uživatelů, kterým se zobrazí důležité zprávy pro danou skupinu",
      "total_user" : "Uživatelů celkem",
      "total_user_text" : "Celkový počet uživatelů využívajících modul důležitých zpráv",
      "audience_overview" : "Přehled skupin",
      "all_users" : "všech uživatelů",
      "no_data": "Žádná data",
      "warnings": "Důležité zprávy",
    },

    'markdown': {
      "link_address_heading": "Adresa odkazu",
      "link_description": "Popis odkazu",
      "save_link": "Uložit odkaz",
      "final_markdown": "Výsledné formátování v nástroji Markdown",
      "tutorial_description1": "- Pro odsazení řádku je třeba zadat 2 mezery nebo prázdný řádek.",
      "tutorial_description2": "- Pro zrušení formátování je nutné znovu označit celý formátovaný úsek a znovu stisknout tlačítko, jehož stylování chcete zrušit.",
      "tutorial_description3": "- Pro rychlejší formátování můžete použít klávesové zkratky jako Ctrl + B pro tučné písmo nebo Ctrl + I pro kurzívu",
      "no_text_selected": "Nebyl vybrán žádný text",
      "incorrect_address": "Neplatná adresa",
      "example_link_address": "https://odkaz.cz",
      "bold": "Tučné",
      "italic": "Kurzíva",
      "strike": "Přeškrtnuté",
      "link": "Odkaz",
      "subheading": "Podnadpis",
    }

}
