<template>
    <v-container class="ma-0 pa-0">
        <v-row v-if="input.length !== 0">
            <v-col cols="12">
                <v-list class="mb-2">
                    <v-list-item two-line v-for="(text, index) in input" :key="index" style="border: 2px solid #eeeeef">
                        <v-icon color="#6D7278" class="mr-5">dehaze</v-icon>
                        <v-list-item-title class="text">
                            {{ text }}
                        </v-list-item-title>

                        <v-btn icon text class="mr-5 ml-8" @click="$emit('editCard',text, index)" data-cy="edit-item">
                            <v-icon color="#6D7278" size="27">edit</v-icon>
                        </v-btn>

                        <v-btn icon text class="mr-3" @click="$emit('deleteCard',text, type, index)" data-cy="delete-item">
                            <v-icon color="red" size="27">delete_outline</v-icon>
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <PlaceHolder :path="'/icons/no_items.png'"
                             :text="getText"
                             :title="getTitle"
                             :width="350"
                             :height="200"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>


import PlaceHolder from "@/components/news/PlaceHolder";

export default {
    name: "NewsfeedCards",

    components: {
        PlaceHolder
    },

    props: {
        input: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            required: true
        },
    },

    computed: {
        getText() {
            return this.type === 'kontakt' ? this.$t('news.no_contacts_text') : this.$t('news.no_links_text');
        },
        getTitle() {
            return this.type === 'kontakt' ? this.$t('news.no_contacts_title') : this.$t('news.no_links_title');
        }
    }

}
</script>

<style scoped>

.text {
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

</style>
