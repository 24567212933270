import moment from 'moment';

export const announcementsHeaders = [
  {
    text: "Titulek",
    align: "left",
    sortable: true,
    value: "title",
    width: 200
  },
  {
    text: "Typ oznámení",
    align: "center",
    sortable: false,
    value: "announcementTypeId",
    width: 75
  },
  {
    text: "Stále viditelné",
    align: "center",
    sortable: false,
    value: "isPermanent",
    width: 50
  },
  {
    text: "Paralelně zobrazované",
    align: "center",
    sortable: false,
    value: "blockParallel",
    width: 50
  },
  {
    text: "Období zobrazování",
    align: "center",
    sortable: false,
    value: "displayTo",
    width: 90
  },
  {
    text: "Akce",
    align: "center",
    sortable: false,
    value: "action",
    width: 120
  }
];

export const daysOfWeek = (displayFrom, displayTo) => {
  const days = [
    { name: 'Po', index: 1 },
    { name: 'Út', index: 2 },
    { name: 'St', index: 3 },
    { name: 'Čt', index: 4 },
    { name: 'Pá', index: 5 },
    { name: 'So', index: 6 },
    { name: 'Ne', index: 7 }
  ];
  if (!displayTo) return days;
  let beginDay = moment.unix(displayFrom).weekday();
  const diff = moment.unix(displayTo).diff(moment.unix(displayFrom), 'days')+1;
  if (diff >= 7) return days;
  let i;
  let locDays = [];
  for (i = 0; i < diff; i++) {
    locDays.push(days[beginDay]);
    if (beginDay === 6) {
      beginDay = 0;
    } else beginDay++;
  }
  return locDays;
};
