import { get,} from '@/services/api'

const state = {
    didLoadedClosedHoursReasons: false,
    closedHoursReasons: [],
};

const getters = {
    didLoadedClosedHoursReasons: state => state.didLoadedClosedHoursReasons,

    getClosedHoursReasons: state => state.closedHoursReasons,

    getClosedHoursReason: state => id => {
        return state.closedHoursReasons.find(closedHoursReasons => closedHoursReasons.id === id);
    },

    getTypeClosedHoursReasons: state => type => {
        return state.closedHoursReasons.filter(element => element.type === type);
    },

    areClosedHoursReasonsFilled: state => {
        return state.closedHoursReasons.length !== 0;
    }
};

const actions = {

    //Fetch ALL
    async fetchClosedHoursReasons({ commit }) {
        commit('setDidLoadedClosedHoursReasons', false);
        await get('study_department/assistant/closed_hours_reasons/')
            .then((response) => {
                commit('setClosedHoursReasons', response.data.items);
                commit('setDidLoadedClosedHoursReasons', true);
            });
    },

    setClosedHoursReasonsStorage({commit}, items) {
        commit('setClosedHoursReasons', items);
    },
};

const mutations = {
    setDidLoadedClosedHoursReasons: (state, value) => (state.didLoadedClosedHoursReasons = value),
    setClosedHoursReasons: ( state, closedHoursReasons ) => (state.closedHoursReasons = closedHoursReasons),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
