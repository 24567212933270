var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.didLoadedAll === false)?_c('Loading'):_c('v-container',{attrs:{"fluid":""}},[_c('back-button',{attrs:{"target":{name: 'EntitiesPortal'}}},[_vm._v(_vm._s(_vm.$t('entities.entity_portal')))]),_c('module-header',[_c('h2',[_vm._v(_vm._s(_vm.$t('entities.studyItem.portal_name')))]),_c('v-spacer'),(_vm.isStudyAdmin)?_c('primary-button',{staticClass:"ml-5",on:{"click":function($event){return _vm.goToNew()}}},[_vm._v("+ "+_vm._s(_vm.$t('entities.add')))]):_vm._e()],1),_c('v-btn',{staticClass:"mx-0 mb-5",staticStyle:{"color":"#65a61a"},attrs:{"text":""},on:{"click":function($event){return _vm.goToCategories()}}},[_vm._v(" Spravovat kategorie")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.customHeaders,"items":_vm.notArchivedItems,"disable-pagination":true,"hide-default-footer":true,"no-data-text":_vm.$t('entities.studyItem.no_study_req'),"sort-by":_vm.$query.sort,"sort-desc":_vm.$query.desc},on:{"update:sortBy":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.$query, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.$query, "desc", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"pointer light-green--text",on:{"click":function($event){return _vm.goToDetail(item.id)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.isShown",fn:function(ref){
var item = ref.item;
return [(item.isShown)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.showOrHide(item.id)}}},[_vm._v(" visibility ")]):_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.showOrHide(item.id)}}},[_vm._v(" visibility_off ")])]}}])}),_c('h5',{staticClass:"mt-10 mb-3"},[_vm._v("Archivované požadavky")]),_c('v-data-table',{staticClass:"elevation-1 archived-table",attrs:{"headers":_vm.customHeaders,"items":_vm.archivedItems,"sort-by":['id'],"sort-desc":[true],"hide-default-header":true,"disable-pagination":true,"hide-default-footer":true,"no-data-text":_vm.$t('entities.studyItem.no_study_req')},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"pointer light-green--text",on:{"click":function($event){return _vm.goToDetail(item.id)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.isShown",fn:function(ref){
var item = ref.item;
return [(item.isShown)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.showOrHide(item.id)}}},[_vm._v(" visibility ")]):_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.showOrHide(item.id)}}},[_vm._v(" visibility_off ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }