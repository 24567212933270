import L from 'leaflet'
import {config} from '@/config';
import Icons from './Icons';
import lodash from 'lodash';
import {sortedCoordinates} from "@/utils"
import {boundingBox, centroid} from '../../utils';

const myMendeluLogo = require(`!!raw-loader!@/assets/myMendeluLogo.svg`).default;
const myMendeluLogoEN = require(`!!raw-loader!@/assets/myMendeluLogoEN.svg`).default;

export default class MapWidget {
    leafletMap;
    center;
    zoom;
    mapElement;
    isReadOnly;
    control;

    constructor(mapElement) {
        this.mapElement = mapElement;
        this.icons = new Icons();
    }

    init() {
        this.leafletMap = L.map(this.mapElement, {
            maxZoom: 23,
            minZoom: 13
        });

        this.changeLabels();

        const tileLayer = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={token}', {
            attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a><span style="opacity:0;">__</span> ',
            maxZoom: 22,
            id: 'mapbox/streets-v11',
            token: config.mapboxToken
        });
        this.addToMap(tileLayer);

        this.setMyMendeluLogo();
    }

    addToMap(layer) {
        this.leafletMap.addLayer(layer);
    }

    createPane(pane, zIndex = undefined) {
        this.leafletMap.createPane(pane)
        if (zIndex !== undefined)
            this.leafletMap.getPane(pane).style.zIndex = zIndex
    }

    addControl(controlLayer) {
        if (!this.isReadOnly) {
            this.leafletMap.addControl(controlLayer);
            this.control = controlLayer;
        }
    }

    removeControl(controlLayer) {
        if (controlLayer && !this.isReadOnly) {
            this.leafletMap.removeControl(controlLayer);
        }
        this.control = null;
    }

    allowedObjects() {
        return this.allowedObj;
    }

    getMap() {
        return this.leafletMap;
    }

    readOnly(isReadOnly) {
        this.isReadOnly = isReadOnly;
    }

    invalidateSize() {
        if (this.leafletMap) {
            this.leafletMap.invalidateSize();
        }
    }

    on(event, callback) {
        this.leafletMap.on(event, (e) => callback(e));
    }

    getCenter() {
        const center = this.leafletMap.getCenter();
        return {
            lat: center.lat,
            lon: center.lng
        };
    }

    getZoom() {
        return this.leafletMap.getZoom();
    }

    setZoom(zoom) {
        this.leafletMap.setZoom(zoom);
    }

    /**
     * Updates the map's center and zoom (optional).
     *
     * The new center may be given as a specific coordinate (Object), or an array of coordinates (Array).
     * In the latter case the centroid is calculated and used as the center.
     *
     * If the zoom is not provided, the zoom is not changed and the current zoom is kept.
     *
     * @param {Object | Array} center
     * @param {Number} zoom
     */
    setCenter(center, zoom = this.getZoom()) {
        const coordinates = Array.isArray(center) ? center : [center];
        const position = centroid(coordinates);
        this.leafletMap.setView(position, zoom);
    }

    /**
     * Centers the map on the given object and updates the zoom, so the whole object is visible.
     * Calculates the proper padding, so that the whole object is visible within the screen.
     * @param {[number, number]} position //Array of coordinates
     */
    fitInView(position) {
        const bbox = boundingBox(position);
        this.leafletMap.fitBounds(bbox, {padding: [80, 80], maxZoom: 21});
    }

    createIconMarker(id, position, type, name = null, weight = 0, pane = undefined) {
        const markerIcon = this.icons.createIcon(type, name, weight);
        const marker = this.createObject(id, 'marker', position, {
            icon: markerIcon,
            zIndexOffset: weight > 0 ? 1000 : 0, // put the focused marker above all others
        });

        if (pane !== undefined)
            marker.options.pane = pane

        return marker;
    }

    createObject(id, type, position, options = {}) {
        const positionCopy = lodash.cloneDeep(position); // copy position to avoid mutating it directly from leaflet
        let object = null;

        if (type === 'polygon') {
            const coordinates = sortedCoordinates(positionCopy);
            object = L.polygon(coordinates, options);
        } else if (type === 'marker') {
            object = L.marker([positionCopy[0].lat, positionCopy[0].lon], {...options, draggable: true});
        }
        if (object != null)
            object.id = id;

        return object;
    }

    /*
    *
    * PNG EXPORT METHODS
    *
    */

    addPrintControl() {
        const clientMapSize = this.mapElement.getBoundingClientRect();

        let customSize = {
            width: clientMapSize.width,
            height: clientMapSize.height,
            className: 'CustomSize',
            tooltip: 'Current Size'
        };

        let sizeModes = [customSize];

        this.printControl = L.easyPrint({
            title: 'My awesome print button',
            position: 'bottomright',
            hideControlContainer: false,
            hideClasses: ['leaflet-control-zoom'],
            hidden: false,
            exportOnly: true,
            sizeModes: sizeModes,
        }).addTo(this.leafletMap);
    }

    /*
    *
    * Prepares the map, so we can export map in different sizes no matter what is the current resolution of the user's screen.
    * This was implemented as there was a need for a higher resolution images.
    *
    */
    prepareForExport(size) {
        const mapBounds = this.leafletMap.getBounds();
        const originalZoom = this.leafletMap.getZoom();
        const originalMapSize = this.mapElement.getBoundingClientRect();

        // We prepare the map element for the resizing and hide it from the user with a class.
        this.mapElement.classList.add('map-print-size');
        this.mapElement.style.width = size.width + "px";
        this.mapElement.style.height = size.height + "px";

        this.setZoom(originalZoom + 1);
        this.leafletMap.invalidateSize();
        this.leafletMap.fitBounds(mapBounds);

        //Resize map due to CSS
        this.toggleSizeClass(size.width)

        // After the export finises we return the map to the initial state.
        this.leafletMap.on('easyPrint-finished', () => {
            this.mapElement.classList.remove('map-print-size');
            this.mapElement.style.width = originalMapSize.width + "px";
            this.mapElement.style.height = originalMapSize.height + "px";
            this.leafletMap.fitBounds(mapBounds);
            this.setZoom(originalZoom);
            this.leafletMap.invalidateSize();

            //Resize map due to CSS
            this.toggleSizeClass(size.width)

            this.leafletMap.off('easyPrint-finished');
        });
    }

    toggleSizeClass(width) {
        //Resize map due to CSS
        if (width <= 860)
            this.mapElement.classList.toggle('print-narrow');
        if (width > 860 && width <= 1280)
            this.mapElement.classList.toggle('print-regular');
        if (width > 1280)
            this.mapElement.classList.toggle('print-wide')
    }

    exportPng(size) {
        this.prepareForExport(size);
        this.addPrintControl();
        this.printControl.printMap('CustomSize', 'MyMendelu-map');
    }

    /*
    *
    * END OF PNG EXPORT METHODS
    *
    */

    addPolygonLabel(polygon, text, weight) {
        let className = 'polygon-name ';

        if (weight !== 0) {
            className += weight > 0 ? ' increased-weight ' : ' decreased-weight ';
        }

        polygon.bindTooltip(
            text,
            {
                permanent: true,
                direction: "center",
                className: className
            }
        ).openTooltip();
    }

    setLegend(legend) {
        if (this.legendControl) {
            this.legendControl.remove();
        }

        if (legend) {
            this.legendControl = L.control({position: 'topright'});
            let div = L.DomUtil.create('div', 'map--legend');
            div.innerText = legend;
            div.id = "map-legend";

            this.legendControl.onAdd = () => div;
            this.legendControl.addTo(this.leafletMap);
        }
    }

    setMyMendeluLogo() {
        this.legendControl = L.control({position: 'topleft'});
        let div = L.DomUtil.create('svg', 'map--legend my-mendelu-logo');
        div.innerHTML = myMendeluLogo;
        div.id = "map-logo";

        if (isInEnglish()) {
            div.innerHTML = myMendeluLogoEN;
        }

        this.legendControl.onAdd = () => div;
        this.legendControl.addTo(this.leafletMap);
    }

    addPolygonIcon(layer, polygon, placeType, weight) {
        const centerPos = polygon.getBounds().getCenter();
        const marker = this.createIconMarker(null, [{lat: centerPos.lat, lon: centerPos.lng}], placeType, null, weight);
        layer.addLayer(marker);
        return marker;
    }

    addPopup(object, html) {
        object.bindPopup(html);
    }

    changeLabels() {
        L.drawLocal.draw.toolbar.actions.title = 'Zrušit kreslení';
        L.drawLocal.draw.toolbar.actions.text = 'Zrušit';
        L.drawLocal.draw.toolbar.finish.title = 'Dokončit kreslení';
        L.drawLocal.draw.toolbar.finish.text = 'Dokončit';
        L.drawLocal.draw.toolbar.undo.title = 'Smazat poslední bod';
        L.drawLocal.draw.toolbar.undo.text = 'Smazat poslední bod';
        L.drawLocal.draw.toolbar.buttons.polygon = 'Vytvořit mnohoúhelník';
        L.drawLocal.draw.toolbar.buttons.marker = 'Vytvořit bod';
        L.drawLocal.draw.handlers.marker.tooltip.start = 'Klikni pro umístnení.';
        L.drawLocal.draw.handlers.polygon.tooltip.start = 'Klikni pro zahájení kreslení.';
        L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Klikni pro pokračování v kreslení.';
        L.drawLocal.draw.handlers.polygon.tooltip.end = 'Klikni na první bod pro uzavřetí.';
        L.drawLocal.draw.handlers.polyline.error = '<strong>Chyba:</strong> body se nesmí překrývat!';
        L.drawLocal.edit.toolbar.buttons.editDisabled = 'Žádná vrstva na úpravu';
        L.drawLocal.edit.toolbar.buttons.edit = 'Upravit';
        L.drawLocal.edit.toolbar.buttons.editDisabled = 'Žádná vrstva na úpravu';
        L.drawLocal.edit.toolbar.buttons.removeDisabled = 'Žádná vrstva na smazání';
        L.drawLocal.edit.toolbar.buttons.remove = 'Smazat';
        L.drawLocal.edit.toolbar.actions.save.title = 'Uložit změny';
        L.drawLocal.edit.toolbar.actions.save.text = 'Uložit';
        L.drawLocal.edit.toolbar.actions.cancel.title = 'Ukončit úpravu, smazat všechny změny';
        L.drawLocal.edit.toolbar.actions.clearAll.title = 'Smazat';
        L.drawLocal.edit.toolbar.actions.cancel.text = 'Zrušit';
        L.drawLocal.edit.toolbar.actions.clearAll.text = 'Smazat';
        L.drawLocal.edit.handlers.edit.tooltip.text = 'Táhni bodama pro úpravu, klikni na bod pro smazání.';
        L.drawLocal.edit.handlers.edit.tooltip.subtext = 'Klikni na Potvrdit pro uložení úprav.';
        L.drawLocal.edit.handlers.remove.tooltip.text = 'Klikni na objekt pro vymazání.';
    }
}

export function isInEnglish() {
    return window.location.href.includes("lang=en") || !navigator.language.includes("sk") && !navigator.language.includes("cs");
}
