import { get, post, put, del } from '@/services/api';
import Vue from "vue";

const state = {
  didLoadDisplays: false,
  displays: [],
};

const getters = {
  didLoadDisplays: state => state.didLoadDisplays,
  getDisplays: state => state.displays,
  getDisplayByID: state => id => state.displays.find(display => display.id == id),
  prepareNewDisplay: () => () => ({
    name: "",
    maxParallels: undefined,
    beaconId: undefined,
    refreshPeriod: 5
  })
};

const actions = {

  async fetchDisplayById({commit}, id) {
    commit('errors/clear', null, { root: true });
    commit('startedDataDownload');
    try {
      const response = await get('displays/displays/' + id);
      commit('updateDisplay', response.data.content);
      commit('finishedDataDownload');
    } catch (e) {
      if (e.statusCode == 404) {
        commit('errors/objectNotFound', null, { root: true });
      } else {
        throw e;
      }
    }
  },

  async fetchDisplays({ commit }, unitId) {
    commit('startedDataDownload');
    await get(`displays/displays/unit/${unitId}`)
    .then((response) => {
      commit('setDisplays', response.data.items)
      commit('finishedDataDownload');
    })
  },

  deleteDisplay({ commit }, id) {
    return new Promise((resolve, reject) => {
      del(`displays/displays/${id}`)
        .then((response) => {
          commit('filterDisplays', id)
          resolve(response.data);
        })
        .catch(() => {
            reject();
        });
    });
  },

  createDisplay({ commit }, item) {
    return new Promise((resolve, reject) => {
      post('displays/displays/', item)
        .then((response) => {
            commit('addDisplay', response.data.content);
            resolve(response.data.content);
        })
        .catch(e => {
          console.log(e.message);
          reject();
        });
    });
  },

  updateDisplay({ commit }, item) {
    return new Promise((resolve, reject) => {
      put(`displays/displays/${item.id}`, item)
        .then(() => {
          commit('updateDisplay', item);
          resolve();
        }).catch(e => {
          console.log(e.message);
          reject();
        });
    });
  },

  refreshDisplays() {
    return new Promise((resolve, reject) => {
      get('displays/refresh')
        .catch(() => {
            reject();
        });
    });
  },
};

const mutations = {
  startedDataDownload: state => state.didLoadDisplays = false,
  finishedDataDownload: state => state.didLoadDisplays = true,
  addDisplay: ( state, item ) => (state.displays.push(item)),
  setDisplays: ( state, displays ) => (state.displays = displays),
  filterDisplays: ( state, id ) => (state.displays = state.displays.filter(display => display.id !== id)),   // used for delete
  updateDisplay: ( state, item ) => {
    let index = state.displays.findIndex(display => display.id == item.id);
    Vue.set(state.displays, index, item);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
