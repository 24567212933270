<template>
    <v-row class="my-2">
        <div class="icon mt-1">
            <slot name="icon"/>
        </div>
        <div class="question-answer d-flex flex-column justify-center">
            <slot name="first"></slot>
            <slot name="second"></slot>
        </div>
    </v-row>
</template>

<script>
    export default {
        name: 'TwoLineCellWithIcon'
    }
</script>

<style scoped>
    .icon {
        width: 3rem;
        display: inline-block;
        text-align: center;
    }
    .question-answer {
        width: calc(100% - 3rem);
    }
</style>