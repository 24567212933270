<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-container fluid v-else>

            <BackButton v-if="!this.isFaculty" :target="{name: 'EntitiesPortal'}">{{ $t('entities.entity_portal') }}</BackButton>
            <BackButton v-else :target="{name: 'Administration'}">{{ $t('entities.study_faculties') }}</BackButton>

            <module-header>
                <h2>{{ $t('entities.assistants.portal_name') }}</h2>
                <v-spacer/>
                <primary-button v-if="isStudyAdmin" @click="goToNew()" class="ml-5">+ {{ $t('entities.add') }}</primary-button>
            </module-header>

            <v-data-table
                    :headers="filterHeaders()"
                    :items="notArchivedAssistants"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                    :no-data-text="$t('entities.assistants.no_study_ref')"
                    class="elevation-1"
                    :item-class="itemRowBackground"
                    :sort-by.sync="$query.sort"
                    :sort-desc.sync="$query.desc"
            >
                <template v-slot:item.name="{ item }">
                  <b class="pointer light-green--text"  @click="goToDetail(item.id)">{{item.name}}</b>
                </template>
            </v-data-table>

            <div class="my-5 flex" v-if="control">
              <div class="square"></div>
              <span class="mx-2">: Pro studijní referentku je potřeba přiřadit minimálně jeden obor nebo požadavek.</span>
            </div>
            <h5 class="mt-10 mb-3">Archivované referentky</h5>

            <v-data-table
                    :headers="filterHeaders()"
                    :items="archivedAssistants"
                    :sort-by="['id']"
                    :sort-desc="[true]"
                    :hide-default-header="true"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                    :no-data-text="$t('entities.assistants.no_study_ref')"
                    class="elevation-1 archived-table"
            >
                <template v-slot:item.name="{ item }">
                  <b class="pointer light-green--text"  @click="goToDetail(item.id)">{{item.name}}</b>
                </template>
            </v-data-table>

    </v-container>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import Loading from "../../../../../components/Loading";

    import BackButton from "../../../../../components/layout/BackButton";
    import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
    import ModuleHeader from '../../../../../components/layout/ModuleHeader.vue';

    export default {
        name: "AssistantPortal",

        components: {
            BackButton,
            PrimaryButton,
            Loading,
            ModuleHeader,
        },

        data() {
            return {
                control:false,
                headers: [
                    {
                        text: this.$t('headers.name'),
                        align: 'left',
                        value: 'name',
                        width: '25%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.room'),
                        align: 'left',
                        value: 'default_room',
                        width: '20%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.xlogin'),
                        align: 'left',
                        value: 'xlogin',
                        width: '15%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.studyFieldIds'),
                        align: 'center',
                        value: 'studyFieldIds',
                        width: '15%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.studyProblemItemIds'),
                        align: 'center',
                        value: 'studyProblemItemIds',
                        width: '15%',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.studyFaculty'),
                        align: 'center',
                        value: 'faculty',
                        width: '15%',
                        sortable: true,
                    }
                ],

                dataToDataTable: []
            }
        },

        created() {
            this.fetchAssistants();
        },

        computed: {
            ...mapGetters('assistant', ['getAssistants', 'didLoadedAssistants']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),

            didLoadedAll() {
                return this.didLoadedAssistants;
            },

            isStudyAdmin() {
                return this.$auth.getStudyAssistantAndRole()?.role === 'STUDY_ADMIN';
            },

            assistants() {
               return this.getAssistants.map(item => ({
                   name: item.name,
                   default_room: item.defaultRoom,
                   xlogin: item.xlogin,
                   id: item.id,
                   archived: item.archived,
                   studyFieldIds: item.studyFieldIds.length,
                   studyProblemItemIds: item.studyProblemItemIds.length,
                   faculty: item.faculty ? item.faculty.localizedAttributes.cz.name : '-'
               }));
            },

            notArchivedAssistants() {
                return this.assistants.filter(f => !f.archived);
            },

            archivedAssistants() {
                return this.assistants.filter(f => f.archived);
            },

            getHeadersWithoutFaculty () {
                return this.headers.filter(obj => obj.text !== this.$t('headers.studyFaculty'))
            },

            isAdmin() {
                return this.$auth.getRoles().includes('ADMIN');
            },

            isFaculty(){
                return this.$route.query.tab === "faculty";
            },

        },

        methods: {
            ...mapActions('assistant', ['fetchAssistants']),

            goToNew() {
                if (this.isFaculty && this.isAdmin ){
                    this.$router.push({name: 'AssistantDetail',query: {'tab': 'faculty'}, params: {Rid: "new"}});
                }else{
                    this.$router.push({name: 'AssistantDetail', params: {Rid: "new"}});
                }
            },

            goToDetail(Rid) {
                if (this.isFaculty && this.isAdmin ){
                    this.$router.push({name: 'AssistantDetail', query: {'tab': 'faculty'},params: {Rid: Rid}});
                }else{
                    this.$router.push({name: 'AssistantDetail', params: {Rid: Rid}});
                }
            },

            itemRowBackground: function (item) {
              if (item.studyFieldIds === 0 && item.studyProblemItemIds===0){
                this.control=true;
                return 'style';
              }
            },

            filterHeaders(){
              if (this.isFaculty && this.isAdmin ){
                  return this.headers;
              }else{
                  return this.getHeadersWithoutFaculty;
              }
            }
        }
    }
</script>

<style scoped>

.archived-table >>> td:not(:last-child) {
  color: rgb(167, 167, 167);
}
.elevation-1 >>> .style  {
  background-color:  #ffe0e0;
}
.square {
  height: 22px;
  width: 22px;
  background-color: #ffe0e0;
}
.flex{
  display:flex;
}

</style>
