import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocalisationFiles () {
  const localisation = require.context('./i18n', true, /\.js$/)
  const msg = {};
  localisation.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if( matched && matched.length > 1 ){
      const locale = matched[1];
      msg[locale] = localisation(key).default;
    }
  });
  return msg;
}

export default new VueI18n({
  locale: 'cz',
  fallbackLocale: 'cz',
  messages: loadLocalisationFiles(),

  pluralizationRules: {

   'cz': function( choice ) {
     if (choice > 1 && choice < 5) {
       return 1;
     }
     if (choice === 0 || choice >= 5) {
       return 2;
     }
     if (choice <= 1) {
       return 0;
     }
   },

   'en': function( choice ) {
     if (choice === 0 || choice > 1) {
       return 1;
     }
     if (choice <= 1) {
       return 0;
     }
   },

 },
})
