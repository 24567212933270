<template>
    <v-dialog
        v-model="showDialog"
        max-width="800px"
        @keydown.esc="close"
        @click:outside="close"
    >
    <CraftingQuestionDialog v-if="showQuestionDialog" :showDialog="showQuestionDialog" :isNew="true" @close="showQuestionDialog = false" />
        <v-card>
            <v-row class="pl-6 pt-4 align-center">
                <v-col cols="auto" class="pr-0">
                    <h4> Otázky </h4>
                </v-col>
                <v-col cols="auto">
                    <InfoButton right>{{$t('gamification.question_dialog_info')}}</InfoButton>
                </v-col>
            </v-row>
            <v-card-text v-if="getQuestionsToShow.length !== 0">

                <v-data-table
                  v-model="selectedItems"
                  :headers="headers"
                  :items="getQuestionsToShow"
                  :disable-pagination="true"
                  :hide-default-footer="true"
                  show-select
                >

                    <template v-slot:item.localizedAttributes.cz.text="{item}">
                        <span>
                            {{item.localizedAttributes.cz.text}}
                        </span>
                    </template>
                    <template v-slot:item.points="{item}">
                        <span>
                            {{item.points}}
                        </span>
                    </template>

                </v-data-table>
            </v-card-text>
            <v-card-text v-else>
                <div class="row">
                    <div class="col smallHeader">
                        {{$t('gamification.no_questions_to_show')}}
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="justify-space-between pb-6 pr-6">
                <v-col>
                    <PrimaryButton @click="() => showQuestionDialog = true">
                        <v-icon class="mr-2">
                            add
                        </v-icon>
                        {{$t('gamification.new_question')}}
                    </PrimaryButton>
                </v-col>
                <v-col class="justify-end text-right">
                    <v-btn color="dark" class="mr-2" text @click="close">Zavřít</v-btn>
                    <v-btn color="primary" @click="save">Uložit</v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import CraftingQuestionDialog from "@/components/gamification/CraftingQuestionDialog.vue";
import {mapGetters} from "vuex";
import InfoButton from "@/components/buttons/InfoButton";


export default {
    name: "QuestionDialog",
    components: {
        PrimaryButton,
        CraftingQuestionDialog,
        InfoButton
    },

    props: {
        showDialog: Boolean,
        testQuestions: Array
    },

    data() {
        return {
            selectedItems: [],
            questionsToShow: [],
            headers: [
                {
                    text: 'Text',
                    value: 'localizedAttributes.cz.text',
                    width: '90%',
                    sortable: true,
                    align: 'left'
                },
                {
                    text: 'Body',
                    value: 'points',
                    width: '10%',
                    sortable: true,
                    align: 'left'
                }
            ],
            showQuestionDialog: false,
        }
    },

    computed: {
        ...mapGetters('Questions', ['getQuestionsBySubjectId']),

        getQuestionsToShow() {
            return this.getQuestionsBySubjectId(this.subjectId).filter(a => !this.testQuestions.map(b => b.id).includes(a.id))
        },

        subjectId() {
            return Number(this.$route.params.subjectId);
        },
    },


    methods: {

        close() {
            this.$emit('close');
            this.selectedItems = []
        },

        save() {
            this.$emit('save', this.selectedItems);
            this.selectedItems = []
        },
    }
}
</script>

<style scoped>

.header {
    padding-left: 40px !important;
}

.smallHeader {
    padding-left: 32px !important;
}

</style>