import L from "leaflet";
import MapVisual from "./MapVisual";
const pinSvg = require( `!!raw-loader!@/assets/Location_pin.svg` ).default;

export default class Icons {
    iconFileName(placeType) {
        return {
            'archive': 'archive-24px.svg',
            'bistro': 'food_default-24px.svg',
            'boardroom': 'office_boardroom-24px.svg',
            'building': 'building-24px.svg',
            'cafe_machine': 'coffee-24px.svg',
            'cafeteria': 'cafeteria-24px.svg',
            'classroom_and_laboratory': 'seminar-24px.svg',
            'coatroom': 'coatroom-24px.svg',
            'computer_kiosk': 'pc_desktop-24px.svg',
            'copier': 'printer-24px.svg',
            'department': 'school_university-24px.svg',
            'dormitory': 'dormitory_hotel-24px.svg',
            'elevator': 'elevator-24px.svg',
            'engine_room': 'utility_room-24px.svg',
            'emergency_stairs': 'emergency_stairs-24px.svg',
            'emergency_floor': 'emergency_floor-24px.svg',
            'food_machine': 'food_machine-24px.svg',
            'garage': 'garage-24px.svg',
            'garbage': 'garbage-24px.svg',
            'garden': 'garden-24px.svg',
            'gate': 'gate-24px.svg',
            'gatehouse': 'gatehouse-24px.svg',
            'indoor_building': 'building-24px.svg',
            'kitchen': 'kitchen-24px.svg',
            'locker': 'locker-24px.svg',
            'office': 'office_boardroom-24px.svg',
            'parking': 'parking-24px.svg',
            'person': 'user-24px.svg',
            'ticket_machine': 'ticket-24px.svg',
            'toilet_female': 'female_toilet-24px.svg',
            'toilet_handicapped': 'barrier_free_entrance-24px.svg',
            'toilet_male': 'male_toilet-24px.svg',
            'transportation_stop': 'transportation_stop-24px.svg',
            'trashcan': 'garbage-24px.svg',
            'utility_room': 'utility_room-24px.svg',
            'water_machine': 'water_machine-24px.svg',
            'sink': 'sink-24px.svg',
            'library': 'local_library-24px.svg',
            'stairs': 'stairs-24px.svg',
            'storage': 'storage-24px.svg',
            // 'ic_id_0': 'map_default_circle-24px.svg', //Neutrální
            // 'ic_id_1': '', //Památka
            // 'ic_id_2': '', //Atrakce
            // 'ic_id_3': '', //Vyhlídka/rozhledna
            // 'ic_id_4': '', //Příroda
            // 'ic_id_5': '', //Architektura
            // 'ic_id_6': '', //Vodní plocha
            // 'ic_id_7': '', //Sport
        }[placeType] ?? 'map_default_circle-24px.svg';
    }

    coloredPin(placeType) {
        const fillColor = MapVisual.getTintColorOfMarker(placeType);
        const coloredPinSvg = pinSvg.replace('#78BE13', fillColor !== '#ffffff' ? fillColor : '#78BE13');
        return coloredPinSvg;
    }

    content(placeType, iconText) {
        let html = `
            <div class="pin">${this.coloredPin(placeType)}</div>
            <img src="/icons/map/${this.iconFileName(placeType)}" class="icon" />`;

        if (iconText) {
            if ( iconText.length > 30 ) {
                html += `<p class="text broad iconText">${iconText}</p>`;
            } else {
                html += `<p class="text iconText">${iconText}</p>`;
            }
        }

        return html;
    }

    createIcon(placeType, iconText, weight = 0) {
        let className = 'place-marker';
        let height = 50;

        if (weight > 0) {
            className += ' increased-weight' ;
            height = 80;
        } else if (weight < 0) {
            height = 40;
            className += ' decreased-weight ';
        }

        return new L.DivIcon({
                className: className,
                html: this.content(placeType, iconText),
                iconSize: [height, height],
                iconAnchor: [height / 2, height * 0.86], // horizontal center, bottom of the pin without the shadow
          })
    }
}
