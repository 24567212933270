<template>
    <ItemNotFoundState v-if="objectNotFound" :objectName="'Zpráva'">
        <template #backButton>
            <BackButton :target="{name: 'Messages'}">Důležité zprávy</BackButton>
        </template>
    </ItemNotFoundState>
    <Loading v-else-if="!didLoadWarnings && !didLoadAudiences"/>
    <div v-else>
        <BackButton :target="{name: 'Messages'}">Důležité zprávy</BackButton>
        <ModuleHeader v-if="warning">
            <h2 v-if="isNew">Přidání důležité zprávy</h2>
            <h2 v-else-if="warning.archived">
                Důležitá zpráva
                <v-chip color="orange" dark class="ml-3">Archivováno</v-chip>
            </h2>
            <h2 v-else>
                Úprava důležité zprávy
            </h2>
            <v-spacer/>
            <div v-if="!warning.archived">
                <SaveButton
                    :disabled="!validateForm || audienceIds.length === 0"
                    :state="savingState"
                    @click="tryToSaveWithNotification()"
                    label="Uložit a notifikovat" />
            </div>
        </ModuleHeader>
        <v-form v-if="warning" @submit.prevent="save" ref="form" :disabled="warning.archived">
            <v-simple-table fixed-header>
                <thead>
                <tr>
                    <th>
                        Čeština
                    </th>
                    <th>
                        Angličtina
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <v-textarea
                            data-cy="title-cz"
                            label="Nadpis"
                            @input="changeWatch"
                            :rules="[required_rule]"
                            v-model.trim="warning.localizedAttributes.cz.title"
                            :rows="1"
                            :maxlength="100"
                            counter
                            auto-grow
                        > </v-textarea>
                    </td>
                    <td>
                        <v-textarea
                            data-cy="title-en"
                            label="Title"
                            @change="changeWatch"
                            :rules="[required_rule]"
                            v-model.trim="warning.localizedAttributes.en.title"
                            :rows="1"
                            :maxlength="100"
                            counter
                            auto-grow
                        > </v-textarea>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>
            <MarkDownEditor v-if="markdownTextLoaded" :text-cz="markdownedTextCz" :text-en="markdownedTextEn" @update:cz="changeCzText" @update:en="changeEnText" />
            <v-row class="mt-5">
                <v-col>
                    <h3 class="font-weight-medium">{{ $t('news.target_groups') }}</h3>
                </v-col>
            </v-row>
            <v-row v-if="warning">
                <v-col>
                    <NewsCards
                        :input="getAudiencesByUser"
                        data="audience"
                        @clicked="setValues"
                        :selectedItems="selectedAudiences"
                        :disabled="warning.archived"
                    />
                </v-col>
            </v-row>
        </v-form>

        <div v-if="warning">
            <div v-if="!isNew && (!warning.archived || archivingState !== SaveButtonState.idle)">
                <h5 class="mt-5">Ostatní</h5>
                <SaveButton
                    :disabled="!validateForm || audienceIds.length === 0"
                    :state="archivingState"
                    label="Archivovat zprávu"
                    saved-label="Zpráva archivována"
                    saving-label="Archivování..."
                    @click="archive"
                    data-cy="archive-button"
                />
            </div>
        </div>

        <confirm-dialog
            :model="confirmDialog"
            title="Opravdu uložit a notifikovat uživatele?"
            no="Zrušit"
            @yes="onConfirmSaveWithNotification()"
            @no="onCancelSave()"
        >
            Všem, kdo používají mobilní aplikaci My MENDELU, přijde upozornění. Důležité zprávy slouží pouze pro sdělení kritických informací, které se týkají všech lidí na univerzitě.
            <br/>
            <img src="@/assets/warning-notification.jpeg" style="width: 100%; margin-top: 1rem;" />
        </confirm-dialog>
    </div>
</template>

<script>

import {mapGetters, mapActions} from 'vuex';
import ModuleHeader from "../../components/layout/ModuleHeader";
import Loading from "@/components/Loading";
import BackButton from "../../components/layout/BackButton";
import SaveButton from "../../components/buttons/SaveButton";
import {getLocalizedLanguages} from "../../localization/languages";
import ConfirmDialog from '../../components/dialogs/ConfirmDialog.vue';
import ItemNotFoundState from '../../components/states/ItemNotFoundState.vue';
import {SaveButtonState} from "@/components/buttons/SaveButtonState";
import NewsCards from "@/components/news/NewsCards";
import MarkDownEditor from "@/components/MarkDownEditor.vue";


export default {
    name: "WarningDetail",

    components: {
        Loading,
        SaveButton,
        BackButton,
        ModuleHeader,
        ConfirmDialog,
        ItemNotFoundState,
        NewsCards,
        MarkDownEditor,
    },

    async created() {
        await this.loadData()
        await this.fetchAllAudiencesByUser()

        this.markdownedTextCz = this.warning.localizedAttributes.cz.description
        this.markdownedTextEn = this.warning.localizedAttributes.en.description

        this.markdownTextLoaded = true
    },

    watch: {
        warningId() {
            this.loadData();
        },
    },

    data() {
        return {
            required_rule: value => (value && value.trim() !== "") || 'Povinné',
            savingState: SaveButtonState.idle,
            archivingState: SaveButtonState.idle,
            confirmDialog: false,
            audienceIds: [],
            markdownedTextCz: '',
            markdownedTextEn: '',
            markdownTextLoaded: false,
            warning: null
        }
    },

    computed: {
        SaveButtonState() {
            return SaveButtonState
        },
        ...mapGetters('localization', ['currentLanguage']),
        ...mapGetters('Warnings', ['warningById', 'didLoadWarnings', 'getNewWarning']),
        ...mapGetters('errors', ['objectNotFound']),
        ...mapGetters('Newsfeed', ['getAudiencesByUser','didLoadAudiences']),

        warningId() {
            const urlId = this.$route.params.id;
            return urlId === "new" ? null : Number(urlId);
        },

        isNew() { return this.warningId === null; },

        languages() { return getLocalizedLanguages(); },

        selectedAudiences() {
            return !this.isNew ? this.warning.audience : []
        },

        validateForm() {
            return !!this.warning.localizedAttributes.cz.title && !!this.markdownedTextCz && !!this.warning.localizedAttributes.en.title && !!this.markdownedTextEn
        },

    },

    methods: {
        ...mapActions('leavePrevent', ['madeUnsavedChanges', 'didSaveChanges']),
        ...mapActions('Warnings', ['fetchWarningById', 'createWarning', 'updateWarning', 'prepareNewWarning']),
        ...mapActions('errors', ['clear']),
        ...mapActions('Newsfeed', ['fetchAllAudiencesByUser']),

        async loadData() {
            if (!this.isNew) {
                await this.fetchWarningById(this.warningId)
                this.warning = this.warningById(this.warningId)
                this.fetchSelectedAudiences()
            } else {
                this.prepareNewWarning()
                this.warning = this.getNewWarning
                this.clear()
            }
        },

        changeWatch() {
            this.madeUnsavedChanges();
        },

        tryToSaveWithNotification() {
            this.confirmDialog = true;
        },

        onConfirmSaveWithNotification() {
            this.confirmDialog = false;
            this.save(true);
        },

        onCancelSave() {
            this.confirmDialog = false;
        },



        async save(notify) {
            if (!this.validateForm) {
                return
            }
            this.savingState = SaveButtonState.saving
            this.warning.audienceIds = this.audienceIds;

            this.warning.localizedAttributes.cz.description = this.markdownedTextCz
            this.warning.localizedAttributes.en.description = this.markdownedTextEn

            try {
                if (this.isNew) {
                    this.warning.id = await this.createWarning({warning: this.warning, notify: notify});
                } else {
                    await this.updateWarning({warning: this.warning, notify: notify});
                }

                this.didSaveChanges();

                if (this.isNew) {
                    await this.$router.push({name: "Messages"});
                }

                this.savingState = SaveButtonState.saved;

            } catch (e) {
                this.savingState = SaveButtonState.error
                throw e
            }

        },

        async archive() {
            if (!this.validateForm) {
                return
            }

            this.archivingState = SaveButtonState.saving;

            try {
                this.warning.archived = true;
                this.warning.audienceIds = this.audienceIds;

                await this.updateWarning({warning: this.warning, notify: false});

                this.didSaveChanges();

                this.archivingState = SaveButtonState.saved;
                await this.$router.push( {name: "Messages"});

            } catch (e) {
                this.warning.archived = false;
                this.archivingState = SaveButtonState.error
                throw e
            }

        },

        setValues(obj) {
            if (obj.type === "audience") {
                this.audienceIds = obj.value
            }
        },

        fetchSelectedAudiences(){
                if(this.warning.audience) {
                    this.audienceIds = this.warning.audience.map(obj => obj.id);
                }
        },

        changeCzText(text) {
            this.markdownedTextCz = text
        },

        changeEnText(text) {
            this.markdownedTextEn = text
        },
    }

}
</script>

<style scoped>
thead {
    border-top: none;
}
th, tr, td  {
    border: none !important;
}
th {
    font-size: 1rem !important;
}
</style>
