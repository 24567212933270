import Vue from 'vue'
import i18n from './localization/i18n'
import App from './App.vue'
import store from './store/Store';
import './filters/translate';
import './filters/ellipsis';
import './filters/firstUpper';
import './filters/localized';
import './filters/noname';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import router from './router'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'tiptap-vuetify/dist/main.css'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import vuetify from './plugins/vuetify';
import moment from 'moment-timezone';
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import LeavePrevent from "./services/LeavePrevent";
import Auth from "./plugins/auth";
import VueNumberInput from '@chenfengyuan/vue-number-input';
import 'vue-swatches/dist/vue-swatches.css';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import CountryFlag from 'vue-country-flag';
import './plugins/vuetify'
import ConnectionMonitor from './services/ConnectionMonitor';
import { initFirebase } from './services/firebase/firebase';
import UUID from "vue-uuid";
import QuerySynchronizer from "@oarepo/vue-query-synchronizer/src/library";
import {initApiErrorHandling} from "@/services/api/error-handler";


// init localization of date and time
const locale = window.navigator.language || 'cz';
moment.locale(locale);
moment.tz.setDefault('Europe/Prague');

// init API error handling
initApiErrorHandling();

// init monitoring of internet connection
ConnectionMonitor.init();

// init prevention of leaving without saving changes
LeavePrevent.init();

// init Firebase
initFirebase();

// init extensions
Vue.component(VueQrcode.name, VueQrcode);
Vue.component('country-flag', CountryFlag)
Vue.use(Auth, {store});
Vue.use(Vuetify);
Vue.use(VueLodash, { lodash: lodash });
Vue.use(VueNumberInput);
Vue.use(VSwatches);
Vue.use(UUID, { name: 'uuid' });
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'md'
});


Vue.use(QuerySynchronizer, {
  router: router,
  navigationOperation: 'replace',
})

// init Vue
const app = new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');


// --- TEST ENVIRONMENT ---
// https://www.cypress.io/blog/2017/11/28/testing-vue-web-application-with-vuex-data-store-and-rest-backend/#ui-to-store
// https://stackoverflow.com/questions/62297240/access-data-model-in-vuejs-with-cypress-application-actions
// Only available during E2E tests
if (window.Cypress) {

  // Allow tests to access the app instance.
  window.app = app;

  // Disable all transitions to speed up tests and prevent necessary waiting for elements to be visible.
  // The general `*` is not working for all Vuetify components because some components like expansion panels have !important too.
  // Hence, we must turn off transitions for these specific components using precise selectors to override also !importants from Vuetify.
  const testStyles = document.createElement("style")
  testStyles.textContent = `
    *,
    *:before,
    *:after,
    .v-item-group.v-expansion-panels,
    .v-item-group.v-expansion-panels .v-expansion-panel,
    .v-item-group.v-expansion-panels .v-expansion-panel-header,
    .v-item-group.v-expansion-panels .v-expansion-panel-content {
        transition: none !important;
    }`;
  document.head.appendChild(testStyles);
}