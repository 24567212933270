<template>
    <ItemNotFoundState v-if="objectNotFound" :objectName="'Otázka'">
        <template #backButton>
            <BackButton :target="{name: 'faq'}">Často kladené otázky</BackButton>
        </template>
    </ItemNotFoundState>
    <Loading v-else-if="didLoadData === false"/>
    <div v-else>
        <BackButton :target="{name: 'faq'}">Často kladené otázky</BackButton>
        <ModuleHeader>
            <h2 v-if="isNew">Přidání otázky</h2>
            <h2 v-else>Úprava otázky</h2>
            <v-spacer/>
            <SaveButton 
                v-if="question.hidden"
                :disabled="!isFormValid" 
                :is-saving="isSaving" 
                @click="save(false)" 
                label="Uložit" 
            />
            <div v-else>
                <SaveButton 
                    :disabled="!isFormValid" 
                    :is-saving="isSaving" 
                    @click="tryToSaveWithNotification()" 
                    label="Uložit a notifikovat" 
                />
                <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on"><v-icon>more_vert</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="save(false)">
                            <v-list-item-title class="primary--text">Uložit bez upozornění uživatelů</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </ModuleHeader>

        <v-form @submit.prevent="save" ref="form" v-model="isFormValid">
            <v-row class="mt-8">
                <v-col cols="12" md="6">
                    <h5>Kategorie otázky</h5>
                    <FAQCategorySelect
                            :categories="sortedCategories(currentLanguage)"
                            :clearable="false"
                            :language="currentLanguage"
                            v-model="question.categoryID"
                            @change="changeWatch"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <h5>Zobrazení v aplikaci</h5>
                    <v-switch
                        :input-value="!question.hidden"
                        label="Zobrazeno"
                        inset
                        @change="v => { question.hidden = !v; changeWatch(); }"
                    ></v-switch>
                </v-col>
            </v-row>

            <h5 class="mt-8">Obsah</h5>
            <v-simple-table fixed-header>
                <thead>
                <tr>
                    <th scope="col"
                        v-for="(name, key) in languages"
                        :key="key"
                    >
                        {{name}}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td v-for="(name, key) in languages" :key="key">
                        <v-textarea
                                label="Otázka"
                                :value="name"
                                @change="changeWatch"
                                :rules="[required_rule]"
                                v-model="question.localizedAttributes[key].question" 
                                :rows="1"
                                :maxlength="150"
                                auto-grow
                        ></v-textarea>
                    </td>
                </tr>
                <tr>
                    <td v-for="key in Object.keys(languages)" :key="key">
                        <v-textarea
                                @change="changeWatch"
                                :rules="[required_rule]"
                                label="Odpověď"
                                v-model="question.localizedAttributes[key].answer"
                                auto-grow
                                :rows="8"
                        ></v-textarea>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>

            <h5 class="mt-8">Úkoly</h5>
            <FAQQuestionDetailTasks :question="question" ref="tasks"/>
        </v-form>

        <confirm-dialog 
            :model="confirmDialog"
            title="Opravdu notifikovat uživatele?"
            no="Uložit bez notifikace"
            @yes="onConfirmSaveWithNotification()"
            @no="onConfirmSaveWithoutNotification()"
        >
            Všem, kdo používají mobilní aplikaci My MENDELU, přijde upozornění na tuto FAQ otázku. Upozornění je vhodné pouze u kritického obsahu, který se týká všech lidí na univerzitě.
            <br/>
            <img src="@/assets/faq-notification.jpeg" style="width: 100%; margin-top: 1rem;" />
        </confirm-dialog>
    </div>
</template>


<script>
    import {mapGetters, mapActions} from 'vuex';
    import ModuleHeader from "../../components/layout/ModuleHeader";
    import BackButton from "../../components/layout/BackButton";
    import SaveButton from "../../components/buttons/SaveButton";
    import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
    import Loading from "../../components/Loading";
    import {getLocalizedLanguages} from "../../localization/languages";
    import FAQCategorySelect from "../../components/faq/FAQCategorySelect";
    import FAQQuestionDetailTasks from "./FAQQuestionDetailTasks";
    import ItemNotFoundState from '../../components/states/ItemNotFoundState.vue';

    export default {
        name: "FAQQuestionDetail",

        components: {
            FAQQuestionDetailTasks,
            FAQCategorySelect,
            SaveButton,
            BackButton,
            ModuleHeader,
            Loading,
            ConfirmDialog,
            ItemNotFoundState
        },

        mounted() {
            this.loadData();
        },

        watch: {
            questionId() {
                this.loadData();
            }
        },

        data() {
            return {
                required_rule: value => !!value || 'Povinné',
                isSaving: false,
                isFormValid: false,
                confirmDialog: false,
            }
        },

        computed: {
            ...mapGetters('localization', ['currentLanguage']),
            ...mapGetters('FAQQuestions', ['questionById', 'didLoadQuestions', 'prepareNewQuestion']),
            ...mapGetters('FAQCategories', ['sortedCategories', 'didLoadCategories']),
            ...mapGetters('errors', ['objectNotFound']),

            questionId() {
                const urlId = this.$route.params.id;
                return urlId === "new" ? null : Number(urlId);
            },
            isNew() { return this.questionId === null; },
            didLoadData() { return (
                (this.didLoadQuestions || this.isNew) &&
                this.didLoadCategories
            )},
            question() { return this.isNew
                ? this.prepareNewQuestion()
                : this.questionById(this.questionId) },
            languages() { return getLocalizedLanguages(); },
        },

        methods: {
            ...mapActions('leavePrevent', ['madeUnsavedChanges', 'didSaveChanges']),
            ...mapActions('FAQCategories', ['fetchCategories']),
            ...mapActions('FAQQuestions', ['fetchQuestionById', 'createQuestion', 'updateQuestion']),
            ...mapActions('errors', ['clear']),

            loadData() {
                if (!this.isNew) {
                    this.fetchQuestionById(this.questionId);
                } else {
                    this.clear();
                }
                this.fetchCategories();
            },

            changeWatch() {
                this.madeUnsavedChanges();
            },

            tryToSaveWithNotification() {
                this.confirmDialog = true;
            },

            onConfirmSaveWithNotification() {
                this.confirmDialog = false;
                this.save(true);
            },

            onConfirmSaveWithoutNotification() {
                this.confirmDialog = false;
                this.save(false);
            },

            async save(notify = false) {
                if (this.$refs.form.validate()) {
                    this.isSaving = true;

                    if (this.isNew) {
                        this.question.id = await this.createQuestion({question: this.question, notify});
                    } else {
                        await this.updateQuestion({question: this.question, notify});
                    }

                    await this.$refs.tasks.save();
                    this.didSaveChanges();

                    if (this.isNew) {
                        await this.$router.push({name: "faq"});
                    }

                    this.isSaving = false;
                }
            },
        }
    }
</script>

<style scoped>
    th {
        font-size: 1rem !important;
    }
    td {
        vertical-align: top;
    }
    td:nth-child(0), td:nth-child(1), th:nth-child(0), th:nth-child(1) {
        width: 50%;
    }
    thead {
      border-top: none;
    }
   th, tr, td  {
     border: none !important;
   }
</style>
