import {get,} from '@/services/api'
import Vue from "vue";

const state = {
    didLoadedClosedHours: false,
    closedHours: []
};
const getters = {
    didLoadedClosedHours: state => state.didLoadedClosedHours,
    getClosedHours: state => state.closedHours,
    getClosedHour: state => id => {
        return state.closedHours.find(closedHour => closedHour.id === id);
    },
    getTypeClosedHours: state => type => {
        return state.closedHours.filter(element => element.type === type);
    },

    getTypeClosedHoursByDate: state => date => {
        return state.closedHours.filter(element => element.date === date);
    },
};
const actions = {

    //Fetch ALL
    async fetchClosedHours({commit}) {
        commit('setDidLoadedClosedHours', false);
        await get('study_department/assistant/closed_hours/').then((response) => {
            commit('setClosedHours', response.data.items);
            commit('setDidLoadedClosedHours', true);
        });
    },

    setClosedHoursStorage({commit}, items) {
        commit('setClosedHours', items);
    },

    setDidLoadedClosedHoursStorage({commit}, val) {
        commit('setDidLoadedClosedHours', val);
    },

    pushToClosedHoursStorage({commit}, item) {
        commit('setDidLoadedClosedHours', false);
        commit('pushClosedHours', item);
        commit('setDidLoadedClosedHours', true);
    },

    deleteFromClosedHoursStorage({commit}, item) {
        commit('setDidLoadedClosedHours', false);
        commit('removeClosedHours', item);
        commit('setDidLoadedClosedHours', true);
    },

    updateClosedHoursStorage({commit}, item) {
        commit('setDidLoadedClosedHours', false);
        commit('updateClosedHours', item);
        commit('setDidLoadedClosedHours', true);
    },
};

const mutations = {
    setDidLoadedClosedHours: (state, value) => (
        state.didLoadedClosedHours = value
    ),
    setClosedHours: (state, closedHours) => {
        state.closedHours = closedHours
    },
    updateClosedHours: (state, item) => {
        const index = state.closedHours.findIndex(i => i.id === item.id);
        Vue.set(state.closedHours, index, item);
    },
    pushClosedHours: (state, item) => {
        state.closedHours.push(item)
    },
    removeClosedHours: (state, item) => {
        const index = state.closedHours.findIndex(i => i.id === item.id);
        Vue.delete(state.closedHours, index)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
