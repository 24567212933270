import Vue from 'vue';
import Router from 'vue-router';

import AuthSection from "./AuthSection";
import LeavePrevent from "./services/LeavePrevent";
import LoggedOut from "./views/LoggedOut/LoggedOut";
import NotFound from './components/NotFound';

// FAQ
import FAQCategories from './views/FAQ/FAQCategories.vue';
import FAQQuestions from './views/FAQ/FAQQuestions.vue';
import FAQQuestionDetail from "./views/FAQ/FAQQuestionDetail";

// Reports
import Reports from './views/Reports/Reports.vue';
import ReportDetail from "./views/Reports/ReportDetail";
import WebReportDetail from "./views/Reports/WebReportDetail"

// Warnings
import Warnings from "./views/Warnings/Warnings";
import WarningDetail from "./views/Warnings/WarningDetail";
import WarningsPortal from "@/views/Warnings/WarningsPortal";
import WarningsStatistics from "@/views/Warnings/WarningsStatistics";

// Map
import Map from './views/Map/Map.vue';
import MapDetail from "./views/Map/MapDetail";
import EmbeddedMap from "./views/Map/EmbeddedMap";
import EmbeddedMapGenerator from "./views/Map/EmbeddedMapGenerator";

// Dashboard
import Dashboard from './views/Dashboard/Dashboard';

// Study department
import AssistantDetail from "./views/StudyDepartment/entitiesComponents/Children/Assistants/AssistantDetail";
import AssistantPortal from "./views/StudyDepartment/entitiesComponents/Children/Assistants/AssistantPortal";
import BasicEntities from "./views/StudyDepartment/entitiesComponents/BasicEntities";
import CalendarDetail from "./views/StudyDepartment/reservationComponents/Calendar/CalendarDetail";
import EntitiesPortal from "./views/StudyDepartment/entitiesComponents/Entities/EntitiesPortal";
import CheckIn from "./views/StudyDepartment/checkInComponents/CheckIn";
import NewRestriction from "./views/StudyDepartment/reservationComponents/Children/Restriction/NewRestriction";
import QueueManagement from './views/StudyDepartment/QueueManagement';
import ReasonsDetail from "./views/StudyDepartment/entitiesComponents/Children/Reasons/ReasonsDetail";
import ReasonsPortal from "./views/StudyDepartment/entitiesComponents/Children/Reasons/ReasonsPortal";
import Reservation from "./views/StudyDepartment/reservationComponents/Reservation";
import ReservationDetail from "./views/StudyDepartment/reservationComponents/Children/Reservation/ReservationDetail";
import ReservationDetailEdit from "./views/StudyDepartment/reservationComponents/Children/Reservation/ReservationDetailEdit";
import RestrictionDetail from "./views/StudyDepartment/reservationComponents/Children/Restriction/RestrictionDetail"
import RestrictionDetailEdit from "./views/StudyDepartment/reservationComponents/Children/Restriction/RestrictionDetailEdit"
import Statistics from "./views/StudyDepartment/statisticsComponents/Statistics";
import StatisticsOverview from "./views/StudyDepartment/statisticsComponents/StatisticsOverview";
import StatisticsOccupation from "./views/StudyDepartment/statisticsComponents/StatisticsOccupation";
import StudyFieldDetail from "./views/StudyDepartment/entitiesComponents/Children/StudyField/StudyFieldDetail";
import StudyFieldPortal from "./views/StudyDepartment/entitiesComponents/Children/StudyField/StudyFieldPortal";
import StudyOpenHoursDetail from "./views/StudyDepartment/entitiesComponents/Children/StudyOpenHours/StudyOpenHoursDetail";
import StudyProblemsCategoryPortal from "./views/StudyDepartment/entitiesComponents/Children/StudyProblemsCategory/StudyProblemsCategoryPortal";
import StudyProblemsCategoryDetail from "./views/StudyDepartment/entitiesComponents/Children/StudyProblemsCategory/StudyProblemsCategoryDetail";
import StudyProblemsItemPortal from "./views/StudyDepartment/entitiesComponents/Children/StudyProblemsItem/StudyProblemsItemPortal";
import StudyProblemsItemDetail from "./views/StudyDepartment/entitiesComponents/Children/StudyProblemsItem/StudyProblemsItemDetail";


// Displays
import Announcements from './views/Displays/announcements/Announcements';
import AnnouncementDetail from './views/Displays/announcements/AnnouncementDetail';
import AnnouncementTypes from './views/Displays/announcement-types/AnnouncementTypes';
import AnnouncementTypeDetail from './views/Displays/announcement-types/AnnouncementTypeDetail';
import DisplaysEntry from './views/Displays/DisplaysEntry';
import DisplaysAdministrators from './views/Displays/Administrators';
import DisplaysAdministratorDetail from "@/views/Displays/AdministratorDetail";
import DisplaysUnits from './views/Displays/Units';
import Displays from './views/Displays/Displays';
import DisplaysGuide from './views/Displays/DisplaysGuide';
import DisplayDetail from './views/Displays/DisplayDetail';

// Beacons
import Beacons from "./views/Beacons/Beacons";
import BeaconDetail from "./views/Beacons/BeaconDetail";
import BeaconPurposes from "./views/Beacons/BeaconPurposes";
import BeaconPurposeDetail from "./views/Beacons/BeaconPurposeDetail";

// Users
import Users from "./views/Users/Users";

// Gamification
import Activities from "./views/Gamification/Activities";
import Badges from "./views/Gamification/Badges";
import Gamification from "./views/Gamification/Gamification";
import QuestionDetail from "./views/Gamification/QuestionDetail";
import Subjects from "./views/Gamification/Subjects";
import SubjectCategories from "./views/Gamification/SubjectCategories"
import SubjectDetail from "./views/Gamification/SubjectDetail";
import TestDetail from "./views/Gamification/TestDetail";
import GamificationDashboard from "./views/Gamification/GamificationDashboard";

// Onboarding
import LocationDetail from "./views/Onboarding/Locations/LocationDetail";
import OnboardingMaster from "./views/Onboarding/OnboardingMaster";
import ScenarioDetail from "./views/Onboarding/Scenarios/ScenarioDetail";

// Schedules
import Schedules from "./views/Schedules/Schedules";
import SchedulesDetail from "./views/Schedules/SchedulesDetail";

// Smart University
import SensorsDashboard from "./views/SmartUniversity/SensorsDashboard"
import IotPlaceDetail from "./views/SmartUniversity/IotPlaceDetail"

// Helpdesk
import Helpdesk from "./views/Helpdesk/Helpdesk"
import PreviousPathMemory from "@/services/PreviousPathMemory";

//News
import Faculties from "@/views/News/Faculties";
import Newsfeed from "@/views/News/Newsfeed";
import Administrators from "@/views/News/Administrators";
import AdministratorDetail from "@/views/News/AdministratorDetail";
import NewsfeedDetail from "@/views/News/NewsfeedDetail";
import News from "@/views/News/News";
import NewsfeedStatistics from "@/views/News/NewsfeedStatistics";

//Administration
import Administration from "@/views/StudyDepartment/entitiesComponents/Administration";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: { name: 'dashboard' }
        },
        {
            path: '/logged-out',
            name: 'loggedOut',
            component: LoggedOut
        },
        {
            path: '/mapwidget',
            name: 'embeddedMapGenerator',
            component: EmbeddedMapGenerator,
            meta: {
                query: {
                    'selectedBuilding': 'int:-1',
                    'selectedPlace': 'int:null',
                    'selectedFloor': 'int:null',
                }
            }
        },
        {
            path: '/mapwidget/embed',
            name: 'embeddedMap',
            component: EmbeddedMap
        },
        {
            path: '/auth',
            component: AuthSection,
            beforeEnter: requireAuth,
            meta: {
                allowedRoles: ['*']
            },
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: Dashboard,
                    meta: {
                        allowedRoles: ['*']
                    },
                },
                {
                    path: 'faq',
                    name: 'faq',
                    component: FAQQuestions,
                    meta: {
                        allowedRoles: ['ADMIN', 'FAQ_ADMIN'],
                        query: {
                            'categoryFilter': 'int:null',
                        },
                    },
                },
                {
                    path: 'faq/categories',
                    name: 'faqCategories',
                    component: FAQCategories,
                    meta: {
                        allowedRoles: ['ADMIN', 'FAQ_ADMIN'],
                    },
                },
                {
                    path: 'faq/:id',
                    name: 'faqQuestionDetail',
                    component: FAQQuestionDetail,
                    meta: {
                        allowedRoles: ['ADMIN', 'FAQ_ADMIN'],
                    },
                },
                {
                    path: 'displays',
                    name: 'displays',
                    component: DisplaysEntry,
                    redirect: {name: 'displaysUnits'},
                    children: [
                        {
                            path: 'units/:unitId/announcement-types/:announcementTypeId',
                            name: 'announcementTypeDetail',
                            component: AnnouncementTypeDetail,
                            meta: {
                                allowedRoles: ['ADMIN', 'DISPLAY_ADMIN'],
                            },
                        },
                        {
                            path: 'units/:unitId/announcement-types',
                            name: 'announcementTypes',
                            component: AnnouncementTypes,
                            meta: {
                                allowedRoles: ['ADMIN', 'DISPLAY_ADMIN'],
                                query: {
                                    'sort': 'string:name',
                                    'desc': 'bool:false',
                                },
                            },
                        },
                        {
                            path: 'units/:unitId/announcements/:announcementId',
                            name: 'announcementDetail',
                            component: AnnouncementDetail,
                            meta: {
                                allowedRoles: ['ADMIN', 'DISPLAY_ADMIN'],
                            },
                        },
                        {
                            path: 'units/:unitId/announcements',
                            name: 'announcements',
                            component: Announcements,
                            meta: {
                                allowedRoles: ['ADMIN', 'DISPLAY_ADMIN'],
                                query: {
                                    "search": 'int:null',
                                    'sort': 'string:title',
                                    'desc': 'bool:false',
                                },
                            },
                        },
                        {
                            path: 'units/:unitId/displays/:displayId',
                            name: 'displayDetail',
                            component: DisplayDetail,
                            meta: {
                                allowedRoles: ['ADMIN', 'DISPLAY_ADMIN'],
                            },
                        },
                        {
                            path: 'units/:unitId/displays/guide',
                            name: 'displaysGuide',
                            component: DisplaysGuide,
                            meta: {
                                allowedRoles: ['ADMIN', 'DISPLAY_ADMIN'],
                            },
                        },
                        {
                            path: 'units/:unitId/displays',
                            name: 'unitDetail',
                            component: Displays,
                            meta: {
                                allowedRoles: ['ADMIN', 'DISPLAY_ADMIN'],
                            }
                        },
                        {
                            path: 'units',
                            name: 'displaysUnits',
                            component: DisplaysUnits,
                            meta: {
                                allowedRoles: ['ADMIN','USER'],
                            }
                        },
                        {
                            path: 'administrators',
                            name: 'displaysAdministrators',
                            component: DisplaysAdministrators,
                            meta: {
                                allowedRoles: ['ADMIN'],
                            },
                        },
                        {
                            path: 'administrator/:administratorId',
                            name: 'administratorDetail',
                            component: DisplaysAdministratorDetail,
                            meta: {
                                allowedRoles: ['ADMIN'],
                            },
                        },
                    ]
                },
                {
                    path: 'reports',
                    redirect: { name: 'reports', params: {tab: 'new'}},
                    meta: {
                        allowedRoles: ['ADMIN', 'REPORT_ADMIN'],
                    }
                },
                {
                    path: 'reports/',
                    name: 'reports',
                    component: Reports,
                    meta: {
                        allowedRoles: ['ADMIN', 'REPORT_ADMIN'],
                        query: {
                            'rowsPerPage': 'int:10',
                            'page': 'int:1',
                            'tab' : 'string:new'
                        }
                    }
                },
                {
                    path: 'reports/:id',
                    name: 'reportDetail',
                    component: ReportDetail,
                    meta: {
                        allowedRoles: ['ADMIN', 'REPORT_ADMIN'],
                    },
                },
                {
                    path: 'web-reports/:id',
                    name: 'webReportDetail',
                    component: WebReportDetail,
                    meta: {
                        allowedRoles: ['ADMIN', 'REPORT_ADMIN'],
                    },
                },

                {
                    path: 'map',
                    redirect: { name: 'map', params: {tab: 'places'}},
                    meta: {
                        allowedRoles: ['ADMIN', 'MAP_ADMIN'],
                    }
                },

                {
                    path: 'map/:tab',
                    name: 'map',
                    component: Map,
                    meta: {
                        allowedRoles: ['ADMIN', 'MAP_ADMIN'],
                        query: {
                            'sort': 'string:name',
                            'desc': 'bool:false',
                            'page': 'int:1',
                            'rowsPerPage': 'int:10',
                            'itemsPerPage': 'int:10',
                            'search': 'string:',
                            'placeType': 'string:',
                            'buildingId': 'int:null',
                            'floorId': 'int:null',
                        }
                    },
                },
                {
                    path: 'support',
                    name: 'support',
                    component: Helpdesk,
                    meta: {
                        allowedRoles: ['USER', 'ADMIN'],
                    },
                },
                {
                    path: 'map/detail/:type/:id',
                    name: 'mapDetail',
                    component: MapDetail,
                    meta: {
                        allowedRoles: ['ADMIN', 'MAP_ADMIN'],
                    },
                },
                {
                    path: 'users',
                    name: 'users',
                    component: Users,
                    meta: {
                        allowedRoles: ['ADMIN'],
                    },
                },
                {
                    path: 'study-department',
                    name: 'StudyDepartment',
                    component: QueueManagement,
                    meta: {
                        layout: 'queue',
                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                    },
                    children: [
                        {
                            path: 'reservation',
                            props: true,
                            component: Reservation,
                            meta: {
                                allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                            },
                            children: [
                                {
                                    path: 'calendar/detail',
                                    name: 'CalendarDetail',
                                    props: true,
                                    component: CalendarDetail,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                },
                                {
                                    path: 'reservation/detail/:Rid',
                                    name: 'ReservationDetail',
                                    component: ReservationDetail,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                    props: true,
                                },
                                {
                                    path: 'reservation/detail/edit/:Rid',
                                    name: 'ReservationDetailEdit',
                                    component: ReservationDetailEdit,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                    props: true,
                                },
                                {
                                    path: 'restriction/new',
                                    name: 'NewRestriction',
                                    component: NewRestriction,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                    props: true,
                                },
                                {
                                    path: 'restriction/detail/:Rid',
                                    name: 'RestrictionDetail',
                                    component: RestrictionDetail,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                    props: true,
                                },
                                {
                                    path: 'restriction/detail/edit/:Rid',
                                    name: 'RestrictionDetailEdit',
                                    component: RestrictionDetailEdit,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                    props: true,
                                },
                            ],
                        },
                        {
                            path: 'basicEntities',
                            name: 'BasicEntities',
                            component: BasicEntities,
                            props: true,
                            meta: {
                                allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                            },
                            children: [
                                {
                                    path: 'studyField/detail/:Rid',
                                    name: 'StudyFieldDetail',
                                    props: true,
                                    component: StudyFieldDetail,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                },
                                {
                                    path: 'studyField/portal',
                                    name: 'StudyFieldPortal',
                                    component: StudyFieldPortal,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                        query: {
                                            'sort' : 'string:shortCode',
                                            'desc' : "bool:false",
                                            'degree': 'string:',
                                            'form' : 'string:',
                                            'sortArchived' : 'string:localizedAttributes.cz.title',
                                            'descArchived' : "bool:false",
                                        }
                                    },
                                },
                                {
                                    path: 'EntitiesPortal',
                                    name: 'EntitiesPortal',
                                    component: EntitiesPortal,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                },
                                {
                                    path: 'studyOpenHours/portal',
                                    name: 'StudyOpenHoursDetail',
                                    component: StudyOpenHoursDetail,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                },
                                {
                                    path: 'studyProblems/category/portal',
                                    name: 'StudyProblemsCategoryPortal',
                                    component: StudyProblemsCategoryPortal,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                        query: {
                                            'sort' : 'string:name',
                                            'desc' : "bool:false",
                                        }
                                    },
                                },

                                {
                                    path: 'studyProblems/category/:Rid',
                                    name: 'StudyProblemsCategoryDetail',
                                    component: StudyProblemsCategoryDetail,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                },
                                {
                                    path: 'studyProblems/item/portal',
                                    name: 'StudyProblemsItemPortal',
                                    component: StudyProblemsItemPortal,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                        query: {
                                            'sort' : 'string:name',
                                            'desc' : "bool:false",
                                        }
                                    },
                                },
                                {
                                    path: 'studyProblems/item/:Rid',
                                    name: 'StudyProblemsItemDetail',
                                    props: true,
                                    component: StudyProblemsItemDetail,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                },
                                {
                                    path: 'assistant/detail/:Rid',
                                    name: 'AssistantDetail',
                                    props: true,
                                    component: AssistantDetail,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                },
                                {
                                    path: 'assistant/portal',
                                    name: 'AssistantPortal',
                                    component: AssistantPortal,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                        query: {
                                            'sort' : 'string:name',
                                            'desc' : "bool:false",
                                        }
                                    },
                                },
                                {
                                    path: 'reasons/detail/',
                                    name: 'ReasonsDetail',
                                    props: true,
                                    component: ReasonsDetail,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                    },
                                },
                                {
                                    path: 'reasons/portal',
                                    name: 'ReasonsPortal',
                                    component: ReasonsPortal,
                                    meta: {
                                        allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                                        query: {
                                            'sort' : 'string:reason',
                                            'desc' : "bool:false",
                                        }
                                    },
                                },
                            ]
                        },
                        {
                            path: 'checkIn',
                            name: 'CheckIn',
                            component: CheckIn,
                            meta: {
                                allowedRoles: ['STUDY', 'STUDY_ADMIN'],
                            },
                        },
                        {
                            path: 'statistics',
                            component: Statistics,
                            meta: {
                                allowedRoles: ['STUDY_ADMIN'],
                            },
                            children: [
                                {
                                    path: '',
                                    name: 'StatisticsOverview',
                                    component: StatisticsOverview,
                                    meta: {
                                        allowedRoles: ['STUDY_ADMIN'],
                                    },
                                },
                                {
                                    path: 'occupation',
                                    name: 'StatisticsOccupation',
                                    component: StatisticsOccupation,
                                    meta: {
                                        allowedRoles: ['STUDY_ADMIN'],
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'warnings',
                    name: 'WarningsPortal',
                    redirect: {name: 'Messages'},
                    component: WarningsPortal,
                    meta: {
                        allowedRoles: ['ADMIN', 'WARNING_ADMIN'],
                    },
                    children: [
                        {
                            path: 'messages',
                            name: 'Messages',
                            component: Warnings,
                            meta: {
                                allowedRoles: ['ADMIN', 'WARNING_ADMIN'],
                                query: {
                                    'sort' : 'string:localizedAttributes.cz.title',
                                    'desc' : "bool:false",
                                    'archived' : 'int:0',
                                }
                            },
                        },
                        {
                            path: 'messages/:id',
                            name: 'messageDetail',
                            component: WarningDetail,
                            meta: {
                                allowedRoles: ['ADMIN', 'WARNING_ADMIN'],
                            },
                        },
                        {
                            path: 'statistics',
                            name: 'WarningsStatistics',
                            component: WarningsStatistics,
                            meta: {
                                allowedRoles: ['ADMIN', 'WARNING_ADMIN'],
                            },
                        },
                    ]
                },
                {
                    path: 'iot/beacons',
                    name: 'Beacons',
                    component: Beacons,
                    meta: {
                        allowedRoles: ['ADMIN', 'BEACON_ADMIN'],
                        query:{
                            'sort': 'string:',
                            'desc': 'bool:false',
                            'rowsPerPage': 'int:10',
                            'page': 'int:1',
                            'search': 'string:',
                            'filtering': 'bool:false',
                            'placeType' : 'string:',
                            'buildingId' : 'int:null',
                            'floorId' : 'int:null',
                            'state' : 'string:',
                            'protocol' : 'string:',
                            'purposeId' : 'int:null',
                            'batteryPlacementFrom' : 'string:',
                            'batteryPlacementTo' : 'string:'


                        }
                    },
                },
                {
                    path: 'iot/beacons/purposes',
                    name: 'BeaconPurposes',
                    component: BeaconPurposes,
                    meta: {
                        allowedRoles: ['ADMIN', 'BEACON_ADMIN'],
                        query:{
                            'sort': 'string:',
                            'desc': 'bool:false',
                            'rowsPerPage': 'int:10',
                            'page': 'int:1',
                        }

                    },
                },
                {
                    path: 'iot/beacons/:id',
                    name: 'BeaconDetail',
                    component: BeaconDetail,
                    meta: {
                        allowedRoles: ['ADMIN', 'BEACON_ADMIN'],
                    },
                },
                {
                    path: 'iot/beacons/purposes/:id',
                    name: 'BeaconPurposeDetail',
                    component: BeaconPurposeDetail,
                    meta: {
                        allowedRoles: ['ADMIN', 'BEACON_ADMIN'],
                    },
                },
                {
                    path: 'iot/places',
                    name: 'SensorsDashboard',
                    component: SensorsDashboard,
                    meta: {
                        allowedRoles: ['ADMIN'],
                    },
                },
                {
                    path: 'iot/places/:id',
                    name: 'iotPlaceDetail',
                    component: IotPlaceDetail,
                    meta: {
                        allowedRoles: ['ADMIN'],
                    },
                },

                {
                    path: 'gamification/subjects/:subjectId/master',
                    name: 'Gamification',
                    component: Gamification,
                    meta: {
                        allowedRoles: ['ADMIN', 'GAMIFICATION_ADMIN'],
                        query: {
                            'sort': 'string:localizedAttributes.cz.name',
                            'desc': 'bool:false',
                            'page': 'int:1',
                            'rowsPerPage': 'int:10',
                            'questionsSearch' : "string:",
                            'onlyStudentQuestions' : 'bool:false',
                            'tab' : 'string:tests'
                        }
                    },
                },
                {
                    path: 'gamification',
                    redirect: {name: 'GamificationDashboard'},
                    meta: {
                        allowedRoles: ['ADMIN', 'GAMIFICATION_ADMIN'],
                    },
                },
                {
                    path: 'gamification/dashboard',
                    name: 'GamificationDashboard',
                    component: GamificationDashboard,
                    meta: {
                        allowedRoles: ['ADMIN', 'GAMIFICATION_ADMIN'],
                    },
                },
                {
                    path: 'gamification/subjects',
                    name: 'Subjects',
                    component: Subjects,
                    meta: {
                        allowedRoles: ['ADMIN', 'GAMIFICATION_ADMIN'],
                        query: {
                            'sort': 'string:localizedAttributes.cz.name',
                            'desc': 'bool:false',
                            'page': 'int:1',
                            'rowsPerPage': 'int:10',
                        }
                    },
                },
                {
                    path: 'gamification/subjects/:subjectId',
                    name: 'SubjectDetail',
                    component: SubjectDetail,
                    meta: {
                        allowedRoles: ['ADMIN', 'GAMIFICATION_ADMIN'],
                    },
                },
                {
                    path: 'gamification/subjects/:subjectId/tests/:testId',
                    name: 'TestDetail',
                    component: TestDetail,
                    meta: {
                        allowedRoles: ['ADMIN', 'GAMIFICATION_ADMIN'],
                    },
                },
                {
                    path: 'gamification/subjects/:subjectId/categories',
                    name: 'SubjectCategories',
                    component: SubjectCategories,
                    meta: {
                        allowedRoles: ['ADMIN', 'GAMIFICATION_ADMIN'],
                        query: {
                            'sort': 'string:localizedAttributes.cz.name',
                            'desc': 'bool:false',
                            'page': 'int:1',
                            'rowsPerPage': 'int:10',
                        }
                    },
                },
                {
                    path: 'gamification/activities',
                    name: 'Activities',
                    component: Activities,
                    meta: {
                        allowedRoles: ['ADMIN', 'GAMIFICATION_ADMIN'],
                    },
                },
                {
                    path: 'gamification/subjects/:subjectId/questions/:questionId/',
                    name: 'QuestionDetail',
                    component: QuestionDetail,
                    meta: {
                        allowedRoles: ['ADMIN', 'GAMIFICATION_ADMIN'],
                    },
                },
                {
                    path: 'gamification/badges',
                    name: 'Badges',
                    component: Badges,
                    meta: {
                        allowedRoles: ['ADMIN', 'GAMIFICATION_ADMIN'],
                    },
                },

                {
                    path: 'onboarding',
                    redirect: {name: 'OnboardingMaster'},
                    allowedRoles: ['ADMIN', 'ONBOARDING_ADMIN'],

                },

                {
                    path: 'onboarding/',
                    name: 'OnboardingMaster',
                    component: OnboardingMaster,
                    meta: {
                        allowedRoles: ['ADMIN', 'ONBOARDING_ADMIN'],
                        query: {
                            'sort': 'string:id',
                            'desc': 'bool:false',
                            'rowsPerPage': 'int:10',
                            'page': 'int:1',
                            'tab' : 'string:locations'
                        },
                    },
                },
                {
                    path: 'onboarding/locations/:id',
                    name: 'locationDetail',
                    component: LocationDetail,
                    meta: {
                        allowedRoles: ['ADMIN', 'ONBOARDING_ADMIN'],
                    },
                },
                {
                    path: 'onboarding/scenarios/:id',
                    name: 'scenarioDetail',
                    component: ScenarioDetail,
                    meta: {
                        allowedRoles: ['ADMIN', 'ONBOARDING_ADMIN'],
                    },
                },
                {
                    path: 'schedules',
                    name: 'Schedules',
                    component: Schedules,
                    meta: {
                        allowedRoles: ['ADMIN'],
                    },
                },
                {
                    path: 'schedules/:faculty',
                    name: 'SchedulesDetail',
                    component: SchedulesDetail,
                    meta: {
                        allowedRoles: ['ADMIN'],
                    },
                },
                {
                    path: 'study-department/administration',
                    name: 'Administration',
                    component: Administration,
                    meta: {
                        allowedRoles: ['ADMIN'],
                    },
                },
                {
                    path: 'news',
                    name: 'News',
                    redirect: {name: 'Faculties'},
                    component: News,
                    meta: {
                        allowedRoles: ['ADMIN','USER'],
                    },
                    children: [
                        {
                            path: 'faculties',
                            name: 'Faculties',
                            component: Faculties,
                            meta: {
                                allowedRoles: ['ADMIN','USER'],
                            },
                        },
                        {
                            path: 'faculties/:id',
                            name: 'Newsfeed',
                            component: Newsfeed,
                            meta: {
                                allowedRoles: ['ADMIN','USER'],
                            },
                        },
                        {
                            path: 'administrators',
                            name: 'Administrators',
                            component: Administrators,
                            meta: {
                                allowedRoles: ['ADMIN','USER'],
                            },
                        },
                        {
                            path: 'administrators/:id',
                            name: 'AdministratorDetail',
                            component: AdministratorDetail,
                            meta: {
                                allowedRoles: ['ADMIN','USER'],
                            },
                        },
                        {
                            path: 'faculties/:id/newsfeed/:idNews',
                            name: 'NewsfeedDetail',
                            component: NewsfeedDetail,
                            meta: {
                                allowedRoles: ['ADMIN','USER'],
                            },
                        },
                        {
                            path: 'statistics',
                            name: 'NewsfeedStatistics',
                            component: NewsfeedStatistics,
                            meta: {
                                allowedRoles: ['ADMIN','USER'],
                            },
                        },
                    ],
                },

                // RENTS - not ready yet
                // {
                //     path: 'rent/my-rents',
                //     name: 'Rents',
                //     component: Rents,
                //     meta: {
                //         allowedRoles: ['*'],
                //     },
                // },
                // {
                //     path: 'rent/technician-rent',
                //     name: 'TechnicianRentsRequests',
                //     component: TechnicianRentsRequests,
                //     meta: {
                //         allowedRoles: ['*'],
                //     },
                // },
                // {
                //     path: 'rent/:id',
                //     name: 'RentDetail',
                //     component: RentDetail,
                //     meta: {
                //         allowedRoles: ['*'],
                //     },
                // },
                // {
                //     path: 'request/my-request',
                //     name: 'Requests',
                //     component: Requests,
                //     meta: {
                //         allowedRoles: ['*'],
                //     },
                // },
                // {
                //     path: 'request/to-approve',
                //     name: 'GarantRequests',
                //     component: GarantRequests,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_GARANT'],
                //     },
                // },
                // {
                //     path: 'request/:id',
                //     name: 'RequestDetail',
                //     component: RequestDetail,
                //     meta: {
                //         allowedRoles: ['*'],
                //     },
                // },
                // {
                //     path: 'technics/model-type',
                //     name: 'ModelTypes',
                //     component: ModelTypes,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // {
                //     path: 'technics/model-type/:id',
                //     name: 'ModelTypeDetail',
                //     component: ModelTypeDetail,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // {
                //     path: 'technics/model',
                //     name: 'Models',
                //     component: Models,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // {
                //     path: 'technics/model?modelTypeId=:modelTypeId',
                //     name: 'ModelsOverview',
                //     component: ModelsOverview,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // {
                //     path: 'technics/model/:id',
                //     name: 'ModelDetail',
                //     component: ModelDetail,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // {
                //     path: 'technics/device',
                //     name: 'Devices',
                //     component: Devices,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // {
                //     path: 'technics/device?modelId=:modelId',
                //     name: 'DevicesOverview',
                //     component: DevicesOverview,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // {
                //     path: 'technics/device/:id',
                //     name: 'DeviceDetail',
                //     component: DeviceDetail,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // {
                //     path: 'technics/control',
                //     name: 'Controls',
                //     component: Controls,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // {
                //     path: 'technics/control/:id',
                //     name: 'ControlDetail',
                //     component: ControlDetail,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // {
                //     path: 'rent-users',
                //     name: 'RentUsers',
                //     component: RentUsers,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // {
                //     path: 'groups',
                //     name: 'Groups',
                //     component: Groups,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // {
                //     path: 'groups/:id',
                //     name: 'GroupDetail',
                //     component: GroupDetail,
                //     meta: {
                //         allowedRoles: ['ADMIN', 'RENT_ADMIN'],
                //     },
                // },
                // END of RENTS
            ]
        },
        {
            path: '*',
            name: 'NotFound',
            component: NotFound,
        }
    ],
});

// display a confirmation dialog in case of unsaved changes
router.beforeEach((to, from, next) => {
    PreviousPathMemory.save(from)
    return LeavePrevent.confirmRouteChange(next)
})

function requireAuth(to, from, next) {
    Vue.$auth.checkLogin(isLoggedIn => {
        if (isLoggedIn) {
            next();
        } else {
            Vue.$auth.login(to.fullPath)
            next(false);
        }
    });
}

export default router;
