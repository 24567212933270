<template>
    <v-container>
        <v-row>
            <v-col>
                <v-tabs icons-and-text color="light-green" class="top_menubar">
                    <v-tab :to="{name: 'Messages'}">
                        <span v-if="isNotMobile" class="mt-2">{{ $t('warnings.warnings') }} </span>
                        <v-icon >announcement</v-icon>
                    </v-tab>
                    <v-tab :to="{name: 'WarningsStatistics'}">
                        <span v-if="isNotMobile" class="mt-2">{{ $t('warnings.statistics') }}</span>
                        <v-icon>insights</v-icon>
                    </v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <router-view/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "WarningsPortal",

    computed: {
        isNotMobile() {
            return !this.$vuetify.breakpoint.xs;
        }
    }
}
</script>

<style scoped>

.top_menubar {
    border-bottom: 2px solid #c4c4c4;
}

</style>
