<template>
    <div>
        <div class="div_layout_info_text" v-if="getCountDownTime > 1">
            <span class="call_in_info_text max_width"> {{ $t('checkIn.waiting_left') }}  </span>

            <span
                class="call_in_info_time max_width"> {{
                    $tc('checkIn.sec', getCountDownTime)
                }} </span>
        </div>

        <div class="div_layout_info_text2" v-else>
            <span class="call_in_info_text max_width"> {{ $t('checkIn.student_did_not_come') }}  </span>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: "Timer",

    data() {
        return {
            defaultTime: 120,
        }
    },

    mounted() {
        this.$root.$on("resetTimerValues", this.resetTimerValues)

        if (this.getCountDownTime === null) {
            this.setCountDownTime(this.defaultTime);
            this.startTimer();
        }
    },

    beforeDestroy() {
      this.$root.$off("resetTimerValues", this.resetTimerValues);
    },

    computed: {
        ...mapGetters('studyFields', ['getCountDownTime', 'getCountDownTimerId'])
    },

    methods: {
        ...mapActions('studyFields', ['setCountDownTime', 'setCountDownTimerId']),

        startTimer() {
            this.stopTimer();
            this.setCountDownTimerId(setInterval(() => {
                //console.log(this.defaultTime)
                if (this.defaultTime > 1) {
                    this.defaultTime--;
                    this.setCountDownTime(this.defaultTime);
                } else {
                    this.stopTimer();
                }
            }, 1000))
        },

        stopTimer() {
            clearInterval(this.getCountDownTimerId);
        },

        resetTimerValues() {
            this.stopTimer();
            this.setCountDownTime(null);
            this.setCountDownTimerId(null);
        }
    },

}
</script>

<style scoped>
.div_layout_info_text {
    width: 100%;
    display: block;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
}

.call_in_info_text {
    margin: 1rem 0 0 0 !important;
    font-size: large;
    font-weight: 400;
    line-height: 1.5;
    color: #ff5700;
}

.max_width {
    width: 100%;
    float: left;
}

.call_in_info_time {
    margin: 0.5rem 0 0 0 !important;
    font-size: x-large;
    font-weight: 400;
    line-height: 1;
    color: #FF5700;
}

.div_layout_info_text2 {
    display: block;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
}
</style>
