<template>
    <Loading v-if="!didLoadData"/>
    <v-container v-else-if="!administrator && !isNew">
        <v-row>
            <v-col>
                <BackButton :target="{name: 'Administrators'}">{{ $t('news.users_list') }}</BackButton>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <PlaceHolder
                    :path="'/icons/something-went-wrong.png'"
                    :text="$t('news.something_went_wrong_text')"
                    :title="$t('news.something_went_wrong_title')">
                </PlaceHolder>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else>
        <Alert
            :model="getErr"
            :onConfirm="() => this.setErr(false)">
            {{ $t('news.user_exist') }}
        </Alert>
        <v-row class="font-weight-medium mb-3">
            <v-col cols="12" md="8" class="pa-0">
                <BackButton :target="{name: 'Administrators'}">{{ $t('news.users_list') }}</BackButton>
            </v-col>
            <v-col cols="12" md="4" align-self="center" class="d-flex justify-md-end justify-start pa-0">
                <SaveButton
                    v-if="!isSaved"
                    :disabled="isFormInvalid || updatedUser"
                    @click="saveUser()"
                    :label="$t('news.save')"
                    data-cy="save"
                />
                <v-btn v-else outlined>
                    <v-icon left>check</v-icon>
                    {{ $t('news.saved') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pa-0">
                <h1 v-if="!isNew" class="font-weight-medium">{{ administrator.fullName }}</h1>
                <h1 v-else class="font-weight-medium"> {{ $t('news.new_user') }}</h1>
            </v-col>
        </v-row>
        <v-row v-if="!isNew" class="mb-1">
            <v-col class="pa-0">
                <span class="subTitle">{{ administrator.xlogin }}</span>
            </v-col>
        </v-row>
        <v-row v-else-if="!controlData" class="mr-1">
            <v-col cols="6" class="pl-0">
                <v-text-field
                    color="light-green darken-1"
                    v-model="xlogin"
                    :label="$t('news.user_xlogin')"
                    :rules="[required_rule]"
                    data-cy="xlogin"
                >
                </v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    color="light-green darken-1"
                    v-model="fullName"
                    :label="$t('news.user_name')"
                    :rules="[required_rule]"
                    data-cy="fullName"
                >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="!controlData" class="mt-7">
            <v-col class="pa-0">
                <v-container fluid>
                    <v-row class="mb-1">
                        <v-col class="pa-0">
                            <h3 class="font-weight-medium">{{ $t('news.faculties_permissions') }}</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pa-0">
                            <NewsCards :input="getFacultiesForEdit" data="faculty" @clicked="onClickChild"
                                       :selectedItems="selectedFaculties" data-cy="card-faculty">
                            </NewsCards>
                        </v-col>
                    </v-row>
                    <v-row class="mb-1">
                        <v-col class="pa-0">
                            <h3 class="font-weight-medium">{{ $t('news.audiences_permissions') }}</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pa-0">
                            <NewsCards :input="getAudiencesForEdit" data="audience" @clicked="onClickChild"
                                       :selectedItems="selectedAudiences" data-cy="card-audience">
                            </NewsCards>
                        </v-col>
                    </v-row>
                    <v-row v-if="!isNew">
                        <v-col class="pa-0">
                                <span class="removeButton mt-3" @click="attemptToDeleteAdministrator(administratorId)">
                                    {{ $t('news.remove_user') }}
                                </span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <PlaceHolder :path="'/icons/something-went-wrong.png'"
                             :text="$t('news.something_went_wrong_text')"
                             :title="$t('news.something_went_wrong_title')">
                </PlaceHolder>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import BackButton from "@/components/layout/BackButton";
import Loading from "@/components/Loading";
import {mapActions, mapGetters} from "vuex";
import NewsCards from "@/components/news/NewsCards";
import SaveButton from "@/components/buttons/SaveButton";
import PlaceHolder from "@/components/news/PlaceHolder";
import Alert from "@/components/dialogs/Alert";

export default {
    name: "AdministratorDetail",

    components: {
        Alert,
        PlaceHolder,
        SaveButton,
        NewsCards,
        BackButton,
        Loading,
    },

    async created() {
        await Promise.all([
            this.fetchAllAudiences(),
            this.fetchAllFaculties(),
            this.fetchAllAdministrators()
        ]);
        this.loadData();
    },

    data() {
        return {
            facultiesIds: [],
            audiencesIds: [],
            updatedUser: true,
            isSaved: false,
            xlogin: "",
            fullName: "",
            required_rule: value => !!value || 'Povinné',
        }
    },

    methods: {
        ...mapActions('Administrators', ['fetchAllAdministrators', 'fetchAllFaculties', 'fetchAllAudiences', 'deleteAdministrator', 'updateAdministrator', 'createAdministrator', 'setErr']),

        async attemptToDeleteAdministrator(id) {
            if (window.confirm(`${this.$t('news.delete_user')} ${this.administrator.fullName} ?`)) {
                await Promise.all([
                    await this.$router.push({name: 'Administrators'}),
                    await this.deleteAdministrator(id)
                ])
            }
        },

        onClickChild(obj) {
            obj.type === "faculty" ? this.facultiesIds = obj.value : this.audiencesIds = obj.value;
            this.updatedUser = false;
        },

        async saveUser() {
            if (!this.isNew) {
                await this.updateAdministrator({
                    id: this.administratorId,
                    request: {
                        audienceIds: this.audiencesIds,
                        facultyIds: this.facultiesIds,
                        fullName: this.administrator.fullName,
                        xlogin: this.administrator.xlogin,
                    }
                });
            } else {
                await this.createAdministrator({
                    audienceIds: this.audiencesIds,
                    facultyIds: this.facultiesIds,
                    fullName: this.fullName,
                    xlogin: this.xlogin,
                });
            }
            this.isSaved = true;
            await this.$router.push({name: 'Administrators'});
            window.scrollTo(0, 0);
        },

        loadData() {
            if (this.administrator && !this.isNew) {
                this.administrator.faculties.forEach(f => this.facultiesIds.push(f.id))
                this.administrator.audiences.forEach(a => this.audiencesIds.push(a.id))
            }
        },
    },


    computed: {
        ...mapGetters('Administrators', ['getAdministratorById', 'getAudiencesForEdit', 'getFacultiesForEdit', 'didLoadAudiences', 'didLoadFaculties', 'didLoadAdministrators', 'getErr']),

        didLoadData() {
            return this.didLoadAudiences && this.didLoadFaculties && this.didLoadAdministrators;
        },

        administratorId() {
            return parseInt(this.$route.params.id);
        },

        isNew() {
            return this.$route.params.id === "new";
        },

        administrator() {
            return this.getAdministratorById(this.administratorId)
        },

        isFormInvalid() {
            const invalidCards = this.facultiesIds.length === 0 || this.audiencesIds.length === 0
            return !this.isNew ? invalidCards : invalidCards || !this.xlogin || !this.fullName
        },

        selectedFaculties() {
            return !this.isNew ? this.administrator.faculties : []
        },

        selectedAudiences() {
            return !this.isNew ? this.administrator.audiences : []
        },

        controlData() {
            return (this.getAudiencesForEdit.length === 0 || this.getFacultiesForEdit.length === 0)
        }
    },


}
</script>

<style scoped>

.subTitle {
    font-size: 19px;
    color: #6D7278;
}

.removeButton {
    color: #E02020;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

</style>
