<template>
    <v-container fluid>
        <div v-if="filteredItems.length === 0">Nemáte oprávnění k žádnému modulu.</div>
        <v-layout v-else wrap>
            <v-flex md6 lg4
                    v-for="item in filteredItems"
                    :key="item.name"
            >
                <v-card
                        outlined
                        v-on:click="item.target ? $router.push({name: item.target}) : openUrl(item)"
                        class="mr-5 mb-5"
                >
                    <v-list-item three-line>
                        <v-list-item-content>
                            <v-list-item-title class="headline mb-1">
                                {{ item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ item.description }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-actions>
                        <v-btn color="primary" text>Vstoupit</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
  export default {
      name: "Homepage",
      
      data() {
          return {
              items: [
                  {
                      name: "Problémy a nápady",
                      description: "Vyřešte hlášení závad v kampusu a aplikaci.",
                      target: "reports"
                  },
                  {
                      name: "Důležité zprávy",
                      description: "Pošlete všem uživatelům kritickou zprávu, např. v případě hrozícího nebezpečí.",
                      target: "Messages"
                  },
                  {
                      name: "Novinky",
                      description: "Pošlete zprávu konkrétnímu segmentu uživatelů a informujte tak lidi na univerzitě o aktuálním dění.",
                      target: "Faculties"
                  },
                  {
                      name: "Mapa",
                      description: "Spravujte místa, budovy, místnosti a jejich vlastnosti.",
                      target: "map"
                  },
                  {
                      name: "Mapový widget",
                      description: "Vygenerujte obrázek nebo iframe mapy pro konkrétní místnosti.",
                      path: "/mapwidget"
                  },
                  {
                      name: "Studijní oddělení",
                      description: "Podívejte se, kdo si dnes rezervoval schůzku nebo nastavte svoje úřední hodiny.",
                      target: "StudyDepartment"
                  },
                  {
                      name: "Správa studijního oddělení",
                      description: "Spravujte studijní oddělení napříč fakultami.",
                      target: "Administration"
                  },
                  {
                      name: "Informační panely",
                      description: "Sdělte důležité informace prostřednictvím chytrých displejů na chodbách.",
                      target: "displays"
                  },
                  {
                      name: "Často kladené otázky",
                      description: "Pomozte studentům s jejich nejčastějšími dotazy a problémy.",
                      target: "faq"
                  },
                  {
                      name: "Průvodce",
                      description: "Vytvořte pro studenty poznávací trasy.",
                      target: "OnboardingMaster"
                  },
                  {
                      name: "Gamifikace studia",
                      description: "Vytvořte pro studenty vědomostné testy.",
                      target: "Subjects"
                  },
                  {
                      name: "IoT",
                      description: "Spravujte místa s IoT zařízeními.",
                      target: "SensorsDashboard"
                  },
                  {
                      name: "Majáky",
                      description: "Udržujte majetek pro indoor lokalizaci.",
                      target: "Beacons"
                  },
                  {
                      name: "Uživatelé",
                      description: "Nastavte, kdo má přístup k této webové administraci.",
                      target: "users"
                  },
                  {
                      name: "Datumy semestru",
                      description: "Set start and end of a semester.",
                      target: "Schedules"
                  }
              ]
          };
      },

      computed: {
          filteredItems() {
              return this.items.filter(item => {
                  const { route } = this.$router.resolve({name: item.target});
                  return route && this.$auth.canAccessRoute(route);
              });
          }
      },

      methods: {
          openUrl(item) {
              window.open(this.$router.resolve({path: item.path}).href, '_blank')
          }
      }
  }
</script>

<style scoped>
</style>
