<template>
  <v-container fluid>
    <v-row>
      <BackButton :target="{name: 'StatisticsOverview'}">{{ $t('queueManagement.stats') }}</BackButton>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="h2_header">{{ $t('statistics.occupation') }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs
          v-model="tab"
          color="light-green"
          icons-and-text
          @change="setStatistics({refreshInterval: true})">
        <v-tab href="#period">
          {{ $t('statistics.time_period') }}
          <v-icon>event</v-icon>
        </v-tab>
        <v-tab href="#busiest">
          {{ $t('statistics.busiest') }}
          <v-icon>trending_up</v-icon>
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row class="mt-7 mb-2 bottom-border">
      <v-alert
          color="#78BE13"
          dense
          icon="info"
          text>
        {{ infoDescription }}
      </v-alert>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12" sm="6">
        <strong>{{ $t('statistics.period') | firstUpper }}</strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-n6" cols="12" sm="3">
        <v-select
            v-model="selectedInterval"
            :items="intervals"
            return-object
            @change="setStatistics({resetSelectedUnit: true})"
        />
      </v-col>
    </v-row>
    <Loading v-if="!didLoadData"/>
    <div v-else>
      <v-row>
        <v-col>
          <Bar ref="chart" :chart-data="chartData" :chart-options="chartOptions" :height="250"/>
        </v-col>
      </v-row>
      <v-row v-if="hasAcceptableUnits">
        <v-col cols="12" sm="6">
          <v-btn-toggle v-model="selectedAcceptableUnit">
            <v-btn v-for="(acceptableUnit, index) in this.acceptableUnitsLocalized" :key="index"
                   @click="setSelectedAcceptableUnit(index)">
              {{ `${$t('statistics.busiest')} ${acceptableUnit.text}` }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <StatisticsTable :data="this.getStatistics" :params="activeTabParameters"/>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import BackButton from "@/components/layout/BackButton";
import {mapActions, mapGetters} from "vuex";
import {Bar} from 'vue-chartjs/legacy';
import StatisticsTable from "@/components/statistics/StatisticsTable";
import Loading from "@/components/Loading";

import {Chart as ChartJS, Tooltip, BarElement, CategoryScale, LinearScale} from 'chart.js'

ChartJS.register(Tooltip, BarElement, CategoryScale, LinearScale)

export default {
  name: "StatisticsOccupation",

  components: {
    Loading,
    StatisticsTable,
    BackButton,
    Bar
  },

  data() {
    return {
      tab: "period",
      selectedInterval: null,
      intervals: [],
      selectedAcceptableUnit: undefined,
      acceptableUnitsLocalized: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
      },

      tabSpecificParameters: {
        period: {
          groupByDescription: this.$t('statistics.reservation_total'),
          infoDescription: this.$t('statistics.alert_info.period'),
          reversedTableOrder: true,
          url: 'study_department/statistics/occupation/',

          // We have to say explicitly, what intervals the tab is able to process.
          // So we can show it differently for each tab
          acceptableIntervals: ['day', 'week', 'month', 'quarter', 'half_year', 'year']
        },
        busiest: {
          groupByDescription: null,
          infoDescription: this.$t('statistics.alert_info.busiest'),
          reversedTableOrder: false,
          url: 'study_department/statistics/occupation/aggregates/',
          acceptableIntervals: ['week', 'month', 'quarter', 'half_year', 'year']
        }
      }
    }
  },

  created() {
    this.setStatistics({initialize: true})
  },

  computed: {
    ...mapGetters('Statistics', ['getStatistics', 'didLoadStatistics']),

    didLoadData() {
      return this.didLoadStatistics
    },

    chartData() {
      return {
        labels: this.getStatistics.unitValues.map(item => item['graphValue']) ?? [],
        datasets: [
          {
            label: this.$t('statistics.reservation_count'),
            backgroundColor: '#78BE13',
            data: this.getStatistics.unitValues.map(item => item['count']) ?? []
          }
        ]
      }
    },

    infoDescription() {
      return this.activeTabParameters['infoDescription']
    },

    hasAcceptableUnits() {
      return this.acceptableUnitsLocalized?.length > 1
    },

    activeTabParameters() {
      return this.tabSpecificParameters[this.tab]
    }
  },

  methods: {
    ...mapActions('Statistics', ['fetchStatisticsByInterval']),

    async setStatistics({initialize, refreshInterval, resetSelectedUnit, groupBy}) {
      if (refreshInterval === true || initialize === true)
        this.setIntervals()

      await this.fetchStatisticsByInterval({
        interval: this.selectedInterval.value.toUpperCase(),
        groupBy: groupBy,
        url: this.activeTabParameters['url']
      })

      let statistics = this.getStatistics
      let acceptableUnits = statistics['acceptableUnits']

      if (refreshInterval === true || resetSelectedUnit === true)
        this.selectedAcceptableUnit = acceptableUnits.indexOf(statistics['defaultUnit'])

      this.localizeAcceptableUnits(acceptableUnits)
    },

    localizeAcceptableUnits(acceptableUnits) {
      this.acceptableUnitsLocalized = []

      if (acceptableUnits?.length > 1) {
        this.acceptableUnitsLocalized = acceptableUnits.map(item => ({
              value: item,
              text: this.$t(`statistics.acceptableUnits.${item.toLowerCase()}`)
            })
        )
      }
    },

    setSelectedAcceptableUnit(value) {
      this.selectedAcceptableUnit = value
      this.setStatistics({groupBy: this.getStatistics['acceptableUnits']?.[value]})
    },

    setIntervals() {
      this.intervals = this.activeTabParameters['acceptableIntervals'].map(item => ({
            value: item,
            text: this.$t(`statistics.interval.${item}`)
          })
      )
      //First is being select by default (no specific reason)
      this.selectedInterval = this.intervals[0]
    },
  },
}
</script>

<style scoped>
.bottom-border {
  border-bottom: 1px solid #CCC
}
</style>