<template>
    <div class="d-flex justify-space-between align-center mb-4">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'ModuleHeader'
    }
</script>

<style scoped>
h2 {
    margin-bottom: 0px !important;
}
</style>