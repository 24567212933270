import firebase from "firebase/app";
import { config } from "@/config"
import 'firebase/messaging';
import { getNotificationParamsFromMessage, BACKGROUND_MESSAGE_EVENT } from "./notification.js"


export function initFirebase() {
    firebase.initializeApp(config.firebase.config);

    // listen to messages from service worker -> handle notifications when window is in background
    // https://firebase.google.com/docs/cloud-messaging/js/receive
    navigator.serviceWorker?.addEventListener('message', event => {
        if (event.data === BACKGROUND_MESSAGE_EVENT) {
            // play only sound, notification is displayed by service worker
            playSound();
        }
    });

    if (firebase.messaging.isSupported()) {
        firebaseMessaging = firebase.messaging();

        // handle notifications when window is in foreground
        firebaseMessaging.onMessage((payload) => {
            if (!document.hidden) {
                new Notification(...getNotificationParamsFromMessage(payload));
                playSound();
            }
        });
    }
}

function playSound() {
    const audio = new Audio('/sounds/House-bell-sound-effect.mp3'); // https://notification-sounds.com/162-house-bell-sound-effect.html
    audio.play();
}

export let firebaseMessaging = null;