import { get,del } from '@/services/api'

const state = {
  images: [],
  didLoadData: false
};

const getters = {
  didLoadImages: state => state.didLoadData,
  getReportImages: state => state.images,
};

const actions = {
  fetchReportImages({ commit }, reportId) {
    commit( 'changeLoadedState', false );
    commit( 'clearBucket' );
    get('reports/images/' + reportId).then((response) => {
      commit('setImages', response.data.items);
      commit( 'changeLoadedState', true );
    });
  },

  async deleteReportImage({commit}, { id, imageId }) {
    del(`reports/images/${id}/${imageId}`)
        .then(()=> {
            commit('removeImage', imageId);
        });
  },

  flushBucket({ commit }) {
    commit( 'clearBucket' );
  }

};

const mutations = {
  changeLoadedState: ( state, status ) => (state.didLoadData = status),
  setImages: (state, images) => ( state.images = images ),
  clearBucket: (state) => ( state.images = [] ),
  removeImage: (state, imageId) => ( state.images = state.images.filter(i => i.id !== imageId) ),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
