<template>
   <v-card :to="to" height="50" width="70" elevation="0" rounded="false">
                <table style="width: 100%;">
                    <tr >
                        <td style="text-align: center">
                        <v-icon :color="active ? 'green' : 'grey'"><slot name="icon"/></v-icon>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center">
                            <span style="font-size:11px;font-weight: 500;" :class="active ?  'light-green--text' :'grey--text'"><slot name="title"/></span>
                        </td>
                    </tr>
                </table>
                <v-divider/>
    </v-card>


</template>

<script>

export default {

    name: "MobileTab",
    props: {
        active: Boolean,
        to: String
    },


    data: () => ({
    }),
    methods : {

    }

};
</script>

<style scoped>


</style>