<template>
  <v-container>
    <router-view/>
  </v-container>
</template>

<script>
  export default {
    name: "Statistics"
  }
</script>
