var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModuleHeader',[_c('RefreshButton',{on:{"click":_vm.refreshWarnings}},[_c('h2',{attrs:{"data-cy":"warnings-heading"}},[_vm._v("Důležité zprávy")])]),_c('PrimaryButton',{attrs:{"data-cy":"add-new"},on:{"click":function($event){return _vm.goToDetail('new')}}},[_vm._v("+ Nové")])],1),_c('div',{staticClass:"panel"},[_c('div',[_c('v-select',{staticClass:"archive-switch settings",attrs:{"data-cy":"archived-selector","items":_vm.selectArchivedItems,"label":"Zobrazit archivované","clearable":false,"color":"dark"},model:{value:(_vm.$query.archived),callback:function ($$v) {_vm.$set(_vm.$query, "archived", $$v)},expression:"$query.archived"}})],1)]),(!_vm.didLoadWarnings)?_c('Loading'):_c('v-data-table',{staticClass:"d-none d-sm-block",attrs:{"headers":_vm.headers,"items":_vm.getWarnings,"sort-by":_vm.$query.sort,"sort-desc":_vm.$query.desc,"hide-default-footer":true,"disable-pagination":true,"hide-default-header":false,"data-cy":"table"},on:{"update:sortBy":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.$query, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.$query, "desc", $event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Žádná dostupná data ")]},proxy:true},{key:"item.localizedAttributes.cz.title",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"pointer light-green--text",on:{"click":function($event){return _vm.goToDetail(item.id)}}},[_vm._v(_vm._s(_vm._f("ellipsis")(item.localizedAttributes.cz.title,60)))])]}},{key:"item.audience",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAudience(item.audience))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.triggeredBy)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.date))+" ")]}}])}),_c('v-data-iterator',{staticClass:"d-block d-sm-none",attrs:{"items":_vm.getWarnings,"sort-by":_vm.$query.sort,"sort-desc":_vm.$query.desc,"hide-default-footer":true,"disable-pagination":true,"hide-default-header":false},on:{"update:sortBy":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.$query, "sort", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.$query, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.$query, "desc", $event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Žádná dostupná data ")]},proxy:true},{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(item){return _c('v-col',{key:item.name,attrs:{"cols":"12"}},[_c('mobile-data-table-row',{on:{"click":function($event){return _vm.goToDetail(item.id)}},scopedSlots:_vm._u([{key:"tag",fn:function(){return [_vm._v("tag")]},proxy:true},{key:"title",fn:function(){return [_vm._v(_vm._s(item.localizedAttributes.cz.title))]},proxy:true},{key:"item.audience",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAudience(item.audience))+" ")]}},{key:"label_header1",fn:function(){return [_vm._v("VYTVOŘIL")]},proxy:true},{key:"label_header2",fn:function(){return [_vm._v("CÍLOVÁ SKUPINA")]},proxy:true},{key:"label_content1",fn:function(){return [_vm._v(_vm._s(item.triggeredBy))]},proxy:true},{key:"label_content2",fn:function(){return [_vm._v(" "+_vm._s(_vm.formatAudience(item.audience))+" ")]},proxy:true}],null,true)})],1)}),1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }