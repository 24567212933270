import {del, get, post, put} from "@/services/api"
import Vue from "vue";

const state = {
    subjects: [],
    didLoadSubjects: false,
};

const getters = {
    getSubjects: state => state.subjects,
    getSubjectById: state => id => state.subjects.find(s => s.id === id),
    didLoadSubjects: state => state.didLoadSubjects,

    prepareNewSubject: () => () => ({
        localizedAttributes: {
            cz: {
                name: null
            },
            en: {
                name: null
            }
        },
        code: null,
        users: []
    })
};

const actions = {

    async fetchAllSubjects({commit}) {
        commit('startedDataDownload');
        await get('gamification/subjects/by_user')
            .then((subjects) => {
                commit('setSubjects', subjects.data.items)
            });
        commit('finishedDataDownload');

    },

    async fetchSubjectById({commit}, id) {
        commit('errors/clear', null, { root: true });
        commit('startedDataDownload');
        const response = await get('gamification/subjects/' + id);
        commit('updateSubject', response.data.content)
        commit('finishedDataDownload');
    },

    async createSubject({commit}, {item}) {
        const response = await post('gamification/subjects/', item);
        const newSubject = response.data.content;
        commit('addSubject', newSubject);
        return newSubject.id;
    },

    async deleteSubjectById({commit}, id) {
        await del(`gamification/subjects/${id}`)
            .then(() => {
                commit('filterSubjects', id);
            });
    },

    async updateSubject({commit}, {item}) {
        await put(`gamification/subjects/${item.id}`, item)
            .then((response) => {
                commit('updateSubject', response)
            });
    }
};

const mutations = {
    startedDataDownload: state => state.didLoadSubjects = false,
    finishedDataDownload: state => state.didLoadSubjects = true,
    setSubjects: (state, subjects) => (state.subjects = subjects),
    updateSubject: (state, item) => {
        const existing = state.subjects.findIndex(s => s.id === item.id);
        if (existing > -1) {
            Vue.set(state.subjects, existing, item)
        } else {
            state.subjects.push(item)
        }
    },
    addSubject: (state, item) => (state.subjects.push(item)),
    filterSubjects: (state, id) => (state.subjects = state.subjects.filter(subjects => subjects.id !== id)),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}