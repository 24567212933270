<template>
    <Loading v-if="!didLoadData"/>
    <v-container v-else-if="!getFacultyTitle">
        <v-row>
            <v-col>
                <BackButton :target="{name: 'Faculties'}">{{ $t('news.list_faculties') }}</BackButton>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <PlaceHolder
                    :path="'/icons/something-went-wrong.png'"
                    :text="$t('news.something_went_wrong_text')"
                    :title="$t('news.something_went_wrong_title')">
                </PlaceHolder>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else>
        <v-row>
            <v-col class="pa-0">
                <BackButton :target="{name: 'Faculties'}">{{ $t('news.list_faculties') }}</BackButton>
            </v-col>
        </v-row>
        <v-row class="mr-1">
            <v-col cols="12" md="8" class="pa-0">
                <h1 data-cy=newsfeed-heading class="font-weight-medium mr-2">{{ $t('news.posts') }} {{ getFacultyTitle }}</h1>
            </v-col>
            <v-col cols="12" md="4" align-self="center" class="d-flex justify-md-end justify-start pa-0">
                <PrimaryButton data-cy="add-new" @click="createNew()" >
                    <v-icon class="mr-1">add</v-icon>
                    {{ $t('news.new_post') }}
                </PrimaryButton>
            </v-col>
        </v-row>
        <v-row class="mr-1" justify="start">
            <v-col cols="12" md="5" lg="4" class="pl-0 pb-0">
                <v-text-field
                    color="light-green darken-1"
                    v-model="search"
                    prepend-icon="search"
                    :label="$t('news.what_post')"
                    clearable
                ></v-text-field>
            </v-col>
            <v-col>
                <v-container class="pa-0">
                    <v-row justify="end">
                        <v-col cols="12" md="4">
                            <v-select color="light-green darken-1"
                                      :items="getCategories"
                                      v-model="newsTopic"
                                      type="string"
                                      :label="$t('news.topic')"
                                      multiple
                                      clearable
                                      :menu-props="{offsetY: true , maxHeight: '60vh' }"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span class="mr-1" v-if="index < maxDisplayOnSelect">{{ item.text }}</span>
                                    <span class="grey--text caption ml-1" v-if="index === maxDisplayOnSelect"
                                    >(+{{ displayNumberOnSelect }})</span>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select color="light-green darken-1"
                                      :items="getNewsStates"
                                      v-model="newsState"
                                      type="string"
                                      label="Stav"
                                      clearable
                                      :menu-props="{offsetY: true , maxHeight: '60vh'}"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row v-if="filteredNews.length !== 0">
            <v-col cols="12" lg="6" v-for="(entity, i) in filteredNews" :key="i" class="pa-0">
                <v-card class="mr-5 mb-5" data-cy="card-newsfeed" @click="goToDetail(entity.id)" outlined style="border: 2px solid #eeeeef">
                    <v-list-item three-line class="mt-1">
                        <v-list-item-avatar rounded size="95">
                            <img :src="controlIcon(entity.titleImageUrl)"
                                 :data-cy="`title-img-${controlIcon(entity.titleImageUrl)}`"
                            />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="ml-1 mb-1 title">
                                {{ entity.localizedAttributes.cz.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="mb-2">
                                <v-card-text class="row font-weight-medium">
                                    <v-chip v-if="entity.isArchived" color="#F1D1BB" small text-color="#D3661A">
                                        <v-icon class="mr-1" small>inventory_2</v-icon>
                                        <span>{{ $t('news.archived') }}</span>
                                    </v-chip>
                                    <v-chip v-else-if="!entity.isConcept" color="#e9f2dc" small text-color="#79bf12">
                                        <v-icon class="mr-2" small>fa-check-circle</v-icon>
                                        <span>{{ $t('news.published') }} {{ published(entity) }}</span>
                                    </v-chip>
                                    <v-chip v-else color="#eeeeef" small text-color="#6c7278">
                                        <v-icon class="mr-1" small>edit</v-icon>
                                        <span>{{ $t('news.concept') }}</span>
                                    </v-chip>
                                </v-card-text>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-card-text class="row font-weight-medium">
                                    <v-chip v-for="(category, i) in entity.category" :key="i"
                                            color="#eeeeef"
                                            text-color="#6c7278"
                                            small
                                            class="mr-1 mb-1">
                                        <span>{{ category.localizedAttributes.cz.name }}</span>
                                    </v-chip>
                                </v-card-text>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <PlaceHolder :path="'/icons/news-place-holder.png'"
                             :text="$t('news.no_news_text')"
                             :title="$t('news.no_news_title')">
                </PlaceHolder>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PrimaryButton from "../../components/buttons/PrimaryButton";
import BackButton from "@/components/layout/BackButton";
import {mapGetters, mapActions} from "vuex";
import moment from "moment";
import Loading from "@/components/Loading";
import PlaceHolder from "@/components/news/PlaceHolder";

export default {
    name: "Newsfeed",

    components: {
        PrimaryButton,
        BackButton,
        Loading,
        PlaceHolder
    },

    data() {
        return {
            newsTopic: '',
            newsState: '',
            search: '',
            maxDisplayOnSelect: 2,
        }
    },

    async created() {
        await Promise.all([
            this.fetchFaculties(),
            // this.fetchAllNews(this.facultyId),
            this.fetchAllNews(),
            this.fetchAllCategories(),
        ]);
    },

    methods: {
        ...mapActions('Newsfeed', ['fetchAllNews', 'fetchAllCategories']),
        ...mapActions('Faculties', ['fetchFaculties']),

        controlIcon(icon) {
            return icon ? icon : '/icons/mendelu.png'
        },

        createNew() {
            this.$router.push({name: 'NewsfeedDetail', params: {id: this.facultyId, idNews: 'new'}});
        },

        goToDetail(idNews) {
            this.$router.push({name: 'NewsfeedDetail', params: {id: this.facultyId, idNews: idNews}});
        },

        formatDateTime(date) {
            return moment(date).format('DD.MM.YYYY');
        },

        published(entity) {
            if (entity.startDate){
                return entity.endDate ? `${this.formatDateTime(entity.startDate)} - ${this.formatDateTime(entity.endDate)}` : `${this.formatDateTime(entity.startDate)}`
            }
        }

    },

    computed: {
        ...mapGetters('Newsfeed', ['getNews', 'getNewsStates', 'didLoadNews', 'getCategories', 'didLoadCategories', 'getNewsfeedDetailError']),
        ...mapGetters('Faculties', ['getFaculties']),

        facultyId() {
            return parseInt(this.$route.params.id);
        },

        didLoadData() {
            return this.didLoadNews && this.didLoadCategories;
        },

        getFacultyTitle() {
            let obj = this.getFaculties.find(x => (x.id === this.facultyId));
            return obj ? obj.localizedAttributes.cz.name : null;
        },

        filteredNews() {
            let news = this.getNews.filter((obj) => {
                return obj.faculty.find(x => (x.id === this.facultyId))
            });

            news = news.filter((obj) =>
                obj.category.find((x) => this.newsTopic.includes(x.id)) ||
                !this.newsTopic.length
            );

            news = news.filter((obj) =>
                obj.isArchived ? obj.isArchived && this.newsState === 'Archived' || !this.newsState :
                    obj.isConcept && this.newsState === 'Concepts' ||
                    !obj.isConcept && this.newsState === 'Published' ||
                    !this.newsState
            );

            news = news.filter((obj) =>
                !this.search ||
                obj.localizedAttributes.cz.title.toUpperCase().includes((this.search.toUpperCase()))
            );

            return news;
        },

        displayNumberOnSelect() {
            return this.newsTopic.length - this.maxDisplayOnSelect
        },

    },
}
</script>

<style scoped>


</style>
