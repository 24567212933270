<template>
  <div>
    <v-container class="p-0">
      <v-row>
        <v-col v-if="intervals.length > 0 && !allDay" class="pr-0 pl-2 text-center interval-chips">
          <v-chip 
            class="interval-chip ma-2"
            v-for="(interval, index) in intervals" 
            v-bind:key="`interval-${index}`" 
            close
            @click:close="removeInterval(index)"
          >{{`${interval.timeFrom} – ${interval.timeTo}`}}</v-chip>
        </v-col>
        <v-col 
          v-else 
          class="text-center p-4 pr-0 pl-4 interval-no-chips">
          {{`${allDay ? '0:00 — 23:59' : 'nezobrazováno'}`}}
        </v-col>
        <v-col v-if="!allDay" class="add-column">
          <v-container>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row>
                <v-col class="pl-6 pt-1 pb-0">
                  <TimePicker 
                    :id="idInput1"
                    :neighborId="idInput2"
                    title="Od"
                    :clearable="true"
                    :rules="[required_rule]"
                    v-on:timeChanged="(value) => interval.timeFrom = value"
                    v-on:trySubmit="() => valid && addInterval()"
                    :initialValue="interval.timeFrom"
                    :prependIcon="true"
                    ref="fromPicker"
                    condensed
                  ></TimePicker>
                </v-col>
                <v-col class="pt-1 pb-0">
                  <TimePicker 
                    :id="idInput2"
                    :neighborId="idInput1"
                    title="Do"
                    :clearable="true"
                    :rules="[required_rule]"
                    v-on:timeChanged="(value) => interval.timeTo = value"
                    v-on:trySubmit="() => valid && addInterval()"
                    :initialValue="interval.timeTo"
                    :minTime="interval.timeFrom"
                    :prependIcon="true"
                    ref="toPicker"
                    condensed
                  ></TimePicker>
                </v-col>
                <v-col class="p-0 pt-2 add-interval-button">
                  <v-btn 
                    class="mt-0 mr-1 btn-small" 
                    rounded
                    color="primary" 
                    small
                    @click="addInterval"
                    :disabled="!valid">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-col>
        <v-col class="text-center all-day-column">
          <v-checkbox 
            :id="`announcement-all-day-${dayOfWeek}`"
            v-model="allDay" 
            :hide-details="true" 
            label="Celý den" 
            :true-value="true"
            :false-value="false"
            dense>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import TimePicker from "../../components/time-picker/TimePicker";
  import { uuid } from 'vue-uuid';
  export default {
    name: "IntervalPicker",
    components:{
      TimePicker
    },
    props: {
      dayOfWeek: Number,
      initialValues: Array,
      allDayInitial: Boolean
    },

    data() {
      return {
        allDay: this.allDayInitial ? this.allDayInitial :false,
        valid: false,
        required_rule: value => !!value || 'Povinné',
        interval: {
          dayOfWeek: this.dayOfWeek ? this.dayOfWeek : undefined,
          timeFrom: undefined,
          timeTo: undefined
        },
        intervals: this.initialValues ? this.initialValues : [],
        idInput1: `time-picker_${uuid.v4()}`,
        idInput2: `time-picker_${uuid.v4()}`,
      };
    },

    watch: {
      outputObject(value) {
        this.$emit('intervalsChanged', value);
      }
    },
    
    computed: {
      ovetflowConflict() {
        return !!(this.intervals.find(
          item => (this.interval.timeFrom >= item.timeFrom && this.interval.timeFrom <= item.timeTo) ||
                  (this.interval.timeTo >= item.timeFrom && this.interval.timeTo <= item.timeTo) ||
                  (this.interval.timeFrom <= item.timeFrom && this.interval.timeTo >= item.timeTo)
        ))
      },
      outputObject() {
        return this.allDay ? [{
          dayOfWeek: this.dayOfWeek ? this.dayOfWeek : undefined,
          timeFrom: '00:00',
          timeTo: '23:59'
        }] : this.intervals;
      }
    },

    methods: {
      addInterval() {
        if (!this.ovetflowConflict || confirm("Časový interval překrývá již existující, chcete pokračovat?")) {
            this.intervals.push({ ...this.interval });
            this.$refs.form.reset();
            this.$refs.fromPicker.reset();
            this.$refs.toPicker.reset();
            this.interval = { 
              dayOfWeek: this.dayOfWeek ? this.dayOfWeek : undefined, 
              timeFrom: undefined, 
              timeTo: undefined }
        }
      },
      removeInterval(index) {
        this.intervals.splice(index, 1)
      }
    }
  };
</script>

<style scoped>
.all-day-column {
  max-width: 140px; 
  min-width: 140px; 
  margin: auto;
}

.interval-chip {
  min-width: 135px;
}

.add-column {
  min-width: 240px;
}

.add-interval-button {
  max-width: 70px;
}

.interval-no-chips {
  word-break: break-all;
  margin: auto;
}

.interval-chips {
  min-width: unset;
}

.v-input--checkbox {
  margin: 0;
  padding: 0;
}
</style>