<template>
  <Loading v-if="!didLoadData"/>
  <v-container fluid v-else>
    <BackButton :target="{name: 'Beacons'}">Majáky</BackButton>
    <ModuleHeader>
      <RefreshButton @click="refreshBeaconPurposes">
        <div class="text-h5 font-weight-bold">Účely majáků</div>
      </RefreshButton>
      <PrimaryButton :to="{name: 'BeaconPurposeDetail', params: {id: 'new'}}">+ Nový</PrimaryButton>
    </ModuleHeader>
    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="dataTableHeaders"
            :items="purposes"
            mobile-breakpoint="0"
            :header-props="{
              sortByText: 'Řadit podle'
            }"
            :footer-props="{
              itemsPerPageText: 'Počet řádků na stránku',
              itemsPerPageAllText: 'Všechny',
              pageText: '{0}-{1} z {2}'
            }"
            :sort-by.sync="$query.sort"
            :sort-desc.sync="$query.desc"
            :items-per-page.sync="$query.rowsPerPage"
            :page.sync="$query.page"
        >
          <template v-slot:no-data>
            Žádná dostupná data
          </template>
          <template v-slot:item.name="{ item }">
            <b class="pointer light-green--text" @click="goToDetail(item.id)">{{ item.name }}</b>
          </template>
          <template v-slot:item.actions="{ item }">
            <DeleteIcon @click="attemptToDeleteBeaconPurpose(item)"/>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PrimaryButton from "@/components/buttons/PrimaryButton";
import RefreshButton from "@/components/buttons/RefreshButton";
import ModuleHeader from "@/components/layout/ModuleHeader";
import BackButton from "@/components/layout/BackButton";
import {mapActions, mapGetters} from "vuex";
import DeleteIcon from "@/components/buttons/DeleteIcon";
import Loading from "@/components/Loading";

export default {
  name: "BeaconPurposes",
  components: {Loading, DeleteIcon, BackButton, ModuleHeader, PrimaryButton, RefreshButton},

  mounted() {
    this.fetchBeaconPurposes();
  },

  data() {
    return {
      dataTableHeaders: [
        {
          text: 'Akce',
          value: 'actions',
          width: '5%',
          sortable: false,
        },
        {
          text: 'Název',
          value: 'name',
          width: '95%',
        },
      ],
    }
  },

  computed: {
    ...mapGetters("BeaconPurposes", ["purposes", "didLoadBeaconPurposes"]),

    didLoadData() {
      return this.didLoadBeaconPurposes
    },
  },

  methods: {
    ...mapActions("BeaconPurposes", ["fetchBeaconPurposes", "deleteBeaconPurpose"]),
    
    refreshBeaconPurposes() {
      this.fetchBeaconPurposes();
    },

    goToDetail(beaconPurposeId) {
      this.$router.push({name: 'BeaconPurposeDetail', params: {id: beaconPurposeId}});
    },

    attemptToDeleteBeaconPurpose(item) {
      if (confirm('Opravdu chcete smazat účel "' + item.name + '"?')) {
        this.deleteBeaconPurpose(item.id);
      }
    },

  }
}
</script>

<style scoped>
</style>