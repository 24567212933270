<template>
  <v-container class="max_width_none" align-content-start>
    <v-layout row class="no_margin no_padding width_max">
      <v-flex>
        <h2 class="h2_header" data-cy="study-entities-portal-heading">{{ $t('entities.list') }}</h2>
      </v-flex>
    </v-layout>

    <v-row>
      <v-col v-for="(entity, i) in infoCards" :key="i">
        <v-card elevation="0">
          <div class="infoCard" >
            <v-row class="mx-5 mt-0">
              <img class="mt-6 icon" :src="entity.icon" />
              <v-col class="mx-4 pa-0">
                <p class="titleCard font-weight-medium mb-0 mt-5">{{entity.title()}}</p>
                <v-card-subtitle class="subTitleCard">{{entity.subTitle()}}</v-card-subtitle>
              </v-col>
              <v-col class="pa-0" v-if="entity.pause && checkPause">
                <p class="titleCard font-weight-medium mb-0 mt-5 ">{{entity.pause()}}</p>
                <v-card-subtitle class="subTitleCard">{{entity.pauseText}}</v-card-subtitle>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(entity, i) in entityListToShow" :key="i">
        <v-card  elevation="0">
          <div class="card" data-cy="study-department-entity-card">
            <div class="mx-5 mt-1 " style="height: 140px">
              <img class="mt-2 icon" :src="entity.icon" />
              <p class="titleCard font-weight-medium mb-1 ">{{entity.name}}</p>
              <v-card-subtitle class="mb-2 subTitleCard">{{entity.text}}</v-card-subtitle>
            </div>
            <span class="button mx-5" :data-cy="`enter-${entity.name}`" @click="setVTabs(entity.path_name)">VSTOUPIT</span>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import moment from "moment";

export default {
  name: "EntitiesPortal",

  async created() {
    await this.fetchStudyOpenHours()
    await this.fetchQueueItemsByWantedDay(this.getCurrentDate());
  },

  data() {
    return {
      today: moment(),
      entityListToShow: [
        {
          name: this.$t('headers.open_hours'),
          text: this.$t('headers.open_hours_text'),
          path_name: 'studyOpenHours/portal',
          icon: "/icons/open-hours.png"
        },
        {
          name: this.$t('headers.study_refs'),
          text: this.$t('headers.study_refs_text'),
          path_name: 'assistant/portal',
          icon: "/icons/sudy-ref.png"
        },
        {
          name: this.$t('headers.study_req_category_and_requests'),
          text: this.$t('headers.study_req_category_and_requests_text'),
          path_name: 'studyProblems/item/portal',
          icon: "/icons/message.png"
        },
        {
          name: this.$t('headers.statistics'),
          text: this.$t('headers.statistics_text'),
          path_name: '../statistics/',
          icon: "/icons/statistics.png"
        },
        {
          name: this.$t('headers.study_fields'),
          text: this.$t('headers.study_fields_text'),
          path_name: 'studyField/portal',
          icon: "/icons/study-fields.png"
        },
      ],
      infoCards : [
        {
          title: () => this.checkOpenTime ? `${this.getOpenAndCloseTime.openTime} - ${this.getOpenAndCloseTime.closeTime}` : "-",
          subTitle: () =>  this.checkOpenTime ? this.$t('headers.today_open') : this.$t('headers.today_close'),
          pause: () => `${this.getOpenAndCloseTime.pauseStart} - ${this.getOpenAndCloseTime.pauseEnd}`,
          pauseText: this.$t('headers.pause'),
          icon: "/icons/open-hours.png"
        },
        {
          title: () => this.getCurrentReservations,
          subTitle: () => this.$t('headers.reservation_count'),
          icon: "/icons/statistics.png"
        },
      ]
    }
  },

  methods: {
    ...mapActions('pageStorage', ['setVtabsActiveCompomentStorage']),
    ...mapActions('queueItems', ['fetchQueueItemsByWantedDay']),
    ...mapActions('StudyOpenHours', ['fetchStudyOpenHours']),

    setVTabs(name) {
      this.setVtabsActiveCompomentStorage(name);
      this.$router.push(name);
    },

    getCurrentDate(){
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0');
      let yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd ;
      return today;
    },
  },

  computed:{
    ...mapGetters('queueItems', ['getQueueItems']),
    ...mapGetters('StudyOpenHours', ['getStudyOpenHours']),

    getOpenAndCloseTime() {
      let openAndClosedHours = this.getStudyOpenHours;
      let today = new Date(this.today);

      return openAndClosedHours.find(obj => obj.dayNumber === (today.getDay()).toString() && obj.purpose === "mobile");
    },

    getCurrentReservations(){
      return this.getQueueItems.length;
    },
    checkPause(){
      return this.getOpenAndCloseTime.pauseStart;
    },
    checkOpenTime(){
      return this.getOpenAndCloseTime.openTime!==null
    }

  }

}
</script>

<style scoped>

.max_width_none {
  max-width: none !important
}

.no_padding {
  padding: 0;
}

.no_margin {
  margin: 0;
}

.width_max {
  width: 100%;
}

.div_main_button_layout a {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding-left: 1rem;
  padding-right: 1rem;
}

.h2_header {
  font-size: 25px;
}
.card{
  width: 235px;
  height: 180px;
  border-radius: 4px;
  border: 2px solid #F0F0F0;
}
.infoCard{
  height: 80px;
  width: 494px;
  border-radius: 4px;
  border: 2px solid #F0F0F0;
}

.button{
  color: #78BE13;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
}
.titleCard{
  line-height: 1.2;
  word-break: normal;
  font-size: 20px;
}
.subTitleCard{
  padding: 0;
  line-height: 1.2;
}
.icon{
  height: 30px;
  width: 30px;
}

</style>
