import { get,} from '@/services/api'

const state = {
    didLoadedStudyOpenHours: false,
    studyOpenHours : [
    ]
};

const getters = {
    didLoadedStudyOpenHours: state => state.didLoadedStudyOpenHours,
    getStudyOpenHours: state => state.studyOpenHours,
    getStudyOpenHour: state => dayNumber => {
        return state.studyOpenHours.filter(studyOpenHours => studyOpenHours.dayNumber === dayNumber);
    }
};

const actions = {

    //Fetch ALL
    async fetchStudyOpenHours({ commit }) {
        commit('setDidLoadedStudyOpenHours', false);
        await get('study_department/open_hours/')
            .then((response) => {
                commit('setStudyOpenHours', response.data.items);
                commit('setDidLoadedStudyOpenHours', true);
            });
    },

    setStudyOpenHoursStorage({commit}, value) {
        commit('setStudyOpenHours', value);
    }

};

const mutations = {
    setDidLoadedStudyOpenHours: (state, value) => (state.didLoadedStudyOpenHours = value),
    setStudyOpenHours: ( state, studyOpenHours ) => (state.studyOpenHours = studyOpenHours),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
