import {del, get, post, put} from "@/services/api"
import Vue from "vue"
import i18n from "@/localization/i18n"

const QuestionTypes = {
    SingleAnswer: {
        text: i18n.t('gamification.one_right_answer'),
        value: 1
    },
    MultipleAnswers: {
        text: i18n.t('gamification.multiple_right_answers'),
        value: 2
    },
    TrueFalse: {
        text: i18n.t('gamification.true_false'),
        value: 3
    },
}

const state = {
    questions: [],
    didLoadedQuestions: false,
    defaultQuestion: {
        localizedAttributes: {
            cz: {
                text: null
            },
            en: {
                text: null
            }
        },
        points: null,
        type: {
            name: null,
            number: null
        },
        approvalCounter: null,
        denialCounter: null,
        approved: true,
        subjectId: null,
        hidden: false,
        answers: []
    },
    newQuestion: null,
};

const getters = {
    getQuestions: state => state.questions,
    getQuestionsBySubjectId: state => subjectId => state.questions.filter(q => q.subjectId === subjectId),
    getQuestionById: state => id => state.questions.find(q => q.id === id),
    getQuestionsByTestId: state => testId => state.questions.filter( q => q.testIds ? q.testIds.find(questionTestId => questionTestId === testId) : q),
    getEmptyQuestion: state => state.newQuestion,
    getQuestionTypes: () => QuestionTypes,
    didLoadedQuestions: state => state.didLoadedQuestions
};

const actions = {

    async fetchAllQuestions({commit}) {
        commit('setDidLoadedQuestions', false);
        const response = await get('gamification/test_questions/')
        commit('setQuestions', response.data.items)
        commit('setDidLoadedQuestions', true);
    },

    async fetchQuestionById({commit}, id) {
        commit('errors/clear', null, { root: true });
        commit('setDidLoadedQuestions', false);
        const response = await get('gamification/test_questions/' + id);
        commit('updateQuestion', response.data.content);
        commit('setDidLoadedQuestions', true);
        return response.data.content;
    },

    async createQuestion({commit}, {item}) {
        const response = await post('gamification/test_questions/', item);
        const newQuestion = response.data.content;
        commit('addQuestion', newQuestion);
        return newQuestion.id;
    },

    async deleteQuestionById({commit}, id) {
        await del(`gamification/test_questions/${id}`)
            .then(() => {
                commit('filterQuestions', id);
            });
    },

    async updateQuestion({commit}, {item}) {
        await put(`gamification/test_questions/${item.id}`, item)
        try {
            // quick fix. dont send response to commit because there is an inperfection in backend - it sends empty list of answers
            commit('updateQuestion', item)
        } catch (e) {
            alert(e.message)
        }
    },

    prepareNewQuestion({ commit }) {
        commit('prepareNew');
    },

    updateQuestionInStore({commit}, item) {
        commit('updateQuestion', item)
    }
}

const mutations = {
    setDidLoadedQuestions: (state, value) => (state.didLoadedQuestions = value),
    addQuestion: (state, item) => (state.questions.push(item)),
    setQuestions: (state, questions) => (state.questions = questions),
    filterQuestions: (state, id) => (state.questions = state.questions.filter(questions => questions.id !== id)),
    updateQuestion: (state, item) => {
        const existing = state.questions.findIndex(q => q.id === item.id);
        if (existing > -1) {
            Vue.set(state.questions, existing, item);
        } else {
            state.questions.push(item);
        }
    },
    prepareNew: ( state ) => {
        state.newQuestion = JSON.parse(JSON.stringify(state.defaultQuestion));
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}