<template>
    <v-row>
        <v-col>
            <v-row no-gutters class="mt-2">
                <v-col cols="auto" class="px-1 d-flex">
                    <v-icon class="mr-2" v-bind:style="{ color: place.color }">{{ place.icon }}</v-icon>
                    <v-card-title style="word-break: normal; line-height: normal;" class="font-weight-bold pa-0">
                        {{ place.localizedAttributes.cz.name }}
                    </v-card-title>
                </v-col>
            </v-row>
            <v-row v-if="place.computerOccupancy" class="mt-7 align-center" no-gutters>
                <v-col>
                    <v-row>
                        <v-col cols="auto" class="pr-1">
                            <v-icon class="pr-2">{{ $t(`iotSensors.measurement_icons.OCCUPANCY`) }}</v-icon>
                        </v-col>
                        <v-col class="pl-1">
                            <v-row no-gutters>
                                <v-col>
                                    <v-card-subtitle class="grey--text text--darken-2 pa-0">
                                        {{ $t('iotSensors.measurements.OCCUPANCY') }}
                                    </v-card-subtitle>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col>
                                    <v-card-text class="font-weight-bold pa-0 bigger-text">
                                        {{ place.computerOccupancy.occupied }} / {{ place.computerOccupancy.total }}
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-for="measure in place.measurements" :key="measure.id" class="align-center">
                <v-col class="py-3">
                    <v-row no-gutters class="align-center">
                        <v-col cols="auto">
                            <v-icon class="pr-2">{{ $t(`iotSensors.measurement_icons.${measure.type}`) }}
                            </v-icon>
                        </v-col>
                        <v-col>
                            <v-row no-gutters>
                                <v-col>
                                    <v-card-subtitle class="grey--text text--darken-2 pa-0">
                                        {{ $t('iotSensors.measurements.' + measure.type) }}
                                    </v-card-subtitle>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col v-if="measure.type === 'TEMPERATURE'">
                                    <v-card-text
                                         class="font-weight-bold pa-0 bigger-text"
                                    >
                                        {{ measure.numberValue }} {{ $t('iotSensors.degree_celsius') }}
                                    </v-card-text>
                                </v-col>
                                <v-col v-else>
                                    <v-card-text
                                        class="font-weight-bold pa-0 bigger-text"
                                    >
                                        {{ measure.numberValue }} {{ measure.unit }}
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
    export default {
        name: "IotCardBody",

        props: {
            place: {
            }
        },

        methods: {
            goToPlaceDetail(id) {
                this.$router.push({name: 'iotPlaceDetail', params: {id: id}})
            },
        }
    }
</script>
<style scoped>
.bigger-text {
    font-size: 1.2em;

}
</style>