<template>
    <div>
        <Loading v-if="didLoadData === false"/>
        <div v-else>
            <GamificationDialog 
               :model="showDialog" 
               :descriptionAttribute="'description'"
                @confirm="saveActivity"
                @close="showDialog = false" 
                :attributeField="'activity_name'" 
                :dialogTitle="$t('gamification.edit_activity')" 
                :target="activityItem"
            >
            </GamificationDialog>
            <v-container>
                <v-row>
                    <v-col>
                        <h1>{{$t('gamification.activities_headline')}}</h1>
                    </v-col>
                </v-row>

                <v-data-table
                v-model="selectedItems"
                :headers="headers"
                :items="getAllActivities"
                loading-text="Načítání..."
                style="width: 60%;"
                >

                    <template v-slot:item.imageUrl="{ item }">
                        <v-img max-height="40" max-width="40" :src="item.imageUrl"></v-img>
                    </template>

                    <template v-slot:item.localizedAttributes.cz.name="{item}">
                        <span class="text-xs-left"
                        style="font-size: 1rem !important;">{{item.localizedAttributes.cz.name}}</span>
                        <br>
                        <span class="text-xs-left grey--text text--darken-2"
                        style="font-size: 1rem !important">{{item.localizedAttributes.cz.description}}</span>
                    </template>

                    <template v-slot:item.edit="{item}" style="display: flex;"> 
                        <div  style="display: flex;  justify-content: center;">
                            <v-icon  @click="showEditDialog(item)">
                                edit
                            </v-icon>
                        </div>

                    </template>
                </v-data-table>
            </v-container>
        </div>
    </div>

    
</template>

<script>
import Loading from "../../components/Loading";
import {mapActions, mapGetters} from "vuex";
import GamificationDialog from "./GamificationDialog.vue";






export default {
    name: "Activities",

    
    components: {
        Loading,
        GamificationDialog
    },

    created() {
        this.fetchAllActivities();
        
    },

    data() {    
        return {
            headers: [
                {
                    text: this.$t('gamification.image'),
                    sortable: false,
                    value: 'imageUrl',
                    width: '15%'
                },
                {
                    text: 'Název aktivity',
                    align: 'left',
                    sortable: true,
                    value: 'localizedAttributes.cz.name',
                    width: '65%',
                },
                {
                    text: 'Akce',
                    align: 'center',
                    sortable: false,
                    value: 'edit',
                    width: '10%'
                }
            ],
            selectedItems: [],
            showDialog: false,
            activityItem: null,
        }
    },
    

    computed: {
        ...mapGetters('Activities', [ 'didLoadActivities', 'getActivities']),  

        getAllActivities() {
            return this.getActivities;
        },

        didLoadData() { 
            return this.didLoadActivities
        },       

    },

    methods: {
        ...mapActions('Activities', ['fetchAllActivities', 'updateActivity']),

        showEditDialog(item) {
            this.showDialog = true
            this.activityItem = this._.cloneDeep(item)
        },   

        async saveActivity(item) {
            await this.updateActivity(item)
            this.showDialog = false;
        },
    }

}
</script>
<style>
</style>