import {del, get, post, put} from '@/services/api'
import Vue from "vue";

const state = {
    scenario: null,
    scenarios: [],
    newScenario: {
        id: null,
        localizedAttributes: {
            cz: {
                name: "",
                description: ""
            },
            en: {
                name: "",
                description: ""
            }
        },
        locations: {},
    },
    didLoadScenario: false,
    didLoadScenarios: false,
    scenarioHeaders: [
        {
            text: 'Název prohlídky',
            align: 'start',
            sortable: true,
            value: 'name',
            width: "35%",
            filterable: true
        },
        {
            text: 'Popis prohlídky',
            align: 'start',
            sortable: true,
            value: 'description',
            width: "45%",
            filterable: true
        },
        {
            text: 'Počet lokací',
            align: 'start',
            sortable: true,
            value: 'amount',
            width: "20%",
            filterable: true
        },
    ],
    scenarioImages: [],
};

const getters = {
    scenarios: state => state.scenarios,
    scenarioById: state => id => {
        return state.scenarios.find(s => s.id === id)
    },
    didLoadScenario: state => state.didLoadScenario,
    didLoadScenarios: state => state.didLoadScenarios,
    getScenario: state => state.scenario,
    emptyScenario: state => state.newScenario,
    getScenarioHeaders: state => state.scenarioHeaders,
    getScenarioImages: state => state.scenarioImages,
};

const actions = {
    async fetchScenarios({commit}) {
        commit('setDidLoadScenarios', false);
        await get('onboarding/scenarios/').then((response) => {
            commit('setScenarios', response.data.items);
            commit('setDidLoadScenarios', true);
        })

    },

    async fetchScenarioById({commit}, id) {
        commit('errors/clear', null, { root: true });
        commit('setDidLoadScenario', false);
        await Promise.all([
            get(`onboarding/scenarios/${id}`),
            get(`onboarding/scenarios/images/${id}`),
        ]).then((response) => {
            commit('setScenario', response[0].data.content);
            commit('setImages', response[1].data.items);
            commit('setDidLoadScenario', true);
        }).catch(e => {
            if (e.statusCode === 404) {
                commit('errors/objectNotFound', null, {root: true});
            } else {
                throw e;
            }
        })
    },

    async createScenario({commit}, {scenario}) {
        const response = await post(`onboarding/scenarios/`, scenario);
        const newScenario = response.data.content;
        commit('addScenario', newScenario);
        return newScenario.id;
    },

    async updateScenario({commit}, {scenario}) {
        const response = await put(`onboarding/scenarios/${scenario.id}`, scenario);
        commit('updateScenario', response.data.content);
    },
    async deleteScenario({ commit },  id) {
        await del( `onboarding/scenarios/${id}`)
            .then(() => {
                commit('updateScenarios', {id});
            });
    },
    addImage({commit}, image) {
        commit('addImage', image);
    },
    async deleteImage({commit}, {scenarioId, id}) {
        await del(`onboarding/scenarios/images/${scenarioId}/${id}`);
        commit('removeImage', id);
    },
};

const mutations = {
    setScenario: (state, scenario) => (state.scenario = scenario),
    setScenarios: (state, scenarios) => (state.scenarios = scenarios),
    updateScenario: (state, item) => {
        const existing = state.scenarios.findIndex(s => s.id === item.id);
        if (existing > -1) {
            Vue.set(state.scenarios, existing, item);
        } else {
            state.scenarios.push(item);
        }
    },
    addScenario: (state, item) => (state.scenarios.push(item)),
    setDidLoadScenario: (state, value) => state.didLoadScenario = value,
    setDidLoadScenarios: (state,value) => state.didLoadScenarios = value,
    updateScenarios: (state, {id}) => {
        let scenarios = [];
        state.scenarios.forEach( (item) => {
            if ( item.id !== id ){
                scenarios.push(item);
            }
        });
        state.scenarios = scenarios;
    },
    addImage: (state, image) => (state.scenarioImages.push(image)),
    setImages: (state, images) => (state.scenarioImages = images),
    removeImage: (state, id) => state.scenarioImages = state.scenarioImages.filter(i => i.id !== id),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
