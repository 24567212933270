<template>
  <ItemNotFoundState v-if="objectNotFound" :objectName="'Účel majáku'">
    <template #backButton>
        <BackButton :target="{ name: 'BeaconPurposes'}">Účely majáků</BackButton>
    </template>
  </ItemNotFoundState>
  <Loading v-else-if="!didLoadData"/>
  <v-container fluid v-else>
    <BackButton :target="{ name: 'BeaconPurposes'}">Účely majáků</BackButton>
    <ModuleHeader>
      <div class="text-h5 font-weight-bold" v-if="isNew">Přidání účelu majáku</div>
      <div class="text-h5 font-weight-bold" v-else>Úprava účelu majáku</div>
      <v-spacer/>
      <SaveButton
          :disabled="!isFormValid"
          :is-saving="isSaving"
          @click="save()"
          label="Uložit"
      />
    </ModuleHeader>
    <v-row>
      <v-col cols="12">
        <v-form @submit.prevent="save" ref="form" v-model="isFormValid">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                    label="Název"
                    @change="changeWatch"
                    :rules="[required_rule]"
                    v-model="beaconPurpose.name"
                    color="light-green darken-1"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import BackButton from "@/components/layout/BackButton";
import ModuleHeader from "@/components/layout/ModuleHeader";
import SaveButton from "@/components/buttons/SaveButton";
import {mapActions, mapGetters} from "vuex";
import Loading from "@/components/Loading";
import ItemNotFoundState from '../../components/states/ItemNotFoundState.vue';

export default {
  name: "BeaconPurposeDetail",
  components: {Loading, SaveButton, ModuleHeader, BackButton, ItemNotFoundState},

  created() {
      this.loadData();
  },

  data() {
    return {
      required_rule: (value) => !!value || "Povinné",
      isFormValid: false,
      isSaving: false,
    }
  },

  computed: {
    ...mapGetters("BeaconPurposes", ["prepareNewBeaconPurpose", "beaconPurposeById", "didLoadBeaconPurposes"]),
    ...mapGetters('errors', ['objectNotFound']),

    beaconPurposeId() {
      const urlId = this.$route.params.id;
      return urlId === "new" ? null : Number(urlId);
    },

    isNew() {
      return this.beaconPurposeId === null;
    },

    beaconPurpose() {
      if (this.isNew) {
        return this.prepareNewBeaconPurpose();
      } else {
        return this.beaconPurposeById(this.beaconPurposeId);
      }
    },
    didLoadData() {
      return (this.didLoadBeaconPurposes || this.isNew)
    }
  },

  watch: {
    beaconPurposeId() {
      this.loadData();
    },
  },

  methods: {
    ...mapActions('leavePrevent', ["madeUnsavedChanges", "didSaveChanges"]),
    ...mapActions("BeaconPurposes", ["fetchBeaconPurposeById", "createBeaconPurpose", "updateBeaconPurpose"]),
    ...mapActions('errors', ['clear']),

    loadData() {
      if (!this.isNew) {
        this.fetchBeaconPurposeById(this.beaconPurposeId);
      } else {
        this.clear();
      }
    },
    changeWatch() {
      this.madeUnsavedChanges();
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        try {
          if (this.isNew) {
            await this.createBeaconPurpose(this.beaconPurpose);
          } else {
            await this.updateBeaconPurpose(this.beaconPurpose);
          }
        } catch (e) {
          alert(e)
        }

        this.didSaveChanges();

        if (this.isNew) {
          await this.$router.push({name: "BeaconPurposes"});
        }

        this.isSaving = false;
      }
    },
  }
}
</script>

<style scoped>

</style>