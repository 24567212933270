<template>
	<v-dialog v-model="showDialog" max-width="70vw" persistent scrollable :fullscreen="$vuetify.breakpoint.smAndDown">
		<v-card height="70vh">
			<v-card-title>
				<span class="headline">Označte Beacon který chcete připojit k lokací</span>
			</v-card-title>
			<v-card-text>
				<v-data-table
					id="beaconDialog"
					class="d-sm-block"
					v-model="selected"
					single-select
					show-select
					:headers="dataTableHeaders"
					:items="getBeacons"
					no-results-text="Žádné údaje"
					:footer-props="{
						itemsPerPageOptions: [5],
					}"
					:search="search">
					<template v-slot:no-data>
						Žádná dostupná data
					</template>

					<template v-slot:top>
						<v-text-field
							color="light-green darken-1"
							v-model="search"
							prepend-icon="search"
							label="Hledat"
							single-line
							hide-details
							clear-icon="clear"
							:clearable="true"
						/>
					</template>

					<template v-slot:item.purposeName="{ item }">
						{{ getPurposeName(item) }}
					</template>

					<template v-slot:item.spot.placeName="{item}">
						{{ getPlaceName(item) }}
					</template>

					<template v-slot:item.spot.buildingName="{item}">
						{{ getBuildingName(item) }}
					</template>

				</v-data-table>
			</v-card-text>
			<v-card-actions>
				<v-spacer/>
				<v-btn color="blue darken-1" text @click="cancelSelect">Zrušit</v-btn>
				<v-spacer/>
				<v-btn id="confirmBeaconSelection" color="primary" rounded :disabled="selected.length !== 1" @click="confirmSelect">Potvrdit</v-btn>
				<v-spacer/>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import {mapActions, mapGetters} from "vuex";

	export default {
		name: "BeaconDialog",

		props: {
			showDialog: Boolean,
		},

		data() {
			return {
				search: "",
				selected: [],
				dataTableHeaders: [
					{
						text: '',
						value: 'id',
						width: '5%',
						sortable: false,
					},
					{
						text: 'UID',
						value: 'beaconId',
						width: "50%",
						sortable: true,
						filterable: true
					},
					{
						text: 'Účel',
						value: 'purposeName',
						width: "20%",
						sortable: true,
						filterable: false,
					},
					{
						text: 'Budova',
						value: 'spot.buildingName',
						width: "10%",
						sortable: true,
						filterable: false,
					},
					{
						text: 'Podlaží',
						value: 'spot.floorLevel',
						width: "5%",
						align: "center",
						sortable: false,
						filterable: false,
					},
					{
						text: 'Místo',
						value: 'spot.placeName',
						width: "10%",
						sortable: false,
						filterable: false,
					},
				],
			}
		},

		async mounted() {
			await this.fetchBeacons();
		},

		computed: {
			...mapGetters('Beacons', ['beacons']),
			...mapGetters('BeaconPurposes', ['purposeName']),

			...mapGetters('places', ['getTypeName']),
			getBeacons() {
				return this.beacons;
			},
		},

		methods: {
			...mapActions('Beacons', ['fetchBeacons']),
			getPurposeName(item) {
				// this is necessary. Beacon includes only purposeId
				return this.purposeName(item)
			},

			getPlaceName(item) {
				return item.spot.placeId && item.spot.floorId && item.spot.buildingId ? item.spot.placeName : this.getTypeName(item.spot.placeType)
			},

			getBuildingName(item) {
				return item.spot.buildingId !== null ? item.spot.buildingName : "Exteriér"
			},

			confirmSelect() {
				this.$emit('updateBeaconId', this.selected[0]);
				this.close();
			},

			cancelSelect() {
				this.close();
			},
			close(){
				this.$emit('close');
			},
		},
	}
</script>

<style scoped>

</style>