import axios from 'axios';

const state = {
    models: [],
    modelDetail: null,
    accordingModels: []
};
const getters = {
    getModels: state => state.models,
    getModel: state => state.modelDetail,
    getAccordingModels: state => state.accordingModels
};
const actions = {
    //Fetch ALL
    async fetchModels({commit}) {
        const response = await axios
            .get('https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model');
        commit('setModels', response.data.models);
    },

    //Fetch ONE
    async fetchModel({commit}, id) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model/${id}`);
        commit('setModel', response.data);
    },

    //Add ONE
    async createModel({commit}, model) {
        await axios
            .post(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model`, model).then((newModel) => {
                commit('addModel', newModel.data);
            });
    },

    //Update ONE
    async updateModel({commit}, {
        id, model, onSuccess = () => {
        }
    }) {
        commit('updateModel', id, model);
        await axios
            .put(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model/${id}`, model)
            .then(onSuccess);
    },

    //Delete ONE
    async deleteModel({commit}, id) {
        commit('filterModel', id);
        await axios
            .delete(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model/${id}`);
    },

    //GET models according to model type and date
    async accordingModel({commit}, modelTypeId, dateFrom, dateTo) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model?modelType=${modelTypeId}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
        commit('setAccordingModels', response.data.models);
    },

    async modelsByModelTypeId({commit}, modelTypeId) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/technics/model?modelType=${modelTypeId}`);
        commit('setAccordingModels', response.data.models);
    }
};

const mutations = {
    setModels: (state, model) => (state.models = model),
    setModel: (state, model) => (state.modelDetail = model),
    addModel: (state, model) => (state.models.push(model)),
    updateModel: (state, id, model) => {
        let index = state.models.filter(model => model.id === id);
        state.models[index] = model
    },
    filterModel: (state, id) => (state.models = state.models.filter(model => model.id !== id)),
    setAccordingModels: (state, model) => (state.accordingModels = model),
    setControlsOfModel: (state, controls) => (state.modelsControls = controls),

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
