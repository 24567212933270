<template>
    <div>
        <h4>Fotografie</h4>
        <div v-if="images.length === 0">Žádná Fotografie.</div>
        <div v-else>
            <v-carousel class="image-box"
                        cycle
                        height="300px"
                        hide-delimiter-background
                        show-arrows-on-hover
                        :hide-delimiters="hideDelimiters"
                        :show-arrows="activeShowRows"
            >
                <v-carousel-item
                    v-for="(image, i) in images" :key="i">
                    <v-row align="center" justify="center">
                            <v-img
                                class="report-img"
                                @click="redirect(image)"
                                :src="(image)"
                                aspect-ratio="1"
                            ></v-img>
                    </v-row>
                </v-carousel-item>
            </v-carousel>
        </div>
    </div>
</template>

<script>

export default {
    name: "WebReportImages",
    props: {
        images: {type: Array, required: true},
    },
    computed:{
        activeShowRows(){
            return this.images.length>1;
        },
        hideDelimiters(){
            return this.images.length===1;
        }
    },
    methods: {
        redirect(url){
            window.open(url, '_blank');
        },
    }
}
</script>

<style scoped>
.report-img {
    cursor: pointer;
}

.image-box {
    float: left;
    width: 400px;
    margin: 2px;
}

</style>
