<template>
    <router-link v-if="to !== undefined" :to="to">
        <v-btn color="primary"><slot/></v-btn>
    </router-link>
    <v-btn v-else @click="onClick" color="primary"><slot/></v-btn>
</template>

<script>
    export default {
        name: 'PrimaryButton',

        props: {
            to: Object
        },

        methods: {
            onClick() {
                this.$emit('click');
            }
        }
    }
</script>

<style scoped>
    a {
        text-decoration: none !important;
    }
</style>