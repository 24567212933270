<template>
    <ItemNotFoundState v-if="objectNotFound" :objectName="'Problém/Nápad'">
        <template #backButton>
          <BackButton :target="{name: 'reports', params: { tab: 'new'}}">Problémy a nápady</BackButton>
        </template>
    </ItemNotFoundState>
    <Loading v-else-if="!didLoadData"/>
    <div v-else class="reportDetail">
      <BackButton :target="{name: 'reports', params: { tab: 'new'}}">Problémy a nápady</BackButton>
        <ModuleHeader>
            <h2>
                Správa hlášení {{ singleDetail.number }}
                <v-chip v-if="singleDetail.archived" color="orange" dark class="ml-3">Archivováno</v-chip>
            </h2>
            <v-spacer/>
            <SaveButton :state="savingState" :disabled="isFormInvalid" @click="saveChanges"/>
        </ModuleHeader>

        <v-form ref="form">
            <v-layout row>
                <div class="col-lg-6">
                    <h4 class="mb-2">Hlášení</h4>

                    <div class="mb-4">
                        <div class="mb-1" v-if="singleDetail.author">
                            <v-icon left>person</v-icon> <i>{{ singleDetail.author}}</i>
                        </div>
                        <div>
                            <v-icon left>today</v-icon ><i>{{reportedDateTime}}</i>
                        </div>
                    </div>

                    <label>Cíl</label><br>
                    <router-link v-if="singleDetail.target && targetRoute" :to="targetRoute" target="_blank">
                        {{targetName}}
                        <span v-if="singleDetail.location">({{singleDetail.location}})</span>
                        <sup><v-icon small class="ml-1" color="primary">launch</v-icon></sup>
                    </router-link>
                    <div v-else-if="singleDetail.target">
                        {{targetName}}
                        <span v-if="singleDetail.location">({{singleDetail.location}})</span>
                    </div>
                    <div v-else>
                        {{singleDetail.location}}
                    </div>

                    <v-select
                            class="mt-5"
                            data-cy="type"
                            @change="changeWatch"
                            :rules="[required_rule]"
                            color="dark"
                            :items="getTypes"
                            v-model="singleDetail.type"
                            label="Typ"
                    ></v-select>
                    <v-textarea
                            data-cy="description"
                            @change="changeWatch"
                            :rules="[required_rule]"
                            color="dark"
                            v-model="singleDetail.description"
                            label="Popis"
                            data-test="description"
                            rows="1"
                            auto-grow
                    ></v-textarea>
                </div>

                <ReportImages :images="getReportImages" :report="singleDetail" class="col-lg-6"/>

                <div class="col-12">
                    <h4 class="mt-4 mb-4">Odpověď</h4>

                    <label>Stav hlášení</label><br>
                    <v-btn-toggle
                        label="Stav"
                        v-model="singleDetail.state"
                        @change="changeWatch"
                    >
                        <v-btn :data-cy="state.value" v-for="(state, index) in getStates" :key="index" :value="state.value">
                            <v-icon left>{{ getStateIcon(state.value) }}</v-icon>
                            {{state.text}}
                        </v-btn>
                    </v-btn-toggle>

                    <v-textarea
                            data-cy="status-message"
                            class="mt-6"
                            @change="changeWatch"
                            color="dark"
                            v-model="singleDetail.response"
                            label="Zpráva pro nahlašujícího"
                            auto-grow
                            rows="1"
                    ></v-textarea>
                </div>
            </v-layout>

            <SaveButton
                v-if="!singleDetail.archived || archivingState !== SaveButtonState.idle"
                :state="archivingState"
                label="Archivovat hlášení"
                saved-label="Hlášení archivováno"
                saving-label="Archivování..."
                @click="archive"
                :disabled="!['solved', 'rejected'].includes(singleDetail.state)"
                data-cy="archive-button"
            />

        </v-form>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import moment from 'moment';
    import BackButton from "../../components/layout/BackButton";
    import SaveButton from "../../components/buttons/SaveButton";
    import ModuleHeader from "../../components/layout/ModuleHeader";
    import Loading from "../../components/Loading";
    import ReportImages from "./ReportImages";
    import ItemNotFoundState from '../../components/states/ItemNotFoundState.vue';
    import {SaveButtonState} from "@/components/buttons/SaveButtonState";

    export default {
        name: "reportDetail",
        components: {Loading, SaveButton, BackButton, ModuleHeader, ReportImages, ItemNotFoundState},

        mounted() {
            this.loadData()
        },
        watch: {
            reportId() {
                this.loadData()
            }
        },

        data(){
            return {
                dialog:false,
                addedItem: {
                    datum: this.date,
                    description: ''
                },
                required_rule: value => !!value || 'Povinné',
                selected: "",
                savingState: SaveButtonState.idle,
                archivingState: SaveButtonState.idle,
            }
        },


        methods: {
            ...mapActions('reports', ['fetchReports', 'fetchReportById', 'deleteReport', 'updateReport', 'fetchReportImages']),
            ...mapActions('ReportImages', ['fetchReportImages', 'flushBucket']),
            ...mapActions('leavePrevent', ['madeUnsavedChanges', 'didSaveChanges']),

            handleLeave(event) {
              event.preventDefault();
              if(this.dataChanged) {
                event.returnValue = 'You have unfinished changes!';
              }
            },

            changeWatch() {
              this.madeUnsavedChanges();
            },

            loadData() {
                this.fetchReportById(this.reportId).then(() => {
                    if (this.objectNotFound)
                        return;
                    this.loadImages();
                })
            },

            deleteItem (item) {
                const index = this.comments.indexOf(item);
                confirm('Jste si jistý, že chcete smazat tento záznam?') && this.comments.splice(index, 1)
            },

            loadImages () {
              let thisItem = this.singleDetail;
              thisItem.imageIds.length > 0 ? this.fetchReportImages( thisItem.id ) : this.flushBucket();
            },

            async archive() {
                if (!this.$refs.form.validate()) { return; }

                this.archivingState = SaveButtonState.saving;

                try {
                    this.singleDetail.archived = true;
                    await this.updateReport({item: this.singleDetail})

                    this.didSaveChanges();

                    this.archivingState = SaveButtonState.saved;
                    await this.$router.push({name: "reports"});

                } catch (e) {
                    this.singleDetail.archived = false;
                    this.archivingState = SaveButtonState.error;
                    throw e;
                }
            },

            async saveChanges() {
                if (!this.$refs.form.validate()) { return; }

                this.savingState = SaveButtonState.saving;

                try {
                    await this.updateReport({item: this.singleDetail});

                    this.didSaveChanges();

                    this.savingState = SaveButtonState.saved;

                } catch (e) {
                    this.savingState = SaveButtonState.error;
                    throw e;
                }
            },

            close () {
                this.dialog = false
            },

            redirect(src){
                window.open(src, '_blank');
            },

        },

        computed: {
            SaveButtonState() {
                return SaveButtonState
            },
            ...mapGetters('reports', ['getReports','getSingle','getTypes','getStates', 'didLoadReports', 'getStateIcon']),
            ...mapGetters('ReportImages', ['didLoadImages', 'getReportImages']),
            ...mapGetters('errors', ['objectNotFound']),

            reportId() { return this.$route.params.id; },

            singleDetail(){
                return this.getSingle(this.reportId);
            },

            didLoadData() {
                return this.didLoadReports //&& this.didLoadImages
            },

            isFormInvalid()  {
                const detail = this.getSingle(this.reportId);
                return (detail.description == '' || detail.type == '')
            },

            date(){
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();

                today = dd + '.' + mm + '.' + yyyy;
                return today;

            },

            reportedDateTime() {
                const timestamp = this.singleDetail.created;
                return moment(timestamp).format('LLL');
            },

            targetParts() {
                if (this.singleDetail.target) {
                    return this.singleDetail.target.split('/');
                } else {
                    return [];
                }
            },

            targetName() {
                switch (this.targetParts[0]) {
                    case 'faq':
                        return 'FAQ otázka #' + this.targetParts[1];
                    case 'place':
                        return 'Místo #' + this.targetParts[1];
                    case 'building':
                        return 'Budova #' + this.targetParts[1];
                }
                return this.singleDetail.target;
            },

            targetRoute() {
                const module = this.targetParts[0];
                const id = parseInt(this.targetParts[1]);

                if (!module || !id) {
                    console.error('Provided target has wrong format!');
                    return null;
                }

                switch (module) {
                    case 'faq':
                        return {name: 'faqQuestionDetail', params: {id: id}};
                    case 'place':
                        return {name: 'mapDetail', params: {type: 'place', id: id}};
                    default:
                        console.error('Module ' + module + ' not supported!');
                }

                return null;
            },

        }
    }
</script>

<style scoped>
.archived-label {
  line-height: 48px;
}


</style>
