<template>
    <v-container v-if="objectNotFound">
        <v-row>
            <v-col>
                <h1 class="font-weight-medium mb-5">{{ $t('news.faculties') }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <PlaceHolder
                    :path="'/icons/something-went-wrong.png'"
                    :text="$t('news.something_went_wrong_text')"
                    :title="$t('news.something_went_wrong_title')">
                </PlaceHolder>
            </v-col>
        </v-row>
    </v-container>
    <Loading v-else-if="!isLoaded"/>
    <v-container v-else class="container">
        <v-row>
            <v-col class="pl-0 mb-3">
                <div data-cy="faculties-heading" class="text-h4 font-weight-medium">{{ $t('news.faculties') }}</div>
                <div class="subTitle">{{ $t('news.faculties_subtitle') }}</div>
            </v-col>
        </v-row>
        <v-row v-if="faculties.length !== 0">
            <v-col cols="12" md="6" lg="3" class="pa-0"
                   v-for="(entity, i) in faculties" :key="i">
                <v-card class="mr-5 mb-5" data-cy="card-faculty" @click="pushToListOfNews(entity.id)" outlined
                        style="border: 2px solid #eeeeef">
                    <v-card-text class="card mx-1">
                        <v-img :src="controlIcon(entity.imageUrl)"
                               max-height="30"
                               max-width="30"
                        />
                        <v-card-title class="titleCard">{{ entity.localizedAttributes.cz.name }}</v-card-title>
                        <v-card-subtitle class="subTitleCard">{{ entity.localizedAttributes.cz.description }}</v-card-subtitle>
                    </v-card-text>
                    <v-card-actions>
                        <span class="button mx-3">{{ $t('news.enter') }}</span>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <PlaceHolder :path="'/icons/faculties-place-holder.png'"
                             :text="$t('news.no_faculties_permissions_text')"
                             :title="$t('news.no_faculties_permissions_title')">
                </PlaceHolder>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import Loading from "@/components/Loading";
import PlaceHolder from "@/components/news/PlaceHolder";

export default {
    name: "Faculties",

    components: {
        Loading,
        PlaceHolder
    },

    async created() {
        await this.fetchFaculties();
    },

    computed: {
        ...mapGetters('Faculties', ['getFaculties', 'didLoadFaculties']),
        ...mapGetters('errors', ['objectNotFound']),

        faculties() {
            return this.getFaculties;
        },
        isLoaded() {
            return this.didLoadFaculties
        }
    },

    methods: {
        ...mapActions('Faculties', ['fetchFaculties']),

        pushToListOfNews(id) {
            this.$router.push({name: 'Newsfeed', params: {id: id}});
        },

        controlIcon(icon) {
            return icon ? icon : '/icons/mendelu.png';
        },

        pushToAdministrators() {
            this.$router.push({name: 'Administrators'});
        },

    }

}
</script>


<style scoped>


.button {
    color: #78BE13;
    font-weight: bold;
    font-size: 16px;
}

.titleCard {
    padding-left: 0;
    padding-top: 0;
    font-weight: 500;
    line-height: 1.2;
    word-break: normal;
    font-size: 20px;
}

.subTitleCard {
    padding: 0;
    line-height: 1.2;
}

.card {
    height: 130px;
}

.container {
    padding-bottom: 250px;
}

.subTitle {
    font-size: 19px;
    color: #6D7278;
}

</style>
