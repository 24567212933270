<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="7">
                <v-row no-gutters>
                    <v-col>
                        <h2>{{$t('support.headline')}}</h2>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-alert
                            v-model="alert"
                            dismissible
                            :type="alertType"
                        >
                        {{this.alertText}}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-form
                    v-model="valid"
                    ref="form" 
                >
                    <v-row no-gutters>
                        <v-col>
                            <v-select
                                :items="items"
                                item-text="text"
                                item-value="value"
                                v-model="type"
                                :label="$t('support.message_type_input')"
                                :rules="[required_rule]"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-text-field
                                type="email"
                                v-model="email"
                                :label="$t('support.email_input')"
                                :rules="[email_rules]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-textarea
                                class="mb-4"
                                :label="$t('support.description_input')"
                                auto-grow
                                :rules="[required_rule]"
                                v-model="description"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <template>
                                <vue-dropzone
                                    ref="dropzone"
                                    id="uploader"
                                    :options="dropzoneOptions"
                                    @vdropzone-complete="finishSaving()"
                                    @vdropzone-file-added="imagesUploading = true"
                                    @vdropzone-success="onFileSucessfullyUploaded"
                                    @vdropzone-error="errorMessage"
                                    :uploadOnDrop="true"
                                    :multipleUpload="true"
                                ></vue-dropzone>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row class="align-center pb-4">
                        <v-col cols="auto" class="px-0">
                            <v-checkbox id="check" v-model="urgent" ></v-checkbox>
                        </v-col>
                        <v-col>
                            <label style="font-size:1.1em" for="check" class="mb-0">{{$t('support.urgent_checkbox')}}</label>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-btn v-if="isUpdating">Ukládání...</v-btn>
                              <v-btn
                                  v-else
                                  color="primary"
                                  class="py-5"
                                  style="width:100%; border-radius:10px; font-size:1.05em;"
                                  @click="saveChanges"
                                  :disabled="type === 'question' && email === '' ? true : !valid "
                              >
                                  {{$t('entities.send')}}
                              </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {mapActions} from 'vuex';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { config } from '@/config';
import Vue from 'vue';



export default {

    name: "Helpdesk",

    data() {
        const allowedFileTypes = ".jpg,.jpeg,.png";
        return {
            valid: false,
            required_rule: value => !!value || 'Povinné',
            email_rules: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
            items: [{text: 'Mám otázku', value: "question"}, {text: 'Mám nápad', value: "idea"}, {text: 'Mám problém', value: "problem"} ],
            type: "",
            email: "",
            description: "",
            urgent: false,
            isUpdating: false,
            carouselActiveImage: 0,
            dropzoneOptions: {
                    url: config.serverUrl + 'helpdesk/images/0', // id will be filled when submitting the form later
                    method: 'post',
                    createImageThumbnails: true,
                    addRemoveLinks: false,
                    acceptedFiles: allowedFileTypes,
                    autoProcessQueue: false,
                    filesizeBase: 1000, // use 1MB=1000KB as the metric for maxFilesize
                    maxFilesize: 29, // MB, must be lower than limit in nginx proxy and lower than limit on backend!
                    paramName: "files",
                    headers: { "Authorization" : "Bearer " + Vue.$auth.getToken() },
                    dictDefaultMessage: this.$t('support.image_drop_text'),
                    dictFileTooBig: "Příliš velký soubor ({{filesize}} MB). Max. {{maxFilesize}} MB.",
                    dictInvalidFileType: "Obrázek musí být ve formátu " + allowedFileTypes,
                    dictResponseError: "Chyba serveru {{statusCode}}",
                    error: function(file) {
                        file.previewElement.remove();
                    },
                    
                },
                imagesUploading: false,
                alert: false,
                alertType: "success",
                alertText: "Úspěšně odesláno",
                reportId: null,
        }
    },

    components: {
        vueDropzone: vue2Dropzone,
    },

    methods: {
        ...mapActions('Helpdesk', ['addImage', 'createReport']),

        errorMessage() {
            this.alertText = "Obrázek musí být ve formátu .jpg,.jpeg,.png"
            this.alertType = "error"
            this.alert = true
            window.scrollTo(0, 0)

        },


        finishSaving() {
            this.imagesUploading = false;
            this.hasRecentlyUpdated = true;
            this.isUpdating = false
            setTimeout(() => this.hasRecentlyUpdated = false, 2000)
        },

        onFileSucessfullyUploaded(file, response) {
            this.addImage(response.content);
            setTimeout(() => {
                this.$refs.dropzone.removeFile(file)
            }, 2000);
        },

        uploadImages( webReportId ) {
            this.$refs.dropzone.setOption('url', config.serverUrl + 'helpdesk/images/' + webReportId);
            this.$refs.dropzone.processQueue();
        },

        async saveChanges() {
            if(this.$refs.form.validate()){
                this.isUpdating = true;
                this.reportId = await this.createReport({description: this.description, email: this.email, type: this.type, urgent: this.urgent})
                this.uploadImages(this.reportId)
                this.finishSaving()
                this.$refs.form.reset()
                this.email = ""
                this.description = ""
                this.type = ""
                this.urgent = false
                this.alertText = "Úspěšně odesláno"
                this.alertType ="success"
                this.alert = true
                window.scrollTo(0, 0)
            }
        },

    },
  }
</script>
<style scoped>
    #uploader {
        border: dotted;
        border-width: 4px;
        border-radius: 10px;
        background-color: rgb(245, 245, 245);
    }

    #uploader .dz-preview {
        width: 100px;
        margin: 3em 0;
    }

    #uploader .dz-error-message {
        opacity: 1;
        top: 0px;
        border-radius: 0px;
    }
</style>