<template>
    <Loading v-if="!didLoadData"/>
    <v-container v-else>

        <v-row>
            <v-col class="pl-0 mb-3">
                <div class="text-h4 font-weight-medium">{{ $t('warnings.statistics') }}</div>
                <div class="subTitle">{{ $t('warnings.statistics_subtitle') }}</div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-container class="pa-0">
                    <v-row>
                        <v-col cols="12" lg="6" class="pa-0">
                            <v-card class="mr-5 mb-5 card" outlined>
                                <v-list :height="180">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="titleCard mt-2">
                                                {{ $t('warnings.total_user') }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="subTitleCard my-4">
                                                {{ this.totalUsers }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle class="mt-3" style="white-space: normal;">
                                                {{ $t('warnings.total_user_text') }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>

                        <v-col cols="12" lg="6" class="pa-0">
                            <v-card class="mr-5 mb-5 card" outlined>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="titleCard mb-9">
                                                {{ $t('warnings.audience_overview') }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="chart-legend mt-3">
                                                <ul>
                                                    <li v-for="(label, index) in chartData.labels" :key="index">
                                                        <span class="legend-dot"
                                                              :style="dotColor(chartData, index)"></span>
                                                        <span class="legend-labels">{{ label }}</span>
                                                    </li>
                                                </ul>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                            <v-container>
                                                <Doughnut
                                                    :chart-data="chartData"
                                                    :chart-options="chartOptions"
                                                    :height="115"
                                                />
                                            </v-container>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6" lg="3" class="pa-0" v-for="(audience, index) in audiencesStatistics" :key="index">
                <v-card class="mr-5 mb-5 card" outlined>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="mb-2">
                                    <v-icon color="light-green" size="27">{{ audience.iconName }}</v-icon>
                                </v-list-item-title>
                                <v-list-item-title class="titleCard">
                                    {{ audience.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="mb-4 subTitleCard">
                                    {{ audience.absolute }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="mb-1">
                                    <span class="text-h7 relative">{{ audience.relative.toFixed(2) }} % </span>
                                    <span class="text-h7"> {{ $t('warnings.all_users') }}</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Loading from "@/components/Loading";

import {Doughnut} from 'vue-chartjs/legacy'
import {
    Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
    name: "WarningsStatistics",

    created() {
        this.fetchWarningsStatistics();
    },

    components: {
        Loading,
        Doughnut
    },

    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    }
                },
            },
        }
    },

    computed: {
        ...mapGetters('Warnings', ['getWarningsStatistics', 'didLoadWarningsStatistics', 'audienceNames', 'audienceAbsolutes']),

        didLoadData() {
            return this.didLoadWarningsStatistics;
        },

        audiencesStatistics() {
            return this.getWarningsStatistics.audiences;
        },

        totalUsers() {
            return this.getWarningsStatistics.totalUsers;
        },

        chartData() {
            let isNotEmpty = this.audienceAbsolutes.find(num => num !== 0);
            return {
                labels: isNotEmpty ? this.audienceNames : [this.$t('warnings.no_data')],
                datasets: [
                    {
                        backgroundColor: ['#8bb946', '#b3d782', '#d1e4b5', '#e9f0dc'],
                        data: isNotEmpty ? this.audienceAbsolutes : [100]
                    }
                ]
            }
        }
    },

    methods: {
        ...mapActions('Warnings', ['fetchWarningsStatistics']),

        dotColor(arr, index) {
            return {'background-color': arr.datasets[0].backgroundColor[index]}
        },

    }

}

</script>

<style scoped>

.subTitle {
    font-size: 19px;
    color: #6D7278;
}

.titleCard {
    font-weight: 500;
    font-size: 20px;
    white-space: normal;
}

.subTitleCard {
    font-weight: 500;
    font-size: 29px;
    white-space: normal;
}

.relative {
    color: #78BE13;
    font-weight: bold;
    font-size: 16px;
}

.chart-legend ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.chart-legend li {
    align-items: center;
    display: flex;
    margin-bottom: 5px;
    margin-right: 10px;
}

.legend-dot {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
}

.legend-labels {
    font-size: 14px;
    font-weight: 500;
    color: #6c757d;
}

.v-card.card {
    border: 2px solid #eeeeef;
}


</style>
