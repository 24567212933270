<template>
  <Loading v-if="didLoadedAll === false"/>

  <v-container fluid v-else>
    <v-row>
      <BackButton :target="{name: 'EntitiesPortal'}">{{ $t('entities.entity_portal') }}</BackButton>
    </v-row>

    <div>
        <v-tabs class="d-flex justify-start"
                v-model="tab"
                color="light-green"
        >
            <v-tab to="#panel" @click="changePurpose">
                Dotykový panel
            </v-tab>
            <v-tab to="#mobile" @click="changePurpose">
                Mobilní a webová aplikace
            </v-tab>
        </v-tabs>
    </div>

    <v-row v-bind:style="{ marginTop: 10 + 'px' }">
      <v-col cols="12" lg="10">
        <div v-if="getPurpose === 'panel'" class="text-h5 font-weight-bold">
          {{ $t('entities.openHours.openHours_edit_panel') }}
        </div>
        <div v-else class="text-h5 font-weight-bold">{{ $t('entities.openHours.openHours_edit_mobile') }}</div>
      </v-col>
      <v-col cols="2">
        <save-button v-if="!validateUserRoles"
                     :disabled="buttonDisabledValidate"
                     @click="putEditedStudyField()"
                     :label="$t('entities.save')"
        />
      </v-col>
    </v-row>

    <!--OPENING HOURS-->
    <template v-for="(day, index) in getDayNamesAccordingToLocalization()">
      <v-row :key="day" class="d-flex justify-lg-space-between" :data-cy="`open-hour-day-${index}-row`">
        <v-col cols="12" lg="1" class="d-flex mb-lg-n0 mb-n10">

          <!-- Checkbox na zavreno-->
          <v-checkbox v-model="disabledHoursOnCertainDay[index]"
                      data-cy="open-checkbox"
                      color="light-green darken-1"
                      :disabled="validateUserRoles">
            <template v-slot:label>
              <b class="black--text">{{ day }}</b>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="6" lg="2">
          <v-menu
              ref="menuOd"
              v-model="menu2[index]"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              :disabled="!disabledHoursOnCertainDay[index] || validateUserRoles"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="pauseStart[index]"
                  :label="$t('entities.openHours.from')"
                  prepend-icon="access_time"
                  readonly
                  v-on="on"
                  color="light-green darken-1"
                  data-cy="open-hour-time-from-text-field"
                  :disabled="!disabledHoursOnCertainDay[index] || validateUserRoles"
              />
            </template>
            <v-time-picker
                v-if="menu2"
                v-model="pauseStart[index]"
                format="24hr"
                color="light-green darken-1"
                full-width
                @click:minute="$refs.menuOd[index].save(pauseStart[index])"
                min="6:00"
                max="22:00"
                data-cy="open-hour-time-from-picker"
            />
          </v-menu>
        </v-col>

        <v-col cols="6" lg="2">
          <v-menu
              ref="menuDo"
              v-model="menu3[index]"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              :disabled="!disabledHoursOnCertainDay[index] || validateUserRoles"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="pauseEnd[index]"
                  :label="$t('entities.openHours.to')"
                  prepend-icon="access_time"
                  readonly
                  v-on="on"
                  color="light-green darken-1"
                  data-cy="open-hour-time-to-text-field"
                  :disabled="!disabledHoursOnCertainDay[index] || validateUserRoles"
              />
            </template>
            <v-time-picker
                v-if="menu3"
                v-model="pauseEnd[index]"
                format="24hr"
                color="light-green darken-1"
                full-width
                @click:minute="$refs.menuDo[index].save(pauseEnd[index])"
                min="6:00"
                max="22:00"
                data-cy="open-hour-time-to-picker"
            />
          </v-menu>
        </v-col>

        <!--Pauza-->
        <v-col cols="12" lg="1" class="d-flex mt-n5 mt-lg-0 mb-n10 mt-lg-0">
          <!-- Checkbox na Pauzu-->
          <v-checkbox :disabled="!disabledHoursOnCertainDay[index] || validateUserRoles" v-model="disabledPauseOnCertainDay[index]"
                      color="light-green darken-1"
                      data-cy="pause-checkbox">
            <template v-slot:label>
              <b class="black--text">{{ $t('entities.openHours.pause') }}</b>
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="6" lg="2">
          <v-menu
              ref="menuOdPauza"
              v-model="menu2_pause[index]"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              :disabled="!disabledPauseOnCertainDay[index] || !disablePause(index) || validateUserRoles"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="pauseStartTimes[index]"
                  :label="$t('entities.openHours.from')"
                  prepend-icon="access_time"
                  readonly
                  v-on="on"
                  color="light-green darken-1"
                  data-cy="open-hour-pause-from-text-field"
                  :disabled="!disabledPauseOnCertainDay[index] || !disablePause(index) || validateUserRoles"
              />
            </template>
            <v-time-picker
                v-model="pauseStartTimes[index]"
                v-if="menu2_pause"
                format="24hr"
                @click:minute="$refs.menuOdPauza[index].save(pauseStartTimes[index])"
                color="light-green darken-1"
                full-width
                :min="pauseStart[index]"
                :max="pauseEndTimes[index] || pauseEnd[index]"
                data-cy="open-hour-pause-from-picker"
            />
          </v-menu>
        </v-col>

        <v-col cols="6" lg="2">
          <v-menu
              ref="menuDoPauza"
              v-model="menu3_pause[index]"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              :disabled="!disabledPauseOnCertainDay[index] || !disablePause(index) || validateUserRoles"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="pauseEndTimes[index]"
                  :label="$t('entities.openHours.to')"
                  prepend-icon="access_time"
                  readonly
                  v-on="on"
                  color="light-green darken-1"
                  data-cy="open-hour-pause-to-text-field"
                  :disabled="!disabledPauseOnCertainDay[index] || !disablePause(index) || validateUserRoles"
              />
            </template>
            <v-time-picker
                v-if="menu3_pause"
                v-model="pauseEndTimes[index]"
                format="24hr"
                color="light-green darken-1"
                full-width
                @click:minute="$refs.menuDoPauza[index].save(pauseEndTimes[index])"
                :min="pauseStartTimes[index] || pauseStart[index]"
                :max="pauseEnd[index]"
                data-cy="open-hour-pause-to-picker"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row :key="index" class="d-flex mt-n2" :data-cy="`open-hour-day-${index}-desc`">
        <v-col cols="12" lg="6">
          <v-expansion-panels :disabled="!disabledHoursOnCertainDay[index]">
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t('entities.openHours.description') }}
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-text-field
                    :label="$t('entities.openHours.cz')"
                    v-model="description[index]"
                    counter
                    maxlength="40"
                    color="light-green darken-1"
                    :disabled="validateUserRoles"
                    data-cy="open-hour-desc-cz"
                />

                <v-text-field
                    :label="$t('entities.openHours.en')"
                    v-model="descriptionEn[index]"
                    counter
                    maxlength="40"
                    color="light-green darken-1"
                    :disabled="validateUserRoles"
                    data-cy="open-hour-desc-en"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import BackButton from "../../../../../components/layout/BackButton";
import SaveButton from "../../../../../components/buttons/SaveButton";
import moment from 'moment';
import {mapGetters, mapActions} from 'vuex';
import {put} from '@/services/api'
import Loading from "../../../../../components/Loading";

export default {
  name: "StudyOpenHoursDetail",

  components: {
    BackButton,
    SaveButton,
    Loading
  },

  data() {
    return {
      //used
      disabledHoursOnCertainDay: [],
      disabledPauseOnCertainDay: [],

      pauseStart: [],
      pauseEnd: [],
      pauseStartTimes: [],
      pauseEndTimes: [],
      description: [],
      descriptionEn: [],
      purpose: [],

      //originals
      disabledHoursOnCertainDayOriginal: [],
      disabledPauseOnCertainDayOriginal: [],

      pauseStartOriginal: [],
      pauseEndOriginal: [],
      pauseStartTimesOriginal: [],
      pauseEndTimesOriginal: [],
      descriptionOriginal: [],
      descriptionEnOriginal: [],

      menu2: [],
      menu3: [],

      menu2_pause: [],
      menu3_pause: [],

      tab: "panel",
    }
  },

  created() {
    this.fetchStudyOpenHours();
    this.setModel();
    this.createCopyOfStartData();
  },

  mounted() {
    this.fetchStudyOpenHours();
    this.setModel();
    this.createCopyOfStartData();
  },

  computed: {
    ...mapGetters('StudyOpenHours', ['getStudyOpenHours', 'didLoadedStudyOpenHours']),
    ...mapGetters('pageStorage', ['getAssistantStorage']),

    getPurpose() {
      return this.tab;
    },

    buttonDisabledValidate() {
      return this.compareOriginalAndStateNow;
    },

    didLoadedAll() {
      return this.didLoadedStudyOpenHours;
    },

    compareDescriptions() {
      let blockers = [];

      for (let i = 0; i < 7; i++) {

        //console.log((this.description[i] !== null && this.descriptionEn[i] === null) ||
        //    (this.description[i] === null && this.descriptionEn[i] !== null));

        if ((this.description[i] !== "" && this.descriptionEn[i] === "") && (this.description[i] === "" && this.descriptionEn[i] !== "")) {
          blockers.push(1);
        }
      }

      return blockers.length === 0;
    },

    compareOriginalAndStateNow: function () {
      return JSON.stringify(this.disabledHoursOnCertainDayOriginal) === JSON.stringify(this.disabledHoursOnCertainDay) &&
          JSON.stringify(this.disabledPauseOnCertainDayOriginal) === JSON.stringify(this.disabledPauseOnCertainDay) &&
          JSON.stringify(this.pauseStartOriginal) === JSON.stringify(this.pauseStart) &&
          JSON.stringify(this.pauseEndOriginal) === JSON.stringify(this.pauseEnd) &&
          JSON.stringify(this.pauseStartTimesOriginal) === JSON.stringify(this.pauseStartTimes) &&
          JSON.stringify(this.pauseEndTimesOriginal) === JSON.stringify(this.pauseEndTimes) &&
          JSON.stringify(this.descriptionOriginal) === JSON.stringify(this.description) &&
          JSON.stringify(this.descriptionEnOriginal) === JSON.stringify(this.descriptionEn) &&
          JSON.stringify(this.purpose) === JSON.stringify(this.purpose);
    },

    validateUserRoles() {
      return this.$auth.getStudyAssistantAndRole()?.role === 'STUDY';
    },

  },

  methods: {
    ...mapActions('StudyOpenHours', ['fetchStudyOpenHours', 'setStudyOpenHoursStorage']),

    goBack() {
      this.$router.push({name: 'EntitiesPortal'});
    },

    getDayNamesAccordingToLocalization() {
      // Will need to change in case of true internalization -> now it is hard coded to czech names and date type
      // Monday is first day of week
      // see: firstDayOfMonth() -> will need fixing too... (.weekday() changed to .day())

      return [1, 2, 3, 4, 5, 6, 7].map(dow => moment().isoWeekday(dow).format('dddd'));
    },

    changePurpose() {
      this.fetchStudyOpenHours();
      this.setModel();
      this.createCopyOfStartData();
    },

    setModel() {
      let openHours = this.getStudyOpenHours.filter(o => o.purpose === this.getPurpose);

      for (let item of openHours) {

        if (item.openTime !== null && item.closeTime !== null) {
          this.pauseStart[this.translateDays(item.day)] = item.openTime;
          this.pauseEnd[this.translateDays(item.day)] = item.closeTime;
          this.description[this.translateDays(item.day)] = item.description;
          this.descriptionEn[this.translateDays(item.day)] = item.descriptionEn;
          this.disabledHoursOnCertainDay[this.translateDays(item.day)] = true;
          this.purpose[this.translateDays(item.days)] = item.purpose;
        } else {
          this.pauseStart[this.translateDays(item.day)] = "08:00";
          this.pauseEnd[this.translateDays(item.day)] = "16:00";
          this.disabledHoursOnCertainDay[this.translateDays(item.day)] = false;
        }

        if (item.pauseStart !== null && item.pauseEnd !== null) {
          this.pauseStartTimes[this.translateDays(item.day)] = item.pauseStart;
          this.pauseEndTimes[this.translateDays(item.day)] = item.pauseEnd;
          this.disabledPauseOnCertainDay[this.translateDays(item.day)] = true;
        } else {
          this.pauseStartTimes[this.translateDays(item.day)] = "12:00";
          this.pauseEndTimes[this.translateDays(item.day)] = "12:30";
          this.disabledPauseOnCertainDay[this.translateDays(item.day)] = false;
        }
      }
    },

    translateDays(day) {
      // weird american system, sunday is 0, need to do translations of days

      switch (day) {
        case "Monday":
          return 0;
        case "Tuesday":
          return 1;
        case "Wednesday":
          return 2;
        case "Thursday":
          return 3;
        case "Friday":
          return 4;
        case "Saturday":
          return 5;
        case "Sunday":
          return 6;
      }
    },

    createDataToSend() {
      let dataToSend = [];
      let openHours = this.getStudyOpenHours.filter(o => o.purpose === this.getPurpose);

      openHours.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)).forEach((item, i) => {

        let dataPrototype = {
          id: item.id,
          day: item.day,
          dayNumber: item.dayNumber,
          openTime: null,
          closeTime: null,
          pauseStart: null,
          pauseEnd: null,
          description: null,
          descriptionEn: null,
          purpose: item.purpose,
        };

        if (this.disabledHoursOnCertainDay[i] === true) {
          dataPrototype.openTime = this.pauseStart[i];
          dataPrototype.closeTime = this.pauseEnd[i];
          dataPrototype.description = this.description[i];
          dataPrototype.descriptionEn = this.descriptionEn[i];

          if (this.disabledPauseOnCertainDay[i] === true) {
            dataPrototype.pauseStart = this.pauseStartTimes[i];
            dataPrototype.pauseEnd = this.pauseEndTimes[i];
          }
        }

        dataToSend.push(dataPrototype);
      });

      return dataToSend;
    },

    putEditedStudyField() {
      put('study_department/open_hours/', {
        "data": this.createDataToSend()
      }).then(response => {
        this.setStudyOpenHoursStorage(response.data.items);
        this.$router.push({name: "EntitiesPortal"})
        //TODO this.$router.push({name: "StudyOpenHoursDetail"})
      }).catch(e => {
        if (e.statusCode > 200 && e.statusCode < 500) {
          alert("Data se nepovedlo uložit.");
        }
        if (e.statusCode === 500) {
          alert("Při ukládání nastala chyba serveru.");
        }
      });
    },

    createCopyOfStartData() {
      this.disabledHoursOnCertainDayOriginal = [...this.disabledHoursOnCertainDay];
      this.disabledPauseOnCertainDayOriginal = [...this.disabledPauseOnCertainDay];

      this.pauseStartOriginal = [...this.pauseStart];
      this.pauseEndOriginal = [...this.pauseEnd];
      this.pauseStartTimesOriginal = [...this.pauseStartTimes];
      this.pauseEndTimesOriginal = [...this.pauseEndTimes];
      this.descriptionOriginal = [...this.description];
      this.descriptionEnOriginal = [...this.descriptionEn];
      this.purpose = [...this.purpose];
    },

    disablePause(idx) {
      return this.disabledHoursOnCertainDay[idx];
    },

  },
}
</script>
