<template>
    <div class="stack">
        <h3> {{ $t('checkIn.calling') }} </h3>
        <v-btn :disabled="validateUserRoles" rounded large dark color="secondary" class="mt-5" @click="startCallIn()" id="start-call-in-btn"> {{ $t('checkIn.start') }}</v-btn>
        <span class="text-center mt-3"> {{ $t('checkIn.start_message') }} </span>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "CallInStart",

        computed: {
            ...mapGetters('pageStorage', ['getAssistantStorage']),
            
            validateUserRoles() {
                return this.$auth.getStudyAssistantAndRole()?.role === 'STUDY_SPY';

            }
        },

        methods: {
            // goes to CheckInInfobar
            startCallIn: function () {
                // goes to CheckInTable
                this.$root.$emit('callInStartItsOn');
            },
        },
    }
</script>

<style scoped>
</style>