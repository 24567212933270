import { get } from '@/services/api';

const state = {
  didLoadFacilities: false,
  facilities: [],
};

const getters = {
  didLoadFacilities: state => state.didLoadFacilities,
  getFacilities: state => state.facilities,
};

const actions = {
  async fetchFacilities({ commit }) {
    commit('startedDataDownload');
    const response = await get('food/facilities/');
    commit('setFacilities', response.data.items);
    commit('finishedDataDownload');
  },
};

const mutations = {
  startedDataDownload: state => state.didLoadFacilities = false,
  finishedDataDownload: state => state.didLoadFacilities = true,
  setFacilities: ( state, facilities ) => (state.facilities = facilities),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
