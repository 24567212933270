<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-container fluid v-else>

        <back-button :target="{name: 'StudyProblemsItemPortal'}">Portál požadavků</back-button>
        <module-header>
            <h2>{{ $t('entities.studyProblems.portal_name') }}</h2>
            <v-spacer/>
            <primary-button v-if="isStudyAdmin" @click="goToNew()" class="ml-5">+ {{ $t('entities.add') }}</primary-button>
        </module-header>

        <v-data-table
                :headers="customHeaders"
                :items="notArchivedCategories"
                :hide-default-footer="true"
                :disable-pagination="true"
                :no-data-text="$t('entities.studyProblems.no_study_category')"
                class="elevation-1"
                :sort-by.sync="$query.sort"
                :sort-desc.sync="$query.desc"
        >
          <template v-slot:item.name="{ item }">
            <b class="pointer light-green--text"  @click="goToDetail(item.id)">{{item.name}}</b>
          </template>
        </v-data-table>

       <h5 class="mt-10 mb-3">Archivované kategorie</h5>
       
       <v-data-table
                :headers="customHeaders"
                :items="archivedCategories"
                :sort-by="['id']"
                :sort-desc="[true]"
                :hide-default-header="true"
                :hide-default-footer="true"
                :disable-pagination="true"
                :no-data-text="$t('entities.studyProblems.no_study_category')"
                class="elevation-1 archived-table"
        >
         <template v-slot:item.name="{ item }">
           <b class="pointer light-green--text" @click="goToDetail(item.id)">{{item.name}}</b>
         </template>
        </v-data-table>

    </v-container>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import Loading from "../../../../../components/Loading";
    import BackButton from "../../../../../components/layout/BackButton";
    import PrimaryButton from "../../../../../components/buttons/PrimaryButton";
    import ModuleHeader from '../../../../../components/layout/ModuleHeader.vue';

    export default {
        name: "StudyProblemsCategoryPortal",

        components: {
            BackButton,
            PrimaryButton,
            Loading,
            ModuleHeader,
        },

        data() {
            return {
                headers: [
                    {
                        text:  this.$t('headers.title'),
                        align: 'left',
                        value: 'name',
                        width: '33%',
                        sortable: true,
                    },
                    {
                        text:  this.$t('headers.code'),
                        align: 'left',
                        value: 'code',
                        width: '15%',
                        sortable: true,
                    },
                    {
                        text:  this.$t('headers.study_form'),
                        align: 'left',
                        value: 'type',
                        width: '15%',
                        sortable: true,
                    },
                ],

                dataToDataTable: []
            }
        },

        created() {
            this.fetchStudyProblemsCategories();
        },

        computed: {
            ...mapGetters('studyProblemsCategories', ['getStudyProblemsCategories', 'didLoadedStudyProblemsCategories']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),

            didLoadedAll() {
                return this.didLoadedStudyProblemsCategories;
            },
            
            isStudyAdmin() {
                return this.$auth.getStudyAssistantAndRole()?.role === 'STUDY_ADMIN';
            },

            customHeaders() {
                return this.isStudyAdmin ? this.headers : this.headers.slice(0, -1);
            },

            categories() {
                return this.getStudyProblemsCategories.map(item => ({
                    name: item.name,
                    name_en: item.name_en,
                    code: item.code,
                    type: this.returnCorectTraslationForType(item.type),
                    id: item.id,
                    archived: item.archived,
                }));
            },

            notArchivedCategories() {
                return this.categories.filter(f => !f.archived);
            },

            archivedCategories() {
                return this.categories.filter(f => f.archived);
            },
        },

        methods: {
            ...mapActions('studyProblemsCategories', ['fetchStudyProblemsCategories']),
            
            goToNew() {
                this.$router.push({name: 'StudyProblemsCategoryDetail', params: {Rid: "new"}});
            },

            goToDetail(Rid) {
                if(this.isStudyAdmin){
                  this.$router.push({name: 'StudyProblemsCategoryDetail', params: {Rid: Rid}});
                }
            },

            returnCorectTraslationForType(type) {
                  switch (type) {
                      case 'weekly_type':
                          return this.$t('entities.study_types.weekly_type');
                      case 'daily_type':
                          return this.$t('entities.study_types.daily_type');
                      case 'other_type':
                          return this.$t('entities.study_types.other_type');
                  }
            },

        }
    }

</script>

<style scoped>

.archived-table >>> td:not(:last-child) {
    color: rgb(167, 167, 167);
}

</style>
