import moment from "moment";


const timeFormat = "HH:mm"
const findOpenHours = (studyOpenHours, wantedDay) => studyOpenHours.find(openHours => openHours.dayNumber === (wantedDay.getDay()).toString() && openHours.purpose === "mobile")

export const getOpeningTime = (studyOpenHours, wantedDay) => {
    const openTime = getOpeningTimeMoment(studyOpenHours, wantedDay)
    if (openTime === null)
        return moment(wantedDay).hours(9).minutes(0).format(timeFormat);
    return openTime.format(timeFormat);
};

export const getClosingTime = (studyOpenHours, wantedDay) => {
    const closeTime = getClosingTimeMoment(studyOpenHours, wantedDay)
    if (closeTime === null)
        return moment(wantedDay).hours(23).minutes(59).format(timeFormat);
    return closeTime.format(timeFormat);
};

const getOpeningTimeMoment = (studyOpenHours, wantedDay) => {
    let time = findOpenHours(studyOpenHours, wantedDay).openTime
    if (time === null)
        return null
    return moment(time, timeFormat);
};

const getClosingTimeMoment = (studyOpenHours, wantedDay) => {
    let time = findOpenHours(studyOpenHours, wantedDay).closeTime
    if (time === null)
        return null
    return moment(time, timeFormat);
};

export const getOpeningTimeForReservation = (studyOpenHours, wantedDay) => {
    const openingTime = getOpeningTimeMoment(studyOpenHours, wantedDay)
    const minuteAfterMidnight = moment(wantedDay).hours(0).minutes(1);
    if (openingTime === null)
        return minuteAfterMidnight.format(timeFormat);
    // return 0:01 if (openTime-2 hours) is before midnight
    return moment.max(openingTime.subtract(2, "hours"), minuteAfterMidnight).format(timeFormat);
};

export const getClosingTimeForReservation = (studyOpenHours, wantedDay) => {
    const closingTime = getClosingTimeMoment(studyOpenHours, wantedDay)
    const minuteBeforeMidnight = moment(wantedDay).hours(23).minutes(59);
    if (closingTime === null)
        return moment(wantedDay).hours(23).minutes(59).format(timeFormat);
    // return 23:59 if (openTime+1 hour) is after midnight
    return moment.max(closingTime.add(1, "hours"), minuteBeforeMidnight).format(timeFormat);
};