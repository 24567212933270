<template>
    <Loading v-if="didLoadedAll === false"></Loading>

    <v-container fluid v-else>
        <BackButton :target="{name: 'EntitiesPortal'}">{{ $t('entities.entity_portal') }}</BackButton>
        <module-header>
            <h2>{{ $t('entities.studyfield.portal_name') }}</h2>
            <v-spacer/>
            <primary-button v-if="isStudyAdmin" @click="goToNew()" class="ml-5">+ {{ $t('entities.add') }}</primary-button>
        </module-header>

            <v-data-table
                    :headers="customHeaders"
                    :items="notArchivedStudyFields"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                    :no-data-text="$t('entities.studyfield.no_studyfield_refs')"
                    class="elevation-1"
                    :sort-by.sync="$query.sort"
                    :sort-desc.sync="$query.desc"
            >
                <template v-slot:top>
                    <v-row class="pb-3 px-3">
                        <v-col cols="6">
                          <v-select color="light-green darken-1" :items="studyDegrees" v-model="$query.degree" type="string" :label="$t('entities.studyfield.study_deg')"
                                    clear-icon="clear"
                                    :menu-props="{maxHeight: '60vh'}"
                                    :clearable="true"
                          ></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select color="light-green darken-1" :items="studyForms" v-model="$query.form" type="string" :label="$t('entities.studyfield.study_form')"
                            clear-icon="clear"
                            :menu-props="{maxHeight: '60vh'}"
                            :clearable="true"
                            ></v-select>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.targetLanguages="{ item }">
                    <v-chip 
                        v-for="language in item.targetLanguages" 
                        :key="language" 
                        class="ma-1"
                        :color="languageColor(language)"
                    >{{ language }}</v-chip>
                </template>
              <template v-slot:item.name="{ item }" >
                  <b  class="pointer light-green--text" @click="goToDetail(item.id)">{{ item.name }}</b>
              </template>

            </v-data-table>

            <h5 class="mt-10 mb-3">Archivované obory</h5>

            <v-data-table
                :headers="customHeaders"
                :items="archivedStudyFields"
                :sort-by.sync="$query.sortArchived"
                :sort-desc.sync="$query.descArchived"
                :hide-default-header="false"
                :disable-pagination="true"
                :hide-default-footer="true"
                :no-data-text="$t('entities.studyfield.no_studyfield_refs')"
                class="elevation-1 archived-table"
            >
                <template v-slot:item.targetLanguages="{ item }">
                    <v-chip 
                        v-for="language in item.targetLanguages" 
                        :key="language" 
                        class="ma-1"
                    >{{ language }}</v-chip>
                </template>

            </v-data-table>
    </v-container>
</template>

<script>
import BackButton from "../../../../../components/layout/BackButton";
import Loading from "../../../../../components/Loading";
import {mapActions, mapGetters} from 'vuex';
import ModuleHeader from '../../../../../components/layout/ModuleHeader.vue';
import PrimaryButton from '../../../../../components/buttons/PrimaryButton.vue';
import {languageColors} from '@/localization/languages.js';

export default {
        name: "StudyFieldPortal",

        components: {
            BackButton,
            Loading,
            PrimaryButton,
            ModuleHeader,
        },

        data() {
            return {
                headers: [
                    {
                        text: this.$t('headers.field_code'),
                        align: 'left',
                        value: 'shortCode',
                        width: '',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.field_name'),
                        align: 'left',
                        value: 'name',
                        width: '',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.study_deg_form'),
                        align: 'left',
                        value: 'typeName',
                        width: '',
                        sortable: true,
                    },
                    {
                        text: this.$t('headers.target_languages'),
                        align: 'left',
                        value: 'targetLanguages',
                        width: '',
                        sortable: false,
                    },
                    {
                      text: this.$t('headers.ref_count'),
                      align: 'center',
                      value: 'refCount',
                      width: '',
                      sortable: true,
                    }
                ],
            }
        },

        created() {
            this.fetchStudyFields();
        },

        computed: {
            ...mapGetters('studyFields', ['didLoadedStudyFields', 'getStudyFields', 'getStudyDegreeTypes', 'getStudyFormTypes']),
            ...mapGetters('pageStorage', ['getAssistantStorage']),

            customHeaders() {
                return this.isStudyAdmin ? this.headers : this.headers.filter(h => h.value !== 'action');
            },

          studyDegrees() {
            return this.getStudyDegreeTypes.map(t => {
                t.text = this.returnCorectTraslationForStudyType(t.value)
                return t
              })
          },

          studyForms() {
            return this.getStudyFormTypes.map(t => {
              t.text = this.returnCorectTraslationForType(t.value)
              return t
            })
          },

            didLoadedAll() {
                return this.didLoadedStudyFields;
            },

            isStudyAdmin() {
                return this.$auth.getStudyAssistantAndRole()?.role === 'STUDY_ADMIN';
            },

            studyFields() {
                let result = [];
                let studyFields = this.getStudyFields;

                for (let item of studyFields) {
                    let dataPrototype = {
                        name: item.name,
                        shortCode: item.shortCode !== 'other' ? item.shortCode : this.$t('entities.study_types.study'),
                        studyForm: item.type,
                        studyDegree: item.studyType,
                        typeName: this.returnCorectTraslationForStudyType(item.studyType) + ', ' + this.returnCorectTraslationForType(item.type),
                        id: item.id,
                        archived: item.archived,
                        targetLanguages: item.targetLanguages,
                        refCount: item.assistantsIds.length
                    }
                    result.push(dataPrototype);
                }

                return result;
            },

            notArchivedStudyFields() {
              // filter out archived
              let studyFields = this.studyFields.filter(f => !f.archived);
              // filter out study fields with different degree than selected
              studyFields = studyFields.filter(f => {
                return !this.$query.degree || (f.studyDegree === this.$query.degree)
              })
              // filter out study fields with different form than selected
              studyFields = studyFields.filter(f => {
                return !this.$query.form || (f.studyForm === this.$query.form)
              })

                return studyFields
            },

            archivedStudyFields() {
              // filter out not archived
              let studyFields = this.studyFields.filter(f => f.archived);
              // filter out study fields with different degree than selected
              studyFields = studyFields.filter(f => {
                return !this.$query.degree || (f.studyDegree === this.$query.degree )
              })
              // filter out study fields with different form than selected
              studyFields = studyFields.filter(f => {
                return !this.$query.form || (f.studyForm === this.$query.form)
              })

              return studyFields
            },
        },

        methods: {
            ...mapActions('studyFields', ['fetchStudyFields']),

            returnCorectTraslationForType(type) {
                switch (type) {
                    case 'weekly_type':
                        return this.$t('entities.study_types.weekly_type_short');
                    case 'daily_type':
                        return this.$t('entities.study_types.daily_type_short');
                    case 'other_type':
                        return this.$t('entities.study_types.other_type');
                }
            },


            returnCorectTraslationForStudyType(type) {
                switch (type) {
                    case 'Bachelor':
                        return this.$t('entities.study_types.Bachelor_short');
                    case 'Master':
                        return this.$t('entities.study_types.Master_short');
                    case 'Doctoral':
                        return this.$t('entities.study_types.Doctoral_short');
                    case 'Other':
                        return this.$t('entities.study_types.other_type');
                }
            },

            languageColor(language) {
                return languageColors[language] ?? 'light';
            },

            goToNew() {
                this.$router.push({name: 'StudyFieldDetail', params: {Rid: "new", from: "portal"}});
            },

            goToDetail(Rid) {
                this.$router.push({name: 'StudyFieldDetail', params: {Rid: Rid, from: "portal"}});

            }
        }
    }
</script>

<style scoped>

.archived-table >>> td:not(:last-child) {
    color: rgb(167, 167, 167);
}

.archived-table >>> .v-chip {
    opacity: 0.5;
}

</style>