<template>
    <v-toolbar flat height="65">
        <v-toolbar-title>
            <router-link to="/">
                <v-list-item class="listitem">
                    <Logo height="80" width="80"/>
                    <v-list-item-title class="title pointer">
                        Moje MENDELU
                        <b v-if="currentEnv === 'development'" class="primary--text">DEV</b>
                        <b v-else-if="currentEnv === 'debug'" class="error--text">DEBUG</b>
                    </v-list-item-title>
                </v-list-item>
            </router-link>
        </v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-toolbar-items id="userToolBar" v-if="isLoggedIn">
            <v-menu bottom>
                <template v-slot:activator="{ on }">
                    <v-btn text color="secondary" v-on="on" data-test="userIcon" class="no-uppercase">
                        {{ username }}
                        <v-icon right class="pr-1">person</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-btn text @click="doLogout" data-test="logoutUser">
                        <v-icon left>power_settings_new</v-icon>
                        Odhlásit se
                    </v-btn>
                </v-list>
            </v-menu>
        </v-toolbar-items>
    </v-toolbar>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Logo from '../assets/logo.svg';
    import {config} from '../config';

    export default {
        name: 'Top',

        components: {Logo},

        data: () => ({
            logo: require('@/assets/logo.svg')
        }),

        computed: {
            ...mapGetters('user', ['isLoggedIn', 'username']),

            currentEnv() {
                return config.env;
            }
        },

        methods: {
            ...mapActions('user', ['logout']),

            doLogout() {
                if (this.isLoggedIn) {
                    this.$auth.manuallyLogout();
                } else {
                    this.$router.push({name: 'dashboard'});
                }

            },
        }
    }
</script>

<style scoped>
    .v-icon {
      margin-right: 10px;
    }

    .user-name {
        padding-top: 20px;
    }

    a.router-link-active {
        text-decoration: none;
    }

    .title {
        font-size: 20px;
        font-weight: 600;
    }

    .listitem {
        padding: 0px;
    }

    @media only screen and (max-width: 820px) {
        .listitem {
          margin-left: 15px;
        }
    }

    header {
        border-bottom: 2px solid #c4c4c4 !important;
    }

    .no-uppercase {
        text-transform: none;
    }

    .v-toolbar {
        /* idk why but toolbar has this default settings for flex grow and shrink that must be overriden to avoid having toolbar over half a page */
        flex-grow: unset;
        flex-shrink: unset;
    }

</style>
