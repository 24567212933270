<template>
  <div class="keep-size">
    <v-card hover elevation="0" class="card" :color="this.wasRead ? 'grey lighten-4' : 'white'" >

      <v-row class="my-0">

        <v-col cols="10" class="py-0" @click="this.onClick">
            <div>
          <b v-if="this.$slots.number" class="mr-2 number-text black--text"
            >#<slot name="number"></slot></b
          >
          <v-chip
            v-if="this.$slots.tag"
            :color="this.tagColor"
            x-small
            label
            class="tag-text white--text"
          >
            <slot name="tag"></slot>
          </v-chip>
        </div>
        <div>
          <b class="pointer title-text light-green--text">
            <slot name="title"></slot>
          </b>
        </div>
        <div class="subtitle-text ">
          <v-icon v-if="this.$slots.icon1" color="black" class="mr-1" small
            ><slot name="icon1"></slot
          ></v-icon>
          <span v-if="this.$slots.subtitle1" class="black--text mr-2"
            ><slot name="subtitle1"></slot
          ></span>
          <v-icon v-if="this.$slots.icon2" color="black" class="mr-1" small
            ><slot name="icon2"></slot
          ></v-icon>
          <span v-if="this.$slots.subtitle2" class=" black--text"
            ><slot name="subtitle2"></slot
          ></span>
        </div>

        <v-chip
            x-small
            label
            class="px-1 mt-1 mr-1"
            v-for="(item, index) in this.extra" 
            v-bind:key="index" 
          >
            <v-icon v-if="!!item.iconName" size="12" class="mr-1">{{ item.iconName }}</v-icon>
            <span style="font-size:10px">{{item.title}}</span>
        </v-chip>

        <div :class="this.$slots.label_header1 && this.$slots.label_header2 ? 'd-flex mt-2 pb-5' : 'd-flex mt-2 pb-3'">
          <table>
            <tbody>
              <tr>
                <td class="tag-text pr-5 grey--text">
                  <b>
                    <slot name="label_header1"></slot>
                  </b>
                </td>
                <td class="tag-text grey--text">
                  <b>
                    <slot name="label_header2"></slot>
                  </b>
                </td>
              </tr>
              <tr>
                <td class="other-text grey--text pr-5">
                  <slot name="label_content1"></slot>
                </td>
                <td class="other-text grey--text">
                  <slot name="label_content2"></slot>
                </td>
              </tr>
            </tbody>
          </table>         
        </div>
        
        </v-col>
        <v-col cols="2" class="py-0">
          <v-btn
            v-if="this.$slots.action_button"
            @click="this.onAction"
            class="floating-btn"
            elevation="0"
            fab
            small
            color="white"
          >
      <v-icon color="grey"><slot name="action_button"></slot></v-icon>
    </v-btn>
        </v-col>
      </v-row>
      <v-divider/>

    </v-card>
    
  </div>
</template>

<script>
export default {
  name: "MobileDataTableRow",

  props: {
    tagColor: {
      type: String,
      required: false,
      default: "orange",
    },
    wasRead: {
      type: Boolean,
      required: false,
      default: false,
    },
    extra: {
      type: Array
    },

  },
  methods: {
    onClick() {
      this.$emit("click");
    },
    onAction() {
      this.$emit("action");
    },
  },
};
</script>

<style scoped>
.title-text {
  font-size: 13px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag-text {
  font-size: 8px;
  font-weight: 500;
}
.other-text {
  font-size: 10px;
}
.subtitle-text {
  font-size: 10px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.number-text {
  font-size: 11px;
}
.card {
  z-index: 1;
}
.floating-btn {
  position: absolute;
  bottom: 8px;
  right: 0;
  z-index: 2;
}
.keep-size {
  min-width: 230px;
}
</style>