import { get, post, del, put } from "@/services/api/";
import { uuid } from 'vue-uuid';
import { languages } from '../../../localization/languages';
import { sanitize } from "@/utils";

const state = {
    floors: [],
    hasUnsavedFloors: false,
};

const getters = {
    getFloors: state => {
        return [...state.floors].sort((a, b) => b.level - a.level);
    },
    getSanatizedFloorLocalizations: (state, getters) => (buildingId, floorId) => {
        let allNames = [];
        let buildingFloors = getters.getBuildingFloors(buildingId);
        buildingFloors = buildingFloors.filter(f => {
            if(f.id == undefined) return f.ghostId !== floorId;
            else return f.id !== floorId;
        });

        for (let language of languages) {
            allNames = allNames.concat(buildingFloors.map(f => f.localizedAttributes[language] ? sanitize(f.localizedAttributes[language].name) : ""));
        }
        return allNames;
    },
    getBuildingFloors: state => buildingId => {
        const filteredFloors = state.floors.filter(f => f.buildingId == buildingId);
        filteredFloors.sort((a, b) => b.level - a.level);
        return filteredFloors;
    },
    getBuildingFloorsExceptOne: (state, getters) => floorData => {
      let floors = getters.getBuildingFloors(floorData.buildingId);
      return floors.filter(f=> (f.id !== floorData.id || floorData.ghostId !== f.ghostId));
    },
    getFloorById: state => id => state.floors.find(f => f.id === id),
    floorByPlaceId: state => item =>  state.floors.find(floor => floor.id === item.floorId),
    hasUnsavedFloors: state => state.hasUnsavedFloors,
    isFloorValidInLanguage: () => (floor, language) => {
        const localization = floor.localizedAttributes[language];
        return localization !== undefined && localization["name"];
    },
    isFloorValid: (state, getters) => floor => {
      for (let language of languages) {
        if (!getters.isFloorValidInLanguage(floor, language)) {
          return false;
        }
      }
      return true;
    },

    areFloorsValidInLanguage: (state, getters) => language => {
      for (let floor of state.floors) {
        if (!getters.isFloorValidInLanguage(floor, language)) {
          console.log('floor ', floor, ' ot valid');
          return false;
        }
      }
      return true;
    },
    areFloorsValid: (state, getters) => {
      for (let floor of state.floors) {
        if (!getters.isFloorValid(floor)) {
          return false;
        }
      }
      return true;
    },
};

const actions = {

    clearFloors({ commit }) {
      commit('initFloors')
    },

    createGhostFloor({ commit }, floor ) {
        floor.ghostId = uuid.v4();
        commit('pushFloor', floor);
        commit('changeUnsavedStatus', true);
    },

    updateGhostFloor({ commit }, floor ) {
        commit('updateGhostFloor', floor);
    },

    removeGhostFloor({ commit }, floor ) {
        commit('removeGhostFloor', floor);
    },

    async fetchAllFloors({ commit}) {
      await get('map/floors/').then( response => {
        commit('setFloors', response.data.items);
      });
    },

    async fetchFloorById({ commit }, id ) {
      await get(`map/floors/${id}`).then(( response ) => {
        commit('pushFloor', response.data.content);
      });
    },

    async createFloor({ commit }, floor ) {
      return new Promise((resolve) => {
          post(`map/floors/`, floor).then(( newFloor ) => {
            if (floor.ghostId !== null) {
              commit('unghostFloor', {ghostId: floor.ghostId, realId: newFloor.data.content.id});
              commit('updateFloor', newFloor.data.content);
            } else {
              commit('pushFloor', newFloor.data.content);
            }
            resolve( newFloor.data.content );
        });
      });
    },

    updateFloor({ commit }, floor ) {
      put(`map/floors/${floor.id}`, floor).then(( newFloor ) => {
        commit('updateFloor', newFloor.data.content)
      });
    },

    deleteFloor({ commit }, id ) {
      del(`map/floors/${id}`).then(() => {
        commit('filterFloor', id)
      });
    },

};

const mutations = {
      initFloors: ( state ) => state.floors = [],
      setFloors: ( state, floors ) => state.floors = floors,
      pushFloor: ( state, floor ) => state.floors.push(floor),
      filterFloor: ( state, id ) => state.floors = state.floors.filter(f => f.id !== id),
      updateFloor: ( state, item ) => {
        let copy = state.floors.slice();
        let index = copy.findIndex(f => f.id == item.id)
        copy[index] = item;
        state.floors = copy;
      },

      changeUnsavedStatus: ( state, status ) => {
        state.hasUnsavedFloors = status;
      },

      unghostFloor: (state, {ghostId, realId}) => {
        let index = state.floors.findIndex(f => f.ghostId == ghostId);
        state.floors[index].id = realId;
        delete state.floors[index].ghostId;
      },
      updateGhostFloor: ( state, item ) => {
        let copy = state.floors.slice();
        let index = copy.findIndex(f => f.ghostId == item.ghostId);
        copy[index] = item;
        state.floors = copy;
      },
      removeGhostFloor: ( state, item ) => {
        state.floors = state.floors.filter(f => f.ghostId !== item.ghostId)
      },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
