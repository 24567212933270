<template>
    <div class="map-places-table">
      <Alert
          :model="errorOnDelete"
          :onConfirm="() => {this.errorOnDelete = false; this.errorMessage = ''}"
      >
        {{errorMessage}}
      </Alert>
            <v-data-table
                v-model="selectedItems"
                :headers="getHeadersOfTable"
                :items="filteredPlaces"
                disable-sort
                show-select
                no-data-text = "Žádné údaje"
                :loading = "!didLoadedPlaces"
                loading-text="Načítání..."
                no-results-text="Žádné údaje"
                class="d-none d-sm-block"
                :page="$query.page"
                :items-per-page="$query.itemsPerPage"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                color="light-green darken-1"
                                v-model="$query.search"
                                prepend-icon="search"
                                label="Hledat"
                                single-line
                                hide-details
                                clear-icon="clear"
                                clearable
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-select color="light-green darken-1" :items="getSortedPlacesTypes" v-model="$query.placeType" type="string" label="Typ"
                                    clear-icon="clear"
                                    :menu-props="{maxHeight: '60vh'}"
                                    clearable
                          ></v-select>
                        </v-col>
                        <v-col cols="3">
                          <v-select color="light-green darken-1" :items="buildingsWithExterior" v-model="$query.buildingId" type="number" label="Budova"
                                    clear-icon="clear"
                                    clearable
                          ></v-select>
                        </v-col>
                        <v-col cols="2">
                          <v-select color="light-green darken-1" :items="buildingFloors" v-model="$query.floorId" type="string" label="Podlaží"
                                    clear-icon="clear"
                                    clearable
                                    :disabled="isExteriorSelected || buildingId === null"
                          ></v-select>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.id="{ item }">
                    <span class="pointer" @click="goToDetail(item.id)">{{ item.id }}</span>
                </template>
                <template v-slot:item.name="{ item }" >
                  <div v-if="item.localizedAttributes.cz">
                    <b v-if='!item.localizedAttributes.cz.name' class="pointer light-green--text" @click="goToDetail(item.id)">Místo bez názvu</b>
                    <b v-else class="pointer light-green--text" @click="goToDetail(item.id)">{{ item.localizedAttributes.cz.name }}</b>
                  </div>
                </template>
                <template v-slot:item.type="{ item }">
                    {{ typeName(item.type) }}
                </template>
                <template v-slot:item.floorId="{ item }">
                    <span v-if='item.floorId === null '>Exteriér</span>
                    <span v-else>{{ getFloorNumber(item) }}</span>
                </template>
                <template v-slot:item.lastUpdate="{ item }">
                    <span v-if="item.lastUpdate">
                    {{ formatDateTime(item.lastUpdate,'lll') }}
                    </span>
                </template>
                <template v-if="selectedItems.length!==0" v-slot:body.append>
                    <v-row>
                        <v-btn small class="actionsDetails mx-5 my-5"
                               @click.stop="dialog = true"
                               :disabled="selectedItems.length === 0"
                        >Smazat</v-btn>
                        <DeleteDialog :method="attemptToDeletePlaces" :dialog="dialog" v-on:childToParent="updateDialog"></DeleteDialog>
                    </v-row>
                </template>
				<template v-slot:footer>
					<v-row class="text-center pt-2">
						<v-col cols="12" md="4" order="second" order-md="first">
							<v-select
								:items="paginationSettings"
								label="Rows per page"
								v-model="$query.itemsPerPage"
								color="light-green darken-1"
							></v-select>
						</v-col>
						<v-col cols="12" md="8" order="first" order-md="second">
							<v-container
								style="max-width: 90%;">
								<v-pagination
									v-model="$query.page"
									total-visible="10"
									color="light-green darken-1"
									:length="paginationLength"
								></v-pagination>
							</v-container>
						</v-col>
					</v-row>
				</template>
            </v-data-table>

            <!-- ONLY FOR SMALLER SCREENS -->
            <v-text-field
                color="light-green darken-1"
                class="rounded-lg make-it-small d-block d-sm-none mb-1"
                v-model="$query.search"
                prepend-inner-icon="search"
                label="Hledat"
                filled
                dense
                flat
                single-line
                hide-details
            />
            <v-row class="my-0 d-flex d-sm-none">
                <v-col cols="4">
                    <MobileFiltering
                        tag_icon="close"
                        :items="getSortedPlacesTypes"
                        v-model="$query.placeType"
                    >
                    <template v-slot:title>TYP</template>
                    </MobileFiltering>
                </v-col>
                <v-col cols="4" class="pl-1">
                    <MobileFiltering
                        tag_icon="close"
                        :items="buildingsWithExterior"
                        v-model="$query.buildingId"
                    >
                    <template v-slot:title>BUDOVA</template>
                    </MobileFiltering>
                </v-col>
				<v-col cols="4" class="pl-1">
					<MobileFiltering
						tag_icon="close"
						:items="buildingFloors"
						v-model="$query.floorId"
					>
						<template v-slot:title>PATRO</template>
					</MobileFiltering>
				</v-col>
            </v-row>
            <v-data-iterator
                :items="filteredPlaces"
                :search.sync="$query.search"
                :custom-filter="filterAllByName"
                :hide-default-footer="true"
                :disable-pagination="true"
                :hide-default-header="false"
                no-results-text="Žádné údaje"
                loading-text="Načítání..."
                class="d-block d-sm-none"
                :page="$query.page"
                :items-per-page="$query.itemsPerPage"
            >
                <template v-slot:no-data>
                        Žádná dostupná data
                </template>
				<template v-slot:default="props">
					<v-row>
						<v-col v-for="item in props.items" :key="item.id" cols="12">
							<mobile-data-table-row
								@click="goToDetail(item.id)"
								@action="deletePlace(item.id)"
							>
								<template v-slot:number>{{ item.id }}</template>
								<template v-slot:title>{{ item.localizedAttributes.cz.name }}</template>
								<template v-slot:icon1>place</template>
								<template v-slot:subtitle1>{{ item.floorId === null ? 'exteriér' : getFloorNumber(item) }}</template>
								<template v-slot:label_header1>UPRAVENO</template>
								<template v-slot:label_header2>TYP</template>
								<template v-slot:label_content1>{{ item.lastUpdate ? formatDateTime(item.lastUpdate,'lll') : '' }}</template>
								<template v-slot:label_content2>{{ getTypeName(item.type).toLowerCase() }}</template>
								<template v-slot:action_button>delete_outline</template>
							</mobile-data-table-row>
						</v-col>
					</v-row>
				</template>
				<template v-slot:footer>
					<v-row class="text-center pt-2">
						<v-col cols="12">
							<v-container>
								<v-pagination
									v-model="$query.page"
									total-visible="10"
									color="light-green darken-1"
									:length="paginationLength"
								></v-pagination>
							</v-container>
						</v-col>
						<v-col cols="12">
							<v-select
								:items="paginationSettings"
								label="Rows per page"
								v-model="$query.itemsPerPage"
								color="light-green darken-1"
							></v-select>
						</v-col>
					</v-row>
				</template>
			</v-data-iterator>
    </div>
</template>

<script>

    import {mapActions, mapGetters} from 'vuex';
    import moment from 'moment';
    import DeleteDialog from "@/components/map/DeleteDialog";
    import MobileDataTableRow from '../../components/layout/MobileDataTableRow.vue';
    import MobileFiltering from '../../components/layout/MobileFiltering.vue';
    import Alert from "../../components/dialogs/Alert";

    export default {
        name: "Places",

        components: {
            DeleteDialog,
            MobileDataTableRow,
            MobileFiltering,
            Alert
        },

        data() {
            return {
                selectedItems: [],
                dialog:false,
                placeEditedDate: '',
                dateCleared: false,
                floorsList: [],
                errorOnDelete: false,
                errorMessage: '',
				paginationSettings: [
					{
						value: 10,
						text: 10
					},
					{
						value: 20,
						text: 20
					},
					{
						value: 50,
						text: 50
					},
					{
						value: 100,
						text: 100
					},
					{
						value: -1,
						text: 'Všechny'
					},
				],
				fetchTimeout: false,
				searchTimeout: null,
            }
        },

        mounted(){
            this.fetchAllFloors();
            this.fetchAllBuildingsIfNecessary();
            this.fetchData();

            if (this.$query.itemsPerPage < 1) this.$query.itemsPerPage = 10
        },

        watch: {
          '$route.query.buildingId': {
            handler() {
              this.$query.floorId = null;
              this.$query.page = 1
              this.fetchData();
            }
          },
          '$route.query.floorId': {
            handler() {
              this.$query.page = 1
              this.fetchData();
            }
          },
          '$route.query.page': {
            handler() {
              this.fetchData();
            }
          },
          '$route.query.itemsPerPage': {
            handler() {
              this.$query.page = 1
              this.fetchData();
            }
          },
          '$route.query.placeType': {
            handler() {
              this.$query.page = 1
              this.fetchData();
            }
          },
          '$route.query.search': {
            handler() {
              if (!this.$query.search) this.$query.search = '';
              
              clearTimeout(this.searchTimeout);
              this.searchTimeout = setTimeout(() => {
                this.$query.page = 1
                this.fetchData();
              }, 500)
            }
          },

        },

        computed: {
            ...mapGetters('floors', ['getFloors', 'getBuildingFloors']),
            ...mapGetters('buildings', ['getBuildings']),
            ...mapGetters('places', ['getPlaces', 'getPlacesTypes', 'getTypeName', 'didLoadedPlaces', 'getHeadersOfTable', 'getTotalItemCount']),
            
            filteredPlaces() {
                // type
                let places = this.getPlaces.filter((i) => {
                    return !this.$query.placeType || (i.type === this.$query.placeType);
                });

                // building
                const buildingFloors = this.getBuildingFloors(this.buildingId);
                places = places.filter((i) =>
                    !this.buildingId || // no filter
                    (this.isExteriorSelected && i.floorId === null) || // exterier (floor is null there)
                    buildingFloors.some(f => f.id === i.floorId) // a specific building - filter all places on one of building's floors
                );

                // floor
                places = places.filter((i) => 
                    !this.$query.floorId || // no filter
                    i.floorId === this.$query.floorId // filter a specific floor's places
                );

                // date
                places = places.filter((i) => {
                    let compareRes = false;
                    let lastUpdated = i.lastUpdate;
                    if (this.placeEditedDate !== "" && lastUpdated !== null){
                        compareRes = this.compareDates(lastUpdated,this.placeEditedDate,"YYYY-MM-DD");
                    }
                    return !this.placeEditedDate || compareRes;
                });
                return places;
            },

            buildings() {
                return this.getBuildings.map(b => ({
                    text: b.localizedAttributes.cz.name, 
                    value: b.id
                }));
            },

          isExteriorSelected() {
            return this.buildingId === -1
          },

          buildingId(){
            // use this computed property for reading value of this.$query.buildingId for NaN -> null conversion
            // (this.$query.buildingId cannot store null value)
              return Number.isInteger(this.$query.buildingId) ? this.$query.buildingId : null
          },

          floorId(){
            // use this computed property for reading value of this.$query.floorId for NaN -> null conversion
            // (this.$query.floorId cannot store null value)
            return Number.isInteger(this.$query.floorId) ? this.$query.floorId : null
          },

          buildingsWithExterior() {
                let copy = this.buildings.slice();
                copy.push({
                  text: "Exteriér",
                  value: -1
                });
                return copy;
            },

            buildingFloors() {
                return this.getBuildingFloors(this.buildingId).map(f => ({
                    text: f.localizedAttributes.cz.name, 
                    value: f.id
                }));
            },

            getSortedPlacesTypes(){
              let placesTypes = this.getPlacesTypes;
              placesTypes = placesTypes.sort((a, b) => {
                return a.text.localeCompare(b.text);
              });
              return placesTypes;
            },


			paginationLength() {
				return Math.ceil(this.getTotalItemCount / this.$query.itemsPerPage);
			}
        },

        methods: {
            ...mapActions('floors', ['fetchAllFloors']),
            ...mapActions('buildings', ['fetchAllBuildingsIfNecessary']),
            ...mapActions('places', ['deletePlace', 'fetchPlacesByPageAndItemsPerPage']),

            reverseSortByNumber(){
                this.sortDesc =  !this.sortDesc
            },

            filterByName(value, search, item) {
              return value !== null && (item.localizedAttributes.cz.name.toLocaleUpperCase()).search(search.toLocaleUpperCase()) !== -1;
            },

            filterAllByName(items, search){
                return items.filter( i => (i.localizedAttributes.cz.name.toLocaleUpperCase()).search(search.toLocaleUpperCase()) !== -1);
            },

            getFloorNumber(item) {
                if (item.floorId !== undefined && item.floorId !== null && item.floorId !== "" && this.getFloors.length) {
                    const floor = this.getFloors.find(floor => floor.id == item.floorId);
                    return floor ? floor.level : null;
                } else {
                    return null;
                }
            },

            goToDetail(placeId) {
                this.$router.push({name:'mapDetail',params:{type: 'place', id: placeId}});
            },

            formatDateTime(timestamp, format) {
                return moment(timestamp).format(format);
            },

            typeName(value){
                return this.getTypeName(value);
            },

            async attemptToDeletePlaces() {
              try {
                for (const item of this.selectedItems) {
                  await this.deletePlace(item.id);
                }
              } catch(e) {
                this.errorOnDelete = true;
                this.errorMessage = e.message;
              }
              this.selectedItems = [];
              this.dialog = false;
            },
          
            compareDates(dateA,dateB, format){
                let formattedA = this.formatDateTime(dateA,format);
                return formattedA >= dateB;
            },

            updateDialog(){
                this.dialog = false;
            },

			fetchData() {
				this.fetchPlacesByPageAndItemsPerPage({
					searchString: this.$query.search === "" ? null : this.$query.search,
					language: "cz",
					buildingId: this.floorId === null ? (this.isExteriorSelected ? null : this.buildingId) : null,
					floorId: this.floorId,
					exterior: this.isExteriorSelected,
					placeType: this.$query.placeType,
          page : this.$query.page,
          itemsPerPage: this.$query.itemsPerPage
				});
			},
        },
    }
</script>

<style scoped>

    .map-places-table{
        margin-top:1rem;
        width:100%;
    }
    .map-places-table a {
        color: rgba(255, 255, 255, 0.75);
        text-decoration: none;
        font-size: 1.2rem;
    }

    .map-places-table tr {
        cursor: pointer;
    }

    .map-places-table >>> .v-progress-linear div{
        background-color: #7BB916 !important;
    }

    #deleteCard{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 0px 5px 1px -2px rgba(0, 0, 0, 0.2),
                    0px 5px 2px 0px rgba(0, 0, 0, 0.14),
                    0px 5px 5px 0px rgba(0, 0, 0, 0.12);
    }

    .actionsDetails{
        background: rgba(0, 0, 0, 0.45) !important;
        color: white !important;
    }

    .map-places-table >>> .v-data-table__checkbox i{
        color: rgba(0,0,0,.48);
    }
    .map-places-table >>> .v-pagination__item.v-pagination__item--active.light-green.darken-1 {
		color: #fff !important;
    }

</style>
