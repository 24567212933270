import {get, post, del, put} from '@/services/api'
import translator from '@/localization/translator';

const state = {
    buildings: [],
    buildingLoading: false,
    defaultBuilding: {
        defaultFloor: null,
        centroid: null,
        outline: [],
        localizedAttributes: {
            "cz": {
                name: "",
                description: "",
            },
            "en": {
                name: "",
                description: "",
            },
        },
    },
    newBuilding: null,
};

const getters = {
    getBuildings: state => state.buildings,
    getSingleBuilding: state => id => state.buildings.find(building => building.id == id),
    buildingByFloorId: state => item => state.buildings.find(building => building.id === item.buildingId),
    getBuildingNames: state => state.buildings.map(building => ({
        value: building.id,
        text: building.localizedAttributes ? building.localizedAttributes.cz.name : translator.translate('noName')
    })),
    getEmptyBuilding: state => state.newBuilding,
    isBuildingLoading: state => state.buildingLoading,
};

const actions = {

    async fetchAllBuildings({commit}) {
        commit('changeBuildingLoadStat', true);
        await get(`map/buildings/`).then((buildings) => {
            commit('setBuildings', buildings.data.items)
            commit('changeBuildingLoadStat', false);
        });
    },

    checkIfBuildingExists({commit}, id) {
        commit('errors/clear', null, {root: true});
        if (!state.buildings.find(b => b.id == id)) {
            commit('errors/objectNotFound', null, {root: true});
        }
    },

    async fetchAllBuildingsIfNecessary({dispatch, getters}) {
        if (getters.getBuildings.length === 0) {
            await dispatch('fetchAllBuildings');
        }
    },

    async fetchBuildingById({commit}, id) {
        const response = await get(`map/buildings/${id}`)
        commit('pushBuilding', response.data.content);
    },

    createBuilding({commit}, item) {
        return new Promise((resolve) => {
            post('map/buildings/', item)
                .then((newItem) => {
                    commit('addBuilding', newItem.data.content)
                    resolve(newItem.data.content);
                });
        });
    },

    async deleteBuilding({commit}, id) {
        await del(`map/buildings/${id}`)
            .then(() => {
                commit('filterBuilding', id)
            });
    },

    updateBuilding({commit}, item) {
        put(`map/buildings/${item.id}`, item)
            .then((res) => {
                commit('updateBuilding', res)
            });
    },

    prepareNewBuilding({commit}) {
        commit('prepareNew');
    },
};

const mutations = {
    setBuildings: (state, buildings) => state.buildings = buildings,
    addBuilding: (state, building) => state.buildings.push(building),
    pushBuilding: (state, building) => state.buildings.push(building),
    filterBuilding: (state, id) => state.buildings = state.buildings.filter(b => b.id !== id),
    updateBuilding: (state, item) => {
        let index = state.buildings.findIndex(b => b.id == item.id)
        state.buildings[index] = item
    },
    prepareNew: (state) => {
        state.newBuilding = JSON.parse(JSON.stringify(state.defaultBuilding));
    },
    changeBuildingLoadStat: (state, status) => {
        state.buildingLoading = status;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
