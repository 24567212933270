<template>
    <Loading v-if="loading"></Loading>
    <v-layout v-else class="right-panel fill-height">
        <component :is="component"></component>
    </v-layout>
</template>

<script>
import CallInStart from "../Children/CallInStart";
import CallInTurn from "../Children/CallInTurn";
import CallInDidNotCome from "../Children/CallInDidNotCome";
import CallInDidCome from "../Children/CallInDidCome";
import CallInPause from "../Children/CallInPause";
import CallInFallback from "../Children/CallInFallback";
import CallInNextInLine from "../Children/CallInNextInLine";
import Loading from "@/components/Loading";

import {mapActions, mapGetters} from 'vuex';

export default {
    name: "CheckInInfobar",

    components: {Loading, CallInPause, CallInDidCome, CallInDidNotCome, CallInTurn, CallInStart, CallInFallback, CallInNextInLine},

    props: {
        assistantID: [String, Number],
    },

    data() {

        return {
            component: null,
            loading: true,
        }
    },

    created() {
        this.setCurrentComponentFromStorage();
        this.checkForActivePause();
    },

    mounted() {
        this.$root.$on('callInDidCome', () => {
            this.component = 'CallInDidCome';
        });

        this.$root.$on('callInDidNotCome', () => {
            this.component = 'CallInDidNotCome';
        });

        this.$root.$on('callInPauseStarted', () => {
            this.component = 'CallInPause';
        });

        this.$root.$on('callInNext', () => {
            this.component = 'CallInTurn';
        });

        this.$root.$on('callInFallback', () => {
            this.component = 'CallInFallback';
        });

        this.$root.$on('callInNextInLine', () => {
            this.component = 'CallInNextInLine';
        });

        this.$root.$on('callInStart', () => {
            this.component = 'CallInStart';
        });

        this.$root.$on('callInStart', () => {
            this.component = 'CallInStart';
        });

        this.$root.$on('checkInInfobarStopLoading', () => {
            this.loading = false;
        });

        this.checkForActivePause();
    },

    computed: {
        ...mapGetters('AssistantPause', ['getActivePause']),
        ...mapGetters('pageStorage', ['getCallInCurrentComponent']),
        
    },

    methods: {
        ...mapActions('pageStorage', ['setCallInCurrentComponentStorage']),

        emitCurrentComponent: function () {
            this.setCallInCurrentComponentStorage(this.component);

            //goes to CheckInTable
            this.$root.$emit('callInCurrentComponent');
        },

        setCurrentComponentFromStorage() {
            this.component = this.getCallInCurrentComponent;
        },

        checkForActivePause() {
            if (this.getActivePause !== null) {
                this.component = 'CallInPause';
            }
        },
    },

    watch: {
        component: function () {
            this.emitCurrentComponent();
        }
    }

}
</script>

<style scoped>
</style>