import {get} from "@/services/api";
import {TimeUnit} from "@/store/modules/studyDepartmentModules/statistics/TimeUnit";

const state = {
    didLoadStatistics: false,
    statistics: {},
};
const getters = {
    didLoadStatistics: state => state.didLoadStatistics,
    getStatistics: (state) => state.statistics,
};
const actions = {
    async fetchStatisticsByInterval({commit}, {interval, groupBy, url}) {
        commit('startedDataDownload');

        let response = await get(utils.buildURL(interval, groupBy, url))

        let content = utils.transformStatisticsResponse(response.data.content);
        commit("setStatistics", content);
        commit('finishedDataDownload');
    },
};
const mutations = {
    startedDataDownload: state => state.didLoadStatistics = false,
    finishedDataDownload: state => state.didLoadStatistics = true,
    setStatistics: (state, value) => (state.statistics = value),
};

const utils = {
    transformStatisticsResponse(content) {
        // In TimeUnit constant (anonymous object) is being found unit (attribute),
        // corresponding to "content['unit']" and it's value is returned.
        //
        // Value is object created by function which takes array of values - "content['unitValues']"
        content['unitValues'] = TimeUnit[content['unit']](content['unitValues'])
        return content
    },

    buildURL(interval, groupBy, url) {
        let editedURL = url

        if (interval !== undefined)
            editedURL += `?interval=${interval}`

        if (groupBy !== undefined)
            editedURL += (interval !== undefined) ? `&group_by=${groupBy}` : `?group_by=${groupBy}`

        return editedURL
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
