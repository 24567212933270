<template>
  <v-card :to="to" class="d-flex mx-3 keep-size" elevation="0">

      <v-btn
          color="#78BE13"
          fab
          elevation="0"
          x-small
          class="mr-3 justify-content-center align-self-center"
      >
        <slot name="icon"/>

      </v-btn>
    <div class="ellipsis justify-content-center align-self-center">
      <b class="pointer title-text light-green--text">
        <slot name="title"/>
      </b>
      <span class="subtitle-text">
        <slot name="subtitle"/>
      </span>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "FAQMobileDataTableRow",

  props: {
    to: Object
  },

};
</script>

<style scoped>
.title-text {
  font-size: 15px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle-text {
  color: #4c4c4c;
  font-size: 10px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.keep-size {
  height: 50px;
  min-width: 200px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>