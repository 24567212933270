<template>
    <v-container class="container">
        <v-row>    
            <v-col class="pl-0 mb-3">
                <h2 class="mb-2">Fakulty a součásti</h2>
                <div class="subtitle">Vyberte fakultu nebo součást, pro kterou chcete spravovat informační panel</div>
            </v-col>
        </v-row>
        <Loading v-if="!didLoadAdministrators" class="mt-12"/>
        <v-row v-else-if="units.length !== 0">
            <v-flex md6 lg4
                v-for="unit in units"
                :key="unit.name"
            >
                <v-card
                    outlined
                    v-on:click="pushToDetail(unit.id)"
                    class="mr-5 mb-5"
                >
                    <v-list-item three-line>
                        <v-list-item-content>
                            <v-img :src="controlIcon(unit.imageUrl)"
                               max-height="30"
                               max-width="30"
                        />
                            <v-list-item-title class="headline mb-1">
                                {{ unit.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ unit.description }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-actions>
                        <v-btn color="primary" text>Vstoupit</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-row>
        <v-row v-else>
            <v-col>
                <PlaceHolder 
                    :path="'/icons/faculties-place-holder.png'"
                    text="Je nám líto, momentálně nemáte priřazená práva k žádným součastem."
                    title="Žádná práva k součástem">
                </PlaceHolder>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PlaceHolder from "@/components/news/PlaceHolder";
  import Loading from "@/components/Loading";

export default {
    name: "Units",

    components: {
        Loading,
        PlaceHolder
    },

    data() {
      return {

      }
    },

    async created() {
      await this.fetchAdministrators();
    },

    computed: {
      ...mapGetters('displaysAdmins', [
        'didLoadAdministrators', 
        'getAdministrators'
      ]),

      ...mapGetters('user', ['username']),

      currentUser() {
        return this.getAdministrators.find(user => user.xlogin === this.username);
      },

      units() {
        return (this.currentUser?.faculties || []).map(faculty => ({
            id: faculty.id, 
            name: faculty.localizedAttributes.cz.name, 
            description: faculty.localizedAttributes.cz.description, 
            imageUrl: faculty.imageUrl
        }));
      },

      roles() {
        return this.$auth.getRoles();
      },
    },

    methods: {
        ...mapActions('displaysAdmins', [
          'fetchAdministrators'
        ]),

        controlIcon(icon) {
            return icon ? icon : '/icons/mendelu.png';
        },

        pushToAdministrators() {
            this.$router.push({name: 'administrators'});
        },

        pushToDetail(unitId) {
            this.$router.push({name: 'unitDetail', params: {unitId: unitId}});
        },
    }

}
</script>


<style scoped>


.button {
    color: #78BE13;
    font-weight: bold;
    font-size: 16px;
}

.titleCard {
    padding-left: 0;
    padding-top: 0;
    font-weight: 500;
    line-height: 1.2;
    word-break: normal;
    font-size: 20px;
}

.subTitleCard {
    padding: 0;
    line-height: 1.2;
}

.card {
    height: 130px;
}

.container {
    padding-bottom: 250px;
}

.subtitle {
    font-size: 16px;
    color: #6D7278;
}

</style>
