<template>
    <Loading v-if="!didLoadData || iotPlacesSorted.length === 0"/>
    <v-container v-else>
        <v-row>
            <v-col>
                <h1 class="font-weight-medium ml-4 ml-md-0">{{ $t('iotSensors.dashboardTitle') }}</h1>
            </v-col>
        </v-row>
        <v-row no-gutters class="align-center ml-2 ml-md-0 text-sm-center pt-2">
            <v-col cols="auto" class="px-1">
                <v-icon>search</v-icon>
            </v-col>
            <v-col cols="10" sm="6">
                <v-autocomplete
                    :label="$t('iotSensors.search')"
                    :items="filteredPlaces.names"
                    v-model="search"
                    :clearable=true
                >
                    <template v-slot:no-data>
                        <v-list-item>
                            <v-list-item-title>
                                {{ $t('entities.result_not_found') }}
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row v-if="!$vuetify.breakpoint.xs">
            <v-col>
                <RoundedSlideGroup :modelValue="typeModel" :search="search" @update:search="newValue => search = newValue" @update:modelValue="newValue => typeModel = newValue"
                            :listOfItems="typesList()"/>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <v-select
                    v-model="typeModel"
                    :items="typesList()"
                    :item-text="typesList().text"
                    @change="search = ''"
                    solo
                />
            </v-col>
        </v-row>
        <v-row justify="start" :class=" !$vuetify.breakpoint.xs ? 'space_for_card' : ''">
            <v-col cols="12" sm="6" md="4" lg="3" v-for="place in filteredPlaces" v-bind:key="place.id">
                <div class="card-wrapper">
                    <Transition>
                        <v-card
                            v-if="!$vuetify.breakpoint.xs"
                            class="room "
                            :class="place.measurements.length > 1 || place.computerOccupancy ? 'pa-4' : 'pa-3'"
                            @mouseover="expand(place.id)"
                            elevation="1"
                            outlined
                        >
                            <div class="btn-background">
                                <v-btn @click="goToPlaceDetail(place.id)"
                                       class="py-5 px-0 block-btn"
                                       color="primary"
                                >
                                    {{$t('entities.detail')}}
                                </v-btn>
                            </div>
                            <IotCardBody :place="place" />
                        </v-card>
                    </Transition>
                    <Transition>
                        <v-card
                            @mouseleave="collapse()"
                            v-if="(hover === place.id || $vuetify.breakpoint.xs) && (place.measurements.length > 1  || place.computerOccupancy)"
                            :class="!$vuetify.breakpoint.xs && (place.measurements.length > 1  || place.computerOccupancy) ? 'room pa-4 floating-card  transition' : 'room-expanded hide-floating-card pa-4 transition'"
                            elevation="1" outlined
                        >
                            <IotCardBody :place="place" />
                            <v-btn @click="goToPlaceDetail(place.id)"
                                   class="py-5 mt-5 px-0"
                                   width="100%"
                                   :class="place.measurements.length === 0 ? 'mt-8' : ''"
                                   color="primary"
                            >
                                {{$t('entities.detail')}}
                            </v-btn>
                        </v-card>
                    </Transition>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import {isEmpty} from "lodash";
import Loading from "@/components/Loading";
import RoundedSlideGroup from "../../components/RoundedSlideGroup.vue"
import IotCardBody from "@/components/iot/IotCardBody";


export default {
    name: 'SensorsDashboard',

    components: {
        Loading,
        RoundedSlideGroup,
        IotCardBody
    },

    data() {
        return {
            search: "",
            typeModel: "iotSensors.roomTypes.all_rooms",
            hover: null,
        }
    },

    computed: {
        ...mapGetters('IotPlaces', ['iotPlacesSorted', 'didLoadedPlaces']),

        didLoadData() {
            return this.didLoadedPlaces
        },

        filteredPlaces() {
            let placesFiltered = this.iotPlacesSorted
            if (this.search) {
                placesFiltered = placesFiltered
                    .filter(place => place.localizedAttributes.cz.name === this.search)
            } else {
                switch (this.typeModel) {
                    case "iotSensors.roomTypes.all_rooms":
                        break
                    case "iotSensors.roomTypes.pc_rooms":
                        placesFiltered = placesFiltered
                            .filter(place => place.computerOccupancy !== null)
                        break
                    default:
                        placesFiltered = placesFiltered
                            .filter(place => "iotSensors.roomTypes." + place.type === this.typeModel)
                }
            }

            placesFiltered.names = placesFiltered
                .filter(place => !isEmpty(place.localizedAttributes.cz.name))
                .map(place => place.localizedAttributes.cz.name)
            return placesFiltered

        },

    },

    created() {
        this.fetchIotPlaces();
    },

    methods: {
        ...mapActions('IotPlaces', ['fetchIotPlaces']),

        goToPlaceDetail(id) {
            this.$router.push({name: 'iotPlaceDetail', params: {id: id}})
        },

        expand(id) {
            this.hover = id
        },

        collapse() {
            if (!this.$vuetify.breakpoint.xs) {
                this.hover = null;
            }
        },

        typesList() {
            return Object.entries(this.$t('iotSensors.roomTypes')).map(type => {
                return {
                    value: "iotSensors.roomTypes." + type[0],
                    text: type[1]
                }})
        },
    },

}
</script>
<style scoped>
.card-wrapper {
    position: relative;
}
.floating-card {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto !important;
    z-index: 20;
}

.room {
    height: 206px;
    min-height: 206px;
    margin: 0 auto;
    border-radius: 12px !important;
    overflow: hidden;
}

.transition:hover {
    transition: 0.3s ease-in-out;
    font-size: 1.1em;

}

.room-expanded {
    margin: 0 auto;
    border-radius: 12px !important;
    transition: ease-in-out;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease-in-out;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.space_for_card {
    margin-bottom: 400px;
}

.btn-background {
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.2) 100%);
    position: absolute;
    width: 100%;
    top: 130px;
    padding: 20px 0;
    z-index: 3;

}


.block-btn {
    position: relative;
    width: 86%;
    left: calc(7% - 16px);
}
</style>