<template>
    <v-container>
        <h2 class="h2_header mb-5">Správa studijního oddělení</h2>
        <v-layout wrap>
            <v-flex md7 lg5 v-for="(entity, i) in cards" :key="i">
                <v-card class="mr-5 mb-5" @click="setVTabs(entity.target)" outlined>
                    <div class="mx-5 mt-1 card">
                        <img class="icon mt-2" :src="entity.icon"/>
                        <v-card-title class="titleCard">{{ entity.name }}</v-card-title>
                        <v-card-subtitle class="subTitleCard">{{ entity.text }}</v-card-subtitle>
                    </div>
                    <v-card-actions>
                        <span class="button mx-3">VSTOUPIT</span>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: "Administration",
    data() {
        return {
            cards: [
                {
                    text: this.$t('headers.study_refs_text'),
                    name: this.$t('headers.study_refs'),
                    target: 'AssistantPortal',
                    icon: "/icons/sudy-ref.png"
                },
                {
                    text: this.$t('headers.restrict_reasons_text'),
                    name: this.$t('headers.restrict_reasons'),
                    target: 'ReasonsPortal',
                    icon: "/icons/study-fields.png"
                },
            ],

        }
    },
    methods: {
        ...mapActions('pageStorage', ['setVtabsActiveCompomentStorage']),

        setVTabs(name) {
            this.setVtabsActiveCompomentStorage(name);
            name === 'AssistantPortal' ? this.$router.push({
                name: name,
                query: {'tab': 'faculty'}
            }) : this.$router.push({name: name});
        },

    }
}
</script>

<style scoped>

.h2_header {
    font-size: 25px;
}

.button {
    color: #78BE13;
    font-weight: bold;
    font-size: 16px;
}

.titleCard {
    margin-bottom: 5px;
    padding-left: 0;
    padding-top: 0;
    font-weight: 500;
    line-height: 1.2;
    word-break: normal;
    font-size: 20px;
}

.subTitleCard {
    padding: 0;
    line-height: 1.2;
}

.icon {
    height: 30px;
    width: 30px;
}

.card{
    height: 130px;
}
</style>
