<template>
  <v-autocomplete
      v-model="modelValue"
      :label="label"
      :items="items"
      :clear-icon="customClearIcon"
      :clearable="isClearable"
      :disabled="disabled"
      @change="change"
      @input="change"
      @click:clear="clear"
      color="light-green darken-1"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Výsledek nenalezen
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>

export default {
  name: "Autocomplete",
  props: {
    value: [Number, String],
    clearable: Boolean,
    customClearIcon: String,
    items: Array,
    label: String,
    disabled: Boolean
  },
  data() {
    return {
      modelValue: this.value,
      clearIcon: this.customClearIcon ? this.customClearIcon : "clear",
    }
  },

  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modelValue = newVal
      }
    }
  },

  computed: {
    isClearable() {
      return this.clearable ? this.clearable : true
    }
  },

  methods: {
    change() {
      this.$emit('change', this.modelValue);
    },
    clear() {
      this.modelValue = null
      this.$emit('clear');
    },
  }
}
</script>
