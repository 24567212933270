var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.didLoadedData)?_c('Loading'):_c('div',[(_vm.questionToDelete)?_c('ConfirmDialog',{attrs:{"model":_vm.showDeleteDialog,"onConfirm":_vm.attemptToDeleteQuestion,"onCancel":function () { return this$1.questionToDelete = undefined; },"yesColor":"red","yes":"Smazat"}},[_vm._v(" Přejete si smazat otázku \""+_vm._s(_vm.questionToDelete.localizedAttributes.cz.question)+"\" ? Tato operace je nevratná. ")]):_vm._e(),_c('Alert',{attrs:{"model":_vm.errorOnDelete,"onConfirm":function () { return this$1.errorOnDelete = false; }}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('ModuleHeader',{on:{"onRefreshButtonClick":_vm.refreshFaqQuestions}},[_c('RefreshButton',{on:{"click":_vm.refreshFaqQuestions}},[_c('h2',[_vm._v("Často kladené otázky")])]),_c('PrimaryButton',{attrs:{"to":{name: 'faqQuestionDetail', params: {id: 'new'}}}},[_vm._v("+ Nová")])],1),_c('v-row',{staticClass:"align-items-center justify-start mb-3"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('FAQCategorySelect',{attrs:{"label":"Kategorie","categories":_vm.sortedCategories(_vm.currentLanguage),"clearable":true,"language":_vm.currentLanguage},model:{value:(_vm.$query.categoryFilter),callback:function ($$v) {_vm.$set(_vm.$query, "categoryFilter", $$v)},expression:"$query.categoryFilter"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('LightButton',{on:{"click":function($event){return _vm.$router.push({name:'faqCategories'})}}},[_vm._v("Spravovat kategorie")])],1)],1),_c('v-data-table',{staticClass:"d-none d-sm-block",attrs:{"headers":_vm.headers,"items":_vm.filteredQuestions,"disable-pagination":true,"hide-default-footer":true,"hide-default-header":true,"sort-by":['id']},scopedSlots:_vm._u([{key:"item.question",fn:function(ref){
var item = ref.item;
return [_c('TwoLineCellWithIcon',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FAQCategoryIcon',{attrs:{"category":_vm.categoryById(item.categoryID)}})]},proxy:true},{key:"first",fn:function(){return [_c('router-link',{attrs:{"to":{name: 'faqQuestionDetail', params: {id: item.id}}}},[_c('b',{staticClass:"pointer ",class:item.hidden ? ' hidden-question secondary--text ' : ' light-green--text '},[_vm._v(" "+_vm._s(_vm._f("noname")(_vm._f("localized")(item,'question', _vm.currentLanguage)))+" ")])])]},proxy:true},{key:"second",fn:function(){return [_c('span',{staticClass:"answer"},[_vm._v(_vm._s(_vm._f("ellipsis")(_vm._f("localized")(item,'answer', _vm.currentLanguage),100)))])]},proxy:true}],null,true)})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('DeleteIcon',{on:{"click":function($event){return _vm.setQuestionToDelete(item)}}})]}}])}),_c('div',{staticClass:"mx-0 d-flex d-block d-sm-none"},[_c('v-checkbox',{staticClass:" mb-2 justify-content-center align-self-center",attrs:{"hide-details":"","dense":""},on:{"change":_vm.handleSelection}}),_c('span',{staticClass:"justify-content-center align-self-center",staticStyle:{"font-size":"10px"}},[_vm._v(" Počet vybraných položek: "+_vm._s(_vm.selected.length))]),_c('v-spacer'),(this.selected.length !== 0)?_c('v-chip',{staticClass:"px-1 justify-content-center align-self-center",attrs:{"label":"","small":"","outlined":"","color":"red"},on:{"click":function($event){return _vm.attemptToDeleteQuestions()}}},[_c('b',[_vm._v("ODSTRANIT")])]):_vm._e()],1),_c('v-data-iterator',{staticClass:"d-block d-sm-none pt-1",attrs:{"items":_vm.filteredQuestions,"disable-pagination":true,"hide-default-footer":true,"hide-default-header":true,"toggle-select-all":true},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Žádná dostupná data ")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
var isSelected = ref.isSelected;
var select = ref.select;
return _vm._l((items),function(item){return _c('div',{key:item.name},[_c('div',{staticClass:"d-flex my-2"},[_c('v-checkbox',{staticClass:"justify-content-center align-self-center mb-2",attrs:{"input-value":isSelected(item),"hide-details":"","dense":""},on:{"change":function (v) { return select(item, v); }}}),_c('FAQMobileDataTableRow',{attrs:{"to":{name: 'faqQuestionDetail', params: {id: item.id}}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FAQCategoryIcon',{attrs:{"color":"white","category":_vm.categoryById(item.categoryID)}})]},proxy:true},{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("noname")(_vm._f("localized")(item,'question', _vm.currentLanguage)))+" ")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("localized")(item,'answer', _vm.currentLanguage))+" ")]},proxy:true}],null,true)})],1),_c('v-divider')],1)})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }