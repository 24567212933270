<template>
        <Loading v-if="didLoadedAll === false"/>

        <v-container v-else fluid class="pa-0 ma-0 flex-grow-1 wrapper">

            <study-overlay v-if="!isEverythingFilled"></study-overlay>

            <v-row v-else>
                <v-col cols="12" lg="8">
                    <calendar :assistantId="getAssistantIdStorage"></calendar>

                    <div class="legend">
                        <div class="d-flex align-center mt-10">
                            <div class="square color1"></div>
                            <span>
                                {{assistantName}} – {{ $t('reservations.res_ref') }}
                            </span>
                        </div>
                        <div class="d-flex align-center mt-2">
                            <div class="square color2"></div>
                            <span>
                                {{assistantName}} – {{ $t('reservations.restrict_ref') }}
                            </span>
                        </div>
                        <div class="d-flex align-center mt-2">
                            <div class="square color3"></div>
                            <span>
                                {{ $t('reservations.restrict_pef') }}
                            </span>
                        </div>
                    </div>
                </v-col>

                <v-col cols="12" lg="4">
                    <div class="right-panel fill-height">
                        <calendar-infobar :assistantId="getAssistantIdStorage"/>
                    </div>
                </v-col>

            </v-row>

        </v-container>
</template>

<script>
    import Calendar from "./Calendar";
    import CalendarInfobar from "./CalendarInfobar";
    import Loading from "../../../../components/Loading";
    import StudyOverlay from "../../../../components/StudyOverlay";
    import {mapActions, mapGetters} from 'vuex';

    import {config} from '@/config';

    export default {
        components: {
            Calendar,
            CalendarInfobar,
            Loading,
            StudyOverlay,
        },

        name: "CalendarDetail",

        data() {
            return {
                URL: config.serverUrl,
            }
        },

        created() {
            this.fetchAssistants();
            this.fetchStudyFields();
            this.fetchStudyProblemsItems();
            this.fetchStudyProblemsCategories();
            this.fetchStudyFields();
            this.fetchStudyOpenHours();
            this.fetchClosedHoursReasons();
        },

        computed: {
            ...mapGetters('assistant', ['getAssistantByXlogin', 'areAssistantsFilled', 'getAssistants', 'didLoadedAssistants']),
            ...mapGetters('queueItemStatuses', ['didLoadedQueueItemStatuses']),
            ...mapGetters('closedHours', ['didLoadedClosedHours']),
            ...mapGetters('queueItems', ['didLoadedQueueItems']),
            ...mapGetters('studyProblemsCategories', ['areStudyProblemsCategoryFilled', 'didLoadedStudyProblemsCategories']),
            ...mapGetters('closedHoursReason', ['areClosedHoursReasonsFilled', 'didLoadedClosedHoursReasons']),
            ...mapGetters('StudyOpenHours', ['didLoadedStudyOpenHours']),
            ...mapGetters('studyProblemsItems', ['areStudyProblemsItemsFilled', 'didLoadedStudyProblemsItems']),
            ...mapGetters('pageStorage', ['getAssistantStorage', 'assistantName', 'getAssistantIdStorage']),
            ...mapGetters('studyFields', ['areStudyFieldsFilled',  'didLoadedStudyFields' ]),

            didLoadedAll() {
                return this.didLoadedClosedHoursReasons && this.didLoadedStudyOpenHours && this.didLoadedClosedHours && this.didLoadedAssistants && this.didLoadedStudyFields && this.didLoadedStudyProblemsItems && this.didLoadedStudyProblemsCategories && this.didLoadedQueueItemStatuses && this.didLoadedQueueItems;
            },

            isEverythingFilled() {
                return this.areClosedHoursReasonsFilled && this.areStudyProblemsItemsFilled && this.areStudyProblemsCategoryFilled && this.areAssistantsFilled && this.areStudyFieldsFilled;
            },
        },

        methods: {
            ...mapActions('studyProblemsCategories', ['fetchStudyProblemsCategories']),
            ...mapActions('closedHoursReason', ['fetchClosedHoursReasons']),
            ...mapActions('assistant', ['fetchAssistants']),
            ...mapActions('queueItemStatuses', ['setQueueItemStatusesUpdate']),
            ...mapActions('StudyOpenHours', ['fetchStudyOpenHours']),
            ...mapActions('studyProblemsItems', ['fetchStudyProblemsItems']),
            ...mapActions('studyFields', ['setFirstSet', 'setNumberOfActiveTickets',  'fetchStudyFields', 'fetchStudyFields']),
        },

    }
</script>

<style scoped lang="scss">

    .legend {
        font-size: small;

        .square {
            height: 1rem;
            width: 1rem;
            float: left;
            margin-right: 0.5rem;
        }

        .color1 {
            background-color: #78BE13;
        }

        .color2 {
            background-color: #F7B500;
        }

        .color3 {
            background-color: #FA6400;
        }
    }

</style>
