import axios from 'axios';

const state = {
    users: [],
    user: null,
    groups: [],
    groupDetail: null,
    technicians: [],
    admins: [],
    groupUsers: []
};

const getters = {
    getRentUsers: state => state.users,
    getRentUser: state => state.user,
    getGroups: state => state.groups,
    getGroup: state => state.groupDetail,
    getTechnicians: state => state.technicians,
    getAdmins: state => state.admins,
    getGroupUsers: state => state.groupUsers,
};
const actions = {
    //Fetch ALL
    async fetchUsers({commit}) {
        const response = await axios
            .get('https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/users');
        commit('setUsers', response.data.users);
    },

    //Fetch ONE
    async fetchUser({commit}, id) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/users/${id}`);
        commit('setUser', response.data);
    },

    //Fetch ALL
    async fetchGroups({commit}) {
        const response = await axios
            .get('https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/groups');
        commit('setGroups', response.data.groups);
    },

    //Fetch ONE
    async fetchGroup({commit}, id) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/groups/${id}`);
        commit('setGroup', response.data);
    },

    //Add ONE
    async createGroup({commit}, group) {
        return new Promise((resolve) => {
            axios
                .post(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/groups`, group).then((newGroup) => {
                commit('addGroup', newGroup.data);
                if (newGroup.status == 201) {
                    resolve();
                }
            });
        })
    },

    //Update ONE
    async updateGroup({commit}, {id, group, onSuccess = () => {} }) {
        commit('updateGroup', id, group);
        await axios
            .put(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/groups/${id}`, group)
            .then(onSuccess);
    },

    //Change privilages of user
    async changePrivilages({commit}, {id, user}) {
        commit('updatePrivilages', id, user);
        await axios
            .put(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/users/${id}`, user);
    },

    //Delete ONE
    async deleteGroup({commit}, id) {
        commit('filterGroup', id);
        await axios
            .delete(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/groups/${id}`);
    },

    async fetchTechnicians({commit}, id) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/groups/${id}/technician`);
        commit('setTechnicians', response.data.technicians);
    },

    async deleteTechnician({commit}, groupId, technicianId) {
        await axios
            .delete(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/groups/${groupId}/technician/${technicianId}`);
        commit('filterTechnician', groupId, technicianId);
    },

    async fetchAdmins({commit}, id) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/groups/${id}/admin`);
        commit('setAdmins', response.data.admins);
    },

    async deleteAdmin({commit}, groupId, adminId) {
        await axios
            .delete(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/groups/${groupId}/admin/${adminId}`);
        commit('filterAdmin', groupId, adminId);
    },

    async fetchGroupUsers({commit}, id) {
        const response = await axios
            .get(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/groups/${id}/user`);
        commit('setGroupUsers', response.data.groupUsers);
    },

    async deleteUser({commit}, groupId, userId) {
        await axios
            .delete(`https://private-e5b2b-mendelurentsystem.apiary-mock.com/rentSystem/API/v1/groups/${groupId}/user/${userId}`);
        commit('filterUser', groupId, userId);
    }
};

const mutations = {
    setUsers: (state, user) => (state.users = user),
    setUser: (state, user) => (state.user = user),
    updatePrivilages: (state, id, user) => {
        let index = state.users.filter(users => users.id === id);
        state.users[index] = user
    },
    setGroups: (state, group) => (state.groups = group),
    setGroup: (state, group) => (state.groupDetail = group),
    setTechnicians: (state, technician) => (state.technicians = technician),
    setAdmins: (state, admin) => (state.admins = admin),
    setGroupUsers: (state, user) => (state.groupUsers = user),
    addGroup: (state, group) => (state.groups.push(group)),
    updateGroup: (state, id, group) => {
        let index = state.groups.filter(groups => groups.id === id);
        state.groups[index] = group
    },
    filterGroup: (state, id) => (state.groups = state.groups.filter(
        group => group.id !== id
    )),
    filterTechnician: (state, groupId, technicianId) => (state.technicians = state.groups.filter(
        group => group.id === groupId,
        technician => technician.id !== technicianId
    )),
    filterAdmin: (state, groupId, adminId) => (state.groups = state.admins.filter(
        group => group.id === groupId,
        admin => admin.id !== adminId
    )),
    filterUser: (state, groupId, userId) => (state.groups = state.groupUsers.filter(
        group => group.id === groupId,
        user => user.id !== userId
    ))
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
