<template>
  <!-- Item not found -->
  <ItemNotFoundState v-if="objectNotFound" :objectName="'Obrazovka'">
    <template #backButton>
        <BackButton :target="{name: 'unitDetail'}">Informační panely</BackButton>
    </template>
  </ItemNotFoundState>
  <Loading v-else-if="!didLoadData"/>
  <div v-else>
    <!-- Module header -->
    <BackButton :target="{name: 'unitDetail'}">Informační panely</BackButton>
    <ModuleHeader>
      <div id="header-title">
        <h2>{{`${isNew ? 'Přidání' : 'Úprava'} obrazovky`}}</h2>
      </div>
      <v-spacer/>
      <SaveButton class="ml-5 mb-6" :disabled="!isFormValid" :is-saving="isSaving" @click="saveDetail"/>
    </ModuleHeader>
    <!-- Display detail -->
    <div class="mb-5 mt-5">
      <v-form @submit.prevent="saveDetail" v-model="isFormValid" ref="form">
        <div class="v-data-table v-data-table--fixed-header">
          <div class="v-data-table__wrapper">
            <table>
              <tbody>
                <tr v-if="!isNew">
                  <th scope="row" class="table-header">ID</th>
                  <td>
                    <v-text-field 
                      color="dark" 
                      :disabled="true"
                      label="Nelze měnit"
                      :value="displayId">
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="table-header">Název</th>
                  <td>
                    <v-text-field 
                      :autofocus="isNew"
                      id="display-name"
                      color="dark" 
                      :label="`${display.name.length}/${displaysConstants.display.name} znaků`"
                      :maxlength="displaysConstants.display.name"
                      :rules="[required_rule]" 
                      v-model="display.name" 
                      @change="changeWatch">
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="table-header">Zobrazení</th>
                  <td>
                    <v-select
                      id="display-parallels"
                      color="dark"
                      @change="changeWatch"
                      :items="displaysConstants.display.maxParallels"
                      label="Počet paralelně zobrazovaných oznámení"
                      :rules="[required_rule]" 
                      v-model="display.maxParallels"
                    ></v-select>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="table-header">Maják <InfoButton right small>Slouží k rozpoznání obrazovky mobilním zařízením s aplikací Moje MENDELU v jeho blízkosti. Pro výběr musí být maják předdefinován v modulu "Majáky".</InfoButton></th>
                  <td>
                    <v-autocomplete
                      id="display-beacons"
                      color="dark"
                      :items="beacons"
                      label="Přiřazený maják"
                      item-text="beaconId"
                      item-value="id"
                      :value="display.beaconId"
                      @change="changeBeacon">
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="table-header">Perioda aktualizace obsahu [s]</th>
                  <td>
                    <v-text-field 
                      type="number"
                      id="display-period"
                      label="Počet vteřin"
                      v-model="display.refreshPeriod" 
                      :rules="[required_rule, period_rule]"
                      :max="periodConstants.max"
                      :min="periodConstants.min"
                      append-outer-icon="add"
                      prepend-icon="remove"
                      @click:append-outer="() => changePeriod(1)"
                      @click:prepend="() => changePeriod(-1)">
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
  import { displaysMaxValues } from '@/config';
  import { mapGetters, mapActions } from 'vuex';
  import BackButton from "../../components/layout/BackButton";
  import ItemNotFoundState from '../../components/states/ItemNotFoundState.vue';
  import Loading from "../../components/Loading";
  import ModuleHeader from "../../components/layout/ModuleHeader";
  import InfoButton from "../../components/buttons/InfoButton";
  import SaveButton from "../../components/buttons/SaveButton";

  export default {
    name: "DisplayDetail",
  
    components: {
      BackButton,
      ItemNotFoundState,
      Loading,
      ModuleHeader,
      InfoButton,
      SaveButton,
    },

    async created() {
      await this.fetchBeacons();
      if (!this.isNew) {
        await this.fetchDisplayById(this.displayId);
      } else {
        this.clear();
      }
    },

    data() {
      const periodConstants = displaysMaxValues.display.refreshPeriod;
      return {
        isSaving: false,
        isFormValid: true,
        required_rule: value => !!value || 'Povinné' ,
        period_rule: value => {
          let val = Number.parseInt(value);
          return val && val >= periodConstants.min && val <= periodConstants.max 
            || `Zadejte číslo od ${periodConstants.min} do ${periodConstants.max}`;
        },
        display: {
          name: "",
          maxParallels: undefined,
          beaconId: undefined,
          refreshPeriod: 5
        },
        periodConstants
      }
    },

    watch: {
      displayId() {
        this.announcementType = this.initialAnnouncementType;
      },

      initialDisplay() {
        this.display = this.initialDisplay;
      }
    },

    computed: {
      ...mapGetters('displays', [
        'getDisplayByID', 
        'prepareNewDisplay',
        'didLoadDisplays'
      ]),

      ...mapGetters('errors', ['objectNotFound']),

      ...mapGetters('Beacons', ['beacons', "didLoadBeacons"]),

      getBeacons() {
        return this.beacons;
      },

      displaysConstants: () => (
        displaysMaxValues
      ),

      displayId() {
        const urlId = this.$route.params.displayId;
        return urlId === "new" ? null : Number(urlId);
      },

      isNew() { return this.displayId === null; },

      didLoadData() { 
        return this.didLoadBeacons && (this.didLoadDisplays || this.isNew);
      },

      initialDisplay() {
        return this.isNew
          ? this.prepareNewDisplay()
          : this.getDisplayByID(this.displayId);
      }
    },

    methods: {
      ...mapActions('leavePrevent', ['madeUnsavedChanges', 'didSaveChanges']),

      ...mapActions('displays', [
        'createDisplay',
        'fetchDisplayById',
        'updateDisplay'
      ]),

      ...mapActions('Beacons', ['fetchBeacons']),

      ...mapActions('errors', ['clear']),

      changeWatch() {
        this.madeUnsavedChanges();
      },

      changeBeacon(value) {
        this.changeWatch();
        this.display.beaconId = value;
      },

      saveDetail() {
        if (this.isFormValid) {
          this.isSaving = true;
          if (this.isNew) {
            this.createDisplay({...this.display, unitId: this.$route.params.unitId}).then(() => {
              this.isSaving = false;
              this.didSaveChanges();
              this.$router.push({name: "unitDetail"});
            });
          } else {
            this.updateDisplay(this.display).then(() => {
              this.isSaving = false;
              this.didSaveChanges();
            }).catch((e) => {
              console.log(e)
            });
          }   
        }
      },

      // increment or decrement refreshPeriod in given boundaries
      changePeriod(value) {
        const currentValue = Number.parseInt(this.display.refreshPeriod);
          this.display.refreshPeriod = currentValue && ( value > 0 ? currentValue >= this.periodConstants.min : currentValue > this.periodConstants.min)
          ? Math.min(currentValue + value, this.periodConstants.max) 
          : this.periodConstants.min;
      }
    }
  }
</script>

<style scoped>
  li {
    list-style-type: none;
  }

  .table-header {
    font-size: 16px;
  }
</style>