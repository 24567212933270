import {get, post, put, del} from '@/services/api'
import Vue from "vue";

const state = {
    beacons: [],
    didLoadBeacons: false,
    states: [
        {text: "Aktivní", value: "active"},
        {text: "Pasivní", value: "passive"},
    ],
    protocols: [
        {text: "Eddystone", value: "Eddystone"},
        {text: "iBeacon", value: "iBeacon"}
    ],
};

const getters = {
    beacons: state => state.beacons,
    beaconById: state => id => {
        return state.beacons.find(w => (w.id === id))
    },
    beaconStates: state => state.states,
    beaconProtocols: state => state.protocols,
    prepareNewBeacon: () => () => ({
        beaconId: "",
        dateOfBatteryReplacement: null,
        dateOfLastUpdate: null,
        dateOfPlacement: null,
        eddystoneUid: "",
        ibeaconlUid: "",
        placeId: null,
        purposeId: null,
        latitude: null,
        longitude: null,
        major: null,
        minor: null,
        url: "",
        eddystoneSupport: false,
        ibeaconSupport: false,
        state: "",
    }),
    didLoadBeacons: state => state.didLoadBeacons,
};

const actions = {
    async fetchBeacons({commit}) {
        commit('startedDataDownload');
        const response = await get('iot/beacons/');
        commit('setBeacons', response.data.items);
        commit('finishedDataDownload');
    },

    async fetchBeaconById({commit}, id) {
        commit('errors/clear', null, { root: true });
        commit('startedDataDownload');
        try {
            const response = await get('iot/beacons/' + id);
            commit('updateBeacon', response.data.content);
            commit('finishedDataDownload');
        } catch (e) {
            if (e.statusCode == 404) {
                commit('errors/objectNotFound', null, { root: true });
            } else {
                throw e;
            }
        }
    },

    async createBeacon({commit}, beacon) {
        const response = await post(`iot/beacons/`, beacon);
        const newBeacon = response.data.content;
        commit('addBeacon', newBeacon);

    },

    async updateBeacon({commit}, beacon) {
        const response = await put(`iot/beacons/${beacon.id}`, beacon);
        commit('updateBeacon', response.data.content);
    },

    async deleteBeacon({commit}, id) {
        await del(`iot/beacons/${id}`)
        commit('filterBeacons', id)
    }

};

const mutations = {
    setBeacons: (state, beacons) => (state.beacons = beacons),
    updateBeacon: (state, item) => {
        const existing = state.beacons.findIndex(w => w.id === item.id);
        if (existing > -1) {
            Vue.set(state.beacons, existing, item);
        } else {
            state.beacons.push(item);
        }
    },
    addBeacon: (state, item) => (state.beacons.push(item)),
    filterBeacons: (state, id) => (state.beacons = state.beacons.filter(beacon => beacon.id !== id)),

    startedDataDownload: state => state.didLoadBeacons = false,
    finishedDataDownload: state => state.didLoadBeacons = true,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
