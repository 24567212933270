<template>
  <v-tooltip :top="top" :right="right" :left="left" :bottom="bottom" open-on-click max-width="250px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon>
        <v-icon v-bind:class="{'help-icon': !xSmall && !small && !xLarge && !large }" :x-small="xSmall" :small="small" :large="large" :x-large="xLarge" v-bind="attrs" v-on="on" dark>help_outline</v-icon>
      </v-btn>
    </template>
    <span><slot/></span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'InfoButton',

  props: {
    /**
     * Tooltip text.
     */
    tooltipText: String,
    /**
     * Top position.
     */
    top: Boolean,
    /**
     * Right position.
     */
    right: Boolean,
    /**
     * Left position.
     */
    left: Boolean,
    /**
     * Bottom position.
     */
    bottom: Boolean,

    /**
     * X-small size.
     */
    xSmall: Boolean,
    /**
     * Small size.
     */
    small: Boolean,
    /**
     * Large size.
     */
    large: Boolean,
    /**
     * X-large size.
     */
    xLarge: Boolean,
  },
}
</script>

<style scoped>
  a {
    text-decoration: none !important;
  }

  .help-icon {
    font-size: 18px !important;
  }
</style>