import store from '@/store/Store';

export default class PreviousPathMemory {

    static init() {
    }

    static save(previousPath) {
        store.dispatch('previousPathMemory/save', previousPath)
    }

    static get() {
        return store.getters['previousPathMemory/getPreviousPath']
    }
}